<div class="modal-header bg-warning">
  <h4 class="modal-title">Ostrzeżenie przed wysyłką</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('cross')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <p class="text-lg">Czy na pewno chcesz wysłać <strong>{{count}}</strong> SMS-ów?</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="modal.dismiss('cross')">
    <i class="fas fa-times fa-fw"></i>
    Anuluj
  </button>
  <button type="button" class="btn btn-primary" (click)="modal.close('send')">
    <i class="fas fa-paper-plane fa-fw"></i>
    Wyślij
  </button>
</div>


import { Group } from '@/model/group.model';
import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';
import { UserAvatarPipe } from './user-avatar.pipe';

@Pipe({
  name: 'groupAvatar'
})
export class GroupAvatarPipe extends UserAvatarPipe {

  transform(group: any, size = 45, fallback = true, prop = 'name', img = 'image', id = 'id'): string {
    if (!group || group[prop] == null || typeof group[prop] == "undefined") return `${environment.apiUrl}/thumb/${size}x${size}/img/placeholder.png`;
    let avatar = this.getAvatar(group[img], group[prop], group[id], size, fallback);
    return avatar ? avatar : '/assets/img/placeholder.png';
  }

}

<ul class="nav nav-tabs d-block d-lg-flex">
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/training']" [queryParams]="{status: 'active'}" routerLinkActive="active">Dla mnie</a>
  </li>
  <!-- <li class="nav-item">
    <a class="nav-link" [routerLink]="['/training']" [queryParams]="{status: 'all'}" routerLinkActive="active">Wszystkie</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/training']" [queryParams]="{status: 'invited'}" routerLinkActive="active">Zaproszenia</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/training']" [queryParams]="{status: 'progress'}" routerLinkActive="active">W trakcie</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/training']" [queryParams]="{status: 'completed'}" routerLinkActive="active">Ukończone</a>
  </li> -->
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/training']" [queryParams]="{status: 'uncompleted'}" routerLinkActive="active">Rozpoczętee</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/training/open']" routerLinkActive="active">Ogólno dostępne</a>
  </li>
  <!-- <li class="nav-item">
    <a class="nav-link">Otwarte</a>
  </li> -->

</ul>
<div class="card" style="border-top-right-radius:0;border-top-left-radius:0;">
  <div class="overlay" *ngIf="(loading | async)">
    <div style="position: sticky; text-align: center">
      <i class="fas fa-3x fa-spinner fa-spin"></i>
      <p>Ładowanie...</p>
    </div>
  </div>
  <div class="card-header d-flex align-items-center" style="border-top-right-radius:0;border-top-left-radius:0;">
    <!-- <h3 class="card-title m-0 flex-grow-1 text-bold"><i class="fas fa-graduation-cap fa-fw"></i> Szkolenia</h3> -->
    <div class="card-tools flex-grow-1">
      <div class="input-group">
        <input type="text" name="table_search" class="form-control float-right" placeholder="Szukaj" #searchInput>
        <button class="btn bg-transparent" style="position:absolute; right: 0; transform: translate(-100%,0);" (click)="clearSearch()">
          <i class="fa fa-times"></i>
        </button>
        <span class="input-group-append">
          <div class="input-group-text bg-transparent"><i class="fas fa-search"></i></div>
        </span>
      </div>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table align-middle table-hover">
      <thead>
        <tr>
          <th style="width:1rem">#</th>
          <th>Nazwa</th>
          <th>Szkoleń w paczce</th>
          <th>Data utworzenia</th>

          <th class="text-right" ></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of list | async; let idx = index; trackBy: identify">
          <th class="align-middle">{{idx + 1}}</th>
          <td class="align-middle">{{item.name}}</td>
          <td class="align-middle">{{item.trainings.length}}</td>
          <td class="align-middle">
            {{item.createdAt  | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}
          </td>

          <td class="align-middle text-nowrap text-left" *ngIf="item.status != 'uncompleted'">
            <button
              class="btn btn-info btn-sm elevation-2 mr-2"
              [routerLink]="['start',item.id]"
              [ngbTooltip]="'Weź udział'">
              <i class="fas fa-play fa-fw fa-lg"></i>
            </button>
          </td>
          <td class="align-middle text-nowrap text-right" *ngIf="item.status == 'uncompleted'">
            <span class="badge badge-secondary" *ngIf="item.package.archive">Archiwalne</span>
          </td>
        </tr>
      </tbody>
    </table>
    <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(total | async) == 0"></nz-empty>
  </div>
  <div class="card-footer" >
    <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="total | async" (pageChange)="pageChange($event)">
      <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationEllipsis>...</ng-template>
      <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { Comment } from '@/model/comment.model';
import { Post } from '@/model/post.model';
import { Idea } from '@/model/idea.model';

@Component({
  selector: 'div.card-comment[full]',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {
  @Input() comment: Comment;
  @Input() post: Post;
  @Input() idea: Idea;
  reply = false;

  constructor() { }

  ngOnInit(): void {
  }

  onReplyClick(replyComment: Comment): void {
    this.reply = !this.reply;
  }

  onReply($event) {
    this.reply = false;
  }

  commentIdenity(item: Comment, index: number) {
    return item.id;
  }

  get replies(): Comment[] {
    return this.comment?.replies ? Object.values(this.comment.replies.entities) : [];
  }
}

import { Group, GroupMember } from "@/model/group.model";
import { User } from "@/model/user.model";
import { createAction, props } from "@ngrx/store";

export const loginSuccess = createAction(
  "loginSuccess"
);

export const loginAction = createAction(
  "login",
  props<{ email: string, password: string }>()
);

export const fetchProfileAction = createAction(
  "fetch profile"
);

export const setTokenAction = createAction(
  "set token",
  props<{token: string}>()
);

export const saveUserLocal = createAction(
  "saveUserLocal",
  props<{user: User}>()
);

export const saveTokenAction = createAction(
  "save token",
  props<{token: string}>()
);

export const fetchTokenAction = createAction(
  "fetch token"
);

export const setLoadingAction = createAction(
  "set loading action"
);

export const setProfileAction = createAction(
  "set user",
  props<{profile: User}>()
);

export const signoutAction = createAction(
  "signout"
);

export const clearUserAction = createAction(
  "clear user"
);

export const editProfileAction = createAction(
  "edit profile",
  props<{profile: User}>()
);

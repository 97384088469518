<label class="btn btn-sm p-1 elevation-2" [ngClass] = "{
  'btn-success' : !isEdited && model,
  'btn-outline-secondary' : !isEdited && !model,
  'btn-secondary disabled': isEdited
}">
<input type="checkbox" [(ngModel)]="model" class="d-none" (ngModelChange)="onChange()"/>

<i class="fas fa-lg fa-fw" [ngClass]="{
  'fa-check' : !isEdited && model,
  'fa-times' : !isEdited && !model,
  'fa-spinner fa-spin': isEdited
}"></i>

<!-- <i class="fa-2x" [ngClass]="{
  'fa-check-square text-green' : !isEdited && model,
  'fa-square' : !isEdited && !model,
  'fa-spinner fa-spin': isEdited,
  'far' : !model,
  'fas' : model || isEdited
}"></i> -->
</label>



<div class="card">
  <div class="card-header d-flex align-items-center">
    <h3 class="card-title m-0 flex-grow-1 text-bold"><i class="fas fa-bug fa-fw"></i> Kategorie problemów</h3>
    <div class="card-tools flex-grow-1">
      <div class="input-group">
        <input type="text" name="table_search" class="form-control float-right" placeholder="Szukaj" #searchInput>
        <button class="btn bg-transparent" style="position:absolute; right: 0; transform: translate(-100%,0);" (click)="clearSearch()">
          <i class="fa fa-times"></i>
        </button>
        <span class="input-group-append">
          <div class="input-group-text bg-transparent"><i class="fas fa-search"></i></div>
        </span>
      </div>
    </div>
  </div>
  <ngb-accordion [closeOthers]="true">
    <ngb-panel *ngFor="let item of list | async" [title]="item.title">
      <ng-template ngbPanelContent>
        <div class="" bind-innerHTML="item?.description | safeHtml"></div>
        <p>&nbsp;</p>
        <hr>
        <p>&nbsp;</p>
        <h4>Wybierz kategorie problemu:</h4>
        <div class="list-group list-group-flush">
          <a [routerLink]="[item.id, sub.id]" class="list-group-item list-group-item-action" *ngFor="let sub of item.children">
            {{sub.title}}
          </a>
        </div>

      </ng-template>
    </ngb-panel>
  </ngb-accordion>
  <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(total | async) == 0"></nz-empty>
  <div class="overlay position-absolute" *ngIf="(loading | async)" style="top: 0; bottom: 0; left: 0; right: 0">
    <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
      <i class="fas fa-3x fa-spinner fa-spin"></i>
      <p>Ładowanie...</p>
    </div>
  </div>
  <div class="card-footer">
    <ngb-pagination [(page)]="page" [maxSize]="22" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="(total | async)"  (pageChange)="pageChange($event)">
      <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationEllipsis>...</ng-template>
      <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>
  </div>
</div>

import { PaginationDTO } from "@/model/pagination.model";
import { RedmineCategory, RedminePriority, RedmineSubCategory, RedmineIssue, RedmineIssueDTO } from "@/model/redmine.model";
import { createAction, props } from "@ngrx/store";

export const success = createAction("redmine-issue success");
export const clear = createAction("redmine-issue clear");
export const fetch = createAction("redmine-issue fetch");
export const fetchOne = createAction("redmine-issue fetchOne", props<{id: number}>());
export const setPage = createAction("redmine-issue setPage", props<{ page: number }>());
export const setOrder = createAction("redmine-issue setOrder", props<{ order: string }>());
export const setDirection = createAction("redmine-issue setDirection", props<{ asc: boolean }>());
export const setSearch = createAction("redmine-issue setSearch", props<{ search: string }>());
export const setType = createAction("redmine-issue setType", props<{ issueType: string }>());
export const setStatus = createAction("redmine-issue setStatus", props<{ status: string }>());


export const createIssue = createAction("redmine-issue createIssue", props<{ item: RedmineIssueDTO, attachments: File[] }>());
export const setIssue = createAction("redmine-issue setIssue", props<{ item: RedmineIssue }>());

export const setAll = createAction( "redmine-issue setAll", props<PaginationDTO<RedmineIssue>>());

export const updateIssue = createAction( "redmine-issue updateIssue", props<{item:any, attachments?: File[]}>());

export const fetchAssignedToMe = createAction("redmine-issue fetchAssignedToMe");
export const setAssignedToMe = createAction("redmine-issue setAssignedToMe", props<PaginationDTO<RedmineIssue>>());
export const setAllCount = createAction("redmine-issue setAllCount", props<{ count: number, watched: number, assigned: number, created: number }>());



import { Group } from '@/model/group.model';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromGroupReducer from '@/store/group/group.reducers';
import * as fromGroupActions from '@/store/group/group.actions';
import { AuthService } from '@/auth/auth.service';
import { User } from '@/model/user.model';
import { take, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { GroupsComponent } from '../groups.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-group-card',
  templateUrl: './group-card.component.html',
  styleUrls: ['./group-card.component.scss']
})
export class GroupCardComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  @Input() group: Group;
  @Input() linkToMembers: boolean = true;
  user: User;

  constructor(
    private authService: AuthService,
    private store: Store<fromGroupReducer.State>,
    private router: Router,
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.authService.getUser().then(user => {this.user = user});
    // this.groupObserver = new BehaviorSubject(this.group);
    this.activatedRoute.params.subscribe(params => {
      let id = parseInt(params.id);
      if(this.group.lazy) {
        this.store.dispatch(fromGroupActions.unlazyOneAction({id}))
        this.httpClient.get<Group>(`<backendhost>/v1/group/${id}`).subscribe(group => {
          this.store.dispatch(fromGroupActions.updateGroupAction({group}))
        });
      }
      this.store.pipe(
        takeUntil(this.unsubscribe$),
        select(fromGroupReducer.selectEntity, id)
      ).subscribe(group => {
        console.log(group)
        this.group = group;
      })
    });
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  canJoin() {
    return !(this.group.superior && this.user?.role !== 'admin')
  }

  join(): void {
    this.store.dispatch(fromGroupActions.joinGroup({group: this.group }));
  }

  leave(): void {
    this.store.dispatch(fromGroupActions.deleteGroupMemberAction({group: this.group, member: this.group.membership}));
  }

  observe(observe: boolean): void {
    this.store.dispatch(fromGroupActions.observeGroupAction({group: this.group, member: this.group.membership, observe: observe}));
  }

  canUnobserve() {
    return this.user && this.user.role != 'user';
  }

  show(): void {
    this.router.navigate(['/groups',this.group.id]);
  }

  backgroundStyle () {
    if(!this.group)
      return null;
    if(!this.group.background)
      return null;
    return {
      'aspect-ratio': '4',
      background : `url(${environment.apiUrl}/thumb/1920x0/${this.group.background.replace('public','')}) center center`,
      'background-size' : 'cover'
    }
  }

  private refresh(): void {
    this.store.pipe(take(1),select(fromGroupReducer.selectEntity, this.group.id)).subscribe((group: Group | null) => {
      this.group = group;
    })
  }

  canLeave() {
    return GroupsComponent.canLeaveStatic(this.group, this.user);
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import * as fromActions from '@/store/ideas/ideas.actions';
import * as fromReducers from '@/store/ideas/ideas.reducers';
import { ActionsSubject, Store } from '@ngrx/store';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-idea',
  templateUrl: './new-idea.component.html',
  styleUrls: ['./new-idea.component.scss']
})
export class NewIdeaComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;

  constructor(
    private store: Store<fromReducers.State>,
    private toastr: ToastrService,
    private router: Router,
    private actionsSubject$: ActionsSubject,
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      title: new FormControl(null, Validators.required),
      description: new FormControl(null, Validators.required),
    });
    this.actionsSubject$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(fromActions.addIdea)
    ).subscribe(()=> {
      this.toastr.success('Zgłoszono nowy pomysł');
      this.router.navigateByUrl("/ideas")
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get saving(): Observable<boolean> {
    return of(false);
  }

  save(): void {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    console.log("OK");

    if (this.form.valid) {
      this.store.dispatch(fromActions.createNewIdea({idea: this.form.value}));
    } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }
}

import { Invitation, TrainingResult } from '@/model/package.model';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import * as fromMyTrainingsAction from '@/store/my-training/my-training.actions';
import * as fromMyTrainingsReducers from '@/store/my-training/my-training.reducers';
import { Observable, Subject } from 'rxjs';
import { Multimedia, Slide } from '@/model/multimedia.model';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { ofType } from '@ngrx/effects';
import { PlyrComponent } from 'ngx-plyr';
import { environment } from 'environments/environment';
import Plyr from 'plyr';

@Component({
  selector: 'app-multimedia-player',
  templateUrl: './multimedia-player.component.html',
  styleUrls: ['./multimedia-player.component.scss']
})
export class MultimediaPlayerComponent implements OnInit {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  private readonly resultUpdate$: Subject<TrainingResult> = new Subject<TrainingResult>();
  show = false;
  invitation: Invitation;
  @Input() result: TrainingResult;
  slideResult: TrainingResult;
  training: Multimedia;
  slide: Slide;
  slideIndex: number;
  @ViewChild(PlyrComponent)
  plyr: PlyrComponent;
  player: Plyr;

  constructor(
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<fromMyTrainingsReducers.State>,
    private actionsSubject$: ActionsSubject,
  ) {
  }

  get audioPath() {
    return `${environment.apiUrl}/${this.slide.audio.path}`;
    return [{
      src: `${environment.apiUrl}/${this.slide.audio.path}`,
      type: 'audio/mp3'
    }]
  }
  audioPathPlyr = []

  ngOnInit(): void {

    this.activatedRoute.data.subscribe( (data: Data) => {
      this.show = false;
      this.training = data.training;
      this.invitation = data.instance;
      this.store.pipe(
        select(fromMyTrainingsReducers.selectOneMyTraining, this.invitation.id),
        takeUntil(this.unsubscribe$),
      ).subscribe( (invitation) => {
        this.invitation = invitation;
      })

      this.activatedRoute.params.subscribe(params => {
        this.slideResult = null;
        this.slideIndex = Math.max(0, params.slide * 1);
        if(this.slideIndex > this.training.slides.length-1) {
          this.slide = null;
          return;
        }
        this.slide = this.training.slides[this.slideIndex];

        if(this.slide.audio)
        {
          this.audioPathPlyr = [{
            src: `${environment.apiUrl}/v1/audio/${this.slide.audio.id}`,
            type: this.slide.audio.mimeType
          }]
        }

        this.initialize().then(() => {
          this.show = true;
          this.updateMainResult();
        })
      });
    });
  }

  get slideResultObserver() : Observable<TrainingResult> {
    return this.store.pipe(
      select(fromMyTrainingsReducers.selectOneMyTraining, this.invitation.id),
      map( (invitation: Invitation) => Object.values(invitation.results.entities).filter(result => {
        return result?.slide?.id == this.slide.id;
      }).pop()),
      distinctUntilChanged( (x,y) => !x && !y || x?.id == y?.id),
      tap( (result) => {
        if(!result) {
          this.store.dispatch(fromMyTrainingsAction.createResult({invitation: this.invitation, training: this.training, slide: this.slide}));
        }
      }),
      filter((result) => result != null),
    )
  }

  initialize(): Promise<void> {
    return new Promise<void>(resolve => {
      this.slideResultObserver.pipe(take(1)).subscribe( (result: TrainingResult) => {
        this.slideResult = result;
        resolve();
      });
    });
  }

  get slideResults(): TrainingResult[] {
    let slideResults = Object.values(this.invitation.results.entities).filter(result => {
      return result.training?.id == this.training.id && result.slide != null;
    });
    for(let i = slideResults.length; i < this.training.slides.length; i++) {
      slideResults.push({
        id: null,
        timeSpent: 0,
        score: 0,
        scoreMax: 0,
        scorePercent: 0,
        completed: false,
        completedOn: null,
        passed: null,
        progression: null,
        runtimeData: {}
      })
    }
    return slideResults;
  }

  updateMainResult() {
    let slideResults = this.slideResults;
    let pointableSlideResults = slideResults.filter(item => item.scoreMax != null);
    this.result = {
      ...this.result,
      ...{
        timeSpent: slideResults.map(item => item.timeSpent).reduce((a,b) => a + b, 0),
        score: slideResults.map(item => item.score).reduce((a,b) => a + b, 0),
        scoreMax: slideResults.map(item => item.scoreMax).reduce((a,b) => a + b, 0),
        scorePercent: pointableSlideResults.map(item => item.scorePercent).reduce((a,b) => {
          return a + b/100/pointableSlideResults.length;
        }, 0) * 100,
        completed : slideResults.map(item => item.completed).reduce((a,b) => a && b, true),
        passed : slideResults.map(item => item.passed).reduce((a,b) => a && b, true),

      }
    };
    if (this.result.completed && !this.result.completedOn) {
      this.result.completedOn = new Date();
    }
  }

  saveResult(result) {
    this.slideResult = {...this.slideResult, ...result};
    // if (!this.slideResult.completedOn) {
    //   this.slideResult = {
    //     ...this.slideResult,
    //     ...{
    //       completed : true,
    //       completedOn : new Date()
    //     }
    //   };
    // }
    this.store.dispatch(fromMyTrainingsAction.updateResult({result: this.slideResult, invitation: this.invitation}));


    this.actionsSubject$.pipe(ofType(fromMyTrainingsAction.setResult),take(1)).subscribe( (action) => {
      this.updateMainResult();
      // if (!this.result.completedOn && this.isMultimediaCompleted()) {

      // }
      this.store.dispatch(fromMyTrainingsAction.updateResult({result: this.result, invitation: this.invitation}));
    });

    // this.next();
  }

  isMultimediaCompleted(): boolean {
    return this.slideResults.map(item => item.completedOn != null).reduce( (x,y) => x && y, true)
  }

  next() {
    this.router.navigate(['training', this.invitation.id ,'multimedia', this.training.id, this.slideIndex + 1]);
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  audioOptions = {
    autoplay:true,
    i18n: {
      speed: 'Prędkość',
      normal: 'Normalna',
    }
  }
}

<section class="content">
  <div class="container pt-3 pb-3">
    <form [formGroup]="form" (ngSubmit)="save()">
      <app-user-tabs></app-user-tabs>
      <div class="card" style="border-top-left-radius: 0;border-top-right-radius: 0;">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="form-group col-12">
                <label for="password">Hasło</label>
                <input formControlName="password"  [appIsValid]="form.controls.password"  type="password" required class="form-control" placeholder="Hasło" />
                <div class="invalid-feedback" *ngIf="form.controls.password.errors?.required">
                  Pole jest wymagane
                </div>
                <div class="invalid-feedback" *ngIf="form.controls.password.errors?.weekPassword">
                  Hasło musi mieć conajmniej 8 znaków, w tym przynajmniej jedną małą i dużą literą, cyfrę i znak specjalny.
                </div>
                <password-strength-meter [password]="form.controls.password.value"></password-strength-meter>
              </div>
              <div class="form-group col-12">
                <label for="confirmPassword">Powtórz hasło</label>
                <input formControlName="confirmPassword" [appIsValid]="form.controls.confirmPassword"  type="password" required class="form-control" placeholder="Powtórz hasło" />
                <div class="invalid-feedback" *ngIf="form.controls.confirmPassword.errors?.required">
                  Pole jest wymagane
                </div>
                <div class="invalid-feedback" *ngIf="form.controls.confirmPassword.errors?.notSame">
                  Hasła nie są identyczne
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button class="btn btn-success float-right" type="submit" [disabled]="disabled">
            <i class="fas fa-spinner fa-spin fa-fw" *ngIf="disabled"></i>
            Dalej
            <i class="fas fa-angle-right fa-fw"></i>
          </button>
        </div>
      </div>
    </form>
  </div>
</section>

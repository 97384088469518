<!-- <table>
  <tr>
    <th>First Name</th>
    <th>Last Name</th>
    <th>Roll#</th>
  </tr>
  <tr *ngFor="let item of data">
    <td>{{item.firstName}}</td>
    <td>{{item.lastName}}</td>
    <td>{{item.rollNumber}}</td>
  </tr>
</table> -->

<div *ngIf="data && rows && timeDividers" class="time-table-scroll"
  style="overflow: hidden; display: flex; padding: 1em;">
  <div class="time-table-cell rows">
    <ul class="title-row">
      <li class="time-table-header-row-row"></li>
    </ul>
    <ul class="data-row" *ngFor="let row of rows" style="border-color: white;">
      <ng-template #popContent><div [innerHtml]="row.description | safeHtml"></div></ng-template>
      <li
        class="time-table-row-row flex-grow-1 btn btn-light"
        style="border: 1px solid #6c757d;"
        [ngbPopover]="popContent"
        triggers="mouseenter:mouseleave"
        [popoverTitle]="row.title">
        <span style="text-overflow: ellipsis; overflow: hidden; " class="text-nowrap d-block">
          <img [src]="row | groupAvatar: 33" class="img-circle elevation-1 mr-2"/>
          {{row.title}}
          <!-- <pre>{{row | json}}</pre> -->
        </span>
      </li>
    </ul>
  </div>
  <!-- <pre>{{data | json}}</pre> -->
  <div class="time-table-cell" style="width: 100%; overflow-x: auto;">
    <div [ngStyle]="{width: (options.pxPer5min*timeDividers.length) + 'px'}">
      <ul class="time-table-header-time">
        <li class="time-table-header-time-item" *ngFor="let slot of timeDividers; let i = index">
          <span *ngIf="slot.includes(':00') || slot.includes(':30')">{{slot}}</span>

        </li>
      </ul>

      <ul *ngFor="let row of rows">
        <li class="time-table-row" *ngFor="let slot of timeDividers; let i = index"  (click)="emmitSelect(row, slot)">
          <ng-template #popContent >
            <div *ngIf="getDataAtSlot(row, slot)">
              <p class="text-nowrap">
                <img class="img-circle" [src]="getDataAtSlot(row, slot)?.user | userAvatar: 35" [appUserDisactivatable]="getDataAtSlot(row, slot)?.user"/>
                {{getDataAtSlot(row, slot).title}}
              </p>
              <p>
                od {{getDataAtSlot(row, slot).startDate  | amCalendar:{sameDay:'HH:mm'} }}
                do {{getDataAtSlot(row, slot).endDate  | amCalendar:{sameDay:'HH:mm'} }}
              </p>
              <p
                checkRolePermition="reservations.reservation.delete"
                [instance]="getDataAtSlot(row, slot)"
                userProperty="user">
                <a

                  class="btn btn-danger btn-sm float-right mt-1"
                  (nzOnConfirm)="deleteReservation(row, slot)"

                  nz-popconfirm
                  nzPopconfirmTitle="Czy na pewno usunąć?"
                  nzOkText="Tak"
                  nzCancelText="Nie"
                  nzPopconfirmPlacement="top"
                  ngbTooltip="Usuń">
                  <i class="fas fa-trash"></i> Usuń rezerwacje
                </a>
                <br>
              </p>
            </div>
          </ng-template>
          <div *ngIf="getDataAtSlot(row, slot)" class="slot-element" style="overflow: hidden; cursor: pointer;"
            (click)="doNothing()"
            [ngbPopover]="popContent"
            autoClose="outside"
            [container]="'body'"
            popoverTitle="Rezerwacja"
            [ngStyle]="{width: (options.pxPer5min*getDuration(row, slot) - 2) + 'px', background: options.element.background}">
            <div class="d-flex p-1">
              <div class="pr-2">
                <img class="img-circle" [src]="getDataAtSlot(row, slot).user | userAvatar: 35" [appUserDisactivatable]="getDataAtSlot(row, slot)?.user"/>
              </div>
              <div>
                <div [ngStyle]="{'color': options.element.titleColor}">{{getDataAtSlot(row, slot).title}}</div>
                <div [ngStyle]="{'color': options.element.subTitleColor}">
                  <small>{{getDataAtSlot(row, slot).subTitle}}</small>
                </div>
              </div>

            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

</div>
<!-- <ul>
  <li class="time-table-row" *ngFor="let slot of timeDividers;">
    <div *ngIf="getDataAtSlot('Mon', slot)" [ngClass]="getDataAtSlot('Mon', slot)? 'slot-element': ''">
      {{getDataAtSlot('Mon', slot).title}}
    </div>
  </li>
</ul>

<ul>
  <li class="time-table-row" *ngFor="let slot of timeDividers;">
    <div *ngIf="getDataAtSlot('Tue', slot)" [ngClass]="getDataAtSlot('Tue', slot)? 'slot-element': ''" [ngStyle]="">
      {{getDataAtSlot('Tue', slot).title}}
    </div>
  </li>
</ul> -->

<!--
<table class="custom">
  <tr>
    <td class="lines">
      <div class="data">

      </div>
    </td>
  </tr>
</table> -->

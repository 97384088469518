import { KbArticle } from "@/model/article.model";
import { KbFile } from "@/model/file.model";
import { Folder } from "@/model/folder.model";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import * as fromActions from "./kb.actions"


export interface State {
  folder: Folder;
  loading: boolean,
  loadingArticles: boolean,
  loadingFiles: boolean,
  saving: boolean,
  files: EntityState<KbFile>
  articles: EntityState<KbArticle>
}


export const adapter: EntityAdapter<Folder> = createEntityAdapter<Folder>({
  sortComparer: (a: Folder, b: Folder) => {
    return a.position < b.position ? -1 : 1;
  }
});

export const fileAdapter: EntityAdapter<KbFile> = createEntityAdapter<KbFile>();
export const articleAdapter: EntityAdapter<KbArticle> = createEntityAdapter<KbArticle>();

export const initialState: State = {
  folder: null,
  loading: true,
  loadingArticles: true,
  loadingFiles: true,
  saving: false,
  files: fileAdapter.getInitialState(),
  articles: articleAdapter.getInitialState(),
};

export const reducer = createReducer(
  initialState,
  on(fromActions.fetchFolder, (state, action) => {
    return initialState;
  }),

  on(fromActions.clearSaving, (state, action) => {return { ...state, ...{saving: false}};}),
  on(fromActions.createFolder, (state, action) => {return { ...state, ...{saving: true}};}),
  on(fromActions.saveFolder, (state, action) => {return { ...state, ...{saving: true}};}),
  on(fromActions.createArticle, (state, action) => {return { ...state, ...{saving: true}};}),
  on(fromActions.saveArticle, (state, action) => {return { ...state, ...{saving: true}};}),

  on(fromActions.setFolder, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        saving: false,
        folder: action.folder
      }
    };
  }),
  on(fromActions.addFolder, (state, action) => {
    return {
      ...state,
      ...{
        saving: false,
        folder: {
          ...state.folder,
          ...{ children: adapter.addOne(action.folder, state.folder.children)}
        }
      }
    };
  }),
  on(fromActions.updateFolder, (state, action) => {
    return {
      ...state,
      ...{
        saving: false,
        folder: {
          ...state.folder,
          ...{ children: adapter.updateOne({id: action.folder.id, changes: action.folder}, state.folder.children)}
        }
      }
    };
  }),
  on(fromActions.updateSelf, (state, action) => {
    return {
      ...state,
      ...{
        saving: false,
        folder: action.folder
      }
    };
  }),
  on(fromActions.removeFolder, (state, action) => {
    return {
      ...state,
      ...{
        saving: false,
        folder: {
          ...state.folder,
          ...{ children: adapter.removeOne(action.folder.id, state.folder.children)}
        }
      }
    };
  }),
  on(fromActions.clearFiles, (state, action) => {
    return {
      ...state,
      ...{
        saving: false,
        files: fileAdapter.getInitialState()
      }
    };
  }),
  on(fromActions.setFiles, (state, action) => {
    return {
      ...state,
      ...{
        saving: false,
        files: action.files
      }
    };
  }),
  on(fromActions.addFile, (state, action) => {
    return {
      ...state,
      ...{
        files: fileAdapter.addOne(action.file, state.files)
      }
    };
  }),
  on(fromActions.updateFile, (state, action) => {
    return {
      ...state,
      ...{
        files: fileAdapter.updateOne({id: action.file.id,changes: action.file}, state.files)
      }
    };
  }),
  on(fromActions.deleteFile, (state, action) => {
    return {
      ...state,
      ...{
        files: fileAdapter.removeOne(action.file.id, state.files)
      }
    };
  }),
  on(fromActions.setArticles, (state, action) => {
    return {
      ...state,
      ...{
        saving: false,
        articles: action.articles
      }
    };
  }),
  on(fromActions.addArticle, (state, action) => {
    return {
      ...state,
      ...{
        saving: false,
        articles: articleAdapter.addOne(action.article, state.articles)
      }
    };
  }),
  on(fromActions.updateArticle, (state, action) => {
    return {
      ...state,
      ...{
        saving: false,
        articles: articleAdapter.updateOne({id: action.article.id,changes: action.article}, state.articles)
      }
    };
  }),
  on(fromActions.deleteArticle, (state, action) => {
    return {
      ...state,
      ...{
        saving: false,
        articles: articleAdapter.removeOne(action.article.id, state.articles)
      }
    };
  }),
)

export const selectKbtState = createFeatureSelector<State>('kb');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// export const fileSelectors = {
//   selectIds,
//   selectEntities,
//   selectAll,
//   selectTotal,
// } = adapter.getSelectors();

export const selectFolder = createSelector(selectKbtState, (state) => state.folder)
export const selectSaving = createSelector(selectKbtState, (state) => state.saving)
export const selectLoading = createSelector(selectKbtState, (state) => state.loading)
export const selectChildren = createSelector(selectKbtState, (state) => state.folder ? selectAll(state.folder.children) : [])
export const selectFiles = createSelector(selectKbtState, (state) => state ? fileAdapter.getSelectors().selectAll(state.files) : [])
export const selectArticles = createSelector(selectKbtState, (state) => state ? articleAdapter.getSelectors().selectAll(state.articles) : [])
export const selectArticle = createSelector(selectKbtState, (state, id: number) => state ? state.articles.entities[id] : null)

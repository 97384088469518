import { OrderForm, OrderFormInput } from "@/model/order.form.model";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { environment } from "environments/environment";
import * as fromActions from './order.actions';



export interface State {
  loading: boolean;
  saving: boolean;
  list: EntityState<OrderForm>;
  total: number;
  page: number;
  order: string;
  readonly limit: number;
  asc: boolean;
  search: string;
  workStage?: fromActions.OrderFormWorkStage
}


export const adapter: EntityAdapter<OrderForm> = createEntityAdapter<OrderForm>({
  // sortComparer: (a: Post, b: Post) => a.id < b.id ? 1 : 0
});

export const inputsAdapter: EntityAdapter<OrderFormInput> = createEntityAdapter<OrderFormInput>({
  // sortComparer: (a: Post, b: Post) => a.id < b.id ? 1 : 0
});

export const initialState: State = {
  loading: false,
  saving: false,
  list: adapter.getInitialState(),
  page: 1,
  total: 0,
  order: 'id',
  limit: environment.pageSize,
  asc: true,
  search: null
}

export const reducer = createReducer(
  initialState,
  on(fromActions.setPage, (state, action) => {
    return {...state, ...{ page: action.page }}
  }),
  on(fromActions.setSearch, (state, action) => {
    return {...state, ...{ search: action.search }}
  }),
  on(fromActions.setOrder, (state, action) => {
    return {...state, ...{ order: action.order }}
  }),
  on(fromActions.setDirection, (state, action) => {
    return {...state, ...{ direction: action.direction }}
  }),
  on(fromActions.reset, (state, action) => {
    return initialState
  }),
  on(fromActions.fetchOrderForms, (state, action) => {
    return {...state, ...{ loading: true }}
  }),
  on(fromActions.fetchOneOrderForm, (state, action) => {
    return {...state, ...{ loading: true }}
  }),
  on(fromActions.fetchOneOrderForm, (state, action) => {
    return {...state, ...{ loading: true }}
  }),
  on(fromActions.addOrderForms, (state, action) => {
    let list = adapter.addMany(action.data, state.list);
    return {...state, ...{
      total: action.total,
      loading: false,
      saving: false,
      list: list
    }}
  }),
  on(fromActions.deleteOrderForm, (state, action) => {
    let list = adapter.removeOne(action.orderForm.id, state.list);
    return {...state, ...{
      loading: false,
      saving: false,
      list: list
    }}
  }),
  on(fromActions.setOrderForm, (state, action) => {
    let list = adapter.setOne(action.orderForm, state.list);
    return {...state, ...{
      loading: false,
      saving: false,
      list: list
    }}
  }),
  on(fromActions.setWorkspace, (state, action) => {
    return {...state, ...{
      loading: false,
      saving: false,
      workStage: {...state.workStage, ...{orderForm: action.orderForm}}
    }}
  }),
  on(fromActions.replaceOrderForm, (state, action) => {
    let list = adapter.removeAll(state.list);
    list = adapter.addMany(action.data, list);
    return {...state, ...{
      total: action.total,
      loading: false,
      saving: false,
      list: list
    }}
  }),
  on(fromActions.cleanWorkspace, (state, action) => {
    return {
      ...state,
      ...{
        workStage : {
          orderForm: {
            id: null,
            title: null,
            email: null,
            short: null,
            description: null,
            image: null,
            background: null,
            inputs: []
          },
          imageFile: null,
          backgroundFile: null
        }
      }
    };
  }),
  on(fromActions.setWorkspaceOrderFormInput, (state, action) => {
    console.log(state);
    let idx = action.input.id ? state.workStage.orderForm.inputs.map(x => x.id).indexOf(action.input.id) : -1;
    let inputs = [...state.workStage.orderForm.inputs]
    if(idx >= 0) {
      inputs[idx] = {...inputs[idx], ...action.input};
    }
    else {
      inputs.push({...action.input, ...{id: Math.max(...inputs.map(x => x.id)) + 1 }})
    }
    return {
      ...state,
      ...{
        workStage : {
          ...state.workStage,
          ...{
            orderForm: {
              ...state.workStage.orderForm,
              ...{
                inputs: inputs
              }
            }
          }
        }
      }
    };
  })
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectOrderFormsState = createFeatureSelector<State>('orders');

export const selectAllOrderForms = createSelector(selectOrderFormsState, (state) => selectAll(state.list) )
export const selectCount= createSelector(selectOrderFormsState, (state) => state.total )
export const selectPageSize = createSelector(selectOrderFormsState, (state) => state.limit )
export const selectOne = createSelector(selectOrderFormsState, (state: State, id: number) => {
  return state.list.entities[id];
})
export const selectWorkStage = createSelector(selectOrderFormsState, (state: State) => {
  return state.workStage;
})

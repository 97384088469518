<div class="card card-widget position-relative shadow" (inView)="seen()">
  <div class="overlay" *ngIf="showOverlay">
    <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%,0);text-align: center; padding: 25px 0">
      <i class="fas fa-3x fa-spinner fa-spin"></i>
      <p>Zapisywanie...</p>
    </div>
  </div>
  <div class="ribbon-wrapper ribbon-lg" *ngIf="!offer?.seen">
    <div class="ribbon bg-orange text-lg">
      NOWE
    </div>
  </div>
  <div [ngStyle]="backgroundStyle()"></div>
  <div class="card-header pb-1">
    <div class="user-block">
      <img class="img-circle" [src]="offer?.createdBy | userAvatar" alt="User Image"  [appUserDisactivatable]="offer?.createdBy">
      <span class="username" >
        <span class="text-decoration-line-through" *ngIf="!offer?.createdBy">Użytkownik usunięty</span>
        <a [routerLink]="['/profile', offer?.createdBy?.id]" *ngIf="offer?.createdBy"  [appUserDisactivatable]="offer?.createdBy">
          {{offer?.createdBy?.firstname}} {{offer?.createdBy?.lastname}}
        </a>
      </span>

      <!-- <span class="description"> {{post.createdAt | amLocale:'pl' | amTimeAgo }}</span> -->
      <span class="description"> {{offer?.createdAt | amLocale:'pl' | amCalendar }}</span>
      <div class="description" style="height: 1.5rem;">
        <a class="btn btn-link p-0 pr-1 text-sm" *ngFor="let tag of offer?.tags" [routerLink]="['/search', tag | lowercase]">#{{tag}}</a>
      </div>

    </div>
    <div class="card-tools">
      <a class="badge text-sm rounded-pill badge-secondary" ngbTooltip="Lista kandydatów" [routerLink]="[offer.id, 'candidates']" checkRolePermition="offers.candidates">
        <i class="fas fa-users"></i>
        {{offer.applicationsCount}}
      </a>
      <a class="btn btn-tool" ngbTooltip="Edytuj ofertę" [routerLink]="['/offer', offer.id, 'edit']"  checkRolePermition="offers.edit">
        <i class="fas fa-edit"></i>
      </a>
      <button
        checkRolePermition="offers.delete"
        type="button"
        class="btn btn-tool"
        nz-popconfirm
        nzPopconfirmTitle="Czy na pewno usunąć?"
        nzOkText="Tak"
        nzCancelText="Nie"
        nzPopconfirmPlacement="left"
        ngbTooltip="Usuń"
        (nzOnConfirm)="delete()">
        <i class="fas fa-trash"></i>
      </button>
    </div>
  </div>
  <div class="card-header pt-1 pb-0" style="border-bottom: 0;">
    <a *ngIf="offer?.id" class="text-primary card-title text-lg m-0" [routerLink]="['/offer', offer.id]">
      {{offer?.title}}
    </a>
  </div>
  <div class="card-header pt-1" >
    <span class="badge rounded-pill bg-primary tag" *ngFor="let cat of offer.category">{{cat.name}}</span>
    &nbsp;
  </div>
  <div class="card-body" style="height: 27.5rem;">
    <div class="content"  [innerHtml]="offer?.short">
      <!-- <span [innerHtml]="offer?.short"></span>... -->
    </div>
    <div class=" pt-3" *ngIf="offer?.id && small">
      <div class="d-flex">
        <div class="btn-group">
          <a class="btn btn-outline-primary btn-sm" [routerLink]="['/offer', offer.id]">
            <i class="fas fa-fw fa-check"></i>
            więcej
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="card-footer card-footer-buttons">
    <a class="text-sm float-left" (click)="like()" [ngClass]="{'link-black': !offer?.liked, 'link-primary  text-bold': offer?.liked}">

      <span class="badge text-sm rounded-pill" [ngClass]="{'badge-secondary': !offer?.liked, 'badge-primary': offer?.liked}">
        <i class="fa-thumbs-up" [ngClass]="{'far': !offer?.liked, 'fas': offer?.liked}" *ngIf="!offer?.likeSaving"></i>
        <i class="fa-spinner fa-spin fas" *ngIf="offer?.likeSaving"></i>
        {{offer?.likeCount ? offer.likeCount : 0}}
      </span>
      <span class="d-none d-sm-inline"> Lubię to</span>
    </a>
    <span class="pl-3 text-black text-sm float-left" (click)="seen()">
      <span class="badge text-sm rounded-pill" [ngClass]="{'badge-secondary': !offer?.seen, 'badge-primary': offer?.seen}">
        <i class="far fa-eye"></i>
        {{ offer?.seenCount }}
      </span>
      <span class="d-none d-sm-inline"> wyświetleń</span>
    </span>
    <a class="pl-3 text-black text-sm float-left" (click)="share()">
      <span class="badge text-sm rounded-pill">
        <i class="fas fa-share"></i>
      </span>
      <span class="d-none d-sm-inline"> udostępnij</span>
    </a>
    <!-- <a class="pl-3 link-black text-sm float-left" (click)="toggleShowComments()">
      <span class="badge text-sm rounded-pill" [ngClass]="{'badge-secondary': post?.comments.count == 0, 'badge-primary': post?.comments.count > 0}">
        <i class="fa-comments" [ngClass]="{'far': post?.comments.count == 0, 'fas': post?.comments.count > 0}"  ></i>
        {{ post?.comments.count }}
      </span>
      <span class="d-none d-sm-inline"> komentarze</span>
    </a> -->
  </div>

  <!-- <div class="card-footer card-comments" *ngIf="showComments">
    <div class="overlay" *ngIf="showCommentOverlay">
      <div>
        <i class="fas fa-3x fa-spinner fa-spin"></i>
        <p>Ładowanie...</p>
      </div>
    </div>
    <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(comments | async).length == 0"></nz-empty>
    <div class="card-comment" full *ngFor="let comment of comments | async; let idx = index; trackBy: commentIdenity" [comment]="comment" [post]="post"></div>
  </div>
  <comment-add  [post]="post" *ngIf="showComments"></comment-add>
  <a name="comments" #addComments></a> -->
</div>

import { Conversation } from '@/model/chat.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppService } from '@services/app.service';
import { ChatService } from '@services/chat.service';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-side-chat',
  templateUrl: './side-chat.component.html',
  styleUrls: ['./side-chat.component.scss']
})
export class SideChatComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();

  constructor(
    private chatService: ChatService,
    private appService: AppService
  ) {}

  get chats(): Observable<Conversation[]> {
    return this.chatService.all.pipe(takeUntil(this.unsubscribe$),map(arr => arr.slice(0,10)));
  }

  ngOnInit(): void {
    this.appService.sideOutletSubject.next(true);
  }

  ngOnDestroy(): void {
    this.appService.sideOutletSubject.next(false);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

<!-- Brand Logo -->
<a [routerLink]="['/']" class="brand-link" style="position: absolute;">
    <img
        src="assets/img/logo.png"
        alt="AdminLTE Logo"
        class="brand-image img-circle elevation-3"
        style="opacity: 0.8"
    />
    <span class="brand-text font-weight-light">SLA Intranet</span>
</a>

<div class="sidebar" #container id="sidebar" style="overflow: hidden;">
  <div class="user-panel mt-3 pb-3 mb-3 d-flex">
    <div class="image">
      <img [src]="user | userAvatar" class="img-circle elevation-2" alt="User Image" [appUserDisactivatable]="user"/>
    </div>
    <div class="info" style="line-height: 1em">
      <a [routerLink]="['/profile']" class="d-block">
        {{ user?.firstname }} {{ user?.lastname }} <br/>
        <small class="text-muted">{{ user?.active ? user?.email : '-' }}</small>
      </a>
    </div>
  </div>
  <nav class="mt-2" style="padding-bottom: 35px;">
    <ul  #nav class="nav nav-pills nav-sidebar flex-column nav-flat-nooot" data-widget="treeview" role="menu" (resized)="onResized($event)">
      <li class="nav-item">
        <a [routerLink]="['/']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <i class="nav-icon fas fa-home"></i> <p>Home</p>
        </a>
      </li>
      <li class="nav-item" checkRolePermition="news.view">
        <a routerLink="/news" class="nav-link" routerLinkActive="active">
          <i class="nav-icon fas fa-bullhorn"></i> <p>Newsy</p>
        </a>
      </li>
      <li class="nav-item" checkRolePermition="groups.list">
        <a [routerLink]="['/groups']" class="nav-link" routerLinkActive="active">
          <i class="nav-icon fas fa-users"></i> <p>Grupy</p>
        </a>
      </li>
      <li class="nav-item">
        <a [routerLink]="['/chat']" class="nav-link" routerLinkActive="active">
          <i class="nav-icon fas fa-comments"></i> <p>Chaty</p>
        </a>
      </li>
      <li class="nav-item">
        <a [routerLink]="['/messages']" class="nav-link" routerLinkActive="active">
          <i class="nav-icon fas fa-envelope"></i> <p>Wiadomości</p>
        </a>
      </li>
      <li class="nav-item" checkRolePermition="users.list" >
        <a [routerLink]="['/users']" class="nav-link" routerLinkActive="active">
          <i class="nav-icon fas fa-user-friends"></i> <p>Użytkownicy</p>
        </a>
      </li>
      <li class="nav-item" checkRolePermition="structure.list" >
        <a [routerLink]="['/structure']" class="nav-link" routerLinkActive="active">
          <i class="nav-icon fas fa-sitemap"></i> <p>Struktura firmy</p>
        </a>
      </li>

      <li class="nav-item"  checkRolePermition="offers.list">
        <a [routerLink]="['/offer']" class="nav-link" routerLinkActive="active">
          <i class="nav-icon fas fa-hammer"></i> <p>Oferty Pracy</p>
        </a>
      </li>
      <li class="nav-item"  checkRolePermition="orders.list">
        <a [routerLink]="['/orders']" class="nav-link" routerLinkActive="active">
          <i class="nav-icon fas fa-truck"></i> <p>Zamówienia</p>
        </a>
      </li>
      <li class="nav-item" *ngFor="let item of kbRoots" checkRolePermition="kb.list">
        <a [routerLink]="['/kb', item.id]" class="nav-link" routerLinkActive="active">
          <i [class]="'nav-icon ' + item.icon"></i> <p>{{item.title}}</p>
        </a>
      </li>
      <li class="nav-item" checkRolePermition="ideas.list">
        <a [routerLink]="['/ideas']" class="nav-link" routerLinkActive="active">
          <i class="nav-icon far fa-lightbulb"></i> <p>Pomysły</p>
        </a>
      </li>
      <li class="nav-item" checkRolePermition="prizes.list">
        <a [routerLink]="['/prizes']" class="nav-link" routerLinkActive="active">
          <i class="nav-icon fas fa-trophy"></i> <p>Nagrody</p>
        </a>
      </li>
      <li class="nav-item"  checkRolePermition="reservations.list">
        <a [routerLink]="['/reservations']" class="nav-link" routerLinkActive="active">
          <i class="nav-icon far fa-calendar-check"></i> <p>Rezerwacje</p>
        </a>
      </li>
      <li class="nav-item">
        <a [routerLink]="['/training']" class="nav-link" routerLinkActive="active"  checkRolePermition="training.view">
          <i class="nav-icon fas fa-graduation-cap"></i> <p>Szkolenia</p>
        </a>
      </li>
      <li class="nav-item">
        <a [routerLink]="['/survey']" class="nav-link" routerLinkActive="active" checkRolePermition="survey.view">
          <i class="nav-icon fas fa-vote-yea"></i> <p>Ankiety</p>
        </a>
      </li>
      <li class="nav-item">
        <a [routerLink]="['/outposts']" class="nav-link" routerLinkActive="active" checkRolePermition="outpost.view">
          <i class="nav-icon fas fa-clinic-medical"></i> <p>Placówki</p>
        </a>
      </li>
      <li class="nav-item">
        <a [routerLink]="['/workmarket']" class="nav-link" routerLinkActive="active" checkRolePermition="vacant.view">
          <i class="nav-icon fas fa-briefcase-medical"></i> <p>Giełda pracy</p>
        </a>
      </li>
      <li class="nav-item">
        <a [routerLink]="['/redmine']" class="nav-link" routerLinkActive="active"  checkRolePermition="redmine.view">
          <i class="nav-icon fas fa-bug"></i> <p>Zgłaszanie problemów</p>
        </a>
      </li>
      <li class="nav-item">
        <a [routerLink]="['/statistics']" class="nav-link" routerLinkActive="active"  checkRolePermition="statistics.view">
          <i class="nav-icon fas fa-chart-pie"></i> <p>Statystyki</p>
        </a>
      </li>
        <li class="nav-item">
        <a [routerLink]="['/call-center']" class="nav-link" routerLinkActive="active"  [checkRolePermition]="['callcenter.view', 'callcenter.fill', 'callcenter.create', 'callcenter.edit', 'callcenter.delete']">
          <i class="nav-icon fas fa-phone"></i> <p>Oceny rozmów</p>
        </a>
      </li>
      <li class="nav-item">
        <a [routerLink]="['/roles']" class="nav-link" routerLinkActive="active"  checkRolePermition="roles.admin">
          <i class="nav-icon fas fa-lock"></i> <p>Zarządzanie rolami</p>
        </a>
      </li>
      <li class="nav-item" *ngIf="goToShopUrl">
        <a class="nav-link" [href]="goToShopUrl" target="_blank">
          <i class="nav-icon fas fa-shopping-cart"></i> <p>Zniżki pracownicze</p>
        </a>
      </li>

    </ul>
  </nav>



</div>
<div class="text-right pb-2" #btn style="position: absolute;transform:  translate(0, -100%); opacity: 0.9" (click)="scrollDown()">
  <div class="btn btn-default btn-sm elevation-3">
    <i class="fas fa-angle-down fa-lg"></i>
    <!-- przewiń w dół -->
  </div>
</div>
<div class="sidebar mt-0  sidebar-custom" style="height: 50vh" #containerOutlet>
  <router-outlet name="sidebar"></router-outlet>
</div>

<section class="content">
  <div class="container">
    <app-package-tab></app-package-tab>
    <div class="card" style="border-top-left-radius: 0;border-top-right-radius: 0;">
      <div class="overlay position-absolute" *ngIf="publishing" style="top: 0; bottom: 0; left: 0; right: 0">
        <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
          <i class="fas fa-3x fa-spinner fa-spin"></i>
          <p>Zapisywanie...</p>
        </div>
      </div>
      <app-package-notification-editor
        [package]="package"
      ></app-package-notification-editor>
      <div class="card-footer text-center">
        <!-- [disabled]="package.published" -->
        <button class="btn btn-primary btn-lg"
          nz-popconfirm
          nzPopconfirmTitle="Wysłać zaproszenia?"
          nzOkText="Tak"
          nzCancelText="Nie"
          nzPopconfirmPlacement="top"
          (nzOnConfirm)="publish()" >
          <i class="fas fa-check fa-fw"></i>
          Wyślij zaproszenia
        </button>

        <div class="alert alert-warning mt-3" role="alert" *ngIf="!package.published">
          <h4>Uwaga!</h4>
          Po wysłaniu pierwszych zaproszeń nie można zmieniać szkoleń w paczce.
        </div>
      </div>
      <app-package-pack-list-of-invited
        [package]="package"
        [invitePack]="package.current"
      ></app-package-pack-list-of-invited>


    </div>
  </div>
</section>





import { RedmineSubCategory } from '@/model/redmine.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-redmine-sub-category',
  templateUrl: './redmine-sub-category.component.html',
  styleUrls: ['./redmine-sub-category.component.scss']
})
export class RedmineSubCategoryComponent implements OnInit {
  subCategory: RedmineSubCategory;

  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(data => {
      this.subCategory = data.subcategory
    })
  }

}

import { AllOrCreated, Role } from "@/model/role.model";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { AdminRole } from "./admin.role";
import * as fromActions from "./roles.actions";

export interface State extends EntityState<Role> {}
export const adapter: EntityAdapter<Role> = createEntityAdapter<Role>();

export const initialState: State  = {
  ids: ["admin" ],
  entities: {
    admin : AdminRole
  }
};
// export const initialState: State  = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(fromActions.set, (state,action) => {
    return adapter.setMany(action.roles, state)
  }),
  on(fromActions.add, (state,action) => {
    return adapter.addOne(action.role, state)
  }),
  on(fromActions.update, (state,action) => {
    return adapter.updateOne({
      id: action.role.id,
      changes: action.role
    }, state);
  }),
  on(fromActions.remove, (state,action) => {
    return adapter.removeOne(action.role.id, state)
  }),
);



const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectRolesState = createFeatureSelector<State>('roles');
export const selectRole = createSelector(selectRolesState, (state: State, id: string) => state.entities[id]);
export const selectRoles = createSelector(selectRolesState, (state: State) => selectAll(state));
export const selectRoleName = createSelector(selectRolesState, (state: State, id: string) => state.entities[id]?.name);
export const selectRolesLoaded = createSelector(selectRolesState, (state: State) => state.ids.length > 0);

import { Idea } from '@/model/idea.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditIdeaModalComponent } from '../edit-idea-modal/edit-idea-modal.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-edit-idea',
  templateUrl: './edit-idea.component.html',
  styleUrls: ['./edit-idea.component.scss']
})
export class EditIdeaComponent implements OnInit {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  idea: Idea;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data: Data) => {
      this.idea = data.idea;
      const modalRef = this.modalService.open(EditIdeaModalComponent, {size: 'xl', scrollable: false});
      modalRef.componentInstance.idea = data.idea;
      modalRef.result
          .then(() => {
            this.router.navigate(['/ideas/admin'])
          })
          .catch(() => {
            this.router.navigate(['/ideas/admin'])
          })
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store, ActionsSubject } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import * as fromChatActions from '@/store/chat/chat.actions';
import * as fromChatReducers from '@/store/chat/chat.reducers';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-room-add',
  templateUrl: './room-add.component.html',
  styleUrls: ['./room-add.component.scss']
})
export class RoomAddComponent implements OnInit {

  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  constructor(
    private toastr: ToastrService,
    private store: Store<fromChatReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
    });
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  save(): void {
    if (this.form.valid) {
      this.store.dispatch(fromChatActions.createNewRoom(this.form.value));
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        filter((action) => action.type === fromChatActions.addEmptyConversation.type)
      ).subscribe( (result: any) => {
        this.toastr.success('Utworzono nowy pokój!');
        console.log(['/chat', result.conversation.id, 'room',  'edit']);
        this.router.navigate(['/chat', result.conversation.id, 'room',  'edit']);
      });
    } else {
        this.toastr.error('Formularz jest nieprawidłowy!');
    }
    this.form.reset();
  }

}

import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import * as fromOutpostActions from '@/store/outpost/outposts.actions';
import * as fromOutpostReducers from '@/store/outpost/outposts.reducers';
import { Outpost } from '@/model/outpost.model';
import { select, Store } from '@ngrx/store';
import { noop, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'outpost-input',
  templateUrl: './outpost-input.component.html',
  styleUrls: ['./outpost-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OutpostInputComponent),
      multi: true
    }
  ]
})
export class OutpostInputComponent implements OnInit, ControlValueAccessor {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  @Input('multiple')
  multiple: boolean = false;
  @Input()
  disabled = false;
  @Input('selectedOutposts')
  selectedOutposts: Outpost[] = [];

  ngControl: NgControl;
  outpost: Outpost;

  private onTouched: () => void = noop;
  private onChange: (_: any) => void = noop;

  constructor(
    private outpostStore: Store<fromOutpostReducers.State>,
    private inj: Injector
  ) { }
  writeValue(obj: any): void {
    this.outpost = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.ngControl = this.inj.get(NgControl);
    this.outpostStore.dispatch(fromOutpostActions.clear());
    this.outpostStore.dispatch(fromOutpostActions.fetch());

    this.outpostInput$
        .pipe(distinctUntilChanged(),debounceTime(500))
        .subscribe((search) => this.outpostStore.dispatch(fromOutpostActions.setSearch({search})));
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  onOutpostChange(value) {
    value = this.multiple
      ? value//.map(item => item.id)
      : (value ? value.id : null);
    this.onChange(value);
  }

  get outpostLoading(): Observable<boolean>  {
    return this.outpostStore.pipe(
      takeUntil(this.unsubscribe$),
      select(fromOutpostReducers.selectState),
      map( (state:fromOutpostReducers.State)  => state.loading),
    );
  }

  get outposts$(): Observable<Outpost[]> {
    let outposts = this.outpostStore.pipe(
      takeUntil(this.unsubscribe$),
      select(fromOutpostReducers.selectAllOutposts),
      // TO DO
      // map((list) => list.map(item => {
      //   let copy = {...item};
      //   if(this.selectedOutposts.find(so => so.id === copy.id)) {
      //     copy.disabled = true;
      //   }
      //   return copy;
      // }))
    );

    return outposts;
  }

  outpostInput$ = new Subject<string>();
  trackByFn(item: any) {
    return item.id;
  }


}

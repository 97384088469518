<div class="content-header pt-0">
  <div class="text-right">
    <a [routerLink]="['/structure/admin']"
      class="btn btn-primary"
      [checkRolePermition]="['structure.create', 'structure.edit', 'structure.delete']">
      <i class="fas fa-tools fa-fw"></i> Zarządzaj strukturą
    </a>
  </div>
</div>
<section class="content">
  <router-outlet></router-outlet>
</section>


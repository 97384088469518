import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import * as fromActions from '@/store/structure/structure.actions';
import * as fromReducers from '@/store/structure/structure.reducers';
import { select, Store } from '@ngrx/store';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { Branch } from '@/model/branch.model';
import { Observable, Subject } from 'rxjs';
declare var $:any;

@Component({
  selector: 'app-admin-structure',
  templateUrl: './admin-structure.component.html',
  styleUrls: ['./admin-structure.component.scss']
})
export class AdminStructureComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private ngZone: NgZone,
    private store: Store<fromReducers.State>
  ) { }

  initTree() {
    $('.tree').treegrid({
      initialState: "collapsed",
      treeColumn: 0,
      expanderExpandedClass: "tree-open fas fa-angle-right fa-lg",
      expanderCollapsedClass: "tree-close fas fa-angle-down fa-lg",
      saveState: true,
      // saveStateMethod : 'hash'
    });
  }

  onNodeMousedown(id: number): void {
    // $(`#node-${id}`).treegrid('collapseRecursive');
    let depth = $(`#node-${id}`).treegrid('getDepth');
    $('.tree').treegrid('getAllNodes').each(function(item) {
      if($(this).treegrid('getDepth') == depth) {
        $(this).treegrid('collapseRecursive');
      }
    });
  }

  initSort() {
    let self = this;
    $('tbody').sortable({
      appendTo: $('tbody'),
      axis: "y",
      handle: ".handle",
      items: "> tr:not(.disabled)",
      helper : fixHelper,
      update: function( event, ui ) {
        setTimeout(() => {
          var updatePos:  {id: number, position: number}[] = [];
          $('.tree-sortable.updated:visible').each(function(k, item) {
              console.log($(item).text(), $(item).data('tree-id'));
              updatePos.push({
                id: $(item).data('tree-id'),
                position: k
              });
          });
          self.ngZone.run(() => {
            self.updatePositions(updatePos)
          });
        });
        // self.initTree();
      },
      start: function( event, ui ) {
          $(ui.sender).sortable( "option", "items", "> tr:not(.disabled)" );
          $('.tree-sortable:not(.disabled)').addClass('updated');
      },
      sort: function(event,ui){
          ui.helper.css('position','fixed');
          ui.helper.css('top',event.clientY - 0.5 * ui.helper.height());
      },
    });
  }

  updatePositions(payload: {id: number, position: number}[]) {
    //
    this.store.dispatch(fromActions.updatePositions({payload}));
  }

  list: Branch[];

  ngOnInit(): void {
    this.initSort();
    this.store.pipe(
      select(fromReducers.isFetched),
      take(1),
      takeUntil(this.unsubscribe$),
      filter( feteched => !feteched)
    ).subscribe((fetched) => {
      this.store.dispatch(fromActions.fetch());
    });
    this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectRoot)).subscribe((list) => {
      this.list = [];
      setTimeout(() => {
        this.list = list;
        setTimeout(() => {this.initTree();}, 0);
      }, 0);
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  identify(item: Branch, index: number) {
    return item.id;
  }

  nodeClass(item: Branch,parent?: Branch) {
    let classes = {};
    // classes['treegrid-' + item.id] = true;
    if (parent) {
      classes['treegrid-parent-' + parent.id] = true;
    }
    return classes;
  }

  delete(branch: Branch) {
    this.store.dispatch(fromActions.deleteOne({branch: branch}));
  }
}


export function fixHelper (e, ui) {
  ui.children().each(function() {
      $(this).width($(this).width());

  });
  //ui.css('left',500);
  return ui;
};

<section class="content">
  <div class="container">
      <div class="row">
          <div class="col-lg-12">
            <form [formGroup]="form" (ngSubmit)="save()">
            <div class="card card-primary">
              <div class="card-body">
                <div class="form-group">
                  <label for="inputName">Nazwa szkolenia</label>
                  <input
                    formControlName="name"
                    type="text"
                    class="form-control"
                    placeholder="Nazwa szkolenia"
                  />
                </div>
              </div>

              <div class="card-footer">
                <button class="btn btn-success float-right" type="submit">
                  Zapisz
                  <i class="fas fa-angle-right fa-fw"></i>
                </button>
              </div>
              <div class="card-header">
                <div class="card-title">Slajdy</div>
              </div>
              <div class="table-responsive">
                <table class="table align-middle table-hover">
                  <thead>
                    <tr>
                      <th style="width:1rem">#</th>
                      <th>Nazwa</th>
                      <th>Rodzaj</th>
                      <th class="text-right" >
                        <!-- <button  class="btn btn-success btn-sm elevation-2 text-nowrap" (click)="addSlide" >
                          <i class="fas fa-plus fa-fw fa-lg"></i> Dodaj
                        </button> -->
                        <div ngbDropdown class="d-inline-block" container="body" placement="bottom-right">
                          <a class="btn btn-success btn-sm elevation-2 text-nowrap" id="dropdownBasic1" ngbDropdownToggle>
                            <i class="fas fa-plus fa-fw"></i> Dodaj
                          </a>
                          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <a ngbDropdownItem (click)="addSlide('content')"><i class="fas fa-file fa-fw"></i> Treść</a>
                            <a ngbDropdownItem (click)="addSlide('scorm')"><i class="fas fa-archive fa-fw"></i> SCORM</a>
                            <a ngbDropdownItem (click)="addSlide('youtube')"><i class="fab fa-youtube fa-fw"></i> Youtube</a>
                            <a ngbDropdownItem (click)="addSlide('video')"><i class="fas fa-film fa-fw"></i> Video</a>
                            <a ngbDropdownItem (click)="addSlide('quiz')"><i class="fas fa-question-circle fa-fw"></i> Quiz</a>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of multimedia.slides; let idx = index">
                      <th class="align-middle">{{idx + 1}}</th>
                      <td class="align-middle">{{item.name}}</td>
                      <td class="align-middle">{{SlideType(item)}}</td>
                      <td class="align-middle text-nowrap text-right" >
                        <a class="btn btn-primary btn-sm elevation-2 mr-2" (click)="editSlide(item)">
                          <i class="fas fa-edit fa-fw fa-lg"></i>
                        </a>
                        <a
                            checkRolePermition="training.admin.scorm"
                            class="btn btn-danger btn-sm elevation-2"
                            nz-popconfirm
                            nzPopconfirmTitle="Czy na pewno usunąć?"
                            nzOkText="Tak"
                            nzCancelText="Nie"
                            nzPopconfirmPlacement="left"
                            ngbTooltip="Usuń"
                            (nzOnConfirm)="deleteSlide(item)" >
                          <i class="fas fa-trash fa-fw fa-lg"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="multimedia.slides.length == 0"></nz-empty>
              </div>
            </div>
            </form>
          </div>
      </div>
  </div>
</section>





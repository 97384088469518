

<ul class="nav nav-tabs">
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active bg-primary" [routerLink]="['/workmarket']" [routerLinkActiveOptions]="{ exact: true }">
      Preferowane
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active bg-primary" [routerLink]="['/workmarket','empty']" [routerLinkActiveOptions]="{ exact: true }">
      Wszystkie wolne
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active bg-primary" [routerLink]="['/workmarket','reserved']" [routerLinkActiveOptions]="{ exact: true }">
      Moje zarezerwowane
    </a>
  </li>
  <li class="nav-item" [checkRolePermition]="['vacant.create','vacant.createMy']">
    <a class="nav-link" routerLinkActive="active bg-primary" [routerLink]="['/workmarket','created']" [routerLinkActiveOptions]="{ exact: true }">
      Które utworzyłem
    </a>
  </li>
  <li class="nav-item" checkRolePermition="vacant.viewAll">
    <a class="nav-link" routerLinkActive="active bg-primary" [routerLink]="['/workmarket','all-reserved']" [routerLinkActiveOptions]="{ exact: true }">
      Wszystkie zarezerwowane
    </a>
  </li>
  <li style="flex-grow: 1;"></li>
  <li class="nav-item" checkRolePermition="vacant.viewAll">
    <a class="nav-link"
      style="border-color: #dee2e6 #dee2e6 #fff;"
      routerLinkActive="active bg-primary"
      [routerLink]="['/workmarket','to-confirm']"
      [routerLinkActiveOptions]="{ exact: true }">
      <span class="badge rounded-pill bg-secondary">
        {{totalToConfirm | async}}
      </span>
      Ogłoszenia do potwierdzenia
    </a>
  </li>
</ul>
<div class="card">

  <div class="card-header d-flex align-items-center">
    <h3 class="card-title m-0 flex-grow-1 text-bold"><i class="fas fa-clinic-medical fa-fw"></i> Filtry</h3>
  </div>
  <div class="card-body row" [formGroup]="filterForm">
    <div class="form-group col-md-3">
      <label>Data</label>
      <div class="input-group">
        <nz-range-picker formControlName="date" class="w-100"></nz-range-picker>
      </div>
    </div>
    <div class="form-group col-md-3 position-relative">
      <label>
        Placówka
        <a class="text-danger ml-3" (click)="filterForm.controls.outpost.setValue(null)"><i class="fas fa-times"></i></a>
      </label>
      <outpost-input formControlName="outpost" [disabled]="filterForm.controls.outpost.disabled"></outpost-input>
    </div>
    <div class="form-group col-md-3">
      <label>
        Rodzaj
        <a class="text-danger ml-3" (click)="filterForm.controls.type.setValue(null)"><i class="fas fa-times"></i></a>
      </label>
      <div class="form-check" *ngFor="let enum of SlotType">
        <input class="form-check-input" type="radio" [value]="enum" formControlName="type" [id]="enum">
        <label class="form-check-label" [for]="enum">{{enum}}</label>
      </div>
    </div>
    <div class="form-group col-md-3" *ngIf="type == 'all-reserved'">
      <label>
        Zarezerwowane przez
      </label>

      <app-user-input formControlName="reservedBy" [multiple]="false"></app-user-input>
    </div>
  </div>
  <div class="table-responsive position-relative">
    <table class="table align-middle table-hover">
      <thead>
        <tr>
          <th style="width:1rem">#</th>
          <th class="sortable" (click)="setSort('outpost')"  [ngClass]="{'active': (order | async) == 'outpost'}"  >
            Placówka
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <th class="sortable" (click)="setSort('type')"  [ngClass]="{'active': (order | async) == 'type'}"  >
            Rodzaj
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <th class="sortable" (click)="setSort('date')"  [ngClass]="{'active': (order | async) == 'day'}"  >
            Data
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <th class="sortable" (click)="setSort('startTime')"  [ngClass]="{'active': (order | async) == 'startTime'}"  >
            Początek
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <th class="sortable" (click)="setSort('endTime')"  [ngClass]="{'active': (order | async) == 'endTime'}"  >
            Koniec
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <th class="sortable" (click)="setSort('createdAt')"  [ngClass]="{'active': (order | async) == 'createdAt'}"  >
            Utworzono
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <th colspan="2">Zarezerwowane przez</th>
          <th class="text-right"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of list | async; let idx = index; trackBy: identify">
          <th class="align-middle">{{idx + 1 + (page-1)*pageSize}}</th>
          <td class="align-middle">{{item.slot.outpost.name}}</td>
          <td class="align-middle">{{item.slot.type}}</td>
          <td class="align-middle">{{item.date | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD (dddd)' }}</td>
          <td class="align-middle">{{item.slot.startTime}}</td>
          <td class="align-middle">{{item.slot.endTime}}</td>

          <td class="align-middle">{{item.createdAt | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
          <td class="user-panel" style="width:70px" *ngIf="item.reservedBy">
            <img
                  [src]="item.reservedBy  | userAvatar: 34"
                  class="img-circle elevation-2"
                  alt="User Image"
                  style="width:45px"
                  [appUserDisactivatable]="item.reservedBy"
              />
          </td>
          <td *ngIf="item.reservedBy">
            <strong [appUserDisactivatable]="item.reservedBy">{{item.reservedBy?.firstname}} {{item.reservedBy?.lastname}}</strong> <br>
            <small [appUserDisactivatable]="item.reservedBy">{{ item?.reservedBy?.active ? item?.reservedBy?.email : '-' }}</small>
          </td>
          <td *ngIf="!item.reservedBy && !item.pack || item.pack?.confirmed" class="align-middle text-center" colspan="2">
            - brak rezerwacji -
          </td>
          <td *ngIf="item.pack && !item.pack?.confirmed" class="align-middle text-center"  colspan="2">
            <i class="far fa-hourglass fa-spin"></i> &nbsp; oczekuje na potwierdzenie
          </td>
          <td *ngIf="item.pack && !item.pack?.confirmed" class="text-right">
            <button
                class="btn btn-danger btn-sm elevation-2 mr-2"
                checkRolePermition="vacant.delete"
                nz-popconfirm
                nzPopconfirmTitle="Czy na pewno usunąć?"
                nzOkText="Tak"
                nzCancelText="Nie"
                nzPopconfirmPlacement="left"
                ngbTooltip="Usuń"
                [instance]="item"
                [container]="'body'"
                (nzOnConfirm)="delete(item)" >
              <i class="fas fa-trash fa-fw fa-lg"></i>
            </button>
          </td>
          <td class="align-middle text-nowrap text-right" *ngIf="!item.pack || item.pack?.confirmed">
            <button
              class="btn btn-success  btn-sm elevation-2 mr-2"
              checkRolePermition="vacant.reservation.create"
              *ngIf="item.reservedBy == null"

              nz-popconfirm
              nzPopconfirmTitle="Czy na pewno zarezerwować?"
              nzOkText="Tak"
              nzCancelText="Nie"
              nzPopconfirmPlacement="left"
              (nzOnConfirm)="reserve(item)"
              ngbTooltip="Zarezerwuj">
              <i class="fas fa-check"></i>
              Zarezerwuj
            </button>
            <button
              class="btn btn-danger btn-sm elevation-2 mr-2"
              *ngIf="item.reservedBy != null"
              checkRolePermition="vacant.reservation.delete"
              [instance]="item"
              userProperty="reservedBy"

              nz-popconfirm
              nzPopconfirmTitle="Czy na pewno usunąć rezerwacje?"
              nzOkText="Tak"
              nzCancelText="Nie"
              nzPopconfirmPlacement="left"
              (nzOnConfirm)="unreserve(item)"
              ngbTooltip="Usuń rezerwacje"
              >
              <i class="fas fa-check"></i>
              Usuń rezerwacje
            </button>
            <button
                class="btn btn-info btn-sm elevation-2 mr-2"
                ngbTooltip="Szczegóły ogłoszenia"
                [container]="'body'"
                [routerLink]="['/workmarket', item.id]"
              >
              <i class="fas fa-info fa-fw fa-lg"></i>
            </button>
            <button
                class="btn btn-info btn-sm elevation-2 mr-2"
                ngbTooltip="Wszystkie ogłoszenia z tego slotu"
                [container]="'body'"
                [routerLink]="['/workmarket/slot', item.slot.id]"
              >
              <i class="fas fa-flask fa-fw fa-lg"></i>
            </button>
            <button
                class="btn btn-info btn-sm elevation-2 mr-2"
                ngbTooltip="Wyślij ponownie powiadomienia"
                checkRolePermition="vacant.delete"
                [instance]="item"
                [container]="'body'"
                (nzOnConfirm)="repeatNotification(item.id)"

                nz-popconfirm
                nzPopconfirmTitle="Czy na pewno wysłąć?"
                nzOkText="Tak"
                nzCancelText="Nie"
                nzPopconfirmPlacement="left"
              >
              <i class="fas fa-bell fa-fw fa-lg"></i>
            </button>

            <button
                class="btn btn-danger btn-sm elevation-2 mr-2"
                checkRolePermition="vacant.delete"
                nz-popconfirm
                nzPopconfirmTitle="Czy na pewno usunąć?"
                nzOkText="Tak"
                nzCancelText="Nie"
                nzPopconfirmPlacement="left"
                ngbTooltip="Usuń"
                [instance]="item"
                [container]="'body'"
                (nzOnConfirm)="delete(item)" >
              <i class="fas fa-trash fa-fw fa-lg"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(list | async).length == 0"></nz-empty>
    <div class="overlay position-absolute" *ngIf="(loading | async)" style="top: 0; bottom: 0; left: 0; right: 0">
      <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
        <i class="fas fa-3x fa-spinner fa-spin"></i>
        <p>Ładowanie...</p>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="(total | async)"  (pageChange)="pageChange($event)">
      <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationEllipsis>...</ng-template>
      <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>
  </div>
</div>

import { Slot } from "@/model/slot.model";
import { PaginationDTO } from "@/model/pagination.model";
import { createAction, props } from "@ngrx/store";
import { Outpost } from "@/model/outpost.model";
import { User } from "@/model/user.model";

export const clear = createAction("slot clear");
export const fetch = createAction("slot fetch");
export const fetchMore = createAction("slot fetchMore", props<{skip: number}>());
export const fetchOneSlot = createAction("slot fetchOneSlot", props<{id: number}>());

export const setOutpost = createAction("slot setOutpost", props<{ outpost: number }>());
export const setType = createAction("slot setType", props<{ value: string }>());
export const setUser = createAction("slot setUser", props<{ user: User }>());
export const setWeekday = createAction("slot setWeekday", props<{ value: boolean }>());
export const setSaturday = createAction("slot setSaturday", props<{ value: boolean }>());
export const setDafaultDay = createAction("slot setDafaultDay");

export const setPage = createAction("slot setPage", props<{ page: number }>());
export const setOrder = createAction("slot setOrder", props<{ order: string }>());
export const setDirection = createAction("slot setDirection", props<{ asc: boolean }>());
export const setSearch = createAction("slot setSearch", props<{ search: string }>());

export const createSlot = createAction("slot createSlot", props<{slot: Slot}>());
export const updateSlot = createAction("slot updateSlot", props<{slot: Slot}>());
export const deleteSlot = createAction("slot deleteSlot", props<{id: number}>());

export const setManySlot = createAction("slot setManySlot", props<PaginationDTO<Slot>>());
export const addManySlot = createAction("slot addManySlot", props<PaginationDTO<Slot>>());
export const addSlot = createAction("slot addSlot", props<{slot: Slot}>());
export const setSlot = createAction("slot setSlot", props<{slot: Slot}>());
export const removeSlot = createAction("slot removeSlot", props<{id: number}>());

export const signSlot = createAction("slot signSlot", props<{slot: Slot}>());
export const unsignSlot = createAction("slot unsignSlot", props<{slot: Slot}>());

<div class="content">
  <div class="container-xl">
    <div class="card card-widget position-relative shadow mt-3 mb-4">
      <div class="overlay" *ngIf="loading">
        <div style="position: sticky; text-align: center">
          <i class="fas fa-3x fa-spinner fa-spin"></i>
          <p>Ładowanie...</p>
        </div>
      </div>
      <div [ngStyle]="backgroundStyle()"></div>
      <div class="card-header pb-1">
        <div class="user-block">
          <img class="img-circle" [src]="orderForm?.createdBy | userAvatar" alt="User Image" [appUserDisactivatable]="orderForm?.createdBy">
          <span class="username">
            <span class="text-decoration-line-through" *ngIf="!orderForm?.createdBy">Użytkownik usunięty</span>
            <a [routerLink]="['/profile', orderForm?.createdBy?.id]"  *ngIf="orderForm?.createdBy" [appUserDisactivatable]="orderForm?.createdBy">
              {{orderForm?.createdBy?.firstname}} {{orderForm?.createdBy?.lastname}}
            </a>
          </span>
          <span class="description"> {{orderForm?.createdAt | amLocale:'pl' | amCalendar }}</span>
        </div>
      </div>
      <div class="card-header pt-1" >
        <h3 *ngIf="orderForm?.id" class="text-primary card-title text-lg m-0">
          {{orderForm?.title}}
        </h3>
      </div>
      <div class="card-body" >
        <div [innerHtml]="orderForm?.description | safeHtml"></div>
      </div>
      <div class="table-responsive position-relative">
        <table class="table align-middle table-hover table-striped">
          <tr>
            <td colspan="2">
              <h3>Zamawiający</h3>
            </td>
          </tr>
          <tr>
              <th>Data</th>
              <td>{{order.createdAt | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
          </tr>
          <tr>
              <th>Imię</th>
              <td>{{ order.createdBy.firstname }}</td>
          </tr>
          <tr>
              <th>Nazwisko</th>
              <td>{{ order.createdBy.lastname }}</td>
          </tr>
          <tr>
              <th>Email</th>
              <td>
                  <a href="mailto:{{ order.createdBy.email }}">{{ order.createdBy.email }}</a>
              </td>
          </tr>
          <tr>
              <td colspan="2">
                  <h3>Szczegóły Zamówienia</h3>
              </td>
          </tr>
          <tr>
              <th>NUMER PUNKTU POBRAŃ</th>
              <td>{{order.npp}}</td>
          </tr>
          <tr>
            <td colspan="2" style="padding:0">
              <table class="table  table-striped table-bordered mb-0">
                <tr>
                  <th>Produkt</th>
                  <th>Zamawiana ilość</th>
                </tr>
                <tr *ngFor="let item of order.data">
                  <td>{{item.label}}</td>
                  <td>
                      {{item.value}}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>

    </div>
  </div>
</div>

import { AuthService } from '@/auth/auth.service';
import { ReservationAsset } from '@/model/reservation.model';
import { User } from '@/model/user.model';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

export interface NgZeeTimeTableDataElement {
  id: number;
  title: string;
  subTitle: string,
  endTime: string;
  startDate: string;
  endDate: string;
  user: User;
}

export interface NgZeeTimeTableData {
  [day: string]: {
    [row: number]: NgZeeTimeTableDataElement
  }
}

export interface NgZeeTimeTableSelect {
  asset: ReservationAsset,
  time?: string
}

// var map: { [email: string]: Customer; } = {};

export interface NgZeeTimeTableOptions {
  pxPer5min: number;
  element: {
    background: string;
    titleColor: string;
    subTitleColor: string;
  },
  rowLabel: {
    background: string;
    labelColor: string;
  }

}

@Component({
  selector: 'app-timetable',
  templateUrl: './timetable.component.html',
  styleUrls: ['./timetable.component.scss']
})
export class TimetableComponent implements OnInit, OnChanges {

  // -----------------------------------------------------------------------------------------------
  getFormattedTime(v) {
    if (parseInt("" + v) > 9) {
      return "" + v;
    }
    else {
      return "0" + v;
    }
  }

  getHour(v: string) {
    return parseInt("" + v.split(":")[0]);
  }

  getFormattedHour(v: string) {
    return this.getFormattedTime(this.getHour(v));
  }

  getMinute(v) {
    let m = parseInt("" + v.split(":")[1]);
    let offset = m % 5;

    if (offset) {
      // console.log(`m:${m}, offset:${offset}`);
      m += offset > 2 ? (5 - offset) : -offset;
    }

    return parseInt("" + m);
  }

  getFormattedMinute(v) {
    return this.getFormattedTime(this.getMinute(v));
  }

  getTimeInMinutes(v) {
    return this.getHour(v) * 60 + this.getMinute(v);
  }

  fixTime(v) {
    let m = this.getMinute(v);
    let h = this.getHour(v);
    if (m == 60) {
      h++;
      m = 0;
    }
    return `${this.getFormattedTime(h)}:${this.getFormattedTime(m)}`
  }

  getDuration(row: ReservationAsset, slot: string) {
    let startTime: string = slot;
    let endTime: string = this.data[row.id][slot].endTime;
    return Math.floor((((this.getHour(endTime) - this.getHour(startTime)) * 60) + (this.getMinute(endTime) - this.getMinute(startTime))) / 5)
  }
  // -----------------------------------------------------------------------------------------------


  timeDividers: any[] = null;
  @Input('rows') rows: ReservationAsset[];
  @Input('data') data: NgZeeTimeTableData = null;
  @Input('options') options: NgZeeTimeTableOptions = {
    pxPer5min: 10,
    element: {
      background: '#f97c7c',
      titleColor: 'white',
      subTitleColor: '#862424'
    },
    rowLabel: {
      background: '#d83d3d',
      labelColor: 'white'
    }
  };
  @Output() onSelect: EventEmitter<NgZeeTimeTableSelect> = new EventEmitter<NgZeeTimeTableSelect>();
  @Output() onDelete: EventEmitter<ReservationAsset> = new EventEmitter<ReservationAsset>();



  deleteReservation(row: ReservationAsset, slot) {
    let item = this.getDataAtSlot(row, slot);
    delete this.data[row.id][slot];
    this.httpClient.delete<any>(`<backendhost>/v1/reservation/${item.id}`).subscribe(
      () => {
        this.onDelete.emit(row);
        this.toaster.success('Usunięto rezerwacje');
      },
      (error) => {
        this.toaster.error(error.message)
      }
    );
  }

  blockClick = false;
  doNothing() {
    this.blockClick = true;
    setTimeout(() => {this.blockClick = false;}, 500)
  }
  emmitSelect(asset?: ReservationAsset, time?: string) {
    if (!this.blockClick) {
      setTimeout(() => {
        this.onSelect.emit({asset: asset, time: time});
      });
    }
  }

  initTimeDividers(data: NgZeeTimeTableData) {
    let minTime = 9*60;
    let maxTime = 16*60;

    let startHours = 8;
    let endHours = 21;

    Object.keys(data).forEach(day => {
      Object.keys(data[day]).forEach(slot => {
        minTime = Math.min(minTime, this.getTimeInMinutes(slot));
        maxTime = Math.max(maxTime, this.getTimeInMinutes(data[day][slot].endTime))
      })
    })

    startHours = Math.floor(minTime / 60);
    endHours = Math.ceil(maxTime / 60);

    //console.log(`maxTime:${maxTime} hours:${hours}`);

    this.timeDividers = [];
    for (let i = startHours; i < endHours; i++) {
      for (let j = 0; j < 60; j += 5) {
        this.timeDividers.push(`${this.getFormattedTime(i)}:${this.getFormattedTime(j)}`);
      }
    }
    this.timeDividers.push(endHours + ":00");
  }



  getDataAtSlot(row, slot): NgZeeTimeTableDataElement {
    // console.log(row);
    if (this.data[row.id] && this.data[row.id][slot]) {
      return this.data[row.id][slot];
    }
    else {
      return null;
    }
  }

  // ------------------------------------------------------------------------------------------------------

  user: User;
  constructor(
    authService: AuthService,
    private httpClient: HttpClient,
    private toaster: ToastrService,
  ) {
    authService.getUser().then(user => this.user = user);
  }

  ngOnChanges(params) {
    // if (params && params.rows && params.rows.currentValue) {
    //   console.table(params.rows.currentValue);
    // }

    if (params && params.data && params.data.currentValue) {
      // console.table(params.data.currentValue);
      this.initTimeDividers(params.data.currentValue);
    }

  }

  ngOnInit() {
    // this.data = this.authService.getStudent()
    // console.log(this.data)

    // this.prepareTimeTable(this.dummyData);
  }

}

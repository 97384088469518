import { Outpost } from "@/model/outpost.model";
import { PaginationDTO } from "@/model/pagination.model";
import { StringifyHttpErrorResponse } from "@/utils/http.error.util";
import { plainText } from "@/utils/plainText";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { catchError, map, switchMap } from "rxjs/operators";
import * as fromActions from "./outposts.actions";
import * as fromReducers from "./outposts.reducers";

@Injectable()
export class OutpostEffects {
  state: fromReducers.State;

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private store$: Store<fromReducers.State>,
  ) {
    this.store$.pipe(select(fromReducers.selectState)).subscribe((state: fromReducers.State) => {
      this.state = state;
    })
  }

  onError (err, caught): any {
    this.toastr.error(StringifyHttpErrorResponse(err));
    return caught;
  };

  paginatorChange$ = createEffect(() => this.actions$.pipe(
    ofType(...[
      fromActions.setPage,
      fromActions.setSearch,
      fromActions.setOrder,
      fromActions.setDirection
    ]),
    map( () => fromActions.fetch())
  ));

  fetch$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetch),
    switchMap(() => {
      let params = new HttpParams();
      params = params.set('skip', this.state.limit * (this.state.page - 1));
      params = params.set('limit', this.state.limit);
      params = params.set('order', this.state.order);
      params = params.set('direction', this.state.asc ? 'asc' : 'desc');
      if(this.state.search)
        params = params.set('search', this.state.search);
      return this.httpClient.get<PaginationDTO<Outpost>>(`<backendhost>/v1/outpost`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: PaginationDTO<Outpost>) => [fromActions.setManyOutpost(response)])
  ));

  fetchOneOutpost$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchOneOutpost),
    switchMap((action) => {
      return this.httpClient.get<Outpost>(`<backendhost>/v1/outpost/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((outpost: Outpost) => [fromActions.addOutpost({outpost})])
  ));

  createOutpost$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createOutpost),
    switchMap((action) => {
      const formData = new FormData();
      if(action.image) {
        formData.append('image', action.image, action.image.name);
      }
      if(action.background) {
        formData.append('background', action.background, action.background.name);
      }
      let outpost = {
        ...action.outpost
      }

      formData.append('data', JSON.stringify(outpost));
      return this.httpClient.post<Outpost>(`<backendhost>/v1/outpost`, formData, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((outpost: Outpost) => [fromActions.addOutpost({outpost})])
  ));

  updateOutpost$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.updateOutpost),
    switchMap((action) => {
      const formData = new FormData();
      if(action.image) {
        formData.append('image', action.image, action.image.name);
      }
      if(action.background) {
        formData.append('background', action.background, action.background.name);
      }
      let outpost = {
        ...action.outpost,
      }

      formData.append('data', JSON.stringify(outpost));
      return this.httpClient.post<Outpost>(`<backendhost>/v1/outpost/${action.outpost.id}/update`, formData, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((outpost: Outpost) => [fromActions.setOutpost({outpost})])
  ));

  deleteOutpost$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deleteOutpost),
    switchMap((action) => {
      return this.httpClient.delete<any>(`<backendhost>/v1/outpost/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map(() => action.id));
    }),
    catchError(this.onError.bind(this)),
    switchMap((id: number) => [fromActions.removeOutpost({id})])
  ));
}

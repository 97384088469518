<section class="content">
  <form [formGroup]="filterForm" (ngSubmit)="save()">
  <div class="container pt-3 pb-3">
    <div class="card" style="border-top-left-radius: 0;border-top-right-radius: 0;">
      <div class="card-body row">
        <div class="col-md-4">
          <div class="form-group">
            <label>Pracownik</label>
            <ng-select
                [items]="users$ | async"
                bindLabel="name"
                [trackByFn]="trackByFn"
                [minTermLength]="2"
                [loading]="userInputLoading"
                typeToSearchText="Please enter 2 or more characters"
                [typeahead]="usersInput$"
                [placeholder]="'Wybierz pracownika'"
                formControlName="user">
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div class="d-flex">
                  <img [src]="item | userAvatar" class="img-size-50 img-circle" [appUserDisactivatable]="item"/>
                  <div class="pl-3">
                    <span [appUserDisactivatable]="item">{{item.name}}</span> <br>
                    <small [appUserDisactivatable]="item" class="text-muted">{{item?.active ? item?.email : '-'}}</small>
                  </div>
                  </div>
              </ng-template>
            </ng-select>
          </div>
          <div class="form-group">
            <label>Data</label>
            <div class="input-group">
              <nz-range-picker formControlName="date" class="w-100"></nz-range-picker>
            </div>
            <div class="text-danger" *ngIf="filterForm.controls.date.errors?.reqiored">
              Pole jest wymagane
            </div>
            <div class="text-danger" *ngIf="filterForm.controls.date.errors?.dateNotValid">
              Data jest nieprawidłowa
            </div>
          </div>
        </div>
        <div class="form-group col-md-4 position-relative">
          <!-- <div class="overlay position-absolute" *ngIf="(outpostLoading | async)" style="top: 0; bottom: 0; left: 0; right: 0">
            <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
              <i class="fas fa-3x fa-spinner fa-spin"></i>
              <p>Ładowanie...</p>
            </div>
          </div> -->
          <label>Placówka</label>
          <outpost-input formControlName="outpost"></outpost-input>
          <!-- <div class="form-check" *ngFor="let outpost of outposts | async">
            <input class="form-check-input" type="radio" formControlName="outpost" [value]="outpost.id" [id]="'outpost' + outpost.id">
            <label class="form-check-label" [for]="'outpost' + outpost.id">{{outpost.name}}</label>
          </div> -->
        </div>
        <div class="form-group col-md-4">
          <label>Rodzaj</label>
          <div class="form-check" *ngFor="let enum of SlotType">
            <input class="form-check-input" type="radio" [value]="enum" formControlName="type" [id]="enum">
            <label class="form-check-label" [for]="enum">{{enum}}</label>
          </div>
        </div>

      </div>
      <div class="form-group col-md-12">
        <label>Komentarz</label>
        <textarea class="form-control" formControlName="comment"></textarea>
      </div>

      <div class="card-header d-flex align-items-center">
        <h3 class="card-title"><i class="fas fa-bell fa-fw"></i> Powiadomienia</h3>
      </div>
      <div class="table-responsive">
        <table class="table align-middle table-hover">
          <thead>
            <tr>
              <th style="width:1rem">#</th>
              <th class="">Wysyłka</th>
              <th class="text-center">
                <!-- Data wysyłki -->
              </th>
              <th class="text-center">Email</th>
              <th class="text-center">Push</th>
              <th class="text-center">SMS</th>
              <th class="text-right" >
                <a class="btn btn-success btn-sm elevation-2 text-nowrap" (click)="addNotification()" >
                  <i class="fas fa-plus fa-fw fa-lg"></i> Dodaj
                </a>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="align-middle">1</th>
              <td class="align-middle" colspan="2">
                powiadomienie wysyłane natychmiast
              </td>
              <td class="align-middle">
                <input class="form-control text-xs" type="checkbox" formControlName="sendEmail" id="sendEmailCheckbox" />
              </td>
              <td class="align-middle">
                <input class="form-control text-xs" type="checkbox" formControlName="sendPush" id="sendPushCheckbox">
              </td>
              <td class="align-middle">
                <input class="form-control text-xs" type="checkbox" formControlName="sendSms" id="sendSmsCheckbox">
                <!-- <input
                  #smsCheckbox
                  class="form-control text-xs"
                  type="checkbox"
                  formControlName="sendSms"
                  id="sendSMSCheckbox"
                  nz-popconfirm
                  [nzPopconfirmTitle]="'Czy na pewno wysłac ' + countSms + ' SMSów?'"
                  nzOkText="Tak"
                  nzCancelText="Nie"
                  nzPopconfirmPlacement="top"
                  [nzCondition]="filterForm.controls.sendSms.value"
                  (nzOnConfirm)="toggleSms()"
                  (nzOnCancel)="filterForm.controls.sendSms.setValue(false)"
                  > -->
              </td>
              <td></td>
            </tr>
            <tr *ngFor="let item of notificationsList; let idx = index" [formGroup]="item">
              <th class="align-middle">{{idx + 2}}</th>
              <td class="align-middle" colspan="2">
                <div class="input-group">
                  <input class="form-control"
                      #dayInput
                      type="number"
                      min="1"
                      style="max-width:6rem"
                      formControlName="daysBefore">
                  <div class="input-group-text text-sm">
                    dni przed datą wakatu
                  </div>
                </div>
              </td>
              <td class="align-middle">
                <input class="form-control text-xs" type="checkbox" formControlName="email"/>
              </td>
              <td class="align-middle">
                <input class="form-control text-xs" type="checkbox" formControlName="push">
              </td>
              <td class="align-middle">
                <input class="form-control text-xs" type="checkbox" formControlName="sms">
                <!-- <input
                  #smsCheckbox
                  class="form-control text-xs"
                  type="checkbox"
                  formControlName="sms"
                  nz-popconfirm
                  [nzPopconfirmTitle]="'Czy na pewno wysłac ' + countSms + ' SMSów?'"
                  nzOkText="Tak"
                  nzCancelText="Nie"
                  nzPopconfirmPlacement="top"
                  [nzCondition]="item.controls.sms.value"
                  (nzOnConfirm)="toggleNotificationSms(item.controls.sms)"
                  (nzOnCancel)="item.controls.sms.setValue(false)"
                  > -->
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>

      <hr>
      <div class="form-group col-md-12 position-relative">
        <label>Użytkownicy do powiadomienia o rezerwacji / usunięcia rezerwacji</label>
        <app-user-input formControlName="notificationUsers" [multiple]="true"></app-user-input>
      </div>

      <div class="card-header d-flex align-items-center">
        <h3 class="card-title m-0 flex-grow-1 text-bold"><i class="fas fa-flask fa-fw"></i> Sloty</h3>
      </div>
      <div class="table-responsive position-relative">
        <table class="table align-middle table-hover">
          <!-- <thead>
            <tr>
              <th style="width:1rem">#</th>
              <th class="sortable" (click)="setSort('outpost')"  [ngClass]="{'active': (order | async) == 'outpost'}"  >
                Placówka
                <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
                <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
              </th>
              <th class="sortable" (click)="setSort('type')"  [ngClass]="{'active': (order | async) == 'type'}"  >
                Rodzaj
                <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
                <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
              </th>
              <th class="sortable" (click)="setSort('day')"  [ngClass]="{'active': (order | async) == 'day'}"  >
                Dzień
                <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
                <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
              </th>
              <th class="sortable" (click)="setSort('startTime')"  [ngClass]="{'active': (order | async) == 'startTime'}"  >
                Początek
                <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
                <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
              </th>
              <th class="sortable" (click)="setSort('endTime')"  [ngClass]="{'active': (order | async) == 'endTime'}"  >
                Koniec
                <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
                <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
              </th>
            </tr>
          </thead> -->
          <tbody formGroupName="slots">
            <tr *ngFor="let item of list | async; let idx = index; trackBy: identify" (click)="toggleSlot(item.id.toString())">
              <th class="align-middle text-nowrap">
                <!-- {{idx + 1}} -->
                <input type="checkbox" [formControlName]="item.id">
              </th>
              <td class="align-middle text-nowrap">{{item.outpost.name}}</td>
              <td class="align-middle text-nowrap">{{item.type}}</td>
              <td class="align-middle text-nowrap">{{item.day == "WEEKDAY" ? "Dzień roboczy" : "Sobota"}}</td>
              <td class="align-middle text-nowrap">{{item.startTime}}</td>
              <td class="align-middle text-nowrap">{{item.endTime}}</td>
              <td class="align-middle p-1 text-nowrap" style="min-width:45px" *ngIf="item.user">
                <img
                      [src]="item.user  | userAvatar: 34"
                      class="img-circle elevation-2"
                      alt="User Image"
                      style="width:40px"
                      [appUserDisactivatable]="item.user"
                  />
              </td>
              <td class="align-middle text-nowrap pl-0"  *ngIf="item.user">
                <span [appUserDisactivatable]="item.user">{{item.user?.name}}</span>
              </td>
              <td class="align-middle text-center text-nowrap"  *ngIf="!item.user" colspan="2">- brak -</td>
              <td class="align-middle w-100"></td>
            </tr>
          </tbody>
        </table>
        <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(list | async).length == 0"></nz-empty>
        <div class="overlay position-absolute" *ngIf="(loading | async)" style="top: 0; bottom: 0; left: 0; right: 0">
          <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
            <i class="fas fa-3x fa-spinner fa-spin"></i>
            <p>Ładowanie...</p>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-secondary" *ngIf="(list | async).length < (total | async)" (click)="loadMore()">
          Załaduj więcej
        </button>
        <button class="btn btn-primary float-right" type="submit">
          Zapisz
        </button>
        <!-- <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="(total | async)"  (pageChange)="pageChange($event)">
          <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination> -->
      </div>
    </div>
  </div>

  </form>
</section>

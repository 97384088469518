import { Component, OnInit } from '@angular/core';
import * as fromVacantActions from '@/store/vacant/vacant.actions';
import * as fromVacantReducers from '@/store/vacant/vacant.reducers';
import * as fromOutpostActions from '@/store/outpost/outposts.actions';
import * as fromOutpostReducers from '@/store/outpost/outposts.reducers';
import * as fromVacantToConfirmActions from '@/store/vacant/vacant.toconfirm.actions';
import * as fromVacantToConfirmReducers from '@/store/vacant/vacant.toconfirm.reducers';
import { Observable, Subject } from 'rxjs';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Vacant } from '@/model/vacant.model';
import { map, takeUntil, tap } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { SlotType } from '@/model/slot.model';
import { Outpost } from '@/model/outpost.model';
import moment from 'moment';

@Component({
  selector: 'app-list-vacant',
  templateUrl: './list-vacant.component.html',
  styleUrls: ['./list-vacant.component.scss']
})
export class ListVacantComponent implements OnInit {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  // @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  // searchTerm: string;
  SlotType = Object.values(SlotType);
  page = 1;
  pageSize = 20;
  filterForm: FormGroup;
  type: string;
  constructor(
    protected store: Store<fromVacantReducers.State>,
    protected storeToConfirm: Store<fromVacantToConfirmReducers.State>,
    protected outpostStore: Store<fromOutpostReducers.State>,
    protected router: Router,
    protected actionsSubject: ActionsSubject,
    protected activatedRoute: ActivatedRoute,
    protected toastr: ToastrService
  ) {
    this.filterForm = new FormGroup({
      type: new FormControl(null),
      outpost: new FormControl(null),
      date: new FormControl(null/*,[this.DateTimeValidator.bind(this)]*/),
      reservedBy: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.store.dispatch(fromOutpostActions.clear());
    this.store.dispatch(fromOutpostActions.fetch());
    this.store.dispatch(fromVacantActions.clear());
    this.storeToConfirm.dispatch(fromVacantToConfirmActions.fetch());
    this.activatedRoute.data.subscribe((data:Data) => {
      this.type = data.type;
      this.store.dispatch(fromVacantActions.setType({value: data.type}));
      if(data.type == 'slot'){
        let slot = data.slot;
        this.store.dispatch(fromVacantActions.setSlot({slot}));
        this.filterForm.controls.outpost.setValue(slot.outpost);
        this.filterForm.controls.type.setValue(slot.type);

        this.filterForm.controls.type.disable();
        this.filterForm.controls.outpost.disable();
      }
      this.store.dispatch(fromVacantActions.fetch());
    });
    this.filterForm.valueChanges.subscribe((value) => {
      let filter = {};
      console.log('this.filterForm.valueChanges', value);
      if(value.type) {
        filter['type'] = value.type;
      }
      if(value.outpost) {
        filter['outpost'] = value.outpost;
      }
      if(value.reservedBy) {
        filter['reservedBy'] = value.reservedBy;
      }
      if(value.date && value.date.length) {
        filter['date'] = {
          start: moment(value.date[0]).format("YYYY-MM-DD"),
          end: moment(value.date[1]).format("YYYY-MM-DD"),
        }
      }
      this.store.dispatch(fromVacantActions.setFilter({filter}));
      this.store.dispatch(fromVacantActions.fetch());
    })
    // this.store.pipe(
    //   take(1),
    //   takeUntil(this.unsubscribe$),
    //   select(fromVacantReducers.selectState),
    //   map(state => state.search)
    // ).subscribe((search:string) => {
    //   this.searchInput.nativeElement.value = search
    // });
    // fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
    //   takeUntil(this.unsubscribe$),
    //   map((event: any) => event.target.value),
    //   filter( (res:string) => res.length > 2),
    //   debounceTime(1000),
    //   distinctUntilChanged()
    // ).subscribe((text: string) => {
    //   this.store.dispatch(fromOutpostActions.setSearch({search: text.toLowerCase().trim()}));
    // });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  // clearSearch() {
  //   this.searchInput.nativeElement.value = '';
  //   this.store.dispatch(fromOutpostActions.setSearch({search: null}));
  // }

  get outposts(): Observable<Outpost[]> {
    return this.outpostStore.pipe(takeUntil(this.unsubscribe$), select(fromOutpostReducers.selectAllOutposts));
  }

  get list(): Observable<Vacant[]> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromVacantReducers.selectCurrent));
  }

  delete(item: Vacant): void {
    this.store.dispatch(fromVacantActions.deleteVacant({id: item.id}));
  }

  identify(index, entity: Vacant){ return entity.id }

  get total(): Observable<number>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromVacantReducers.selectState),
      map( (state:fromVacantReducers.State)  => state.total)
    );
  }

  get totalToConfirm(): Observable<number>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromVacantToConfirmReducers.selectState),
      map( (state:fromVacantToConfirmReducers.State)  => state.total)
    );
  }

  get direction(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromVacantReducers.selectState),
      map( (state:fromVacantReducers.State)  => state.asc),
      tap( asc => this.asc = asc)
    );
  }

  get loading(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromVacantReducers.selectState),
      map( (state:fromVacantReducers.State)  => state.loading),
    );
  }

  get outpostLoading(): Observable<boolean>  {
    return this.outpostStore.pipe(
      takeUntil(this.unsubscribe$),
      select(fromOutpostReducers.selectState),
      map( (state:fromOutpostReducers.State)  => state.loading),
    );
  }



  get order(): Observable<string>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromVacantReducers.selectState),
      map( (state:fromVacantReducers.State)  => state.order),
      tap( sort => this.sort = sort)
    );
  }

  private sort: string;
  private asc: boolean;
  setSort(sort: string): void {
    if(sort == this.sort) {
      this.store.dispatch(fromVacantActions.setDirection({asc: !this.asc}));
    } else {
      this.store.dispatch(fromVacantActions.setDirection({asc: false }));
    }
    this.store.dispatch(fromVacantActions.setOrder({order: sort}));
  }

  toggleDirection(): void {
    this.store.dispatch(fromVacantActions.setDirection({asc: !this.asc}));
  }

  pageChange(page): void {
    this.store.dispatch(fromVacantActions.setPage({page: page}));
  }

  reserve(vacant: Vacant) {
    this.store.dispatch(fromVacantActions.reserveVacant({vacant}));
  }

  unreserve(vacant: Vacant) {
    this.store.dispatch(fromVacantActions.unreserveVacant({vacant}));
  }

  repeatNotification(id: number) {
    this.store.dispatch(fromVacantActions.repeatNotification({id}));
  }
}

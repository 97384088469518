<table class="table align-middle table-sm table-bordered mb-0">
  <thead>
    <tr>
      <th>#</th>
      <th>Nazwa pliku</th>
      <th>Utworzono</th>
      <th>Przez</th>
      <th>Rozmiar</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let attachment of attachments; let aIndex = index">
      <td>{{aIndex+1}}</td>
      <td>
        {{attachment.filename}}
      </td>
      <!-- <td>{{attachment.description}}</td> -->
      <td>{{attachment.created_on | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm'}}</td>
      <td>
        <div class="d-flex">
          <div>
            <img [src]="attachment?.author | userAvatar:25" class="img-circle elevation-2 mr-2" alt="User Image" style="width:25px" [appUserDisactivatable]="attachment?.author"/>
          </div>
          <div class="info flex-grow-1">
            {{ attachment?.author?.name}}
          </div>
        </div>
      </td>
      <td>{{attachment.filesize | fileSize}}</td>
      <td width="1">
        <a class="btn btn-primary btn-sm" (click)="download(attachment)" [title]="attachment.filename">Pobierz</a>
      </td>
    </tr>
  </tbody>
</table>

<nz-upload *ngIf="true"
  class="w-100"
  [ngClass]="{'avatar-uploader': avatar, 'bg-uploader': !avatar}"
  [class]="avatar ? null : 'bg-uploader' + aspectRatioClass"
  nzName="image"
  nzListType="picture-card"
  [nzShowUploadList]="false"
  [nzBeforeUpload]="beforeUpload"
  (nzChange)="handleImageUploadChange($event)"
>
  <ng-container *ngIf="!image && !preview" class="w-100">
    <i class="fas fa-3x" [ngClass]="{'fa-upload' : !uploading, 'fa-spinner fa-spin' : uploading}"></i>
    <div class="ant-upload-text">Upload</div>
  </ng-container>
  <img *ngIf="preview" [src]="preview" style="width: 100%" />
  <img *ngIf="image && !preview" [src]="image" style="width: 100%" />
</nz-upload>

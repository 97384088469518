import { UsersPrize } from '@/model/prize.model';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-prize-modal',
  templateUrl: './prize-modal.component.html',
  styleUrls: ['./prize-modal.component.scss']
})
export class PrizeModalComponent implements OnInit {
  usersPrize: UsersPrize;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}

import { Invitation, TrainingResult } from "@/model/package.model";
import { PaginationDTO } from "@/model/pagination.model";
import { StringifyHttpErrorResponse } from "@/utils/http.error.util";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { catchError, map, switchMap } from "rxjs/operators";
import * as fromActions from './my-training.actions';
import * as fromReducers from './my-training.reducers';

@Injectable()
export class MyTrainingsEffects {
  state: fromReducers.State;

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private store$: Store<fromReducers.State>,
  ) {
    this.store$.pipe(select(fromReducers.selectState)).subscribe((state: fromReducers.State) => {
      this.state = state;
    })
  }

  onError (err, caught): any {
    this.toastr.error(StringifyHttpErrorResponse(err));
    return caught;
  };

  paginatorChange$ = createEffect(() => this.actions$.pipe(
    ofType(...[
      fromActions.setPage,
      fromActions.setSearch,
      fromActions.setOrder,
      fromActions.setDirection,
      fromActions.setMode
    ]),
    map( () => fromActions.fetch())
  ));

  fetch$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetch),
    switchMap(() => {
      let params = new HttpParams();
      params = params.set('skip', this.state.limit * (this.state.page - 1));
      params = params.set('limit', this.state.limit);
      params = params.set('order', this.state.order);
      params = params.set('mode', this.state.mode);
      params = params.set('direction', this.state.asc ? 'asc' : 'desc');
      if(this.state.search)
        params = params.set('search', this.state.search);
      return this.httpClient.get<PaginationDTO<Invitation>>(`<backendhost>/v1/my-trainings`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: PaginationDTO<Invitation>) => [fromActions.setList(response)])
  ));

  fetchOne$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchOne),
    switchMap((action) => {
      return this.httpClient.get<Invitation>(`<backendhost>/v1/my-trainings/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((invitation: Invitation) => [fromActions.setOne({invitation})])
  ));

  start$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.start),
    switchMap((action) => {
      return this.httpClient.get<Invitation>(`<backendhost>/v1/my-trainings/${action.id}/0/start`, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((invitation: Invitation) => [fromActions.update({invitation})])
  ));

  createResult$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createResult),
    switchMap((action) => {
      let data = {
        invitation: {
          id: action.invitation.id
        }
      };
      if(action.training?.id) {
        data = {
          ...data,
          ...{ training: {id : action.training.id}}
        }
      }
      if(action.slide?.id) {
        data = {
          ...data,
          ...{ slide: {id : action.slide.id}}
        }
      }
      return this.httpClient.post<TrainingResult>(`<backendhost>/v1/my-trainings`, data, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map((res) => {
        return {
          invitation: action.invitation,
          result: res
        }
      }));
    }),
    catchError(this.onError.bind(this)),
    switchMap(result => [fromActions.addResult(result)])
  ));

  updateResult$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.updateResult),
    switchMap((action) => {
      let result = {...action.result}
      return this.httpClient.put<TrainingResult>(`<backendhost>/v1/my-trainings/${result.id}`, result, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map((res) => {
        return {
          invitation: action.invitation,
          result: res
        }
      }));
    }),
    catchError(this.onError.bind(this)),
    switchMap( (result: {invitation: Invitation, result: TrainingResult})=> {
      let actions = [];
      actions.push(fromActions.setResult(result));

      let eResults = fromReducers.resultAdapter.setOne(result.result, result.invitation.results);
      let tResults = Object.values(eResults.entities);
      console.log(tResults);
      let completed = tResults
        .filter(item => item.slide == null)
        .map(item => item.completedOn != null)
        .reduce( (x,y) => x && y, true);

      let passed = tResults
        .filter(item => item.slide == null)
        .map(item => item.passed)
        .reduce( (x,y) => x && y, true);


      if (completed && !result.invitation.completedAt) {
        actions.push( fromActions.complete({id: result.invitation.id }));
      } else if (passed && !result.invitation.passed) {
        actions.push( fromActions.complete({id: result.invitation.id }));
      }
      return actions;
    })
    // switchMap(result => [])
  ));

  complete$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.complete),
    switchMap((action) => {
      return this.httpClient.post<Invitation>(`<backendhost>/v1/my-trainings/${action.id}/0/finish`, action, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map((res) => {
        return {
          invitation: res
        }
      }));
    }),
    catchError(this.onError.bind(this)),
    switchMap(result => [fromActions.update(result)])
  ));

  reset$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.reset),
    switchMap((action) => {
      return this.httpClient.post<Invitation>(`<backendhost>/v1/my-trainings/${action.invitation.id}/0/reset`, action.invitation, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map((res) => {
        return {
          invitation: res
        }
      }));
    }),
    catchError(this.onError.bind(this)),
    switchMap(result => [fromActions.update(result)])
  ));

  resetHard$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.resetHard),
    switchMap((action) => {
      return this.httpClient.post<Invitation>(`<backendhost>/v1/my-trainings/${action.invitation.id}/0/reset-hard`, action.invitation, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map((res) => {
        return {
          invitation: res
        }
      }));
    }),
    catchError(this.onError.bind(this)),
    switchMap(result => [fromActions.update(result)])
  ));

  resetTraining$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.resetTraining),
    switchMap((action) => {
      return this.httpClient.get<Invitation>(`<backendhost>/v1/my-trainings/${action.invitation.id}/${action.training.id}/reset-training`, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map((res) => {
        return {
          invitation: res
        }
      }));
    }),
    catchError(this.onError.bind(this)),
    switchMap(result => [fromActions.update(result)])
  ));

  startOpen$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.startOpen),
    switchMap((action) => {
      return this.httpClient.get<Invitation>(`<backendhost>/v1/my-trainings/${action.id}/0/start-open`, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap(result => [fromActions.setOne({invitation: result})])
  ));
}

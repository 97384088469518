<div class="content-header pt-0">
  <!-- <div class="row mb-2">
    <div class="col-sm-6">
        <h1 class="m-0 text-dark">{{(folder | async)?.title}}</h1>
    </div>
    <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
            <li *ngFor="let bread of (folder | async)?.path" class="breadcrumb-item"><a [routerLink]="['/kb', bread.id]">{{bread.title}}</a></li>
        </ol>
    </div>
  </div> -->
  <div checkRolePermition="kb.admin" class="row">
    <div class="col-12 text-right">
      <!-- <a [routerLink]="['/structure/admin']" class="btn btn-primary">
        <i class="fas fa-tools fa-fw"></i> Zarządzaj strukturą
      </a> -->
      <div ngbDropdown class="d-inline-block">
        <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
          <i class="fas fa-tools fa-fw"></i> Zarządzaj
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <a ngbDropdownItem checkRolePermition="kb.admin.folders"  [routerLink]="['admin']"><i class="fas fa-folder-open fa-fw"></i> Folderami</a>
          <a ngbDropdownItem checkRolePermition="kb.admin.articles" [routerLink]="['admin/articles']"><i class="fas fa-newspaper fa-fw"></i> Artykułami</a>
          <a ngbDropdownItem checkRolePermition="kb.admin.files"    [routerLink]="['admin/files']"><i class="fas fa-upload fa-fw"></i> Plikami</a>
          <a ngbDropdownItem checkRolePermition="kb.admin.folders"  [routerLink]="['admin/edit',(folder | async)?.id]"><i class="fas fa-edit fa-fw"></i> Edytuj folder</a>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="content">
  <div class="container-fluid">
    <router-outlet></router-outlet>
  </div>
</section>
<!-- <pre>{{folder | async | json}}</pre> -->

import { User } from '@/model/user.model';
import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {


  transform(image): string {
    if (!image)
      return null;
    if(typeof image === 'object')
      return `${environment.apiUrl}/thumb/1280x0/${image.path.replace('public','')}`;
    else if(typeof image === 'string' && image.indexOf('data:image') == 0)
      return image;
    else
      return `${environment.apiUrl}/thumb/1280x0/${image.replace('public','')}`;

  }

}

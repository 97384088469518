import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as fromGroupReducer from '@/store/group/group.reducers';
import * as fromGroupActions from '@/store/group/group.actions';
import { select, Store } from '@ngrx/store';
import { combineLatest, fromEvent, Observable, OperatorFunction, Subject } from 'rxjs';
import { Group } from '@/model/group.model';
import { debounceTime, distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';
import { User } from '@/model/user.model';
import { AuthService } from '@/auth/auth.service';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  allGroups: Group[];
  user: User;
  page = 1;
  pageSize = 10;
  searchTerm: string = "";

  constructor(
    private store: Store<fromGroupReducer.State>,
    private authService: AuthService
  ) {
    authService.getUser().then(user => {this.user = user;});
  }

  get groups(): Group[] {
    return this.searchTerm ? this.allGroups.filter( group => group.name.toLowerCase().indexOf(this.searchTerm) >= 0) : this.allGroups;
  }

  get loading(): Observable<boolean> {
    return this.store.pipe(select(fromGroupReducer.selectLoading));
  }

  get paginator(): Observable<Group[]> {
    return this.store.pipe(
      select(fromGroupReducer.selectPage, this.page),
      tap( (result) => {
        combineLatest([
          this.store.select(fromGroupReducer.selectCount),
          this.store.select(fromGroupReducer.selectCurrentFetchPage),
        ])
        .subscribe(([total,currentFetchPage]) => {
          if(total != 0 && result.length == 0 && this.page != currentFetchPage) {
            console.log("DISPATCH");
            this.store.dispatch(fromGroupActions.fetchFullAction({page: this.page, search: this.searchTerm}));
          }
        })
      }),
    );
    // return this.groups.slice( (this.page-1) * this.pageSize, this.page * this.pageSize );
  }

  get total(): Observable<number> {
    return this.store.pipe(select(fromGroupReducer.selectCount));
  }

  ngOnInit(): void {
    this.store.dispatch(fromGroupActions.fetchFullAction({page:1, search: this.searchTerm}));

    this.store.pipe(select(fromGroupReducer.selectAllGroups)).subscribe( (groups: Group[]) => {
      this.allGroups = groups;
      this.allGroups = groups;
    });

    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      map((event: any) => event.target.value),
      filter( (res:string) => res.length > 2),
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((text: string) => {
      if(text.toLowerCase().trim() == this.searchTerm) return;
      this.searchTerm = text.toLowerCase().trim();
      this.page = 1;
      this.store.dispatch(fromGroupActions.clear());
      console.log("SEARCH", this.searchTerm);
      this.store.dispatch(fromGroupActions.fetchFullAction({page: this.page, search: this.searchTerm}));
    });
  }

  clearSearch() {
    this.searchTerm = "";
    this.store.dispatch(fromGroupActions.clear());
    this.store.dispatch(fromGroupActions.fetchFullAction({page: this.page, search: this.searchTerm}));
  }

  identify(index, group: Group){ return group.id }

  canJoin(group: Group) {
    return !(group.superior && this.user.role !== 'admin')
  }

  canLeave(group: Group): boolean {
    return GroupsComponent.canLeaveStatic(group, this.user);
  }

  static canLeaveStatic(group: Group, user: User): boolean {
    if (!user) return false;
    return !(group.superior && user.role !== 'admin');// && group.membership.canLeave;
  }

  join(group: Group): void {
    this.store.dispatch(fromGroupActions.joinGroup({group: group }));
  }

  leave(group: Group): void {
    this.store.dispatch(fromGroupActions.deleteGroupMemberAction({group: group, member: group.membership}));
  }

  observe(group: Group, observe: boolean): void {
    this.store.dispatch(fromGroupActions.observeGroupAction({group: group, member: group.membership, observe: observe}));
  }

  delete(group: Group): void {
    this.store.dispatch(fromGroupActions.deleteGroupAction({group: group}));
  }

  canUnobserve(group: Group) {
    return this.user.role != 'user';
  }

  canViewPosts(group: Group) {
    return this.user.role != 'user' || group.membership;
  }


  canEdit(item: Group): boolean {
    if(!this.user)
      return false;
    if(this.user.role == 'admin')
      return true;
    if(this.user.id == item.createdBy?.id)
      return true;
    return false;
  }
}

<div class="modal-header">
  <div class="input-group"  *ngIf="!prize">
    <input type="text" name="table_search" class="form-control float-right" placeholder="Wyszukaj nagrody" #searchInput>
    <button class="btn bg-transparent" style="position:absolute; right: 0; transform: translate(-100%,0);" (click)="clearSearch()">
      <i class="fa fa-times"></i>
    </button>
    <span class="input-group-append">
      <div class="input-group-text bg-transparent"><i class="fas fa-search"></i></div>
    </span>
  </div>
  <img class="img-circle mr-2" [src]="prize | groupAvatar:45" *ngIf="prize">
  <h3 *ngIf="prize">{{prize.title}}</h3>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-0" *ngIf="!prize">
  <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(paginator | async).length == 0"></nz-empty>
  <div class="overlay" *ngIf="(loading | async)" style='background: rgba(255,255,255,0.7);'>
    <div style="
      position: sticky;
      top:50%;
      left:50%;
      align-self: baseline;
      transform: translate(-50%, 0%);
      text-align: center;
      padding: 25px 0">
      <i class="fas fa-3x fa-spinner fa-spin"></i>
      <p>Ładowanie...</p>
    </div>
  </div>
  <span
  class="list-group-item list-group-item-action"
  *ngFor="let item of paginator | async; let idx = index; trackBy: identify">
    <div class="user-block float-none d-flex">
      <img class="img-circle" [src]="item | groupAvatar" [alt]="item.title">
      <div class="flex-grow-1">
        <span class="username ml-3 mr-3">{{ item.title }}</span>
        <span class="description ml-3 mr-3">{{ item.short }}</span>
      </div>
      <div>
        <a class="btn btn-success" (click)="selectPrize(item)">
          <i class="fas fa-fw fa-plus"></i>
        </a>
      </div>
    </div>
  </span>
</div>
<div class="modal-footer justify-content-start" *ngIf="!prize">
  <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="total | async" (pageChange)="pageChange($event)"> -->
    <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
    <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
    <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
    <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
    <ng-template ngbPaginationEllipsis>...</ng-template>
    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
  </ngb-pagination>
</div>
<form [formGroup]="form" (ngSubmit)="save()" *ngIf="prize">
  <div class="overlay" *ngIf="saving">
    <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
      <i class="fas fa-3x fa-spinner fa-spin"></i>
      <p>Zapisywanie...</p>
    </div>
  </div>
  <div class="modal-body">
    <div [innerHtml]="prize.description | safeHtml"></div>
    <div class="form-group">
      <label for="comment">Komentarz</label>
      <app-editor formControlName="comment"></app-editor>
      <div class="invalid-feedback" *ngIf="form.controls.comment.errors?.required">
        Pole jest wymagane
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" type="submit">
      <i class="fas fa-check fa-fw"></i>
      Przyznaj nagrode
    </button>
  </div>
</form>

import { Component, OnDestroy, OnInit } from '@angular/core';
import * as fromKbActions from '@/store/kb/kb.actions';
import * as fromKbReducers from '@/store/kb/kb.reducers';
import { select, Store } from '@ngrx/store';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Folder } from '@/model/folder.model';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'app-kb',
  templateUrl: './kb.component.html',
  styleUrls: ['./kb.component.scss']
})
export class KbComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  private id: number;

  constructor(
    private store: Store<fromKbReducers.State>,
    private activatedRoute: ActivatedRoute,
    protected actions$: Actions,
  ) { }

  ngOnInit(): void {
    // this.activatedRoute.paramMap.pipe(
    //   map((params: ParamMap) => parseInt(params.get('folder')))
    // ).subscribe( (id: number) => {
    //   this.store.dispatch(fromKbActions.clearFiles());
    //   this.store.dispatch(fromKbActions.fetchFolder({id}));
    //   this.store.dispatch(fromKbActions.fetchFiles({id}));
    //   this.store.dispatch(fromKbActions.fetchArticles({id}));


    //   this.actions$.pipe(takeUntil(this.unsubscribe$),ofType(fromKbActions.updateSelf)).subscribe( () => {
    //     this.store.dispatch(fromKbActions.fetchFolder({id}));
    //   })
    // })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get folder(): Observable<Folder>  {
    return this.store.pipe(takeUntil(this.unsubscribe$),select(fromKbReducers.selectFolder));
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ofType } from '@ngrx/effects';
import { Store, ActionsSubject } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fromQuizAction from '@/store/quiz/quiz.actions';
import * as fromQuizReducers from '@/store/quiz/quiz.reducers';
import { Quiz } from '@/model/quiz.model';

@Component({
  selector: 'app-quiz-add',
  templateUrl: './quiz-add.component.html',
  styleUrls: ['./quiz-add.component.scss']
})
export class QuizAddComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  quiz: Quiz;
  constructor(
    private toastr: ToastrService,
    private store: Store<fromQuizReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {}


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  save(): void {
    if (this.form.valid) {
      this.quiz = {...this.quiz, ...this.form.value};
      this.store.dispatch(fromQuizAction.createQuiz({quiz: this.quiz}));
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromQuizAction.addQuiz.type)
      ).subscribe( (action: any) => {
        this.toastr.success('Dodano nowy quiz');
        this.router.navigate(['/training/admin/quiz', action.quiz.id, 'edit']);
      });
  } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
  }
  }

}


<a class="btn btn-primary btn-block mb-2" routerLink="/news/add"  checkRolePermition="news.create">
  <i class="nav-icon fas fa-plus fa-fw align-slef-center"></i> Nowy wpis
</a>
<a class="user-panel pt-2 pb-2 d-flex align-items=center"
  routerLinkActive="bg-primary" [routerLinkActiveOptions]="{ exact: true }"
  style="margin: 0 -8px; padding: 0 8px;"
  [routerLink]="['/news']"
>
  <div class="image position-relative">
    <img src="/assets/img/default-profile.png" class="img-circle elevation-2" alt="User Image"/>
  </div>
  <div class="info" style="line-height: 1em; margin-top: -0.2rem;">
    Wszystkie <br/>
    <small class="text-xs"> ... </small>
  </div>
</a>
<a class="user-panel pt-2 pb-2 d-flex align-items=center"
  routerLinkActive="bg-primary" [routerLinkActiveOptions]="{ exact: true }"
  style="margin: 0 -8px; padding: 0 8px;"
  [routerLink]="['/news','observed']"
>
  <div class="image position-relative">
    <img src="/assets/img/default-profile.png" class="img-circle elevation-2" alt="User Image"/>
  </div>
  <div class="info" style="line-height: 1em; margin-top: -0.2rem;">
    Obserwowane <br/>
    <small class="text-xs"> ... </small>
  </div>
</a>
<a class="user-panel pt-2 pb-2 d-flex align-items=center"
  routerLinkActive="bg-primary"
  style="margin: 0 -8px; padding: 0 8px;"
  [routerLink]="['/news', 'to-all']"
>
  <div class="image position-relative">
    <img src="/assets/img/default-profile.png" class="img-circle elevation-2" alt="User Image"/>
    <!-- <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary mr-3 mt-2"  *ngIf="group.unseenCount">
      {{group.unseenCount}}
    </span> -->
  </div>
  <div class="info" style="line-height: 1em; margin-top: -0.2rem;">
    Do wszystkich <br/>
    <small class="text-xs"> ... </small>
  </div>
</a>

<a class="user-panel pt-2 pb-2 d-flex align-items=center"
  routerLinkActive="bg-primary"
  style="margin: 0 -8px; padding: 0 8px;"
  [routerLink]="['/news', 'favorite']"
>
  <div class="image position-relative">
    <img src="/assets/img/default-profile.png" class="img-circle elevation-2" alt="User Image"/>
    <!-- <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary mr-3 mt-2"  *ngIf="group.unseenCount">
      {{group.unseenCount}}
    </span> -->
  </div>
  <div class="info" style="line-height: 1em; margin-top: -0.2rem;">
    Ulubione <br/>
    <small class="text-xs"> ... </small>
  </div>
</a>

<a class="user-panel pt-2 pb-2 d-flex align-items=center"
  routerLinkActive="bg-primary"
  style="margin: 0 -8px; padding: 0 8px;"
  *ngFor="let group of groups"
  [routerLink]="['/groups', group.id, 'news']"
>
  <div class="image position-relative">
    <img [src]="group | groupAvatar" class="img-circle elevation-2" alt="User Image"/>
    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary mr-3 mt-2"  *ngIf="group.unseenCount">
      {{group.unseenCount}}
    </span>
  </div>
  <div class="info" style="line-height: 1em; margin-top: -0.2rem;">
    {{ group.name }} <br/>
    <small class="text-xs">{{ group.name }}</small>
  </div>
</a>

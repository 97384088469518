import { Slide } from '@/model/multimedia.model';
import { TrainingResult } from '@/model/package.model';
import { youtubeExtract } from '@/utils/youtube.validator';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { environment } from 'environments/environment';
import { of, Subject, timer } from 'rxjs';
import { takeUntil, tap, timeout } from 'rxjs/operators';

@Component({
  selector: 'app-youtube-player',
  templateUrl: './youtube-player.component.html',
  styleUrls: ['./youtube-player.component.scss']
})
export class YoutubePlayerComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  player: YT.Player;
  @Input() slide: Slide;
  @Input() result: TrainingResult;
  @Output() onFinish: EventEmitter<TrainingResult> = new EventEmitter<TrainingResult>();

  constructor() {}

  ngOnInit(): void {
    timer(this.slide.completeTimeout * 1000).pipe(takeUntil(this.unsubscribe$)).subscribe(this.finish.bind(this));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  finish() {
    this.result = {
      ...this.result,
      ... {
        completed: true,
        completedOn: this.result.completedOn ? this.result.completedOn : new Date(),
        passed: true
      }
    }
    this.onFinish.emit(this.result);
  }

  public get id(): string {
    return youtubeExtract(this.slide.url);
  }

  savePlayer(player) {
    this.player = player;
    // console.log("player instance", player);
  }
  onStateChange(event) {
    // console.log("player state", event.data);
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import * as fromGroupReducer from '@/store/group/group.reducers';
import * as fromGroupActions from '@/store/group/group.actions';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { map, take, takeUntil } from 'rxjs/operators';
import { Group } from '@/model/group.model';
import { tinyMceConfig } from '@/config/tinymce.config';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-edit-group',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.scss']
})
export class EditGroupComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  group: Group;
  tinyMceConfig = tinyMceConfig;
  uploading = false;


  constructor(
    private toastr: ToastrService,
    private store: Store<fromGroupReducer.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      superior: new FormControl(null),
      content: new FormControl(null),
    });
    this.route.paramMap.pipe(
      map((params: ParamMap) => parseInt(params.get('id')))
    ).subscribe( (id: number) => {
      this.store.dispatch(fromGroupActions.fetchOneAction({id}));
      this.store.pipe(takeUntil(this.unsubscribe$),select(fromGroupReducer.selectEntity, id)).subscribe((group: Group | null) => {
        this.group = group;
        this.form.patchValue(group);
      })
    })
  }

  handleImageUploadChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.uploading = true;
        break;
      case 'done':
        let update = {...this.group,...this.form.value, ...{ image: info.file.response.image}};
        console.log(update);
        this.store.dispatch(fromGroupActions.updateGroupAction({group: update}));
        this.uploading = false;
        break;
      case 'error':
        this.toastr.error('Błąd uploadu pliku');
        this.uploading = false;
        break;
    }
  }

  handleBackgroundUploadChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.uploading = true;
        break;
      case 'done':
        let update = {...this.group,...this.form.value, ...{ background: info.file.response.background}};
        this.store.dispatch(fromGroupActions.updateGroupAction({group: update}));
        this.uploading = false;
        break;
      case 'error':
        this.toastr.error('Błąd uploadu pliku');
        this.uploading = false;
        break;
    }
  }

  save(): void {
  //   console.log(this.form.valid);
    if (this.form.valid) {
      let payload = Object.assign(Object.assign({}, this.group), this.form.value);
      this.store.dispatch(fromGroupActions.editGroupAction({group: payload}));
      this.toastr.success('Zapisano!');
  } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
  }
  }

}

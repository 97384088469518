<form [formGroup]="form" (ngSubmit)="save()">
<div class="modal-header" *ngIf="slide">
  <h4 class="modal-title">Slajd [{{SlideType(slide)}}]</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div *ngIf="slide" class="modal-body row">


  <div class="form-group col-md-12">
    <label>Tytuł</label>
    <input type="text" class="form-control" placeholder="Tytuł" formControlName="name" [appIsValid]="form.controls.name"/>
    <div class="invalid-feedback" *ngIf="form.controls.name.errors?.required">Pole jest wymagane</div>
  </div>


  <div class="form-group col-md-4" *ngIf="['content','youtube','video'].indexOf(slide.type) >= 0">
    <label>Minimalny czas spędzony na danym slajdzie</label>
    <div class="input-group">
      <input min="0"  class="form-control" type="number" formControlName="completeTimeout" [appIsValid]="form.controls.completeTimeout"/>
      <span class="input-group-text">
        sekund
      </span>
      <div class="invalid-feedback" *ngIf="form.controls.completeTimeout.errors?.required">Pole jest wymagane</div>
    </div>
  </div>


  <div class="form-group col-md-12" *ngIf="slide.type == 'youtube'" >
    <label>Url filmu youtube</label>
    <input type="text" class="form-control" placeholder="Url filmu youtube" formControlName="url" [appIsValid]="form.controls.url"/>
    <div class="invalid-feedback" *ngIf="form.controls.url.errors?.required">Pole jest wymagane</div>
    <div class="invalid-feedback" *ngIf="form.controls.url.errors?.youtubeUrl">Nieprawidłowy adres filmu</div>
  </div>



  <div class="form-group col-md-12" *ngIf="slide.type == 'video'" >
    <label>Plik Video</label>
    <div class="d-flex" style="flex-direction: column;"  *ngIf="slide.video">
      <video height="100%" controls>
        <source [src]="videoPath" type="{{slide.video.mimeType}}">
        Your browser does not support the video tag.
      </video>
    </div>
    <div>
      <nz-upload
        nzType="drag"
        [nzMultiple]="false"
        [(nzFileList)]="videoFile"
        [nzFileType]="'video/mp4,video/mpeg'"
        [nzBeforeUpload]="beforeUpload"
      >
      <div>
        <p class="ant-upload-drag-icon">
          <i nz-icon nzType="inbox"></i>
        </p>
        <p class="ant-upload-text">Kliknij lub przeciągnij plik do tego obszaru, aby przesłać</p>
        <p class="ant-upload-hint"></p>
      </div>
      </nz-upload>
    </div>
    <!-- formControlName="video" [appIsValid]="form.controls.video" -->
    <!-- <div class="invalid-feedback" *ngIf="form.controls.video.errors?.required">Pole jest wymagane</div> -->
  </div>


  <div class="form-group col-md-12" *ngIf="slide.type == 'content'"  >
    <label>Treść</label>
    <app-editor formControlName="content" [appIsValid]="form.controls.content"></app-editor>
    <div class="invalid-feedback" *ngIf="form.controls.name.errors?.required">Pole jest wymagane</div>
  </div>


  <div class="form-group col-md-12" *ngIf="slide.type == 'scorm'"  >
    <label>Szkolenie SCORM</label>
    <ng-select
        [items]="scorms$ | async"
        bindLabel="name"
        [trackByFn]="trackByFn"
        [minTermLength]="2"
        [loading]="loading"
        typeToSearchText="Please enter 2 or more characters"
        [typeahead]="scormInput$"
        formControlName="scorm"
        [appIsValid]="form.controls.scorm">>
    </ng-select>
    <div class="invalid-feedback" *ngIf="form.controls.scorm.errors?.required">Pole jest wymagane</div>
  </div>


  <div class="form-group col-md-12" *ngIf="slide.type == 'quiz'"  >
    <label>Quiz</label>
    <ng-select
        [items]="quizes$ | async"
        bindLabel="name"
        [trackByFn]="trackByFn"
        [minTermLength]="2"
        [loading]="loading"
        typeToSearchText="Please enter 2 or more characters"
        [typeahead]="quizInput$"
        formControlName="quiz"
        [appIsValid]="form.controls.quiz">>
    </ng-select>
    <div class="invalid-feedback" *ngIf="form.controls.quiz.errors?.required">Pole jest wymagane</div>
  </div>

  <div class="form-group col-md-12">
    <label>Plik Audio</label>

    <div class="d-flex" style="flex-direction: column;" *ngIf="slide.audio">
      <audio height="100%" controls>
        <source [src]="audioPath" type="{{slide.audio.mimeType}}">
        Your browser does not support the video tag.
      </audio>
    </div>
    <!-- <div *ngIf="slide.audio" plyr style="width: 100%;" [plyrPlaysInline]="true" plyrType="audio" [plyrSources]="audioPath" ></div> -->
    <div>
      <nz-upload
        nzType="drag"
        [nzMultiple]="false"
        [(nzFileList)]="audioFile"
        [nzBeforeUpload]="beforeUploadAudio"
      >
      <!-- [nzFileType]="'audio/mp3'" -->
      <div>
        <p class="ant-upload-drag-icon">
          <i nz-icon nzType="inbox"></i>
        </p>
        <p class="ant-upload-text">Kliknij lub przeciągnij plik do tego obszaru, aby przesłać</p>
        <p class="ant-upload-hint"></p>
      </div>
      </nz-upload>
    </div>
  </div>

</div>


<div class="modal-footer">
  <a type="button" class="btn btn-default" (click)="modal.dismiss('cross')">
    <i class="fas fa-times fa-fw"></i>
    Anuluj
  </a>
  <button type="button" class="btn btn-primary" type="submit">
    <i class="fas fa-save fa-fw"></i>
    Zapisz
  </button>
</div>
</form>

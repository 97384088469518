
<form [formGroup]="form" (ngSubmit)="submit()" *ngIf="result">
  <div class="list-group" >
    <div class="list-group-item" *ngFor="let question of questions; let qIdx = index">
      <div class="form-group" [formGroup]="getQuestionGroup(question)">
        <label for="inputName">
          <span class="badge badge-success text-md" *ngIf="closed && getQuestionGroup(question).controls.correct.value">Poprawna</span>
          <span class="badge badge-danger text-md" *ngIf="closed && !getQuestionGroup(question).controls.correct.value">Błędna</span>
          {{question.label}}
        </label>

        <div *ngIf="question.type == 'radio'" >
          <div class="form-check" *ngFor="let option of question.options; let aIdx = index" >
            <label class="form-check-label" [ngClass]="labelClass(option)">
              <input class="form-check-input" type="radio" formControlName="answer" [value]="option.label">
              <span>{{option.label}}</span>
              <i class="fas fa-fw" *ngIf="closed" [ngClass]="{'fa-check text-success' : option.correct, 'fa-times text-danger' : !option.correct}"></i>
            </label>
          </div>
        </div>
        <div *ngIf="question.type == 'checkbox'" >
          <div class="form-check" *ngFor="let option of question.options; let aIdx = index" formGroupName="answer">
            <label class="form-check-label" [ngClass]="labelClass(option)">
              <input class="form-check-input" type="checkbox" [formControlName]="aIdx" [value]="option.label">
              <span>{{option.label}}</span>
              <i class="fas fa-fw" *ngIf="closed" [ngClass]="{'fa-check text-success' : option.correct, 'fa-times text-danger' : !option.correct}"></i>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <pre>{{form.value | json}}</pre> -->
  <div class="card-body" *ngIf="closed">
    <div class="alert text-center" [ngClass]="{'alert-success': result.passed, 'alert-danger': !result.passed}">
      <h4>Twój Wynik:</h4>
      <h2>{{result?.scorePercent  | number}}% na {{quiz.minScore}}% wymaganych</h2>
    </div>
  </div>
  <div class="card-footer text-center">


    <button class="btn btn-success mr-1" type="submit" *ngIf="!closed">
      Zakoncz Quiz i zapisz wynik
      <i class="fas fa-angle-right fa-fw fa-lg"></i>
    </button>

    <button class="btn btn-primary ml-1" (click)="repeat()" *ngIf="closed">
      Podejdź do quizu jeszcze raz
      <i class="fas fa-redo fa-fw fa-lg"></i>
    </button>

  </div>
</form>

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, OperatorFunction, Subject } from 'rxjs';

import * as fromTrainingGroupActions from '@/store/training-group/training-group.actions';
import * as fromTrainingGroupReducers from '@/store/training-group/training-group.reducers';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TrainingGroup } from '@/model/training.group.model';
import { ofType } from '@ngrx/effects';
import { importExpr } from '@angular/compiler/src/output/output_ast';
import { User } from '@/model/user.model';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '@services/app.service';

@Component({
  selector: 'app-group-edit',
  templateUrl: './group-edit.component.html',
  styleUrls: ['./group-edit.component.scss']
})
export class GroupEditComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  pageSize = 20;
  count = 0;
  page = 1;
  form: FormGroup;
  trainingGroup: TrainingGroup;

  model: any;
  formatter = (user: User) => `${user.firstname} ${user.lastname}`;
  formatterEmpty = () => null;
  searching = false;
  searchFailed = false;

  constructor(
    private toastr: ToastrService,
    private store: Store<fromTrainingGroupReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe( (data: Data) => {
      this.trainingGroup = data.instance;
      this.form.patchValue(this.trainingGroup);
      this.store.dispatch(fromTrainingGroupActions.fetchMember({group: this.trainingGroup}));
    });
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get members(): Observable<User[]> {
    return this.store.pipe(
      select(fromTrainingGroupReducers.selectOneTrainingGroup, this.trainingGroup.id),
      map(group => group.members),
      tap( result => this.count = result ? result.length : 0),
      map(result => result ? result.slice( (this.page-1) * this.pageSize, this.page * this.pageSize ) : [])
    );
  }

  save(): void {
    if (this.form.valid) {
      this.trainingGroup = {...this.trainingGroup, ...this.form.value};
      this.store.dispatch(fromTrainingGroupActions.saveTrainingGroup({item: this.trainingGroup}));
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromTrainingGroupActions.updateTrainingGroup.type)
      ).subscribe( (result: any) => {
        this.toastr.success('Zapisano zmiany');
        this.router.navigate(['/training/admin/group']);
      });
    } else {
        this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }

  onPageChange() {
    // this.groupObserver.next(this.group)
  }

  deleteMemeber(user: User) {
    this.store.dispatch(fromTrainingGroupActions.deleteMember({user: user, group: this.trainingGroup}));
  }

  groupMemberIdentity(index, item) {
    return item.id;
  }

  onUserSelect(selectedItem: NgbTypeaheadSelectItemEvent) {
    let user: User = selectedItem.item;
    this.store.dispatch(fromTrainingGroupActions.addMember({user: user, group: this.trainingGroup}));
    // this.store.pipe(select(fromGroupReducer.selectEntity, this.group.id), take(1)).subscribe((group: Group | null) => {
    //   this.group = group;
    // })
  }

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.appService.searchUser(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),

      tap(() => this.searching = false)
    )
}


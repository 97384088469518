import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs'


@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private httpClient: HttpClient,
  ) {
    this.angularFireMessaging.messages.subscribe((payload) => {
      console.log("new message received. ", payload);
      this.currentMessage.next(payload);
    })
    // this.angularFireMessaging.messages.subscribe( (_messaging) => {
    //   _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //   _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    // })
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        // @ts-ignore.
        let data = {...{token:token}, ...WURFL};
        if (token) {
            // // @ts-ignore.
            // var uuid = new DeviceUUID().get();
            // // @ts-ignore.
            // var test = new DeviceUUID().parse();
            // console.log(uuid, test);
            this.httpClient.post<any>(`<backendhost>/v1/auth/push-token`, data).subscribe(
              (resonse) => {},
              (err) => {}
            );
        }
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      });
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      console.log("new message received. ", payload);
      this.currentMessage.next(payload);
    })
  }
}

import { PaginationDTO } from "@/model/pagination.model";
import { Survey, SurveyQuestion } from "@/model/survey.model";
import { StringifyHttpErrorResponse } from "@/utils/http.error.util";
import { plainText } from "@/utils/plainText";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { catchError, map, switchMap } from "rxjs/operators";
import * as fromActions from "./survey.actions";
import * as fromReducers from "./survey.reducers";

@Injectable()
export class SurveyEffects {
  state: fromReducers.State;

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private store$: Store<fromReducers.State>,
  ) {
    this.store$.pipe(select(fromReducers.selectState)).subscribe((state: fromReducers.State) => {
      this.state = state;
    })
  }

  onError (err, caught): any {
    this.toastr.error(StringifyHttpErrorResponse(err));
    return caught;
  };

  paginatorChange$ = createEffect(() => this.actions$.pipe(
    ofType(...[
      fromActions.setPage,
      fromActions.setSearch,
      fromActions.setOrder,
      fromActions.setDirection
    ]),
    map( () => fromActions.fetch())
  ));

  fetch$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetch),
    switchMap(() => {
      let params = new HttpParams();
      params = params.set('skip', this.state.limit * (this.state.page - 1));
      params = params.set('limit', this.state.limit);
      params = params.set('order', this.state.order);
      params = params.set('archive', +this.state.archive);
      params = params.set('direction', this.state.asc ? 'asc' : 'desc');
      if(this.state.search)
        params = params.set('search', this.state.search);
      return this.httpClient.get<PaginationDTO<Survey>>(`<backendhost>/v1/survey`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: PaginationDTO<Survey>) => [fromActions.setManySurvey(response)])
  ));

  fetchOne$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchOne),
    switchMap((action) => {
      return this.httpClient.get<Survey>(`<backendhost>/v1/survey/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((survey: Survey) => [fromActions.addSurvey({survey})])
  ));

  createSurvey$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createSurvey),
    switchMap((action) => {
      const formData = new FormData();
      if(action.image) {
        formData.append('image', action.image, action.image.name);
      }
      if(action.background) {
        formData.append('background', action.background, action.background.name);
      }
      let survey = {
        ...action.survey,
        ...{short: plainText(action.survey.content)}
      }

      formData.append('data', JSON.stringify(survey));
      return this.httpClient.post<Survey>(`<backendhost>/v1/survey`, formData, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((survey: Survey) => [fromActions.addSurvey({survey})])
  ));

  cloneSurvey$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.cloneSurvey),
    switchMap((action) => {
      return this.httpClient.post<Survey>(`<backendhost>/v1/survey/${action.id}/clone`, {}, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((survey: Survey) => [fromActions.addSurvey({survey})])
  ));

  updateSurvey$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.updateSurvey),
    switchMap((action) => {
      const formData = new FormData();
      if(action.image) {
        formData.append('image', action.image, action.image.name);
      }
      if(action.background) {
        formData.append('background', action.background, action.background.name);
      }
      let survey = {
        ...action.survey,
        ...{short: plainText(action.survey.content)}
      }

      formData.append('data', JSON.stringify(survey));
      return this.httpClient.post<Survey>(`<backendhost>/v1/survey/${action.survey.id}/update`, formData, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((survey: Survey) => [fromActions.setSurvey({survey})])
  ));

  publishSurvey$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.publishSurvey),
    switchMap((action) => {
      return this.httpClient.post<Survey>(`<backendhost>/v1/survey/${action.survey.id}/publish`, action.survey, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((survey: Survey) => [fromActions.setSurvey({survey})])
  ));

  deleteSurvey$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deleteSurvey),
    switchMap((action) => {
      return this.httpClient.delete<any>(`<backendhost>/v1/survey/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map(() => action.id));
    }),
    catchError(this.onError.bind(this)),
    switchMap((id: number) => [fromActions.removeSurvey({id})])
  ));

  createSurveyQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createSurveyQuestion),
    switchMap((action) => {
      return this.httpClient.post<SurveyQuestion>(`<backendhost>/v1/survey/${action.survey.id}/create-question`, action.question, {
        observe: 'body',
        responseType: 'json'
      }).pipe(
        map(question => {
          return {
            survey: action.survey,
            question: question
          }
        })
      );
    }),
    catchError(this.onError.bind(this)),
    switchMap((result) => [fromActions.addSurveyQuestion(result)])
  ));

  updateSurveyQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.updateSurveyQuestion),
    switchMap((action) => {
      return this.httpClient.post<SurveyQuestion>(`<backendhost>/v1/survey/${action.survey.id}/create-question`, action.question, {
        observe: 'body',
        responseType: 'json'
      }).pipe(
        map(question => {
          return {
            survey: action.survey,
            question: question
          }
        })
      );
    }),
    catchError(this.onError.bind(this)),
    switchMap((result) => [fromActions.setSurveyQuestion(result)])
  ));

  deleteSurveyQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deleteSurveyQuestion),
    switchMap((action) => {
      return this.httpClient.get<SurveyQuestion>(`<backendhost>/v1/survey/${action.question.id}/delete-question`, {
        observe: 'body',
        responseType: 'json'
      }).pipe(
        map(question => {
          return {
            survey: action.survey,
            question: action.question
          }
        })
      );
    }),
    catchError(this.onError.bind(this)),
    switchMap((result) => [fromActions.removeSurveyQuestion(result)])
  ));

  archiveSurvey$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.archiveSurvey),
    switchMap((action) => {
      return this.httpClient.get<Survey>(`<backendhost>/v1/survey/${action.id}/archive`,
          {
            observe: 'body',
            responseType: 'json'
          }).pipe(map(()=> action.id));
    }),
    catchError(this.onError.bind(this)),
    switchMap((id: number) => [fromActions.removeSurvey({id})])
  ));
}

<div class="card-header d-flex align-items-center">
  <h3 class="card-title m-0 flex-grow-1 text-bold"><i class="fas fa-bell fa-fw"></i> Powiadomienia</h3>
</div>
<div class="table-responsive">
  <table class="table align-middle table-hover">
    <thead>
      <tr>
        <th style="width:1rem">#</th>
        <th class="">Wysyłka</th>
        <th class="text-center">Data wysyłki</th>
        <th class="text-center">Email</th>
        <th class="text-center">Push</th>
        <th class="text-center">SMS</th>
        <th class="text-center">Wysłane</th>
        <th class="text-right" >
          <button  checkRolePermition="training.admin.scorm" class="btn btn-success btn-sm elevation-2 text-nowrap" (click)="add()" >
            <i class="fas fa-plus fa-fw fa-lg"></i> Dodaj
          </button>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of list | async; let idx = index">
        <th class="align-middle">{{idx + 1}}</th>

        <td class="align-middle" colspan="2" *ngIf="item.first">
          zaproszenie wysyłane natychmiast
        </td>
        <td class="align-middle"  *ngIf="!item.first">
          <div class="input-group">
            <input class="form-control"
                #dayInput
                [disabled]="item.sended"
                type="number"
                min="1"
                style="max-width:6rem"
                [ngModel]="item.daysBefore"
                (ngModelChange)="update('daysBefore', $event, item)"
                [ngModelOptions]="{ updateOn: 'blur' }">
            <div class="input-group-text text-sm">
              dni przed końcem szkolenia
            </div>
          </div>
        </td>
        <td  *ngIf="!item.first">
          {{sendDate(idx) | async | amUtc | amLocal | amDateFormat:'YYYY-MM-DD'}}
        </td>
        <td class="align-middle">
          <input class="form-control text-xs"
            [disabled]="item.sended"
            type="checkbox"
            [ngModel]="item.email"
            (ngModelChange)="update('email', $event, item)" >
        </td>
        <td class="align-middle">
          <input class="form-control text-xs"
            [disabled]="item.sended"
            type="checkbox"
            [ngModel]="item.push"

            (ngModelChange)="update('push', $event, item)" >
        </td>
        <td class="align-middle">
          <input class="form-control text-xs"
            [disabled]="item.sended"
            type="checkbox"
            [ngModel]="item.sms"
            nz-popconfirm
            [nzPopconfirmTitle]="'Czy na pewno wysłac ' + package?.current.invited?.total + ' SMSów?'"
            nzOkText="Tak"
            nzCancelText="Nie"
            nzPopconfirmPlacement="left"

            [nzCondition]="item.sms && package?.current.invited?.total"
            (nzOnConfirm)="updateSms(item)" >
        </td>

        <td class="text-center">
          <i class="fas fa-check fa-2x text-success" *ngIf="item.sended"></i>
          <i class="fas fa-times fa-2x text-danger" *ngIf="!item.sended"></i>
        </td>
        <td class="text-right">
          <a *ngIf="!item.first && !item.sended"
              class="btn btn-danger btn-sm elevation-2"
              nz-popconfirm
              nzPopconfirmTitle="Czy na pewno usunąć?"
              nzOkText="Tak"
              nzCancelText="Nie"
              nzPopconfirmPlacement="left"
              ngbTooltip="Usuń"
              (nzOnConfirm)="delete(item)" >
            <i class="fas fa-trash fa-fw fa-lg"></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>

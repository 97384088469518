import { signoutAction } from "./auth/auth.actions";

export function clearState(reducer) {
  return function (state, action) {

    // console.log("clearState", action.type, signoutAction.type);
    if (action.type === signoutAction.type) {
      state = undefined;
    }

    return reducer(state, action);
  };
}

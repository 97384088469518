<div class="card" style="border-top-right-radius:0;border-top-left-radius:0;">
  <div class="overlay" *ngIf="(loading | async)">
    <div style="position: sticky; text-align: center">
      <i class="fas fa-3x fa-spinner fa-spin"></i>
      <p>Ładowanie...</p>
    </div>
  </div>
  <div class="card-header d-flex align-items-center" style="border-top-right-radius:0;border-top-left-radius:0;">
    <h3></h3>
    <div class="card-tools flex-grow-1">
      <div class="input-group">
        <input type="text" name="table_search" class="form-control float-right" placeholder="Szukaj" #searchInput>
        <button class="btn bg-transparent" style="position:absolute; right: 0; transform: translate(-100%,0);z-index: 100;" (click)="clearSearch()">
          <i class="fa fa-times"></i>
        </button>
        <span class="input-group-append">
          <div class="input-group-text bg-transparent"><i class="fas fa-search"></i></div>
        </span>
      </div>
    </div>
  </div>
  <form [formGroup]="form">
    <div class="card-footer">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Status </label>
            <div class="form-check">
              <input class="form-check-input" type="radio" formControlName="status" value="open" id="statusOpen">
              <label class="form-check-label" for="typeInvited"> Otwarte</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" formControlName="status" value="close" id="statusClosed">
              <label class="form-check-label" for="typeStarted"> Zamknięte</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" formControlName="status" value="*" id="statusAll">
              <label class="form-check-label" for="typeUncompleted"> Wszystkie</label>
            </div>
          </div>
        </div>
        <!-- <div class="col">
          <div class="form-group">
            <label>Data zaproszenia</label>
            <div class="input-group">
              <div class="input-group-text">
                <input type="checkbox" formControlName="invitationRangeOn">
              </div>
              <nz-range-picker formControlName="invitationRange" [nzDisabled]="!this.form.controls.invitationRangeOn.value"></nz-range-picker>
            </div>
            <div class="text-danger" *ngIf="form.controls.invitationRange.errors?.dateNotValid">
              Data jest nieprawidłowa
            </div>
          </div>
          <div class="form-group">
            <label>Data ukończenia</label>
            <div class="input-group">
              <div class="input-group-text">
                <input type="checkbox" formControlName="completedRangeOn">
              </div>
              <nz-range-picker formControlName="completedRange" [nzDisabled]="!this.form.controls.completedRangeOn.value"></nz-range-picker>
            </div>
            <div class="text-danger" *ngIf="form.controls.completedRange.errors?.dateNotValid">
              Data jest nieprawidłowa
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </form>
  <div class="table-responsive">
    <!--class="table-responsive no-more-tables"-->
    <!-- <pre>{{order | async | json }}</pre> -->
    <table class="table align-middle table-hover">
      <thead>
        <tr>
          <th style="width:1rem">#</th>
          <th>Tytuł</th>
          <th>Kategoria</th>
          <th>Status</th>
          <th>Przypisany do</th>
          <th>Utworzony przez</th>
          <th>Data utworzenia</th>
          <th>Data aktualizacji</th>

          <!-- <th class="buttons text-right" ></th> -->
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let item of list | async; let idx = index; trackBy: identify"
          [routerLink]="['/redmine/issue', item.id]"
          style="cursor: pointer; position:relative"
         [class.table-info]="me.email == item.assigned_to?.email">
          <th class="align-middle hidden-mobile">{{idx + 1 + pageSize*(page-1)}}</th>
          <td data-title="Tytuł" class="align-middle">{{item.subject}}</td>
          <td data-title="Tytuł" class="align-middle">{{item.category}} / {{item.subCategory}}</td>
          <td data-title="Status" class="align-middle">{{item.status.name}}</td>
          <td data-title="Przypisany do" class="align-middle">
            <div class="d-flex">
              <img [src]="item?.assigned_to | userAvatar" class="img-circle elevation-2 mr-2" alt="User Image" *ngIf="item?.assigned_to" [appUserDisactivatable]="item?.assigned_to"/>
              <div class="info flex-grow-1">
                <strong [appUserDisactivatable]="item?.assigned_to">{{ item?.assigned_to?.name }}</strong> <br/>
                <span [appUserDisactivatable]="item?.assigned_to">{{ item?.assigned_to?.actibe ? item?.assigned_to?.email : '-' }}</span>
              </div>
            </div>
          </td>
          <td data-title="Utworzony przez" class="align-middle">
            <div class="d-flex">
              <img [src]="item?.author | userAvatar" class="img-circle elevation-2 mr-2" alt="User Image" *ngIf="item?.author" [appUserDisactivatable]="item?.author"/>
              <div class="info flex-grow-1">
                <strong [appUserDisactivatable]="item?.author">{{ item?.author?.name }}</strong> <br/>
                <span [appUserDisactivatable]="item?.author">{{ item?.author?.active ? item?.author?.email : '-' }}</span>
              </div>
            </div>
          </td>
          <td data-title="Data utworzenia" class="align-middle">{{item?.created_on | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm'}}</td>
          <td data-title="Data utworzenia" class="align-middle; position: relative">
            <div class="position: relative">
              {{item?.updated_on | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm'}}

              <div class="ribbon-wrapper ribbon-sm" *ngIf="hasNotification(item) | async" style="right:0">
                <div class="ribbon bg-orange text-sm">
                  UPDATE
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(total | async) == 0"></nz-empty>
  </div>
  <div class="card-footer" >
    <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="total | async" (pageChange)="pageChange($event)">
      <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationEllipsis>...</ng-template>
      <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>
  </div>
</div>

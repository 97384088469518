import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { from, Observable, of } from 'rxjs';
import * as fromKbReducers from '@/store/kb/kb.reducers';
import * as fromKbActions from '@/store/kb/kb.actions';
import { KbArticle } from '@/model/article.model';
import { AuthService } from '@/auth/auth.service';
import { OrderForm } from '@/model/order.form.model';
import { Store, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { tap, filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class KbArticleResolver implements   Resolve<KbArticle> {
  constructor(
    private store: Store<fromKbReducers.State>,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  check(resolve: any, instance : any, route: ActivatedRouteSnapshot) {
    this.authService.checkPermition(route.data.permition, instance).then( (alowed:boolean) => {
      if (alowed) {
        resolve(instance);
      } else {
        // resolve(null);
        this.toastr.error("Nie masz uprawnień do tej sekcji!");
        this.router.navigate(["/"]);
      }
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<KbArticle> {
    console.log(route);
    return from( new Promise<KbArticle>(resolve => {
      let id = 1*route.params.id;
      this.store.pipe(
        select(fromKbReducers.selectArticle, id),
        tap( (result) => {
          console.log();
          if(!result) {
            this.store.pipe(select(fromKbReducers.selectFolder), filter((result) => result != null)).subscribe(folder => {
              console.log(folder);
              this.store.dispatch(fromKbActions.fetchArticles({id: folder.id}));
            })
          }
        }),
        filter((result) => result != null),
        take(1),
      ).subscribe(instance => this.check(resolve, instance, route));
    }));
  }
}

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromPrizesActions from '@/store/prizes/prizes.actions';
import * as fromPrizesReducers from '@/store/prizes/prize.reducers';
import * as fromUsersActions from '@/store/users/users.actions';
import * as fromUsersReducers from '@/store/users/users.reducers';
import { Observable, Subject } from 'rxjs';
import { Prize } from '@/model/prize.model';
import { map, takeUntil, tap } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '@/model/user.model';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Actions, ofType } from '@ngrx/effects';


@Component({
  selector: 'app-add-prize-modal',
  templateUrl: './add-prize-modal.component.html',
  styleUrls: ['./add-prize-modal.component.scss']
})
export class AddPrizeModalComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  page = 1;
  pageSize = fromPrizesReducers.initialState.limit;
  searchTerm: string;
  user: User;
  prize: Prize;
  form: FormGroup;
  saving = false;


  constructor(
    public modal: NgbActiveModal,
    private prizeStore: Store<fromPrizesReducers.State>,
    private userStore: Store<fromUsersReducers.State>,
    private toastr: ToastrService,
    protected actions$: Actions,
  ) {
    this.form = new FormGroup({
      comment: new FormControl(),
      user: new FormControl(),
      prize: new FormControl(),
   });
  }

  ngOnInit(): void {
    this.prizeStore.dispatch(fromPrizesActions.reset());
    this.prizeStore.dispatch(fromPrizesActions.setUser({user: this.user}));
    this.prizeStore.dispatch(fromPrizesActions.fetchPrizes({}));
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  clearSearch() {
    this.searchInput.nativeElement.value = '';
    this.prizeStore.dispatch(fromPrizesActions.setSearch({search: null}));
  }

  pageChange(page): void {
    this.prizeStore.dispatch(fromPrizesActions.setPage({page: page}));
  }

  get loading(): Observable<boolean>  {
    return this.prizeStore.pipe(
      takeUntil(this.unsubscribe$),
      select(fromPrizesReducers.selectPrizesState),
      map( (state: fromPrizesReducers.State) => state.loading),
      tap(console.log)
    );
  }

  get paginator(): Observable<Prize[]>  {
    return this.prizeStore.pipe(takeUntil(this.unsubscribe$), select(fromPrizesReducers.selectAllPrizes));
  }

  get total(): Observable<number>  {
    return this.prizeStore.pipe(takeUntil(this.unsubscribe$), select(fromPrizesReducers.selectCount));
  }

  identify(index, item: Prize): number { return item.id;}

  selectPrize(item: Prize) {
    this.prize = item;
    this.form.patchValue({user: this.user.id, prize: this.prize.id});
  }

  save() {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });

    if (this.form.valid) {
      this.modal.close();
      this.saving = true;
      this.userStore.dispatch(fromUsersActions.createUserPrize({
        user: this.user,
        prize: this.prize,
        usersPrize: this.form.value,
      }));
      this.actions$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromUsersActions.addUserPrize)
      ).subscribe( () => {
        this.toastr.success('Przyznano nagrodę!');
        this.modal.close();
      })
    } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }
}

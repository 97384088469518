import { OrderForm } from '@/model/order.form.model';
import { Component, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import * as fromActions from '@/store/order/order.actions';
import * as fromReducers from '@/store/order/order.reducer';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { map, takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { createPaginationInitialState, Pagination, PaginationDTO } from '@/model/pagination.model';
import { createEntityAdapter } from '@ngrx/entity';


@Component({
  selector: 'app-view-orders',
  templateUrl: './view-orders.component.html',
  styleUrls: ['./view-orders.component.scss']
})
export class ViewOrdersComponent implements OnInit {
  protected readonly unsubscribe$: Subject<void> = new Subject();
  orderForm: OrderForm;

  constructor(
    protected store: Store<fromReducers.State>,
    protected toastr: ToastrService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    this.initialState = {
      ...createPaginationInitialState(this.adapter),
      ...{ limit: 10}
    };
    this.state = {...this.initialState};
    this.activatedRoute.paramMap.pipe(
      map((params: ParamMap) => parseInt(params.get('id')))
    ).subscribe( (id: number) => {
      this.store.dispatch(fromActions.fetchOneOrderForm({id}));
      this.store.pipe(
        takeUntil(this.unsubscribe$),
        select(fromReducers.selectOne, id)).subscribe((orderForm: OrderForm) => {
          if(!orderForm)return;
          this.orderForm = orderForm;
          this.load(1);
        })
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get loading(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromReducers.selectOrderFormsState),
      map(state => state.loading)
    );
  }
  adapter = createEntityAdapter<Pagination<any>>({});
  initialState: Pagination<any>;
  state: Pagination<any>;

  get paginator(): Observable<any[]> {
    return of(this.adapter.getSelectors().selectAll(this.state.list));
  }
  load(page) {
    this.state = {
      ...this.state,
      ...{
        loading: true,
        page: page,
      }
    }
    let params = new HttpParams();
    params = params.set('skip', (page - 1) * this.state.limit);
    params = params.set('limit', this.state.limit);
    this.httpClient.get<PaginationDTO<any>>(`<backendhost>/v1/order/${this.orderForm.id}/list`, {
          params: params,
          observe: 'body',
          responseType: 'json'
        })
        .subscribe((result: PaginationDTO<any>) => {
          this.state = {
            ...this.state,
            ...{
              loading: false,
              total: result.total,
              list: this.adapter.addMany(result.list, this.initialState.list)
            }
          }
        });
  }


  backgroundStyle () {

    if(!this.orderForm)
      return null;
    if(!this.orderForm.background)
      return null;
    return {
      'aspect-ratio': '4',
      'background' : `url(${environment.apiUrl}/thumb/1920x0/${this.orderForm.background.replace('public','')}) center center`,
      'background-size': 'cover'
    }
  }

}

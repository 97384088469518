import { Component, OnDestroy, OnInit } from '@angular/core';
import * as fromActions from '@/store/order/order.actions';
import * as fromReducers from '@/store/order/order.reducer';
import { select, Store } from '@ngrx/store';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { OrderForm } from '@/model/order.form.model';
import { environment } from 'environments/environment';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-make-order',
  templateUrl: './make-order.component.html',
  styleUrls: ['./make-order.component.scss']
})
export class MakeOrderComponent implements OnInit, OnDestroy {
  protected readonly unsubscribe$: Subject<void> = new Subject();
  orderForm: OrderForm;
  saving = false;
  form: FormGroup;
  constructor(
    protected store: Store<fromReducers.State>,
    protected toastr: ToastrService,
    // protected actionsSubject$: ActionsSubject,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected httpClient: HttpClient
  ) {
    this.activatedRoute.paramMap.pipe(
      map((params: ParamMap) => parseInt(params.get('id')))
    ).subscribe( (id: number) => {
      this.store.dispatch(fromActions.fetchOneOrderForm({id}));
      this.store.pipe(
        takeUntil(this.unsubscribe$),
        select(fromReducers.selectOne, id)).subscribe((orderForm: OrderForm) => {
          if(!orderForm)return;
          this.orderForm = orderForm;
          let controls = {}
          orderForm.inputs.forEach(input => {
            let arr = new FormGroup({
              'label': new FormControl(input.label, null),
              'options' : new FormArray(input.options.map(option => new FormControl(option, null))),
              'value': input.type == 'checkbox'
                ? new FormArray(input.options.map(() => new FormControl(false, null)), null)
                // @ts-ignore:next-line
                : new FormControl(input.type == 'select' && input.options.length ? input.options[0] : null, null)
            }, null);
            // if(input.type == 'checkbox') {
            //   input.options.forEach( option => {
            //     let value = arr.controls.value as FormArray;
            //     value.controls[option] =  new FormControl(false, null)
            //   })
            // }


            controls[input.id] = arr;
          });
          this.form = new FormGroup({
            npp: new FormControl(null, [Validators.required]),
            fields: new FormGroup(controls)
          });
        });
    })
  }

  get fieldsCtrl(): FormGroup {
    return this.form.controls['fields'] as FormGroup;
  }

  get fieldValue(): any {
    // let e = Object.entries(this.form.value);
    // e.sort((a:[string,any],b:[string,any]) => {
    //   if(a[0] == 'npp')
    //     return -1;
    //   if(b[0] == 'npp')
    //     return 1;
    //   return +a[0] < +b[0] ? -1 : 1;
    // })

    return Object.values(this.form.value.fields).map( (item:any) => {
      return {
        'label': item.label,
        'value': Array.isArray(item.value) ? (item.options as string[]).filter( (v,i) => item.value[i]) : (item.value ? item.value : ""),
      }
    });
  }

  get value(): any {
    return {
      ...this.form.value,
      ...{fields: this.fieldValue}
    };
  }

  getFormArray(idx): FormControl[] {
    let grup = (this.form.controls['fields'] as FormGroup).controls[idx] as FormGroup;
    let arr = grup.controls.value as FormArray;
    return arr.controls as FormControl[];
  }

  ngOnInit(): void {
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  get loading(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromReducers.selectOrderFormsState),
      map(state => state.loading)
    );
  }


  backgroundStyle () {

    if(!this.orderForm)
      return null;
    if(!this.orderForm.background)
      return null;
    return {
      'aspect-ratio': '4',
      'background' : `url(${environment.apiUrl}/thumb/1920x0/${this.orderForm.background.replace('public','')}) center center`,
      'background-size': 'cover'
    }
  }

  save() {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    // console.log(this.form.invalid);
    if (this.form.invalid) {
      this.toastr.warning('Formularz zawiera błędy');
      return;
    }
    this.saving = true;
    this.httpClient.post(`<backendhost>/v1/order/${this.orderForm.id}/send`, this.value).toPromise()
      .then(() => {
        this.saving = false;
        this.toastr.success('Wysłano zamówienie');
        this.router.navigateByUrl("/orders");
      }).catch(() => {
        this.saving = false;
        this.toastr.error('Wysyłanie zamówienia nie powiodło się');
      });
  }

}

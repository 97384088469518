import { createPaginationInitialState, Pagination } from "@/model/pagination.model";
import { Survey, SurveyQuestion } from "@/model/survey.model";
import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import * as actions from "./my-survey.actions";

export interface State extends Pagination<Survey> {}

export const adapter: EntityAdapter<Survey> = createEntityAdapter<Survey>();
export const questionAdapter: EntityAdapter<SurveyQuestion> = createEntityAdapter<SurveyQuestion>();

export const initialState: State = createPaginationInitialState(adapter, false, 10);

export const reducer = createReducer(
  initialState,
  on(actions.clear, (state) => initialState),
  on(actions.fetch, (state) => { return {...state, ...{loading: true}}}),
  on(actions.fetchOne, (state) => { return {...state, ...{loading: true}}}),
  on(actions.setPage, (state, action) => { return {...state, ...{page: action.page}}}),
  on(actions.setOrder, (state, action) => { return {...state, ...{order: action.order}}}),
  on(actions.setDirection, (state, action) => { return {...state, ...{asc: action.asc}}}),
  on(actions.setSearch, (state, action) => { return {...state, ...{search: action.search}}}),

  on(actions.setMySurveys, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: action.total,
        list: adapter.addMany(action.list, initialState.list)
      }
    }
  }),
  on(actions.addMySurvey, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: state.total + 1,
        list: adapter.addOne(action.survey, state.list)
      }
    }
  }),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectState = createFeatureSelector<State>('my-survey');
export const selectAllSurvey = createSelector(selectState, (state) => selectAll(state.list) )
export const selectOneSurvey = createSelector(selectState, (state: State, id) => state.list.entities[id]);

<!-- <div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">Strona główna</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Strona główna</li>
                </ol>
            </div>
        </div>
    </div>
</div> -->
<section class="content">
    <div class="container pt-3 pb-3">
      <div class="row">
        <div class="col-6">
          <a href="https://badaj.to/" target="_blank" class="card">
            <div class="card-body d-flex align-items-center p-1" style="aspect-ratio: 3 / 1;">
              <img src="/assets/img/badajto.png" style="width: 100%;" class="img-fluid" alt="Badaj.to">
            </div>
          </a>
        </div>
        <div class="col-6">
          <a href="https://sla.pl/" target="_blank" class="card">
            <div class="card-body d-flex align-items-center p-1" style="aspect-ratio: 3 / 1;">
              <img src="/assets/img/SLA_logotype_basic.png" style="width: 100%;" class="img-fluid m-3" alt="Śląskie Laboratoria Analityczne">
            </div>
          </a>
        </div>
      </div>
    </div>
</section>

 <div class="content">
  <div class="container pt-3 pb-3">
    <div class="row">
      <div class="col-lg-6">
        <div class="info-box shadow" [routerLink]="['/messages/inbox']" style="cursor: pointer">
          <span class="info-box-icon bg-info"><i class="far fa-envelope"></i></span>
          <div class="info-box-content">
            <span class="info-box-text">Wiadomości</span>
            <span class="info-box-number">{{msg | async}}</span>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="info-box shadow" [routerLink]="['/chat']" style="cursor: pointer">
          <span class="info-box-icon bg-orange"><i class="far fa-comments"></i></span>
          <div class="info-box-content">
            <span class="info-box-text">Chat</span>
            <span class="info-box-number">{{chat | async}}</span>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="info-box shadow" [routerLink]="['/news/unseen']" style="cursor: pointer">
          <span class="info-box-icon bg-success"><i class="far fa-comments"></i></span>
          <div class="info-box-content">
            <span class="info-box-text">Nowe posty</span>
            <span class="info-box-number">{{newPosts | async}}</span>
          </div>
        </div>
      </div>
      <div class="col-lg-6" checkRolePermition="redmine.view">
        <div class="info-box shadow" [routerLink]="['/redmine']" style="cursor: pointer">
          <span class="info-box-icon bg-primary"><i class="fas fa-bug"></i></span>
          <div class="info-box-content">
            <span class="info-box-text">Zgłoszenia : <strong>{{(redmineAll | async)>99 ? '99+' : (redmineAll | async) }}</strong></span>
            <span class="info-box-number">Wymagające reakcji: {{redmineAssigned | async}}</span>
          </div>
        </div>
      </div>
  </div>

  <hr>

  <div class="card card-primary">
    <div class="card-header">
      <h3 class="card-title text-center float-none">Nowi użytkownicy</h3>
    </div>
    <div class="overlay" *ngIf="(recentUsers | async).length == 0">
      <i class="fas fa-3x fa-sync-alt fa-spin"></i>
    </div>
    <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(recentUsers | async).length == 0"></nz-empty>
    <div class="card-body p-0">
      <ul class="users-list clearfix d-flex flex-wrap justify-content-center">
        <li *ngFor="let recentUser of (recentUsers | async)" style="min-width:150px; flex-shrink: 0; width: auto">
          <img [src]="recentUser | userAvatar: 128" alt="User Image" style="width:128px; height: 128px" class="elevation-2 mb-2">
          <a [routerLink]="['/profile',recentUser.id]" class="users-list-name">{{recentUser.name}}</a>
          <span class="users-list-date"> {{recentUser.createdAt | amLocale:'pl' | amCalendar}} </span>
        </li>
      </ul>
    </div>
    <!-- <div class="card-footer text-center">
      <a href="javascript:">View All Users</a>
    </div> -->
  </div>

  <hr>
    <app-group-card [group]="group" *ngIf="group"></app-group-card>
    <div class="search-results timeline"
          infinite-scroll
          [infiniteScrollDistance]="scrollDistance"
          [infiniteScrollUpDistance]="scrollUpDistance"
          [infiniteScrollThrottle]="throttle"
          [immediateCheck]="true"
          (scrolled)="onScroll()"
          (scrolledUp)="onScrollUp()">

      <ng-template ngFor let-post [ngForOf]="posts | async" let-idx="index" [ngForTrackBy]="identify">
        <div class="time-label" *ngIf="before(post, idx) | async">
          <span class="bg-primary">
            <i class="far fa-calendar fa-fw"></i>
            {{post.createdAt | amDateFormat: 'DD.MM.YYYY' }}
          </span>
        </div>

        <div>
          <!-- <i class="fas fa-bullhorn bg-orange"></i> -->
          <post-box  [post]="post" class="timeline-item d-block"></post-box>
        </div>
      </ng-template>
      <button
        type="button"
        class="btn btn-block btn-outline-primary btn-block"
        *ngIf="(hasMore | async)"
        (click)="loadMore()"
        [ngClass]="{'disabled' : (loading | async)}">
        <i class="fas" [ngClass]="{'fa-spinner fa-spin' : (loading | async), 'fa-sync' : !(loading | async)}"></i>
        załaduj więcej
      </button>
       <!-- <pre>{{ state | async | json}}</pre> -->
    </div>
  </div>
</div>

import { tinyMceConfig } from '@/config/tinymce.config';
import { User } from '@/model/user.model';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppService } from '@services/app.service';
import { merge, Observable, of, OperatorFunction, pipe, Subject } from 'rxjs';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import * as fromGroupReducer from '@/store/group/group.reducers';
import * as fromGroupActions from '@/store/group/group.actions';

import * as fromPostReducer from '@/store/post/post.reducers';
import * as fromPostActions from '@/store/post/post.actions';

import { Post } from '@/model/post.model';
import { Group } from '@/model/group.model';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { HttpClient } from '@angular/common/http';
import ClassicEditorWithMention from 'ckeditor5/packages/ckeditor5-build-classic/build/ckeditor'
import moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'environments/environment';



@Component({
  selector: 'app-edit-news',
  templateUrl: './edit-news.component.html',
  styleUrls: ['./edit-news.component.scss']
})
export class EditNewsComponent implements OnInit {
  @ViewChild('instance', {static: true}) instance: NgbTypeahead;
  @ViewChild('instanceUser', {static: true}) instanceUser: NgbTypeahead;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  focusGroup$ = new Subject<string>();
  clickGroup$ = new Subject<string>();

  post: Post ={
    id: null,
    title: null,
    content: null,
    tags: []
  }
  Editor = ClassicEditorWithMention;
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  typeaheadModel: any;
  typeaheadList: Group[] = [];

  typeaheadUserModel: any;
  typeaheadUsersList: User[] = [];


  formatter = (group: Group) => `${group.name}`;
  formatterEmpty = () => null;
  groups: Group[];
  previewImage: string | undefined = '';
  previewVisible = false;
  fileList: NzUploadFile[] = [];
  backgroundFile: NzUploadFile;
  @Output() fileListChange: EventEmitter<NzUploadFile[]> = new EventEmitter<NzUploadFile[]>();

  constructor(
    private httpClient: HttpClient,
    private store: Store<fromGroupReducer.State>,
    private storePost: Store<fromPostReducer.PostsState>,
    private toastr: ToastrService,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sanitized: DomSanitizer,
    private appService: AppService,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(fromGroupActions.fetchAllAction());

    this.store.pipe(takeUntil(this.unsubscribe$), select(fromGroupReducer.selectAllGroups)).subscribe( (groups: Group[]) => {
      this.groups = groups;
    });
    this.form = new FormGroup({
      title: new FormControl(null, Validators.required),
      content: new FormControl(null, Validators.required),
      groups: new FormArray([]),
      sendTo: new FormControl('all', Validators.required),
      users: new FormArray([]),
      startDate: new FormControl(new Date(),[this.DateTimeValidator.bind(this)]),
      endDate: new FormControl(new Date(),[this.DateTimeValidator.bind(this)])
    });
    this.form.controls.sendTo.valueChanges.subscribe((value) => {
      setTimeout(() => this.updateSelectedUsers(), 0);
    });
    this.updateSelectedUsers();
    this.form.get("startDate").disable();
    this.form.get("endDate").disable();
    this.activatedRoute.paramMap.pipe(
      map((params: ParamMap) => parseInt(params.get('id')))
      ).subscribe( (id: number) => {

        this.store.pipe(takeUntil(this.unsubscribe$), select(fromPostReducer.selectEntity, id)).subscribe((post: Post | null) => {
          if(!post) {
            console.log("dispatch");
            this.store.dispatch(fromPostActions.fetchOneAction({id}));
            return;
          };
          this.post = {
            ...post,
            ...{
              startDate : post.startDate ? moment(post.startDate).format('YYYY-MM-DD hh:mm:ss') : moment().format('YYYY-MM-DD 00:00:00'),
              endDate : post.endDate ? moment(post.endDate).format('YYYY-MM-DD hh:mm:ss') : moment().add(1, 'M').format('YYYY-MM-DD 23:45:00'),
            }
          };
          this.form.patchValue(this.post);
          if (this.post.audio) {
            this.audioPath =  `${environment.apiUrl}/v1/audio/${this.post.audio.id}`
          }
          if (this.post.startDateActive) {
            this.form.get('startDate').enable();
          } else {
            this.form.get('startDate').disable();
          }
          if (this.post.endDateActive) {
            this.form.get('endDate').enable();
          } else {
            this.form.get('endDate').disable();
          }
        })
    })
  }

  deleteImage(item) {
    let idx = this.post.images.map(a => a.id).indexOf(item.id);
    let images = [...this.post.images];
    images.splice(idx, 1)
    this.post = {
      ...this.post,
      ...{
        images : images
      }
    }
    this.httpClient.delete<any>(`<backendhost>/v1/images/${item.id}`).toPromise();
    this.store.dispatch(fromPostActions.updateNews({post: this.post}));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get saving(): Observable<boolean> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromPostReducer.selectSaving));
  }

  save(): void {

    if (this.post.startDateActive) {
      this.form.get('startDate').enable();
    } else {
      this.form.get('startDate').disable();
    }
    if (this.post.endDateActive) {
      this.form.get('endDate').enable();
    } else {
      this.form.get('endDate').disable();
    }
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    if (this.form.valid) {

      this.post = {...this.post, ...this.form.value, ...{
        startDate : this.post.startDateActive ? this.form.value.startDate.toLocaleString('pl-PL') : null,
        endDate : this.post.endDateActive ? this.form.value.endDate.toLocaleString('pl-PL') : null
      }};

      let audioFile = this.audioFile?.length ? this.audioFile[0] as any : null;
      this.storePost.dispatch(fromPostActions.savePost({
        post: this.post,
        background: this.backgroundFile as any,
        audioFile: audioFile
      }));
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        filter((action) => action.type === fromPostActions.updateNews.type),
      ).subscribe( (_) => {
        this.toastr.success('Zapisano zmiany!');
        this.router.navigate(['/news', this.post.id]);
      });
    } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }

  // search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => text$.pipe(
  //     debounceTime(300),
  //     distinctUntilChanged(),
  //     switchMap((term: string) => this.appService.searchUser(term).pipe())
  // )
  search: OperatorFunction<string, readonly Group[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      takeUntil(this.unsubscribe$),
      map(term => (term === '' ? this.groups : this.groups.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))),
      map( (arr:Group[]) => arr.filter(item => this.typeaheadList.map(x => x.id).indexOf(item.id) === -1)),
      map( (arr:Group[]) => arr.slice(0, 10))
    );
  }

  get groupCtrl(): FormArray {
    return this.form.get('groups') as FormArray;
  }

  onTypeaheadSelect(selectedItem: NgbTypeaheadSelectItemEvent) {
    let group: Group = selectedItem.item;
    if (this.typeaheadList.map(x => x.id).indexOf(selectedItem.item.id) !== -1)return;
    this.typeaheadList.push(group);
    this.groupCtrl.push(new FormControl(group.id))
  }

  removeGroup(item: Group) {
    let idx = this.typeaheadList.map(it => it.id).indexOf(item.id);
    this.typeaheadList.splice(idx, 1);
    this.groupCtrl.removeAt(idx);
  }

  groupIdentity(index, item: Group) {
    return item.id;
  }



  DateTimeValidator = (fc: FormControl) => {
    const date = new Date(fc.value);
    const isValid = !isNaN(date.valueOf());
    return isValid ? null : {dateNotValid: true};
  };
  handleChange(info: NzUploadChangeParam): void {
    if (info.file.status === 'done') {
      this.fileList.splice(this.fileList.map(x => x.name).indexOf(info.file.name), 1);
      this.post = {...this.post, ...{ images: [...this.post.images, info.file.response]}};
      this.store.dispatch(fromPostActions.updateNews({post: this.post}));
    }
    else if (info.file.status === 'error') {
      this.toastr.error(`Upload pliku ${info.file.name} nie udał się.`);
    }
  }

  handlePreview = async (file: NzUploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  };

  beforeUploadBackground = (file: NzUploadFile): boolean => {
    getBase64(file as any).then( (preview: string) => {
      this.backgroundFile  = file;
      this.post = {...this.post, ...{background: preview}};
    })
    return false;
  };

  searchUsers: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instanceUser.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(text$, debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term =>
        this.appService.searchUser(term)
      ),
    );
  }

  get usersCtrl(): FormArray {
    return this.form.get('users') as FormArray;
  }

  onUsersTypeaheadSelect(selectedItem: NgbTypeaheadSelectItemEvent) {
    let user: User = selectedItem.item;
    this.typeaheadUsersList.push(user);
    this.usersCtrl.push(new FormControl(user.id));
    this.updateSelectedUsers();
  }

  removeUser(item: User) {
    let idx = this.typeaheadUsersList.map(it => it.id).indexOf(item.id);
    this.typeaheadUsersList.splice(idx, 1);
    this.usersCtrl.removeAt(idx);
    this.updateSelectedUsers();
  }


  audioFile: NzUploadFile[] = [];
  beforeUploadAudio = (file: NzUploadFile): boolean => {
    console.log(file);
    this.audioFile = [file];
    getBase64(file as any).then(hash => {
      this.audioPath = this.sanitized.bypassSecurityTrustUrl(hash as string)
    })
    return false;
  };

  audioPath: any;

  clearAudio() {
    this.post.audio = null;
    this.audioFile = [];
    this.audioPath = null;
  }


  selectedUsers: { list: User[], count: number, page: number, loading: boolean } = {list: [], count: 0, page: 1, loading: false};
  updateSelectedUsers = (): void => {
    this.selectedUsers.loading = true;
    const payload = {...this.post, ...this.form.value};
    this.httpClient.post<{ list: User[], count: number }>('<backendhost>/v1/posts/0/count', payload, {params: {
      page: this.selectedUsers.page
    }})
      .subscribe((result) => {
        this.selectedUsers = {...this.selectedUsers, ...result, loading: false};
      });
  }
  identify(index, entity: any){
    return entity.id;
  }

  pageChange($event) {
    this.updateSelectedUsers();
  }
}

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

<div class="card card-widget widget-user" [ngClass]="{'with-bg': group.background}">
  <div class="widget-user-header bg-info text-white position-relative" [ngStyle]="backgroundStyle()">
    <h3 class="widget-user-username">{{group.name}}</h3>

    <div class="widget-user-image">
      <img class="img-circle elevation-3" style="height: 90px;" [src]="group | groupAvatar: 90" alt="User Avatar" *ngIf="group.image">
      <div class="btn btn-secondary text-secondary elevation-3" style="height: 90px;" *ngIf="!group.image">
        <i class="fas fa-users fa-3x fa-fw"></i>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="row">
      <div class="col-sm-4 border-right">
        <div class="description-block">
          <h5 class="description-header" *ngIf="group.lazyLoaded"><i class="fas fa-users"></i> {{group.memberCount}}</h5>
          <h5 class="description-header" *ngIf="!group.lazyLoaded"><i class="fas fa-users"></i> <i class="fas fa-spinner fa-spin ml-2"></i></h5>
          <span class="description-text">CZŁONKÓW</span>
        </div>
      </div>
      <div class="col-sm-4 border-right">
        <div class="description-block">
          <h5 class="description-header" *ngIf="group.lazyLoaded"><i class="fas fa-eye"></i> {{group.observersCount}}</h5>
          <h5 class="description-header" *ngIf="!group.lazyLoaded"><i class="fas fa-users"></i> <i class="fas fa-spinner fa-spin ml-2"></i></h5>
          <span class="description-text">OBSERWUJE</span>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="description-block">
          <h5 class="description-header" *ngIf="group.lazyLoaded"><i class="fas fa-edit"></i> {{group.postCount}}</h5>
          <h5 class="description-header" *ngIf="!group.lazyLoaded"><i class="fas fa-users"></i> <i class="fas fa-spinner fa-spin ml-2"></i></h5>
          <span class="description-text">POSTÓW</span>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-4 border-right">
        <div class="description-block">
          <button class="btn btn-outline-primary btn-block" *ngIf="!group.membership" (click)="join()" [disabled]="!canJoin()">
            <i class="fas fa-users fa-fw fa-lg"></i> Dołącz do grupy
          </button>
          <button class="btn btn-block" *ngIf="group.membership"  (click)="leave()" [disabled]="!canLeave()" [ngClass]="{'btn-primary': group.membership.canLeave, 'btn-secondary': !group.membership.canLeave}">
            <i class="fas fa-users-slash fa-fw fa-lg"></i> Opuść grupę
          </button>
        </div>
      </div>
      <div class="col-sm-4 border-right">
        <div class="description-block">
          <button class="btn btn-block" *ngIf="!group.membership?.observing" (click)="observe(true)" [disabled]="!group.membership"  [ngClass]="{'btn-outline-primary': group.membership, 'btn-outline-secondary': !group.membership}">
            <i class="fas fa-eye fa-fw fa-lg"></i> Obserwuj grupę
          </button>
          <button class="btn btn-primary btn-block" *ngIf="group.membership?.observing" (click)="observe(false)" [disabled]="!canUnobserve()">
            <i class="fas fa-eye-slash fa-fw fa-lg"></i> Przestań obserwować
          </button>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="description-block">
          <a class="btn btn-primary btn-block" [routerLink]="['/groups', group.id]" *ngIf="linkToMembers">
            <i class="fas fa-users fa-fw fa-lg"></i> Członkowie
          </a>
          <a class="btn btn-primary btn-block" [routerLink]="['/groups', group.id, 'news']" *ngIf="!linkToMembers">
            <i class="fas fa-newspaper fa-fw fa-lg"></i> Posty
          </a>
          <!-- <button class="btn btn-primary btn-block" *ngIf="group.membership?.observing" (click)="observe(false)">
            <i class="fas fa-eye-slash fa-fw fa-lg"></i> Przestań obserwować
          </button> -->
        </div>
      </div>
    </div>
    <!-- <span class="description"> {{post.createdAt | amLocale:'pl' | amCalendar }}</span> -->
  </div>
  <div class="card-body">
    <span class="description text-muted text-sm">Data utworzenia grupy <strong>{{group.createdAt | amLocale:'pl' | amTimeAgo }}</strong></span>
    <span class="description text-muted text-sm float-right" *ngIf="group.membership">Należysz do grupy od <strong>{{group.membership.createdAt| amLocale:'pl' | amTimeAgo }}</strong></span>
    <!-- <hr> -->
    <!-- <div  [innerHtml]="group.content | safeHtml"></div> -->
  </div>
</div>

import { AppNotification } from "@/model/notification.model";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import * as fromActions from "./notification.actions"

export interface State extends EntityState<AppNotification>{}


export const adapter: EntityAdapter<AppNotification> = createEntityAdapter<AppNotification>({
  sortComparer: (a: AppNotification, b: AppNotification) => b.createdAt > a.createdAt ? 1 : -1
});

export const initialState: State  = adapter.getInitialState();


export const reducer = createReducer(
  initialState,
  on(fromActions.set, (state, action) => adapter.setMany(action.notifications, state)),
  on(fromActions.add, (state, action) => adapter.setOne(action.notification, state)),
  // on(fromActions.remove, (state, action) => adapter.removeOne(action.notification.id, state)),
  on(fromActions.remove, (state, action) => adapter.updateOne({id: action.notification.id, changes: {seen: true}}, state)),
)

export const selectNotificationState = createFeatureSelector<State>('notification');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectAllNotification = createSelector(selectNotificationState, selectAll )

export const hasNotification = createSelector(selectNotificationState, (state, id) => state.ids.indexOf (id) != -1);

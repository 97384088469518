<div class="modal-header">
  <h4 class="modal-title">Rezerwacja</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (ngSubmit)="send()">
  <div class="position-absolute overlay" style="width:100%; height: 100%; background: rgba(255,255,255,0.7);" *ngIf="saving">
    <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
      <i class="fas fa-3x fa-spinner fa-spin"></i>
      <p>Zapisywanie...</p>
    </div>
  </div>
<div class="modal-body">
    <div class="form-group">
      <label for="asset">Rezerwowany zasób</label>
      <select formControlName="asset" [appIsValid]="form.controls.asset" class="form-control" placeholder="Wybierz">
        <option [value]="asset.id" *ngFor="let asset of assets">{{asset.title}}</option>
      </select>
      <i class="fas fa-spinner fa-spin position-relative" style="top: -30px; left: 5px;" *ngIf="form.controls.asset.status == 'PENDING'"></i>
      <div class="invalid-feedback" *ngIf="form.controls.asset.errors?.required">
        Pole jest wymagane
      </div>
      <div class="invalid-feedback" *ngIf="form.controls.asset.errors?.taken">
        Dla wybranej daty zasób jest już zajęty
      </div>
    </div>
    <div class="form-group">
      <label for="startDate">Początek</label>
      <app-date-time-picker
        formControlName="startDate"
        [appIsValid]="form.controls.startDate"
        name="startDate"
        inputDatetimeFormat="YYYY-MM-dd HH:mm"
        [minuteStep]="5"
        [seconds]="false"
        [timeFirst]="true"
        ></app-date-time-picker>
      <i class="fas fa-spinner fa-spin position-relative" style="top: -30px; left: 5px;" *ngIf="form.controls.startDate.status == 'PENDING'"></i>
      <div class="invalid-feedback" *ngIf="form.controls.startDate.errors?.required">
        Pole jest wymagane
      </div>
      <div class="invalid-feedback" *ngIf="form.controls.startDate.errors?.inValidDate">
        Nieprawidłowy format daty
      </div>
      <div class="invalid-feedback" *ngIf="form.controls.startDate.errors?.isAfterEnd">
        Data początku nie może być większa od daty końca
      </div>

      <div class="invalid-feedback" *ngIf="form.controls.startDate.errors?.taken">
        Dla wybranej daty zasób jest już zajęty
      </div>
      <!-- <pre>{{form.controls.startDate.errors | json}}</pre> -->
    </div>
    <div class="form-group">
      <label for="endDate">Koniec</label>
      <app-date-time-picker
        formControlName="endDate"
        [appIsValid]="form.controls.endDate"
        name="endDate"
        inputDatetimeFormat="YYYY-MM-dd HH:mm"
        [minuteStep]="5"
        [seconds]="false"
        [timeFirst]="true"
        ></app-date-time-picker>

      <i class="fas fa-spinner fa-spin position-relative" style="top: -30px; left: 5px;" *ngIf="form.controls.endDate.status == 'PENDING'"></i>
      <div class="invalid-feedback" *ngIf="form.controls.endDate.errors?.required">
        Pole jest wymagane
      </div>
      <div class="invalid-feedback" *ngIf="form.controls.endDate.errors?.inValidDate">
        Nieprawidłowy format daty
      </div>
      <div class="invalid-feedback" *ngIf="form.controls.endDate.errors?.isBeforeStart">
        Data końca nie może być mniejsza od daty początku
      </div>
      <div class="invalid-feedback" *ngIf="form.controls.endDate.errors?.taken">
        Dla wybranej daty zasób jest już zajęty
      </div>
    </div>
    <!-- <pre>{{form.value | json}}</pre> -->
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" type="submit">
    <i class="fas fa-paper-plane fa-fw"></i>
    Zapisz
  </button>
</div>
</form>

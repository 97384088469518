<section class="content">
  <div class="container">
    <app-package-tab></app-package-tab>
    <div class="card" style="border-top-left-radius: 0;border-top-right-radius: 0;">
      <app-package-notification-editor
        *ngIf="package.current"
        [package]="package"
      ></app-package-notification-editor>
      <app-package-pack-list-of-invited
        *ngIf="package.current"
        [package]="package"
        [invitePack]="package.current"
      ></app-package-pack-list-of-invited>
    </div>
  </div>
</section>





import { TrainingPackage } from '@/model/package.model';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Data } from '@angular/router';
import moment from 'moment';
import { Subject } from 'rxjs';
import * as fromTrainingPackageiaActions from '@/store/package/package.actions';
import * as fromTrainingPackageReducers from '@/store/package/package.reducers';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-package-tab',
  templateUrl: './package-tab.component.html',
  styleUrls: ['./package-tab.component.scss']
})
export class PackageTabComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  package: TrainingPackage;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<fromTrainingPackageReducers.State>,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe( (data: Data) => {
      if (!data.instance) return;
      this.package = data.instance;
      this.store.pipe(
        select(fromTrainingPackageReducers.selectOneTrainingPackage, this.package.id),
        takeUntil(this.unsubscribe$),
        distinctUntilChanged()
      ).subscribe((item) => {
        this.package = item;
      });
    });
  }

}

import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import * as fromTrainingPackageiaActions from '@/store/package/package.actions';
import * as fromTrainingPackageReducers from '@/store/package/package.reducers';
import { TrainingNotification, TrainingPackage } from '@/model/package.model';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';


@Component({
  selector: 'app-package-notification-editor',
  templateUrl: './package-notification-editor.component.html',
  styleUrls: ['./package-notification-editor.component.scss']
})
export class PackageNotificationEditorComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  @ViewChildren('dayInput') dayInputs: QueryList<ElementRef>;
  @Input() package: TrainingPackage;

  constructor(
    private store: Store<fromTrainingPackageReducers.State>,
    private activatedRoute: ActivatedRoute,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get list(): Observable<TrainingNotification[]> {
    return this.store.pipe(
      select(fromTrainingPackageReducers.selectOneTrainingPackage, this.package.id),
      map(_package => _package.current.notifications),
      map(notifications => fromTrainingPackageReducers.notificationAdapter.addMany(
        Object.values(notifications.entities),
        fromTrainingPackageReducers.notificationAdapter.getInitialState()
      )),
      map(notifications => fromTrainingPackageReducers.notificationAdapter.getSelectors().selectAll(notifications))
    );
  }

  add() {
    this.store.dispatch(fromTrainingPackageiaActions.createNotification({package: this.package}));
  }


  update(key, event, item) {
    let change = {};
    change[key] = event;
    let notification : TrainingNotification = {...item, ...change};
    notification = {...notification, ...{daysBefore : Math.max(1, notification.daysBefore)}};
    this.store.dispatch(fromTrainingPackageiaActions.setNotification({package: this.package, notification}));
    this.store.dispatch(fromTrainingPackageiaActions.updateNotification({package: this.package, notification}));
  }

  updateSms(item) {
    let change = {
      sms: !item.sms
    };
    let notification : TrainingNotification = {...item, ...change};
    notification = {...notification, ...{daysBefore : Math.max(1, notification.daysBefore)}};
    this.store.dispatch(fromTrainingPackageiaActions.setNotification({package: this.package, notification}));
    this.store.dispatch(fromTrainingPackageiaActions.updateNotification({package: this.package, notification}));
  }

  delete(item) {
    this.store.dispatch(fromTrainingPackageiaActions.deleteNotification({package: this.package, notification: item}));
  }

  sendDate(item): Observable<any>  {
    return of(this.dayInputs).pipe(
      filter(dayInputs => typeof dayInputs != "undefined"),
      map(dayInputs => {
        let days = dayInputs.get(item-1).nativeElement.value;
        return moment(this.package.current.endDate).subtract(days, 'days')
      })
    );
  }

}

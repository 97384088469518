import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";

export interface ComponentWithUnsavedData {
  hasUnsavedData(): boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentWithUnsavedData> {
  canDeactivate(component: ComponentWithUnsavedData): boolean {
    if(component.hasUnsavedData()){
        if (confirm("Masz niezapisane zmiany! Jeśli opuścisz stronę, zmiany zostaną utracone")) {
            return true;
        } else {
            return false;
        }
    }
    return true;
  }
}

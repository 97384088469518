import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';

import * as fromTrainingPackageiaActions from '@/store/package/package.actions';
import * as fromTrainingPackageReducers from '@/store/package/package.reducers';
import * as fromTrainingGroupActions from '@/store/training-group/training-group.actions';
import * as fromTrainingGroupReducers from '@/store/training-group/training-group.reducers';

import { ToInvite, TrainingInvitePack, TrainingPackage } from '@/model/package.model';
import { fromEvent, merge, Observable, of, OperatorFunction, Subject } from 'rxjs';
import { PaginationDTO } from '@/model/pagination.model';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { User } from '@/model/user.model';
import { AppService } from '@services/app.service';
import { ofType } from '@ngrx/effects';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { TrainingGroup } from '@/model/training.group.model';
import { Group } from '@/model/group.model';

@Component({
  selector: 'app-package-invitation',
  templateUrl: './package-invitation.component.html',
  styleUrls: ['./package-invitation.component.scss']
})
export class PackageInvitationComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  @ViewChild('instanceUserGroup', {}) instanceUserGroup: NgbTypeahead;
  @ViewChild('instanceGroup', {}) instanceGroup: NgbTypeahead;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  focusGroup$ = new Subject<string>();
  clickGroup$ = new Subject<string>();

  focusUserGroup$ = new Subject<string>();
  clickUserGroup$ = new Subject<string>();

  form: FormGroup;
  package: TrainingPackage;
  invitePack: TrainingInvitePack;

  typeaheadUserGroupModel: any;
  typeaheadGroupModel: any;
  typeaheadTrainingModel: any;


  formatter = (item: any) => `${item.name}`;
  formatterEmpty = () => null;

  searchTerm: string;
  page = 1;
  pageSize = 20;
  model: any;
  searching = false;
  searchFailed = false;

  constructor(
    private toastr: ToastrService,
    private store: Store<fromTrainingPackageReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private appService: AppService,
    private activatedRoute: ActivatedRoute,
    private storeTrainingGroup: Store<fromTrainingGroupReducers.State>,
  ) {
    this.form = new FormGroup({
      endDate: new FormControl(new Date(),[Validators.required, this.DateTimeValidator.bind(this)]),
      groups: new FormArray([], []),
      userGroups: new FormArray([], []),
      all: new FormControl(false),
    });
    this.form.controls.all.valueChanges.subscribe((value: boolean) => {
      this.package = {
        ...this.package,
        ...{
          current: {
            ...this.package.current,
            ...{
              all:value
            }
          }
        }
      }
      this.store.dispatch(fromTrainingPackageiaActions.saveInvitationPack({package: this.package}));
    })
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.storeTrainingGroup.dispatch(fromTrainingGroupActions.clear());
    this.storeTrainingGroup.dispatch(fromTrainingGroupActions.fetch());

    this.package = {
      ...this.package,
      ...{
        current : {
          ...this.package?.current,
          ...{
            endDate : moment().add(1, 'M').add(1, 'd').format('YYYY-MM-DD  00:00:00'),
            groups: [],
            userGroups: [],
          }
        }
      }
    };
    this.form.patchValue(this.package);
    this.activatedRoute.data.subscribe( (data: Data) => {
      if (!data.instance) {
        return;
      }

      this.package = {
        ...this.package,
        ...data.instance,
        ...{
          current: {
            ...this.package.current,
            ...data.instance.current,
            ...{
                endDate : data.instance.endDate
                          ? moment(data.instance.endDate).format('YYYY-MM-DD hh:mm:ss')
                          : moment().add(1, 'M').add(1, 'd').format('YYYY-MM-DD 00:00:00'),
            }
          }
        }
      };
      if (this.package.archive || this.package.open) {
        this.router.navigate(['/training/admin/package']);
        return;
      }

      this.form.patchValue(this.package.current);

      this.package.current.groups.map( group => {
        this.groupCtrl.push(new FormControl(group.id))
      });
      this.package.current.userGroups.map( group => {
        this.userGroupCtrl.push(new FormControl(group.id))
      });
      this.store.dispatch(fromTrainingPackageiaActions.fetchToInvite({package: this.package, page: 1}));

      this.store.pipe(
          select(fromTrainingPackageReducers.selectOneTrainingPackage, this.package.id),
          takeUntil(this.unsubscribe$),
          distinctUntilChanged())
              .subscribe((item) => {
                this.package = item;
              });
        fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
          takeUntil(this.unsubscribe$),
          map((event: any) => event.target.value),
          filter( (res:string) => res.length > 2),
          debounceTime(1000),
          distinctUntilChanged())
            .subscribe((text: string) => {
              this.searchTerm = text.toLowerCase().trim();
              this.store.dispatch(fromTrainingPackageiaActions.fetchToInvite({package: this.package, page: this.page, search: this.searchTerm}));
            });
    });
  }

  identify(index, entity: any){
    return entity.id;
  }

  get pagination(): Observable<PaginationDTO<ToInvite>> {
    // return of({
    //   total: 0,
    //   list: []
    // });
    return this.package ? this.store.pipe(select(fromTrainingPackageReducers.selectTrainingToInvite, this.package.id)) : of({total: 0, list: []});
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  clearSearch() {
    this.searchInput.nativeElement.value = '';
    // this.store.dispatch(fromTrainingPackageActions.setSearch({search: null}));
  }

  onUserSelect(selectedItem: NgbTypeaheadSelectItemEvent) {
    let user: User = selectedItem.item;
    this.pagination.pipe(take(1)).subscribe( invitations => {
      this.store.dispatch(fromTrainingPackageiaActions.addUserToInvite({
        user: user,
        package: {...this.package, ...{invitations: invitations}}
      }));
    });

  }

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => {
        return this.appService.searchUserNotInPackage(this.package, term).pipe(
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      }),

      tap(() => this.searching = false)
    )
  };

  pageChange(page): void {
    this.page = page;
    this.store.dispatch(fromTrainingPackageiaActions.fetchToInvite({package: this.package, page: this.page, search: this.searchTerm}));
  }

  delete(item: (ToInvite) ) {
    this.store.dispatch(fromTrainingPackageiaActions.deleteToInvite({toInvite: item, package: this.package}));
  }

  send() {
    console.log("SEND", this.form.value.endDate.toLocaleString('pl-PL'), this.form.value.endDate.toLocaleString('pl-PL'));
    this.store.dispatch(fromTrainingPackageiaActions.sendInvitations({
      package: {
        ...this.package,
        ...{
          current : {
            ...this.package.current,
            ...{
              endDate : this.form.value.endDate.toLocaleString('pl-PL')
            }
          }
        }
      }
    }));
    this.actionsSubject$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(fromTrainingPackageiaActions.updateTrainingPackage),
      take(1)
    ).subscribe(() => {
      this.toastr.success("Dodano zaproszenia");
      this.router.navigate(['/training/admin/package', this.package.id, 'invitated'])
    })
  }

  /**
   * GROUPS SET
   */
   searchGroups: OperatorFunction<string, readonly TrainingGroup[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(takeUntil(this.unsubscribe$), debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickGroup$.pipe(takeUntil(this.unsubscribe$), filter(() => !this.instanceGroup.isPopupOpen()));
    const inputFocus$ = this.focusGroup$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(300),
      distinctUntilChanged(),
      tap(search => this.storeTrainingGroup.dispatch(fromTrainingGroupActions.setSearch({search}))),
      switchMap(term => this.storeTrainingGroup.pipe(select(fromTrainingGroupReducers.selectAllTrainingGroup))),
      map(items => items.filter(item => this.package.current.groups.map(i => i.id).indexOf(item.id) == -1))
    );
  }
  onGroupsTypeaheadSelect(selectedItem: NgbTypeaheadSelectItemEvent) {
    let group: TrainingGroup = selectedItem.item;
    this.package = {
      ...this.package,
      ...{
        current: {
          ...this.package.current,
          ...{
            groups: [...this.package.current.groups, ...[group]]
          }
        }
      }
    };
    this.groupCtrl.push(new FormControl(group.id));
    this.store.dispatch(fromTrainingPackageiaActions.saveInvitationPack({package: this.package}));
  }
  removeGroup(item: TrainingGroup) {
    let idx = this.package.current.groups.map(it => it.id).indexOf(item.id);
    let groups = [...this.package.current.groups];
    groups.splice(idx, 1);

    this.package = {
      ...this.package,
      ...{
        current: {
          ...this.package.current,
          ...{
            groups: groups
          }
        }
      }
    };
    this.groupCtrl.removeAt(idx);
    this.store.dispatch(fromTrainingPackageiaActions.saveInvitationPack({package: this.package}));
  }
  get groupCtrl(): FormArray {
    return this.form.get('groups') as FormArray;
  }


  /**
   * USER GROUPS SET
   */
  searchUserGroups: OperatorFunction<string, readonly Group[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(takeUntil(this.unsubscribe$), debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickUserGroup$.pipe(takeUntil(this.unsubscribe$), filter(() => !this.instanceUserGroup.isPopupOpen()));
    const inputFocus$ = this.focusUserGroup$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term =>
        this.appService.searchGroups(term)
      ),
      map(items => items.filter(item => this.package.current.userGroups.map(i => i.id).indexOf(item.id) == -1))
    );
  }
  onUserGroupsTypeaheadSelect(selectedItem: NgbTypeaheadSelectItemEvent) {
    let group: Group = selectedItem.item;
    this.package = {
      ...this.package,
      ...{
        current: {
          ...this.package.current,
          ...{
            userGroups: [...this.package.current.userGroups, ...[group]]
          }
        }
      }
    };
    this.userGroupCtrl.push(new FormControl(group.id));
    this.store.dispatch(fromTrainingPackageiaActions.saveInvitationPack({package: this.package}));
  }
  removeUserGroup(item: Group) {
    let idx = this.package.current.userGroups.map(it => it.id).indexOf(item.id);
    let userGroups = [...this.package.current.userGroups];
    console.log(idx);
    userGroups.splice(idx, 1);
    this.package = {
      ...this.package,
      ...{
        current: {
          ...this.package.current,
          ...{
            userGroups: userGroups
          }
        }
      }
    };
    this.userGroupCtrl.removeAt(idx);
    this.store.dispatch(fromTrainingPackageiaActions.saveInvitationPack({package: this.package}));
  }
  get userGroupCtrl(): FormArray {
    return this.form.get('userGroups') as FormArray;
  }

  DateTimeValidator = (fc: FormControl) => {
    const date = new Date(fc.value);
    const isValid = !isNaN(date.valueOf());
    return isValid ? null : {dateNotValid: true};
  };

  save() {}
}

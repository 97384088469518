import { AuthService } from '@/auth/auth.service';
import { User } from '@/model/user.model';
import { Comment } from '@/model/comment.model';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromReducers from '@/store/offer/offers.reducers'
import * as fromActions from '@/store/offer/offer.actions';

import { SlideInOutAnimation } from '@/animations';
import { Observable, Subject } from 'rxjs';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';
import { ImagePipe } from '@/pipes/image.pipe';
import { environment } from 'environments/environment';
import { Actions, ofType } from '@ngrx/effects';
import { Offer } from '@/model/offer.model';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { KbFile } from '@/model/file.model';

@Component({
  selector: 'offer-candidates',
  templateUrl: './offer-candidates.component.html',
  styleUrls: ['./offer-candidates.component.scss'],
  animations: [SlideInOutAnimation]
})
export class OfferCandidatesComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  form: FormGroup;
  offer: Offer;
  user: User;
  showComments = false;
  moreAnimationState = 'out';
  showOverlay = false;
  showCommentOverlay = true;
  protected imagePipe: ImagePipe;
  fileList: NzUploadFile[] = [];
  candidates: any[];

  constructor(
    protected store: Store<fromReducers.State>,
    protected authService: AuthService,
    protected lightbox: Lightbox,
    protected lightboxConfig: LightboxConfig,
    protected actions: Actions,
    protected activeRoute: ActivatedRoute,
    protected httpClient: HttpClient,
    protected toaster: ToastrService,
    protected router: Router,
  ) {
    this.imagePipe = new ImagePipe();
    this.lightboxConfig.showZoom = true;
    this.lightboxConfig.centerVertically = true;
    this.lightboxConfig.wrapAround = true;
    this.fileList = [];
    this.form = new FormGroup({
      firstname: new FormControl(null, Validators.required),
      lastname: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email])
    });

  }

  download(file: KbFile) {
    this.httpClient
      .get(`${environment.apiUrl}/v1/kb/file/${file.id}/download`, {responseType: 'blob'})
      .subscribe(
        (response) => {
          let blob:any = new Blob([response], { type: file.mimeType });
          // saveAs(blob, file.name);

          const url = window.URL.createObjectURL(blob);
          var anchor = document.createElement('a');
        	anchor.href = url;
        	anchor.download = file.name;
        	document.body.appendChild(anchor);
        	anchor.click();
        },
        error => console.log('Error downloading the file'),
        () => console.info('File downloaded successfully')
    );
  }

  ngOnInit(): void {
    this.authService.getUser().then(user => {
      this.user = user
      this.form.patchValue(user);
    });
    this.activeRoute.paramMap.pipe(
      map((params: ParamMap) => parseInt(params.get('id')))
    ).subscribe( (id?: number) => {
      this.store.dispatch(fromActions.fetchOne({id}));
      this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectOfferById, id)).subscribe (offer => {
        if(!offer) return;
        this.offer = offer;
        this.httpClient.post<any>(`<backendhost>/v1/offers/${offer.id}/candidates`, { observe: 'body', responseType: 'json'})
        .subscribe((candidates) => {
          this.candidates = candidates;
        })
      })
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  backgroundStyle () {

    if(!this.offer)
      return null;
    if(!this.offer.background)
      return null;
    return {
      'aspect-ratio': '4',
      'background' : `url(${environment.apiUrl}/thumb/1920x0/${this.offer.background.replace('public','')}) center center`,
      'background-size': 'cover'
    }
  }


}

import { AuthService } from '@/auth/auth.service';
import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[checkRolePermition]'
})
export class CheckRolePermitionDirective {
  @Input('checkRolePermition') checkRolePermition: string | string[] = '';
  @Input('instance') instance;
  @Input('userProperty') userProperty = 'user';

  constructor(
    element: ElementRef,
    private authService: AuthService,
    private renderer: Renderer2,
  ) {
    this.renderer.addClass(element.nativeElement, 'd-none');
    setTimeout(() => {

      this.authService.checkPermition(this.checkRolePermition, this.instance, this.userProperty).then( (alowed:boolean) => {
        if (alowed) {
          this.renderer.removeClass(element.nativeElement, 'd-none');
        } else {
          element.nativeElement.remove();
        }
      });
    })
  }

}

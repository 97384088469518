import { Invitation, TrainingResult } from "@/model/package.model";
import { createPaginationInitialState, Pagination } from "@/model/pagination.model";
import { EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import * as actions from "./my-training.actions";

export interface State extends Pagination<Invitation> {
  mode: string
}

export const adapter: EntityAdapter<Invitation> = createEntityAdapter<Invitation>();
export const resultAdapter: EntityAdapter<TrainingResult> = createEntityAdapter<TrainingResult>();

export const initialState: State = {
  ...createPaginationInitialState(adapter, false, 10),
  ...{mode: 'active'}
}


export const reducer = createReducer(
  initialState,
  on(actions.clear, (state, action) => {
    return {...initialState}
  }),
  on(actions.fetch, (state, action) => {
    return {
      ...state,
      ...{ loading: true}
    }
  }),
  on(actions.setList, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.addMany(action.list, adapter.getInitialState()),
        total: action.total
      }
    }
  }),
  on(actions.setOne, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.addOne(action.invitation, state.list),
      }
    }
  }),
  on(actions.update, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.updateOne({id: action.invitation.id, changes: action.invitation}, state.list),
      }
    }
  }),
  on(actions.addResult, (state, action) => {
    let invitation = {...state.list.entities[action.invitation.id]};
    if (!invitation.results) invitation.results = resultAdapter.getInitialState();
    invitation.results = resultAdapter.addOne(action.result, invitation.results);
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.updateOne({
          id: invitation.id,
          changes: invitation
        }, state.list),
      }
    }
  }),
  on(actions.setResult, (state, action) => {
    let invitation = {...state.list.entities[action.invitation.id]};
    if (!invitation.results) invitation.results = resultAdapter.getInitialState();

    invitation.results = resultAdapter.setOne(action.result, invitation.results);
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.updateOne({
          id: invitation.id,
          changes: invitation
        }, state.list),
      }
    }
  }),
  on(actions.deleteResult, (state, action) => {
    let invitation = {...state.list.entities[action.invitation.id]};
    if (!invitation.results) invitation.results = resultAdapter.getInitialState();

    invitation.results = resultAdapter.removeOne(action.result.id, invitation.results);
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.updateOne({
          id: invitation.id,
          changes: invitation
        }, state.list),
      }
    }
  }),

  on(actions.fetch, (state, action) => { return {...state, ...{loading: true}}}),
  on(actions.setPage, (state, action) => { return {...state, ...{page: action.page}}}),
  on(actions.setOrder, (state, action) => { return {...state, ...{order: action.order}}}),
  on(actions.setDirection, (state, action) => { return {...state, ...{asc: action.asc}}}),
  on(actions.setSearch, (state, action) => { return {...state, ...{search: action.search}}}),
  on(actions.setMode, (state, action) => { return {...state, ...{mode: action.mode}}}),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectState = createFeatureSelector<State>('my-trainings');
export const selectAllMyTrainings = createSelector(selectState, (state) => selectAll(state.list) )
export const selectOneMyTraining = createSelector(selectState, (state: State, id) => state.list.entities[id]);

export const selectMyTrainingResult = createSelector(selectState, (state: State, {id,training}) => {
  let invitation = state.list.entities[id];
  if (!invitation) return null;
  return Object.values(invitation.results.entities).filter(result => {
    return result.training?.id == training.id && result.slide == null;
  }).pop();
});

export const selectMyTrainingCompletedResults = createSelector(selectState, (state: State, id) => {
  let invitation = state.list.entities[id];
  if (!invitation) return null;
  return Object.values(invitation.results.entities).filter(result => {
    return result.completed && result.slide == null;
  }).length;
});

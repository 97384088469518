import { createPaginationInitialState, Pagination } from "@/model/pagination.model";
import { Slot } from "@/model/slot.model";
import { User } from "@/model/user.model";
import { Vacant } from "@/model/vacant.model";
import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import * as actions from './vacant.actions';

export interface State extends Pagination<Vacant> {
  type: string,
  user: User,
  filter?: actions.VacantFilter,
  slot?: Slot
}

export const adapter: EntityAdapter<Vacant> = createEntityAdapter<Vacant>();

export const initialState: State = {
  ...createPaginationInitialState(adapter, false),
  ...{
    type: null,
    user: null,
    order: 'date',
    asc: true,
    slot: null,
    filter : {
      type: null,
      outpost: null,
      date: null
    }
  }
};

export const reducer = createReducer(
  initialState,
  on(actions.clear, (state) => initialState),
  on(actions.fetch, (state) => { return {...state, ...{loading: true}}}),
  on(actions.fetchMore, (state) => { return {...state, ...{loading: true}}}),
  on(actions.fetchOne, (state) => { return {...state, ...{loading: true}}}),

  on(actions.setType, (state, action) => { return {...state, ...{type: action.value}}}),
  on(actions.setUser, (state, action) => { return {...state, ...{user: action.user}}}),
  on(actions.setFilter, (state, action) => { return {...state, ...{filter: action.filter}}}),
  on(actions.setSlot, (state, action) => { return {...state, ...{slot: action.slot}}}),


  on(actions.setPage, (state, action) => { return {...state, ...{page: action.page}}}),
  on(actions.setOrder, (state, action) => { return {...state, ...{order: action.order}}}),
  on(actions.setDirection, (state, action) => { return {...state, ...{asc: action.asc}}}),
  on(actions.setSearch, (state, action) => { return {...state, ...{search: action.search}}}),
  on(actions.setMany, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: action.total,
        list: adapter.addMany(action.list, adapter.removeAll(state.list))
      }
    }
  }),
  on(actions.addMany, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: action.total == null ? state.total + action.list.length : action.total,
        list: adapter.addMany(action.list, state.list)
      }
    }
  }),
  on(actions.addOne, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: state.total + 1,
        list: adapter.addOne(action.vacant, state.list)
      }
    }
  }),
  on(actions.setOne, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.updateOne({ id: action.vacant.id, changes: action.vacant}, state.list)
      }
    }
  }),
  on(actions.removeOne, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: state.total - 1,
        list: adapter.removeOne(action.id, state.list)
      }
    }
  }),

);



export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectState = createFeatureSelector<State>('vacant');
export const selectCurrent = createSelector(selectState, (state) => selectAll(state.list) )
export const selectOne = createSelector(selectState, (state: State, id) => state.list.entities[id]);

import { Pagination, PaginationDTO } from "@/model/pagination.model";
import { Scorm } from "@/model/scorm.model";
import { StringifyHttpErrorResponse } from "@/utils/http.error.util";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, State, Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { catchError, map, switchMap } from "rxjs/operators";
import * as fromActions from "./scorm.actions";
import * as fromReducers from "./scorm.reducers";

@Injectable()
export class ScormEffects {
  state: fromReducers.State;

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private store$: Store<fromReducers.State>,
  ) {
    this.store$.pipe(select(fromReducers.selectState)).subscribe((state: fromReducers.State) => {
      this.state = state;
    })
  }

  onError (err, caught): any {
    this.toastr.error(StringifyHttpErrorResponse(err));
    return caught;
  };

  paginatorChange$ = createEffect(() => this.actions$.pipe(
    ofType(...[
      fromActions.setPage,
      fromActions.setSearch,
      fromActions.setOrder,
      fromActions.setDirection
    ]),
    map( () => fromActions.fetch())
  ));

  fetch$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetch),
    switchMap(() => {
      let params = new HttpParams();
      params = params.set('skip', this.state.limit * (this.state.page - 1));
      params = params.set('limit', this.state.limit);
      params = params.set('order', this.state.order);
      params = params.set('direction', this.state.asc ? 'asc' : 'desc');
      if(this.state.search)
        params = params.set('search', this.state.search);
      return this.httpClient.get<PaginationDTO<Scorm>>(`<backendhost>/v1/scorm`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: PaginationDTO<Scorm>) => [fromActions.setScorms(response)])
  ));

  fetchOne$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchOne),
    switchMap((action) => {
      return this.httpClient.get<Scorm>(`<backendhost>/v1/scorm/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((item: Scorm) => [fromActions.addScorm({item})])
  ));

  removeScorm$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.removeScorm),
    switchMap((action) => {
      return this.httpClient.delete<any>(`<backendhost>/v1/scorm/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map(() => action.id))
    }),
    catchError(this.onError.bind(this)),
    switchMap((id: number) => [fromActions.deleteScorm({id})])
  ));

  saveScorm$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.saveScorm),
    switchMap((action) => {
      return this.httpClient.put<Scorm>(`<backendhost>/v1/scorm/${action.item.id}`,
          action.item,
          {
            observe: 'body',
            responseType: 'json'
          });
    }),
    catchError(this.onError.bind(this)),
    switchMap((item: Scorm) => [fromActions.updateScorm({item})])
  ));

}

<div class="content">
  <div class="container pt-3 pb-3">
    <div class="row">
      <div class="col-12">
        <form [formGroup]="form" (ngSubmit)="save()" *ngIf="offer">
        <div class="card card-primary">
          <div class="overlay" *ngIf="(saving | async)">
            <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
              <i class="fas fa-3x fa-spinner fa-spin"></i>
              <p>Zapisywanie...</p>
            </div>
          </div>
          <div class="overlay" *ngIf="(loading | async)">
            <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
              <i class="fas fa-3x fa-spinner fa-spin"></i>
              <p>Ładowanie...</p>
            </div>
          </div>
          <div class="card-body">

            <div class="form-group">
              <label for="title">Tytuł</label>
              <input formControlName="title" [appIsValid]="form.controls.title" type="text" class="form-control" placeholder="Tytuł" />
              <div class="invalid-feedback" *ngIf="form.controls.title.errors?.required">
                Pole jest wymagane
              </div>
            </div>

            <div class="form-group">
              <label for="title">Kategoria</label>
              <nz-select nzMode="multiple" formControlName="category" [appIsValid]="form.controls.category" class="form-control-off" style="width:100%">
                <nz-option *ngFor="let category of categories" [nzValue]="category.id" [nzLabel]="category.name"></nz-option>
              </nz-select>
              <div class="invalid-feedback" *ngIf="form.controls.title.errors?.required">
                Pole jest wymagane
              </div>
            </div>

            <div class="form-group">
              <label for="content">Treść</label>
              <!-- <editor [init]="tinyMceConfig.init" apiKey="{{tinyMceConfig.apiKey}}" formControlName="content"></editor> -->
              <app-editor formControlName="content"></app-editor>
              <div class="text-danger" *ngIf="form.controls.content.touched && form.controls.content.errors?.required">
                Pole jest wymagane
              </div>
            </div>

            <!-- <div class="form-group">
              <label for="typeahead-prevent-manual-entry">Publikuj post tylko dla grup użytkowników</label>
              <ng-template #rt let-r="result" let-t="term">
                <div class="d-flex flex-md-row">
                <div class="mr-2">
                  <img class="img-circle elevation-3" style="height: 45px;width: 45px;" [src]="r | groupAvatar" alt="User Avatar"/>
                </div>
                <ngb-highlight class="align-self-center" [result]="formatter(r)" [term]="t"></ngb-highlight>
                </div>
              </ng-template>
              <input id="typeahead-prevent-manual-entry"  type="text" class="form-control" placeholder="Zacznij wpisywać"
                  [(ngModel)]="typeaheadModel"
                  [ngModelOptions]="{standalone: true}"
                  [ngbTypeahead]="search"
                  [resultTemplate]="rt"
                  [inputFormatter]="formatterEmpty"
                  [editable]='false'
                  (selectItem)="onTypeaheadSelect($event)"
                  (focus)="focus$.next($any($event).target.value)"
                  (click)="click$.next($any($event).target.value)"
                  #instance="ngbTypeahead"
                />

              <ul class="list-group mt-3">
                <li *ngFor="let item of typeaheadList; let idx = index; trackBy: groupIdentity" class="list-group-item ">
                  <div class="d-flex align-items-center">
                    <img class="img-circle elevation-3" [src]="item | groupAvatar" alt="{{formatter(item)}}"/>
                    <p class="pl-3 flex-grow-1  m-0">{{formatter(item)}}</p>
                    <button class="btn btn-danger btn-sm p-1 elevation-2" (click)="removeGroup(item)">
                      <i class="fas fa-times fa-fw fa-lg"></i>
                    </button>
                  </div>
                </li>
              </ul>
            </div> -->
            <div class="form-group">
              <label for="inputName">Obrazek w tle</label>
              <div style="max-width: 100%;">
                <app-upload-crop-image
                  [avatar]="false"
                  [(file)] = "backgroundFile"
                  [image]="offer?.background ? (offer.background | image) : null"
                  [aspectRatio]="4 / 1">
                </app-upload-crop-image>
                <!-- <nz-upload
                  class="bg-uploader  w-100"
                  nzAccept="image/png, image/jpeg"
                  nzName="image"
                  nzListType="picture-card"
                  [nzShowUploadList]="false"
                  [nzBeforeUpload]="beforeUploadBackground"
                >
                  <ng-container *ngIf="!offer?.background" class="w-100">
                    <i class="fas fa-3x fa-image"></i>
                  </ng-container>
                  <img *ngIf="offer?.background" [src]="offer.background | image" style="width: 100%" />
                </nz-upload> -->
              </div>
            </div>
            <div class="form-group" >
              <label>Tagi</label>
              <tag-input
                [(ngModel)]='offer.tags'
                [ngModelOptions]="{standalone: true}"
                theme='bootstrap'
                secondaryPlaceholder="Wpisz nowy tag"></tag-input>
            </div>

            <div class="form-group">
              <label>Data początku ważności newsa</label>
              <div class="input group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <input type="checkbox"
                      [(ngModel)]='offer.startDateActive'
                      [ngModelOptions]="{standalone: true}"
                      aria-label="Checkbox for following text input">
                  </div>
                  <app-date-time-picker formControlName="startDate" name="startDate" [disabled]="!offer.startDateActive"></app-date-time-picker>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Data końca ważności oferty</label>
              <div class="input group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <input type="checkbox"
                      [(ngModel)]='offer.endDateActive'
                      [ngModelOptions]="{standalone: true}"
                      aria-label="Checkbox for following text input">
                  </div>
                  <app-date-time-picker formControlName="endDate" name="endDate" [disabled]="!offer.endDateActive"></app-date-time-picker>
                </div>
              </div>
            </div>

          </div>
          <div class="card-footer">
            <button class="btn btn-success float-right" type="submit" (mouseDown)="save()" (touchEnd)="save()">
              Zapisz
              <i class="fas fa-angle-right fa-fw"></i>
            </button>
          </div>
        </div>
        </form>
      </div>
    </div>
  </div>
</div>

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import * as fromPostReducer from '@/store/post/post.reducers';
import * as fromPostActions from '@/store/post/post.actions';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { Post } from '@/model/post.model';
import { Subject } from 'rxjs';
import { PostBoxComponent } from '@components/post-box/post-box.component';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'app-view-news',
  templateUrl: './view-news.component.html',
  styleUrls: ['./view-news.component.scss']
})
export class ViewNewsComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  post: Post;
  @ViewChild('postBox') postBox : PostBoxComponent;

  constructor(
    private route: ActivatedRoute,
    private actionsSubject: ActionsSubject,
    private store: Store<fromPostReducer.PostsState>
  ) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(
      map((params: ParamMap) => parseInt(params.get('id')))
    ).subscribe( (id: number) => {
      this.store.dispatch(fromPostActions.fetchOneAction({id}));
      this.store.pipe(takeUntil(this.unsubscribe$), select(fromPostReducer.selectEntity, id)).subscribe((post: Post | null) => {
        this.post = post;
        this.seen();
      })
    })

  }

  ngAfterViewInit(): void {
    this.route.fragment.subscribe( (fragment: string) => {
      if(fragment && fragment.indexOf('comment') == 0) {
        this.postBox.toggleShowComments();
        this.actionsSubject.pipe(
          takeUntil(this.unsubscribe$),
          ofType(fromPostActions.addManyCommentsToPostAction))
            .subscribe(() => {
              setTimeout(() => {
                console.log(document.getElementById(fragment));
                document.getElementById(fragment).scrollIntoView();
              })

            })
      }
    })

  }

  seen () {
    // console.log("SEEEEN");
    if (!this.post.seen && !this.post.important) {
      this.store.dispatch(fromPostActions.setAsSeen({post: this.post}));
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

<section class="content">
  <div class="container">
    <form [formGroup]="form" (ngSubmit)="save()">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">{{survey.name}}</h3>
        </div>
        <div [ngStyle]="backgroundStyle()"></div>
        <div class="card-body" >
          <div [innerHtml]="survey.content | safeHtml"></div>
          <h4>
            <small>Data zakończenia ankiety: </small>
            {{survey.endDate | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}
          </h4>
        </div>
        <div class="list-group" >
          <div class="list-group-item" *ngFor="let question of questions; let qIdx = index">
            <div class="form-group" [formGroup]="getQuestionGroup(question)">
              <label for="inputName">{{question.label}} </label>

              <div *ngIf="question.type == 'radio'" >
                <div class="form-check" *ngFor="let option of question.options; let aIdx = index" >
                  <label class="form-check-label">
                    <input class="form-check-input" type="radio" formControlName="answer" [value]="option.id">
                    <span>{{option.label}}</span>
                  </label>
                </div>
              </div>
              <div *ngIf="question.type == 'checkbox'" >
                <div class="form-check" *ngFor="let option of question.options; let aIdx = index" formGroupName="answer">
                  <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" [formControlName]="option.id" [value]="option.label">
                    <span>{{option.label}}</span>
                  </label>
                </div>
              </div>
              <div *ngIf="question.type == 'open'" >
                <div class="form-check" >
                  <textarea class="form-control" formControlName="answer" ></textarea>
                </div>
              </div>
              <div *ngIf="question.type == 'scale'" class="d-flex">
                <div class="form-check" *ngFor="let option of question.options; let aIdx = index" >
                  <label class="form-check-label d-block">
                    <p>{{option.label}}</p>
                    <input type="radio" formControlName="answer" [value]="option.id">
                  </label>
                </div>
              </div>

            </div>
          </div>
        </div>
        <!-- <pre>{{ form.value | json}}</pre> -->
        <!-- <pre>{{ parseResult(form.value) | json}}</pre> -->
        <div class="card-footer">
          <button class="btn btn-success float-right" type="submit" >
            Zapisz
            <i class="fas fa-angle-right fa-fw fa-lg"></i>
          </button>
        </div>
      </div>
    </form>
  </div>
</section>

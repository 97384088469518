import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import * as fromActions from '@/store/order/order.actions';
import * as fromReducers from '@/store/order/order.reducer';
import { Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderFormInputModalComponent } from '../order-form-input-modal/order-form-input-modal.component';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { OrderFormInput } from '@/model/order.form.model';
import { ofType } from '@ngrx/effects';
import { ComponentWithUnsavedData } from '@guards/can-deactivate.guard';

@Component({
  selector: 'app-add-order-form',
  templateUrl: './add-order-form.component.html',
  styleUrls: ['./add-order-form.component.scss']
})
export class AddOrderFormComponent implements OnInit, OnDestroy, ComponentWithUnsavedData {
  protected readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  isSubmited = false;
  isEdited = false;
  disabled = false;
  uploading = false;
  imageFile: NzUploadFile;
  backgroundFile: NzUploadFile;
  workStage: fromActions.OrderFormWorkStage = {
    orderForm: null,
    imageFile: null,
    backgroundFile: null
  };

  constructor(
    protected store: Store<fromReducers.State>,
    protected modalService: NgbModal,
    protected toastr: ToastrService,
    protected actionsSubject$: ActionsSubject,
    protected router: Router
  ) {
    this.store.dispatch(fromActions.cleanWorkspace());

  }
  hasUnsavedData(): boolean {
    return !this.isSubmited && (this.form.dirty || this.isEdited);
  }

  @HostListener('window:beforeunload', ['$event'])
  canDeactivate($event: any) {
    if (this.hasUnsavedData()) {
      $event.returnValue = true;
    }
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      title: new FormControl(null, Validators.required),
      description: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
    // this.form.valueChanges.subscribe((value) => {
    //   let orderForm = {...this.workStage.orderForm, ...value};
  // this.store.dispatch(fromActions.setWorkspace({orderForm}));
    // });
    this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromReducers.selectWorkStage)).subscribe(stage => {
        if(stage)
        this.form.patchValue(stage.orderForm);
        this.workStage = stage;
      })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  save(): void {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    let valid =  this.form.valid;
    if (valid) {
      this.isSubmited = true;

      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromActions.setOrderForm)
      ).subscribe( (action:any) => {
        this.toastr.success("Zapisano zmiany");
        this.router.navigate(['/orders/admin'])
      })
      let orderForm = {...this.workStage.orderForm, ...this.form.value};
      this.store.dispatch(fromActions.saveOrderFormWorkStage({
        orderForm: orderForm,
        imageFile: this.imageFile as any,
        backgroundFile: this.backgroundFile as any,
      }));
    } else{
      this.toastr.error("Formularz zawiera błędy");
    }
  }

  get saving(): Observable<boolean> {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromReducers.selectOrderFormsState),
      map( (state:fromReducers.State) => state.saving));
  }

  addInput() {
    this.isEdited = true;
    let orderForm = {...this.workStage.orderForm, ...this.form.value};
    this.store.dispatch(fromActions.setWorkspace({orderForm}));
    const modalRef = this.modalService.open(OrderFormInputModalComponent, {size: 'xl',scrollable: false});
    // modalRef.componentInstance.user = user;)
  }

  editInput(item: OrderFormInput) {
    this.isEdited = true;
    let orderForm = {...this.workStage.orderForm, ...this.form.value};
    this.store.dispatch(fromActions.setWorkspace({orderForm}));
    const modalRef = this.modalService.open(OrderFormInputModalComponent, {size: 'xl',scrollable: false});
    modalRef.componentInstance.orderFormInput = item;
  }

  deleteInput(idx: number) {
    this.isEdited = true;
    let inputs = [...this.workStage.orderForm.inputs];
    inputs.splice(idx,1);
    let orderForm = {...this.workStage.orderForm, ...this.form.value, ...{inputs : inputs}};
    this.store.dispatch(fromActions.setWorkspace({orderForm}));
  }

  getTypeName(item: OrderFormInput): string {
    return OrderFormInputModalComponent.types[item.type];
  }
  checkMultiple(item: OrderFormInput): boolean {
    return OrderFormInputModalComponent.isMultiple(item.type);
  }

  // get workStage(): Observable<fromActions.OrderFormWorkStage> {
  //   return this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectWorkStage));
  // }
}


<div class="content-header pt-0">
  <div class="row">
    <div class="col-12 text-right">
      <a [routerLink]="['/ideas/admin']" class="btn btn-primary ml-2"  checkRolePermition="ideas.edit">
        <i class="fas fa-tools fa-fw"></i> Zarządzaj
      </a>
      <a [routerLink]="['/ideas/add']" class="btn btn-success ml-2"  checkRolePermition="ideas.create">
        <i class="far fa-lightbulb fa-fw"></i> Zgłoś nowy pomysł
      </a>
    </div>
  </div>
</div>

<router-outlet></router-outlet>

<!-- <p>Prevents manual text entry and updates the model only when an item from suggestions is selected</p> -->

<ng-template #rt let-r="result" let-t="term">
  <div class="d-flex flex-md-row">
  <div class="user-panel mr-2">
    <img [src]="r | userAvatar" class="img-circle">
  </div>
  <ngb-highlight class="align-self-center" [result]="formatter(r)" [term]="t"></ngb-highlight>
  </div>
</ng-template>


<div class="card">

  <div class="card-body" checkRolePermition="groups.superior.create">
  <label for="typeahead-prevent-manual-entry">Dodaj użytkownika do grupy</label>
  <input id="typeahead-prevent-manual-entry"  type="text" class="form-control" placeholder="Zacznij wpisywać"
        [(ngModel)]="model"
        [ngbTypeahead]="search"
        [resultTemplate]="rt"
        [inputFormatter]="formatterEmpty"
        [editable]='false'
        (selectItem)="onUserSelect($event)"/>
  </div>

  <table class="table">
    <thead>
      <tr>
        <th style="width:1px">#</th>
        <th colspan=2>
          <div class="d-flex flex-md-row">
            <div>Użytkownik</div>
          </div>
        </th>
        <th>Data dodania</th>
        <th  colspan=2>Dodany przez </th>
        <th style="width:1px" class="text-center text-nowrap"  checkRolePermition="groups.superior.edit" instance="group">Obserwuje</th>
        <th style="width:1px" class="text-center text-nowrap"  checkRolePermition="groups.superior.edit" instance="group">Może opuścić</th>
        <!-- <th style="width:1px" class="text-center text-nowrap">Może publikować</th> -->
        <!-- <th style="width:1px" class="text-center text-nowrap">Może edytować</th> -->
        <th style="width:1px" checkRolePermition="groups.superior.delete" instance="group"></th>
      </tr>
    </thead>
    <tbody *ngIf="group && group.members">
      <tr *ngFor="let item of members; let idx = index; trackBy: groupMemberIdentity" >
        <td  >{{idx+1 + (page-1)*pageSize}}</td>
        <td class="user-panel" style="width:1px">
          <img
                [src]="item.user | userAvatar: 34"
                class="img-circle elevation-2"
                alt="User Image"
                [appUserDisactivatable]="item.user"
            />
        </td>
        <td>
            <strong [appUserDisactivatable]="item.user">{{item.user.firstname}} {{item.user.lastname}}</strong> <br>
            <small [appUserDisactivatable]="item.user">{{ item.user?.active ? item.user?.email : '-' }}</small>
        </td>
        <td>
          <span>{{item.createdAt | amUtc | amLocal | amDateFormat:'DD.MM.YYYY HH:mm'}}</span>
        </td>
        <td class="user-panel" style="width:1px">
          <img
                [src]="item.createdBy  | userAvatar: 34"
                class="img-circle elevation-2"
                alt="User Image"
            />
        </td>
        <td>
          <span class="text-decoration-line-through" *ngIf="!item?.createdBy">Użytkownik usunięty</span>
          <strong>{{item.createdBy?.firstname}} {{item.createdBy?.lastname}}</strong> <br>
          <small>{{item.createdBy?.email}}</small>
        </td>
        <td class="text-center" checkRolePermition="groups.superior.edit" instance="group">
          <fa-checkbox [(model)]="item.observing" (modelChange)="updateMemeber(item)" [isEdited]="isUpdated"></fa-checkbox>
        </td>
        <td class="text-center" checkRolePermition="groups.superior.edit" instance="group">
          <fa-checkbox [(model)]="item.canLeave" (modelChange)="updateMemeber(item)" [isEdited]="isUpdated"></fa-checkbox>
        </td>
        <!-- <td class="text-center">
          <fa-checkbox [(model)]="item.canPublicate" (modelChange)="updateMemeber(item)" [isEdited]="isUpdated"></fa-checkbox>
        </td>
        <td class="text-center">
          <fa-checkbox [(model)]="item.canEdit" (modelChange)="updateMemeber(item)" [isEdited]="isUpdated"></fa-checkbox>
        </td> -->
        <td checkRolePermition="groups.superior.delete" instance="group">
          <button class="btn btn-danger btn-sm p-1 elevation-2" (click)="deleteMemeber(item)" [disabled]="isDeleted">
            <i class="fas fa-trash fa-fw fa-lg"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="card-footer" *ngIf="group && group.members">
    <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="group.members.ids.length" (pageChange)="onPageChange()">
      <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationEllipsis>...</ng-template>
      <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>
  </div>

</div>

<!-- <pre>{{group | json }}</pre> -->

import { HttpClient, HttpParams, HttpBackend } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, Effect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import { Observable, of, throwError } from "rxjs";

import { Post } from "@/model/post.model";
import * as PostReducers from './post.reducers';
import * as PostActions from './post.actions';
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { User } from "@/model/user.model";
import { Comment } from "@/model/comment.model";
import { AuthService } from "@/auth/auth.service";
import { environment } from "environments/environment";
import * as fromGroupActions from '@/store/group/group.actions';
import * as fromGroupReducers from '@/store/group/group.reducers';
import { AppNotification } from "@/model/notification.model";
import * as fromNotificationActions from '@/store/notification/notification.actions';
import * as fromNotificationReducers from '@/store/notification/notification.reducers';
import { Socket } from "ngx-socket-io";



@Injectable()
export class PostsEffects {
  user: User;

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private httpBackend: HttpBackend,
    private store$: Store<PostReducers.PostsState>,
    private groupStore$: Store<fromGroupReducers.State>,
    private notificationStore$: Store<fromNotificationReducers.State>,
    private authService: AuthService,
    private toastr: ToastrService,
    private socket: Socket,
  ) {
    this.authService.getUser().then(user => {this.user = user});
  }
  private pageSize: number = environment.pageSize;

  onError (err, caught): any {
    this.toastr.error(err.message)
    return caught;
  };

  fetchNews$ = createEffect(() => this.actions$.pipe(
    ofType(PostActions.fetchNewsAction),
    switchMap((action) => {
      let params = new HttpParams();
      if(action.group) {
        params = params.set('group', action.group);
      }
      if(action.before) {
        params = params.set('before', action.before);
      }
      if(action.after) {
        params = params.set('after', action.after);
      }
      if(action.term) {
        params = params.set('term', action.term);
      }
      if(action.all) {
        params = params.set('all', 1);
      }
      if(action.observed) {
        params = params.set('observed', 1);
      }
      if(action.unseen) {
        params = params.set('unseen', 1);
      }
      if(action.favorite) {
        params = params.set('favorite', 1);
      }
      // params = params.set('size', this.pageSize);
      return this.httpClient.get<Post[]>('<backendhost>/v1/posts', {
        params: params,
        observe: 'body',
        responseType: 'json'
      }).pipe(
        map(response => { return {response: response, action: action}})
      );
    }),
    catchError(this.onError.bind(this)),
    switchMap((payload: {response: Post[], action: any}) => {
      let actionsToDistpatch = [];
      actionsToDistpatch.push(PostActions.addMultipleNews({posts: payload.response}));
      actionsToDistpatch.push(PostActions.laodingCompleted());
      actionsToDistpatch.push(PostActions.setHasMore({hasMore: payload.response.length == this.pageSize}));
      return actionsToDistpatch;
    })
  ));

  fetchOneNews$ = createEffect(() => this.actions$.pipe(
    ofType(PostActions.fetchOneAction),
    switchMap((action) => {
      return this.httpClient.get<Post>(`<backendhost>/v1/posts/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      }).pipe(
        map(response => { return {response: response, action: action}})
      );
    }),
    catchError(this.onError.bind(this)),
    switchMap((payload: {response: Post, action: any}) => {
      let actionsToDistpatch = [];
      actionsToDistpatch.push(PostActions.addMultipleNews({posts: [payload.response]}));
      actionsToDistpatch.push(PostActions.laodingCompleted());
      return actionsToDistpatch;
    })
  ));

  createNewPost$ = createEffect(() => this.actions$.pipe(
    ofType(PostActions.createNewPostAction),
    switchMap((action) => {
      const formData = new FormData();
      if(action.background) {
        formData.append('background', action.background, action.background.name);
      }
      if(action.audioFile) {
        formData.append('audio', action.audioFile, action.audioFile.name);
      }
      formData.append('data', JSON.stringify(action.post));
      return this.httpClient.post<Post>('<backendhost>/v1/posts',
        // action.post,
        formData,
        { observe: 'body', responseType: 'json'})
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: Post) => [
      PostActions.addOneNews({post: response}),
      PostActions.addMultipleNews({posts: [response]})
    ])
  ));

  savePost$ = createEffect(() => this.actions$.pipe(
    ofType(PostActions.savePost),
    switchMap((action) => {
      const formData = new FormData();
      if(action.background) {
        formData.append('background', action.background, action.background.name);
      }
      if(action.audioFile) {
        formData.append('audio', action.audioFile, action.audioFile.name);
      }
      formData.append('data', JSON.stringify(action.post));
      return this.httpClient.post<Post>(`<backendhost>/v1/posts/${action.post.id}/update`,
        // action.post,
        formData,
        { observe: 'body', responseType: 'json'})
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: Post) => [
      PostActions.updateNews({post: response}),
    ])
  ));

  deletePost$ = createEffect(() => this.actions$.pipe(
    ofType(PostActions.deletePostAction),
    switchMap((action) => {
      return this.httpClient
        .delete<Post>(`<backendhost>/v1/posts/${action.post.id}`, { observe: 'body', responseType: 'json'})
        .pipe(map(() => action.post))
    }),
    catchError(this.onError.bind(this)),
    switchMap((post: Post) => [
      PostActions.removePostAction({post}),
    ])
  ));

  fetchPostComments$ = createEffect(() => this.actions$.pipe(
    ofType(PostActions.fetchPostCommentsAction),
    switchMap((action) => this.httpClient.get<Comment[]>(`<backendhost>/v1/post/${action.post.id}/comment`,{
        observe: 'body',
        responseType: 'json'
    }).pipe(map( (response: Comment[]) => {
      return {comments: response, post: action.post}
    }))),
    catchError(this.onError.bind(this)),
    switchMap((response: { comments: Comment[], post: Post}) => [PostActions.addManyCommentsToPostAction(response)])
  ));

  createPostComment$ = createEffect(() => this.actions$.pipe(
    ofType(PostActions.createPostCommentAction),
    switchMap( action => {
      let url = action.replyTo
          ? `<backendhost>/v1/post/${action.post.id}/reply/${action.replyTo.id}/comment`
          : `<backendhost>/v1/post/${action.post.id}/comment` ;
      return this.httpClient.post<Comment>(url, action.comment, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map( (response:Comment) => {
        return { comment: response, action: action};
      }))
    }),
    catchError(this.onError.bind(this)),
    switchMap((payload: { comment: Comment, action: any}) => {
      let actionsToDispatch = [];
      if (payload.action.replyTo) {
        actionsToDispatch.push(PostActions.addCommentToCommentAction({ comment: payload.comment, post: payload.action.post, replyTo: payload.action.replyTo}))
        actionsToDispatch.push(PostActions.addCommentSuccess({ comment: payload.comment, post: payload.action.post, replyTo: payload.action.replyTo}))
      }
      else {
        actionsToDispatch.push(PostActions.addCommentToPostAction({ comment: payload.comment, post: payload.action.post}))
        actionsToDispatch.push(PostActions.addCommentSuccess({ comment: payload.comment, post: payload.action.post}))
      }

      return actionsToDispatch;
    })
  ));

  likePost$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PostActions.likePostAction),
      switchMap((action) => {
        let url = `<backendhost>/v1/post/${action.post.id}/like` ;
        return this.httpClient.post<Post>(url, {}, {observe: 'body', responseType: 'json'});
      }),
      catchError(this.onError.bind(this)),
      switchMap((response: any) => {
        return [PostActions.updateNews({post: {id: response.id, liked: true, likeCount: response.likeCount} as Post})];
      })
    );
  });

  unlikePost$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PostActions.unlikePostAction),
      switchMap((action) => {
        let url = `<backendhost>/v1/post/${action.post.id}/like` ;
        return this.httpClient.delete<Post>(url, {observe: 'body', responseType: 'json'});
      }),
      catchError(this.onError.bind(this)),
      switchMap((response: Post) => {
        return [PostActions.updateNews({post: {id: response.id, liked: false, likeCount: response.likeCount} as Post})];
      })
    );
  });

  likeComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PostActions.likeCommentAction),
      switchMap((action) => {
        let url = `<backendhost>/v1/comment/${action.comment.id}/like` ;
        return this.httpClient.post<Comment>(url, {}, {observe: 'body', responseType: 'json'});
      }),
      catchError(this.onError.bind(this)),
      switchMap((response: Comment) => {
        let comment = {id: response.id, likeCount: response.likeCount, liked: true, likeSaving: false} as Comment;
        if (response.replyTo?.id) {
          return [PostActions.updateReplyCommentAction({post: response.post, comment: comment, replyTo: response.replyTo})];
        }
        else {
          return [PostActions.updateCommentAction({post: response.post, comment: comment})];
        }

      })
    );
  });

  setAsSeen$ = createEffect(() => this.actions$.pipe(
    ofType(PostActions.setAsSeen),
    map( (action) => {
      console.log("SET AS SEEN", action.post.id);
      let url = `<backendhost>/v1/posts/${action.post.id}/seen` ;
      this.notificationStore$.dispatch( fromNotificationActions.remove({notification: {id: `${action.post.id}`} as AppNotification}));
      this.store$.dispatch( PostActions.setAsSeenComplete({post : action.post}));
      // this.store$.dispatch( PostActions.updateNews({post : {...action.post, seenCount: action.post}));
      // return this.httpClient.post<void>(url,{}, {reportProgress: true, observe: 'body', responseType: 'json'})
      // .pipe(map( () => action.post));
      // return of(action.post);
      this.socket.emit('post seen', action.post.id);
      action.post.groups.forEach( (group) => {
        group = {...group, ...{unseenCount: group.unseenCount - 1}};
        this.groupStore$.dispatch(fromGroupActions.updateGroupAction({group}));
      })
      return (action.post);
    }),
    switchMap((post) => {
      return [
        // PostActions.setAsSeenComplete({post}),
      ]
    })
  ));

  unlikeComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PostActions.unlikeCommentAction),
      switchMap((action) => {
        let url = `<backendhost>/v1/comment/${action.comment.id}/like` ;
        return this.httpClient.delete<Comment>(url, {observe: 'body', responseType: 'json'});
      }),
      catchError(this.onError.bind(this)),
      switchMap((response: Comment) => {
        let comment = {id: response.id, likeCount: response.likeCount, liked: false, likeSaving: false} as Comment;
        if (response.replyTo?.id) {
          return [PostActions.updateReplyCommentAction({post: response.post, comment: comment, replyTo: response.replyTo})];
        }
        else {
          return [PostActions.updateCommentAction({post: response.post, comment: comment})];
        }

      })
    );
  });
  deleteComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PostActions.deleteComment),
      switchMap((action) => {
        let url = `<backendhost>/v1/post/${action.comment.post.id}/comment/${action.comment.id}` ;
        return this.httpClient.delete<Comment>(url, {observe: 'body', responseType: 'json'});
      }),
      catchError(this.onError.bind(this)),
      switchMap((response: Comment) => [])
    );
  });

  toogglePostFavorite$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PostActions.toogglePostFavorite),
      switchMap((action) => {
        const url = `<backendhost>/v1/posts/${action.post.id}/favorite`;
        return this.httpClient.post<Post>(url, {favorite: !action.post.favorite}, {observe: 'body', responseType: 'json'});
      }),
      catchError(this.onError.bind(this)),
      switchMap((response: Post) => {
        const id = response.id;
        const favorite = response.favorite;
        const favoriteSaving = false;
        return [PostActions.updateNews({post: {id, favorite, favoriteSaving} as Post})]
      })
    );
  });
}

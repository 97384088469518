import { Component } from '@angular/core';
import * as fromPostReducers from '@/store/post/post.reducers';
import * as fromPostActions from '@/store/post/post.actions';

import { ActionsSubject, select, Store } from '@ngrx/store';
import { Observable} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Post } from '@/model/post.model';
import { ListNewsComponent } from './list-news.component';

@Component({
  templateUrl: './list-news.component.html',
  styleUrls: ['./list-news.component.scss']
})
export class ListNewsFavoriteComponent extends ListNewsComponent {
  constructor(
    store: Store<fromPostReducers.PostsState>,
    router: Router,
    activatedRoute: ActivatedRoute,
    actionsSubject$: ActionsSubject,
  ) {
    super(store, router, activatedRoute, actionsSubject$);
  }

  ngOnInit(): void {
    this.onInit();
    this.store.dispatch(fromPostActions.fetchNewsAction({favorite: true}));
  }

  get posts(): Observable<Post[]> {
    return this.store.pipe(takeUntil(this.unsubscribe$),select(fromPostReducers.selectPostsFavorite));
  }

  protected dispatchMore() {
    this.store.dispatch(fromPostActions.fetchNewsAction({observed: false, before: this.oldestId }));
  }
}

import { PaginationDTO } from "@/model/pagination.model";
import { Survey } from "@/model/survey.model";
import { createAction, props } from "@ngrx/store";

export const notFound = createAction("my-survey notFound");
export const clear = createAction("my-survey clear");
export const success = createAction("my-survey success");
export const fetch = createAction("my-survey fetch");
export const fetchOne = createAction("my-survey fetchOne", props<{id: number}>());

export const setPage = createAction("my-survey setPage", props<{ page: number }>());
export const setOrder = createAction("my-survey setOrder", props<{ order: string }>());
export const setDirection = createAction("my-survey setDirection", props<{ asc: boolean }>());
export const setSearch = createAction("my-survey setSearch", props<{ search: string }>());

export const setMySurveys = createAction("my-survey setMySurveys", props<PaginationDTO<Survey>>());
export const addMySurvey = createAction("my-survey addMySurvey", props<{survey: Survey}>());
export const submitSurvey = createAction("my-survey submitSurvey", props<{survey: Survey, data: any}>());

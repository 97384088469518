<section class="content">
  <div class="container pt-3 pb-3">
    <app-user-tabs *ngIf="!myaccount"></app-user-tabs>
    <app-account-tabs *ngIf="myaccount"></app-account-tabs>
    <div class="card" style="border-top-left-radius: 0;border-top-right-radius: 0;">
      <div class="card-header d-flex align-items-center">
        <h3 class="card-title m-0 flex-grow-1 text-bold"><i class="fas fa-flask fa-fw"></i> Sloty</h3>
      </div>
      <div class="table-responsive position-relative">
        <table class="table align-middle table-hover">
          <thead>
            <tr>
              <th style="width:1rem">#</th>
              <th class="sortable" (click)="setSort('outpost')"  [ngClass]="{'active': (order | async) == 'outpost'}"  >
                Placówka
                <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
                <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
              </th>
              <th class="sortable" (click)="setSort('type')"  [ngClass]="{'active': (order | async) == 'type'}"  >
                Rodzaj
                <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
                <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
              </th>
              <th class="sortable" (click)="setSort('day')"  [ngClass]="{'active': (order | async) == 'day'}"  >
                Dzień
                <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
                <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
              </th>
              <th class="sortable" (click)="setSort('startTime')"  [ngClass]="{'active': (order | async) == 'startTime'}"  >
                Początek
                <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
                <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
              </th>
              <th class="sortable" (click)="setSort('endTime')"  [ngClass]="{'active': (order | async) == 'endTime'}"  >
                Koniec
                <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
                <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
              </th>
              <th class="text-right" ></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of list | async; let idx = index; trackBy: identify">
              <th class="align-middle">{{idx + 1}}</th>
              <td class="align-middle">{{item.outpost.name}}</td>
              <td class="align-middle">{{item.type}}</td>
              <td class="align-middle">{{item.day == "WEEKDAY" ? "Dzień roboczy" : "Sobota"}}</td>
              <td class="align-middle">{{item.startTime}}</td>
              <td class="align-middle">{{item.endTime}}</td>
              <td class="align-middle text-nowrap text-right" >
                <button class="btn btn-primary btn-sm elevation-2 mr-2" [routerLink]="['/outposts',item.outpost.id,'slots', item.id, 'edit']" ngbTooltip="Edycja" checkRolePermition="outpost.admin">
                  <i class="fas fa-edit fa-fw fa-lg"></i>
                </button>
                <button
                    checkRolePermition="outpost.admin"
                    class="btn btn-danger btn-sm elevation-2"
                    nz-popconfirm
                    nzPopconfirmTitle="Czy na pewno usunąć?"
                    nzOkText="Tak"
                    nzCancelText="Nie"
                    nzPopconfirmPlacement="left"
                    ngbTooltip="Usuń"
                    (nzOnConfirm)="delete(item)" >
                  <i class="fas fa-trash fa-fw fa-lg"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(list | async).length == 0"></nz-empty>
        <div class="overlay position-absolute" *ngIf="(loading | async)" style="top: 0; bottom: 0; left: 0; right: 0">
          <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
            <i class="fas fa-3x fa-spinner fa-spin"></i>
            <p>Ładowanie...</p>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="(total | async)"  (pageChange)="pageChange($event)">
          <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
</section>

<section class="content">
  <form [formGroup]="filterForm" (ngSubmit)="save()">
  <div class="container pt-3 pb-3">
    <div class="card" style="border-top-left-radius: 0;border-top-right-radius: 0;">
      <div class="card-body row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Data</label>
            <div class="input-group">
              <nz-range-picker formControlName="date" class="w-100"></nz-range-picker>
            </div>
            <div class="text-danger" *ngIf="filterForm.controls.date.errors?.reqiored">
              Pole jest wymagane
            </div>
            <div class="text-danger" *ngIf="filterForm.controls.date.errors?.dateNotValid">
              Data jest nieprawidłowa
            </div>
          </div>
        </div>
        <!-- <div class="col-md-12"><hr></div> -->


        <!-- <div class="form-group col-md-6 position-relative">
          <label>Placówka</label>
          <outpost-input formControlName="outpost"></outpost-input>
        </div>
        <div class="form-group col-md-6">
          <label>Rodzaj</label>
          <div class="form-check" *ngFor="let enum of SlotType">
            <input class="form-check-input" type="radio" [value]="enum" formControlName="type" [id]="enum">
            <label class="form-check-label" [for]="enum">{{enum}}</label>
          </div>
        </div> -->
      </div>

      <div class="col-md-12"><hr></div>

      <div class="form-group col-md-12">
        <label>Komentarz</label>
        <textarea class="form-control" formControlName="comment"></textarea>
      </div>

      <div class="card-header d-flex align-items-center mb-3">
        <h3 class="card-title"><i class="fas fa-bell fa-fw"></i> Powiadomienia</h3>
      </div>

      <div class="form-group col-md-12">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="sendPush" id="sendPushCheckbox">
          <label class="form-check-label" for="sendPushCheckbox">
            Wyślij powiadomienie Push
          </label>
        </div>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="sendEmail" id="sendEmailCheckbox">
          <label class="form-check-label" for="sendEmailCheckbox">
            Wyślij powiadomienie Email
          </label>
        </div>
      </div>
      <div class="form-group col-md-12 position-relative d-none">
        <label>Użytkownicy do powiadomienia o rezerwacji / usunięcia rezerwacji</label>
        <app-user-input formControlName="notificationUsers" [multiple]="true"></app-user-input>
      </div>

      <div class="card-header d-flex align-items-center">
        <h3 class="card-title m-0 flex-grow-1 text-bold"><i class="fas fa-flask fa-fw"></i> Sloty</h3>
      </div>
      <div class="table-responsive position-relative">
        <table class="table align-middle table-hover">
          <tbody formGroupName="slots">
            <tr *ngFor="let item of list | async; let idx = index; trackBy: identify" (click)="toggleSlot(item.id.toString())">
              <th class="align-middle text-nowrap">
                <!-- {{idx + 1}} -->
                <input type="checkbox" [formControlName]="item.id">
              </th>
              <td class="align-middle text-nowrap">{{item.outpost.name}}</td>
              <td class="align-middle text-nowrap">{{item.type}}</td>
              <td class="align-middle text-nowrap">{{item.day == "WEEKDAY" ? "Dzień roboczy" : "Sobota"}}</td>
              <td class="align-middle text-nowrap">{{item.startTime}}</td>
              <td class="align-middle text-nowrap">{{item.endTime}}</td>
              <td class="align-middle p-1 text-nowrap" style="min-width:45px" *ngIf="item.user">
                <img
                      [src]="item.user  | userAvatar: 34"
                      class="img-circle elevation-2"
                      alt="User Image"
                      style="width:40px"
                      [appUserDisactivatable]="item.user"
                  />
              </td>
              <td class="align-middle text-nowrap pl-0"  *ngIf="item.user">
                <span [appUserDisactivatable]="item.user">{{item.user?.name}}</span>
              </td>
              <td class="align-middle text-center text-nowrap"  *ngIf="!item.user" colspan="2">- brak -</td>
              <td class="align-middle w-100"></td>
            </tr>
          </tbody>
        </table>
        <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(list | async).length == 0"></nz-empty>
        <div class="overlay position-absolute" *ngIf="(loading | async)" style="top: 0; bottom: 0; left: 0; right: 0">
          <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
            <i class="fas fa-3x fa-spinner fa-spin"></i>
            <p>Ładowanie...</p>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-secondary" *ngIf="(list | async).length < (total | async)" (click)="loadMore()">
          Załaduj więcej
        </button>
        <button class="btn btn-primary float-right" type="submit">
          Zapisz
        </button>
      </div>
    </div>
  </div>

  </form>
</section>

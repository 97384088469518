import { PaginationDTO } from "@/model/pagination.model";
import { RedmineCategory, RedminePriority, RedmineSubCategory } from "@/model/redmine.model";
import { createAction, props } from "@ngrx/store";

export const success = createAction("redmine-category success");
export const clear = createAction("redmine-category clear");
export const fetch = createAction("redmine-category fetch");
export const fetchOne = createAction("redmine-category fetchOne", props<{id: number}>());
export const setPage = createAction("redmine-category setPage", props<{ page: number }>());
export const setOrder = createAction("redmine-category setOrder", props<{ order: string }>());
export const setDirection = createAction("redmine-category setDirection", props<{ asc: boolean }>());
export const setSearch = createAction("redmine-category setSearch", props<{ search: string }>());

export const setAll = createAction( "redmine-category setAll", props<PaginationDTO<RedmineCategory>>());
export const addOneCategory = createAction( "redmine-category addOneCategory", props<{item: RedmineCategory}>());
export const updateOneCategory = createAction( "redmine-category updateOneCategory", props<{item: RedmineCategory}>());
export const removeOneCategory = createAction( "redmine-category removeOneCategory", props<{id: number}>());

export const createCategory = createAction("redmine-category createCategory", props<{item: RedmineCategory}>());
export const updateCategory = createAction("redmine-category updateCategory", props<{item: RedmineCategory}>());
export const deleteCategory = createAction("redmine-category deleteCategory", props<{id: number}>());


export const setOneSubCategory = createAction( "redmine-category setOneSubCategory", props<{item: RedmineSubCategory}>());
export const removeOneSubCategory = createAction( "redmine-category removeOneSubCategory", props<{item: RedmineSubCategory}>());

export const saveSubCategory = createAction( "redmine-category saveSubCategory", props<{item: RedmineSubCategory}>());
export const deleteSubCategory = createAction( "redmine-category deleteSubCategory", props<{item: RedmineSubCategory}>());


export const fetchPriorities = createAction("redmine-category fetchPriorities");
export const setPriorities = createAction("redmine-category setPriorities", props<{items: RedminePriority[]}>());

import { Post } from "@/model/post.model";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { environment } from "environments/environment";
import { ToastrService } from "ngx-toastr";
import { switchMap, map, catchError } from "rxjs/operators";

import * as fromMessageActions from '@/store/messages/messages.actions';
import { InnerMessage } from "@/model/message.mode";
import { PaginationDTO } from "@/model/pagination.model";
import { State, selectState, initialState } from "./messages.reducers";
import { select, Store } from "@ngrx/store";
import { DefaultEffects } from "../default/default.effects";
import { ErrorState } from "../error.store";

@Injectable()
export class MessagesEffects extends DefaultEffects {
  constructor(
    private actions$: Actions,
    httpClient: HttpClient,
    toastr: ToastrService,
    errorStore: Store<ErrorState>,
    private store$: Store<State>,
  ) {
    super(httpClient, toastr, errorStore);
    this.store$.pipe(select(selectState)).subscribe((state: State) => {
      this.state = state;
    })
  }
  private state: State;
  private pageSize: number = environment.pageSize;

  paginatorFetchChange$ = createEffect(() => this.actions$.pipe(
    ofType(...[
      fromMessageActions.setPageInbox,
      fromMessageActions.setSearchInbox,
      fromMessageActions.setOrderInbox,
      fromMessageActions.setDirectionInbox
    ]),
    map( () => fromMessageActions.fetchInbox({}))
  ));

  fetchRecent$ = createEffect(() => this.actions$.pipe(
    ofType(fromMessageActions.fetchRecent),
    switchMap((action) => {
      return this.httpClient.get<PaginationDTO<InnerMessage>>('<backendhost>/v1/messages', {
        observe: 'body',
        responseType: 'json'
      }).pipe(map( (responnse) => responnse.list))
    }),
    catchError(this.onError.bind(this)),
    switchMap((payload: InnerMessage[]) => [fromMessageActions.addRecent({payload})])
  ));

  fetchInbox$ = createEffect(() => this.actions$.pipe(
    ofType(fromMessageActions.fetchInbox),
    switchMap((action) => {
      let params = new HttpParams();
      let skip = this.state.inbox.limit * (this.state.inbox.page - 1);
      let limit = action?.limit ? action.limit : this.state.inbox.limit;
      if (skip !== 0)
        params = params.set('skip', skip);
      if (limit !== initialState.inbox.limit)
        params = params.set('limit', limit);
      if(this.state.inbox.order != initialState.inbox.order)
        params = params.set('order', this.state.inbox.order);
      if(this.state.inbox.asc != initialState.inbox.asc)
        params = params.set('direction', this.state.inbox.asc ? 'asc' : 'desc');
      if(this.state.inbox.search)
        params = params.set('search', this.state.inbox.search);
      return this.httpClient.get<PaginationDTO<InnerMessage>>('<backendhost>/v1/messages', {
        params: params,
        observe: 'body',
        responseType: 'json'
      })
    }),
    catchError(this.onError.bind(this)),
    switchMap((result) => [fromMessageActions.setInbox(result)])
  ));

  createNewMessage$ = createEffect(() => this.actions$.pipe(
    ofType(fromMessageActions.createNewMessage),
    switchMap((action) => {
      return this.httpClient.post<InnerMessage>('<backendhost>/v1/messages', action.message, {
        observe: 'body',
        responseType: 'json'
      })
    }),
    catchError(this.onError.bind(this)),
    switchMap(() => [fromMessageActions.success()])
  ));

  paginatorSentChange$ = createEffect(() => this.actions$.pipe(
    ofType(...[
      fromMessageActions.setPageSent,
      fromMessageActions.setSearchSent,
      fromMessageActions.setOrderSent,
      fromMessageActions.setDirectionSent
    ]),
    map( () => fromMessageActions.fetchSent({}))
  ));

  fetchSent$ = createEffect(() => this.actions$.pipe(
    ofType(fromMessageActions.fetchSent),
    switchMap((action) => {
      let params = new HttpParams();
      params = params.set('skip', this.state.sent.limit * (this.state.sent.page - 1));
      params = params.set('limit', action?.limit ? action.limit : this.state.sent.limit);
      params = params.set('order', this.state.sent.order);
      params = params.set('direction', this.state.sent.asc ? 'asc' : 'desc');
      if(this.state.sent.search)
        params = params.set('search', this.state.sent.search);
      return this.httpClient.get<PaginationDTO<InnerMessage>>('<backendhost>/v1/messages/0/sent', {
        params: params,
        observe: 'body',
        responseType: 'json'
      })
    }),
    catchError(this.onError.bind(this)),
    switchMap((result) => [fromMessageActions.setSent(result)])
  ));



  fetchUnseenCount$ = createEffect(() => this.actions$.pipe(
    ofType(fromMessageActions.fetchUnseenCount),
    switchMap((action) => {
      return this.httpClient.get<{count: number}>('<backendhost>/v1/messages/0/unseen', {
        observe: 'body',
        responseType: 'json'
      }).pipe(map( (responnse) => responnse.count))
    }),
    catchError(this.onError.bind(this)),
    switchMap((count: number) => [fromMessageActions.setUnseenCount({count})])
  ));

}

<div class="content">
  <div class="container-fluid">
    <div class="card card-primary position-relative shadow mt-3 mb-4" style="min-height: 15rem;">
      <div class="overlay" *ngIf="!result">
        <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%,0);text-align: center; padding: 25px 0">
          <i class="fas fa-3x fa-spinner fa-spin"></i>
          <p>Ładowanie...</p>
        </div>
      </div>
      <div class="card-header" >
        <h3 *ngIf="invitation.package?.id" class="card-title text-lg m-0">
          {{title}}
        </h3>
      </div>
      <div *ngIf="result">
        <app-scorm-player
                *ngIf="training.type == 'SCORM'"
                [result]="result"
                [scorm]="training"
                (onFinish)="onFinish($event)"
                (onClose)="onScormClose($event)"
                ></app-scorm-player>

        <app-multimedia-player
                *ngIf="training.type == 'Multimedialne'"
                [result]="result"
                ></app-multimedia-player>

        <app-quiz-player
                *ngIf="training.type == 'Quiz'"
                [quiz]="training"
                [result]="result"
                (onFinish)="onFinish($event)"></app-quiz-player>
      </div>
      <div class="card-footer text-center" *ngIf="result?.completedOn && training.type != 'Multimedialne'">
        <h3 class="p-3">Szkolenie zakończone</h3>
        <a [routerLink]="['/training', invitation.id]" class="btn btn-primary btn-lg">
          <i class="fas fa-check"></i>
          Zakończ
        </a>
      </div>
    </div>
    <a [routerLink]="['/training', invitation.id]" class="btn btn-primary">
      <i class="fas fa-chevron-left"></i>
      Wróć
    </a>
    <p>&nbsp;</p>
    <table class="table" *ngIf="false && result">
      <thead>
        <th>createdAt</th>
        <th>timeSpent</th>
        <th>score</th>
        <th>scoreMax</th>
        <th>scorePercent</th>
        <th>completed</th>
        <th>completedOn</th>
        <th>passed</th>
      </thead>
      <tbody>
        <tr>
          <td>{{result?.createdAt  | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
          <td>{{result.timeSpent}}</td>
          <td>{{result.score}}</td>
          <td>{{result.scoreMax}}</td>
          <td>{{result.scorePercent}}</td>
          <td>{{result.completed}}</td>
          <td>{{result?.completedOn  | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
          <td>{{result?.passed }}</td>
        </tr>
        <tr *ngFor="let item of slideResults">
          <td>{{item?.createdAt  | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
          <td>{{item.timeSpent}}</td>
          <td>{{item.score}}</td>
          <td>{{item.scoreMax}}</td>
          <td>{{item.scorePercent}}</td>
          <td>{{item.completed}}</td>
          <td>{{item?.completedOn  | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
          <td>{{item?.passed }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<aside  *ngIf="training.type == 'Multimedialne'" class="control-sidebar control-sidebar-light" style="display: block" [ngStyle]="sidebarStyle()">
  <a
    (click)="sidebarToggle()"
    class="btn btn-secondary"
    style="transform: translate(-100%, 66px);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    position: absolute;">
    <i class="fas fa-list"></i>
  </a>
  <div class="control-sidebar-content">
    <h5 class="p-3 ">Lista slajdów</h5>
    <div class="list-group">
      <a
        *ngFor="let item of slides; let idx = index;"
        class="list-group-item list-group-item-action"
        [routerLink]="['/training', invitation.id, 'multimedia', training.id, idx]"
        [class.disabled]="!slideResult(item)?.completedOn"
        routerLinkActive="active"
        >
        <span class="fa-stack fa-1x">
          <i class="far fa-square fa-stack-2x"></i>
          <i class="fas fa-check text-success fa-stack-2x" *ngIf="slideResult(item)?.passed == true"></i>
          <i class="fas fa-times text-danger fa-stack-2x" *ngIf="slideResult(item)?.passed == false"></i>
          <i class="fas fa-check text-info fa-stack-2x" *ngIf="slideResult(item)?.completedOn && slideResult(item)?.passed == null"></i>
        </span>
        {{item.name}}
      </a>
    </div>
  </div>
</aside>

import { Outpost } from '@/model/outpost.model';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { ofType } from '@ngrx/effects';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import * as fromSlotActions from '@/store/slot/slot.actions';
import * as fromSlotReducers from '@/store/slot/slot.reducers';
import * as fromUsersActions from '@/store/users/users.actions';
import * as fromUsersReducers from '@/store/users/users.reducers';
import { Slot, SlotDay, SlotType } from '@/model/slot.model';
import moment from 'moment';
import { User } from '@/model/user.model';
import { SlotController, SlotTimeValidator } from '../slot.time.validator';

@Component({
  selector: 'app-add-slot',
  templateUrl: './add-slot.component.html',
  styleUrls: ['./add-slot.component.scss']
})
export class AddSlotComponent implements OnInit, SlotController {
  private readonly unsubscribe$: Subject<void> = new Subject();
  SlotType = Object.values(SlotType);
  form: FormGroup;
  outpost: Outpost;
  slot: Slot;
  constructor(
    private toastr: ToastrService,
    private store: Store<fromSlotReducers.State>,
    private usersStore: Store<fromUsersReducers.State>,

    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.form = new FormGroup({
      type: new FormControl(SlotType.rejestrator, Validators.required),
      day: new FormControl(SlotDay.WEEKDAY, Validators.required),
      outpost: new FormControl(null, Validators.required),
      startTime: new FormControl(moment("09:00", "HH:mm").toDate(), [Validators.required, SlotTimeValidator(this)]),
      endTime: new FormControl(moment("17:00", "HH:mm").toDate(),[Validators.required, SlotTimeValidator(this)]),
      user: new FormControl(null/*, Validators.required*/),
    });
  }

  ngOnInit(): void {
    this.usersStore.dispatch(fromUsersActions.clearFilters());
    this.usersStore.dispatch(fromUsersActions.fetchUsers({limit:40}));
    this.usersInput$
      .pipe(distinctUntilChanged(),debounceTime(500))
      .subscribe((search) => this.usersStore.dispatch(fromUsersActions.setSearch({search})));
    this.activatedRoute.data.subscribe((data: Data) => {
      this.outpost = data.instance;
      this.form.patchValue({
        ...this.slot,
        ...{
          outpost: this.outpost
        }
      })
    })
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  save(): void {
    if (this.form.valid) {
      this.slot = {...this.slot, ...this.form.value};
      this.store.dispatch(fromSlotActions.createSlot({slot: this.slot}));
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromSlotActions.addSlot.type)
      ).subscribe( (action: any) => {
        this.toastr.success('Dodano nowy slot');
        // this.router.navigate(['/outposts', action.outpost.id, 'edit']);
        this.router.navigate(['/outposts', action.slot.outpost.id,'slots']);
      });
    } else {
        this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }

  loading = false;
  get users$(): Observable<User[]> {
    return this.usersStore.pipe(select(fromUsersReducers.selectAllUsers));
  }
  usersInput$ = new Subject<string>();
  trackByFn(item: any) {
    return item.id;
  }

}

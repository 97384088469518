import { CallCenterQuestionnaire } from '@/model/call-center.model';
import { Component, OnInit } from '@angular/core';

import * as fromReducers from '@/store/call-center/call-center-questionnaire.reducers';
import * as fromActions from '@/store/call-center/call-center-questionnaire.actions';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'app-add-questionnaire-call-center',
  templateUrl: './add-questionnaire-call-center.component.html',
  styleUrls: ['./add-questionnaire-call-center.component.scss']
})
export class AddQuestionnaireCallCenterComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  questionnaire: CallCenterQuestionnaire;

  constructor(
    private toastr: ToastrService,
    private store: Store<fromReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      outgoing: new FormControl('Wychodzący'),
      areas: new FormArray([
        new FormControl('obsługa', Validators.required),
        new FormControl('sprzedaż', Validators.required),
        new FormControl('komunikacja', Validators.required),
      ]),
      categories: new FormArray([this.emptyCategoryGroup]),
    });
    // this.form.valueChanges.subscribe(value => {
    //   this.categories.forEach(group => {
    //     let weight = group.controls.questions.value.map(item => item.weight).reduce((a,b) => a+b, 0);
    //     console.log(weight, group.value);
    //     // group.controls.weight.setValue(weight, false);
    //   })
    // })
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data: Data) => {
      this.questionnaire = data.instance;
      this.questionnaire.categories.forEach((category, categoryIndex) => {
        if(categoryIndex !== 0){
          this.addCategory();
        }
        category.questions.forEach((question, index) => {
          if(index === 0)return;
          this.addQuestion(categoryIndex);
        })
      })
      this.form.patchValue(this.questionnaire);
    })
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private get emptyCategoryGroup(): FormGroup {
    const group = new FormGroup({
      label: new FormControl(null, Validators.required),
      area: new FormControl(''),
      weight: new FormControl(0, Validators.required),
      questions: new FormArray([this.emptyQuestionGroup])
    });
    group.controls.questions.valueChanges.subscribe(value => {
      let weight = value.map(item => item.weight).reduce((a,b) => a+b, 0);
      group.controls.weight.setValue(weight);
    })
    return group;
  }

  private get emptyQuestionGroup(): FormGroup {
    const group =  new FormGroup({
      label: new FormControl(null, Validators.required),
      weight: new FormControl(0, Validators.required),
    });
    group.controls.weight.valueChanges.subscribe(value => {
      if(value < 0)
        group.controls.weight.setValue(0);
      if(value > 100)
        group.controls.weight.setValue(100);

      setTimeout(() => {
        Object.values(this.categories).forEach( (category: FormGroup) => {
          category.controls.questions.updateValueAndValidity();
        });
      })
    })
    return group;
  }

  get categories(): FormGroup[] {
    return (this.form.controls.categories as FormArray).controls as FormGroup[]
  }

  get areas(): FormControl[] {
    return (this.form.controls.areas as FormArray).controls as FormControl[]
  }

  get validAreas(): FormControl[] {
    return this.areas.filter(ctrl => ctrl.value);
  }

  questions(categoryIndex: number): FormGroup[] {
    const category = this.categories[categoryIndex];
    const questionGroup = category.controls.questions as FormArray;
    return questionGroup.controls as FormGroup[];
  }

  addArea(): void {
    this.areas.push(new FormControl(null, Validators.required));
    this.form.controls.ares.updateValueAndValidity();
  }
  delArea(index: number): void {
    console.log(index);
    this.areas.splice(index, 1);
    this.form.controls.ares.updateValueAndValidity();
  }

  addCategory(): void {
    (this.form.controls.categories as FormArray).controls.push(this.emptyCategoryGroup);
    this.form.controls.categories.updateValueAndValidity();
  }
  delCategory(categoryIndex: number): void {
    this.categories.splice(categoryIndex, 1);
    this.form.controls.categories.updateValueAndValidity();
  }
  moveCategoryUp(categoryIndex: number): void {
    let element = this.categories.splice(categoryIndex, 1);
    this.categories.splice(categoryIndex - 1, 0, element[0]);
    this.form.controls.categories.updateValueAndValidity();
  }
  moveCategoryDown(categoryIndex: number): void {
    let element = this.categories.splice(categoryIndex, 1);
    this.categories.splice(categoryIndex + 1, 0, element[0]);
    this.form.controls.categories.updateValueAndValidity();
  }

  addQuestion(categoryIndex: number): void {
    const category = this.categories[categoryIndex];
    const questionGroup = category.controls.questions as FormArray;
    questionGroup.controls.push(this.emptyQuestionGroup);
    Object.values(this.categories).forEach( (category: FormGroup) => {
      category.controls.questions.updateValueAndValidity();
    });
  }
  delQuestion(categoryIndex: number, index: number): void {
    const category = this.categories[categoryIndex];
    const questionGroup = category.controls.questions as FormArray;
    questionGroup.controls.splice(index, 1);
    Object.values(this.categories).forEach( (category: FormGroup) => {
      category.controls.questions.updateValueAndValidity();
    });
  }

  moveQuestionUp(categoryIndex: number, index: number) {
    const category = this.categories[categoryIndex];
    const questionGroup = category.controls.questions as FormArray;
    let element = questionGroup.controls.splice(index, 1);
    questionGroup.controls.splice(index - 1, 0, element[0]);
    Object.values(this.categories).forEach( (category: FormGroup) => {
      category.controls.questions.updateValueAndValidity();
    });
  }

  moveQuestionDown(categoryIndex: number, index: number) {
    const category = this.categories[categoryIndex];
    const questionGroup = category.controls.questions as FormArray;
    let element = questionGroup.controls.splice(index, 1);
    questionGroup.controls.splice(index + 1, 0, element[0]);
    Object.values(this.categories).forEach( (category: FormGroup) => {
      category.controls.questions.updateValueAndValidity();
    });
  }

  isValid(ctrl: FormControl) {
    return ctrl.errors && ctrl.touched;
  }

  get weightSum() {
    return this.categories.map(cat => cat.value.weight).reduce((a,b) => a + b, 0);
  }

  save() {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
      // input.addAsyncValidators()
    });
    let valid =  this.form.valid;
    if (valid) {
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromActions.addOne, fromActions.setOne)
      ).subscribe( (action:any) => {
        this.toastr.success("Zapisano zmiany");
        this.router.navigate(['/call-center/admin'])
      })
      if(this.questionnaire?.id) {
        this.store.dispatch(fromActions.updateQuestionnaire({
          item: {
            ...this.form.value,
            id: this.questionnaire.id
          }
        }));
      } else {
        this.store.dispatch(fromActions.createQuestionnaire({item: this.form.value}));
      }
    }
    else {
      this.toastr.error("Formularz zawiera błędy");
    }
  }

  get loading(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromReducers.selectState),
      map( (state:fromReducers.State)  => state.loading),
    );
  }

  get saving(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromReducers.selectState),
      map( (state:fromReducers.State)  => state.saving),
    );
  }

}

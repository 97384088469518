import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as fromKbActions from '@/store/kb/kb.actions';
import * as fromKbReducers from '@/store/kb/kb.reducers';
import { select, Store } from '@ngrx/store';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import { KbArticle } from '@/model/article.model';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { ViewportScroller } from '@angular/common';
import { PlyrComponent } from 'ngx-plyr';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  article: KbArticle;
  fragment: string = "static-1";
  @ViewChild(PlyrComponent)
  plyr: PlyrComponent;
  player: Plyr;

  constructor(
    private viewportScroller: ViewportScroller,
    private store: Store<fromKbReducers.State>,
    private activatedRoute: ActivatedRoute,
  ) { }



  audioOptions = {
    autoplay: true,
    i18n: {
      speed: 'Prędkość',
      normal: 'Normalna',
    }
  }
  audioPathPlyr = []

  ngOnInit(): void {
    this.activatedRoute.paramMap.pipe(
      map((params: ParamMap) => parseInt(params.get('id')))
    ).subscribe( (id: number) => {
      this.store.pipe(takeUntil(this.unsubscribe$), select(fromKbReducers.selectArticle, id)).subscribe((article: KbArticle) => {
        this.article = article;
        this.activatedRoute.fragment.pipe(filter(value => value != null)).subscribe((fragment) => {

          console.log('fragment', fragment);
          this.fragment = fragment;
          setTimeout( () => {
            this.viewportScroller.scrollToAnchor(fragment + '-ancor');
            // setTimeout( () => {
            //   this.fragment = fragment;
            // });
          }, 500);
        })
      });
    })
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.audioPathPlyr = [{
        src: `${environment.apiUrl}/v1/audio/${this.article.audio.id}`,
        type: this.article.audio.mimeType
      }];
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  backgroundStyle () {
    if(!this.article)
      return null;
    if(!this.article.background)
      return null;
    return {
      'aspect-ratio': '4',
      background : `url(${environment.apiUrl}/thumb/1920x0/${this.article.background.replace('public','')}) center center`,
      'background-size': 'cover'
    }
  }

}

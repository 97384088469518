<ul class="nav nav-tabs" *ngIf="checkViewPermition([AllOrCreated.USER, AllOrCreated.CREATED, AllOrCreated.ALL])">
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active bg-primary" [routerLink]="['/call-center']" [routerLinkActiveOptions]="{ exact: true }">
      Moje oceny
    </a>
  </li>
  <li class="nav-item" *ngIf="checkViewPermition([AllOrCreated.CREATED, AllOrCreated.ALL])">
    <a class="nav-link" routerLinkActive="active bg-primary" [routerLink]="['/call-center','created']" [routerLinkActiveOptions]="{ exact: true }">
      Oceny które utworzyłem
    </a>
  </li>
  <li class="nav-item" *ngIf="checkViewPermition([AllOrCreated.ALL])">
    <a class="nav-link" routerLinkActive="active bg-primary" [routerLink]="['/call-center','all']" [routerLinkActiveOptions]="{ exact: true }">
      Wszystkie
    </a>
  </li>
</ul>
<div class="card">
  <div class="card-header d-flex align-items-center">
    <!-- <h3 class="card-title m-0 flex-grow-1 text-bold"><i class="fas fa-vote-yea fa-fw"></i> Lista Formatek</h3> -->
    <div class="card-tools flex-grow-1">
      <div class="input-group">
        <input type="text" name="table_search" class="form-control float-right" placeholder="Szukaj" #searchInput>
        <button class="btn bg-transparent" style="position:absolute; right: 0; transform: translate(-100%,0);" (click)="clearSearch()">
          <i class="fa fa-times"></i>
        </button>
        <span class="input-group-append">
          <div class="input-group-text bg-transparent"><i class="fas fa-search"></i></div>
        </span>
      </div>
    </div>
  </div>
  <div class="table-responsive position-relative">
    <table class="table align-middle table-hover">
      <thead>
        <tr>
          <th style="width:1rem">#</th>
          <th>Nazwa</th>
          <th>Ocena</th>
          <th colspan="2">Oceniany</th>
          <th colspan="2">Oceniający</th>
          <th>Utworzono</th>
          <th class="text-right"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of list | async; let idx = index; trackBy: identify">
          <th class="align-middle">{{idx + 1}}</th>
          <td class="align-middle">{{item.name}}</td>
          <td class="align-middle">
            <span class="badge text-sm rounded-pill badge-primary">
              {{ item.score }}%
            </span>
          </td>
          <td class="user-panel" style="width:70px">
            <img
            [src]="item.user  | userAvatar: 34"
            class="img-circle elevation-2"
            alt="User Image"
            style="width:45px"
            [appUserDisactivatable]="item.user"
            />
          </td>
          <td>
            <span class="text-decoration-line-through" *ngIf="!item?.user">Użytkownik usunięty</span>
            <strong [appUserDisactivatable]="item.user">{{item.user?.firstname}} {{item.user?.lastname}}</strong> <br>
            <small [appUserDisactivatable]="item.user">{{ item?.user?.active ? item?.user?.email : '-'}}</small>
          </td>

          <td class="user-panel" style="width:70px">
            <img
            [src]="item.createdBy  | userAvatar: 34"
            class="img-circle elevation-2"
            alt="User Image"
            style="width:45px"
            [appUserDisactivatable]="item.createdBy"
            />
          </td>
          <td>
            <span class="text-decoration-line-through" *ngIf="!item?.createdBy">Użytkownik usunięty</span>
            <strong [appUserDisactivatable]="item.createdBy">{{item.createdBy?.firstname}} {{item.createdBy?.lastname}}</strong> <br>
            <small [appUserDisactivatable]="item.createdBy">{{ item?.createdBy?.active ? item?.createdBy?.email : '-'}}</small>
          </td>
          <td class="align-middle">{{item.createdAt | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
          <td class="align-middle text-nowrap text-right">
            <button class="btn btn-info btn-sm elevation-2 mr-2"
              [routerLink]="['/call-center/detail', item.id]"
              ngbTooltip="Szczegóły"
              checkRolePermition="callcenter.view"
              [instance]="item">
                <i class="fas fa-check fa-fw fa-lg"></i>
            </button>
            <button class="btn btn-primary btn-sm elevation-2 mr-2"
              [routerLink]="['/call-center/edit', item.id]"
              ngbTooltip="Edycja"
              checkRolePermition="callcenter.fillEdit"
              [instance]="item">
                <i class="fas fa-edit fa-fw fa-lg"></i>
            </button>
            <button
                  checkRolePermition="callcenter.fillDelete"
                  class="btn btn-danger btn-sm elevation-2"
                  nz-popconfirm
                  nzPopconfirmTitle="Czy na pewno usunąć?"
                  nzOkText="Tak"
                  nzCancelText="Nie"
                  nzPopconfirmPlacement="left"
                  ngbTooltip="Usuń"
                  [instance]="item"
                  (nzOnConfirm)="delete(item)" >
                <i class="fas fa-trash fa-fw fa-lg"></i>
              </button>
          </td>
        </tr>
      </tbody>
    </table>
    <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(list | async).length == 0"></nz-empty>
    <div class="overlay position-absolute" *ngIf="(loading | async) || (saving | async)" style="top: 0; bottom: 0; left: 0; right: 0">
      <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
        <i class="fas fa-3x fa-spinner fa-spin"></i>
        <p *ngIf="(loading | async)">Ładowanie...</p>
        <p *ngIf="(saving | async)">Zapisywanie...</p>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="(total | async)"  (pageChange)="pageChange($event)">
      <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationEllipsis>...</ng-template>
      <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionsSubject, Store } from '@ngrx/store';

import * as fromActions from '@/store/survey/survey.actions';
import * as fromReducers from '@/store/survey/survey.reducers'
import { Survey, SurveyQuestion } from '@/model/survey.model';
import { ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-survey-admin-question-modal',
  templateUrl: './survey-admin-question-modal.component.html',
  styleUrls: ['./survey-admin-question-modal.component.scss']
})
export class SurveyAdminQuestionModalComponent implements OnInit {
  survey: Survey;
  form: FormGroup;
  private _question: SurveyQuestion;
  static multiple = ['checkbox', 'radio', 'select'];
  public static types = {
    'radio': 'Jednokrotnego wyboru',
    'checkbox': 'Wielokrotnego wyboru',
    'select': 'Lista rozwijana',
    'scale': 'Skala',
    'open': 'Otwarte',
  }

  constructor(
    public modal: NgbActiveModal,
    private store: Store<fromReducers.State>,
    private actionsSubject$: ActionsSubject,
    private toastr: ToastrService
  ) {
    this.form = new FormGroup({
      label: new FormControl(null, Validators.required),
      type: new FormControl('radio', Validators.required),
      scaleNumber: new FormControl(10, null),
      options: new FormArray([], null),
    });
    this.form.valueChanges.subscribe(value => {
      if(SurveyAdminQuestionModalComponent.isMultiple(value.type) && value.options.length == 0) {
        this.addOption();
      } else if(!SurveyAdminQuestionModalComponent.isMultiple(value.type) && value.options.length != 0) {
        this.optionsCtrl.controls = [];
        this.optionsCtrl.updateValueAndValidity();
      }
    })
  }


  ngOnInit(): void {}

  get question(): SurveyQuestion {
    return this._question;
  }
  set question(value: SurveyQuestion) {
    this._question = value;
    value.options.forEach( option => {
      if(value.options.length != this.optionsCtrl.controls.length) {
        this.optionsCtrl.push(this.createOptionGroup(option), {emitEvent: false})
      }
    })
    this.form.patchValue(value);
  }

  get optionsCtrl(): FormArray {
    return this.form.get('options') as FormArray;
  }

  getOptionFormGroup(idx: number): FormGroup {
    return this.optionsCtrl.controls[idx] as FormGroup;
  }

  createOptionGroup(value? : any): FormGroup {
    let group = new FormGroup({
      label: new FormControl(null, Validators.required),
      id: new FormControl(null)
    });
    group.patchValue(value);
    return group;
  }

  addOption() {
    this.optionsCtrl.push(this.createOptionGroup());
  }

  delOption(idx: number) {
    this.optionsCtrl.controls.splice(idx,1);
    this.optionsCtrl.updateValueAndValidity();
    if (this.optionsCtrl.controls.length == 0) {
      this.addOption();
    }
  }

  public static isMultiple(type: string): boolean {
    return this.multiple.includes(type);
  }

  public checkMultiple(type: string): boolean {
    return SurveyAdminQuestionModalComponent.isMultiple(type);
  }

  save(): void {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
     if (this.form.valid) {
      this.question = {...this.question, ...this.form.value};
      if (this.question.id) {
        this.store.dispatch(fromActions.updateSurveyQuestion({survey: this.survey, question: this.question}));
      } else {
        this.store.dispatch(fromActions.createSurveyQuestion({survey: this.survey, question: this.question}));
      }
      this.actionsSubject$.pipe(ofType(fromActions.addSurveyQuestion,fromActions.setSurveyQuestion)).subscribe(() => {
        this.toastr.success("Zapisano pytanie");
        this.modal.close();
      })

    } else {
      console.log(this.form);
      this.toastr.warning("Formularz zawiera błędy");
    }
  }

}

import { Survey } from '@/model/survey.model';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject, fromEvent, Observable } from 'rxjs';
import { take, takeUntil, map, filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import * as fromSurveyActions from '@/store/my-survey/my-survey.actions';
import * as fromSurveyReducers from '@/store/my-survey/my-survey.reducers';

@Component({
  selector: 'app-survey-list',
  templateUrl: './survey-list.component.html',
  styleUrls: ['./survey-list.component.scss']
})
export class SurveyListComponent implements OnInit {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  searchTerm: string;
  page = 1;
  pageSize = 20;
  constructor(
    protected store: Store<fromSurveyReducers.State>,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(fromSurveyActions.fetch());
    this.store.pipe(
      take(1),
      takeUntil(this.unsubscribe$),
      select(fromSurveyReducers.selectState),
      map(state => state.search)
    ).subscribe((search:string) => {
      this.searchInput.nativeElement.value = search
    });
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      takeUntil(this.unsubscribe$),
      map((event: any) => event.target.value),
      filter( (res:string) => res.length > 2),
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((text: string) => {
      this.store.dispatch(fromSurveyActions.setSearch({search: text.toLowerCase().trim()}));
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  clearSearch() {
    this.searchInput.nativeElement.value = '';
    this.store.dispatch(fromSurveyActions.setSearch({search: null}));
  }

  get list(): Observable<Survey[]> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromSurveyReducers.selectAllSurvey));
  }

  identify(index, entity: Survey){ return entity.id }

  get total(): Observable<number>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromSurveyReducers.selectState),
      map( (state:fromSurveyReducers.State)  => state.total)
    );
  }

  get direction(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromSurveyReducers.selectState),
      map( (state:fromSurveyReducers.State)  => state.asc),
      tap( asc => this.asc = asc)
    );
  }

  get loading(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromSurveyReducers.selectState),
      map( (state:fromSurveyReducers.State)  => state.loading),
    );
  }

  get order(): Observable<string>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromSurveyReducers.selectState),
      map( (state:fromSurveyReducers.State)  => state.order),
      tap( sort => this.sort = sort)
    );
  }

  private sort: string;
  private asc: boolean;
  setSort(sort: string): void {
    if(sort == this.sort) {
      this.store.dispatch(fromSurveyActions.setDirection({asc: !this.asc}));
    } else {
      this.store.dispatch(fromSurveyActions.setDirection({asc: false }));
    }
    this.store.dispatch(fromSurveyActions.setOrder({order: sort}));
  }

  toggleDirection(): void {
    this.store.dispatch(fromSurveyActions.setDirection({asc: !this.asc}));
  }

  pageChange(page): void {
    this.store.dispatch(fromSurveyActions.setPage({page: page}));
  }

}


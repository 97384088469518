import { AllOrCreated, Role } from "@/model/role.model";
import { User } from "@/model/user.model";
import { state } from "@angular/animations";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { Action } from "rxjs/internal/scheduler/Action";
import * as fromActions from './auth.actions';



export interface State {
  token: string;
  profile: User;
  loadingUser: boolean;
  role: Role
};

export const initialState: State  = {
  token: null,
  profile: null,
  loadingUser: false,
  role: null
};

export const authReducer = createReducer(
  initialState,
  on(fromActions.setLoadingAction, (state, _) => { return {...state, ...{loadingUser: true}}}),
  on(fromActions.setTokenAction, (state, action) => {
    return {...state, ...{ token: action.token}}
  }),
  on(fromActions.setProfileAction, (state, action) => {
    return {...state, ...{ profile: action.profile, loadingUser: false}}
  }),
  on(fromActions.clearUserAction, () => { return {...initialState}})
);

export const selectAuthState = createFeatureSelector<State>('auth');
export const selectAuthLoading = createSelector(selectAuthState, (state) => state.loadingUser )
export const selectAuthRole = createSelector(selectAuthState, (state) => state.role )

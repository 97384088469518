import { createPaginationInitialState, Pagination } from "@/model/pagination.model";
import { Scorm } from "@/model/scorm.model";
import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import * as actions from "./scorm.actions";

export interface State extends Pagination<Scorm> {}

export const adapter: EntityAdapter<Scorm> = createEntityAdapter<Scorm>();

export const initialState: State = createPaginationInitialState(adapter, false);

export const reducer = createReducer(
  initialState,
  on(actions.setScorms, (state, action) => {
    return {
      ...state,
      ...{
        total: action.total,
        loading: false,
        list: adapter.addMany(action.list, adapter.getInitialState())
      }
    }
  }),
  on(actions.addScorm, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.addOne(action.item, state.list)
      }
    }
  }),
  on(actions.updateScorm, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.updateOne({id : action.item.id, changes: action.item}, state.list)
      }
    }
  }),
  on(actions.deleteScorm, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.removeOne(action.id, state.list)
      }
    }
  }),

  on(actions.fetch, (state, action) => { return {...state, ...{loading: true}}}),
  on(actions.setPage, (state, action) => { return {...state, ...{page: action.page}}}),
  on(actions.setOrder, (state, action) => { return {...state, ...{order: action.order}}}),
  on(actions.setDirection, (state, action) => { return {...state, ...{asc: action.asc}}}),
  on(actions.setSearch, (state, action) => { return {...state, ...{search: action.search}}}),
);



export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectState = createFeatureSelector<State>('scorm');
export const selectAllScorm = createSelector(selectState, (state) => selectAll(state.list) )
export const selectOneScorm = createSelector(selectState, (state: State, id) => state.list.entities[id]);

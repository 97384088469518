import { AuthService } from "@/auth/auth.service";
import { User } from "@/model/user.model";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { select, Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import * as fromUsersActions from "./users.actions";
import {State, UserPagination, selectUsersState} from "./users.reducers";
import { UsersPrize } from "@/model/prize.model";

@Injectable()
export class UsersEffects {
  user: User;
  state: State;

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private store$: Store<State>,
    private authService: AuthService,
    private toastr: ToastrService
  ) {
    this.authService.getUser().then(user => {this.user = user});
    this.store$.pipe(select(selectUsersState)).subscribe((state: State) => {
      this.state = state;
    })
  }

  onError (err, caught): any {
    this.toastr.error(err.message)
    return caught;
  };

  paginatorChange$ = createEffect(() => this.actions$.pipe(
    ofType(...[
      fromUsersActions.setPage,
      fromUsersActions.setSearch,
      fromUsersActions.setOrder,
      fromUsersActions.setDirection,
      fromUsersActions.toggleDeactivated
    ]),
    map( () => fromUsersActions.fetchUsers({}))
  ));

  fetchUsers$ = createEffect(() => this.actions$.pipe(
    ofType(fromUsersActions.fetchUsers),
    switchMap( (action) => {
      let params = new HttpParams();
      params = params.set('skip', this.state.limit * (this.state.page - 1));
      params = params.set('limit', action?.limit ? action.limit : this.state.limit);
      params = params.set('order', this.state.order);
      params = params.set('direction', this.state.asc ? 'asc' : 'desc');
      params = params.set('deactivated', this.state.deactivated ? '1' : '0');

      if(this.state.search)
        params = params.set('search', this.state.search);
      return this.httpClient.get<UserPagination>(`<backendhost>/v1/users`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: UserPagination) => [fromUsersActions.replaceUsers(response)])
  ));

  createUser$ = createEffect(() => this.actions$.pipe(
    ofType(fromUsersActions.createUser),
    switchMap( (action) => {
      return this.httpClient.post<User>(`<backendhost>/v1/users`, action.user, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: User) => [
      fromUsersActions.emitNewUserId({id: response.id}),
      fromUsersActions.addOneUser({user: response})
    ])
  ));

  fetchOneUser$ = createEffect(() => this.actions$.pipe(
    ofType(fromUsersActions.fetchOneUser),
    switchMap( (action) => {
      return this.httpClient.get<User>(`<backendhost>/v1/users/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: User) => [fromUsersActions.addOneUser({user: response})])
  ));

  editOneUser$ = createEffect(() => this.actions$.pipe(
    ofType(fromUsersActions.editUser),
    switchMap( (action) => {
      return this.httpClient.put<User>(`<backendhost>/v1/users/${action.id}`, action.user, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: User) => [
      fromUsersActions.emitNewUserId({id: response.id}),
      fromUsersActions.updateUser({user: response})
    ])
  ));

  deleteUser$ = createEffect(() => this.actions$.pipe(
    ofType(fromUsersActions.deleteUser),
    switchMap( (action) => {
      return this.httpClient.delete<User>(`<backendhost>/v1/users/${action.user.id}`, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: User) => [
      fromUsersActions.removeUser({user: response})
    ])
  ));

  createUserPrize$ = createEffect(() => this.actions$.pipe(
    ofType(fromUsersActions.createUserPrize),
    switchMap( (action) => {
      return this.httpClient.post<UsersPrize>(`<backendhost>/v1/users/${action.user.id}/add-prize`, action.usersPrize, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map(result => {
        return { user: action.user, usersPrize: result};
      }));
    }),
    catchError(this.onError.bind(this)),
    switchMap((response) => [
      fromUsersActions.addUserPrize(response)
    ])
  ));

  deleteUserPrize$ = createEffect(() => this.actions$.pipe(
    ofType(fromUsersActions.deleteUserPrize),
    switchMap( (action) => {
      return this.httpClient.post<UsersPrize>(`<backendhost>/v1/users/${action.user.id}/delete-prize`, action.usersPrize, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    tap(() => {
      this.toastr.success("Usunieto nagrode");
    }),
    switchMap(() => [])
  ));

  fetchRecentUsers$ = createEffect(() => this.actions$.pipe(
    ofType(fromUsersActions.fetchRecentUsers),
    switchMap( (action) => {
      return this.httpClient.get<User[]>('<backendhost>/v1/users/0/recent', {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((users: User[]) => [
      fromUsersActions.setRecentUsers({users})
    ])
  ));
}

import { AuthService } from "@/auth/auth.service";
import { Outpost } from "@/model/outpost.model";
import { PaginationDTO } from "@/model/pagination.model";
import { Slot, SlotNotificationUser } from "@/model/slot.model";
import { User } from "@/model/user.model";
import { plainText } from "@/utils/plainText";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import * as fromActions from "./slot.actions";
import * as fromNotificationActions from "./slot.notification.actions";
import * as fromReducers from "./slot.reducers";

@Injectable()
export class SlotEffects {
  state: fromReducers.State;
  user: User;

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private store$: Store<fromReducers.State>,
    private userService: AuthService
  ) {
    this.store$.pipe(select(fromReducers.selectState)).subscribe((state: fromReducers.State) => {
      this.state = state;
      userService.getUser().then(user => {
        this.user = user;
      });
    })
  }

  onError (err, caught): any {
    this.toastr.error(err.error?.message ? err.error.message : err.message);
    return caught;
  };

  paginatorChange$ = createEffect(() => this.actions$.pipe(
    ofType(...[
      fromActions.setPage,
      fromActions.setSearch,
      fromActions.setOrder,
      fromActions.setDirection
    ]),
    map( () => fromActions.fetch())
  ));

  fetch$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetch),
    switchMap(() => {
      let params = new HttpParams();
      if(this.state.outpost)
        params = params.set('outpost', this.state.outpost);
      if(this.state.type)
        params = params.set('type', this.state.type);
      if(this.state.user)
        params = params.set('user', this.state.user?.id);

      params = params.set('weekday', +this.state.weekday);
      params = params.set('saturday', +this.state.saturday);

      params = params.set('skip', this.state.limit * (this.state.page - 1));
      params = params.set('limit', this.state.limit);
      if(this.state.order)
        params = params.set('order', this.state.order);
      params = params.set('direction', this.state.asc ? 'asc' : 'desc');
      if(this.state.search)
        params = params.set('search', this.state.search);
      return this.httpClient.get<PaginationDTO<Outpost>>(`<backendhost>/v1/slot`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: PaginationDTO<Slot>) => [fromActions.setManySlot(response)])
  ));

  fetchMore$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchMore),
    switchMap((action) => {
      let params = new HttpParams();
      if(this.state.outpost)
        params = params.set('outpost', this.state.outpost);
      if(this.state.type)
        params = params.set('type', this.state.type);
      if(this.state.user)
        params = params.set('user', this.state.user?.id);

      params = params.set('weekday', +this.state.weekday);
      params = params.set('saturday', +this.state.saturday);

      params = params.set('skip', action.skip);
      params = params.set('limit', this.state.limit);
      if(this.state.order)
        params = params.set('order', this.state.order);
      params = params.set('direction', this.state.asc ? 'asc' : 'desc');
      if(this.state.search)
        params = params.set('search', this.state.search);
      return this.httpClient.get<PaginationDTO<Outpost>>(`<backendhost>/v1/slot`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: PaginationDTO<Slot>) => [fromActions.addManySlot(response)])
  ));

  fetchOneSlot$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchOneSlot),
    switchMap((action) => {
      return this.httpClient.get<Slot>(`<backendhost>/v1/slot/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((slot: Slot) => [fromActions.addSlot({slot})])
  ));

  createSlot$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createSlot),
    switchMap((action) => {
      return this.httpClient.post<Slot>(`<backendhost>/v1/slot`, action.slot, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((slot: Slot) => [fromActions.addSlot({slot})])
  ));

  updateSlot$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.updateSlot),
    switchMap((action) => {
      return this.httpClient.put<Slot>(`<backendhost>/v1/slot/${action.slot.id}`, action.slot, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((slot: Slot) => [fromActions.setSlot({slot})])
  ));

  deleteSlot$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deleteSlot),
    switchMap((action) => {
      return this.httpClient.delete<any>(`<backendhost>/v1/slot/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map(() => action.id));
    }),
    catchError(this.onError.bind(this)),
    switchMap((id: number) => [fromActions.removeSlot({id})])
  ));

  notificationPaginatorChange$ = createEffect(() => this.actions$.pipe(
    ofType(...[
      fromNotificationActions.setPage,
      fromNotificationActions.setSearch,
      fromNotificationActions.setOrder,
      fromNotificationActions.setDirection
    ]),
    map( () => fromNotificationActions.fetch())
  ));

  fetchNotification$ = createEffect(() => this.actions$.pipe(
    ofType(fromNotificationActions.fetch),
    switchMap(() => {
      let slot = this.state.notificationUsers.slot.id;
      let params = new HttpParams();
      params = params.set('skip', this.state.notificationUsers.limit * (this.state.notificationUsers.page - 1));
      params = params.set('limit', this.state.notificationUsers.limit);
      params = params.set('order', this.state.notificationUsers.order);
      params = params.set('direction', this.state.notificationUsers.asc ? 'asc' : 'desc');
      if(this.state.notificationUsers.search)
        params = params.set('search', this.state.notificationUsers.search);
      return this.httpClient.get<PaginationDTO<SlotNotificationUser>>(`<backendhost>/v1/slot/${slot}/notification-users`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: PaginationDTO<SlotNotificationUser>) => [fromNotificationActions.setMany(response)])
  ));

  createSlotNotificationUser$ = createEffect(() => this.actions$.pipe(
    ofType(fromNotificationActions.createSlotNotificationUser),
    switchMap((action) => {
      let slot = this.state.notificationUsers.slot.id;
      return this.httpClient.post<SlotNotificationUser>(`<backendhost>/v1/slot/${slot}/add-notification-users`, {user: action.user.id}, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((item: SlotNotificationUser) => [fromNotificationActions.addOne({item})])
  ));

  deleteSlotNotificationUser$ = createEffect(() => this.actions$.pipe(
    ofType(fromNotificationActions.deleteSlotNotificationUser),
    switchMap((action) => {
      let slot = this.state.notificationUsers.slot.id;
      return this.httpClient.post<{id:number, keep: boolean}>(`<backendhost>/v1/slot/${slot}/del-notification-users`, {user: action.id}, {
        observe: 'body',
        responseType: 'json'
      })
    }),
    catchError(this.onError.bind(this)),
    switchMap((response) => {
      return [fromNotificationActions.removeOne(response)]
    })
  ));

  signSlot$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.signSlot),
    switchMap((action) => {
      return this.httpClient.put<Slot>(`<backendhost>/v1/slot/${action.slot.id}/sign`, {}, {
        observe: 'body',
        responseType: 'json'
      })
    }),
    catchError(this.onError.bind(this)),
    tap(() => {this.toastr.success("Slot zajęty!")}),
    switchMap((slot) => {
      return [fromActions.setSlot({slot})]
    })
  ));

  unsignSlot$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.unsignSlot),
    switchMap((action) => {
      return this.httpClient.put<Slot>(`<backendhost>/v1/slot/${action.slot.id}/unsign`, {}, {
        observe: 'body',
        responseType: 'json'
      })
    }),
    catchError(this.onError.bind(this)),
    tap(() => {this.toastr.success("Slot zwolniony!")}),
    switchMap((slot) => {
      return [fromActions.setSlot({slot})]
    })
  ));

  setSlotNotificationUserSms$ = createEffect(() => this.actions$.pipe(
    ofType(fromNotificationActions.setSlotNotificationUserSms),
    switchMap((action) => {
      let slot = this.state.notificationUsers.slot.id;
      return this.httpClient.put<Slot>(`<backendhost>/v1/slot/${slot}/set-sms`, {user: action.id}, {
        observe: 'body',
        responseType: 'json'
      })
    }),
    catchError(this.onError.bind(this)),
    // tap(() => {this.toastr.success("Slot zwolniony!")}),
    switchMap((slot) => {
      return []
    })
  ));

  unsetSlotNotificationUserSms$ = createEffect(() => this.actions$.pipe(
    ofType(fromNotificationActions.unsetSlotNotificationUserSms),
    switchMap((action) => {
      let slot = this.state.notificationUsers.slot.id;
      return this.httpClient.put<Slot>(`<backendhost>/v1/slot/${slot}/unset-sms`, {user: action.id}, {
        observe: 'body',
        responseType: 'json'
      })
    }),
    catchError(this.onError.bind(this)),
    // tap(() => {this.toastr.success("Slot zwolniony!")}),
    switchMap((slot) => {
      return []
    })
  ));
}



<ul class="nav nav-tabs">
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active bg-primary" [routerLink]="['/workmarket']" [routerLinkActiveOptions]="{ exact: true }">
      Preferowane
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active bg-primary" [routerLink]="['/workmarket','empty']" [routerLinkActiveOptions]="{ exact: true }">
      Wszystkie wolne
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active bg-primary" [routerLink]="['/workmarket','reserved']" [routerLinkActiveOptions]="{ exact: true }">
      Moje zarezerwowane
    </a>
  </li>
  <li class="nav-item" checkRolePermition="vacant.create">
    <a class="nav-link" routerLinkActive="active bg-primary" [routerLink]="['/workmarket','created']" [routerLinkActiveOptions]="{ exact: true }">
      Które utworzyłem
    </a>
  </li>
  <li class="nav-item" checkRolePermition="vacant.viewAll">
    <a class="nav-link" routerLinkActive="active bg-primary" [routerLink]="['/workmarket','all-reserved']" [routerLinkActiveOptions]="{ exact: true }">
      Wszystkie zarezerwowane
    </a>
  </li>
</ul>
<div class="card">
  <div class="overlay position-absolute" *ngIf="!vacant" style="top: 0; bottom: 0; left: 0; right: 0">
    <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
      <i class="fas fa-3x fa-spinner fa-spin"></i>
      <p>Ładowanie...</p>
    </div>
  </div>
  <div class="card-header d-flex align-items-center">
    <h3 class="card-title m-0 flex-grow-1 text-bold"><i class="fas fa-clinic-medical fa-fw"></i> Szczegóły ogłoszenia</h3>
  </div>
  <div class="card-body">

    <dl *ngIf="vacant">

      <dt>Placówka</dt>
      <dd>{{vacant.slot.outpost.name}}</dd>

      <dt>Rodzaj</dt>
      <dd>{{vacant.slot.type}}</dd>

      <dt>Data</dt>
      <dd>{{vacant.date}}</dd>

      <dt>Czas</dt>
      <dd>{{vacant.slot.startTime}} - {{vacant.slot.endTime}}</dd>

      <dt>Komentarz</dt>
      <dd>{{vacant.comment}}</dd>
    </dl>
  </div>
  <div class="card-footer">
    <button
      class="btn btn-success  btn-sm elevation-2 mr-2"
      checkRolePermition="vacant.reservation.create"
      *ngIf="vacant.reservedBy == null"

      nz-popconfirm
      nzPopconfirmTitle="Czy na pewno zarezerwować?"
      nzOkText="Tak"
      nzCancelText="Nie"
      nzPopconfirmPlacement="top"
      (nzOnConfirm)="reserve()"
      ngbTooltip="Zarezerwuj">
      <i class="fas fa-check"></i>
      Zarezerwuj
    </button>

    <button
      class="btn btn-danger btn-sm elevation-2 mr-2"
      *ngIf="vacant.reservedBy != null"
      checkRolePermition="vacant.reservation.delete"
      [instance]="vacant"
      userProperty="reservedBy"

      nz-popconfirm
      nzPopconfirmTitle="Czy na pewno usunąć rezerwacje?"
      nzOkText="Tak"
      nzCancelText="Nie"
      nzPopconfirmPlacement="top"
      (nzOnConfirm)="unreserve()"
      ngbTooltip="Usuń rezerwacje"
      >
      <i class="fas fa-check"></i>
      Usuń rezerwacje
    </button>
  </div>
</div>

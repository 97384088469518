import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { selectIdeasState, State } from "./ideas.reducers";
import * as fromActions from './ideas.actions';
import { catchError, map, switchMap } from "rxjs/operators";
import { Idea } from "@/model/idea.model";
import { plainText } from "@/utils/plainText";
import { Comment } from '@/model/comment.model';

@Injectable()
export class IdeasEffects {
  private state: State;
  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private store$: Store<State>,
  ) {
    // super(httpClient, toastr, errorStore);
    this.store$.pipe(select(selectIdeasState)).subscribe((state: State) => {
      this.state = state;
    })
  }
  // private state: State;
  // private pageSize: number = environment.pageSize;

  onError (err, caught): any {
    this.toastr.error(err.message)
    return caught;
  };

  createNewIdea$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createNewIdea),
    switchMap((action) => {
      let idea = {...action.idea, ...{short: plainText(action.idea.description)}}
      return this.httpClient.post<Idea>('<backendhost>/v1/ideas', idea, {observe: 'body',responseType: 'json'});
    }),
    catchError(this.onError.bind(this)),
    switchMap((idea) => [
      fromActions.addIdea({idea})
    ])
  ));

  paginatorFetchChange$ = createEffect(() => this.actions$.pipe(
    ofType(...[
      fromActions.setAdminDirection,
      fromActions.setAdminOrder,
      fromActions.setAdminPage,
      fromActions.setAdminSearch
    ]),
    switchMap( () => [fromActions.fetchAdminIdeas()])
  ));

  fetchAdminIdeas$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchAdminIdeas),
    switchMap( (action) => {
      let params = new HttpParams();
      params = params.set('skip', this.state.admin.limit * (this.state.admin.page - 1));
      params = params.set('limit', this.state.admin.limit);
      params = params.set('order', this.state.admin.order);
      params = params.set('direction', this.state.admin.asc ? 'asc' : 'desc');
      if(this.state.admin.search)
        params = params.set('search', this.state.admin.search);
      return this.httpClient.get<fromActions.IdeaPagination>(`<backendhost>/v1/ideas/0/admin`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: fromActions.IdeaPagination) => [fromActions.replaceAdminIdeas(response)])
  ));

  fetchPublicIdeas$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchPublicIdeas),
    switchMap( (action) => {
      let params = new HttpParams();
      params = params.set('skip', this.state.public.list.ids.length);
      if(this.state.admin.search)
        params = params.set('search', this.state.admin.search);
      return this.httpClient.get<fromActions.IdeaPagination>(`<backendhost>/v1/ideas`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: fromActions.IdeaPagination) => [fromActions.addPublicIdeas(response)])
  ));

  fetchPublicIdea$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchPublicIdea),
    switchMap( (action) => {
      return this.httpClient.get<Idea>(`<backendhost>/v1/ideas/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: Idea) => [fromActions.addIdea({idea: response})])
  ));

  saveIdea$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.saveIdea),
    switchMap( (action) => {
      return this.httpClient.put<Idea>(`<backendhost>/v1/ideas/${action.id}`, action.idea, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map(() => {
        return {
          id: action.id,
          changes: action.idea,
        }
      }));
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: {id: number, changes: Idea}) => [
      fromActions.updateAdminIdea(response),
      fromActions.updateIdea(response),
    ])
  ));

  hideIdea$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.hideIdea),
    switchMap( (action) => {
      return this.httpClient.get<Idea>(`<backendhost>/v1/ideas/${action.idea.id}/hide`, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((idea: Idea) => [
      fromActions.updateAdminIdea({id: idea.id, changes: {publicUntil: idea.publicUntil} as Idea}),
      fromActions.removeIdea({id: idea.id}),
    ])
  ));

  deleteIdea$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deleteIdea),
    switchMap( (action) => {
      return this.httpClient.delete<Idea>(`<backendhost>/v1/ideas/${action.idea.id}`, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map( () => action.idea ));
    }),
    catchError(this.onError.bind(this)),
    switchMap((idea: Idea) => [
      fromActions.removeAdminIdea({id: idea.id}),
      fromActions.removeIdea({id: idea.id}),
    ])
  ));

  voteUp$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.voteUp),
    switchMap( (action) => {
      let changes = {
        myVote: "up",
        votesDown: action.idea.votesDown - (action.idea.myVote == "down" ? 1 : 0),
        votesUp: action.idea.votesUp + (action.idea.myVote != "up" ? 1 : 0)
      }
      this.store$.dispatch(fromActions.updateIdea({id: action.idea.id, changes: changes as Idea}))
      return this.httpClient.get<Idea>(`<backendhost>/v1/ideas/${action.idea.id}/vote-up`, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((idea: Idea) => [

    ])
  ));

  voteDown$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.voteDown),
    switchMap( (action) => {
      let changes = {
        myVote: "down",
        votesUp: action.idea.votesUp - (action.idea.myVote == "up" ? 1 : 0),
        votesDown: action.idea.votesDown + (action.idea.myVote != "down" ? 1 : 0)
      }
      this.store$.dispatch(fromActions.updateIdea({id: action.idea.id, changes: changes as Idea}))
      return this.httpClient.get<Idea>(`<backendhost>/v1/ideas/${action.idea.id}/vote-down`, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((idea: Idea) => [

    ])
  ));

  fetchIdeaCommentsAction$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchIdeaCommentsAction),
    switchMap((action) => this.httpClient.get<Comment[]>(`<backendhost>/v1/ideas/${action.idea.id}/comment`,{
        observe: 'body',
        responseType: 'json'
    }).pipe(map( (response: Comment[]) => {
      return {comments: response, idea: action.idea}
    }))),
    catchError(this.onError.bind(this)),
    switchMap((response: { comments: Comment[], idea: Idea}) => [fromActions.addManyCommentsToIdeaAction(response)])
  ));

  createIdeaComment$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createIdeaCommentAction),
    switchMap( action => {
      let url = action.replyTo
          ? `<backendhost>/v1/ideas/${action.idea.id}/reply/${action.replyTo.id}/comment`
          : `<backendhost>/v1/ideas/${action.idea.id}/comment` ;
      return this.httpClient.post<Comment>(url, action.comment, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map( (response:Comment) => {
        return { comment: response, action: action};
      }))
    }),
    catchError(this.onError.bind(this)),
    switchMap((payload: { comment: Comment, action: any}) => {
      let actionsToDispatch = [];
      if (payload.action.replyTo) {
        actionsToDispatch.push(fromActions.addCommentToIdeaCommentAction({ comment: payload.comment, idea: payload.action.idea, replyTo: payload.action.replyTo}))
        actionsToDispatch.push(fromActions.addCommentSuccess({ comment: payload.comment, idea: payload.action.idea, replyTo: payload.action.replyTo}))
      }
      else {
        actionsToDispatch.push(fromActions.addCommentToIdeaAction({ comment: payload.comment, idea: payload.action.idea}))
        actionsToDispatch.push(fromActions.addCommentSuccess({ comment: payload.comment, idea: payload.action.idea}))
      }

      return actionsToDispatch;
    })
  ));
  likeComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.likeIdeaCommentAction),
      switchMap((action) => {
        let url = `<backendhost>/v1/comment/${action.comment.id}/like` ;
        return this.httpClient.post<Comment>(url, {}, {observe: 'body', responseType: 'json'});
      }),
      catchError(this.onError.bind(this)),
      switchMap((response: Comment) => {
        let comment = {id: response.id, likeCount: response.likeCount, liked: true, likeSaving: false} as Comment;
        if (response.replyTo?.id) {
          return [fromActions.updateIdeaReplyCommentAction({idea: response.idea, comment: comment, replyTo: response.replyTo})];
        }
        else {
          return [fromActions.updateIdeaCommentAction({idea: response.idea, comment: comment})];
        }

      })
    );
  });
  unlikeComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.unlikeIdeaCommentAction),
      switchMap((action) => {
        let url = `<backendhost>/v1/comment/${action.comment.id}/like` ;
        return this.httpClient.delete<Comment>(url, {observe: 'body', responseType: 'json'});
      }),
      catchError(this.onError.bind(this)),
      switchMap((response: Comment) => {
        let comment = {id: response.id, likeCount: response.likeCount, liked: false, likeSaving: false} as Comment;
        if (response.replyTo?.id) {
          return [fromActions.updateIdeaReplyCommentAction({idea: response.idea, comment: comment, replyTo: response.replyTo})];
        }
        else {
          return [fromActions.updateIdeaCommentAction({idea: response.idea, comment: comment})];
        }

      })
    );
  });
  deleteComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.deleteIdeaComment),
      switchMap((action) => {
        let url = `<backendhost>/v1/ideas/${action.comment.idea.id}/comment/${action.comment.id}` ;
        return this.httpClient.delete<Comment>(url, {observe: 'body', responseType: 'json'});
      }),
      catchError(this.onError.bind(this)),
      switchMap((response: Comment) => [])
    );
  });
}

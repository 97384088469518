import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import * as fromSlotActions from '@/store/slot/slot.actions';
import * as fromSlotReducers from '@/store/slot/slot.reducers';
import { Outpost } from '@/model/outpost.model';
import { takeUntil, map, tap } from 'rxjs/operators';
import { Slot } from '@/model/slot.model';

@Component({
  selector: 'app-slots',
  templateUrl: './slots.component.html',
  styleUrls: ['./slots.component.scss']
})
export class SlotsComponent implements OnInit {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  outpost: Outpost;
  page = 1;
  pageSize = 20;
  constructor(
    protected store: Store<fromSlotReducers.State>,
    protected router: Router,
    protected actionsSubject: ActionsSubject,
    protected toastr: ToastrService,
    protected activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data: Data) => {
      this.outpost = data.instance;
      this.store.dispatch(fromSlotActions.clear())
      this.store.dispatch(fromSlotActions.setOutpost({outpost: this.outpost.id}))
      this.store.dispatch(fromSlotActions.fetch())
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  clearSearch() {
    // this.searchInput.nativeElement.value = '';
    // this.store.dispatch(fromOutpostActions.setSearch({search: null}));
  }

  get list(): Observable<Slot[]> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromSlotReducers.selectAllSlots));
  }

  delete(item: Slot): void {
    this.store.dispatch(fromSlotActions.deleteSlot({id: item.id}));
  }

  identify(index, entity: Outpost){ return entity.id }

  get total(): Observable<number>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromSlotReducers.selectState),
      map( (state:fromSlotReducers.State)  => state.total)
    );
  }

  get direction(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromSlotReducers.selectState),
      map( (state:fromSlotReducers.State)  => state.asc),
      tap( asc => this.asc = asc)
    );
  }

  get loading(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromSlotReducers.selectState),
      map( (state:fromSlotReducers.State)  => state.loading),
    );
  }

  get order(): Observable<string>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromSlotReducers.selectState),
      map( (state:fromSlotReducers.State)  => state.order),
      tap( sort => this.sort = sort)
    );
  }

  private sort: string;
  private asc: boolean;
  setSort(sort: string): void {
    if(sort == this.sort) {
      this.store.dispatch(fromSlotActions.setDirection({asc: !this.asc}));
    } else {
      this.store.dispatch(fromSlotActions.setDirection({asc: false }));
    }
    this.store.dispatch(fromSlotActions.setOrder({order: sort}));
  }

  toggleDirection(): void {
    this.store.dispatch(fromSlotActions.setDirection({asc: !this.asc}));
  }

  pageChange(page): void {
    this.store.dispatch(fromSlotActions.setPage({page: page}));
  }

  sign(slot: Slot) {
    this.store.dispatch(fromSlotActions.signSlot({slot}));
  }

  unsign(slot: Slot) {
    this.store.dispatch(fromSlotActions.unsignSlot({slot}));
  }

}

<section class="content">
  <div class="container pt-3 pb-3">
    <form [formGroup]="form" (ngSubmit)="save()">
      <div class="card card-primary">
        <div class="overlay" *ngIf="(saving | async)">
          <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
            <i class="fas fa-3x fa-spinner fa-spin"></i>
            <p>Zapisywanie...</p>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="form-group col-12">
              <label for="title">Tytuł</label>
              <input formControlName="title" type="text" [appIsValid]="form.controls.title" class="form-control" placeholder="Nazwa" />
              <div class="invalid-feedback" *ngIf="form.controls.title.errors?.required">
                Pole jest wymagane
              </div>
            </div>
            <div class="form-group col-12">
              <label for="title">Email odbiorcy formularza</label>
              <input formControlName="email" type="email" [appIsValid]="form.controls.email" class="form-control" placeholder="Email" />
              <div class="invalid-feedback" *ngIf="form.controls.email.errors?.required">
                Pole jest wymagane
              </div>
              <div class="invalid-feedback" *ngIf="form.controls.email.errors?.email">
                Niepoprawny email
              </div>
            </div>
            <div class="form-group col-12">
              <label for="lastname">Opis</label>
              <app-editor formControlName="description"></app-editor>
              <div class="text-danger" *ngIf="form.controls.description.touched && form.controls.description.errors?.required">
                Pole jest wymagane
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-3">
              <label for="inputName">Avatar</label>
              <div style="max-width: 200px;">
                <app-upload-crop-image
                  [avatar]="true"
                  [(file)] = "imageFile"
                  [image]="workStage?.orderForm?.image | image"
                  [aspectRatio]="1 / 1">
                </app-upload-crop-image>
              </div>
            </div>
            <div class="form-group col-lg-9">
              <label for="inputName">Obrazek tła</label>
              <div>
                <app-upload-crop-image
                  [avatar]="false"
                  [(file)] = "backgroundFile"
                  [image]="workStage?.orderForm?.background | image"
                  [aspectRatio]="4 / 1">
                </app-upload-crop-image>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive" *ngIf="workStage.orderForm">
          <table class="table align-middle table-hover">
            <thead>
              <tr>
                <th style="width:1rem">#</th>
                <th>Pytanie</th>
                <th>Rodzaj</th>
                <th>Odpowiedzi</th>
                <th class="text-right" >
                  <span class="btn btn-success btn-sm elevation-2 text-nowrap" (click)="addInput()">
                    <i class="fas fa-plus fa-fw fa-lg"></i> Dodaj
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of workStage.orderForm.inputs; let idx = index; ">
                <td>{{idx+1}}</td>
                <td>{{item.label}}</td>
                <td>{{ getTypeName(item) }}</td>
                <td>
                  <ul *ngIf="checkMultiple(item)">
                    <li *ngFor="let option of item.options">{{option}}</li>
                  </ul>
                </td>
                <td class="text-right text-nowrap"  style="width:1rem">
                  <a class="btn btn-primary btn-sm elevation-2 mr-2" (click)="editInput(item)" ngbTooltip="Edycja">
                    <i class="fas fa-edit fa-fw fa-lg"></i>
                  </a>
                  <a
                    class="btn btn-danger btn-sm elevation-2"
                    nz-popconfirm
                    nzPopconfirmTitle="Czy na pewno usunąć?"
                    nzOkText="Tak"
                    nzCancelText="Nie"
                    nzPopconfirmPlacement="left"
                    ngbTooltip="Usuń"
                    (nzOnConfirm)="deleteInput(idx)" >
                    <i class="fas fa-trash fa-fw fa-lg"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="workStage.orderForm.inputs.length == 0"></nz-empty>
        </div>
        <div class="card-footer">
          <button class="btn btn-success float-right" type="submit" [disabled]="(saving | async)">
            Zapisz
            <i class="fas fa-angle-right fa-fw"></i>
          </button>
        </div>
      </div>
    </form>
  </div>
</section>

<section class="content">
  <div class="container">
      <div class="row">
          <div class="col-lg-12">
            <form [formGroup]="form" (ngSubmit)="save()">
            <div class="card card-primary">
              <div class="card-body">
                <div class="form-group">
                  <label>Nazwa placówki</label>
                  <input
                    [ngModel]="outpost.name"
                    [ngModelOptions]="{standalone: true}"
                    type="text"
                    class="form-control"
                    placeholder="Nazwa placówki"
                    disabled
                    readonly
                  />
                </div>
                <div class="form-group">
                  <label>Rodzaj</label>
                  <div class="form-check" *ngFor="let enum of SlotType">
                    <input class="form-check-input" type="radio" [value]="enum" formControlName="type" [id]="enum">
                    <label class="form-check-label" [for]="enum">{{enum}}</label>
                  </div>
                </div>
                <div class="form-group">
                  <label>Dzień</label>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" value="WEEKDAY" formControlName="day" id="WEEKDAY">
                    <label class="form-check-label" for="WEEKDAY">Dzień roboczy</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" value="SATURDAY" formControlName="day" id="SATURDAY">
                    <label class="form-check-label" for="SATURDAY">Sobota</label>
                  </div>
                </div>
                <div class="form-group">
                  <label>Początek</label>
                  <nz-time-picker class="form-control" formControlName="startTime" nzFormat="HH:mm" [nzMinuteStep]="15"></nz-time-picker>
                </div>
                <div class="form-group">
                  <label>Koniec</label>
                  <nz-time-picker class="form-control" formControlName="endTime" nzFormat="HH:mm" [nzMinuteStep]="15"></nz-time-picker>
                </div>
                <div class="form-group">
                  <label>Pracownik</label>
                  <ng-select
                      [items]="users$ | async"
                      bindLabel="name"
                      [trackByFn]="trackByFn"
                      [minTermLength]="2"
                      [loading]="loading"
                      typeToSearchText="Please enter 2 or more characters"
                      [typeahead]="usersInput$"
                      formControlName="user"
                      [appIsValid]="form.controls.user">
                      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <div class="d-flex">
                        <img [src]="item | userAvatar" class="img-size-50 img-circle" [appUserDisactivatable]="item"/>
                        <div class="pl-3">
                          <span  [appUserDisactivatable]="item">{{item.name}}</span> <br>
                          <small class="text-muted"  [appUserDisactivatable]="item">{{item?.active ? item?.email : '-'}}</small>
                        </div>
                        </div>
                    </ng-template>
                  </ng-select>
                  <div class="invalid-feedback" *ngIf="form.controls.user.errors?.required">Pole jest wymagane</div>
                </div>
              </div>
              <div class="card-footer">
                <button class="btn btn-success float-right" type="submit">
                  Dalej
                  <i class="fas fa-angle-right fa-fw fa-lg"></i>
                </button>
              </div>
            </div>
            </form>
          </div>
      </div>
  </div>
</section>

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, Observable, of, OperatorFunction, Subject } from 'rxjs';
import * as fromSlotActions from '@/store/slot/slot.notification.actions';
import * as fromSlotReducers from '@/store/slot/slot.reducers';
import { Outpost } from '@/model/outpost.model';
import { takeUntil, map, tap, take, filter, debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { Slot, SlotNotificationUser } from '@/model/slot.model';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '@services/app.service';
import { User } from '@/model/user.model';

@Component({
  selector: 'app-slot-notification-users',
  templateUrl: './slot-notification-users.component.html',
  styleUrls: ['./slot-notification-users.component.scss']
})
export class SlotNotificationUsersComponent implements OnInit {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  searchTerm: string;
  page = 1;
  pageSize = 20;
  slot: Slot;
  model: any;
  searching = false;
  searchFailed = false;

  formatter = (item: any) => `${item.name}`;
  formatterEmpty = () => null;

  constructor(
    protected store: Store<fromSlotReducers.State>,
    protected router: Router,
    protected actionsSubject: ActionsSubject,
    protected toastr: ToastrService,
    protected activatedRoute: ActivatedRoute,
    protected appService: AppService
  ) { }

  ngOnInit(): void {
    this.store.dispatch(fromSlotActions.clear());
    this.activatedRoute.data.subscribe((data: Data) => {
      let slot = data.slot;
      this.slot = slot;
      this.store.dispatch(fromSlotActions.setSlot({slot}));
      this.store.dispatch(fromSlotActions.fetch());
    })
    this.store.pipe(
      take(1),
      takeUntil(this.unsubscribe$),
      select(fromSlotReducers.selectState),
      map(state => state.search)
    ).subscribe((search:string) => {
      this.searchInput.nativeElement.value = search
    });
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      takeUntil(this.unsubscribe$),
      map((event: any) => event.target.value),
      filter( (res:string) => res.length > 2),
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((text: string) => {
      this.store.dispatch(fromSlotActions.setSearch({search: text.toLowerCase().trim()}));
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  clearSearch() {
    this.searchInput.nativeElement.value = '';
    this.store.dispatch(fromSlotActions.setSearch({search: null}));
  }

  get list(): Observable<SlotNotificationUser[]> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromSlotReducers.selectAllNotificationUsers));
  }

  delete(item: SlotNotificationUser): void {
    this.store.dispatch(fromSlotActions.deleteSlotNotificationUser({id: item.id}));
  }

  identify(index, entity: SlotNotificationUser){ return entity.id }

  get total(): Observable<number>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromSlotReducers.selectAllNotificationState),
      map( (state:fromSlotReducers.NotificationState)  => state.total)
    );
  }

  get direction(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromSlotReducers.selectAllNotificationState),
      map( (state:fromSlotReducers.NotificationState)  => state.asc),
      tap( asc => this.asc = asc)
    );
  }

  get loading(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromSlotReducers.selectAllNotificationState),
      map( (state:fromSlotReducers.NotificationState)  => state.loading),
    );
  }

  get order(): Observable<string>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromSlotReducers.selectAllNotificationState),
      map( (state:fromSlotReducers.NotificationState)  => state.order),
      tap( sort => this.sort = sort)
    );
  }

  private sort: string;
  private asc: boolean;
  setSort(sort: string): void {
    if(sort == this.sort) {
      this.store.dispatch(fromSlotActions.setDirection({asc: !this.asc}));
    } else {
      this.store.dispatch(fromSlotActions.setDirection({asc: false }));
    }
    this.store.dispatch(fromSlotActions.setOrder({order: sort}));
  }

  toggleDirection(): void {
    this.store.dispatch(fromSlotActions.setDirection({asc: !this.asc}));
  }

  pageChange(page): void {
    this.store.dispatch(fromSlotActions.setPage({page: page}));
  }

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => {
        return this.appService.searchUser(term).pipe(
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      }),

      tap(() => this.searching = false)
    )
  };

  onUserSelect(selectedItem: NgbTypeaheadSelectItemEvent) {
    let user: User = selectedItem.item;
    this.store.dispatch(fromSlotActions.createSlotNotificationUser({user}));
    // this.pagination.pipe(take(1)).subscribe( invitations => {
    //   this.store.dispatch(fromTrainingPackageiaActions.addUserToInvite({
    //     user: user,
    //     package: {...this.package, ...{invitations: invitations}}
    //   }));
    // });

  }

  toggleSms(item: SlotNotificationUser) {

  }

}

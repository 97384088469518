import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as fromKbActions from '@/store/kb/kb.actions';
import * as fromKbReducers from '@/store/kb/kb.reducers';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Actions } from '@ngrx/effects';
import { Folder } from '@/model/folder.model';
import { KbArticle } from '@/model/article.model';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-articles-folder',
  templateUrl: './articles-folder.component.html',
  styleUrls: ['./articles-folder.component.scss']
})
export class ArticlesFolderComponent implements OnInit, OnDestroy  {
  unsubscribe$ = new Subject<boolean>();
  filesFetched = false;

  constructor(
    private store: Store<fromKbReducers.State>,
    private router: Router,
    private toastr: ToastrService,
    protected actions$: Actions,
  ) {
    // this.actions$.pipe(
    //   ofType(fromKbActions.saveSuccess),
    //   takeUntil(this.destroyed$)
    // ).subscribe((action) => {
    //   this.toastr.success("Zapisano zmiany");
    // });
  }
  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  get folder(): Observable<Folder>  {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromKbReducers.selectFolder));
  }

  get articles(): Observable<KbArticle[]>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromKbReducers.selectArticles),
      map(arr => arr.map( f => Object.assign({},f)))
    );
  }

  identify(file: KbArticle, index: number) {
    return file.id;
  }

  delete(article: KbArticle) {
    this.store.dispatch(fromKbActions.deleteArticle({article}));
  }

}

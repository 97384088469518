<div class="card card-widget position-relative shadow mt-3 mb-4" (inView)="seen()">
  <div class="overlay" *ngIf="showOverlay">
    <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%,0);text-align: center; padding: 25px 0">
      <i class="fas fa-3x fa-spinner fa-spin"></i>
      <p>Zapisywanie...</p>
    </div>
  </div>
  <div class="ribbon-wrapper ribbon-lg" *ngIf="!post?.seen && !post?.important">
    <div class="ribbon bg-orange text-lg">
      NOWE
    </div>
  </div>
  <div class="ribbon-wrapper ribbon-xl" *ngIf="!post?.seen && post?.important">
    <div class="ribbon bg-danger text-lg">
      KLUCZOWA <i class="far fa-bell"></i>
    </div>
  </div>
  <div [ngStyle]="backgroundStyle()"></div>
  <div class="card-header pb-1">
    <div class="user-block">
      <img class="img-circle" [src]="post?.createdBy | userAvatar" alt="User Image" [appUserDisactivatable]="post?.createdBy">
      <span class="username">
        <span class="text-decoration-line-through" *ngIf="!post?.createdBy">Użytkownik usunięty</span>
        <a [routerLink]="['/profile', post?.createdBy?.id]" *ngIf="post?.createdBy" [appUserDisactivatable]="post?.createdBy">
          {{post?.createdBy?.firstname}} {{post?.createdBy?.lastname}}
        </a>
      </span>
      <!-- <span class="description"> {{post.createdAt | amLocale:'pl' | amTimeAgo }}</span> -->
      <span class="description"> {{post?.createdAt | amLocale:'pl' | amCalendar }}</span>
      <span class="description">
        <a class="btn btn-link p-0 pr-1 text-sm" *ngFor="let tag of post?.tags" [routerLink]="['/search', tag | lowercase]">#{{tag}}</a>
      </span>

    </div>
    <div class="card-tools">


      <a class="btn btn-tool"
        (click)="tooggleFavorite()"
        [class.text-primary]="post?.favorite"
        [class.disabled]="post?.favoriteSaving"
        ngbTooltip="Dodaj post do ulubionych">
        <i class="far fa-star" [class.fas]="post?.favorite" *ngIf="!post?.favoriteSaving"></i>
        <i class="fas fa-spinner fa-spin" *ngIf="post?.favoriteSaving"></i>
      </a>

      <a class="btn btn-tool"
        [routerLink]="['/news', post?.id, 'seen-report']"
        checkRolePermition="news.edit"
        [instance]="post"
        *ngIf="post?.important"
        ngbTooltip="Raport z wyświetleń">
        <i class="fas fa-chart-area"></i>
      </a>

      <a class="btn btn-tool"
        [routerLink]="['/news', post?.id, 'edit']"
        checkRolePermition="news.edit"
        [instance]="post"
        ngbTooltip="Edytuj">
        <i class="fas fa-edit"></i>
      </a>

      <button
        checkRolePermition="news.delete"
        [instance]="post"
        type="button"
        class="btn btn-tool"
        nz-popconfirm
        nzPopconfirmTitle="Czy na pewno usunąć?"
        nzOkText="Tak"
        nzCancelText="Nie"
        nzPopconfirmPlacement="left"
        ngbTooltip="Usuń"
        (nzOnConfirm)="delete()">
        <i class="fas fa-trash"></i>
      </button>
    </div>
  </div>
  <div class="card-header pt-1" >
    <a *ngIf="post?.id" class="text-primary card-title text-lg m-0" [routerLink]="['/news', post.id]"s>
      {{post?.title}}
    </a>
    <div class="card-tools d-flex flex-column">
      <div class="text-right">
        <a *ngFor="let group of post?.groups | slice : 0 : 3"
          [routerLink]="['/groups', group.id, 'news']" class="badge rounded-pill bg-primary tag" >
            <i class="fas fa-users"></i>
            {{group.name}}
        </a>
        <div ngbDropdown class="d-inline-block" *ngIf="post?.groups.length > 3">
          <a class="badge rounded-pill bg-secondary tag" ngbDropdownToggle><i class="fas fa-users"></i> +{{ post?.groups.length - 3}}</a>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <a
              *ngFor="let group of post?.groups | slice : 3"
              [routerLink]="['/groups', group.id, 'news']"
              class="user-block"
              ngbDropdownItem>
              <img class="img-circle" [src]="group | groupAvatar: 25" alt="Group Image" style="width:25px; height:25px">
              <span class="username" style="margin-left:35px"> {{group?.name}}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="text-right">
        <a *ngFor="let user of post?.users | slice : 0 : 3"
          [routerLink]="['/profile', user.id]" class="badge rounded-pill bg-info tag" >
            <i class="far fa-user"></i>
            {{user.name}}
        </a>
        <div ngbDropdown class="d-inline-block" *ngIf="post?.users.length > 3">
          <a class="badge rounded-pill bg-secondary tag" ngbDropdownToggle><i class="far fa-user"></i> +{{ post?.users.length - 3}}</a>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <a
              *ngFor="let user of post?.users | slice : 3"
              [routerLink]="['/profile', user?.id]"
              class="user-block"
              ngbDropdownItem>
              <img class="img-circle" [src]="user | userAvatar: 25" alt="User Image" [appUserDisactivatable]="user">
              <span class="username"> {{user?.name}}</span>
              <span class="description"> {{user.email }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body" [ngClass]="{'rolledup' : small}">
    <div class="inside" [@slideInOutMore]="small ? moreAnimationState : null">
      <div  *ngIf="audioPathPlyr.length">
        <plyr *ngIf="audioPathPlyr.length"
          [plyrPlaysInline]="false"
          [plyrSources]="audioPathPlyr"
          plyrType="audio"
          (plyrInit)="player = $event"
          [plyrOptions]="audioOptions"></plyr>
      </div>
      <div class="" bind-innerHTML="post?.content | safeHtml"></div>
      <div class="row m-0">
        <div class="col-md-4 p-0">
          <a *ngFor="let img of images3cols[0]; let idx = index"  style="margin:1px; display: block;" >
            <img [src]="img | image"
              [alt]="img.name"
              class="img-fluid d-block"
              style="margin:1px"
              (click)="open(img.id)"/>
          </a>
        </div>
        <div class="col-md-4 p-0">
          <a *ngFor="let img of images3cols[1]; let idx = index"  style="margin:1px; display: block;">
            <img [src]="img | image"
              [alt]="img.name"
              class="img-fluid d-block"
              style="margin:1px"
              (click)="open(img.id)"/>
          </a>
        </div>
        <div class="col-md-4 p-0">
          <a *ngFor="let img of images3cols[2]; let idx = index"  style="margin:1px; display: block;">
            <img [src]="img | image"
              [alt]="img.name"
              class="img-fluid d-block"
              style="margin:1px"
              (click)="open(img.id)"/>
          </a>
        </div>
      </div>
      <div class="text-center">
        <span class="btn btn-primary" *ngIf="!post?.seen && post?.important" (click)="confirmSeen()">
          <i class="fas fa-check fa-fw"></i>
          Potwierdz obejrzenie
        </span>
      </div>
    </div>
    <div class="bottom-overlay" *ngIf="post?.id && small">
      <div class="d-flex">
        <div class="btn-group">
          <a class="btn btn-outline-primary btn-sm" (click)="toggleMore()" >
            <i class="fas fa-fw" [class]="moreAnimationState == 'out' ? 'fa-angle-down' : 'fa-angle-up'" ></i>
            {{moreAnimationState == 'out' ? 'rozwiń' : 'zwiń'}}
          </a>
          <a class="btn btn-outline-primary btn-sm" [routerLink]="['/news', post.id]">
            <i class="fas fa-fw fa-expand"></i>
            pokaż news
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="card-footer card-footer-buttons">
    <a class="text-sm float-left" (click)="like()" [ngClass]="{'link-black': !post?.liked, 'link-primary  text-bold': post?.liked}">

      <span class="badge text-sm rounded-pill" [ngClass]="{'badge-secondary': !post?.liked, 'badge-primary': post?.liked}">
        <i class="fa-thumbs-up" [ngClass]="{'far': !post?.liked, 'fas': post?.liked}" *ngIf="!post?.likeSaving"></i>
        <i class="fa-spinner fa-spin fas" *ngIf="post?.likeSaving"></i>
        {{post?.likeCount ? post.likeCount : 0}}
      </span>
      <span class="d-none d-sm-inline"> Lubię to</span>
    </a>
    <span class="pl-3 text-black text-sm float-left" (click)="seen()">
      <span class="badge text-sm rounded-pill" [ngClass]="{'badge-secondary': !post?.seen, 'badge-primary': post?.seen}">
        <i class="far fa-eye"></i>
        {{ post?.seenCount }}
      </span>
      <span class="d-none d-sm-inline"> wyświetleń</span>
    </span>
    <a class="pl-3 link-black text-sm float-left" (click)="toggleShowComments()">
      <span class="badge text-sm rounded-pill" [ngClass]="{'badge-secondary': post?.comments.count == 0, 'badge-primary': post?.comments.count > 0}">
        <i class="fa-comments" [ngClass]="{'far': post?.comments.count == 0, 'fas': post?.comments.count > 0}"  ></i>
        {{ post?.comments.count }}
      </span>
      <span class="d-none d-sm-inline"> komentarze</span>
    </a>
  </div>

  <div class="card-footer card-comments" *ngIf="showComments">
    <div class="overlay" *ngIf="showCommentOverlay">
      <div>
        <i class="fas fa-3x fa-spinner fa-spin"></i>
        <p>Ładowanie...</p>
      </div>
    </div>
    <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(comments | async).length == 0"></nz-empty>
    <div class="card-comment" full *ngFor="let comment of comments | async; let idx = index; trackBy: commentIdenity" [comment]="comment" [post]="post"></div>
  </div>
  <comment-add  [post]="post" *ngIf="showComments"></comment-add>
  <a name="comments" #addComments></a>
</div>

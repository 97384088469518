import * as gitVersion from '../../assets/git-version.json';

export const versionInfo = (() => {
  try {
    // tslint:disable-next-line:no-var-requires
    return gitVersion;
  } catch {
    // In dev the file might not exist:
    return { tag: 'v0.0.0', hash: 'dev', branch: "master", raw: 'v0.0.0' , timestamp: 'Thu, 01 Jan 1970 00:00:00 GMT'};
  }
})();

<div class="modal-header">
  <h4 class="modal-title">Wysyłanie powiadomienia</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  Czy wysłać powiadomienie konsultantowi o dostępności karty monitoringu?
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary btn-lg" (click)="send()">
    <i class="fas fa-check fa-fw"></i>
    Wyślij
  </button>
  <button type="button" class="btn btn-default" (click)="dismiss()">
    <i class="fas fa-times fa-fw"></i>
    Nie
  </button>
</div>

<div class="card">
  <div class="card-footer mt-0 pt-3">
    <h3 class="card-title">Załaduj plik SCORM</h3>
  </div>
  <div>
    <nz-upload
      nzType="drag"
      [nzMultiple]="false"
      [nzDisabled]="uploading"
      nzAction="<backendhost>/v1/scorm"
      (nzChange)="handleUploadChange($event)"
    >
    <div [ngStyle]="{'opacity': uploading ? 0.5 : 1}">
      <p class="ant-upload-drag-icon">
        <i nz-icon nzType="inbox" *ngIf="!uploading"></i>
        <i class="fa fa-spinner fa-spin fa-2x text-info" *ngIf="uploading"></i>
      </p>
      <p class="ant-upload-text">Kliknij lub przeciągnij plik do tego obszaru, aby przesłać</p>
      <p class="ant-upload-hint"></p>
    </div>
    </nz-upload>
  </div>
</div>

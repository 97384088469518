import { Group, GroupMember } from '@/model/group.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromGroupReducer from '@/store/group/group.reducers';
import * as fromGroupActions from '@/store/group/group.actions';

@Component({
  selector: 'app-view-group',
  templateUrl: './view-group.component.html',
  styleUrls: ['./view-group.component.scss']
})
export class ViewGroupComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  groupObserver: BehaviorSubject<Group>;
  members: GroupMember[];
  page = 1;
  pageSize = 20;

  constructor(
    private route: ActivatedRoute,
    private store: Store<fromGroupReducer.State>
  ) {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.group.subscribe(group => {
      this.groupObserver = new BehaviorSubject(group);
      this.groupObserver.subscribe(this.getMembers.bind(this));
      this.store.dispatch(fromGroupActions.fetchGroupMembersAction({group}));
      this.store.subscribe((data: any) =>{
        this.groupObserver.next(data.groups.entities[group.id])
      });
    })
  }

  get group(): Observable<Group> {
    return this.route.data.pipe(map(data=> data.instance as Group));
  }

  onPageChange() {
    // this.groupObserver.next(this.group)
  }

  private getMembers(data): void {
    if(!this.group)return;
    let result: GroupMember[] = Object.values(data.members.entities);
    result = result.slice( (this.page-1) * this.pageSize, this.page * this.pageSize );
    this.members = result.map( (item: GroupMember) => {return { ...item}; });
  }

  groupMemberIdentity(index: number, member: GroupMember) {
    return member.user.id;
  }

}

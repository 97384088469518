import { Invitation } from '@/model/package.model';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Data } from '@angular/router';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as fromMyTrainingsAction from '@/store/my-training/my-training.actions';
import * as fromMyTrainingsReducers from '@/store/my-training/my-training.reducers';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-my-training-certificate',
  templateUrl: './my-training-certificate.component.html',
  styleUrls: ['./my-training-certificate.component.scss']
})
export class MyTrainingCertificateComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  @ViewChild('viewer') viewer: ElementRef;

  isMobile: boolean;
  invitation: Invitation
  pdfSrc: any;
  imgSrc: any;
  constructor(
    activatedRoute: ActivatedRoute,
    sanitizer: DomSanitizer,
    deviceService: DeviceDetectorService,
    private store: Store<fromMyTrainingsReducers.State>
  ) {
    this.isMobile = deviceService.isMobile()
    activatedRoute.data.subscribe((data: Data) => {
      this.invitation = data.invitation;
      this.store.pipe(
        select(fromMyTrainingsReducers.selectOneMyTraining, this.invitation.id),
        takeUntil(this.unsubscribe$),
      ).subscribe( (invitation) => {
        this.invitation = invitation;
        this.pdfSrc = sanitizer.bypassSecurityTrustResourceUrl(`${environment.apiUrl}/certificate/${this.invitation.best?.certificate}.pdf`);
        this.imgSrc = sanitizer.bypassSecurityTrustResourceUrl(`${environment.apiUrl}/certificate/${this.invitation.best?.certificate}.jpg`);
      })

    })
  }

  ngOnInit(): void {}

}

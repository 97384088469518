<div class="card card-primary">
  <div class="card-header">
  <h3 class="card-title">{{item.title}}</h3>
</div>
<div class="card-footer pb-1">
  <div class="user-block">
    <img class="img-circle" [src]="item?.createdBy | userAvatar" alt="User Image" [appUserDisactivatable]="item.createdBy">
    <span class="username">
      <span class="text-decoration-line-through" *ngIf="!item?.createdBy">Użytkownik usunięty</span>
      <a [routerLink]="['/profile', item?.createdBy?.id]" *ngIf="item?.createdBy" [appUserDisactivatable]="item.createdBy">
        {{item?.createdBy?.firstname}} {{item?.createdBy?.lastname}}
      </a>
    </span>
    <span class="description"> {{item?.createdAt | amLocale:'pl' | amCalendar }}</span>
  </div>
  <div class="card-tools float-right" >
    <h4 class="d-inline" >
      <span class="badge" [class]="statusClass(item)">{{statusName(item)}}</span>
    </h4>
    <button
      *ngIf="!isPublic(item)"
      checkRolePermition="ideas.delete" [instance]="item"
      type="button"
      class="btn btn-tool"
      nz-popconfirm
      nzPopconfirmTitle="Czy na pewno usunąć?"
      nzOkText="Tak"
      nzCancelText="Nie"
      nzPopconfirmPlacement="left"
      ngbTooltip="Usuń"
      (nzOnConfirm)="delete(item)">
      <i class="fas fa-trash"></i>
    </button>
  </div>
</div>
<div class="card-body" [innerHtml]="item.description | safeHtml"></div>
<div class="card-footer" *ngIf="votinOpen(item)">
  <span class="btn btn-outline-success" (click)="voteUp(item)" *ngIf="notVoted(item)">
    <i class="far fa-thumbs-up fa-fw fa-lg"></i>&nbsp;<span class="badge rounded-pill bg-success">{{item.votesUp}}</span>
  </span>
  <span class="btn btn-success" (click)="voteUp(item)" *ngIf="votedUp(item)">
    <i class="far fa-thumbs-up fa-fw fa-lg"></i>&nbsp;<span class="badge rounded-pill bg-light text-dark">{{item.votesUp}}</span>
  </span>
  <span class="btn btn-secondary" (click)="voteUp(item)" *ngIf="votedDown(item)">
    <i class="far fa-thumbs-up fa-fw fa-lg"></i>&nbsp;<span class="badge rounded-pill bg-light text-dark">{{item.votesUp}}</span>
  </span>
  &nbsp;
  <span class="btn btn-outline-danger" (click)="voteDown(item)" *ngIf="notVoted(item)">
    <i class="far fa-thumbs-down fa-fw fa-lg"></i>&nbsp;<span class="badge rounded-pill bg-danger">{{item.votesDown}}</span>
  </span>
  <span class="btn btn-danger" (click)="voteDown(item)" *ngIf="votedDown(item)">
    <i class="far fa-thumbs-down fa-fw fa-lg"></i>&nbsp;<span class="badge rounded-pill bg-light text-dark">{{item.votesDown}}</span>
  </span>
  <span class="btn btn-secondary" (click)="voteDown(item)" *ngIf="votedUp(item)">
    <i class="far fa-thumbs-down fa-fw fa-lg"></i>&nbsp;<span class="badge rounded-pill bg-light text-dark">{{item.votesDown}}</span>
  </span>
</div>
<div class="card-footer" *ngIf="!votinOpen(item)">
  <span [ngClass]="{'text-success' : item.votesUp > 0 , 'text-dark' : item.votesUp == 0}"  ngbTooltip="Głosowanie nie jest teraz aktywene">
    <i class="far fa-thumbs-up fa-fw fa-lg"></i>
    <span class="badge rounded-pill" [ngClass]="{'bg-success' : item.votesUp > 0 , 'bg-secondary' : item.votesUp == 0}">
      {{item.votesUp}}
    </span>
  </span>
  &nbsp;
  <span [ngClass]="{'text-danger' : item.votesDown > 0 , 'text-dark' : item.votesDown == 0}"  ngbTooltip="Głosowanie nie jest teraz aktywene">
    <i class="far fa-thumbs-down fa-fw fa-lg"></i>
    <span class="badge rounded-pill" [ngClass]="{'bg-danger' : item.votesDown > 0 , 'bg-secondary' : item.votesDown == 0}">
      {{item.votesDown}}
    </span>
  </span>
</div>
<div class="card-footer card-footer-buttons">
  <a class="pl-3 link-black text-sm float-left" (click)="toggleShowComments()">
    <span class="badge text-sm rounded-pill" [ngClass]="{'badge-secondary': item?.comments.count == 0, 'badge-primary': item?.comments.count > 0}">
      <i class="fa-comments" [ngClass]="{'far': item?.comments.count == 0, 'fas': item?.comments.count > 0}"  ></i>
      {{ item?.comments.count }}
    </span>
    <span class="d-none d-sm-inline"> komentarze</span>
  </a>
</div>
<div class="card-footer card-comments position-relative" *ngIf="showComments">
  <div class="overlay" *ngIf="showCommentOverlay" style="position: absolute; top:0; left: 0; bottom:0; right: 0">
    <div>
      <i class="fas fa-3x fa-spinner fa-spin"></i>
      <p>Ładowanie...</p>
    </div>
  </div>
  <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="item?.comments?.list.ids.length == 0"></nz-empty>
  <div
    class="card-comment"
    full
    *ngFor="let comment of comments(); let idx = index; trackBy: commentIdenity" [comment]="comment" [idea]="item"></div>
</div>
<comment-add  [idea]="item" *ngIf="showComments"></comment-add>
<a name="comments" #addComments></a>
</div>

import { AuthService } from '@/auth/auth.service';
import { User } from '@/model/user.model';
import { Comment } from '@/model/comment.model';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromReducers from '@/store/offer/offers.reducers'
import * as fromActions from '@/store/offer/offer.actions';

import { SlideInOutAnimation } from '@/animations';
import { Observable, Subject } from 'rxjs';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';
import { ImagePipe } from '@/pipes/image.pipe';
import { environment } from 'environments/environment';
import { Actions, ofType } from '@ngrx/effects';
import { Offer } from '@/model/offer.model';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ShareLinkPopupComponent } from '@components/share-link-popup/share-link-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OfferRefferComponent } from '../offer-reffer/offer-reffer.component';

@Component({
  selector: 'offer-full',
  templateUrl: './offer-full.component.html',
  styleUrls: ['./offer-full.component.scss'],
  animations: [SlideInOutAnimation]
})
export class OfferFullComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  form: FormGroup;
  offer: Offer;
  user: User;
  showComments = false;
  moreAnimationState = 'out';
  showOverlay = false;
  showCommentOverlay = true;
  protected imagePipe: ImagePipe;
  fileList: NzUploadFile[] = [];
  isPublic: boolean;

  constructor(
    protected store: Store<fromReducers.State>,
    protected authService: AuthService,
    protected lightbox: Lightbox,
    protected lightboxConfig: LightboxConfig,
    protected actions: Actions,
    protected activeRoute: ActivatedRoute,
    protected httpClient: HttpClient,
    protected toaster: ToastrService,
    protected router: Router,
    protected modalService: NgbModal,
    protected window: Window
  ) {
    this.imagePipe = new ImagePipe();
    this.lightboxConfig.showZoom = true;
    this.lightboxConfig.centerVertically = true;
    this.lightboxConfig.wrapAround = true;
    this.fileList = [];
    this.form = new FormGroup({
      firstname: new FormControl(null, Validators.required),
      lastname: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      files: new FormControl(null,  [Validators.required, Validators.min(1)]),
      refferer: new FormControl(null),
    });

  }

  onFileRemove = (file: NzUploadFile): boolean => {
    this.form.controls.files.setValue(this.fileList.length - 1);
    return true;
  };

  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    this.form.patchValue({files: this.fileList.length});
    return false;
  };

  ngAfterViewInit(): void {
    // this.actions.pipe(
    //   ofType(fromPostActions.fetchPostCommentsAction),
    //   filter(action => action.post.id == this.post.id))
    //     .subscribe(() => this.showCommentOverlay = true);
    // this.actions.pipe(
    //   ofType(fromPostActions.addManyCommentsToPostAction),
    //   filter(action => action.post.id == this.post.id))
    //     .subscribe(() => this.showCommentOverlay = false)
  }

  ngOnInit(): void {

    this.activeRoute.data.pipe(map( (x:any) => x?.public)).subscribe(isPublic => {
      this.isPublic = isPublic == true;
    });
    this.activeRoute.paramMap.pipe(
      map((params: ParamMap) => parseInt(params.get('id')))
    ).subscribe( (id?: number) => {
      this.store.dispatch(fromActions.fetchOne({id}));
      console.log("ABC");
      this.store.pipe(takeUntil(this.unsubscribe$), distinctUntilChanged(), select(fromReducers.selectOfferById, id)).subscribe (offer => {
        if(!offer) return;
        this.offer = offer;

        this.authService.getUser().then(user => {
          this.user = user
          this.form.patchValue(user);
          this.seen();
          if(this.user && !this.offer.seen) {
            this.store.dispatch(fromActions.setAsSeen({offer: this.offer}));
          }
        });
      })
    })
    this.activeRoute.paramMap.pipe(
      map((params: ParamMap) => parseInt(params.get('refferer')))
    ).subscribe( (refferer?: number) => {
      this.form.patchValue({refferer});
      // console.log(refferer, this.form.value);
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  save() {

    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    if (this.form.valid && this.form.enabled) {
      const formData = new FormData();
      formData.append('firstname', this.form.value.firstname);
      formData.append('lastname', this.form.value.lastname);
      formData.append('email', this.form.value.email);
      formData.append('refferer', this.form.value.refferer);
      this.fileList.forEach((file: any) => {
        formData.append('files[]', file);
      });
      return this.httpClient
        .post<any>(`<backendhost>/v1/offers/${this.offer.id}/applicate`, formData, { observe: 'body', responseType: 'json'})
        .subscribe(() => {
          this.store.dispatch(fromActions.updateOne({offer: {id: this.offer.id, applicationsCount: this.offer.applicationsCount+1} as Offer}));
          this.toaster.success('Poprawnie złożyłeś aplikacje');

          if(!this.isPublic)
            this.router.navigateByUrl("/offer");
        })
      } else if (this.form.enabled){
        // this.form.enable();
        this.toaster.error('Formularz jest nieprawidłowy!');
      } else {
      }

  }

  toggleShowComments(): void {
    // this.showComments = !this.showComments;
    // if (this.showComments) {
    //   this.store.dispatch(fromPostActions.fetchPostCommentsAction({post: this.post}));
    // }
  }



  // get comments(): Observable<Comment[]> {
  //   return this.store.pipe(select(fromPostReducers.selectComments, this.offer.id));
  // };

  seen () {
    if (!this.offer.seen && this.user) {
      this.offer = {...this.offer, ...{seen:true}};
      this.store.dispatch(fromActions.setAsSeen({offer: this.offer}));
    }
  }

  like() {
    this.offer = {...this.offer, ...{likeSaving:true}};
    if (!this.offer.liked) {
      this.store.dispatch(fromActions.likeOfferAction({offer:this.offer}));
    }
    else {
      this.store.dispatch(fromActions.unlikeOfferAction({offer:this.offer}));
    }
  }

  backgroundStyle () {

    if(!this.offer)
      return null;
    if(!this.offer.background)
      return null;
    return {
      'aspect-ratio': '4',
      'background' : `url(${environment.apiUrl}/thumb/1920x0/${this.offer.background.replace('public','')}) center center`,
      'background-size': 'cover'
    }
  }

  delete() {
    // this.showOverlay = true;
    this.store.dispatch(fromActions.deleteOne({offer:this.offer}));
    this.router.navigateByUrl("/offer");
  }

  commentIdenity(item: Comment, index: number) {
    return item.id;
  }

  share() {
    const modalRef = this.modalService.open(ShareLinkPopupComponent);
    modalRef.componentInstance.url = `https://${this.window.location.hostname}/public/offer/${this.offer.id}/refferer/${this.user.id}`;
  }

  reffer() {
    const modalRef = this.modalService.open(OfferRefferComponent, {size: 'lg',scrollable: false});
    modalRef.componentInstance.offer = this.offer;
    modalRef.componentInstance.form.patchValue({
      reffererName: `${this.user.firstname} ${this.user.lastname}`,
      reffererEmail: this.user.email,
      url: `https://${this.window.location.hostname}/public/offer/${this.offer.id}/refferer/${this.user.id}`
    })
  }
}

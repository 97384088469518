import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

import * as fromScormActions from '@/store/scorm/scorm.actions';
import * as fromScormReducers from '@/store/scorm/scorm.reducers';
import { ActionsSubject, Store } from '@ngrx/store';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Scorm } from '@/model/scorm.model';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'app-scorm-edit',
  templateUrl: './scorm-edit.component.html',
  styleUrls: ['./scorm-edit.component.scss']
})
export class ScormEditComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  scorm: Scorm;
  constructor(
    private toastr: ToastrService,
    private store: Store<fromScormReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      pointable: new FormControl(true),
      defaultMinScore: new FormControl(true),
      minScore: new FormControl(100, Validators.required),
    });
    this.form.valueChanges.subscribe(value => {
      if(value.pointable) {
        this.form.controls.defaultMinScore.enable({emitEvent: false});
      } else {
        this.form.controls.defaultMinScore.disable({emitEvent: false});
      }
    })
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe( (data: Data) => {
      this.scorm = data.instance;
      this.form.patchValue(this.scorm);
    });
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  save(): void {
    if (this.form.valid) {
      this.scorm = {...this.scorm, ...this.form.value};
      this.store.dispatch(fromScormActions.saveScorm({item: this.scorm}));
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromScormActions.updateScorm.type)
      ).subscribe( (result: any) => {
        this.toastr.success('Zapisano zmiany');
        this.router.navigate(['/training/admin/scorm']);
      });
    } else {
        this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }

  percentFormatter(value: number): string {
    return `${value}%`;
  }

}

import { versionInfo } from '../utils/version.info';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdatePopupComponent } from '@modules/main/update-popup/update-popup.component';
import { ifStmt } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  constructor(
    public updates: SwUpdate,
    public http: HttpClient,
    private toastr: ToastrService,
    private modalService: NgbModal,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {
    http.get('<backendhost>/v1/version').subscribe((json:any) => {
      console.log('t1', json.raw, versionInfo.raw, json.raw != versionInfo.raw && json.raw);
      let lastRefresh = +this.storage.get("last-refresh");
      lastRefresh = lastRefresh ? lastRefresh : 0;

      if(json.raw != versionInfo.raw && json.raw) {
        if((Date.now() - lastRefresh) > 1000 * 60 * 60 * 24) {
          this.storage.set("last-refresh", Date.now());
          // @ts-ignore
          window.location.reload(true);
        } else {
          updates.activateUpdate();
          // setTimeout(() => {
          //   this.modalService.open(UpdatePopupComponent);
          // }, 1000 * 60)
        }
      }
    });
    updates.activated.subscribe(event => {
      console.log(event)
      console.log('old version was', event.previous);
      console.log('new version is', event.current);
    });

    updates.available.subscribe(event => {
      console.log(event)
    });
    if (updates.isEnabled) {
      interval(6 * 60 * 60)
        .subscribe(() => updates.checkForUpdate()
        .then((event) => console.log('checking for updates', event)));
    }
  }

  public checkForUpdates(): void {
    console.log('checkForUpdates');
    this.updates.available.subscribe(event => this.promptUser(event));
  }

  private promptUser(event): void {
    console.log('updating to new version', event);
    this.updates.activateUpdate().then(() => document.location.reload());
  }
}

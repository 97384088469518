import { tinyMceConfig } from '@/config/tinymce.config';
import { User } from '@/model/user.model';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef, NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppService } from '@services/app.service';
import { merge, Observable, of, OperatorFunction, pipe, Subject } from 'rxjs';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import * as fromGroupReducer from '@/store/group/group.reducers';
import * as fromGroupActions from '@/store/group/group.actions';

import * as fromPostReducer from '@/store/post/post.reducers';
import * as fromPostActions from '@/store/post/post.actions';

import { Post } from '@/model/post.model';
import { Group } from '@/model/group.model';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { HttpClient } from '@angular/common/http';
import ClassicEditorWithMention from 'ckeditor5/packages/ckeditor5-build-classic/build/ckeditor'
import { ckeConfig } from '@/config/cke.config';
import { SmsConfirmModalComponent } from '@components/sms-confirm-modal/sms-confirm-modal.component';
import moment from 'moment';
import { ComponentWithUnsavedData } from '@guards/can-deactivate.guard';
import { environment } from 'environments/environment';
import { base64ToFile } from 'ngx-image-cropper';
import { DomSanitizer } from '@angular/platform-browser';



@Component({
  selector: 'app-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.scss']
})
export class AddNewsComponent implements OnInit, ComponentWithUnsavedData {
  @ViewChild('instanceGroup', {static: true}) instanceGroup: NgbTypeahead;
  @ViewChild('instanceUser', {static: true}) instanceUser: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  focusGroup$ = new Subject<string>();
  clickGroup$ = new Subject<string>();

  post: Post ={
    id: null,
    title: null,
    content: null,
    tags: []
  }
  Editor = ClassicEditorWithMention;

  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  ckeEditorConfig = ckeConfig;
  // @ViewChild('editor') editorComponent: CKEditorComponent;
  tinyMceConfig = tinyMceConfig;
  typeaheadModel: any;
  typeaheadList: Group[] = [];

  typeaheadUserModel: any;
  typeaheadUsersList: User[] = [];

  formatter = (group: Group) => `${group.name}`;
  formatterEmpty = () => null;
  groups: Group[];
  previewImage: string | undefined = '';
  previewVisible = false;
  fileList: NzUploadFile[] = [];
  filesToUploadCount = 0;
  uploading = false;
  isSubmited = false;
  backgroundFile: NzUploadFile;
  @Output() fileListChange: EventEmitter<NzUploadFile[]> = new EventEmitter<NzUploadFile[]>();

  constructor(
    private appService: AppService,
    private httpClient: HttpClient,
    private store: Store<fromGroupReducer.State>,
    private storePost: Store<fromPostReducer.PostsState>,
    private toastr: ToastrService,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private sanitized: DomSanitizer,
    protected modalService: NgbModal,
  ) { }


  hasUnsavedData(): boolean {
    return !this.isSubmited && (this.form.dirty);
  }

  ngOnInit(): void {
    this.store.dispatch(fromGroupActions.fetchAllAction());

    this.store.pipe(takeUntil(this.unsubscribe$), select(fromGroupReducer.selectAllGroups)).subscribe( (groups: Group[]) => {
      this.groups = groups;
    });

    this.form = new FormGroup({
      title: new FormControl(null, Validators.required),
      content: new FormControl(null, Validators.required),
      groups: new FormArray([]),
      sendTo: new FormControl('all', Validators.required),
      sendPush: new FormControl(false),
      sendSms: new FormControl(false),
      sendEmail: new FormControl(false),
      important: new FormControl(false),
      startDate: new FormControl(new Date(),[this.DateTimeValidator.bind(this)]),
      endDate: new FormControl(new Date(),[this.DateTimeValidator.bind(this)]),
      users: new FormArray([]),
    });
    this.form.get("startDate").disable();
    this.form.get("endDate").disable();
    this.form.controls.sendTo.valueChanges.subscribe((value) => {
      setTimeout(() => this.updateSelectedUsers(), 0);
      // switch(value) {
      //   case 'users':
      //     this.form.controls.users.addValidators([Validators.required]);
      //     this.form.controls.groups.removeValidators([Validators.required]);
      //     break;
      //   case 'groups':
      //     this.form.controls.users.removeValidators([Validators.required]);
      //     this.form.controls.groups.addValidators([Validators.required]);
      //     break;
      //   case 'all':
      //     this.form.controls.users.removeValidators([Validators.required]);
      //     this.form.controls.groups.removeValidators([Validators.required]);
      //     break;
      // }
    });
    this.post = {
      ...this.post,
      ...{
        startDate : moment().format('YYYY-MM-DD 00:00:00'),
        endDate : moment().add(1, 'M').format('YYYY-MM-DD 23:45:00'),
      }
    };
    this.form.patchValue(this.post);
    this.updateSelectedUsers();
  }



  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get saving(): Observable<boolean> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromPostReducer.selectSaving));
  }

  get fileUploadedCount(): number {
    return this.fileList.filter(x => x.status !== 'uploading').length;
  }


  async submit() {
    this.isSubmited = true;
    this.post = {...this.post, ...this.form.value, ...{
      startDate : this.post.startDateActive ? this.form.value.startDate.toLocaleString('pl-PL') : null,
      endDate : this.post.endDateActive ? this.form.value.endDate.toLocaleString('pl-PL') : null
    }};
    let backgroundFile = this.backgroundFile ? await this.backgroundFile : null;
    let audioFile = this.audioFile?.length ? await this.audioFile[0] as any : null;
    this.storePost.dispatch(fromPostActions.createNewPostAction({
      post: this.post,
      background: backgroundFile,
      audioFile: audioFile,
    }));
    this.actionsSubject$.pipe(
      takeUntil(this.unsubscribe$),
      filter((action) => action.type === fromPostActions.addOneNews.type),
      tap( (action: any) => {
        this.post = action.post;
        let fileList = [...this.fileList];
        this.fileList = [];
        this.filesToUploadCount = fileList.length;
        if(fileList.length == 0) {
          this.toastr.success('Opublikowano post!');
          setTimeout(() => {this.router.navigate(['/news']);}, 500);
        } else {
          this.uploading = true;
        }

        fileList.forEach( (file: NzUploadFile, index: number) => {

          file.status = 'uploading';
          this.fileList.push(file);
          this.fileListChange.emit(this.fileList);
          const formData = new FormData();
          formData.append('file', file as any);
          this.httpClient.post<any>(`<backendhost>/v1/posts/${action.post.id}/images`, formData, {observe: 'body',responseType: 'json'})
            .subscribe(
              (response) => {
                console.log("S");
                file.status = 'success';
                fileList.splice(index, 1, file);
                this.fileList = [...fileList];
                let num = fileList.filter(x => x.status !== 'uploading').length;
                // this.toastr.success(`Wgrano obraz ${num} z ${fileList.length}`, file.name);

              this.post = {...this.post, ...{ images: [...this.post.images, response]}};
                this.store.dispatch(fromPostActions.updateNews({post: this.post}));
                if (num == fileList.length) {
                  setTimeout(() => {
                    this.toastr.success('Opublikowano post!');
                    this.router.navigate(['/news']);
                    this.uploading = false;
                  }, 500);
                }

              },
              () => {
                console.log("E");
                file.status = 'error';
                fileList.splice(index, 1, file);
                this.fileList = [...fileList];
                let num = fileList.filter(x => x.status !== 'uploading').length;

                // this.toastr.error(`Nie powiodło się wgrywanie obrazu ${num} z ${fileList.length}`, file.name);
                if (num == fileList.length) {
                  setTimeout(() => {
                    this.toastr.success('Opublikowano post!');
                    this.router.navigate(['/news']);
                    this.uploading = false;
                  }, 500);
                }
              })
            // .pipe(
            //   catchError( (err, cought) => {
            //     this.fileList[index].status = 'error';
            //     return cought;
            //   }),
            //   map( (response:any) => {this.fileList[index].status = 'success';})
            // ).then();
        })
      })
    ).subscribe( (_) => {
      // this.toastr.success('Opublikowano post!');
      // this.router.navigate(['/news']);
    });
  }

  save(): void {
    if (this.post.startDateActive) {
      this.form.get('startDate').enable();
    } else {
      this.form.get('startDate').disable();
    }
    if (this.post.endDateActive) {
      this.form.get('endDate').enable();
    } else {
      this.form.get('endDate').disable();
    }
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });

    if (this.form.valid) {
      if(this.form.value.sendSms) {
        this.httpClient.post<{count: number}>(`<backendhost>/v1/posts/0/count-sms`, this.form.value).subscribe( (result: {count: number}) => {
          const modalRef: NgbModalRef = this.modalService.open(SmsConfirmModalComponent);
          modalRef.componentInstance.count = result.count;
          modalRef.result
          .then(() => {
            this.submit();
          })
          .catch(() => {
            this.form.patchValue({sendSms: false});
          })
        });

      } else {
        this.submit();
      }

    } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }

  // search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => text$.pipe(
  //     debounceTime(300),
  //     distinctUntilChanged(),
  //     switchMap((term: string) => this.appService.searchUser(term).pipe())
  // )
  search: OperatorFunction<string, readonly Group[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickGroup$.pipe(filter(() => !this.instanceGroup.isPopupOpen()));
    const inputFocus$ = this.focusGroup$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      takeUntil(this.unsubscribe$),
      map(term => (term === '' ? this.groups : this.groups.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))),
      map( (arr:Group[]) => arr.filter(item => this.typeaheadList.map(x => x.id).indexOf(item.id) === -1)),
      map( (arr:Group[]) => arr.slice(0, 10))
    );
  }

  get groupCtrl(): FormArray {
    return this.form.get('groups') as FormArray;
  }

  onTypeaheadSelect(selectedItem: NgbTypeaheadSelectItemEvent) {
    let group: Group = selectedItem.item;
    if (this.typeaheadList.map(x => x.id).indexOf(selectedItem.item.id) !== -1)return;
    this.typeaheadList.push(group);
    this.groupCtrl.push(new FormControl(group.id));
    this.updateSelectedUsers();
  }

  removeGroup(item: Group) {
    let idx = this.typeaheadList.map(it => it.id).indexOf(item.id);
    this.typeaheadList.splice(idx, 1);
    this.groupCtrl.removeAt(idx);
    this.updateSelectedUsers();
  }

  groupIdentity(index, item: Group) {
    return item.id;
  }



  DateTimeValidator = (fc: FormControl) => {
    const date = new Date(fc.value);
    const isValid = !isNaN(date.valueOf());
    return isValid ? null : {dateNotValid: true};
  };
  beforeUpload = (file: NzUploadFile): boolean => {
    getBase64(file as any).then( (preview: string) => {
      file.url = preview;
      file.status = 'done';
      // file.percent = 50;
      this.fileList = this.fileList.concat(file);
    })
    return false;
  };

  handlePreview = async (file: NzUploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  };

  beforeUploadBackground = (file: NzUploadFile): boolean => {
    getBase64(file as any).then( (preview: string) => {
      this.backgroundFile  = file;
      this.post = {...this.post, ...{background: preview}};
    })
    return false;
  };

  searchUsers: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instanceUser.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(text$, debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term =>
        this.appService.searchUser(term)
      ),
    );
  }

  get usersCtrl(): FormArray {
    return this.form.get('users') as FormArray;
  }

  onUsersTypeaheadSelect(selectedItem: NgbTypeaheadSelectItemEvent) {
    let user: User = selectedItem.item;
    this.typeaheadUsersList.push(user);
    this.usersCtrl.push(new FormControl(user.id));
    this.updateSelectedUsers();
  }

  removeUser(item: User) {
    let idx = this.typeaheadUsersList.map(it => it.id).indexOf(item.id);
    this.typeaheadUsersList.splice(idx, 1);
    this.usersCtrl.removeAt(idx);
    this.updateSelectedUsers();
  }

  audioFile: NzUploadFile[] = [];
  beforeUploadAudio = (file: NzUploadFile): boolean => {
    console.log(file);
    this.audioFile = [file];
    getBase64(file as any).then(hash => {
      this.audioPath = this.sanitized.bypassSecurityTrustUrl(hash as string)
    })
    return false;
  };

  audioPath: any;

  clearAudio() {
    this.post.audio = null;
    this.audioFile = [];
    this.audioPath = null;
  }

  selectedUsers: { list: User[], count: number, page: number, loading: boolean } = {list: [], count: 0, page: 1, loading: false};
  updateSelectedUsers = (): void => {
    this.selectedUsers.loading = true;
    const payload = {...this.post, ...this.form.value};
    this.httpClient.post<{ list: User[], count: number }>('<backendhost>/v1/posts/0/count', payload, {params: {
      page: this.selectedUsers.page
    }})
      .subscribe((result) => {
        this.selectedUsers = {...this.selectedUsers, ...result, loading: false};
      });
  }
  identify(index, entity: any){
    return entity.id;
  }

  pageChange($event) {
    this.updateSelectedUsers();
  }
}

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

import { Pagination, PaginationDTO } from "@/model/pagination.model";
import { Quiz, QuizQuestion } from "@/model/quiz.model";
import { StringifyHttpErrorResponse } from "@/utils/http.error.util";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, State, Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { catchError, map, switchMap } from "rxjs/operators";
import * as fromActions from "./quiz.actions";
import * as fromReducers from "./quiz.reducers";

@Injectable()
export class QuizEffects {
  state: fromReducers.State;

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private store$: Store<fromReducers.State>,
  ) {
    this.store$.pipe(select(fromReducers.selectState)).subscribe((state: fromReducers.State) => {
      this.state = state;
    })
  }

  onError (err, caught): any {
    this.toastr.error(StringifyHttpErrorResponse(err));
    return caught;
  };

  paginatorChange$ = createEffect(() => this.actions$.pipe(
    ofType(...[
      fromActions.setPage,
      fromActions.setSearch,
      fromActions.setOrder,
      fromActions.setDirection
    ]),
    map( () => fromActions.fetch())
  ));

  fetch$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetch),
    switchMap(() => {
      let params = new HttpParams();
      params = params.set('skip', this.state.limit * (this.state.page - 1));
      params = params.set('limit', this.state.limit);
      params = params.set('order', this.state.order);
      params = params.set('direction', this.state.asc ? 'asc' : 'desc');
      if(this.state.search)
        params = params.set('search', this.state.search);
      return this.httpClient.get<PaginationDTO<Quiz>>(`<backendhost>/v1/quiz`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: PaginationDTO<Quiz>) => [fromActions.setManyQuiz(response)])
  ));

  fetchOne$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchOne),
    switchMap((action) => {
      return this.httpClient.get<Quiz>(`<backendhost>/v1/quiz/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((quiz: Quiz) => [fromActions.addQuiz({quiz})])
  ));

  createQuiz$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createQuiz),
    switchMap((action) => {
      return this.httpClient.post<Quiz>(`<backendhost>/v1/quiz`, action.quiz, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((quiz: Quiz) => [fromActions.addQuiz({quiz})])
  ));

  updateQuiz$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.updateQuiz),
    switchMap((action) => {
      return this.httpClient.put<Quiz>(`<backendhost>/v1/quiz/${action.quiz.id}`, action.quiz, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((quiz: Quiz) => [fromActions.setQuiz({quiz})])
  ));

  deleteQuiz$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deleteQuiz),
    switchMap((action) => {
      return this.httpClient.delete<any>(`<backendhost>/v1/quiz/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map(() => action.id));
    }),
    catchError(this.onError.bind(this)),
    switchMap((id: number) => [fromActions.removeQuiz({id})])
  ));

  createQuizQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createQuizQuestion),
    switchMap((action) => {
      return this.httpClient.post<QuizQuestion>(`<backendhost>/v1/quiz/${action.quiz.id}/create-question`, action.question, {
        observe: 'body',
        responseType: 'json'
      }).pipe(
        map(question => {
          return {
            quiz: action.quiz,
            question: question
          }
        })
      );
    }),
    catchError(this.onError.bind(this)),
    switchMap((result) => [fromActions.addQuizQuestion(result)])
  ));

  updateQuizQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.updateQuizQuestion),
    switchMap((action) => {
      return this.httpClient.post<QuizQuestion>(`<backendhost>/v1/quiz/${action.quiz.id}/create-question`, action.question, {
        observe: 'body',
        responseType: 'json'
      }).pipe(
        map(question => {
          return {
            quiz: action.quiz,
            question: question
          }
        })
      );
    }),
    catchError(this.onError.bind(this)),
    switchMap((result) => [fromActions.setQuizQuestion(result)])
  ));

  deleteQuizQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deleteQuizQuestion),
    switchMap((action) => {
      return this.httpClient.get<QuizQuestion>(`<backendhost>/v1/quiz/${action.question.id}/delete-question`, {
        observe: 'body',
        responseType: 'json'
      }).pipe(
        map(question => {
          return {
            quiz: action.quiz,
            question: action.question
          }
        })
      );
    }),
    catchError(this.onError.bind(this)),
    switchMap((result) => [fromActions.removeQuizQuestion(result)])
  ));
}

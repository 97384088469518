<section class="content">
  <div class="container">
    <app-package-tab></app-package-tab>
    <div class="card" style="border-top-left-radius: 0;border-top-right-radius: 0;">
      <div class="card-header d-flex align-items-center">
        <h3 class="card-title m-0 flex-grow-1 text-bold"><i class="fas fa-chart-area fa-fw"></i> Raporty</h3>
        <div class="card-tools flex-grow-1">
          <!-- <div class="input-group">
            <input type="text" name="table_search" class="form-control float-right" placeholder="Szukaj" #searchInput>
            <button class="btn bg-transparent" style="position:absolute; right: 0; transform: translate(-100%,0);" (click)="clearSearch()">
              <i class="fa fa-times"></i>
            </button>
            <span class="input-group-append">
              <div class="input-group-text bg-transparent"><i class="fas fa-search"></i></div>
            </span>
          </div> -->
        </div>
      </div>
      <form [formGroup]="form" (ngSubmit)="report()">
      <div class="card-footer">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>Raport z </label>
              <div class="form-check">
                <input class="form-check-input" type="radio" formControlName="type" value="invited" id="typeInvited">
                <label class="form-check-label" for="typeInvited"> Zaproszonych</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" formControlName="type" value="started" id="typeStarted">
                <label class="form-check-label" for="typeStarted"> Rozpoczętych</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" formControlName="type" value="uncompleted" id="typeUncompleted">
                <label class="form-check-label" for="typeUncompleted"> Nieukończonych</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" formControlName="type" value="completed" id="typeCompleted">
                <label class="form-check-label" for="typeCompleted"> Ukończonych</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" formControlName="type" value="passed" id="typePassed">
                <label class="form-check-label" for="typePassed"> Zaliczonych</label>
              </div>
            </div>
          </div>
          <div class="col">


            <div class="form-group">
              <label>Data zaproszenia</label>
              <div class="input-group">
                <div class="input-group-text">
                  <input type="checkbox" formControlName="invitationRangeOn">
                </div>
                <nz-range-picker formControlName="invitationRange" [nzDisabled]="!this.form.controls.invitationRangeOn.value"></nz-range-picker>
              </div>
              <div class="text-danger" *ngIf="form.controls.invitationRange.errors?.dateNotValid">
                Data jest nieprawidłowa
              </div>
            </div>

            <div class="form-group">
              <label>Data ukończenia</label>
              <div class="input-group">
                <div class="input-group-text">
                  <input type="checkbox" formControlName="completedRangeOn">
                </div>
                <nz-range-picker formControlName="completedRange" [nzDisabled]="!this.form.controls.completedRangeOn.value"></nz-range-picker>
              </div>
              <div class="text-danger" *ngIf="form.controls.completedRange.errors?.dateNotValid">
                Data jest nieprawidłowa
              </div>
            </div>


          </div>
          <div class="col">

            <div class="form-group" *ngIf="!package.open">
              <label>Termin realizacji szkoleni</label>
              <div class="input-group">
                <div class="input-group-text">
                  <input type="checkbox" formControlName="endDateRangeOn">
                </div>
                <nz-range-picker formControlName="endDateRange" [nzDisabled]="!this.form.controls.endDateRangeOn.value"></nz-range-picker>
              </div>
              <div class="text-danger" *ngIf="form.controls.endDateRange.errors?.dateNotValid">
                Data jest nieprawidłowa
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary float-left" type="submit">
          <i class="fas fa-check fa-fw"></i>
          Wygeneruj raport
        </button>
        <a class="btn btn-secondary float-right" [class.disabled]="result.length == 0" (click)="generateCSV()">
          <i class="fas fa-check fa-fw"></i>
          Export do CSV
        </a>
      </div>
      </form>
      <div class="table-responsive position-relative">
        <table class="table align-middle table-hover text-sm">
          <thead>
            <tr>
              <th style="width:1rem">#</th>
              <th>Imię</th>
              <th>Nazwisko</th>
              <th>Email</th>
              <th>Data zaproszenia</th>
              <th>Data rozpoczęcia</th>
              <th>Data ukończenia</th>
              <th>Termin realizacji szkoleni</th>
              <th>Ukończone szkolenia</th>
              <th>Liczba szkoleń</th>
              <th>Zaliczone</th>
              <th>Punktów</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of pagination; let idx = index">
              <th class="align-middle">{{idx + 1 + (page-1)*pageSize}}</th>
              <td class="align-middle">{{item.firstname}}</td>
              <td class="align-middle">{{item.lastname}}</td>
              <td class="align-middle">{{item.email}}</td>
              <td class="align-middle">{{item.invitedAt }}</td>
              <td class="align-middle">{{item.startedAt }}</td>
              <td class="align-middle">{{item.completedAt }}</td>
              <td class="align-middle">{{item.endDateAt }}</td>
              <td class="align-middle">{{item.trainingsCompleted }}</td>
              <td class="align-middle">{{item.trainingsTotal }}</td>
              <td class="align-middle">{{item.passed }}</td>
              <td class="align-middle">{{item.score }}</td>
            </tr>
          </tbody>
        </table>
        <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="result.length == 0"></nz-empty>
        <div class="overlay" *ngIf="loading" style="    position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;">
          <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
            <i class="fas fa-3x fa-spinner fa-spin"></i>
            <p>Ładowanie...</p>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="result.length" class="float-left">
          <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
</section>





import { Role } from "@/model/role.model";
import { createAction, props } from "@ngrx/store";

export const fetch = createAction("roles fetch");
export const save = createAction( "roles save", props<{ role: Role }>());
export const create = createAction( "roles create", props<{ role: Role }>());
export const deleteRole = createAction( "roles deleteRole", props<{ role: Role }>());


export const set = createAction("roles set", props<{ roles: Role[] }>());
export const update = createAction( "roles update", props<{ role: Role }>());
export const add = createAction( "roles add", props<{ role: Role }>());
export const remove = createAction( "roles remove", props<{ role: Role }>());

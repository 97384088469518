import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as fromActions from '@/store/order/order.actions';
import * as fromReducers from '@/store/order/order.reducer';
import { Store } from '@ngrx/store';
import { OrderFormInput } from '@/model/order.form.model';

@Component({
  selector: 'app-order-form-input-modal',
  templateUrl: './order-form-input-modal.component.html',
  styleUrls: ['./order-form-input-modal.component.scss']
})
export class OrderFormInputModalComponent implements OnInit {
  form: FormGroup;
  private _orderFormInput: OrderFormInput;
  static multiple = ['checkbox', 'radio', 'select'];
  public static types = {
    'text': 'Tekstowe',
    'number': 'Liczbowe',
    'checkbox': 'Wielokrotnego wyboru',
    'radio': 'Jednokrotnego wyboru',
    'select': 'Lista rozwijana',
  }

  constructor(
    public modal: NgbActiveModal,
    private store: Store<fromReducers.State>,
  ) {
    this.form = new FormGroup({
      label: new FormControl(null, Validators.required),
      type: new FormControl('text', Validators.required),
      options: new FormArray([], null),
    });
    this.addOption();
    // this.optionsCtrl.valueChanges.subscribe((values:string[]) => {
    //   if(values[values.length-1] != null) {
    //     this.addOption();
    //   }
    // });
  }


  ngOnInit(): void {
  }

  get orderFormInput(): OrderFormInput {
    return this._orderFormInput;
  }
  set orderFormInput(value: OrderFormInput) {
    this._orderFormInput = value;
    value.options.forEach( option => {
      if(value.options.length != this.optionsCtrl.controls.length)
      this.optionsCtrl.push(new FormControl(option))
    })
    this.form.patchValue(value);
  }

  get optionsCtrl(): FormArray {
    return this.form.get('options') as FormArray;
  }

  addOption() {
    this.optionsCtrl.push(new FormControl());
  }
  delOption(idx: number) {
    this.optionsCtrl.controls.splice(idx,1);
    this.optionsCtrl.updateValueAndValidity();
    if (this.optionsCtrl.controls.length == 0) {
      this.addOption();
    }
  }
  public static isMultiple(type: string): boolean {
    return this.multiple.includes(type);
  }
  public checkMultiple(type: string): boolean {
    return OrderFormInputModalComponent.isMultiple(type);
  }

  save(): void {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
     if (this.form.valid) {
      this.store.dispatch(fromActions.setWorkspaceOrderFormInput({input: {...this.orderFormInput, ...this.form.value}}));
      this.modal.close();
    } else {
      // this.toastr.warning("Trwa zapisywanie");
    }
  }

}

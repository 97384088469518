import { ReservationAsset, ReservationCategory } from "@/model/reservation.model";
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { environment } from "environments/environment";
import * as fromActions from './reservations.actions';

export interface State {
  public: {
    list: EntityState<ReservationCategory>;
    total: number;
    loading: boolean;
  },
  reservations: {
    loading: boolean,
    reservations: any,
  },
  admin: {
    loading: boolean;
    saving: boolean;
    list: EntityState<ReservationCategory>;
    total: number;
    page: number;
    order: string;
    readonly limit: number;
    asc: boolean;
    search: string;
  }
}

const categoryAdapter = createEntityAdapter<ReservationCategory>();
const assetAdapter = createEntityAdapter<ReservationAsset>();

export const initialState: State = {
  public: {
    list: categoryAdapter.getInitialState(),
    total: 0,
    loading: false
  },
  reservations: {
    loading: false,
    reservations: null,
  },
  admin: {
    loading: false,
    saving: false,
    list: categoryAdapter.getInitialState(),
    total: 0,
    page: 1,
    order: 'id',
    limit:  environment.pageSize,
    asc: true,
    search: null
  }
}



export const reducer = createReducer(
  initialState,
  on(fromActions.fetchOneReservationCategory, (state,action) => {
    let change = {loading: true};
    return {...state, ...{admin : {...state.admin, ... change}}, ...{public : {...state.public, ... change}}};
  }),
  on(fromActions.fetchAdminReservationCategory, (state,action) => {
    let change = {loading: true};
    return {...state, ...{admin : {...state.admin, ... change}}};
  }),
  on(fromActions.setAdminPage, (state, action) => {
    let change = {page: action.page};
    return {...state, ...{admin : {...state.admin, ... change}}};
  }),
  on(fromActions.setAdminSearch, (state, action) => {
    let change = { search: action.search };
    return {...state, ...{admin : {...state.admin, ... change}}};
  }),
  on(fromActions.setAdminOrder, (state, action) => {
    let change = { order: action.order };
    return {...state, ...{admin : {...state.admin, ... change}}};
  }),
  on(fromActions.setAdminDirection, (state, action) => {
    let change = { direction: action.direction };
    return {...state, ...{admin : {...state.admin, ... change}}};
  }),
  on(fromActions.reset, (state, action) => {
    return initialState
  }),
  on(fromActions.replaceAdminReservationCategory, (state,action) => {
    let change = {
      loading: false,
      total: action.total,
      list: categoryAdapter.addMany(action.data, categoryAdapter.getInitialState())
    };
    return {...state, ...{admin : {...state.admin, ... change}}};
  }),
  on(fromActions.addAdminReservationCategory, (state, action) => {
    let change = { list: categoryAdapter.addOne(action.reservationCategory, state.admin.list), loading: false, saving: false };
    return {...state, ...{admin : {...state.admin, ... change}}};
  }),
  on(fromActions.updateAdminReservationCategory, (state, action) => {
    let change = { list: categoryAdapter.updateOne(action, state.admin.list), loading: false, saving: false };
    return {...state, ...{admin : {...state.admin, ... change}}};
  }),
  on(fromActions.updateAdminReservationCategory, (state, action) => {
    let change = { list: categoryAdapter.updateOne(action, state.admin.list), loading: false, saving: false };
    return {...state, ...{admin : {...state.admin, ... change}}};
  }),
  on(fromActions.removeAdminReservationCategory, (state, action) => {
    let change = { list: categoryAdapter.removeOne(action.id, state.admin.list), loading: false, saving: false };
    return {...state, ...{admin : {...state.admin, ... change}}};
  }),

  on(fromActions.addAdminReservationAsset, (state, action) => {
    console.log(action)
    let change = {
      list: categoryAdapter.updateOne({
        id: action.reservationAsset.category,
        changes: {
          assets: assetAdapter.addOne(action.reservationAsset, state.admin.list.entities[action.reservationAsset.category].assets)
        }
      }, state.admin.list), loading: false, saving: false
    };
    return {...state, ...{admin : {...state.admin, ... change}}};
  }),
  on(fromActions.updateAdminReservationAsset, (state, action) => {
    console.log(action)
    let change = {
      list: categoryAdapter.updateOne({
        id: action.reservationAsset.category,
        changes: {
          assets: assetAdapter.updateOne({
              id: action.reservationAsset.id,
              changes: action.reservationAsset
            },
            state.admin.list.entities[action.reservationAsset.category].assets
          )
        }
      }, state.admin.list), loading: false, saving: false
    };
    return {...state, ...{admin : {...state.admin, ... change}}};
  }),
  on(fromActions.removeAdminReservationAsset, (state, action) => {
    console.log(action)
    let change = {
      list: categoryAdapter.updateOne({
        id: action.reservationAsset.category,
        changes: {
          assets: assetAdapter.removeOne(action.reservationAsset.id, state.admin.list.entities[action.reservationAsset.category].assets)
        }
      }, state.admin.list), loading: false, saving: false
    };
    return {...state, ...{admin : {...state.admin, ... change}}};
  }),


  // on(fromActions.fetchPublicIdeas, (state, action) => {
  //   let change = {loading: true};
  //   return {...state, ...{public : {...state.public, ... change}}};
  // }),
  // on(fromActions.removeIdea, (state, action) => {
  //   let change = { list: adapter.removeOne(action.id, state.public.list) };
  //   return {...state, ...{public : {...state.public, ... change}}};
  // }),
  // on(fromActions.updateIdea, (state, action) => {
  //   let change = { list: adapter.updateOne(action, state.public.list) };
  //   return {...state, ...{public : {...state.public, ... change}}};
  // }),
  // on(fromActions.addPublicIdeas, (state, action) => {
  //   return {...state,
  //     ...{
  //       public : {
  //         loading: false,
  //         total: action.total,
  //         list: adapter.addMany(action.data, state.public.list)
  //       }
  //     }
  //   };
  // })
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = categoryAdapter.getSelectors();



export const selectReservationState = createFeatureSelector<State>('reservations');

export const selectAllAdminReservationCategories = createSelector(selectReservationState, (state) => selectAll(state.admin.list));
export const selectOneAdminReservationCategory = createSelector(selectReservationState, (state: State, id) => state.admin.list.entities[id]);
export const selectAdminCategoryAssets = createSelector(selectReservationState, (state: State, id) => state.admin.list.entities[id] && state.admin.list.entities[id]?.assets ? assetAdapter.getSelectors().selectAll(state.admin.list.entities[id].assets) : []);


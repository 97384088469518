import { Slide } from "@/model/multimedia.model";
import { Invitation, TrainingResult } from "@/model/package.model";
import { PaginationDTO } from "@/model/pagination.model";
import { Training } from "@/model/scorm.model";
import { createAction, props } from "@ngrx/store";

export const clear = createAction("myTrainings clear");
export const fetch = createAction("myTrainings fetch");
export const fetchOne = createAction("myTrainings fetchOne", props<{id: number}>());
export const setList = createAction("myTrainings setList", props<PaginationDTO<Invitation>>());
export const setOne = createAction("myTrainings setOne", props<{invitation: Invitation}>());
export const update = createAction("myTrainings update", props<{invitation: Invitation}>());
export const reset = createAction("myTrainings reset", props<{invitation: Invitation}>());
export const resetTraining = createAction("myTrainings resetTraining", props<{training: Training, invitation: Invitation}>());
export const resetHard = createAction("myTrainings resetHard", props<{invitation: Invitation}>());


export const setPage = createAction("myTrainings setPage", props<{ page: number }>());
export const setOrder = createAction("myTrainings setOrder", props<{ order: string }>());
export const setDirection = createAction("myTrainings setDirection", props<{ asc: boolean }>());
export const setSearch = createAction("myTrainings setSearch", props<{ search: string }>());
export const setMode = createAction("myTrainings setMode", props<{ mode: string }>());

export const start = createAction("myTrainings start", props<{id: number}>());
export const startOpen = createAction("myTrainings startOpen", props<{id: number}>());
export const complete = createAction("myTrainings complete", props<{id: number}>());
export const createResult = createAction("myTrainings createResult", props<{invitation: Invitation, training?: Training, slide?: Slide}>());
export const addResult = createAction("myTrainings addResult", props<{invitation: Invitation, result: TrainingResult}>());
export const updateResult = createAction("myTrainings updateResult", props<{invitation: Invitation, result: TrainingResult}>());
export const setResult = createAction("myTrainings setResult", props<{invitation: Invitation, result: TrainingResult}>());
export const deleteResult = createAction("myTrainings deleteResult", props<{invitation: Invitation, result: TrainingResult}>());

import * as fromActions from "./offer.actions";
import { Offer } from "@/model/offer.model";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";

export interface State {
  unseenCount: number;
  loading: boolean;
  saving: boolean;
  total: number;
  offers: EntityState<Offer>,
}

export const adapter: EntityAdapter<Offer> = createEntityAdapter<Offer>({
  sortComparer: (a: Offer, b: Offer) => a.id < b.id ? 1 : 0
});


export const initialState: State = {
  loading: false,
  saving: false,
  total: 0,
  unseenCount: 0,
  offers: adapter.getInitialState()
}

export const reducer = createReducer(
  initialState,
  on(fromActions.fetch, (state, action) => { return {...state, ...{loading: true}}}),
  on(fromActions.createOffer, (state, action) => { return {...state, ...{saving: true}}}),
  on(fromActions.addOne, (state, action) => {
    return {...state, ...{saving: false}, ...{offers: adapter.addOne(action.offer, state.offers)}
  }}),
  on(fromActions.addMany, (state, action) => {
    return {...state, ...{loading: false, total: action.total}, ...{offers: adapter.addMany(action.offers, state.offers)}
  }}),
  on(fromActions.updateOne, (state, action) => {
    return {...state, ...{saving: false}, ...{offers: adapter.updateOne({id: action.offer.id, changes: action.offer}, state.offers)}
  }}),
  on(fromActions.removeOne, (state, action) => {
    return {...state, ...{saving: false}, ...{offers: adapter.removeOne(action.offer.id, state.offers)}
  }}),
  on(fromActions.likeOfferAction, (state, action) => {
    return {...state, ...{saving: false}, ...{offers: adapter.updateOne({id: action.offer.id, changes: {likeSaving: true}}, state.offers)}
  }}),
  on(fromActions.unlikeOfferAction, (state, action) => {
    return {...state, ...{saving: false}, ...{offers: adapter.updateOne({id: action.offer.id, changes: {likeSaving: true}}, state.offers)}
  }}),

);

export const selectOfferState = createFeatureSelector<State>('offers');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectAllOffers = createSelector(selectOfferState, (state) => selectAll(state.offers) )
export const selectLoadding = createSelector(selectOfferState, (state) => state.loading )
export const selectCount = createSelector(selectOfferState, (state) => state.total )
export const selectSaving = createSelector(selectOfferState, (state) => state.saving )
export const selectOfferById = createSelector(selectOfferState, (state, id: number) => {
  return state.offers?.entities ? state.offers.entities[id] : null
} )

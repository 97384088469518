import { KbFile } from '@/model/file.model';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-file-item',
  templateUrl: './file-item.component.html',
  styleUrls: ['./file-item.component.scss']
})
export class FileItemComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  @Input() item: KbFile;

  constructor(
    protected httpClient: HttpClient,

  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  link() {
    return `${environment.apiUrl}/${this.item.path}`;
  }

  isImage() {
    return this.item.mimeType.indexOf('image/') === 0;
  }

  download() {
    let file = this.item;
    this.httpClient
      .get(`${environment.apiUrl}/v1/kb/file/${file.id}/download`, {responseType: 'blob'})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          let blob:any = new Blob([response], { type: file.mimeType });
          // saveAs(blob, file.name);

          const url = window.URL.createObjectURL(blob);
          var anchor = document.createElement('a');
        	anchor.href = url;
        	anchor.download = file.name;
        	document.body.appendChild(anchor);
        	anchor.click();
        },
        error => console.log('Error downloading the file'),
        () => console.info('File downloaded successfully')
      );
  }
}

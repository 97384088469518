<app-header (toggleMenuSidebar)="toggleMenuSidebar()" style="position: relative;z-index: 50001;"></app-header>
<app-menu-sidebar class="main-sidebar main-sidebar-custom sidebar-dark-primary elevation-4"></app-menu-sidebar>
<div class="content-wrapper" style="padding-bottom: 1px;">
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
            <h1 class="m-0 text-dark">{{title | async}}</h1>
        </div>
        <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
                <!-- <li class="breadcrumb-item"><a [routerLink]="['/']"><i class="fas fa-home"></i> Home</a></li> -->
                <li *ngFor="let breadcrumb of (breadcrumbs | async)"  class="breadcrumb-item">
                  <a [routerLink]="[breadcrumb.url]">{{ breadcrumb.label }}</a>
                </li>
                <li class="breadcrumb-item">{{title | async}}</li>
            </ol>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
  <router-outlet></router-outlet>
  </div>
</div>
<app-footer class="main-footer"></app-footer>
<div id="sidebar-overlay" (click)="toggleMenuSidebar()">
  TEST
</div>



<div class="content">
  <div class="container pt-3 pb-3">
    <div class="row">
      <div class="col-12">
        <form [formGroup]="form" (ngSubmit)="save()">
        <div class="card card-primary">
          <div class="overlay" *ngIf="(saving | async)">
            <div style="position: sticky; top:50%; left:50%; align-self: baseline;text-align: center;  padding: 25px 0">
              <i class="fas fa-3x fa-spinner fa-spin"></i>
              Zapisywanie...
            </div>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label for="title">Tytuł pomysłu</label>
              <input formControlName="title" [appIsValid]="form.controls.title" type="text" class="form-control" placeholder="Tytuł" />
              <div class="invalid-feedback" *ngIf="form.controls.title.errors?.required">
                Pole jest wymagane
              </div>
            </div>
            <div class="form-group">
              <label for="content">Opis pomysłu</label>
              <app-editor formControlName="description"></app-editor>
              <div class="text-danger" *ngIf="form.controls.description.touched && form.controls.description.errors?.required">
                Pole jest wymagane
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button class="btn btn-success float-right" type="submit" (mouseDown)="save()" (touchEnd)="save()">
              Zapisz
              <i class="fas fa-angle-right fa-fw"></i>
            </button>
          </div>
        </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- <div class="overlay">
  <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
    <i class="fas fa-3x fa-spinner fa-spin"></i>
    <p>Zapisywanie...</p>
  </div>
</div> -->
<div class="modal-header d-flex">
  <img class="img-circle mr-2" [src]="usersPrize.prize | groupAvatar:55">
  <h3 class="align-self-center">{{usersPrize.prize.title}}</h3>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div [innerHtml]="usersPrize.prize.description | safeHtml"></div>
</div>
<div class="card-footer justify-content-start">
  <h3 class="card-title">Komentarz</h3>
</div>
<div class="modal-body">
  <div [innerHtml]="usersPrize.comment | safeHtml"></div>
</div>
<div class="card-footer">
  <h3 class="card-title">
    <img class="img-circle" [src]="usersPrize.createdBy | userAvatar:25"  [appUserDisactivatable]="usersPrize.createdBy">
    <span class="text-decoration-line-through" *ngIf="!usersPrize?.createdBy">Użytkownik usunięty</span>
    <span  [appUserDisactivatable]="usersPrize.createdBy">{{ usersPrize.createdBy?.name }}</span>
  </h3>
  <div class="card-tools text-muted text-md" style="float: right">
    {{usersPrize.createdAt | amLocale:'pl' | amTimeAgo }}
  </div>
</div>

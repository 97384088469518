import { PaginationDTO } from "@/model/pagination.model";
import { Quiz, QuizQuestion } from "@/model/quiz.model";
import { createAction, props } from "@ngrx/store";

export const clear = createAction("quiz clear");
export const fetch = createAction("quiz fetch");
export const fetchOne = createAction("quiz fetchOne", props<{id: number}>());

export const setPage = createAction("quiz setPage", props<{ page: number }>());
export const setOrder = createAction("quiz setOrder", props<{ order: string }>());
export const setDirection = createAction("quiz setDirection", props<{ asc: boolean }>());
export const setSearch = createAction("quiz setSearch", props<{ search: string }>());

export const createQuiz = createAction("quiz createQuiz", props<{quiz: Quiz}>());
export const updateQuiz = createAction("quiz updateQuiz", props<{quiz: Quiz}>());
export const deleteQuiz = createAction("quiz deleteQuiz", props<{id: number}>());

export const setManyQuiz = createAction("quiz setManyQuiz", props<PaginationDTO<Quiz>>());
export const addManyQuiz = createAction("quiz addManyQuiz", props<PaginationDTO<Quiz>>());
export const addQuiz = createAction("quiz addQuiz", props<{quiz: Quiz}>());
export const setQuiz = createAction("quiz setQuiz", props<{quiz: Quiz}>());
export const removeQuiz = createAction("quiz removeQuiz", props<{id: number}>());

export const createQuizQuestion = createAction("quiz createQuizQuestion", props<{quiz: Quiz, question: QuizQuestion}>());
export const updateQuizQuestion = createAction("quiz updateQuizQuestion", props<{quiz: Quiz, question: QuizQuestion}>());
export const deleteQuizQuestion = createAction("quiz deleteQuizQuestion", props<{quiz: Quiz, question: QuizQuestion}>());

export const addQuizQuestion = createAction("quiz addQuizQuestion", props<{quiz: Quiz, question: QuizQuestion}>());
export const setQuizQuestion = createAction("quiz setQuizQuestion", props<{quiz: Quiz, question: QuizQuestion}>());
export const removeQuizQuestion = createAction("quiz removeQuizQuestion", props<{quiz: Quiz, question: QuizQuestion}>());

import { Outpost } from "@/model/outpost.model";
import { PaginationDTO } from "@/model/pagination.model";
import { createAction, props } from "@ngrx/store";

export const clear = createAction("outpost clear");
export const fetch = createAction("outpost fetch");
export const fetchOneOutpost = createAction("outpost fetchOneOutpost", props<{id: number}>());

export const setPage = createAction("outpost setPage", props<{ page: number }>());
export const setOrder = createAction("outpost setOrder", props<{ order: string }>());
export const setDirection = createAction("outpost setDirection", props<{ asc: boolean }>());
export const setSearch = createAction("outpost setSearch", props<{ search: string }>());

export const createOutpost = createAction("outpost createOutpost", props<{outpost: Outpost, image?:any, background?: any}>());
export const updateOutpost = createAction("outpost updateOutpost", props<{outpost: Outpost, image?:any, background?: any}>());
export const deleteOutpost = createAction("outpost deleteOutpost", props<{id: number}>());

export const setManyOutpost = createAction("outpost setManyOutpost", props<PaginationDTO<Outpost>>());
export const addManyOutpost = createAction("outpost addManyOutpost", props<PaginationDTO<Outpost>>());
export const addOutpost = createAction("outpost addOutpost", props<{outpost: Outpost}>());
export const setOutpost = createAction("outpost setOutpost", props<{outpost: Outpost}>());
export const removeOutpost = createAction("outpost removeOutpost", props<{id: number}>());

import { Component, OnInit } from '@angular/core';

import * as fromRedmineIssueActions from '@/store/redmine/redmine.issue.actions';
import * as fromRedmineIssueReducers from '@/store/redmine/redmine.issue.reducers';
import { ActionsSubject, Store } from '@ngrx/store';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { RedmineIssue } from '@/model/redmine.model';
import { ofType } from '@ngrx/effects';
import { filter, take, takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxMarkdownItService } from 'ngx-markdown-it';
import { AuthService } from '@/auth/auth.service';
import { User } from '@/model/user.model';
import { HttpClient } from '@angular/common/http';

import * as fromNotificationActions from '@/store/notification/notification.actions';
import * as fromNotificationReducers from '@/store/notification/notification.reducers';
import { AppNotification } from '@/model/notification.model';
import { Socket } from 'ngx-socket-io';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-redmine-issue-detail',
  templateUrl: './redmine-issue-detail.component.html',
  styleUrls: ['./redmine-issue-detail.component.scss']
})
export class RedmineIssueDetailComponent implements OnInit {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  me: User;

  issue: RedmineIssue;
  loading: boolean = true;
  commentForm: FormGroup;
  assignable: User[];
  fileList: NzUploadFile[] = [];

  constructor(
    protected store: Store<fromRedmineIssueReducers.State>,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    private actionsSubject$: ActionsSubject,
    private toastr: ToastrService,
    private markdownItService: NgxMarkdownItService,
    protected authService: AuthService,
    protected httpClient: HttpClient,
    private storeNotification: Store<fromNotificationReducers.State>,
    private socket: Socket,
  ) {
    this.authService.getUser().then(user => {
      this.me = user;
    });
    this.commentForm = new FormGroup({
      notes: new FormControl(null, Validators.required),
    });
    this.httpClient.get('<backendhost>/v1/redmine-issue/0/assignable').subscribe(assignable => {this.assignable = assignable as User[]});
  }

  private redmineIssueId: number;

  ngOnInit(): void {
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd)
    ).subscribe((event) => {
      this.store.dispatch(fromRedmineIssueActions.fetchOne({id: this.redmineIssueId}));
    });
    this.activatedRoute.params.subscribe(params => {
      this.redmineIssueId = params.id;

      this.socket.fromEvent(`notification:user:${this.me.id}`)
        .pipe(
          takeUntil(this.unsubscribe$),
          filter((notification: AppNotification) => notification.url === `/redmine/issue/${this.redmineIssueId}`)
        )
        .subscribe( (notification: AppNotification) => {
          this.store.dispatch(fromRedmineIssueActions.fetchOne({id: this.redmineIssueId}));
        });
      this.store.dispatch(fromRedmineIssueActions.fetchOne({id: this.redmineIssueId}));
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromRedmineIssueActions.setIssue)
      ).subscribe((action: any) => {
        this.issue = action.item;
        this.loading = false;

        let notification = {
          id: this.issue.notification,
          title: null,
          createdAt:null,
          important: false,
          type: 'redmine'
        } as AppNotification;
        this.storeNotification.dispatch(fromNotificationActions.deleteNotification({notification}));
      })
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
  private static markdownImageRegex = /\!(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))!/;
  markdownHtml(markdown) {
    var m;
    do {
        m = RedmineIssueDetailComponent.markdownImageRegex.exec(markdown);
        if (m) {
          markdown = markdown.replace(m[0],`![](${m[1]})`);
        }
    } while (m);
    return this.markdownItService.render(markdown);
  }

  addComment() {
    Object.values(this.commentForm.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    if (this.commentForm.valid) {
      let item = {
        ... {id: this.issue.id},
        ... this.commentForm.value
      }
      this.store.dispatch(fromRedmineIssueActions.updateIssue({
        item,
        attachments: this.fileList as unknown as File[]
      }));
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromRedmineIssueActions.setIssue),
        take(1),
      ).subscribe( (action: any) => {
        this.toastr.success('Dodano komentarz');
        this.commentForm.reset();
        this.fileList = [];
      });
    } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }

  openIssue(status_id) {
    let item = {
      id: this.issue.id,
      status_id: status_id
    }
    this.store.dispatch(fromRedmineIssueActions.updateIssue({item}));
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromRedmineIssueActions.setIssue),
        take(1),
      ).subscribe( (action: any) => {
        this.toastr.success('Otwarto ponownie zgłoszenie');
      });
  }

  closeIssue(status_id) {
    let item = {
      id: this.issue.id,
      status_id: status_id
    }
    this.store.dispatch(fromRedmineIssueActions.updateIssue({item}));
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromRedmineIssueActions.setIssue),
        take(1),
      ).subscribe( (action: any) => {
        this.toastr.success('Zamknieto zgłoszenie');
      });
  }

  assigneTo(id) {
    let item = {
      id: this.issue.id,
      assigned_to_id : id
    }
    this.store.dispatch(fromRedmineIssueActions.updateIssue({item}));
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromRedmineIssueActions.setIssue)
      ).subscribe( (action: any) => {
        this.toastr.success('Przypisano zadanie');
      });
  }

  get saving(): Observable<boolean> {
    return this.store.select(fromRedmineIssueReducers.isSaving);
  }

  commentAttachments(details: any[]) {
    const ids = details.filter(item => item.property === "attachment").map(item => parseInt(item.name));
    if(!ids || !this.issue.attachments.length)
      return [];
    const attachmentIndexes = this.issue.attachments.map(a => a.id);
    return ids
      .map(id => attachmentIndexes.indexOf(id))
      .map(index => this.issue.attachments[index])
  }

  commentHasAttachments(comment: any) {
    return this.commentAttachments(comment.details).length > 0
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };
}

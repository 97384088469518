
<ng-template #rt let-r="result" let-t="term">
  <div class="d-flex flex-md-row">
  <div class="user-panel mr-2">
    <img [src]="r | userAvatar" class="img-circle" [appUserDisactivatable]="r">
  </div>
  <ngb-highlight class="align-self-center" [result]="formatter(r)" [term]="t"></ngb-highlight>
  </div>
</ng-template>

<div class="content">
  <div class="container pt-3 pb-3 direct-chat-contacts-open ">
    <div class="card card-primary card-outline">
      <div class="card-header">
        <h3 class="card-title">
          <i class="fas fa-comments fa-fw fa-lg"></i> Chaty
        </h3>
        <div class="card-tools">
          <div class="input-group">
            <input id="typeahead-prevent-manual-entry"  type="text" class="form-control" placeholder="Znajdz użytkownika"
            style="position: static;"
                          [(ngModel)]="model"
                          [ngbTypeahead]="search"
                          [resultTemplate]="rt"
                          [inputFormatter]="formatterEmpty"
                          [editable]='false'
                          (selectItem)="onUserSelect($event)"/>
            <span class="input-group-append">
              <div class="input-group-text bg-transparent"><i class="fas fa-search"></i></div>
            </span>
          </div>
        </div>
      </div>
      <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(all | async).length == 0"></nz-empty>
      <ul class="contacts-list list-group">
        <li *ngFor="let item of all | async" class="list-group-item list-group-item-action">
          <div class="d-flex flex-md-row">
            <div class="possition-relative" [routerLink]="['/chat',item.id]">
              <img class="img-size-50 img-circle float-left" [src]="item | groupAvatar" [alt]="item.name">
              <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary mr-3 mt-2" *ngIf="item.unseenCount">
                {{item.unseenCount }}
              </span>
            </div>
            <div class="pl-3 pr-3 flex-grow-1" [routerLink]="['/chat',item.id]">
              <strong *ngIf="!item.name && item.type == 'direct'" class="text-decoration-line-through" > Użytkownik usunięty</strong>
              <strong *ngIf="item.name || item.type != 'direct'">{{item.name}}</strong>
              <br/>
              <p>
                <span *ngIf="item.lastMessage.short" [innerHtml]="item.lastMessage.short | safeHtml"></span>
                <span *ngIf="!item.lastMessage.short && item.lastMessage.files?.length">przesyła plik</span>
              </p>
            </div>
            <div class="text-right">
              <small class="contacts-list-date float-right">
                <i class="far fa-clock mr-1"></i> {{item.lastMessage.createdAt | amLocale:'pl' | amCalendar}}
              </small> <br>
              <a
                *ngIf="item.type=='room'"
                checkRolePermition="chat.room.delete" [instance]="item"
                class="btn btn-tool float-right mt-1"
                nz-popconfirm
                nzPopconfirmTitle="Czy na pewno usunąć?"
                nzOkText="Tak"
                nzCancelText="Nie"
                nzPopconfirmPlacement="left"
                ngbTooltip="Usuń"
                (nzOnConfirm)="delete(item)" >
                <i class="fas fa-trash"></i>
              </a>
              <a
                *ngIf="item.type=='room'"
                checkRolePermition="chat.room.edit" [instance]="item"
                type="button"
                class="btn btn-tool float-right mt-1"
                title="Edit"
                data-widget="chat-pane-toggle"
                [routerLink]="['/chat/'+ item.id + '/room/edit']">
                <i class="fas fa-edit"></i>
              </a>
            </div>
          </div>
          <!-- <a [routerLink]="['/chat',item.id]"> -->
            <!-- <div class="mr-3">
              <div class="possition-relative">
                <img class="img-size-50 img-circle float-left" [src]="item | groupAvatar" [alt]="item.name">
                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary mr-3 mt-2" *ngIf="item.unseenCount">
                  {{item.unseenCount }}
                </span>
              </div>
            </div>

            <div class="contacts-list-info pl-4">
              <span class="contacts-list-name text-primary">
                {{item.name}}
                <small class="contacts-list-date float-right">
                  <i class="far fa-clock mr-1"></i> {{item.lastMessage.createdAt | amLocale:'pl' | amCalendar}}
                </small>
              </span>
              <span class="contacts-list-msg" style="color:black">
                  {{item.lastMessage.content }} -->
                  <!-- <a
                    *ngIf="canEdit(item)"
                    class="btn btn-tool float-right mt-1"
                    nz-popconfirm
                    nzPopconfirmTitle="Czy na pewno usunąć?"
                    nzOkText="Tak"
                    nzCancelText="Nie"
                    nzPopconfirmPlacement="left"
                    ngbTooltip="Usuń"
                    (nzOnConfirm)="delete()" >
                    <i class="fas fa-trash"></i>
                  </a>
                  <button
                    *ngIf="canEdit(item)"
                    type="button"
                    class="btn btn-tool float-right mt-1"
                    title="Edit"
                    data-widget="chat-pane-toggle"
                    [routerLink]="['/chat', item.id, 'room/edit']">
                    <i class="fas fa-edit"></i>
                  </button> -->
                <!-- </span>
            </div> -->
          <!-- </a> -->
        </li>
      </ul>
    </div>
  </div>
</div>

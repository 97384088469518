import { createPaginationInitialState, Pagination } from "@/model/pagination.model";
import { Invitation, TrainingInvitePack, TrainingNotification, TrainingPackage } from "@/model/package.model";
import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import * as actions from "./package.actions";

export interface State extends Pagination<TrainingPackage> {
  archive: boolean,
  open: boolean,
}

export const adapter: EntityAdapter<TrainingPackage> = createEntityAdapter<TrainingPackage>();
export const adapterInvitePack: EntityAdapter<TrainingInvitePack> = createEntityAdapter<TrainingInvitePack>();
export const invitationAdapter: EntityAdapter<Invitation> = createEntityAdapter<Invitation>();
export const notificationAdapter: EntityAdapter<TrainingNotification> = createEntityAdapter<TrainingNotification>({
  sortComparer: (a: TrainingNotification, b: TrainingNotification) => {
    if (a.first) return -1;
    if (b.first) return 1;
    return a.daysBefore < b.daysBefore ? 1 : -1
  }
});

export const initialState: State = {
  ...createPaginationInitialState(adapter, false),
  ...{
    archive: false,
    open: false,
  }
};

const updateCurrentInvitePack = (state: State, id: number, pack: TrainingInvitePack) => {
  return {
    ...state,
    ...{
      loading: false,
      list: adapter.updateOne({
        id : id,
        changes: {
          current: {
            ...state.list.entities[id].current?.toInvite,
            ...pack,
          }
        }
      }, state.list)
    }
  }
}

export const reducer = createReducer(
  initialState,
  on(actions.clear, (state, action) => {
    return initialState
  }),
  on(actions.setTrainingPackages, (state, action) => {
    return {
      ...state,
      ...{
        total: action.total,
        loading: false,
        list: adapter.addMany(action.list, adapter.getInitialState())
      }
    }
  }),
  on(actions.addTrainingPackage, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.setOne(action.item, state.list)
      }
    }
  }),
  on(actions.updateTrainingPackage, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.updateOne({id : action.item.id, changes: action.item}, state.list)
      }
    }
  }),
  on(actions.deleteTrainingPackage, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.removeOne(action.id, state.list)
      }
    }
  }),
  on(actions.saveInvitationPack, (state, action) => {
    let pack = state.list.entities[action.package.id];
    return updateCurrentInvitePack(state, pack.id, {
      ...action.package.current,
      ...{
        toInvite: {
          ...pack.current.toInvite,
          ...{ loading: true}
        }
      }
    } as TrainingInvitePack);
  }),
  on(actions.fetchToInvite, (state, action) => {
    let pack = state.list.entities[action.package.id];
    return updateCurrentInvitePack(state, pack.id, {
      ...action.package.current,
      ...{
        toInvite: {
          ...pack.current?.toInvite,
          ...{ loading: true}
        }
      }
    } as TrainingInvitePack);
  }),
  on(actions.setToInvite, (state, action) => {
    let pack = state.list.entities[action.package.id];
    return updateCurrentInvitePack(state, pack.id, {
      ...action.package.current,
      ...{
        toInvite: {
          ...action.invited,
          ...{ loading: false}
        }
      }
    } as TrainingInvitePack);
  }),

  on(actions.fetchInvited, (state, action) => {
    let pack = state.list.entities[action.package.id];
    return updateCurrentInvitePack(state, pack.id, {
      ...action.package.current,
      ...{
        invited: {
          ...pack.current.invited,
          ...{ loading: true}
        }
      }
    } as TrainingInvitePack);
  }),
  on(actions.setInvited, (state, action) => {
    let pack = state.list.entities[action.package.id];
    return updateCurrentInvitePack(state, pack.id, {
      ...action.package.current,
      ...{
        invited: {
          ...action.invited,
          ...{ loading: false}
        }
      }
    } as TrainingInvitePack);
  }),
  on(actions.removeInvited, (state, action) => {
    let pack = state.list.entities[action.package.id];
    let list = [...pack.current.invited.list];
    let pos = list.map(i => i.id).indexOf(action.invited.id);
    list.splice(pos, 1);

    return updateCurrentInvitePack(state, pack.id, {
      ...action.package.current,
      ...{
        invited: {
          ...pack.current.invited,
          ... {
            total: +pack.current.invited.total-1,
            list: list
          }
        }
      }
    } as TrainingInvitePack);
  }),
  on(actions.addToInvite, (state, action) => {
    let pack = state.list.entities[action.package.id];
    return updateCurrentInvitePack(state, pack.id, {
      ...action.package.current,
      ...{
        toInvite: {
          ...pack.current.toInvite,
          ...{
            total: pack.current.toInvite? +pack.current.toInvite.total + 1 : 1,
            list:  pack.current.toInvite? [...pack.current.toInvite.list, ...[action.toInvite]] : [action.toInvite],
            loading: false
          }
        }
      }
    } as TrainingInvitePack);
  }),
  on(actions.deleteToInvite, (state, action) => {
    let pack = state.list.entities[action.package.id];
    return updateCurrentInvitePack(state, pack.id, {
      ...action.package.current,
      ...{
        toInvite: {
          ...pack.current.toInvite,
          ...{ loading: true}
        }
      }
    } as TrainingInvitePack);
  }),
  on(actions.removeToInvite, (state, action) => {
    let pack = state.list.entities[action.package.id];
    let toInviteList = [...pack.current.toInvite.list];
    let pos = toInviteList.map(item => item.id).indexOf(action.toInvite.id);
    toInviteList.splice(pos,1);

    return updateCurrentInvitePack(state, pack.id, {
      ...action.package.current,
      ...{
        toInvite: {
          ...action.package.current.toInvite,
          ...{
            total: pack.current.toInvite.total - 1,
            list: toInviteList,
            loading: false
          }
        }
      }
    } as TrainingInvitePack);
  }),
  on(actions.addNotification, (state, action) => {
    console.log("addNotification", action);
    let pack = state.list.entities[action.package.id];

    return updateCurrentInvitePack(state, pack.id, {
      notifications: notificationAdapter.addOne(action.notification, pack.current.notifications)
    } as TrainingInvitePack);
  }),
  on(actions.setNotification, (state, action) => {
    let pack = state.list.entities[action.package.id];

    return updateCurrentInvitePack(state, pack.id, {
      ...pack.current,
      ...{
        notifications: notificationAdapter.updateOne({ id : action.notification.id, changes: action.notification}, pack.current.notifications)
      }
    } as TrainingInvitePack);
  }),
  on(actions.removeNotification, (state, action) => {
    let pack = state.list.entities[action.package.id];

    return updateCurrentInvitePack(state, pack.id, {
      notifications: notificationAdapter.removeOne(action.notification.id, pack.current.notifications)
    } as TrainingInvitePack);
  }),

  on(actions.fetchParticipants, (state, action) => {
    let pack = state.list.entities[action.package.id];
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.updateOne({
          id : pack.id,
          changes: {
            invited: {
              loading: true,
              total: pack.invited.total,
              list: pack.invited.list
            }
          }
        }, state.list)
      }
    }
  }),

  on(actions.setParticipants, (state, action) => {
    let pack = state.list.entities[action.package.id];
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.updateOne({
          id : pack.id,
          changes: {
            invited: {
              loading: false,
              total: action.invited.total,
              list: action.invited.list
            }
          }
        }, state.list)
      }
    }
  }),


  on(actions.fetch, (state, action) => { return {...state, ...{loading: true}}}),
  on(actions.setPage, (state, action) => { return {...state, ...{page: action.page}}}),
  on(actions.setOrder, (state, action) => { return {...state, ...{order: action.order}}}),
  on(actions.setDirection, (state, action) => { return {...state, ...{asc: action.asc}}}),
  on(actions.setSearch, (state, action) => { return {...state, ...{search: action.search}}}),
  on(actions.setArchive, (state, action) => { return {...state, ...{archive: action.archive}}}),
  on(actions.setOpen, (state, action) => { return {...state, ...{open: action.open}}}),
);



export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectState = createFeatureSelector<State>('training-package');
export const selectAllTrainingPackage = createSelector(selectState, (state) => selectAll(state.list) )
export const selectTotalCount = createSelector(selectState, (state) => state.total )
export const selectOneTrainingPackage = createSelector(selectState, (state: State, id) => state.list.entities[id]);

export const selectTrainingToInvite = createSelector(selectState, (state: State, id) => {
  return state.list.entities[id]?.current?.toInvite ? state.list.entities[id].current.toInvite : {total: 0, list: []}
});

export const selectTrainingInvited = createSelector(selectState, (state: State, id) => {
  return state.list.entities[id]?.current?.invited ? state.list.entities[id].current.invited : {total: 0, list: []}
});

export const selectTrainingParticipants = createSelector(selectState, (state: State, id) => {
  return state.list.entities[id]?.invited ? state.list.entities[id].invited : {total: 0, list: []}
});

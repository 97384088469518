<section class="content">
  <div class="container">
      <div class="row">
          <div class="col-lg-12">
            <form [formGroup]="form" (ngSubmit)="save()">
            <app-package-tab></app-package-tab>
            <div class="card card-primary" style="border-top-left-radius: 0;border-top-right-radius: 0;">
              <div class="card-body">


                <div class="form-group">
                  <label for="inputName">Nazwa szkolenia</label>
                  <input formControlName="name" type="text" class="form-control" placeholder="Nazwa szkolenia" [appIsValid]="form.controls.name"/>
                  <div class="invalid-feedback" *ngIf="form.controls.name.errors?.required">Pole jest wymagane</div>
                </div>


                <div class="form-group">
                  <label for="inputName">Szkolenie ogólno dostępne</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" type="checkbox" formControlName="open"/>
                      Szkolenie ogólno dostępne
                    </label>
                  </div>
                </div>

                <div class="form-group">
                  <label for="inputName">Szkolenie z certyfikatem</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" type="checkbox" formControlName="certificate"/>
                      Szkolenie z certyfikatem
                    </label>
                  </div>
                </div>


                <div class="form-group">
                  <label for="typeahead-prevent-manual-entry">Wybierz szkolenia w paczce</label>
                    <ng-template #trainingElement let-r="result" let-t="term">
                      <div class="d-flex flex-md-row">
                      <div class="mr-2">
                        <img class="img-circle elevation-3" style="height: 45px;width: 45px;" [src]="r | groupAvatar" alt="User Avatar"/>
                      </div>
                      <ngb-highlight class="align-self-center" [result]="formatter(r)" [term]="t"></ngb-highlight>
                      <div class="align-self-center"> &nbsp; <strong>({{r.type}})</strong></div>
                      </div>
                    </ng-template>
                    <div class="position-relative">
                      <input id="typeahead-prevent-manual-entry"  type="text" class="form-control" placeholder="Zacznij wpisywać"
                        [(ngModel)]="typeaheadTrainingModel"
                        [ngModelOptions]="{standalone: true}"
                        [ngbTypeahead]="searchTraining"
                        [resultTemplate]="trainingElement"
                        [inputFormatter]="formatterEmpty"
                        [editable]='false'
                        (selectItem)="onTrainingTypeaheadSelect($event)"
                        (focus)="focus$.next($any($event).target.value)"
                        (click)="click$.next($any($event).target.value)"
                        #instanceTraining="ngbTypeahead"
                        [appIsValid]="form.controls.trainings"
                        [disabled]="package.published"
                      />
                      <i class="fas fa-chevron-down" style="position: absolute; right:0; top:0; margin: 0.7rem;"></i>
                      <div class="invalid-feedback" *ngIf="form.controls.trainings.errors?.required">
                        Pole jest wymagane
                      </div>
                    </div>
                    <ul class="list-group mt-3">
                      <li *ngFor="let item of package?.trainings; let idx = index; trackBy: identify" class="list-group-item ">
                        <div class="d-flex align-items-center">
                          <img class="img-circle elevation-3" [src]="item | groupAvatar" alt="{{formatter(item)}}"/>
                          <p class="pl-3 flex-grow-1  m-0">
                            {{formatter(item)}}
                            &nbsp; <strong>({{item.type}})</strong>
                          </p>
                          <a class="btn btn-danger btn-sm p-1 elevation-2"
                            *ngIf="!package.published"
                            (click)="removeTraining(item)">
                            <i class="fas fa-times fa-fw fa-lg"></i>
                          </a>
                        </div>
                      </li>
                    </ul>
                </div>


                <div class="form-group">
                  <label for="inputName">Obrazek w tle</label>
                  <div style="max-width: 100%;">
                    <app-upload-crop-image
                      [avatar]="false"
                      [(file)] = "backgroundFile"
                      [image]="package?.background ? (package.background | image) : null"
                      [aspectRatioClass]="'16-9'"
                      [aspectRatio]="16 / 9">
                    </app-upload-crop-image>
                  </div>
                </div>


                <div class="form-group">
                  <label for="inputName">Opis szkolenia</label>
                  <app-editor formControlName="content" [appIsValid]="form.controls.content"></app-editor>
                  <div class="invalid-feedback" *ngIf="form.controls.content.errors?.required">Pole jest wymagane</div>
                </div>



              </div>


              <div class="card-footer">
                <button class="btn btn-success float-right" type="submit">
                  Zapisz
                  <i class="fas fa-angle-right fa-fw"></i>
                </button>
              </div>
            </div>
            </form>
          </div>
      </div>
  </div>
</section>





<li class="nav-item dropdown user-menu ml-2" *ngIf="user">
    <a
        class="nav-link dropdown-toggle p-0 "
        data-toggle="dropdown"
        (click)="toggleDropdownMenu()"
    >
        <img
            [src]="user | userAvatar"
            class="img-size-40 img-circle elevation-2"
            alt="User Image"
            [appUserDisactivatable]="user"
        />
    </a>
    <ul
        #dropdownMenu
        class="dropdown-menu dropdown-menu-lg dropdown-menu-right"
    >
        <li class="user-header bg-primary">
            <img
                [src]="user | userAvatar: 84"
                class="img-circle elevation-2"
                alt="User Image"
                [appUserDisactivatable]="user"
            />

            <p>
                <span [appUserDisactivatable]="user">{{ user.active ? user.email : '-' }}</span>
                <small>
                    <span>Dołączył </span>
                    <span>{{user.createdAt | amLocale:'pl' | amCalendar }}</span>
                </small>
            </p>
        </li>
        <!-- <li class="user-body">
            <div class="row">
                <div class="col-4 text-center">
                    <a [routerLink]="['/']">Followers</a>
                </div>
                <div class="col-4 text-center">
                    <a [routerLink]="['/']">Sales</a>
                </div>
                <div class="col-4 text-center">
                    <a [routerLink]="['/']">Friends</a>
                </div>
            </div>
        </li>
        <li class="user-footer">
            <a [routerLink]="['/profile']" class="btn btn-default btn-flat">
                Profile
            </a>
            <a (click)="logout()" class="btn btn-default btn-flat float-right"
                >Sign out</a
            >
        </li> -->
        <li class="dropdown-item link" [routerLink]="['/account']" >
          Mój profil
        </li>
        <li class="dropdown-item link" [routerLink]="['/account','edit']" >
          Edytuj konto
        </li>
        <li class="dropdown-item link" [routerLink]="['/account','password']" >
          Edytuj hasło
        </li>
        <li class="dropdown-item link" [routerLink]="['/account','slots']" >
          Moje sloty pracy
        </li>
        <li class="dropdown-item link" [routerLink]="['/account','workmarket']" >
          Ustawienia giełdy pracy
        </li>
        <li class="dropdown-item link" (click)="logout()">
          Wyloguj się
        </li>
    </ul>
</li>


<ng-template #row let-item="item" let-parent="parent">
  <li>
    <a class="btn btn-flat btn-primary btn-block elevation-3" (click)="show(item, target)" [ngClass]="{'active' : item.id == current?.id}">
      {{item.title}}
    </a>
    <ul class="structure-list" *ngIf="item.children?.ids.length">
      <ng-template ngFor let-id [ngForOf]="item.children?.ids" let-idx="index">
        <ng-container *ngTemplateOutlet="row;context:{item: item.children.entities[id], parent: item}"></ng-container>
      </ng-template>
    </ul>
  </li>
</ng-template>

<div class="container-fluid" #target>
  <div class="row">

    <div class="col-lg-8 no-float order-lg-2" >
      <div class="card position-relative">
        <div class="overlay position-absolute" style="top:0; right:0; bottom:0; left:0" *ngIf="loading || !current">
          <div style="position: sticky; text-align: center">
            <i class="fas fa-3x fa-spinner fa-spin"></i>
            <p>Ładowanie...</p>
          </div>
        </div>
        <div
          class="bg-primary text-white"
          style="aspect-ratio: 16 / 9"
          *ngIf="current?.image"
          [ngStyle]="backgroundStyle(current)">
        </div>
        <div class="card-header">
          <h3 class="card-title text-bold text-center">{{current?.title}}</h3>
        </div>
        <div class="card-body" *ngIf="current?.description" [innerHtml]="current.description | safeHtml"></div>
        <div class="card-footer pt-2">
          <h3 class="card-title text-bold ">Członkowie:</h3>
        </div>
        <div>
          <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="members.length == 0"></nz-empty>

          <div class="card-footer pt-2" *ngFor="let user of members">
            <div class="user-block">
              <img class="img-circle img-bordered-sm" [src]="user | userAvatar:65" alt="User Image" style="width:65px; height: 65px" [appUserDisactivatable]="user">
              <span class="username" style="margin-left: 80px;">
                <a [routerLink]="['/profile',user.id]"  [appUserDisactivatable]="user">
                  {{user.firstname}} {{user.lastname}}
                </a>
              </span>
              <span class="description" style="margin-left: 80px;">
                <i class='fa fa-envelope fa-fw'></i> <span [appUserDisactivatable]="user">{{user?.assigned_to ? user?.email : '-'}}</span><br>
                <i class='fa fa-calendar fa-fw'></i> W bazie od <strong [appUserDisactivatable]="user">{{user.createdAt  | amDateFormat:'DD.MM.YYYY'}}</strong>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="col-lg-4 no-float order-lg-1 position-relative">
      <div class="card" style="position: sticky;top: 100px;">
          <div class="card-header with-border">
              <h3 class="card-title" >Struktura Firmy</h3>
          </div>
          <div class="card-body" style="height: calc(100vh - 310px); overflow-x: hidden; overflow-y: auto;">
            <div  id="treeMap" style="padding-bottom: 50px;">
              <ul class="structure-list">
                <ng-template ngFor let-item [ngForOf]="list" let-idx="index" [ngForTrackBy]="identify">
                  <ng-container *ngTemplateOutlet="row;context:{item: item}"></ng-container>
                </ng-template>
              </ul>
            </div>
          </div>
      </div>
    </div>

  </div>
</div>

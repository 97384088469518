import { OrderForm } from '@/model/order.form.model';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import * as fromActions from '@/store/order/order.actions';
import * as fromReducers from '@/store/order/order.reducer';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-view-orders-detail',
  templateUrl: './view-orders-detail.component.html',
  styleUrls: ['./view-orders-detail.component.scss']
})
export class ViewOrdersDetailComponent implements OnInit {
  protected readonly unsubscribe$: Subject<void> = new Subject();
  orderForm: OrderForm;
  order: any;
  loading = true;


  constructor(
    protected store: Store<fromReducers.State>,
    protected toastr: ToastrService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe( params => {
      let id = params.id;
      let order = params.order;
      this.store.dispatch(fromActions.fetchOneOrderForm({id}));
      this.store.pipe(
        takeUntil(this.unsubscribe$),
        select(fromReducers.selectOne, id)).subscribe((orderForm: OrderForm) => {
          if(!orderForm)return;
          this.orderForm = orderForm;
          this.httpClient.get<any>(`<backendhost>/v1/order/${order}/detail`, {
            observe: 'body',
            responseType: 'json'
          })
          .subscribe((result) => {
            this.order = result;
            this.loading = false;
          });
        })
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  backgroundStyle () {

    if(!this.orderForm)
      return null;
    if(!this.orderForm.background)
      return null;
    return {
      'aspect-ratio': '4',
      'background' : `url(${environment.apiUrl}/thumb/1920x0/${this.orderForm.background.replace('public','')}) center center`,
      'background-size': 'cover'
    }
  }

}

<!-- <pre>{{ (loading | async) }}</pre> -->
<section class="content">
  <div class="container-xl pt-3 pb-3">
    <div class="card">
      <div class="overlay" *ngIf="(loading | async)">
        <div style="position: sticky; text-align: center">
          <i class="fas fa-3x fa-spinner fa-spin"></i>
          <p>Ładowanie...</p>
        </div>
      </div>
      <div class="card-header d-flex align-items-center">
        <h3 class="card-title m-0 flex-grow-1">Kategorie zasobów</h3>
        <div class="card-tools flex-grow-1">
          <div class="input-group">
            <input type="text" name="table_search" class="form-control float-right" placeholder="Szukaj" #searchInput>
            <button class="btn bg-transparent" style="position:absolute; right: 0; transform: translate(-100%,0);" (click)="clearSearch()">
              <i class="fa fa-times"></i>
            </button>
            <span class="input-group-append">
              <div class="input-group-text bg-transparent"><i class="fas fa-search"></i></div>
            </span>
          </div>
        </div>
      </div>
      <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(paginator | async).length == 0"></nz-empty>
      <div class="">
        <a *ngFor="let item of paginator | async; let idx = index; trackBy: identify"
          class="list-group-item list-group-item-action d-flex"
          [routerLink]="['/reservations', item.id]"
          >
          <div><img class="img-circle" [src]="item | groupAvatar:55"/></div>
          <div class="ml-3">
            <strong>{{item.title}}</strong><br/>
            <span [innerHtml]="item.short | safeHtml"></span>...
          </div>
        </a>
      </div>
      <div class="card-footer" >
        <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="total | async" (pageChange)="pageChange($event)">
          <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
</section>

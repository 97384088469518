
<div style="height: 70vh;">
  <ng-scorm-player

    [urlDirSco]="scormDir"
    [urlLaunchPage]="entryPoint"
    [scormResult]="scormResult"></ng-scorm-player>

</div>
<div class="card-body" *ngIf="result.passed !== null && scorm.pointable">
  <div class="alert text-center" [ngClass]="{'alert-success': result.passed, 'alert-danger': !result.passed}">
    <h4>Twój Wynik:</h4>
    <h2>{{result?.scorePercent  | number}}% na {{scorm.minScore}}% wymaganych</h2>
  </div>
</div>
<!-- <pre>{{runtimeData | json}}</pre> -->
<!-- <pre>{{result | json}}</pre> -->

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { ofType } from '@ngrx/effects';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, pairwise, takeUntil } from 'rxjs/operators';
import * as fromQuizAction from '@/store/quiz/quiz.actions';
import * as fromQuizReducers from '@/store/quiz/quiz.reducers';
import { Quiz, QuizQuestion } from '@/model/quiz.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuizQuestionModalComponent } from '../quiz-question-modal/quiz-question-modal.component';

@Component({
  selector: 'app-quiz-edit',
  templateUrl: './quiz-edit.component.html',
  styleUrls: ['./quiz-edit.component.scss']
})
export class QuizEditComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  quiz: Quiz;
  showSilder = true;
  constructor(
    private toastr: ToastrService,
    private store: Store<fromQuizReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected modalService: NgbModal,
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      minScore: new FormControl(100, Validators.required),
      questionNum: new FormControl(1, Validators.required),
    });
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data:Data) => {
      this.quiz = {...this.quiz, ...data.instance};
      this.form.patchValue(this.quiz);

      this.store.pipe(
        select(fromQuizReducers.selectOneQuiz, this.quiz.id),
        takeUntil(this.unsubscribe$),
        map(item => item.questions.ids.length),
        pairwise(),
        )
          .subscribe(([prev, next]: [number, number]) => {
            if (next != prev) {
              this.showSilder = false;
              setTimeout(() => {
                let v =  Math.max(Math.min(this.form.value.questionNum, next),1);
                this.form.controls.questionNum.setValue(v, {emitEvent: false});
                this.showSilder = true;
              }, 100)
            }
          })
      // .pipe(startWith(this.optionsCtrl.value), pairwise())
      // .subscribe(([prev, next]: [any[], any[]]) => {

      this.store.pipe(
        select(fromQuizReducers.selectOneQuiz, this.quiz.id),
        takeUntil(this.unsubscribe$),
        distinctUntilChanged()
      ).subscribe((quiz) => {
        this.quiz = quiz;
      })
    })
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  save(): void {
      if (this.form.valid) {
        this.quiz = {...this.quiz, ...this.form.value};
        this.store.dispatch(fromQuizAction.updateQuiz({quiz: this.quiz}));
        this.actionsSubject$.pipe(
          takeUntil(this.unsubscribe$),
          ofType(fromQuizAction.setQuiz.type)
        ).subscribe( (action: any) => {
          this.toastr.success('Zapisano quiz');
          this.router.navigate(['/training/admin/quiz']);
        });
    } else {
        this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }

  get questions(): QuizQuestion[] {
    return fromQuizReducers.questionAdapter.getSelectors().selectAll(this.quiz.questions);
  }

  addQuestion() {
    const modalRef = this.modalService.open(QuizQuestionModalComponent, {size: 'xl',scrollable: false});
    (modalRef.componentInstance as QuizQuestionModalComponent).quiz = this.quiz;
  }

  editQuestion(item: QuizQuestion) {
    const modalRef = this.modalService.open(QuizQuestionModalComponent, {size: 'xl',scrollable: false});
    (modalRef.componentInstance as QuizQuestionModalComponent).quiz = this.quiz;
    (modalRef.componentInstance as QuizQuestionModalComponent).question = item;
  }

  deleteQuestion(item: QuizQuestion) {
    this.store.dispatch(fromQuizAction.deleteQuizQuestion({quiz: this.quiz, question: item}));
  }

  getTypeName(item: QuizQuestion): string {
    return QuizQuestionModalComponent.types[item.type];
  }

  checkMultiple(item: QuizQuestion): boolean {
    return QuizQuestionModalComponent.isMultiple(item.type);
  }

  percentFormatter(value: number): string {
    return `${value}%`;
  }
}

import { Multimedia, Slide } from '@/model/multimedia.model';
import { Invitation, TrainingResult } from '@/model/package.model';
import { Training } from '@/model/scorm.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import * as fromMyTrainingsAction from '@/store/my-training/my-training.actions';
import * as fromMyTrainingsReducers from '@/store/my-training/my-training.reducers';
import { select, Store } from '@ngrx/store';
import { map, distinctUntilChanged, tap, filter, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-my-training-player',
  templateUrl: './my-training-player.component.html',
  styleUrls: ['./my-training-player.component.scss']
})
export class MyTrainingPlayerComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  invitation: Invitation;
  training: Training;
  nextDisabled = true;
  result: TrainingResult;
  slideTitle: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<fromMyTrainingsReducers.State>
  ) {

  }

  // get resultObserver() : Observable<TrainingResult> {
  //   return this.store.pipe(
  //     select(fromMyTrainingsReducers.selectMyTrainingResult, {id: this.invitation.id, training: this.training}),
  //     distinctUntilChanged( (x,y) => !x && !y || x?.id == y?.id),
  //     tap( (result) => {
  //       if(!result) {
  //         this.store.dispatch(fromMyTrainingsAction.createResult({invitation: this.invitation, training: this.training}));
  //       }
  //     }),
  //     filter((result) => result != null),
  //     // take(1)
  //   )
  // }

  // initialize(): Promise<TrainingResult> {
  //   return new Promise<TrainingResult>(resolve => {
  //     this.resultObserver.pipe(take(1)).subscribe(resolve)
  //   })
  // }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data: Data) => {
      this.invitation = data.invitation;
      this.training = data.training;

      this.activatedRoute.params.subscribe( params  => {
        if (typeof params.slide != "undefined") {
          let idx = Math.max(0, params.slide * 1 );
          this.slideTitle = idx < (this.training as Multimedia).slides.length ? (this.training as Multimedia).slides[idx].name : 'Koniec';
        }
      })


      this.store.pipe(
        select(fromMyTrainingsReducers.selectOneMyTraining, this.invitation.id),
        takeUntil(this.unsubscribe$),
      ).subscribe( (invitation) => {
        this.invitation = invitation;
      })

      this.store.pipe(
        select(fromMyTrainingsReducers.selectMyTrainingResult, {id: this.invitation.id, training: this.training}),
        takeUntil(this.unsubscribe$),
      ).subscribe( (result) => {

        if(!result) {
          this.store.dispatch(fromMyTrainingsAction.createResult({invitation: this.invitation, training: this.training}));
        }
        this.result = result;
      })

      this.store.pipe(
        select(fromMyTrainingsReducers.selectMyTrainingCompletedResults,this.invitation.id),
        takeUntil(this.unsubscribe$),
        distinctUntilChanged( (x,y) => x == y),
      ).subscribe( (result) => {
         console.log('COMPLETED', result, this.invitation.package.trainings.length);
         if (result == this.invitation.package.trainings.length && this.invitation.completedAt == null) {
          this.store.dispatch(fromMyTrainingsAction.complete({id: this.invitation.id}));
         }
      })
    })
  }

  get slides(): Slide[] {
    return (this.training as Multimedia).slides;
  }

  private sidebarOpen = false;
  sidebarToggle() {
    this.sidebarOpen = !this.sidebarOpen;
  }

  sidebarStyle() {
    return {
      "right" : this.sidebarOpen ? '0px' : '-250px'
    }
  }

  get slideResults(): TrainingResult[] {
    return Object.values(this.invitation.results.entities).filter(result => {
      return result.training?.id == this.training.id && result.slide != null;
    });
  }

  slideResult(slide: Slide): TrainingResult {
    return Object.values(this.invitation.results.entities).filter((item) => item.slide?.id == slide.id).pop();
  }

  get title() : string {
    return this.training.type == 'Multimedialne' ? `${this.training.name} :: ${this.slideTitle}` :  this.training.name;
  }

  onFinish(result) {
    this.result = {...this.result, ...result};
    this.store.dispatch(fromMyTrainingsAction.updateResult({result: this.result, invitation: this.invitation}))
  }

  onScormClose(result) {
    this.router.navigate(['training', this.invitation.id]);
  }
}

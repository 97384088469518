import { RedmineIssue } from '@/model/redmine.model';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RedmineService } from '@services/redmine.service';
import { interval, Observable, of, Subject, timer } from 'rxjs';
import * as fromNotificationReducers from '@/store/notification/notification.reducers';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-redmine-dropdown-menu',
  templateUrl: './redmine-dropdown-menu.component.html',
  styleUrls: ['./redmine-dropdown-menu.component.scss']
})
export class RedmineDropdownMenuComponent implements OnInit, OnDestroy {
  @ViewChild('dropdownMenu', {static: false}) dropdownMenu;
  private readonly unsubscribe$: Subject<void> = new Subject();

  @HostListener('document:click', ['$event'])
  clickout(event) {
      if (!this.elementRef.nativeElement.contains(event.target)) {
          this.hideDropdownMenu();
      }
  }

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private service: RedmineService,
    private storeNotification: Store<fromNotificationReducers.State>,
    private router: Router,
  ) { }

  ngOnInit(): void {
    // timer(1000 * 30, 1000 * 40)
    //   .pipe(takeUntil(this.unsubscribe$))
    //   .subscribe(() => {
    //     this.service.load();
    //   })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleDropdownMenu() {
    if (this.dropdownMenu.nativeElement.classList.contains('show')) {
        this.hideDropdownMenu();
    } else {

        this.service.fetchAssignedToMe();
        this.showDropdownMenu();
    }
  }

  showDropdownMenu() {
      this.renderer.addClass(this.dropdownMenu.nativeElement, 'show');
  }

  hideDropdownMenu() {
      this.renderer.removeClass(this.dropdownMenu.nativeElement, 'show');
  }

  get count(): Observable<number>{
    return this.service.assignedCount;
  }

  get list(): Observable<RedmineIssue[]>{
    return this.service.all;
  }

  identify (item: RedmineIssue, index: number) {
    return item.id;
  }

  link (item: RedmineIssue) {
    this.router.navigate(['/redmine/issue', item.id]);
  }

  hasNotification(item: RedmineIssue): Observable<boolean> {
    return item.notification ? this.storeNotification.select(fromNotificationReducers.hasNotification, item.id) : of(false);
  }

}

export const tinyMceConfig = {
  apiKey: 'dwv5fed8ebe92i7h5wbzi4n42oit0hdhbszytxmygoah5pn2',
  init: {
    powerpaste_googledocs_imports: function() {
      // use a native confirm dialog to prompt the user to choose between clean and merge
      return new Promise(function (resolve) {
        if (confirm('Would you like to keep formatting?')) {
          resolve('merge');
        } else {
          resolve('clean');
        }
      });
    },

    height: 500,
    menubar: false,
    statusbar: false,
    toolbar_items_size: 'small',
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table code help wordcount',
      // 'powerpaste'
    ],
    fontsize_formats: '8px 10px 12px 14px 16px 18px 20px 24px 36px',
    toolbar:
      'bold italic underline strikethrough | formatselect fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link |  removeformat code'
  }
}


import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[appIsValid]'
})
export class IsValidDirective implements OnChanges {
  @Input() appIsValid: FormControl;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnChanges(changes: SimpleChanges):void {
    this.observe(changes.appIsValid.currentValue);
  }

  private observe(input: FormControl) {
    input.valueChanges.subscribe(this.update.bind(this));
    input.statusChanges.subscribe(this.update.bind(this));
  }

  private update() {
    if(this.appIsValid.errors && (this.appIsValid.dirty || this.appIsValid.touched)) {
      this.renderer.addClass(this.element.nativeElement, 'is-invalid');
    } else {
      this.renderer.removeClass(this.element.nativeElement, 'is-invalid');
    }
  }

}
// [ngClass]="{'is-invalid' : form.controls.confirmPassword.invalid && form.controls.confirmPassword.touched}"

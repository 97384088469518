import { Component,  ViewChild, HostListener, ElementRef, Renderer2, OnDestroy, OnInit} from '@angular/core';
import { interval, Observable, of, Subject, timer } from 'rxjs';
import { AppNotification } from '@/model/notification.model';
import { NotificationService } from '@services/notification.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
@Component({
    selector: 'app-notifications-dropdown-menu',
    templateUrl: './notifications-dropdown-menu.component.html',
    styleUrls: ['./notifications-dropdown-menu.component.scss']
})
export class NotificationsDropdownMenuComponent implements OnInit, OnDestroy{
  private readonly unsubscribe$: Subject<void> = new Subject();
    @ViewChild('dropdownMenu', {static: false}) dropdownMenu;

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.hideDropdownMenu();
        }
    }

    constructor(
      private router: Router,
      private elementRef: ElementRef,
      private renderer: Renderer2,
      private service: NotificationService
    ) {}

    ngOnInit(): void {
      // timer(1000*20, 1000 * 40)
      //   .pipe(takeUntil(this.unsubscribe$))
      //   .subscribe(() => {
      //     this.service.load();
      //   })
    }

    ngOnDestroy() {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    }

    toggleDropdownMenu() {
        if (this.dropdownMenu.nativeElement.classList.contains('show')) {
            this.hideDropdownMenu();
        } else {
            this.showDropdownMenu();
        }
    }

    showDropdownMenu() {
        this.renderer.addClass(this.dropdownMenu.nativeElement, 'show');
    }

    hideDropdownMenu() {
        this.renderer.removeClass(this.dropdownMenu.nativeElement, 'show');
    }

    get count(): Observable<number>{
      return this.service.count();
    }

    get list(): Observable<AppNotification[]>{
      return this.service.recent();
    }

    identify (item: AppNotification, index: number) {
      return item.id;
    }

    type (item: AppNotification) {
      if(item.subtitle)
        return item.subtitle;
      switch (item.type) {
        case 'post' : return 'News';
        case 'offer' : return 'Oferta pracy';
        case 'comment_idea' : return 'Komentarz do pomysłu';
        case 'comment_post' : return 'Komentarz do posta';
        case 'comment_reply' : return 'Odpowiedz na komentarz';
        case 'comment_mention' : return 'Wzmianka w komentarzu';
        case 'post_mention' : return 'Wzmianka w poście';
        case 'post_article' : return 'Wzmianka w atykule';
        case 'redmine' : return 'Zgłoszenie';
        default :
          return item.type;
      }
    }

    link (item: AppNotification) {
      switch (item.type) {
        case 'post' :
          this.router.navigate(['/news', item.id]);
          break;
        case 'offer' :
          this.router.navigate(['/offer', item.id]);
          break;
        default :
          this.router.navigate([item.url.split('#')[0]], {fragment: item.url.split('#')[1] });
          this.service.delete(item);
          break;
      }
    }
}

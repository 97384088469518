<div class="card card-primary card-outline" *ngIf="message">
  <div class="card-header">
    <h3 class="card-title">Wiadomości</h3>

    <!-- <div class="card-tools">
      <a href="#" class="btn btn-tool" title="Previous"><i class="fas fa-chevron-left"></i></a>
      <a href="#" class="btn btn-tool" title="Next"><i class="fas fa-chevron-right"></i></a>
    </div> -->
  </div>
  <div class="card-body p-0">
    <div class="mailbox-read-info">
      <h5>{{message?.title}}</h5>
    </div>
  </div>

  <div class="card-body p-0">
    <div class="mailbox-read-info">
      <h6 class="d-flex align-items-center">
        <small class="text-muted pr-2">Od: </small>
        <span class="text-decoration-line-through" *ngIf="!message?.createdBy">Użytkownik usunięty</span>
        <a class="d-flex flex-grow-1 align-items-center" [routerLink]="message.createdBy.active ? ['/profile', message?.createdBy?.id] : null" *ngIf="message?.createdBy" [attr.disabled]="!message.createdBy.active">
          <div class="pr-2">
            <img [src]="message?.createdBy | userAvatar:45" class="img-circle" [appUserDisactivatable]="message?.createdBy"/>
          </div>
          <div>
            <span [appUserDisactivatable]="message?.createdBy">{{message?.createdBy?.name}}</span><br>
            <small [appUserDisactivatable]="message?.createdBy"><{{ message.createdBy?.active ? message.createdBy?.email : '-' }}></small>
          </div>
        </a>

        <span class="mailbox-read-time float-right">
          {{message?.createdAt | amUtc | amLocal | amDateFormat:'DD.MM.YYYY HH:mm'}}
        </span></h6>
    </div>
    <div class="mailbox-read-message" [innerHtml]="message?.content | safeHtml">
    </div>
    <!-- /.mailbox-read-message -->
  </div>

</div>

import { OfferCategory } from "@/model/offer.model";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import * as fromActions from './offer.category.action';

export interface State extends EntityState<OfferCategory>{

}

export const adapter: EntityAdapter<OfferCategory> = createEntityAdapter<OfferCategory>({
  sortComparer: (a: OfferCategory, b: OfferCategory) => a.id < b.id ? 0 : 1
});

export const initialState: State = adapter.getInitialState()

export const reducer = createReducer(
  initialState,
  on(fromActions.addOne, (state, action) => {
    return adapter.addOne(action.item, state);
  }),
  on(fromActions.addMany, (state, action) => {
    return adapter.addMany(action.list, state);
  }),
  on(fromActions.updateOne, (state, action) => {
    return adapter.updateOne({
      id: action.item.id,
      changes: action.item
    }, state);
  }),
  on(fromActions.removeOne, (state, action) => {
    return adapter.removeOne(action.id, state);
  }),
);

export const selectOfferState = createFeatureSelector<State>('offer-category');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectAllOfferCategory = createSelector(selectOfferState, (state) => selectAll(state))
export const selectOfferCategory = createSelector(selectOfferState, (state, id) => state.entities[id])

<section class="content">
  <div class="container">
    <form [formGroup]="form" (ngSubmit)="save()">
      <app-survey-admin-tab></app-survey-admin-tab>
      <div class="card card-primary" style="border-top-left-radius: 0;border-top-right-radius: 0;">
        <div class="card-body">
          <div class="form-group">
            <label for="sendTo">Wyślij do</label>
            <div class="form-check">
              <input class="form-check-input" type="radio" value="users" formControlName="sendTo" id="sendToUsers" checkRolePermition="chat.room.create">
              <label class="form-check-label" for="sendToUsers">Wybranych użytkowników</label>
            </div>
            <div class="form-check" [checkRolePermition]="['messages.send.group', 'messages.send.myGroup']">
              <input class="form-check-input" type="radio" value="groups" formControlName="sendTo" id="sendToGroups" >
              <label class="form-check-label" for="sendToGroups">Wybranych grup</label>
            </div>
            <div class="form-check" checkRolePermition="messages.send.all">
              <input class="form-check-input" type="radio" value="all" formControlName="sendTo" id="sendToAll">
              <label class="form-check-label" for="sendToAll">Wszystkich użytkowników</label>
            </div>
          </div>

          <div class="form-group" *ngIf="form.get('sendTo').value == 'users'">
            <label for="typeahead-prevent-manual-entry">Wybierz użytkowników</label>
            <ng-template #usersElement let-r="result" let-t="term">
              <div class="d-flex flex-md-row">
              <div class="mr-2">
                <!-- <img [src]="r.image" class="img-circle"> -->
                <img class="img-circle elevation-3" style="height: 45px;width: 45px;" [src]="r | userAvatar" alt="User Avatar"/>
              </div>
              <ngb-highlight class="align-self-center" [result]="formatter(r)" [term]="t"></ngb-highlight>
              </div>
            </ng-template>
            <input id="typeahead-prevent-manual-entry"  type="text" class="form-control" placeholder="Zacznij wpisywać"
                [(ngModel)]="typeaheadUserModel"
                [ngModelOptions]="{standalone: true}"
                [ngbTypeahead]="searchUsers"
                [resultTemplate]="usersElement"
                [inputFormatter]="formatterEmpty"
                [editable]='false'
                (selectItem)="onUsersTypeaheadSelect($event)"
                (focus)="focus$.next($any($event).target.value)"
                (click)="click$.next($any($event).target.value)"
                #instanceUser="ngbTypeahead"
                [appIsValid]="form.controls.recepients"
              />
              <div class="invalid-feedback" *ngIf="form.controls.recepients.errors?.required">
                Pole jest wymagane
              </div>

            <ul class="list-group mt-3">
              <li *ngFor="let item of typeaheadUsersList; let idx = index" class="list-group-item ">
                <div class="d-flex align-items-center">
                  <img class="img-circle elevation-3" [src]="item | userAvatar" alt="{{formatter(item)}}" [appUserDisactivatable]="item"/>
                  <p class="pl-3 flex-grow-1  m-0" [appUserDisactivatable]="item">{{formatter(item)}}</p>
                  <button class="btn btn-danger btn-sm p-1 elevation-2" (click)="removeUser(item)">
                    <i class="fas fa-times fa-fw fa-lg"></i>
                  </button>
                </div>
              </li>
            </ul>
          </div>


          <div class="form-group" *ngIf="form.get('sendTo').value == 'groups'">
            <label for="typeahead-prevent-manual-entry">Wybierz grupy</label>
            <ng-template #groupElement let-r="result" let-t="term">
              <div class="d-flex flex-md-row">
              <div class="mr-2">
                <img class="img-circle elevation-3" style="height: 45px;width: 45px;" [src]="r | groupAvatar" alt="User Avatar"/>
              </div>
              <ngb-highlight class="align-self-center" [result]="formatter(r)" [term]="t"></ngb-highlight>
              </div>
            </ng-template>
            <input id="typeahead-prevent-manual-entry"  type="text" class="form-control" placeholder="Zacznij wpisywać"
                [(ngModel)]="typeaheadGroupModel"
                [ngModelOptions]="{standalone: true}"
                [ngbTypeahead]="searchGroups"
                [resultTemplate]="groupElement"
                [inputFormatter]="formatterEmpty"
                [editable]='false'
                (selectItem)="onGroupsTypeaheadSelect($event)"
                (focus)="focus$.next($any($event).target.value)"
                (click)="click$.next($any($event).target.value)"
                #instanceGroup="ngbTypeahead"
                [appIsValid]="form.controls.groups"
              />
              <div class="invalid-feedback" *ngIf="form.controls.groups.errors?.required">
                Pole jest wymagane
              </div>

            <ul class="list-group mt-3">
              <li *ngFor="let item of typeaheadGroupList; let idx = index" class="list-group-item ">
                <div class="d-flex align-items-center">
                  <img class="img-circle elevation-3" [src]="item | groupAvatar" alt="{{formatter(item)}}"/>
                  <p class="pl-3 flex-grow-1  m-0">{{formatter(item)}}</p>
                  <button class="btn btn-danger btn-sm p-1 elevation-2" (click)="removeGroup(item)">
                    <i class="fas fa-times fa-fw fa-lg"></i>
                  </button>
                </div>
              </li>
            </ul>
          </div>

          <div class="form-group">
            <label>Termin ankliety</label>
            <app-date-time-picker formControlName="endDate" name="endDate" [appIsValid]="form.controls.endDate"></app-date-time-picker>
            <div class="text-danger" *ngIf="form.controls.endDate.errors?.required">Pole jest wymagane</div>
            <div class="text-danger" *ngIf="form.controls.endDate.errors?.dateNotValid">
              Data jest nieprawidłowa
            </div>
          </div>

          <div class="form-check">
            <label class="form-check-label">
              <input class="form-check-input" type="checkbox" formControlName="sendPush" >
              Wyślij powiadomienie Push
            </label>
          </div>
          <div class="form-check">
            <label class="form-check-label">
              <input class="form-check-input" type="checkbox" formControlName="sendEmail">
              Wyślij powiadomienie Email
            </label>
          </div>
          <!-- <div class="form-check">
            <label class="form-check-label">
              <input class="form-check-input" type="checkbox" formControlName="sendSms">
              Wyślij powiadomienie SMS
            </label>
          </div> -->


        </div>
        <div class="card-footer">
          <button
            [disabled]="!potentialBox?.total || potentialBox?.loading"
            class="btn btn-primary float-right ml-3"
            type="submit"
            (click)="publish()">
            <i class="fas fa-check fa-fw fa-lg"></i>
            Zapisz i opublikuj
          </button>
          <button class="btn btn-success float-right" type="submit">
            Zapisz
            <!-- <i class="fas fa-angle-right fa-fw fa-lg"></i> -->
          </button>
        </div>

        <div class="alert alert-warning" role="alert" *ngIf="!survey.published">
          <h4>Uwaga!</h4>
          Po opublikowaniu ankiety stanie się ona widoczna dla wybranych użytkowników oraz zostaną wysłane powiadomienia. <br>
          W opublikowanych ankietach nie będzie już można edytować pytań ani grupy docelowej.
        </div>

        <app-survey-publish-potential [survey]="survey" #potential></app-survey-publish-potential>
        <app-survey-publish-participants [survey]="survey" #participants></app-survey-publish-participants>
      </div>
    </form>
  </div>
</section>

import { AllOrCreated, Role } from "@/model/role.model";

export const AdminRole: Role = {
  id: "admin",
  name: "Admin",
  default: true,
  root: true,
  privileges: {
    news: {
      view: true,
      create: true,
      publicate: {
        all: true,
        group: true,
        myGroup: false,
        sms: true,
        push: true,
        important: true,
      },
      edit: AllOrCreated.ALL,
      delete: AllOrCreated.ALL
    },
    comments: {
      delete: AllOrCreated.ALL
    },
    groups: {
      list: true,
      create: true,
      edit: AllOrCreated.ALL,
      delete: AllOrCreated.ALL,
      superior: {
        create: true,
        edit: AllOrCreated.ALL,
        delete: AllOrCreated.ALL,
      }
    },
    chat: {
      room: {
        create: true,
        edit: AllOrCreated.ALL,
        delete: AllOrCreated.ALL,
      }
    },
    messages: {
      send: {
        all : true,
        group: true,
        myGroup: true,
        sms: true,
        push: true
      }
    },
    users: {
      list: true,
      create : true,
      edit: true,
      delete: true,
      password: true,
      role: true,
      prize: AllOrCreated.NONE
    },
    structure: {
      list: true,
      create : true,
      edit: true,
      delete: true
    },
    offers: {
      list: true,
      candidates: true,
      create : true,
      edit: true,
      delete: true,
      category: true,
    },
    orders: {
      list: true,
      order: true,
      create : true,
      edit: true,
      delete: true
    },
    kb: {
      list: true,
      admin: {
        folders: {
          create: true,
          edit: true,
          delete: true
        },
        articles: {
          create: true,
          edit: true,
          delete: true
        },
        files: {
          create: true,
          edit: true,
          delete: true
        }
      }
    },
    ideas: {
      list: true,
      create: true,
      edit: true,
      delete: AllOrCreated.ALL,
    },
    prizes: {
      list: false,
      create: false,
      edit: false,
      delete: false,
    },
    reservations: {
      list: true,
      reservation: {
        create: true,
        delete: AllOrCreated.ALL,
      },
      admin: {
        category: {
          create: true,
          edit: true,
          delete: true,
        },
        assets: {
          create: true,
          edit: true,
          delete: AllOrCreated.ALL,
        }
      },
    },
    training: {
      view: true,
      admin: {
        scorm: true
      }
    },
    survey: {
      view: true,
      admin: true
    },
    outpost: {
      view: true,
      admin: true,
      slot: {
        sign: true,
        unsign: AllOrCreated.ALL,
      }
    },
    vacant: {
      view: true,
      create: true,
      createMy: false,
      edit: AllOrCreated.ALL,
      delete: AllOrCreated.ALL,
      viewAll: true,
      reservation: {
        create: true,
        delete: AllOrCreated.ALL,
      }
    },
    roles: {
      admin: true,
      delete: true
    },
    redmine: {
      view: true,
      admin: true
    },
    callcenter: {
      view: AllOrCreated.ALL,
      fill: true,
      fillEdit: AllOrCreated.ALL,
      fillDelete: AllOrCreated.ALL,
      create: true,
      edit: AllOrCreated.ALL,
      delete: AllOrCreated.ALL,
    },
    statistics: {
      view: true
    }
  }
};

import { ActionReducerMap, State } from '@ngrx/store';

import * as fromAuthReducers from './auth/auth.reducers';
import * as fromPostReducers from './post/post.reducers';
import * as fromGroupReducers from './group/group.reducers';
import * as fromUsersReducers from './users/users.reducers';
import * as fromChatReducers from './chat/chat.reducers';
import * as fromMessagesReducers from './messages/messages.reducers';
import * as fromStructureReducers from './structure/structure.reducers';
import * as fromKbeReducers from './kb/kb.reducers';
import * as fromOfferReducers from './offer/offers.reducers';
import * as fromNotificationReducers from './notification/notification.reducers';
import * as fromSearchReducers from './search/search.reducers';
import * as fromSearchTagReducers from './search-tag/search-tag.reducers';
import * as fromPrizesReducers from './prizes/prize.reducers';
import * as fromOrdeReducers from './order/order.reducer';
import * as fromIdeasReducers from './ideas/ideas.reducers';
import * as fromReservationsReducers from './reservations/reservations.reducers';
import * as fromRolesReducers from './roles/roles.reducers';
import * as fromScormReducers from './scorm/scorm.reducers';
import * as fromMultimediaReducers from './multimedia/multimedia.reducers';
import * as fromTrainingGroupReducers from '@/store/training-group/training-group.reducers';
import * as fromTrainingReducers from '@/store/training/training.reducers';
import * as fromTrainingPackageReducers from '@/store/package/package.reducers';
import * as fromTrainingMyTrainingsReducers from '@/store/my-training/my-training.reducers';
import * as fromTrainingQuizReducers from '@/store/quiz/quiz.reducers';
import * as fromSurveyReducers from '@/store/survey/survey.reducers';
import * as fromMySurveyReducers from '@/store/my-survey/my-survey.reducers';
import * as fromOutpostReducers from '@/store/outpost/outposts.reducers';
import * as fromSlotReducers from '@/store/slot/slot.reducers';
import * as fromVacantReducers from '@/store/vacant/vacant.reducers';
import * as fromVacantToConfirmReducers from '@/store/vacant/vacant.toconfirm.reducers';
import * as fromOfferCategoryReducers from '@/store/offer-category/offer.category.reducers';
import * as fromRedmineCategoryReducers from '@/store/redmine/redmine.category.reducers';
import * as fromRedmineIssueReducers from '@/store/redmine/redmine.issue.reducers';
import * as fromCallCenterQuestionnaire from '@/store/call-center/call-center-questionnaire.reducers';
import * as fromCallCenterFill from '@/store/call-center/call-center.reducers';

import { ErrorReducer, ErrorState } from './error.store';
// import * as fromAuth from '../auth/store/auth.reducers';

export interface AppState {
  error: ErrorState,
  auth: fromAuthReducers.State,
  posts: fromPostReducers.PostsState;
  groups: fromGroupReducers.State,
  users: fromUsersReducers.State
  chats: fromChatReducers.State,
  messages: fromMessagesReducers.State,
  structure: fromStructureReducers.State,
  kb: fromKbeReducers.State,
  offers: fromOfferReducers.State,
  notification: fromNotificationReducers.State,
  search: fromSearchReducers.State,
  'search-tag': fromSearchTagReducers.State,
  prizes: fromPrizesReducers.State,
  orders: fromOrdeReducers.State,
  ideas: fromIdeasReducers.State,
  reservations: fromReservationsReducers.State
  roles: fromRolesReducers.State,
  scorm: fromScormReducers.State,
  multimedia: fromMultimediaReducers.State,
  training: fromTrainingReducers.State,
  'training-group': fromTrainingGroupReducers.State,
  'training-package': fromTrainingPackageReducers.State,
  'my-trainings': fromTrainingMyTrainingsReducers.State,
  quiz: fromTrainingQuizReducers.State,
  survey: fromSurveyReducers.State,
  'my-survey': fromMySurveyReducers.State,
  outpost: fromOutpostReducers.State,
  slot: fromSlotReducers.State,
  vacant: fromVacantReducers.State,
  'vacant-toconfirm': fromVacantToConfirmReducers.State,
  'offer-category': fromOfferCategoryReducers.State,
  'redmine-category': fromRedmineCategoryReducers.State,
  'redmine-issue': fromRedmineIssueReducers.State,
  'call-center-questionnaire': fromCallCenterQuestionnaire.State,
  'call-center-fill': fromCallCenterFill.State
}

export const reducers: ActionReducerMap<AppState> = {
  error: ErrorReducer,
  auth: fromAuthReducers.authReducer,
  posts: fromPostReducers.reducer,
  groups: fromGroupReducers.groupReducer,
  users: fromUsersReducers.reducer,
  chats: fromChatReducers.reducers,
  messages: fromMessagesReducers.reducer,
  structure: fromStructureReducers.reducer,
  kb: fromKbeReducers.reducer,
  offers: fromOfferReducers.reducer,
  notification: fromNotificationReducers.reducer,
  search: fromSearchReducers.reducer,
  'search-tag': fromSearchTagReducers.reducer,
  prizes: fromPrizesReducers.reducer,
  orders: fromOrdeReducers.reducer,
  ideas: fromIdeasReducers.reducer,
  reservations: fromReservationsReducers.reducer,
  roles: fromRolesReducers.reducer,
  scorm: fromScormReducers.reducer,
  multimedia: fromMultimediaReducers.reducer,
  training: fromTrainingReducers.reducer,
  'training-group': fromTrainingGroupReducers.reducer,
  'training-package': fromTrainingPackageReducers.reducer,
  'my-trainings': fromTrainingMyTrainingsReducers.reducer,
  quiz: fromTrainingQuizReducers.reducer,
  survey: fromSurveyReducers.reducer,
  'my-survey': fromMySurveyReducers.reducer,
  outpost: fromOutpostReducers.reducer,
  slot: fromSlotReducers.reducer,
  vacant: fromVacantReducers.reducer,
  'vacant-toconfirm': fromVacantToConfirmReducers.reducer,
  'offer-category': fromOfferCategoryReducers.reducer,
  'redmine-category': fromRedmineCategoryReducers.reducer,
  'redmine-issue': fromRedmineIssueReducers.reducer,
  'call-center-questionnaire': fromCallCenterQuestionnaire.reducer,
  'call-center-fill': fromCallCenterFill.reducer
};

import { createPaginationInitialState, Pagination } from "@/model/pagination.model";
import { Quiz, QuizQuestion } from "@/model/quiz.model";
import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import * as actions from "./quiz.actions";

export interface State extends Pagination<Quiz> {}

export const adapter: EntityAdapter<Quiz> = createEntityAdapter<Quiz>();
export const questionAdapter: EntityAdapter<QuizQuestion> = createEntityAdapter<QuizQuestion>();

export const initialState: State = createPaginationInitialState(adapter, false);

export const reducer = createReducer(
  initialState,
  on(actions.clear, (state) => initialState),
  on(actions.fetch, (state) => { return {...state, ...{loading: true}}}),
  on(actions.fetchOne, (state) => { return {...state, ...{loading: true}}}),
  on(actions.setPage, (state, action) => { return {...state, ...{page: action.page}}}),
  on(actions.setOrder, (state, action) => { return {...state, ...{order: action.order}}}),
  on(actions.setDirection, (state, action) => { return {...state, ...{asc: action.asc}}}),
  on(actions.setSearch, (state, action) => { return {...state, ...{search: action.search}}}),

  on(actions.setManyQuiz, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: action.total,
        list: adapter.addMany(action.list, initialState.list)
      }
    }
  }),
  on(actions.addManyQuiz, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: action.total,
        list: adapter.addMany(action.list, state.list)
      }
    }
  }),
  on(actions.addQuiz, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: state.total + 1,
        list: adapter.addOne(action.quiz, state.list)
      }
    }
  }),
  on(actions.setQuiz, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.updateOne({ id: action.quiz.id, changes: action.quiz}, state.list)
      }
    }
  }),
  on(actions.removeQuiz, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: state.total - 1,
        list: adapter.removeOne(action.id, state.list)
      }
    }
  }),

  on(actions.addQuizQuestion, (state, action) => {
    let quiz = state.list.entities[action.quiz.id];
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.updateOne({
          id: quiz.id,
          changes: {
            questions: questionAdapter.addOne(action.question, quiz.questions)
          }
        }, state.list)
      }
    }
  }),
  on(actions.removeQuizQuestion, (state, action) => {
    let quiz = state.list.entities[action.quiz.id];
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.updateOne({
          id: quiz.id,
          changes: {
            questions: questionAdapter.removeOne(action.question.id, quiz.questions)
          }
        }, state.list)
      }
    }
  }),
  on(actions.setQuizQuestion, (state, action) => {
    let quiz = state.list.entities[action.quiz.id];
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.updateOne({
          id: quiz.id,
          changes: {
            questions: questionAdapter.updateOne({
              id: action.question.id,
              changes: action.question
            }, quiz.questions)
          }
        }, state.list)
      }
    }
  }),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectState = createFeatureSelector<State>('quiz');
export const selectAllQuiz = createSelector(selectState, (state) => selectAll(state.list) )
export const selectOneQuiz = createSelector(selectState, (state: State, id) => state.list.entities[id]);

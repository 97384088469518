<section class="content">
  <div class="container">
      <div class="row">
          <div class="col-lg-12">
            <form [formGroup]="form" (ngSubmit)="save()">
            <div class="card card-primary">
              <div class="card-body">
                <div class="form-group">
                  <label for="inputName">Nazwa placówki</label>
                  <input
                    formControlName="name"
                    type="text"
                    class="form-control"
                    placeholder="Nazwa placówki"
                    [appIsValid]="form.controls.name"
                  />
                  <div class="invalid-feedback" *ngIf="form.controls.name.errors?.required">Pole jest wymagane</div>
                </div>
                <div class="form-group">
                  <label>Otwarcie placówki</label>
                  <nz-time-picker class="form-control" formControlName="startTime" nzFormat="HH:mm" [nzMinuteStep]="15"></nz-time-picker>
                </div>
                <div class="form-group">
                  <label>Zamknięcie placówki</label>
                  <nz-time-picker class="form-control" formControlName="endTime" nzFormat="HH:mm" [nzMinuteStep]="15"></nz-time-picker>
                </div>
                <div class="form-group position-relative">
                  <label>Kierownictwo</label>
                  <app-user-input formControlName="management" [multiple]="true" [appIsValid]="form.controls.management"></app-user-input>
                  <div class="text-danger" *ngIf="form.controls.management.errors?.required
                  && (form.controls.management.dirty || form.controls.management.touched)">Pole jest wymagane</div>
                </div>
              </div>
              <div class="card-footer">
                <button class="btn btn-success float-right" type="submit">
                  Dalej
                  <i class="fas fa-angle-right fa-fw fa-lg"></i>
                </button>
              </div>
            </div>
            </form>
          </div>
      </div>
  </div>
</section>

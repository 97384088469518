import { OfferCategory } from '@/model/offer.model';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ofType } from '@ngrx/effects';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fromOfferCategoryActions from '@/store/offer-category/offer.category.action';
import * as fromOfferCategoryReducers from '@/store/offer-category/offer.category.reducers';


@Component({
  selector: 'app-edit-offer-category',
  templateUrl: './edit-offer-category.component.html',
  styleUrls: ['./edit-offer-category.component.scss']
})
export class EditOfferCategoryComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  category: OfferCategory;
  constructor(
    private toastr: ToastrService,
    private store: Store<fromOfferCategoryReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data) => {
      console.log(data);
      this.activatedRoute.params.subscribe((params) => {
        let id = parseInt(params.id);
        this.store.pipe(
          takeUntil(this.unsubscribe$),
          select(fromOfferCategoryReducers.selectOfferCategory, id)
        ).subscribe((category) => {
          this.category = category;
          this.form.patchValue(category);
        });
      })
    })

  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  save(): void {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    if (this.form.valid) {
      this.category = {
        ...this.category,
        ...this.form.value
      };
      this.store.dispatch(fromOfferCategoryActions.updateOfferCategory({item: this.category}));
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromOfferCategoryActions.updateOne.type)
      ).subscribe( (action: any) => {
        this.toastr.success('Zapisano zmiany');
        this.router.navigate(['/offer/admin/category']);
      });
  } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
  }
  }

}

import { AuthService } from "@/auth/auth.service";
import { AppNotification } from "@/model/notification.model";
import { StringifyHttpErrorResponse } from "@/utils/http.error.util";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ToastrService } from "ngx-toastr";
import { catchError, map, switchMap } from "rxjs/operators";
import * as fromActions from './notification.actions'

@Injectable()
export class NotificationEffects {
  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }

  onError (err, caught): any {
    if(err.status === 401) {
      this.authService.signOut();
    }
    this.toastr.error(StringifyHttpErrorResponse(err));
    return caught;
  };


  fetch$ = createEffect(() => {
    return  this.actions$.pipe(
      ofType(fromActions.fetch),
      switchMap((action) => {
        return this.httpClient.get<AppNotification[]>('<backendhost>/v1/notification', {observe: 'body',responseType: 'json'});
      }),
      catchError(this.onError.bind(this)),
      switchMap( (notifications: AppNotification[]) => {
        let actionsToDistpatch = [];
        actionsToDistpatch.push(fromActions.set({notifications}))
        return actionsToDistpatch;
      })
    );
  });

  deleteNotification$ = createEffect(() => {
    return  this.actions$.pipe(
      ofType(fromActions.deleteNotification),
      switchMap((action) => {
        return this.httpClient.delete<AppNotification[]>(
          `<backendhost>/v1/notification/${action.notification.id}`,
          {observe: 'body',responseType: 'json'})
            .pipe(map(() => action.notification));
      }),
      catchError(this.onError.bind(this)),
      switchMap( (notification: AppNotification) => {
        let actionsToDistpatch = [];
        actionsToDistpatch.push(fromActions.remove({notification}))
        return actionsToDistpatch;
      })
    );
  });
}

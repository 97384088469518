
<ng-template #rt let-r="result" let-t="term">
  <div class="d-flex flex-md-row">
  <div class="user-panel mr-2">
    <img [src]="r | userAvatar" class="img-circle" [appUserDisactivatable]="r">
  </div>
  <ngb-highlight class="align-self-center" [result]="formatter(r)" [term]="t"></ngb-highlight>
  </div>
</ng-template>

<section class="content">
  <div class="container pt-3 pb-3">
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-secondary">
          <div class="card-header">
            <h3 class="card-title">Ustawienia pokoju</h3>
          </div>

            <div class="card-body">
              <div class="row">
                <div class="col-lg-8">
                  <div class="form-group">
                    <form [formGroup]="form" (ngSubmit)="save()">
                    <label for="inputName">Nazwa</label>
                    <div class="input-group">
                      <input formControlName="name" type="text" class="form-control" placeholder="Nazwa grupy"/>
                      <div class="input-group-append">
                        <button type="submit" class="btn btn-success">Zapisz</button>
                      </div>
                    </div>

                    </form>
                  </div>
                  <div class="form-group">
                    <label for="typeahead-prevent-manual-entry">Dodaj użytkownika do pokoju</label>
                    <input id="typeahead-prevent-manual-entry"  type="text" class="form-control" placeholder="Zacznij wpisywać"
                          [(ngModel)]="model"
                          [ngbTypeahead]="search"
                          [resultTemplate]="rt"
                          [inputFormatter]="formatterEmpty"
                          [editable]='false'
                          (selectItem)="onUserSelect($event)"/>
                  </div>
                  <div class="list-group">
                    <div class="list-group-item user-panel pt-2 pb-2 d-flex align-items-center" *ngFor="let item of membersFull">
                      <img [src]="item | userAvatar" class="img-circle elevation-2" alt="User Image" [appUserDisactivatable]="item"/>
                      <div class="info flex-grow-1">
                        <strong [appUserDisactivatable]="item">{{ item.name }}</strong> <br/>
                        <span [appUserDisactivatable]="item">{{ item?.active ? item?.email : '-' }}</span>
                      </div>
                      <div class="p-3" *ngIf="item.id === conversation.createdBy?.id">
                        <i class="fas fa-user-shield"></i> Administrator
                      </div>
                      <button class="btn btn-danger btn-sm p-1 elevation-2" (click)="deleteMember(item)" [disabled]="item.id === conversation.createdBy?.id">
                        <i class="fas fa-trash fa-fw fa-lg"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="inputName">Avatar pokoju</label>
                    <div style="max-width: 250px;">
                      <app-upload-crop-image  *ngIf="conversation"
                        [avatar]="true"
                        [aspectRatio]="1 / 1"
                        [image]="conversation | groupAvatar:250:false"
                        url="<backendhost>/v1/chat/room/{{conversation.id}}/upload"
                        (nzChange)="handleImageUploadChange($event)"
                      ></app-upload-crop-image>
                      <!-- <nz-upload *ngIf="conversation"
                        class="avatar-uploader w-100"
                        nzAction="<backendhost>/v1/chat/room/{{conversation.id}}/upload"
                        nzName="image"
                        nzListType="picture-card"
                        [nzShowUploadList]="false"
                        (nzChange)="handleImageUploadChange($event)"
                      >
                        <ng-container *ngIf="!conversation.image" class="w-100">
                          <i class="fas fa-3x" [ngClass]="{
                            'fa-upload' : !uploading,
                            'fa-spinner fa-spin' : uploading
                          }"></i>
                          <div class="ant-upload-text">Upload</div>
                        </ng-container>
                        <img *ngIf="conversation.image" [src]="conversation | groupAvatar:250:false" style="width: 100%" />
                      </nz-upload> -->
                    </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <a class="btn btn-primary btn-block" [routerLink]="['/chat', conversation.id]">
                <i class="fas fa-comments fa-fw fa-lg"></i>
                Wróć do pokoju
              </a>
            </div>
        </div>
        <!-- <app-edit-group-members [group]="group" *ngIf="group"></app-edit-group-members> -->
      </div>
    </div>
  </div>
</section>





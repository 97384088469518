<div class="card" *ngIf="folder | async">
  <div class="card-footer mt-0 pt-3">
    <h3 class="card-title">Artykuły</h3>
  </div>
  <div class="table-responsive">
    <table class="table align-middle table-hover">
      <thead>
        <tr>
          <th style="width:1rem">#</th>
          <th style="width:1rem"></th>
          <th>Tytuł</th>
          <th>Utworzono</th>
          <th colspan=2>Przez</th>
          <th class="text-right" style="width:2rem">
            <button class="btn btn-success btn-sm elevation-2 text-nowrap" [routerLink]="['add']" checkRolePermition="kb.admin.articles.create" >
              <i class="fas fa-plus fa-fw fa-lg"></i> Dodaj
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of articles | async; let idx = index; trackBy: identify" >
          <td class="align-middle">{{idx + 1}}</td>
          <td class="align-middle">
            <img class="img-circle" [src]="item | groupAvatar:45:true:'title':'image'"/>
          </td>
          <td class="align-middle">{{item.title}}</td>
          <td class="align-middle">{{item.createdAt | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
          <td class="user-panel" style="width:1px">
            <img
                  [src]="item.createdBy  | userAvatar: 34"
                  class="img-circle elevation-2"
                  alt="User Image"
                  [appUserDisactivatable]="item.createdBy"
              />
          </td>
          <td>
            <span class="text-decoration-line-through" *ngIf="!item?.createdBy">Użytkownik usunięty</span>
            <strong [appUserDisactivatable]="item.createdBy">{{item.createdBy?.firstname}} {{item.createdBy?.lastname}}</strong> <br>
            <small [appUserDisactivatable]="item.createdBy">{{ item.createdBy?.active ? item.createdBy?.email : '-' }}</small>
          </td>
          <td class="align-middle text-right text-nowrap">
            <button class="btn btn-primary btn-sm elevation-2 mr-2" [routerLink]="[item.id, 'edit']" ngbTooltip="Edycja"  checkRolePermition="kb.admin.articles.edit">
              <i class="fas fa-edit fa-fw fa-lg"></i>
            </button>
            <a
                checkRolePermition="kb.admin.articles.delete"
                class="btn btn-danger btn-sm elevation-2"
                nz-popconfirm
                nzPopconfirmTitle="Czy na pewno usunąć?"
                nzOkText="Tak"
                nzCancelText="Nie"
                nzPopconfirmPlacement="left"
                ngbTooltip="Usuń"
                (nzOnConfirm)="delete(item)" >
              <i class="fas fa-trash fa-fw fa-lg"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>

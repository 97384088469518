<section class="content">
  <div class="container-xl pt-3 pb-3">
    <div class="card">
      <div class="overlay" *ngIf="(loading | async)">
        <div style="position: sticky; text-align: center">
          <i class="fas fa-3x fa-spinner fa-spin"></i>
          <p>Ładowanie...</p>
        </div>
      </div>
      <div class="card-header d-flex align-items-center">
        <h3 class="card-title m-0 flex-grow-1">Formularze zamówień</h3>
        <div class="card-tools flex-grow-1">
          <div class="input-group">
            <input type="text" name="table_search" class="form-control float-right" placeholder="Szukaj" #searchInput>
            <button class="btn bg-transparent" style="position:absolute; right: 0; transform: translate(-100%,0);" (click)="clearSearch()">
              <i class="fa fa-times"></i>
            </button>
            <span class="input-group-append">
              <div class="input-group-text bg-transparent"><i class="fas fa-search"></i></div>
            </span>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table align-middle table-hover">
          <thead>
            <tr>
              <th style="width:1rem">#</th>
              <th style="width:1rem"></th>
              <th>Nazwa</th>
              <th>Opis</th>
              <th>Utworzono</th>
              <th colspan=2>Przez</th>
              <th class="text-right" >
                <button  checkRolePermition="orders.create" class="btn btn-success btn-sm elevation-2 text-nowrap" [routerLink]="['/orders/admin', 'add']" >
                  <i class="fas fa-plus fa-fw fa-lg"></i> Dodaj
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of paginator | async; let idx = index; trackBy: identify">
              <th class="align-middle">{{idx + 1 + pageSize * (page - 1)}}</th>
              <th class="align-middle"><img class="img-circle" [src]="item | groupAvatar:55"/></th>
              <th class="align-middle">{{item.title}}</th>
              <td class="align-middle text-sm">{{item.short}}...</td>
              <td class="align-middle text-nowrap">{{item.createdAt | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
              <td class="align-middle pr-1 text-right" style="width: 3rem">
                <img class="img-circle" [src]="item.createdBy | userAvatar:25" [appUserDisactivatable]="item.createdBy"/>
              </td>
              <td class="align-middle pl-0 text-nowrap">
                <span class="text-decoration-line-through" *ngIf="!item?.createdBy">Użytkownik usunięty</span>
                <span [appUserDisactivatable]="item.createdBy">{{item?.createdBy?.name}}</span>
              </td>
              <td class="align-middle text-nowrap text-right" >
                <a class="btn btn-primary btn-sm elevation-2 mr-2" [routerLink]="['/orders/admin', item.id]" ngbTooltip="Lista zamówień" checkRolePermition="orders.edit">
                  <i class="fas fa-list fa-fw fa-lg"></i>
                </a>
                <a class="btn btn-primary btn-sm elevation-2 mr-2" [routerLink]="['/orders/admin', item.id, 'edit']" ngbTooltip="Edycja" checkRolePermition="orders.edit">
                  <i class="fas fa-edit fa-fw fa-lg"></i>
                </a>
                <a
                    checkRolePermition="orders.delete"
                    class="btn btn-danger btn-sm elevation-2"
                    nz-popconfirm
                    nzPopconfirmTitle="Czy na pewno usunąć?"
                    nzOkText="Tak"
                    nzCancelText="Nie"
                    nzPopconfirmPlacement="left"
                    ngbTooltip="Usuń"
                    (nzOnConfirm)="delete(item)" >
                  <i class="fas fa-trash fa-fw fa-lg"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(paginator | async).length == 0"></nz-empty>
      <div class="card-footer" >
        <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="total | async" (pageChange)="pageChange($event)">
          <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
</section>

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';

import * as fromTrainingPackageiaActions from '@/store/package/package.actions';
import * as fromTrainingPackageReducers from '@/store/package/package.reducers';
import * as fromTrainingActions from '@/store/training/training.actions';
import * as fromTrainingReducers from '@/store/training/training.reducers';
import * as fromTrainingGroupReducers from '@/store/training-group/training-group.reducers';


import { ActionsSubject, select, Store } from '@ngrx/store';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TrainingPackage } from '@/model/package.model';
import { ofType } from '@ngrx/effects';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Training } from '@/model/scorm.model';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { AppService } from '@services/app.service';

@Component({
  selector: 'app-package-add',
  templateUrl: './package-add.component.html',
  styleUrls: ['./package-add.component.scss']
})
export class PackageAddComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  @ViewChild('instanceTraining', {static: true}) instanceTraining: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  focusGroup$ = new Subject<string>();
  clickGroup$ = new Subject<string>();

  focusUserGroup$ = new Subject<string>();
  clickUserGroup$ = new Subject<string>();

  form: FormGroup;
  package: TrainingPackage;

  backgroundFile: NzUploadFile;

  typeaheadUserGroupModel: any;
  typeaheadGroupModel: any;
  typeaheadTrainingModel: any;


  formatter = (item: any) => `${item.name}`;
  formatterEmpty = () => null;

  constructor(
    private toastr: ToastrService,
    private store: Store<fromTrainingPackageReducers.State>,
    private storeTraining: Store<fromTrainingReducers.State>,
    private storeTrainingGroup: Store<fromTrainingGroupReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appService: AppService
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      content: new FormControl(null, Validators.required),
      open: new FormControl(false),
      certificate: new FormControl(true),
      trainings: new FormArray([], [Validators.required]),

      // endDate: new FormControl(new Date(),[Validators.required, this.DateTimeValidator.bind(this)]),
      // groups: new FormArray([], []),
      // userGroups: new FormArray([], []),
    });
  }

  ngOnInit(): void {
    this.storeTraining.dispatch(fromTrainingActions.clear());
    this.storeTraining.dispatch(fromTrainingActions.fetch());

    this.package = {
      ...this.package,
      ...{
        // startDate : moment().format('YYYY-MM-DD 00:00:00'),
        // endDate : moment().add(1, 'M').add(1, 'd').format('YYYY-MM-DD  00:00:00'),
        groups: [],
        userGroups: [],
        trainings: []
      }
    };
    this.form.patchValue(this.package);
    // this.form.controls.groups.disable();


    this.activatedRoute.data.subscribe( (data: Data) => {
      if (!data.instance) {
        return;
      }
      this.package = {
        ...this.package,
        ...data.instance
      };
      if (this.package.archive) {
        this.router.navigate(['/training/admin/package']);
        return;
      }
      this.form.patchValue(this.package);
      this.package.trainings.map( training => {
        this.trainingCtrl.push(new FormControl(training.id))
      });

      this.store.pipe(
        select(fromTrainingPackageReducers.selectOneTrainingPackage, this.package.id),
        takeUntil(this.unsubscribe$),
        distinctUntilChanged()
      ).subscribe((item) => {
        this.package = item;
      });
    });
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  save(): void {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    if (this.form.valid) {
        let trainingPackage = {
          ...this.package,
          ...this.form.value,
           ...{
            // startDate : this.form.value.startDate.toLocaleString('pl-PL'),
            // endDate : this.form.value.endDate.toLocaleString('pl-PL')
          }
        };


        if (trainingPackage.id) {
          this.store.dispatch(fromTrainingPackageiaActions.saveTrainingPackage({item: trainingPackage, background: this.backgroundFile as any}));
        } else {
          this.store.dispatch(fromTrainingPackageiaActions.createTrainingPackage({item: trainingPackage, background: this.backgroundFile as any}));
        }
        this.actionsSubject$.pipe(
          takeUntil(this.unsubscribe$),
          ofType(fromTrainingPackageiaActions.addTrainingPackage.type)
        ).subscribe( (result: any) => {
          this.toastr.success('Utworzono nowe szkolenie');
          this.router.navigate([`/training/admin/package/${result.item.id}/to-invitate`]);
        });

        this.actionsSubject$.pipe(
          takeUntil(this.unsubscribe$),
          ofType(fromTrainingPackageiaActions.updateTrainingPackage.type)
        ).subscribe( (result: any) => {
          this.toastr.success('Zapisano zmiany');
          this.router.navigate([`/training/admin/package/`]);
        });
    } else {
        this.toastr.error('Formularz jest nieprawidłowy!');
        console.log(this.form.errors);
        console.log(this.form.controls);
    }
  }




  searchTraining: OperatorFunction<string, readonly Training[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(takeUntil(this.unsubscribe$), debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(takeUntil(this.unsubscribe$), filter(() => !this.instanceTraining.isPopupOpen()));
    const inputFocus$ = this.focus$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(300),
      distinctUntilChanged(),
      tap(search => this.storeTraining.dispatch(fromTrainingActions.setSearch({search}))),
      switchMap(term => this.storeTraining.pipe(select(fromTrainingReducers.selectAllTraining))),
      map(items => items.filter(item => this.package.trainings.map(i => i.id).indexOf(item.id) == -1))
    );
  }
  onTrainingTypeaheadSelect(selectedItem: NgbTypeaheadSelectItemEvent) {
    let training: Training = selectedItem.item;
    this.package.trainings = [...this.package.trainings, ...[training]];
    this.trainingCtrl.push(new FormControl(training.id))
  }
  removeTraining(item: Training) {
    let idx = this.package.trainings.map(it => it.id).indexOf(item.id);
    let trainings = [...this.package.trainings];
    trainings.splice(idx, 1);
    this.package.trainings = trainings;
    this.trainingCtrl.removeAt(idx);
  }
  get trainingCtrl(): FormArray {
    return this.form.get('trainings') as FormArray;
  }

  identify(index, entity: any){ return entity.id }
}

import { AuthService } from '@/auth/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RolePermitionGuardGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  async check(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise( async (resolve, reject) => {
      this.authService.checkPermition(route.data.permition, "skip").then( (alowed:boolean) => {
        if (alowed) {
          resolve(true);
        } else {
          resolve(false);
          this.toastr.error("Nie masz uprawnień do tej sekcji!");
        }
      });
    })
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
      | Observable<boolean | UrlTree>
      | Promise<boolean | UrlTree>
      | boolean
      | UrlTree
  {
    return this.check(next);
  }

  // canActivateChild(
  //     next: ActivatedRouteSnapshot,
  //     state: RouterStateSnapshot
  // ):
  //     | Observable<boolean | UrlTree>
  //     | Promise<boolean | UrlTree>
  //     | boolean
  //     | UrlTree {
  //     return this.canActivate(next, state);
  // }

  // canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   console.log("TEST");
  //   return true;
  // }

}

<section class="content">
  <div class="container">
    <app-package-tab></app-package-tab>
    <div class="card" style="border-top-left-radius: 0;border-top-right-radius: 0;">
      <div class="card-header d-flex align-items-center">
        <h3 class="card-title m-0 flex-grow-1 text-bold"><i class="fas fa-users fa-fw"></i> Lista zaproszonych</h3>
        <div class="card-tools flex-grow-1">
          <div class="input-group">
            <input type="text" name="table_search" class="form-control float-right" placeholder="Szukaj" #searchInput>
            <button class="btn bg-transparent" style="position:absolute; right: 0; transform: translate(-100%,0);" (click)="clearSearch()">
              <i class="fa fa-times"></i>
            </button>
            <span class="input-group-append">
              <div class="input-group-text bg-transparent"><i class="fas fa-search"></i></div>
            </span>
          </div>
        </div>
      </div>
      <div class="table-responsive position-relative">
        <table class="table align-middle table-hover">
          <thead>
            <tr>
              <th style="width:1rem">#</th>
              <th></th>
              <th>Imię</th>
              <th>Nazwisko</th>
              <th>Email</th>
              <th>Data zaproszenia</th>
              <th>Termin zakończenia</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let item of (pagination | async).list; let idx = index; trackBy: identify">
              <th class="align-middle">{{idx + 1 + pageSize*(page-1)}}</th>
              <td class="user-panel" style="width:70px">
                <img
                      [src]="item.user  | userAvatar: 34"
                      class="img-circle elevation-2"
                      alt="User Image"
                      style="width:45px"
                      [appUserDisactivatable]="item.user"
                  />
              </td>
              <td class="align-middle">
                <span [appUserDisactivatable]="item.user">{{item.firstname}}</span>
              </td>
              <td class="align-middle">
                <span [appUserDisactivatable]="item.user">{{item.lastname}}</span>
              </td>
              <td class="align-middle">
                <span [appUserDisactivatable]="item.user">{{item.email}}</span>
              </td>
              <td class="align-middle">{{item.createdAt | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
              <td class="align-middle">{{item.invitePack.endDate | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(pagination | async).total == 0"></nz-empty>
        <div class="overlay" *ngIf="(pagination | async).loading" style="position: absolute; top:0; left: 0; bottom: 0; right: 0">
          <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
            <i class="fas fa-3x fa-spinner fa-spin"></i>
            <p>Ładowanie...</p>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="(pagination | async).total"  (pageChange)="pageChange($event)" class="float-left">
          <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
</section>

import { Slide } from '@/model/multimedia.model';
import { TrainingResult } from '@/model/package.model';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { environment } from 'environments/environment';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  @Input() slide: Slide;
  @Input() result: TrainingResult;
  @Output() onFinish: EventEmitter<TrainingResult> = new EventEmitter<TrainingResult>();

  constructor() {}

  ngOnInit(): void {
    timer(this.slide.completeTimeout * 1000).pipe(takeUntil(this.unsubscribe$)).subscribe(this.finish.bind(this));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  finish() {
    this.result = {
      ...this.result,
      ... {
        completed: true,
        completedOn: this.result.completedOn ? this.result.completedOn : new Date(),
        passed: true
      }
    }
    this.onFinish.emit(this.result);
  }

  onStateChange(event) {
    // console.log("player state", event.data);
  }

  get path() {
    return `${environment.apiUrl}/v1/video/${this.slide.video.name}`;
  }

}

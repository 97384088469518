<li class="nav-item dropdown ml-2">
  <a class="nav-link position-relative elevation-2 img-circle img-size-40 bg-white text-center" data-toggle="dropdown" (click)="toggleDropdownMenu()">
      <i class="fas fa-bug fa-lg"></i>
      <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-orange" *ngIf="count | async">{{count | async}}</span>
  </a>
  <ul #dropdownMenu class="dropdown-menu dropdown-menu-lg dropdown-menu-right">

    <a [routerLink]="['/redmine']" class="dropdown-item dropdown-footer text-center text-dark">Wszystkie przypisane do mnie</a>
    <div class="dropdown-divider"></div>
    <li class="dropdown-menu-item" *ngFor="let item of list | async; trackBy: identify">
      <a class="dropdown-item  position-relative"  (click)="link(item)">
        <div class="media">
          <div class="ribbon-wrapper ribbon-sm" *ngIf="hasNotification(item) | async">
            <div class="ribbon bg-orange text-sm">
              UPDATE
            </div>
          </div>
          <div class="media-body" style="overflow: hidden;">
              <h3 class="dropdown-item-title text-primary" style="overflow: hidden; text-overflow: ellipsis;">
                {{item.subject}}
              </h3>
              <!-- <p class="text-md text-truncate text-dark float-left"> {{ type(item) }}</p> -->
              <p class="text-sm text-muted text-nowrap float-right">
                  <i class="far fa-clock mr-1"></i> {{item.updated_on | amLocale:'pl' | amCalendar}}
              </p>
          </div>
        </div>
      </a>
    </li>
    <li class="dropdown-menu-item" *ngIf="(list | async).length == 0">
      <p  class="dropdown-item"><nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Brak zgłoszeń'"></nz-empty></p>
    </li>
    <div class="dropdown-divider"></div>
    <a [routerLink]="['/redmine']" class="dropdown-item dropdown-footer text-center text-dark">Wszystkie przypisane do mnie</a>
  </ul>
</li>


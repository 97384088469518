<div class="card" *ngIf="folder | async">
  <div class="card-footer mt-0 pt-3" checkRolePermition="kb.admin.files.create">
    <h3 class="card-title">Załaduj pliki</h3>
  </div>
  <div class="card-body">
    <nz-upload  checkRolePermition="kb.admin.files.create"
      nzType="drag"
      [nzMultiple]="true"
      nzAction="<backendhost>/v1/kb/folder/{{(folder | async).id}}/upload"
        (nzChange)="handleUploadChange($event)"
    >
    <p class="ant-upload-drag-icon">
      <i nz-icon nzType="inbox"></i>
    </p>
    <p class="ant-upload-text">Kliknij lub przeciągnij plik do tego obszaru, aby przesłać</p>
    <p class="ant-upload-hint"></p>
    </nz-upload>
  </div>
  <div class="table-responsive">
    <table class="table align-middle table-hover">
      <thead>
        <tr>
          <th style="width:1rem">#</th>
          <th style="width:1rem"></th>
          <th>Nazwa pliku / Tytuł pliku</th>
          <th>Opis</th>
          <th>Tagi</th>
          <th>Utworzono / Rozmiar</th>
          <th class="text-right" ></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let file of files | async; let idx = index; trackBy: identify" >
          <td class="align-middle">{{idx + 1}}</td>
          <td class="align-middle">
            <span class="btn btn-circle btn-default img-circle" style="width:55px; height: 55px; line-height: 2.5rem;" *ngIf='!isImage(file)'>
              <i class="fas fa-file fa-lg" [class]="file.icon"></i>
            </span>
            <img class="img-circle" [src]="file | groupAvatar:55:true:'name':'path'"  *ngIf='isImage(file)'/>
          </td>
          <td class="align-middle" style="width: 30%;">
            <input type="text" class="form-control" readonly [value]="file.name"/>
            <input type="text"
              checkRolePermition="kb.admin.files.edit"
              class="form-control"
              [(ngModel)]="file.title"
              [ngModelOptions]="{ updateOn: 'blur' }"
              (ngModelChange)="updateFile(file)">
          </td>
          <td class="align-middle" style="width: 30%;">
            <textarea type="text"
              checkRolePermition="kb.admin.files.edit"
              class="form-control"
              rows="3"
              [(ngModel)]="file.description"
              [ngModelOptions]="{ updateOn: 'blur' }"
              (ngModelChange)="updateFile(file)"></textarea>
          </td>
          <td class="align-middle" style="width: 30%;">
            <tag-input
              checkRolePermition="kb.admin.files.edit"
              [(ngModel)]='file.tags'
              [ngModelOptions]="{ updateOn: 'blur' }"
              (ngModelChange)="updateFile(file)"
              theme='bootstrap'
              secondaryPlaceholder="Wpisz nowy tag"></tag-input>
          </td>
          <td class="align-middle">
            <p class="text-nowrap">{{file.createdAt | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</p>
            <p class="text-nowrap">{{file.size | fileSize}}</p>
          </td>
          <td class="align-middle text-right">
            <a
                checkRolePermition="kb.admin.files.delete"
                class="btn btn-danger btn-sm elevation-2"
                nz-popconfirm
                nzPopconfirmTitle="Czy na pewno usunąć?"
                nzOkText="Tak"
                nzCancelText="Nie"
                nzPopconfirmPlacement="left"
                ngbTooltip="Usuń"
                (nzOnConfirm)="delete(file)" >
              <i class="fas fa-trash fa-fw fa-lg"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>

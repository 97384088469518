import {Component} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { BreadcrumbsService } from '@services/breadcrumbs.service';
import { MessagingService } from '@services/messaging.service';
import { UpdateService } from '@services/update.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { combineLatest, Observable, OperatorFunction } from 'rxjs';
import { filter, map, merge } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { User } from '@/model/user.model';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private sw: UpdateService,
    private router: Router,
    private titleService: Title,
    protected $gaService: GoogleAnalyticsService,
    private breadcrumbsService: BreadcrumbsService,
    private authService: AuthService,
  ) {
    this.sw.checkForUpdates();
  }

  ngOnInit() {
    combineLatest(
      this.authService.getUser(),
      this.breadcrumbsService.breadcrumbs$.pipe(
        filter((breadcrumbs) => breadcrumbs.length > 0),
        map((breadcrumbs) => {
          return {
            url: [...breadcrumbs].pop().url,
            title: breadcrumbs.slice(1).map(b => b.label).reduce((a,b) => `${a} / ${b}`, 'Home'),
          }
        })
      )
    ).subscribe(([user,page]: [User,{url: string, title: string}]) => {
        this.$gaService.pageView(page.url, page.title, undefined, {
          user_id: user.id
        })
    })
  }
}

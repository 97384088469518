import { Outpost } from '@/model/outpost.model';
import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Subject, noop, Observable } from 'rxjs';
import { distinctUntilChanged, debounceTime, takeUntil, map } from 'rxjs/operators';

import * as fromUserActions from '@/store/users/users.actions';
import * as fromUserReducers from '@/store/users/users.reducers';
import { User } from '@/model/user.model';

@Component({
  selector: 'app-user-input',
  templateUrl: './user-input.component.html',
  styleUrls: ['./user-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserInputComponent),
      multi: true
    }
  ]
})
export class UserInputComponent implements OnInit, ControlValueAccessor {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  @Input('multiple')
  multiple: boolean = false;
  @Input()
  disabled = false;

  ngControl: NgControl;
  user: User;

  private onTouched: () => void = noop;
  private onChange: (_: any) => void = noop;

  constructor(
    private store: Store<fromUserReducers.State>,
    private inj: Injector
  ) { }
  writeValue(obj: any): void {
    this.user = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.ngControl = this.inj.get(NgControl);
    this.store.dispatch(fromUserActions.clearFilters());
    this.store.dispatch(fromUserActions.fetchUsers({}));

    this.userInput$
        .pipe(distinctUntilChanged(),debounceTime(500))
        .subscribe((search) => this.store.dispatch(fromUserActions.setSearch({search})));
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  onUserChange(value) {
    value = this.multiple
      ? value//.map(item => item.id)
      : (value ? value.id : null);
    this.onChange(value);
  }

  get usersLoading(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromUserReducers.selectUsersState),
      map( (state:fromUserReducers.State)  => state.loading),
    );
  }

  get users$(): Observable<User[]> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromUserReducers.selectAllUsers));
  }

  userInput$ = new Subject<string>();

  trackByFn(item: any) {
    return item.id;
  }


}

import { AuthService } from "@/auth/auth.service";
import { Group, GroupMember } from "@/model/group.model";
import { User } from "@/model/user.model";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { catchError, map, switchMap } from "rxjs/operators";

import * as fromActions from "./group.actions"
import * as fromReducers from "./group.reducers"

@Injectable()
export class GroupEffects {
  private user: User;

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private authService: AuthService
  ) {
    this.authService.getUser().then(user => {this.user = user});
  }

  onError (err, caught): any {
    this.toastr.error(err.message)
    return caught;
  };

  createNewGroup$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createNewGroupAction),
    switchMap((action) => {
      return this.httpClient.post<Group>('<backendhost>/v1/group', {name: action.name, superior: action.superior},{
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    map((response: any) => fromActions.addGroupsAction({payload: [response]}))
  ));

  deleteGroup$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deleteGroupAction),
    switchMap((action) => {
      return this.httpClient.delete<Group>(`<backendhost>/v1/group/${action.group.id}`,{
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    map((response: any) => fromActions.removeGroupAction({group: response}))
  ));

  fetchOneGroup$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchOneAction),
    switchMap((action) => {
      return this.httpClient.get<Group>(`<backendhost>/v1/group/${action.id}`,{
        // observe: 'body',
        // responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    map((response: any) => fromActions.addGroupsAction({payload: [response]}))
  ));

  fetchAllGroups$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchAllAction),
    switchMap((action) => {
      return this.httpClient.get<Group[]>(`<backendhost>/v1/group`,{
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    map((response: any) => fromActions.addGroupsAction({payload: response}))
  ));

  fetchFullGroups$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchFullAction),
    switchMap((action) => {
      let params= new HttpParams();
      params = params.set('page', action.page);
      params = params.set('search', action.search);
      return this.httpClient.get<{list:Group[], total: number}>(`<backendhost>/v1/group/0/full`,{
        params,
        observe: 'body',
        responseType: 'json'
      }).pipe(map(response => {
        return {
          ...response,
          ...{page: action.page}
        }
      }));
    }),
    catchError(this.onError.bind(this)),
    map((response: any) => fromActions.addGroupsFullAction({payload: response.list, total: response.total, page: response.page}))
  ));

  editGroup$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.editGroupAction),
    switchMap((action) => {
      console.log(action);
      return this.httpClient.put<Group>(`<backendhost>/v1/group/${action.group.id}`, action.group,{
        observe: 'body',
        responseType: 'json'
      })
    }),
    catchError(this.onError.bind(this)),
    map((response: Group) => fromActions.updateGroupAction({group: response}))
  ));

  createNewGroupMember$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createNewGroupMemberAction),
    switchMap((action) => {
      return this.httpClient.post<GroupMember>(`<backendhost>/v1/group/${action.group.id}/member`, action.user,{
        observe: 'body',
        responseType: 'json'
      }).pipe(
        map(member => { return {member: member, group: action.group}})
      );
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: {member: GroupMember, group: Group}) => {
      let actionsToDispatch = [];
      actionsToDispatch.push(fromActions.addMemberToGroupAction(response));
      actionsToDispatch.push(fromActions.increseGroupMemberCountAction(response));

      if (response.member.user.id == this.user.id) {
        actionsToDispatch.push(fromActions.updateMyGroupMembershipAction(response));
      }
      return actionsToDispatch;
    })
  ));

  joinGroup$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.joinGroup),
    switchMap((action) => {
      return this.httpClient.post<GroupMember>(`<backendhost>/v1/group/${action.group.id}/member`, this.user,{
        observe: 'body',
        responseType: 'json'
      }).pipe(
        map(member => { return {member: member, group: action.group}})
      );
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: {member: GroupMember, group: Group}) => {
      let actionsToDispatch = [];
      actionsToDispatch.push(fromActions.addMemberToGroupAction(response));
      actionsToDispatch.push(fromActions.increseGroupMemberCountAction(response));

      if (response.member.user.id == this.user.id) {
        actionsToDispatch.push(fromActions.updateMyGroupMembershipAction(response));
      }
      return actionsToDispatch;
    })
  ));

  fetchGroupMembers$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchGroupMembersAction),
    switchMap((action) => {
      console.log(action.group);
      return this.httpClient.get<GroupMember[]>(`<backendhost>/v1/group/${action.group.id}/member`,{
        observe: 'body',
        responseType: 'json'
      }).pipe(
        map(members => { return {members: members, group: action.group}})
      );
    }),
    catchError(this.onError.bind(this)),
    map((response: {members: GroupMember[], group: Group}) => fromActions.addManyMemberToGroupAction(response))
  ));

  deleteGroupMember$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deleteGroupMemberAction),
    switchMap((action) => {
      return this.httpClient.delete<GroupMember>(`<backendhost>/v1/group/${action.group.id}/member/${action.member.id}`,{
        observe: 'body',
        responseType: 'json'
      }).pipe(
        map(member => { return {member: member, group: action.group}})
      );
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: {member: GroupMember, group: Group}) => {
      let actionsToDispatch = [];
      actionsToDispatch.push(fromActions.removeGroupMemberAction(response));
      actionsToDispatch.push(fromActions.decreseGroupMemberCountAction(response));
      if (response.member.user.id == this.user.id) {
        actionsToDispatch.push(fromActions.updateMyGroupMembershipAction({group: response.group, member: null}));
      }
      return actionsToDispatch;
    })
  ));

  editGroupMember$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.editGroupMemberAction),
    switchMap((action) => {
      return this.httpClient.put<GroupMember>(`<backendhost>/v1/group/${action.group.id}/member/${action.member.id}`, action.member, {
        observe: 'body',
        responseType: 'json'
      }).pipe(
        map(member => { return {member: member, group: action.group}})
      );
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: {member: GroupMember, group: Group}) => {
      let actionsToDispatch = [];
      actionsToDispatch.push(fromActions.updateGroupMemberAction(response));
      if (response.member.user.id == this.user.id) {
        actionsToDispatch.push(fromActions.updateMyGroupMembershipAction(response));
      }
      return actionsToDispatch;
    })
  ));

  observeGroup$= createEffect(() => this.actions$.pipe(
    ofType(fromActions.observeGroupAction),
    switchMap((action) => {
      let actionsToDispatch = [];
      let member = Object.assign({}, action.member);
      member.observing = action.observe;
      actionsToDispatch.push(fromActions.editGroupMemberAction({group: action.group, member: member}));
      if (action.observe) {
        actionsToDispatch.push(fromActions.increseGroupObserverCountAction({group: action.group}));
      }
      else {
        actionsToDispatch.push(fromActions.decreseGroupObserverCountAction({group: action.group}));
      }
      return actionsToDispatch;
    })
  ));
}

<div class="content">
  <div class="container-fluid">
    <div class="card card-widget position-relative shadow mt-3 mb-4">
      <div class="card-header pt-1" >
        <h3 *ngIf="package?.id" class="text-primary card-title text-lg m-0">
          {{package?.name}}
        </h3>
      </div>
      <div [ngStyle]="backgroundStyle()"></div>
      <div class="card-body" >
        <div [innerHtml]="package.content | safeHtml"></div>
      </div>
      <div class="card-footer text-center">
        <button (click)="start()" class="btn btn-primary btn-lg">Rozpocznij</button>
      </div>
    </div>
  </div>
  </div>


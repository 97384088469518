import { AuthService } from '@/auth/auth.service';
import { User } from '@/model/user.model';
import { Comment } from '@/model/comment.model';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromReducers from '@/store/offer/offers.reducers'
import * as fromActions from '@/store/offer/offer.actions';

import { SlideInOutAnimation } from '@/animations';
import { Observable, Subject } from 'rxjs';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';
import { ImagePipe } from '@/pipes/image.pipe';
import { environment } from 'environments/environment';
import { Actions, ofType } from '@ngrx/effects';
import { Offer } from '@/model/offer.model';
import { ShareLinkPopupComponent } from '@components/share-link-popup/share-link-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'offer-box',
  templateUrl: './offer-box.component.html',
  styleUrls: ['./offer-box.component.scss'],
  animations: [SlideInOutAnimation]
})
export class OfferBoxComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('addComments') protected addCommentsElement: ElementRef;
  @Input() offer: Offer;
  @Input() small: boolean = true;
  user: User;
  showComments = false;
  moreAnimationState = 'out';
  showOverlay = false;
  showCommentOverlay = true;
  protected imagePipe: ImagePipe;

  constructor(
    protected store: Store<fromReducers.State>,
    protected authService: AuthService,
    protected lightbox: Lightbox,
    protected lightboxConfig: LightboxConfig,
    protected actions: Actions,

    protected modalService: NgbModal,
    protected window: Window
  ) {
    this.imagePipe = new ImagePipe();
    this.lightboxConfig.showZoom = true;
    this.lightboxConfig.centerVertically = true;
    this.lightboxConfig.wrapAround = true;


  }

  ngAfterViewInit(): void {
    // this.actions.pipe(
    //   ofType(fromPostActions.fetchPostCommentsAction),
    //   filter(action => action.post.id == this.post.id))
    //     .subscribe(() => this.showCommentOverlay = true);
    // this.actions.pipe(
    //   ofType(fromPostActions.addManyCommentsToPostAction),
    //   filter(action => action.post.id == this.post.id))
    //     .subscribe(() => this.showCommentOverlay = false)
  }

  ngOnInit(): void {
    this.authService.getUser().then(user => {this.user = user});
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  toggleShowComments(): void {
    // this.showComments = !this.showComments;
    // if (this.showComments) {
    //   this.store.dispatch(fromPostActions.fetchPostCommentsAction({post: this.post}));
    // }
  }



  // get comments(): Observable<Comment[]> {
  //   return this.store.pipe(select(fromPostReducers.selectComments, this.offer.id));
  // };

  seen () {
    if (!this.offer.seen) {
      this.store.dispatch(fromActions.setAsSeen({offer: this.offer}));
    }
  }

  like() {
    this.offer = {...this.offer, ...{likeSaving:true}};
    if (!this.offer.liked) {
      this.store.dispatch(fromActions.likeOfferAction({offer:this.offer}));
    }
    else {
      this.store.dispatch(fromActions.unlikeOfferAction({offer:this.offer}));
    }
  }

  backgroundStyle () {
    if(!this.offer)
      return null;
    if(!this.offer.background)
      return {
        'aspect-ratio': '4',
        'background' : `#ed1a3b`,
        'background-size': 'cover'
      }
    return {
      'aspect-ratio': '4',
      'background' : `url(${environment.apiUrl}/thumb/1920x0/${this.offer.background.replace('public','')}) center center`,
      'background-size': 'cover'
    }
  }

  delete() {
    // this.showOverlay = true;
    this.store.dispatch(fromActions.deleteOne({offer:this.offer}));
  }

  commentIdenity(item: Comment, index: number) {
    return item.id;
  }

  share() {
    const modalRef = this.modalService.open(ShareLinkPopupComponent);
    modalRef.componentInstance.url = `https://${this.window.location.hostname}/public/offer/${this.offer.id}/refferer/${this.user.id}`;
  }

}

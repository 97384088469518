import { AfterContentInit, AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as fromActions from '@/store/ideas/ideas.actions';
import * as fromReducers from '@/store/ideas/ideas.reducers';
import { ActivatedRoute, Data, ParamMap } from '@angular/router';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { map, take, takeUntil } from 'rxjs/operators';
import { Idea } from '@/model/idea.model';
import { IdeaItemComponent } from '../list-ideas/idea-item/idea-item.component';
import { ofType } from '@ngrx/effects';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-view-idea',
  templateUrl: './view-idea.component.html',
  styleUrls: ['./view-idea.component.scss']
})
export class ViewIdeaComponent implements OnInit, OnDestroy , AfterViewInit {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  idea: Idea;
  @ViewChild('ideaItem') ideaItem!: IdeaItemComponent;

  constructor(
    private store: Store<fromReducers.State>,
    private actionsSubject: ActionsSubject,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data: Data) => {
      this.idea = data.instance;
      this.store.pipe(select(fromReducers.selectPublicIdea, data.instance.id)).subscribe((idea) => {
        this.idea = idea;
      })
    })
  }

  ngAfterViewInit(): void {
    this.activatedRoute.fragment.subscribe(fragment => {
      setTimeout( () => {
        if(!this.ideaItem.showComments)
          this.ideaItem.toggleShowComments();
          this.actionsSubject.pipe(
            takeUntil(this.unsubscribe$),
            ofType(fromActions.addManyCommentsToIdeaAction))
              .subscribe(() => {
                setTimeout(() => {
                  console.log(document.getElementById(fragment));
                  document.getElementById(fragment).scrollIntoView();
                })
              })
      });

    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, Observable, Subject } from 'rxjs';

import * as fromRedmineCategoryActions from '@/store/redmine/redmine.category.actions';
import * as fromRedmineCategoryReducers from '@/store/redmine/redmine.category.reducers';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { RedmineCategory } from '@/model/redmine.model';

@Component({
  selector: 'app-list-redmine-category',
  templateUrl: './list-redmine-category.component.html',
  styleUrls: ['./list-redmine-category.component.scss']
})
export class ListRedmineCategoryComponent implements OnInit {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  searchTerm: string;
  page = 1;
  pageSize = 20;
  constructor(
    protected store: Store<fromRedmineCategoryReducers.State>,
    protected actionsSubject: ActionsSubject,
    protected router: Router,
    protected toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.store.dispatch(fromRedmineCategoryActions.fetch());
    // this.store.pipe(
    //   take(1),
    //   takeUntil(this.unsubscribe$),
    //   select(fromRedmineCategoryReducers.selectState),
    //   map(state => state.search)
    // ).subscribe((search:string) => {
    //   this.searchInput.nativeElement.value = search
    // });
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      takeUntil(this.unsubscribe$),
      map((event: any) => event.target.value),
      filter( (res:string) => res.length > 2),
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((text: string) => {
      this.store.dispatch(fromRedmineCategoryActions.setSearch({search: text.toLowerCase().trim()}));
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  clearSearch() {
    this.searchInput.nativeElement.value = '';
    this.store.dispatch(fromRedmineCategoryActions.setSearch({search: null}));
  }

  pageChange(page): void {
    this.store.dispatch(fromRedmineCategoryActions.setPage({page: page}));
  }

  identify(index, entity: RedmineCategory){ return entity.id }

  get list(): Observable<RedmineCategory[]>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromRedmineCategoryReducers.selectState),
      map( (state:fromRedmineCategoryReducers.State)  => fromRedmineCategoryReducers.selectAll(state.list))
    );
  }

  get total(): Observable<number>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromRedmineCategoryReducers.selectState),
      map( (state:fromRedmineCategoryReducers.State)  => state.total)
    );
  }

  get loading(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromRedmineCategoryReducers.selectState),
      map( (state:fromRedmineCategoryReducers.State)  => state.loading),
    );
  }

  delete(id: number) {
    this.store.dispatch(fromRedmineCategoryActions.deleteCategory({id}));
  }
}

import { HttpErrorResponse } from "@angular/common/http";


export const StringifyHttpErrorResponse = (errorMsg: HttpErrorResponse) => {
  switch (errorMsg.status) {
    case 401:
      if(errorMsg?.error?.message === "Konto nieaktywe") {
        return "Konto nieaktywe";
      }
      return "Błąd autoryzacji";
    case 405:
      return "Nie masz uprawnień";
    default:
      return `Wystąpił błąd połączenia z serwerem (${errorMsg.status}): ${errorMsg?.error?.error ? errorMsg.error.error : errorMsg.statusText}`;
  }

};

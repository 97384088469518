<div class="modal-header">
  <h4 class="modal-title">Udostępnij</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-group">
    <div class="input-group">
      <input class="form-control" readonly [value]="url" #urlClipboard/>
      <span class="input-group-append">
        <button (click)="copyInputMessage(urlClipboard)" class="btn btn-default" ngbTooltip="Kopiuj link">
          <i class="fas fa-copy"></i>
        </button>
      </span>
    </div>
  </div>
</div>
<div class="modal-footer">
  <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
</div>

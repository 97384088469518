import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ToastrService } from "ngx-toastr";
import * as fromActions from '@/store/kb/kb.actions'
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { Folder } from "@/model/folder.model";
import { resultMemoize, Store } from "@ngrx/store";
import { EntityState } from "@ngrx/entity";
import { KbFile } from "@/model/file.model";
import { KbArticle } from "@/model/article.model";
import { plainText } from "@/utils/plainText";
import { State } from "./kb.reducers";
import { StringifyHttpErrorResponse } from "@/utils/http.error.util";


@Injectable()
export class KbEffects {
  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private store: Store<State>,
  ) {
  }

  onError (err, caught): any {
    this.toastr.error(StringifyHttpErrorResponse(err));
    this.store.dispatch(fromActions.clearSaving());
    return caught;
  };

  fetchFolder$ = createEffect(() => {
    return  this.actions$.pipe(
      ofType(fromActions.fetchFolder),
      switchMap((action) => {
        return this.httpClient.get<Folder>(`<backendhost>/v1/kb/folder/${action.id}`, {observe: 'body',responseType: 'json'});
      }),
      catchError(this.onError.bind(this)),
      switchMap( (folder: any) => {
        let actionsToDistpatch = [];
        actionsToDistpatch.push(fromActions.setFolder({folder}))
        return actionsToDistpatch;
      })
    );
  });

  createFolder$ = createEffect(() => {
    return  this.actions$.pipe(
      ofType(fromActions.createFolder),
      switchMap((action) => {
        const formData = new FormData();
        let folder = {...action.folder, ...{folder: action.folder.id}};
        if(action.image) {
          formData.append('image', action.image, action.image.name);
        }
        if(action.background) {
          formData.append('background', action.background, action.background.name);
        }
        formData.append('data', JSON.stringify(folder));
        return this.httpClient
          .post<Folder>(`<backendhost>/v1/kb/folder`, formData, {observe: 'body',responseType: 'json'})
          .pipe(map( (result:Folder) => {
            return {
              folder: result,
              parent: action.parent
            }
          }));
      }),
      catchError(this.onError.bind(this)),
      switchMap( (result: any) => {
        console.log(result);
        let actionsToDistpatch = [];
        actionsToDistpatch.push(fromActions.saveSuccess({parent: result.parent.id, id : result.folder.id}))
        actionsToDistpatch.push(fromActions.addFolder({folder: result.folder}))
        return actionsToDistpatch;
      })
    );
  });

  saveFolder$ = createEffect(() => {
    return  this.actions$.pipe(
      ofType(fromActions.saveFolder),
      switchMap((action) => {
        return this.httpClient
          .put<Folder>(`<backendhost>/v1/kb/folder/${action.folder.id}`, action.folder, {observe: 'body',responseType: 'json'})
      }),
      catchError(this.onError.bind(this)),
      switchMap( (folder: any) => {
        let actionsToDistpatch = [];
        if (folder.parent == folder.id || !folder.parent)
          actionsToDistpatch.push(fromActions.updateSelf({folder}))
        else
          actionsToDistpatch.push(fromActions.updateFolder({folder}))
        actionsToDistpatch.push(fromActions.saveSuccess({parent: folder.parent ? folder.parent : folder.id, id : folder.id}))
        return actionsToDistpatch;
      })
    );
  });

  deleteFolder$ = createEffect(() => {
    return  this.actions$.pipe(
      ofType(fromActions.deleteFolder),
      switchMap((action) => {
        return this.httpClient
          .delete<Folder>(`<backendhost>/v1/kb/folder/${action.folder.id}`, {observe: 'body',responseType: 'json'})
          .pipe(map( () => action.folder));
      }),
      catchError(this.onError.bind(this)),
      switchMap( (folder: any) => {
        let actionsToDistpatch = [];
        actionsToDistpatch.push(fromActions.removeFolder({folder}))
        return actionsToDistpatch;
      })
    );
  });

  fetchFiles$ = createEffect(() => {
    return  this.actions$.pipe(
      ofType(fromActions.fetchFiles),
      switchMap((action) => {
        return this.httpClient
          .get<EntityState<KbFile>>(`<backendhost>/v1/kb/folder/${action.id}/files`, {observe: 'body',responseType: 'json'})
      }),
      catchError(this.onError.bind(this)),
      switchMap( (files: any) => {
        let actionsToDistpatch = [];
        actionsToDistpatch.push(fromActions.setFiles({files}))
        return actionsToDistpatch;
      })
    );
  });

  deleteFile$ = createEffect(() => {
    return  this.actions$.pipe(
      ofType(fromActions.deleteFile),
      switchMap((action) => {
        return this.httpClient
          .delete<any>(`<backendhost>/v1/kb/file/${action.file.id}`, {observe: 'body',responseType: 'json'})
          .pipe(map( ()=> action.file))
      }),
      catchError(this.onError.bind(this)),
      tap( (file: KbFile) => {this.toastr.success(`Usunięto plik ${file.name}`)}),
      switchMap( (file: KbFile) => [])
    );
  });

  updateFile$ = createEffect(() => {
    return  this.actions$.pipe(
      ofType(fromActions.updateFile),
      switchMap((action) => {
        return this.httpClient
          .put<KbFile>(`<backendhost>/v1/kb/file/${action.file.id}`, action.file, {observe: 'body',responseType: 'json'})
      }),
      catchError(this.onError.bind(this)),
      switchMap( (files: any) => {
        let actionsToDistpatch = [];

        actionsToDistpatch.push(fromActions.saveSuccess({parent: 0, id : 0}))
        return actionsToDistpatch;
      })
    );
  });

  createArticle$ = createEffect(() => {
    return  this.actions$.pipe(
      ofType(fromActions.createArticle),
      switchMap((action) => {
        const formData = new FormData();
        let article = {...action.article, ...{folder: action.folder.id}};
        article.short = plainText(article.content);
        delete article.createdBy;
        delete article.createdAt;
        if(action.image) {
          formData.append('image', action.image, action.image.name);
        }
        if(action.background) {
          formData.append('background', action.background, action.background.name);
        }

        if(action.audioFile) {
          formData.append('audio', action.audioFile, action.audioFile.name);
        }
        formData.append('data', JSON.stringify(article));
        return this.httpClient
          .post<KbArticle>(`<backendhost>/v1/kb/article`, formData,  {observe: 'body',responseType: 'json'})
          .pipe(map( (result: KbArticle) => {
            return {
              article: result,
              folder: action.folder
            }
          }))
      }),
      catchError(this.onError.bind(this)),
      switchMap( (result: any) => {
        let actionsToDistpatch = []
        actionsToDistpatch.push(fromActions.saveSuccess({parent: result.folder.id, id : result.article.id}))
        actionsToDistpatch.push(fromActions.addArticle({article: result.article}))
        return actionsToDistpatch;
      })
    );
  });

  updateArticle$ = createEffect(() => {
    return  this.actions$.pipe(
      ofType(fromActions.saveArticle),
      switchMap((action) => {
        const formData = new FormData();
        let article = {...action.article, ...{folder: action.folder.id}};
        article.short = plainText(article.content);
        delete article.createdAt;
        delete article.createdBy;
        if(action.image) {
          formData.append('image', action.image, action.image.name);
        }
        if(action.background) {
          formData.append('background', action.background, action.background.name);
        }
        if(action.audioFile) {
          formData.append('audio', action.audioFile, action.audioFile.name);
        }
        formData.append('data', JSON.stringify(article));
        return this.httpClient
          .post<KbArticle>(`<backendhost>/v1/kb/article/${article.id}/update`, formData,  {observe: 'body',responseType: 'json'})
          .pipe(map( (result: KbArticle) => {
            return {
              article: result,
              folder: action.folder
            }
          }))
      }),
      catchError(this.onError.bind(this)),
      switchMap( (result: any) => {
        let actionsToDistpatch = []
        actionsToDistpatch.push(fromActions.saveSuccess({parent: result.folder.id, id : result.article.id}))
        actionsToDistpatch.push(fromActions.updateArticle({article: result.article}))
        return actionsToDistpatch;
      })
    );
  });

  deleteArticle$ = createEffect(() => {
    return  this.actions$.pipe(
      ofType(fromActions.deleteArticle),
      switchMap((action) => {
        return this.httpClient
          .delete<KbArticle>(`<backendhost>/v1/kb/article/${action.article.id}`,  {observe: 'body',responseType: 'json'})
      }),
      catchError(this.onError.bind(this)),
      switchMap( (result: any) => {
        let actionsToDistpatch = []
        return actionsToDistpatch;
      })
    );
  });


  fetchArticles$ = createEffect(() => {
    return  this.actions$.pipe(
      ofType(fromActions.fetchArticles),
      switchMap((action) => {
        return this.httpClient
          .get<EntityState<KbFile>>(`<backendhost>/v1/kb/folder/${action.id}/articles`, {observe: 'body',responseType: 'json'})
      }),
      catchError(this.onError.bind(this)),
      switchMap( (articles: any) => {
        let actionsToDistpatch = [];
        actionsToDistpatch.push(fromActions.setArticles({articles}))
        return actionsToDistpatch;
      })
    );
  });
}

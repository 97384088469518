<div class="card" #viewer *ngIf="invitation.best?.certificate">
  <div class="overlay" *ngIf="!isMobile">
    <div style="position: sticky; text-align: center">
      <i class="fas fa-3x fa-spinner fa-spin"></i>
      <p>Ładowanie...</p>
    </div>
  </div>
  <object
    *ngIf="!isMobile"
    [data]="pdfSrc"
    type="application/pdf"
    style="width:100%;height:70vh;position: relative; z-index: 1000;">
    <div class="overlay" style="background: white; position: absolute; top:0; left:0; bottom:0; right:0;">
      <img  [src]="imgSrc" style="width:100%" class="img-responsive"/>
      <p class="text-center"><a [href]="pdfSrc" class="btn btn-primary">Pobierz Certyfikat</a></p>
    </div>
  </object>

  <div *ngIf="isMobile">
    <a [href]="pdfSrc" target="_blank"><img  [src]="imgSrc" style="width:100%" class="img-responsive"/></a>
    <p class="text-center"><a [href]="pdfSrc" class="btn btn-primary" target="_blank">Pobierz Certyfikat</a></p>
  </div>

</div>

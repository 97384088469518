<div class="modal-header">
  <h4 class="modal-title">Pole formularza</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (ngSubmit)="save()">
<div class="modal-body">
    <div class="form-group">
      <label>Treść pytania</label>
      <input formControlName="label" type="text" [appIsValid]="form.controls.label" class="form-control" placeholder="Treść pytania" />
      <div class="invalid-feedback" *ngIf="form.controls.label.errors?.required">
        Pole jest wymagane
      </div>
    </div>
    <div class="form-group">
      <label>Rodzaj pytania</label>
      <select formControlName="type" [appIsValid]="form.controls.type" class="form-control">
        <!-- <option value="text">Tekstowe</option>
        <option value="number">Liczbowe</option> -->
        <option value="checkbox">Wielokrotnego wyboru</option>
        <option value="radio">Jednokrotnego wyboru</option>
        <option value="scale">Skala</option>
        <option value="open">Otwarte</option>
        <!-- <option value="select">Lista rozwijana</option> -->
      </select>
      <div class="invalid-feedback" *ngIf="form.controls.label.errors?.required">
        Pole jest wymagane
      </div>
    </div>
    <div class="form-group" *ngIf="form.controls.type.value == 'scale'">
      <label>Skala</label>
      <input type="number" class="form-control"
        formControlName="scaleNumber"
        [value]="form.controls.scaleNumber.value"
        min="1"
        max="10"/>
    </div>
    <table class="table align-middle table-hover" *ngIf="checkMultiple(form.value?.type)">
      <thead>
        <tr>
          <th style="width:1rem">#</th>
          <th>Odpowiedz</th>
          <th class="text-right" style="width:1rem">
            <span class="btn btn-success btn-sm elevation-2 text-nowrap" (click)="addOption()">
              <i class="fas fa-plus fa-fw"></i>
            </span>
          </th>
        </tr>
      </thead>
      <tbody  [formGroup]="optionsCtrl">
        <tr *ngFor="let item of optionsCtrl.controls; let idx = index; " [formGroup]="getOptionFormGroup(idx)">
          <td>{{idx+1}}</td>

          <td>
            <input formControlName="label" type="text" class="form-control" placeholder="Treść odpowiedzi" [appIsValid]="getOptionFormGroup(idx).controls.label"/>
            <div class="text-danger" *ngIf="getOptionFormGroup(idx).controls.label.errors?.required">
              Pole jest wymagane
            </div>
            <!-- <input formControlName="id" type="text" class="form-control" /> -->
          </td>
          <td class="text-right" >
            <span class="btn btn-danger btn-sm elevation-2 text-nowrap" (click)="delOption(idx)">
              <i class="fas fa-trash fa-fw"></i>
            </span>
          </td>
        </tr>
        <tr>
          <td colspan="2"></td>
          <td>
            <span class="btn btn-success btn-sm elevation-2 text-nowrap" (click)="addOption()">
              <i class="fas fa-plus fa-fw"></i>
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- <pre>{{form.value | json}}</pre>
    <hr>
    <pre>{{quiz | json}}</pre> -->
</div>
<div class="modal-footer justify-content-start">
  <button type="button" class="btn btn-primary" type="submit">
    Ok
  </button>
</div>
</form>

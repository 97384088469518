
import { Post } from "@/model/post.model";
import { Comment, CommentsDto } from '@/model/comment.model';
import { createAction, props } from "@ngrx/store";

export const clear = createAction("clear news");

export const fetchNewsAction = createAction(
  "fetch news",
  props<{ group?: number, term?: string, after?:number, before?:number, all?:boolean, observed?:boolean, unseen?:boolean, favorite?: boolean }>()
);

export const fetchOneAction = createAction(
  "fetch one news",
  props<{ id: number }>()
);

// export const fetchBetweenCompletedAction = createAction("fetch between completed");
// export const fetchBetweenIncompletedAction = createAction("fetch between incompleted");

export const addMultipleNews = createAction(
  "add multiple news",
  props<{ posts: Post[] }>()
);

export const addOneNews = createAction(
  "add one news",
  props<{ post: Post }>()
);

export const savePost = createAction(
  "save news",
  props<{ post: Post,  background: any, audioFile: any }>()
);

export const updateNews = createAction(
  "update news",
  props<{ post: Post }>()
);

export const setHasMore = createAction(
  "set hasMore",
  props<{hasMore: boolean, loading?: boolean}>()
);

export const laodingCompleted = createAction(
  "loading completed"
);

export const createNewPostAction = createAction(
  "create new post",
  props<{ post: Post, background: any, audioFile: any}>()
);

export const deletePostAction = createAction(
  "deletePostAction",
  props<{ post: Post}>()
);

export const removePostAction = createAction(
  "removePostAction",
  props<{ post: Post}>()
);

export const fetchPostCommentsAction = createAction(
  "fetch post comments",
  props<{ post: Post }>()
);

export const createPostCommentAction = createAction(
  "create post comment",
  props<{ post: Post, replyTo: Comment, comment: Comment }>()
);

export const addCommentSuccess = createAction(
  "addCommentSuccess",
  props<{ post: Post, comment: Comment, replyTo?: Comment }>()
);

export const addCommentToPostAction = createAction(
  "add comment to post",
  props<{ post: Post, comment: Comment }>()
);

export const addCommentToCommentAction = createAction(
  "add comment to comment",
  props<{ post: Post, comment: Comment, replyTo: Comment }>()
);

export const addManyCommentsToPostAction = createAction(
  "add many comments to post",
  props<{ post: Post, comments: Comment[] }>()
);

export const deleteComment = createAction(
  "deleteComment",
  props<{ comment: Comment }>()
);

export const likePostAction = createAction(
  "like post",
  props<{ post: Post }>()
);

export const unlikePostAction = createAction(
  "unlike post",
  props<{ post: Post }>()
);

export const likeCommentAction = createAction(
  "like comment",
  props<{ comment: Comment }>()
);

export const unlikeCommentAction = createAction(
  "unlike comment",
  props<{ comment: Comment }>()
);

export const updateCommentAction = createAction(
  "update comment",
  props<{ comment: Comment, post: Post }>()
);

export const updateReplyCommentAction = createAction(
  "update reply comment",
  props<{ comment: Comment, post: Post, replyTo: Comment }>()
);

export const setAsSeen = createAction(
  "set post as seen",
  props<{ post: Post }>()
);

export const setAsSeenComplete = createAction(
  "set post as seen complete",
  props<{ post: Post }>()
);

export const toogglePostFavorite = createAction(
  "toogglePostFavorite",
  props<{ post: Post}>()
);

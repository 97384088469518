<section class="content">
  <div class="container">
      <div class="row">
          <div class="col-lg-12">
            <form [formGroup]="form" (ngSubmit)="save()">
            <div class="card card-primary">
              <div class="card-body">
                <div class="form-group">
                  <label for="inputName">Nazwa grupy</label>
                  <input
                    formControlName="name"
                    type="text"
                    class="form-control"
                    placeholder="Nazwa grupy"
                  />
                </div>
              </div>
              <div class="card-footer">
                <button class="btn btn-success float-right" type="submit">
                  Zapisz
                  <i class="fas fa-angle-right fa-fw"></i>
                </button>
              </div>
            </div>
            </form>
          </div>
      </div>
  </div>
</section>





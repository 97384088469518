<section class="content">
  <div class="container pt-3 pb-3">
      <form [formGroup]="form" (ngSubmit)="save()">

        <app-user-tabs *ngIf="!myaccount"></app-user-tabs>
        <app-account-tabs *ngIf="myaccount"></app-account-tabs>
        <div class="card" style="border-top-left-radius: 0;border-top-right-radius: 0;">
        <div class="overlay" *ngIf="loading">
          <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%,0);text-align: center; padding: 25px 0">
            <i class="fas fa-3x fa-spinner fa-spin"></i>
            <p>Ładowanie...</p>
          </div>
        </div>
        <div class="card-body">
          <div class="form-group position-relative" formGroupName="outpost">
            <!-- <div class="overlay position-absolute" *ngIf="(outpostLoading | async)" style="top: 0; bottom: 0; left: 0; right: 0">
              <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
                <i class="fas fa-3x fa-spinner fa-spin"></i>
                <p>Ładowanie...</p>
              </div>
            </div> -->
            <label>Placówka</label>

            <outpost-input formControlName="outpost" [multiple]="true" [selectedOutposts]="selectedOutposts"></outpost-input>
            <!-- <div class="form-check" *ngFor="let outpost of outposts">
              <input class="form-check-input" type="checkbox" [formControlName]="outpost.id" [id]="'outpost' + outpost.id">
              <label class="form-check-label" [for]="'outpost' + outpost.id">{{outpost.name}}</label>
            </div> -->
            <!-- <div class="invalid-feedback" *ngIf="form.controls.outpost.errors?.required" [class.d-block]="form.controls.outpost.touched">Pole jest wymagane</div> -->
          </div>
          <div class="form-group" formGroupName="type">
            <label>Rodzaj</label>
            <div class="form-check" *ngFor="let enum of SlotType">
              <input class="form-check-input" type="checkbox" [formControlName]="enum" [id]="enum">
              <label class="form-check-label" [for]="enum">{{enum}}</label>
            </div>
            <div class="invalid-feedback" *ngIf="form.controls.type.errors?.required" [class.d-block]="form.controls.type.touched">Pole jest wymagane</div>
          </div>
          <div class="form-group" formGroupName="day">
            <label>Dzień</label>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" formControlName="WEEKDAY" id="WEEKDAY">
              <label class="form-check-label" for="WEEKDAY">Dzień roboczy</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" formControlName="SATURDAY" id="SATURDAY">
              <label class="form-check-label" for="SATURDAY">Sobota</label>
            </div>
            <div class="invalid-feedback" *ngIf="form.controls.day.errors?.required" [class.d-block]="form.controls.day.touched">Pole jest wymagane</div>
          </div>
        </div>
        <div class="card-footer">
          <button class="btn btn-success float-right" type="submit">
            Zapisz
            <i class="fas fa-angle-right fa-fw fa-lg"></i>
          </button>
        </div>
      </div>
    </form>
  </div>
</section>

<div class="container-xl pt-3 pb-3">
  <div class="card">
    <div class="card-header with-border">
        <h3 class="card-title">Struktura Firmy</h3>
        <div class="card-tools">
            <a class="btn btn-success  btn-social" [routerLink]="['/structure/admin/add']" checkRolePermition="structure.create" >
                <i class="fa fa-fw fa-plus"></i>
                Dodaj nową pozycję główną
            </a>
        </div>
    </div>
    <div class="card-body">
        <table class="table tree table-hover">
            <thead>
                <tr>
                    <!-- <th style="width:<?= 32* (1+$this->maxLevel); ?>px">Rozwiń</th> -->
                    <th colspan="2" style="width: 124px">Rozwiń</th>
                    <th>Dział</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
              <ng-template ngFor let-item [ngForOf]="list" let-idx="index" [ngForTrackBy]="identify">
                <ng-container *ngTemplateOutlet="row;context:{item: item}"></ng-container>
              </ng-template>
            </tbody>
        </table>
    </div>
  </div>
</div>

<ng-template #row let-item="item" let-parent="parent">
  <tr
    [attr.id]="'node-' + item.id"
    [attr.data-tree-id]="item.id"
    [attr.data-tree-parent]="parent?.id ? parent?.id : 0"
    [ngClass]="nodeClass(item,parent)"
    class="tree-sortable treegrid-{{item.id}}">
    <td  style="white-space: nowrap;width: 110px " class="leveled" >
      <span class="treegrid-expander fas  fa-chevron-down"></span>
    </td>
    <th style="width: 12px">
      <span class="badge badge-primary" *ngIf="item.children?.ids.length">
        {{item.children?.ids.length}}
      </span>
    </th>
    <th [ngStyle]="{'padding': '12px ' + (24 * item.level + 12) + 'px'}">
      {{item.title}}
    </th>
    <td
      style="width:100px;white-space: nowrap;text-align: right;padding:8px 0"
      [ngStyle]="{'padding': '8px ' + 32 * item.level + 'px'}">
      <span
        checkRolePermition="structure.edit"
        class="btn btn-info handle btn-circle ml-1"
        (mousedown)="onNodeMousedown(item.id)"
        ngbTooltip="Zmień pozycje">
          <i class="fas fa-fw fa-arrows-alt-v fa-lg"></i>
      </span>
      <a
        checkRolePermition="structure.edit"
        class="btn btn-info btn-circle ml-1"
        ngbTooltip="Edytuj dział"
        [routerLink]="['/structure/admin/edit', item.id]">
          <i class="fas fa-fw fa-edit fa-lg"></i>
      </a>
      <a
        checkRolePermition="structure.create"
        class="btn btn-success btn-circle ml-1"
        ngbTooltip="Dodaj podrzędny dział"
        [routerLink]="['/structure/admin/add', item.id]">
          <i class="fas fa-fw fa-plus fa-lg"></i>
      </a>
      <a
        checkRolePermition="structure.delete"
        nz-popconfirm
        nzPopconfirmTitle="Czy na pewno usunąć?"
        nzOkText="Tak"
        nzCancelText="Nie"
        nzPopconfirmPlacement="left"
        (nzOnConfirm)="delete(item)"
        ngbTooltip="Usuń"
        class="btn btn-danger btn-circle confirm ml-1">
        <i class="fas fa-fw fa-trash fa-lg"></i>
      </a>
  </td>
  </tr>
  <ng-template ngFor let-id [ngForOf]="item.children?.ids" let-idx="index">
    <ng-container *ngTemplateOutlet="row;context:{item: item.children.entities[id], parent: item}"></ng-container>
  </ng-template>
</ng-template>

<!-- <pre>{{list | json}}</pre> -->

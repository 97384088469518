import { Conversation } from '@/model/chat.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import * as fromChatActions from '@/store/chat/chat.actions'
import * as fromChatReducers from '@/store/chat/chat.reducers'
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, of, OperatorFunction, Subject } from 'rxjs';
import { AppService } from '@services/app.service';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { User } from '@/model/user.model';

@Component({
  selector: 'app-room-edit',
  templateUrl: './room-edit.component.html',
  styleUrls: ['./room-edit.component.scss']
})
export class RoomEditComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  form: FormGroup;
  conversation: Conversation;
  uploading = false;
  searching = false;
  searchFailed = false;
  model: any;
  formatter = (user: User) => `${user.firstname} ${user.lastname}`;
  formatterEmpty = () => null;


  constructor(
    private toastr: ToastrService,
    private store: Store<fromChatReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService,
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
    });
    this.route.paramMap.pipe(
      map((params: ParamMap) => parseInt(params.get('id')))
    ).subscribe( (id: number) => {
      this.store.dispatch(fromChatActions.fetchRoom({id}));
      this.store.pipe(takeUntil(this.unsubscribe$),select(fromChatReducers.selectConversation, id)).subscribe((conversation: Conversation | null) => {
        if (conversation) {
          this.conversation = conversation;
          this.form.patchValue(conversation);
        }
      })
    })
  }

  save(): void {
    if (this.form.valid) {
      let conversation = {... this.conversation, ... {name: this.form.value.name}};
      this.store.dispatch(fromChatActions.saveRoom({conversation}));
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        filter((action: {type: any, conversation?: Conversation}) => {
          return action.type === fromChatActions.updateRoom.type && action?.conversation.id === this.conversation.id
        })
      ).subscribe( (result: any) => {
        this.toastr.success('Zapisano zmiany!');
      });
    } else {
        this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }

  handleImageUploadChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.uploading = true;
        break;
      case 'done':
        this.store.dispatch(fromChatActions.updateRoom({conversation: info.file.response}));
        this.uploading = false;
        break;
      case 'error':
        this.toastr.error('Błąd uploadu pliku');
        this.uploading = false;
        break;
    }
  }

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    return text$.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.appService.searchUser(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    );
  }

  get membersFull() : User[] {
    return this.conversation ? this.conversation.membersFull : [];
  }

  onUserSelect(selectedItem: NgbTypeaheadSelectItemEvent) {
    let user: User = selectedItem.item;
    this.store.dispatch(fromChatActions.addMemberToRoom({user: user, room: this.conversation}));
    // this.store.pipe(select(fromGroupReducer.selectEntity, this.group.id), take(1)).subscribe((group: Group | null) => {
    //   this.group = group;
    // })
  }

  deleteMember(user: User): void {
    this.store.dispatch(fromChatActions.deleteMemberFromRoom({user: user, room: this.conversation}));

  }

}

import { PaginationDTO } from "@/model/pagination.model";
import { Slot } from "@/model/slot.model";
import { User } from "@/model/user.model";
import { Vacant, VacantCreateDTO } from "@/model/vacant.model";
import { createAction, props } from "@ngrx/store";

export interface VacantFilter {
  type?: string,
  outpost?: number,
  reservedBy?: number,
  date? : {
    start: string,
    end: string
  }
}

export const clear = createAction("vacant clear");
export const fetch = createAction("vacant fetch");
export const fetchMore = createAction("vacant fetchMore", props<{skip: number}>());
export const fetchOne = createAction("vacant fetchOne", props<{id: number}>());

export const setType = createAction("vacant setType", props<{ value: string }>());
export const setSlot = createAction("vacant setSlot", props<{ slot: Slot }>());
export const setUser = createAction("vacant setUser", props<{ user: User }>());
export const setFilter = createAction("vacant setFilter", props<{ filter: VacantFilter }>());

export const setPage = createAction("vacant setPage", props<{ page: number }>());
export const setOrder = createAction("vacant setOrder", props<{ order: string }>());
export const setDirection = createAction("vacant setDirection", props<{ asc: boolean }>());
export const setSearch = createAction("vacant setSearch", props<{ search: string }>());

export const createVacant = createAction("vacant createVacant", props<{data: VacantCreateDTO}>());
export const deleteVacant = createAction("vacant deleteVacant", props<{id: number}>());

export const setMany = createAction("vacant setMany", props<PaginationDTO<Vacant>>());
export const addMany = createAction("vacant addMany", props<PaginationDTO<Vacant>>());
export const addOne = createAction("vacant addOne", props<{vacant: Vacant}>());
export const setOne = createAction("vacant setOne", props<{vacant: Vacant}>());
export const removeOne = createAction("vacant removeOne", props<{id: number}>());

export const reserveVacant = createAction("vacant reserveVacant", props<{vacant: Vacant}>());
export const unreserveVacant = createAction("vacant unreserveVacant", props<{vacant: Vacant}>());
export const repeatNotification = createAction("vacant repeatNotification", props<{id: number}>());
export const reserveSuccess = createAction("vacant reserveSuccess");

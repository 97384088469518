import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import * as fromTrainingPackageiaActions from '@/store/package/package.actions';
import * as fromTrainingPackageReducers from '@/store/package/package.reducers';
import { TrainingPackage } from '@/model/package.model';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'app-package-invitated',
  templateUrl: './package-invitated.component.html',
  styleUrls: ['./package-invitated.component.scss']
})
export class PackageInvitatedComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  package: TrainingPackage;
  publishing = false;

  constructor(
    private store: Store<fromTrainingPackageReducers.State>,
    private activatedRoute: ActivatedRoute,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe( (data: Data) => {
      this.package = data.instance;

      if (this.package.archive || this.package.open) {
        this.router.navigate(['/training/admin/package']);
        return;
      }
      this.store.pipe(
        select(fromTrainingPackageReducers.selectOneTrainingPackage, this.package.id),
        takeUntil(this.unsubscribe$),
        distinctUntilChanged()
      ).subscribe((item) => {
        this.package = item;
      });
    });

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  publish() {
    this.publishing = true;
    this.actionsSubject$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(fromTrainingPackageiaActions.updateTrainingPackage))
        .subscribe( (result: any) => {
          this.toastr.success('Opublikowano szkolenie');
          this.router.navigate([`/training/admin/package`]);
        });
    this.store.dispatch(fromTrainingPackageiaActions.publishPackage({package: this.package}));
  }
}

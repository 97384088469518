import { Injectable } from '@angular/core';
import { User } from '@/model/user.model';
import * as fromAuthActions from '@/store/auth/auth.actions';
import * as fromAuthReductors from '@/store/auth/auth.reducers';
import * as fromRolesReducers from '@/store/roles/roles.reducers';

import { ActionsSubject, select, Store } from '@ngrx/store';
import { Observable, Observer, of, pipe, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, share, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { WelcomePopupComponent } from '@modules/main/welcome-popup/welcome-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessagingService } from '@services/messaging.service';
import { ofType } from '@ngrx/effects';
import { AllOrCreated, Role } from '@/model/role.model';


@Injectable()
export class AuthService {
  private state: fromAuthReductors.State;
  private requestedPush = false;
  constructor (
    private store: Store<fromAuthReductors.State>,
    private rolesStore: Store<fromRolesReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private httpClient: HttpClient,
    private modalService: NgbModal,
    private messagingService: MessagingService
  ) {
    this.actionsSubject$.pipe(
      takeUntil(this.unsubscribeUserFetch$),
      ofType(fromAuthActions.loginSuccess)
    ).subscribe( (action: any) => {
      this.router.navigateByUrl("/");
      this.modalService.open(WelcomePopupComponent);

    });
    this.store.pipe(select(fromAuthReductors.selectAuthState)).subscribe((state: fromAuthReductors.State) => {
      if (state.token && !this.requestedPush) {
        this.messagingService.requestPermission();
        this.requestedPush = true;
      }
      let loadUser = state?.token && this.state?.token != state?.token;
      this.state = state;
      if (loadUser) {
        this.store.dispatch(fromAuthActions.fetchProfileAction());
      }
    })
  }

  private readonly unsubscribeUserFetch$: Subject<void> = new Subject();
  async getUser(): Promise<User> {

    return new Promise<User>( (resolve, reject) => {
      this.store.pipe(
        select(fromAuthReductors.selectAuthState),
        map(state => state?.profile),
        filter(profile => profile != null)
      ).subscribe(resolve);
    } );
  }

  isAuthenticated(): boolean {
    return this.getToken() !== null;
  }

  getToken(): string {
    if (!this.state.token) {
      this.store.dispatch(fromAuthActions.fetchTokenAction());
    }
    return this.state.token;
  }

  private readonly unsubscribeLogin$: Subject<void> = new Subject();
  loginByAuth(email:any , password:any ) {
    this.store.dispatch(fromAuthActions.loginAction({email,password}));

  }

  ngOnDestroy(): void {
    this.unsubscribeUserFetch$.next();
    this.unsubscribeUserFetch$.unsubscribe();

  }

  remindPassowrd(email:any): Observable<any> {
    return this.httpClient.post<any>('<backendhost>/v1/auth/remind', {
      email: email,
    }, {
      observe: 'body',
      responseType: 'json'
    });//.pipe(tap(console.log)).subscribe();
  }

  recoverPassword(data:any) {
    return this.httpClient.post<any>('<backendhost>/v1/auth/reset', data, {
      observe: 'body',
      responseType: 'json'
    });//.pipe(tap(console.log)).subscribe();
  }

  checkPassword(password:any ) : Observable<boolean> {
    return this.store.pipe(
      select(fromAuthReductors.selectAuthState),
      switchMap( (state: fromAuthReductors.State) => {
        return this.httpClient.post<{token: string, user: User}>('<backendhost>/v1/auth/login', {
          email: state.profile.email,
          password: password
        }, {
          observe: 'body',
          responseType: 'json'
        });
      }),
      map(() => true),
      catchError(() => of(false))
    );
  }

  checkEmailUniqe(email:string ) : Observable<boolean> {
    console.log(email)
    return this.store.pipe(
      select(fromAuthReductors.selectAuthState),
      switchMap( (state: fromAuthReductors.State) => {
        return this.httpClient.post<{found: boolean}>('<backendhost>/v1/users/0/check-email', {
          email: email
        }, {
          observe: 'body',
          responseType: 'json'
        });
      }),
      map((result: {found: boolean}) => {
        return result.found;
      }),
      catchError(() => of(true))
    );
  }

  signOut(): void {
    this.store.dispatch(fromAuthActions.signoutAction());
    // this.router.navigate([{outlets: { sidebar: null}}]);
    this.router.navigateByUrl("/login");
  }

  // getRandomUser(): Observable<User> {

  //   return this.httpClient.get<User>('<backendhost>/v1/users/0/random');//.pipe(distinctUntilChanged(), share());
  // }

  async getRandomUser(): Promise<User> {

    return new Promise<User>( (resolve, reject) => {
      this.httpClient.get<User>('<backendhost>/v1/users/0/random').subscribe(resolve);
    } );
  }


  private checkSingleCheckPermition(scope: string, role: Role, instance: any, user: User, userProperty: string): boolean {
    if(!role?.privileges) return false;
    var res = scope.split('.').reduce(function(o, k) {
      return o && o[k];
    }, role.privileges);
    if (typeof res == "boolean") {
      return res;
    }

    if (typeof res == "number") {

      if (res == AllOrCreated.NONE) return false;
      if (res == AllOrCreated.CREATED) {
        if (instance === "skip")
          return true;
        if (instance?.createdBy?.id === user?.id)
          return true;
        if (userProperty && instance[userProperty]?.id === user?.id)
          return true;
        return false;
      }
      if (res == AllOrCreated.USER) return instance === "skip" || instance[userProperty]?.id === user?.id;
      if (res == AllOrCreated.BRANCH) return instance === "skip" || instance?.branchPath && user.branchPath && instance.branchPath.indexOf(user.branchPath) == 0;
      if (res == AllOrCreated.ALL) return true;
    }
    if (typeof res == "object") {
      return Object.values(res).reduce( (a: boolean,b: boolean) => a || b) as boolean;
    }
  }

  checkPermition(scope: string | string[], instance: any, userProperty: string = 'user'): Promise<boolean> {
    return new Promise<boolean>( (resolve, reject) => {
      if(!scope){
        resolve(true);
        return;
      };

      let scopes = Array.isArray(scope) ? scope : [scope];
      this.getUser().then( (user: User) => {
        this.rolesStore.pipe(select(fromRolesReducers.selectRole,user.role)).subscribe( (role: Role) => {
          let allowed = scopes.map( (item: string) => this.checkSingleCheckPermition(item, role, instance, user, userProperty)).reduce((a,b) => a || b);
          if (typeof allowed == "object") {
            allowed = Object.values(allowed).reduce( (a: boolean,b: boolean) => a || b) as boolean
          }
          resolve(allowed);
        })

      });

    });
  }
}
  // public static STORAGE_TOKEN_KEY = 'USER_TOKEN';
  // private token: string = null;
  // private user: User;

  // constructor(
  //   private router: Router,
  //   private http: HttpClient,
  //   @Inject(LOCAL_STORAGE) private storage: StorageService
  // ) {
  //   this.token = this.storage.get(AuthService.STORAGE_TOKEN_KEY) || null;
  // }

  // async loginByAuth(email:any , password:any ) {
  //  return this.http.post<any>('<backendhost>/v1/auth/login' , {email, password})
  //     .pipe(
  //       map((this.onSignupUserResponse.bind(this))),
  //       catchError((this.onSignupUserError.bind(this)))
  //     ).toPromise()
  // }

  // onSignupUserResponse(response: any) {
  //   this.token = response.token;
  //   this.storage.set(AuthService.STORAGE_TOKEN_KEY, this.token);
  // }

  // onSignupUserError(response: any) {
  //   throw(response.error.message)
  // }

  // setToken(token: string) {
  //   this.token = token;
  // }

  // getToken(): string {
  //   return this.token;
  // }

  // async fetchUser() {
  //   let promise = this.http.get<any>('<backendhost>/v1/user').toPromise();
  //   promise.catch(err => {throw(err)});
  //   return await promise;
  // }

  // async getUser() {
  //   if(!this.user) {
  //     this.user = await this.fetchUser();
  //   }
  //   return this.user;
  // }

  // getProfile() {

  // }

  // signOut() {
  //   this.token = null;
  //   this.storage.remove(AuthService.STORAGE_TOKEN_KEY);
  //   this.router.navigate(['/auth/login']);
  // }



// }

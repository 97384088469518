
<a class="btn btn-primary btn-block mb-2 text-white" routerLink="/chat/room/add"  checkRolePermition="chat.room.create">
  <i class="nav-icon fas fa-plus fa-fw align-slef-center"></i> Nowy pokój
</a>

<a class="user-panel pt-2 pb-2 d-flex align-items=center"
  routerLinkActive="bg-primary"
  style="margin: 0 -8px; padding: 0 8px;"
  *ngFor="let item of chats | async"
  [routerLink]="['/chat', item.id]"
>
  <div class="image position-relative flex-shrink-0">
    <img [src]="item | groupAvatar" class="img-circle elevation-2" alt="User Image"/>
    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary mr-3 mt-2"  *ngIf="item.unseenCount">
      {{item.unseenCount}}
    </span>
  </div>
  <div class="info flex-grow-1 pb-0" style="line-height: 1.2em; margin-top: -0.2rem;">
    <span *ngIf="!item.name && item.type == 'direct'" class="text-decoration-line-through" >Użytkownik usunięty</span>
    <span *ngIf="item.name || item.type != 'direct'">{{item.name}}</span>
    <br/>
    <p class="mb-2 d-flex">
      <small class="text-xs text-truncate flex-grow-1">
        <span *ngIf="item.lastMessage.short" [innerHtml]="item.lastMessage.short | safeHtml"></span>
        <span *ngIf="!item.lastMessage.short && item.lastMessage.files?.length">przesyła plik</span>
      </small>
      <small class="text-xs align-self-end text-muted">{{item.lastMessage.createdAt | amLocale:'pl' | amCalendar}}</small>
    </p>
  </div>
</a>

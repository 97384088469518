import { RedmineCategory, RedmineSubCategory } from '@/model/redmine.model';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';

import * as fromRedmineIssueActions from '@/store/redmine/redmine.issue.actions';
import * as fromRedmineIssueReducers from '@/store/redmine/redmine.issue.reducers';
import { Router, ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-redmine-create-issue',
  templateUrl: './redmine-create-issue.component.html',
  styleUrls: ['./redmine-create-issue.component.scss']
})
export class RedmineCreateIssueComponent implements OnInit {
  unsubscribe$ = new Subject<boolean>();
  form: FormGroup;
  category: RedmineCategory;
  subCategory: RedmineSubCategory;
  fileList: NzUploadFile[] = [];

  constructor(
    private store: Store<fromRedmineIssueReducers.State>,
    private toastr: ToastrService,
    private router: Router,
    protected actions$: Actions,
    protected activatedRoute$: ActivatedRoute,
  ) {

    this.form = new FormGroup({
      subject: new FormControl(null, Validators.required),
      description: new FormControl(null),
      category: new FormControl(null, Validators.required),
      subCategory: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {
    this.activatedRoute$.data.subscribe(data => {
      this.category = data.category;
      this.subCategory = data.subcategory;
      this.form.patchValue({
        category: this.category.id,
        subCategory: this.subCategory.id,
        description: this.subCategory.template,
      })
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  get saving(): Observable<boolean> {
    return this.store.select(fromRedmineIssueReducers.isSaving);
  }

  save() {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    if (this.form.valid) {
      this.store.dispatch(fromRedmineIssueActions.createIssue({
        item: this.form.value,
        attachments: this.fileList as unknown as File[]
      }));
      this.actions$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromRedmineIssueActions.setIssue)
      ).subscribe( (action: any) => {
        this.toastr.success('Utworzono nowe zgłoszenie');
        this.router.navigate(['/redmine/issue',action.item.id]);
        this.fileList = [];
      });
    } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
      console.log(this.form.value);
    }
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };
}

import { Scorm } from '@/model/scorm.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { environment } from 'environments/environment';
import { NgScormPlayerConfig, NgScormPlayerService, ScormResult } from 'ng-scorm-player';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-scorm-preview',
  templateUrl: './scorm-preview.component.html',
  styleUrls: ['./scorm-preview.component.scss']
})
export class ScormPreviewComponent implements OnInit {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  scorm: Scorm;
  result: ScormResult;

  constructor(
    private activatedRoute: ActivatedRoute,
    private player: NgScormPlayerService,
    private scormPlayerConfig: NgScormPlayerConfig
  ) {
    this.scormPlayerConfig.debug = false;
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe( (data: Data) => {
      this.scorm = data.instance;
    });
    console.log(this.player.Initialize())
    console.log(this.player)
    this.player.initializeEvent.subscribe(val => { console.log('initializeEvent:', val); });
    this.player.setValueEvent.subscribe(val => { console.log('setValueEvent:', val); });
    this.player.getValueEvent.subscribe(val => { console.log('getValueEvent:', val); });
    this.player.finishEvent.subscribe(val => { console.log('finishEvent:', val); });
    this.player.commitEvent.subscribe(val => { console.log('commitEvent:', val); });
    // this.player.scormResult = {
    //   timeSpent: null,
    //   progression: null,
    //   completed: false,
    //   completedOn: null,
    //   score: null,
    //   scoreMax: null,
    //   scorePercent: null,
    //   /** raw scorm data */
    //   runtimeData: {}
    // };
    // // this.player.Initialize();
    // // console.log(this.player)
    // // this.player.initializeEvent.subscribe(val => { console.log('initializeEvent:', val); });
    // this.player.setValueEvent.pipe(takeUntil(this.unsubscribe$)).subscribe(val => { console.log('setValueEvent:', val); });
    // this.player.getValueEvent.pipe(takeUntil(this.unsubscribe$)).subscribe(val => { console.log('getValueEvent:', val); });
    // // this.player.finishEvent.subscribe(val => { console.log('finishEvent:', val); });
    // // this.player.commitEvent.subscribe(val => { console.log('commitEvent:', val); });


  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  get entryPoint(): string {
    return `/assets/scorm/${this.scorm.code}/${this.scorm.entryPoint}`;
  }

  get scormDir(): string {
    return `/assets/scorm/${this.scorm.code}`;
  }

}

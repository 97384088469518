<section class="content">
  <div class="container">
    <div class="card card-primary">
      <div class="card-header d-flex align-items-center">
        <h3 class="card-title m-0 flex-grow-1 text-bold"><i class="fas fa-question fa-fw"></i> {{quiz?.name}}</h3>
      </div>
      <app-quiz-player *ngIf="quiz" [quiz]="quiz" [result]="result" (onFinish)="onFinish($event)" [reset]="resetSubject.asObservable()"></app-quiz-player>
    </div>
    <button class="btn btn-primary" (click)="reset()">Resetuj</button>
  </div>
</section>

import { StringifyHttpErrorResponse } from "@/utils/http.error.util";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { ErrorState, generalError } from "../error.store";

export class DefaultEffects {
  constructor(
    protected httpClient: HttpClient,
    protected toastr: ToastrService,
    protected errorStore: Store<ErrorState>
    ) {

  }

  onError (err, caught): any {
    // this.toastr.error(err.message)
    this.toastr.error(StringifyHttpErrorResponse(err));
    this.errorStore.dispatch(generalError());
    return caught;
  };
}

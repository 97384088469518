<section class="content">
  <div class="container-fluid">
    <form [formGroup]="form" (ngSubmit)="save()">
      <div class="card card-primary" style="border-top-left-radius: 0;border-top-right-radius: 0;">
        <div class="overlay position-absolute" *ngIf="(loading | async)" style="top: 0; bottom: 0; left: 0; right: 0">
          <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
            <i class="fas fa-3x fa-spinner fa-spin"></i>
            <p>Ładowanie...</p>
          </div>
        </div>
        <div class="overlay position-absolute" *ngIf="(saving | async)" style="top: 0; bottom: 0; left: 0; right: 0">
          <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
            <i class="fas fa-3x fa-spinner fa-spin"></i>
            <p>Zapisywanie...</p>
          </div>
        </div>
        <div class="card-body">
            <div class="form-group">
                <label for="inputName">Nazwa formularza oceny</label>
                <p>{{form.value.name}}</p>
            </div>
            <div class="form-group">
              <label for="inputName">Oceniany urzytkonwik</label>
              <app-user-input formControlName="user" [multiple]="false" [appIsValid]="form.controls.user" [disabled]="fill?.id"></app-user-input>
              <div class="invalid-feedback" *ngIf="form.controls.user.errors?.required">Pole jest wymagane</div>
            </div>
            <div class="form-group">
              <label for="inputName">Ścieżka pliku z zapisem rozmowy</label>
              <input formControlName="filePath" type="text" class="form-control" placeholder="Ścieżka pliku z zapisem rozmowy" [appIsValid]="form.controls.filePath"/>
              <div class="invalid-feedback" *ngIf="form.controls.filePath.errors?.required">Pole jest wymagane</div>
          </div>
            <div class="form-group">
              <label for="inputName">Data i godzina rozmowy</label>
              <app-date-time-picker formControlName="date" name="date" [appIsValid]="form.controls.date" inputDatetimeFormat="YYYY-MM-dd HH:mm"></app-date-time-picker>
              <div class="invalid-feedback" *ngIf="form.controls.date.errors?.required">Pole jest wymagane</div>
            </div>
        </div>
        <table class="table align-middle table-hover table-bordered">
          <thead>
            <tr>
              <th width=1></th>
              <th class="bg-primary">KARTA MONITORINGU</th>
              <th class="bg-primary">Waga obszaru</th>
              <th class="bg-primary">Waga pytania</th>
              <th class="bg-primary">Odpowiedź</th>
              <th class="bg-primary">Suma punktów</th>
              <th class="bg-primary">Uwagi</th>
              <th class="bg-primary">Notatki prywatne</th>
            </tr>
          </thead>
          <!-- <pre>{{form.value | json}}</pre> -->
          <ng-container *ngFor="let category of categories; let categoryIndex=index" [formGroup]="category">
              <tr>
                <th *ngIf="hasRowspan(categoryIndex)" [attr.rowspan]="rowspan(categoryIndex)" class="rotate" [class.bg-info]="category.controls.area.value">
                  {{category.controls.area.value}}
                </th>
                <th class="bg-info align-top">
                  {{category.controls.label.value}}
                </th>
                <th class="bg-info align-top">
                  {{category.controls.weight.value}}
                </th>
                <th class="bg-info align-top"></th>
                <th class="bg-info align-top"></th>
                <th class="bg-info align-top"></th>
                <th class="bg-info align-top"></th>
                <th class="bg-info align-top"></th>
              </tr>
              <tr *ngFor="let question of questions(categoryIndex); let i = index" [formGroup]="question">
                <td>
                  {{question.controls.label.value}}
                </td>
                <td></td>
                <td>
                  {{question.controls.weight.value}}
                </td>
                <td class="p-0">
                  <select formControlName="answer" class="form-control">
                    <option [value]="true">TAK</option>
                    <option [value]="false">NIE</option>
                    <option [value]="null">nie dotyczy</option>
                  </select>
                </td>
                <td>
                  {{question.controls.score.value}}
                </td>
                <td class="p-0">
                  <textarea formControlName="comment" class="form-control text-xs"></textarea>
                </td>
                <td class="p-0">
                  <textarea formControlName="commentPrivate" class="form-control text-xs"></textarea>
                </td>
              </tr>
          </ng-container>
          <tfoot>
            <tr>
              <th></th>
              <th class="bg-primary">Suma punktów</th>
              <th class="bg-primary"></th>
              <th class="bg-primary">100</th>
              <th class="bg-primary"></th>
              <th class="bg-primary">{{form.value.score}}</th>
              <th class="bg-primary"></th>
              <th class="bg-primary"></th>
            </tr>
          </tfoot>
        </table>
        <div class="card-body">
          <div class="form-group">
            <label for="inputName">Uwagi</label>
            <app-editor formControlName="comment"></app-editor>
          </div>
          <div class="form-group">
            <label for="inputName">Notatki prywatne</label>
            <app-editor formControlName="commentPrivate"></app-editor>
          </div>
        </div>
        <div class="card-footer">
            <button class="btn btn-success float-right" type="submit">
                Zapisz
                <i class="fas fa-angle-right fa-fw fa-lg"></i>
            </button>
        </div>
      </div>
    </form>
  </div>
</section>

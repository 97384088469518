import { PaginationDTO } from "@/model/pagination.model";
import { TrainingGroup } from "@/model/training.group.model";
import { User } from "@/model/user.model";
import { createAction, props } from "@ngrx/store";

export const clear = createAction("training-group clear");
export const fetch = createAction("training-group fetch");
export const fetchOne = createAction("training-group fetchOne", props<{id: number}>());
export const createTrainingGroup = createAction( "training-group createTrainingGroup",
  props<{item: TrainingGroup}>()
);
export const saveTrainingGroup = createAction( "training-group saveTrainingGroup",
  props<{item: TrainingGroup}>()
);
export const removeTrainingGroup = createAction( "training-group removeTrainingGroup",
  props<{id: number}>()
);

export const setTrainingGroups = createAction( "training-group setTrainingGroups",
  props<PaginationDTO<TrainingGroup>>()
);
export const addTrainingGroup = createAction( "training-group addTrainingGroup",
  props<{item: TrainingGroup}>()
);
export const updateTrainingGroup = createAction( "training-group updateTrainingGroup",
  props<{item: TrainingGroup}>()
);
export const deleteTrainingGroup = createAction( "training-group deleteTrainingGroup",
  props<{id: number}>()
);
export const setPage = createAction("training-group setPage", props<{ page: number }>());
export const setOrder = createAction("training-group setOrder", props<{ order: string }>());
export const setDirection = createAction("training-group setDirection", props<{ asc: boolean }>());
export const setSearch = createAction("training-group setSearch", props<{ search: string }>());


export const fetchMember = createAction( "training-group fetchMember",
  props<{group: TrainingGroup}>()
);

export const addMember = createAction( "training-group addMember",
  props<{user:User, group: TrainingGroup}>()
);

export const deleteMember = createAction( "training-group deleteMember",
  props<{user:User, group: TrainingGroup}>()
);

import { AuthService } from '@/auth/auth.service';
import { Offer } from '@/model/offer.model';
import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { from, Observable, of } from 'rxjs';
import * as fromOfferActions from '@/store/offer/offer.actions';
import * as fromOfferReducers from '@/store/offer/offers.reducers';
import { tap, filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OfferResolver implements Resolve<Offer> {
  constructor(
    private store: Store<fromOfferReducers.State>,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  check(resolve: any, instance : any, route: ActivatedRouteSnapshot) {
    this.authService.checkPermition(route.data.permition, instance).then( (alowed:boolean) => {
      if (alowed) {
        resolve(instance);
      } else {
        // resolve(null);
        this.toastr.error("Nie masz uprawnień do tej sekcji!");
        this.router.navigate(["/"]);
      }
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Offer> {
    return from( new Promise<Offer>(resolve => {
      this.store.pipe(
        select(fromOfferReducers.selectOfferById, 1*route.params.id),
        tap( (result) => {
          if(!result) {
            this.store.dispatch(fromOfferActions.fetchOne({id: 1*route.params.id}));
          }
        }),
        filter((result) => result != null),
        take(1),
      ).subscribe(instance => this.check(resolve, instance, route));
    }));
  }
}

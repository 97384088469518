<div class="card">
  <div class="card-header d-flex align-items-center">
    <h3 class="card-title m-0 flex-grow-1 text-bold"><i class="fas fa-user-lock fa-fw"></i> Zarządzanie rolami</h3>
    <div class="card-tools flex-grow-1">
      <!-- <div class="input-group">
        <input type="text" name="table_search" class="form-control float-right" placeholder="Szukaj" #searchInput>
        <button class="btn bg-transparent" style="position:absolute; right: 0; transform: translate(-100%,0);" (click)="clearSearch()">
          <i class="fa fa-times"></i>
        </button>
        <span class="input-group-append">
          <div class="input-group-text bg-transparent"><i class="fas fa-search"></i></div>
        </span>
      </div> -->
    </div>
  </div>
  <div class="table-responsive position-relative">
    <table class="table align-middle table-hover">
      <thead>
        <tr>
          <th style="width:1rem">#</th>
          <th>Nazwa</th>
          <th>ID</th>
          <th class="text-right" >
            <button class="btn btn-success btn-sm elevation-2 text-nowrap" [routerLink]="['add']" >
              <i class="fas fa-plus fa-fw fa-lg"></i> Dodaj
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of list | async; let idx = index; trackBy: identify">
          <th class="align-middle">{{idx + 1}}</th>
          <td class="align-middle">{{item.name}}</td>
          <td class="align-middle">{{item.id}}</td>
          <td class="align-middle text-nowrap p-0" style="width:0">
            <button class="btn btn-primary btn-sm elevation-2  m-1" [routerLink]="[ item.id]" ngbTooltip="Edycja" checkRolePermition="roles.admin">
              <i class="fas fa-edit fa-fw fa-lg"></i>
            </button>
            <button
                [disabled]="item.default"
                checkRolePermition="roles.delete"
                class="btn btn-danger btn-sm elevation-2 m-1"
                nz-popconfirm
                nzPopconfirmTitle="Czy na pewno usunąć?"
                nzOkText="Tak"
                nzCancelText="Nie"
                nzPopconfirmPlacement="left"
                ngbTooltip="Usuń"
                (nzOnConfirm)="delete(item)" >
              <i class="fas fa-trash fa-fw fa-lg"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

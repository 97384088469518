import { Survey, SurveyQuestion } from '@/model/survey.model';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { ActionsSubject, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import * as fromSurveyActions from '@/store/my-survey/my-survey.actions';
import * as fromSurveyReducers from '@/store/my-survey/my-survey.reducers';
import { environment } from 'environments/environment';
import { takeUntil } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'app-survey-submit',
  templateUrl: './survey-submit.component.html',
  styleUrls: ['./survey-submit.component.scss']
})
export class SurveySubmitComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  survey: Survey;

  constructor(
    private toastr: ToastrService,
    private store: Store<fromSurveyReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.form = new FormGroup({});
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data:Data) => {
      this.survey = {...this.survey, ...data.instance};
      this.initializeForm();
      // this.form.patchValue(this.survey);
      // this.store.pipe(
      //   select(fromSurveyReducers.selectOneSurvey, this.survey.id),
      //   takeUntil(this.unsubscribe$),
      //   distinctUntilChanged()
      // ).subscribe((survey) => {
      //   this.survey = survey;
      // })
    })
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  save(): void {
    if (this.form.valid) {
      this.survey = {...this.survey, ...this.form.value};
      this.store.dispatch(fromSurveyActions.submitSurvey({
        survey: this.survey,
        data: this.parseResult(this.form.value)
      }));
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromSurveyActions.success)
      ).subscribe( (action: any) => {
        this.toastr.success('Zapisano');
        this.router.navigate(['/survey']);
      });
    } else {
        this.toastr.error('Nie odpowiedziałeś na wszystkie pytania');
    }
  }

  backgroundStyle () {
    if(!this.survey)
      return null;
    if(!this.survey.background)
      return {
        'aspect-ratio': '4',
        'background' : `#ed1a3b`,
        'background-size': 'cover'
      }
    return {
      'aspect-ratio': '4',
      'background' : `url(${environment.apiUrl}/thumb/1920x0/${this.survey.background.replace('public','')}) center center`,
      'background-size': 'cover'
    }
  }

  getQuestionGroup(question: SurveyQuestion): FormGroup {
    return this.form.controls[question.id.toString()] as FormGroup;
  }

  get questions(): SurveyQuestion[] {
    return fromSurveyReducers.questionAdapter.getSelectors().selectAll(this.survey.questions);
  }

  parseResult(value: Object) {
    let result: any[] = Object.values(value).map(item => item.answer)
    result = result.map(item => {
      if (!item) return null;
      if(typeof item == "object") {
        return Object.entries(item).map((val) => val[1] ? +val[0] : null);
      }
      return item;
    });
    result = (result).concat(...result);
    let numeric = result.filter(item => typeof item == "number");

    let open = Object.entries(value)
                  .filter(([key,item]) => typeof item.answer == "string")
                  .reduce( (prev: any, [key,item]) => {
                    console.log(prev, key, item);
                    prev[key] = item.answer;
                    return prev;
                  }, {});
                  // .map(([key,item]) => {
                  //   let ret = {};
                  //   ret[key] = item.answer;
                  //   return ret;
                  // });
    // open = open.filter((v, i, a) => a.indexOf(v) === i);
    return {
      close: numeric.filter((v, i, a) => a.indexOf(v) === i),
      open: open
    };
  }

  CheckboxValidator = (fc: FormGroup) => {
    let hasCheck = Object.values(fc.value).reduce((a,b) => a || b, false);
    return hasCheck ? null : {requireds : true};
  };

  initializeForm() {
    this.questions.forEach((question:SurveyQuestion, idx: number) => {
      let correctAnswer;
      let answer;
      if (['radio', 'select', 'scale'].indexOf(question.type) != -1) {
        answer = new FormControl(null, Validators.required);
      }
      else if (['checkbox'].indexOf(question.type) != -1) {
        answer = new FormGroup({})
        answer.addValidators([this.CheckboxValidator]);
        question.options.forEach( option => {
          answer.addControl(option.id.toString(), new FormControl(false));
        })

        // new FormArray(question.options.map(() => new FormControl(false)));
      }
      else if (['open'].indexOf(question.type) != -1) {
        answer = new FormControl(null, Validators.required);
      }


      let questionsGroup = new FormGroup({
        "answer": answer,
      });
      this.form.addControl(question.id.toString(), questionsGroup);
    });
  }
}

import { Idea } from "@/model/idea.model";
import { Comment } from "@/model/comment.model";
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { environment } from "environments/environment";
import * as fromActions from './ideas.actions';

export interface State{
  public: {
    list: EntityState<Idea>;
    total: number;
    loading: boolean;
  },
  admin: {
    loading: boolean;
    saving: boolean;
    list: EntityState<Idea>;
    total: number;
    page: number;
    order: string;
    readonly limit: number;
    asc: boolean;
    search: string;
  }
}

const adapter = createEntityAdapter<Idea>({
  sortComparer: (a: Idea, b: Idea) => {
    return a.id < b.id ? 1 : -1
  }
});
const commnetAdapter = createEntityAdapter<Comment>();

export const initialState: State = {
  public: {
    loading: false,
    list: adapter.getInitialState(),
    total: 0
  },
  admin: {
    loading: false,
    saving: false,
    list: adapter.getInitialState(),
    total: 0,
    page: 1,
    order: 'id',
    limit:  environment.pageSize,
    asc: true,
    search: null
  }
}

export const reducer = createReducer(
  initialState,
  on(fromActions.fetchAdminIdeas, (state,action) => {
    let change = {loading: true};
    return {...state, ...{admin : {...state.admin, ... change}}};
  }),
  on(fromActions.setAdminPage, (state, action) => {
    let change = {page: action.page};
    return {...state, ...{admin : {...state.admin, ... change}}};
  }),
  on(fromActions.setAdminSearch, (state, action) => {
    let change = { search: action.search };
    return {...state, ...{admin : {...state.admin, ... change}}};
  }),
  on(fromActions.setAdminOrder, (state, action) => {
    let change = { order: action.order };
    return {...state, ...{admin : {...state.admin, ... change}}};
  }),
  on(fromActions.setAdminDirection, (state, action) => {
    let change = { direction: action.direction };
    return {...state, ...{admin : {...state.admin, ... change}}};
  }),
  on(fromActions.reset, (state, action) => {
    return initialState
  }),
  on(fromActions.replaceAdminIdeas, (state,action) => {
    let change = {
      loading: false,
      total: action.total,
      list: adapter.addMany(action.data, adapter.getInitialState())
    };
    return {...state, ...{admin : {...state.admin, ... change}}};
  }),
  on(fromActions.updateAdminIdea, (state, action) => {
    let change = { list: adapter.updateOne(action, state.admin.list) };
    return {...state, ...{admin : {...state.admin, ... change}}};
  }),
  on(fromActions.removeAdminIdea, (state, action) => {
    let change = { list: adapter.removeOne(action.id, state.admin.list) };
    return {...state, ...{admin : {...state.admin, ... change}}};
  }),


  on(fromActions.fetchPublicIdeas, (state, action) => {
    let change = {loading: true};
    return {...state, ...{public : {...state.public, ... change}}};
  }),
  on(fromActions.removeIdea, (state, action) => {
    let change = { list: adapter.removeOne(action.id, state.public.list) };
    return {...state, ...{public : {...state.public, ... change}}};
  }),
  on(fromActions.updateIdea, (state, action) => {
    let change = { list: adapter.updateOne(action, state.public.list) };
    return {...state, ...{public : {...state.public, ... change}}};
  }),
  on(fromActions.addPublicIdeas, (state, action) => {
    return {...state,
      ...{
        public : {
          loading: false,
          total: action.total,
          list: adapter.addMany(action.data, state.public.list)
        }
      }
    };
  }),
  on(fromActions.addIdea, (state, action) => {
    return {...state,
      ...{
        public : {
          loading: false,
          total: state.public.total+1,
          list: adapter.addOne(action.idea, state.public.list)
        }
      }
    };
  }),
  on(fromActions.addManyCommentsToIdeaAction, (state, action) => {
    let idea = state.public.list.entities[action.idea.id];
    let ideasPublicList = adapter.updateOne({
      id: action.idea.id,
      changes: {
        comments : {
          count: +idea.comments.count,
          list: commnetAdapter.addMany(action.comments, idea.comments.list)
        }
      }
    }, state.public.list);
    return {...state,
      ...{
        public : {
          loading: false,
          total: state.public.total,
          list: ideasPublicList
        }
      }
    };
  }),
  on(fromActions.addCommentToIdeaAction, (state, action) => {
    let idea = state.public.list.entities[action.idea.id];
    let ideasPublicList = adapter.updateOne({
      id: action.idea.id,
      changes: {
        comments : {
          count: +idea.comments.count + 1,
          list: commnetAdapter.addOne(action.comment, idea.comments.list)
        }
      }
    }, state.public.list);
    return {...state,
      ...{
        public : {
          loading: false,
          total: state.public.total,
          list: ideasPublicList
        }
      }
    };
  }),
  on(fromActions.addCommentToIdeaCommentAction, (state, action) => {
    let idea = state.public.list.entities[action.idea.id];
    let replyTo = idea.comments.list.entities[action.replyTo.id];
    let ideasPublicList = adapter.updateOne({
      id: action.idea.id,
      changes: {
        comments : {
          count: +idea.comments.count + 1,
          list: commnetAdapter.updateOne({
            id: replyTo.id,
            changes: {
              replies: commnetAdapter.addOne(action.comment,replyTo.replies)
            }
          }, idea.comments.list)
        }
      }
    }, state.public.list);
    return {...state,
      ...{
        public : {
          loading: false,
          total: state.public.total,
          list: ideasPublicList
        }
      }
    };
  }),
  on(fromActions.updateIdeaCommentAction, (state, action) => {
    let idea = state.public.list.entities[action.idea.id];
    let comment = idea.comments.list.entities[action.comment.id];
    let ideasPublicList = adapter.updateOne({
      id: action.idea.id,
      changes: {
        comments : {
          count: +idea.comments.count,
          list: commnetAdapter.updateOne({
            id: comment.id,
            changes: action.comment
          }, idea.comments.list)
        }
      }
    }, state.public.list);
    return {...state,
      ...{
        public : {
          loading: false,
          total: state.public.total,
          list: ideasPublicList
        }
      }
    };
  }),
  on(fromActions.updateIdeaReplyCommentAction, (state, action) => {
    let idea = state.public.list.entities[action.idea.id];
    let replyTo = idea.comments.list.entities[action.replyTo.id];
    let ideasPublicList = adapter.updateOne({
      id: action.idea.id,
      changes: {
        comments : {
          count: +idea.comments.count,
          list: commnetAdapter.updateOne({
            id: replyTo.id,
            changes: {
              replies: commnetAdapter.updateOne({
                id: action.comment.id,
                changes: action.comment
              }, replyTo.replies)
            }
          }, idea.comments.list)
        }
      }
    }, state.public.list);
    return {...state,
      ...{
        public : {
          loading: false,
          total: state.public.total,
          list: ideasPublicList
        }
      }
    };
  }),

  on(fromActions.deleteIdeaComment, (state, action) => {
    let idea = state.public.list.entities[action.comment.idea.id];
    let ideasPublicList = state.public.list;
    if(!action.comment.replyTo || !action.comment.replyTo.id) {
      ideasPublicList = adapter.updateOne({
        id: idea.id,
        changes: {
          comments : {
            count: +idea.comments.count - 1 - action.comment.replies.ids.length,
            list: commnetAdapter.removeOne(action.comment.id, idea.comments.list)
          }
        }
      }, state.public.list);
    } else {
      let reply = idea.comments.list.entities[action.comment.replyTo.id]
      ideasPublicList = adapter.updateOne({
        id: idea.id,
        changes: {
          comments : {
            ...idea.comments,
            ...{
              count: +idea.comments.count - 1,
              list: commnetAdapter.updateOne({
                id: reply.id,
                changes: {
                  replies: commnetAdapter.removeOne(action.comment.id, reply.replies)
                }
              }, idea.comments.list)
            }
          }
        }
      }, state.public.list);
    }
    return {...state,
      ...{
        public : {
          loading: false,
          total: state.public.total,
          list: ideasPublicList
        }
      }
    };
  }),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectIdeasState = createFeatureSelector<State>('ideas');

export const selectAllAdminIdeas = createSelector(selectIdeasState, (state) => selectAll(state.admin.list));
export const selectAllPublicIdeas = createSelector(selectIdeasState, (state) => selectAll(state.public.list));
export const selectPublicIdea = createSelector(selectIdeasState, (state, id) => state.public.list.entities[id]);
export const selectHasMorePublicIdeas = createSelector(selectIdeasState, (state) => state.public.total > state.public.list.ids.length);

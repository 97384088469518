import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ToastrService } from "ngx-toastr";
import * as fromActions from '@/store/structure/structure.actions';
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { Branch } from "@/model/branch.model";
import { EntityState } from "@ngrx/entity";


@Injectable()
export class StructureEffects {
  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    // private store$: Store<Reducers.PostsState>,
    private toastr: ToastrService
  ) {
  }

  onError (err, caught): any {
    this.toastr.error(err.message)
    return caught;
  };

  fetch$ = createEffect(() => {
    return  this.actions$.pipe(
      ofType(fromActions.fetch),
      switchMap((action) => {
        return this.httpClient.get<EntityState<Branch>>('<backendhost>/v1/branch', {observe: 'body',responseType: 'json'});
      }),
      catchError(this.onError.bind(this)),
      switchMap( (payload: any) => {
        let actionsToDistpatch = [];
        actionsToDistpatch.push(fromActions.addAll({payload}))
        return actionsToDistpatch;
      })
    );
  });

  fetchOne$ = createEffect(() => {
    return  this.actions$.pipe(
      ofType(fromActions.fetchOne),
      switchMap((action) => {
        return this.httpClient.get<Branch>(`<backendhost>/v1/branch/${action.id}`, {observe: 'body',responseType: 'json'});
      }),
      catchError(this.onError.bind(this)),
      switchMap( (branch: any) => {
        let actionsToDistpatch = [];
        console.log("fetchOneSuccess", branch);
        actionsToDistpatch.push(fromActions.fetchOneSuccess({branch}))
        return actionsToDistpatch;
      })
    );
  });

  createBranch$ = createEffect(() => {
    return  this.actions$.pipe(
      ofType(fromActions.createBranchAction),
      switchMap((action) => {
        const formData = new FormData();
        if(action.image) {
          formData.append('image', action.image, action.image.name);
        }
        formData.append('title', action.branch.title);
        formData.append('description', action.branch.description);
        if(action.branch.parent) {
          formData.append('parent', action.branch.parent.toString());
        }
        return this.httpClient.post<Branch>('<backendhost>/v1/branch',
            formData,
            {observe: 'body',responseType: 'json'});
      }),
      catchError(this.onError.bind(this)),
      switchMap( (response: any) => {
        console.log(response);
        let actionsToDistpatch = [];
        actionsToDistpatch.push(fromActions.saveSuccess({id: response.id}))
        actionsToDistpatch.push(fromActions.addOne({branch: response}))
        return actionsToDistpatch;
      })
    );
  });

  deleteBranch$ = createEffect(() => {
    return  this.actions$.pipe(
      ofType(fromActions.deleteOne),
      switchMap((action) => {
        return this.httpClient.delete<Branch>(`<backendhost>/v1/branch/${action.branch.id}`, {observe: 'body',responseType: 'json'})
        .pipe(map(response => action.branch));
      }),
      catchError(this.onError.bind(this)),
      switchMap( (response: any) => {
        this.toastr.success("Usunięto oddział");
        let actionsToDistpatch = [];
        actionsToDistpatch.push(fromActions.removeOne({branch: response}))
        return actionsToDistpatch;
      })
    );
  });

  editBranch$ = createEffect(() => {
    return  this.actions$.pipe(
      ofType(fromActions.editBranch),
      switchMap((action) => {
        const formData = new FormData();
        if(action.image) {
          console.log(action.image, action.image.name);
          formData.append('image', action.image, action.image.name);
        }
        formData.append('title', action.branch.title);
        formData.append('description', action.branch.description);
        if(action.branch.parent) {
          formData.append('parent', action.branch.parent.toString());
        };
        return this.httpClient.post<{previous: Branch, current: Branch}>(
          `<backendhost>/v1/branch/${action.branch.id}/update`,
          formData,//action.branch,
          {observe: 'body',responseType: 'json'})
      }),
      catchError(this.onError.bind(this)),
      switchMap( (response: {previous: Branch, current: Branch}) => {
        let actionsToDistpatch = [];
        actionsToDistpatch.push(fromActions.saveSuccess({id: response.current.id}));
        console.log(response.current.parent == response.previous.parent);
        if (response.current.parent == response.previous.parent) {
          actionsToDistpatch.push(fromActions.updateBranch({branch: response.current}));
        } else {
          actionsToDistpatch.push(fromActions.removeOne({branch: response.previous}));
          actionsToDistpatch.push(fromActions.addOne({branch: response.current}));
        }


        return actionsToDistpatch;
      })
    );
  });

  updatePos$ = createEffect(() => {
    return  this.actions$.pipe(
      ofType(fromActions.updatePositions),
      switchMap((action) => {
        return this.httpClient.post<any>(`<backendhost>/v1/branch/0/posiotns`, action.payload, {observe: 'body',responseType: 'json'})
      }),
      catchError(this.onError.bind(this)),
      // switchMap( (response: any) => {
      //   this.toastr.success("Usunięto oddział");
      //   let actionsToDistpatch = [];
      //   actionsToDistpatch.push(fromActions.removeOne({branch: response}))
      //   return actionsToDistpatch;
      // })
    );
  });
}

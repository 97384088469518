<section class="content">
  <div class="container">
      <div class="card card-primary" style="border-top-left-radius: 0;border-top-right-radius: 0;">
        <div class="overlay position-absolute" *ngIf="(loading | async)" style="top: 0; bottom: 0; left: 0; right: 0">
          <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
            <i class="fas fa-3x fa-spinner fa-spin"></i>
            <p>Ładowanie...</p>
          </div>
        </div>
        <div class="card-body">
          <div class="form-group">
              <label for="inputName">Nazwa formularza oceny</label>
              <p>{{fill.name}}</p>
          </div>
          <div class="form-group">
            <label for="inputName">Oceniany urzytkonwik</label>
            <table>
              <tr>
                <td class="user-panel" style="width:70px; overflow: visible;">
                  <img
                        [src]="fill.user  | userAvatar: 34"
                        class="img-circle elevation-2"
                        alt="User Image"
                        style="width:45px"
                        [appUserDisactivatable]="fill.user"
                    />
                </td>
                <td>
                  <span class="text-decoration-line-through" *ngIf="!fill?.user">Użytkownik usunięty</span>
                  <strong [appUserDisactivatable]="fill.user">{{fill.user?.name}}</strong> <br>
                  <small [appUserDisactivatable]="fill.user">{{ fill?.user?.active ? fill?.user?.email : '-'}}</small>
                </td>
              </tr>
            </table>
          </div>
          <div class="form-group">
            <label for="inputName">Ścieżka pliku z zapisem rozmowy</label>
            <p>{{fill.filePath}}</p>
          </div>
          <div class="form-group">
            <label for="inputName">Data i godzina rozmowy</label>
            <p>{{fill.date | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</p>
          </div>
          <div class="form-group">
            <label for="inputName">Oceniony przez</label>
            <!-- <p>{{fill.createdBy.name}}</p> -->
            <table>
              <tr>
                <td class="user-panel" style="width:70px; overflow: visible;">
                  <img
                        [src]="fill.createdBy  | userAvatar: 34"
                        class="img-circle elevation-2"
                        alt="User Image"
                        style="width:45px"
                        [appUserDisactivatable]="fill.createdBy"
                    />
                </td>
                <td>
                  <span class="text-decoration-line-through" *ngIf="!fill?.createdBy">Użytkownik usunięty</span>
                  <strong [appUserDisactivatable]="fill.createdBy">{{fill.createdBy?.name}}</strong> <br>
                  <small [appUserDisactivatable]="fill.createdBy">{{ fill?.createdBy?.active ? fill?.createdBy?.email : '-'}}</small>
                </td>
              </tr>
            </table>
          </div>
          <div class="form-group">
            <label for="inputName">Data wystawienia oceny</label>
            <p>{{fill.createdAt | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</p>
          </div>
        </div>
        <table class="table align-middle table-hover table-bordered">
          <thead>
            <tr>
              <th width=1></th>
              <th class="bg-primary">KARTA MONITORINGU</th>
              <th class="bg-primary">Waga obszaru</th>
              <th class="bg-primary">Waga pytania</th>
              <th class="bg-primary">Odpowiedź</th>
              <th class="bg-primary">Suma punktów</th>
              <th class="bg-primary">Uwagi</th>
              <th class="bg-primary" *ngIf="canSeePrivateNotes">Notatki prywatne</th>
            </tr>
          </thead>
          <ng-container *ngFor="let category of fill.categories; let categoryIndex=index">
              <tr>
                <th *ngIf="hasRowspan(categoryIndex)" [attr.rowspan]="rowspan(categoryIndex)" class="rotate" [class.bg-info]="category.area">
                  {{category.area}}
                </th>
                <th class="bg-info align-top">
                  {{category.label}}
                </th>
                <th class="bg-info align-top">
                  {{category.weight}}%
                </th>
                <th class="bg-info align-top"></th>
                <th class="bg-info align-top"></th>
                <th class="bg-info align-top"></th>
                <th class="bg-info align-top"></th>
                <th class="bg-info align-top" *ngIf="canSeePrivateNotes"></th>
              </tr>
              <tr *ngFor="let question of category.questions; let i = index">
                <td>
                  {{question.label}}
                </td>
                <td></td>
                <td>
                  {{question.weight}}%
                </td>
                <td>
                  <span *ngIf="question.answer === true">TAK</span>
                  <span *ngIf="question.answer === false">NIE</span>
                  <span *ngIf="question.answer === null">nie dotyczy</span>
                </td>
                <td>
                  {{question.answer === null ? null : question.score + '%'}}
                </td>
                <td>{{question.comment}}</td>
                <td *ngIf="canSeePrivateNotes">{{question.commentPrivate}}</td>
              </tr>
          </ng-container>
          <tfoot>
            <tr>
              <th></th>
              <th class="bg-primary">Suma punktów</th>
              <th class="bg-primary"></th>
              <th class="bg-primary">100%</th>
              <th class="bg-primary"></th>
              <th class="bg-primary">{{fill.score}}%</th>
              <th class="bg-primary"></th>
              <th class="bg-primary" *ngIf="canSeePrivateNotes"></th>
            </tr>
          </tfoot>
        </table>
        <div class="card-body">
          <div class="form-group" *ngIf="fill.comment">
            <label for="inputName">Uwagi</label>
            <div bind-innerHTML="fill.comment"></div>
          </div>
          <div class="form-group" *ngIf="fill.commentPrivate">
            <label for="inputName">Notatki prywatne</label>
            <div bind-innerHTML="fill.commentPrivate"></div>
          </div>
        </div>
      </div>
  </div>
</section>

import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { from, Observable, of } from 'rxjs';
import * as fromPostReducer from '@/store/post/post.reducers';
import * as fromPostActions from '@/store/post/post.actions';
import { select, Store } from '@ngrx/store';
import { Post } from '@/model/post.model';
import { delay, filter, map, take, takeWhile, tap } from 'rxjs/operators';
import { AuthService } from '@/auth/auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class PostResolverResolver implements Resolve<Post> {
  constructor(
    private store: Store<fromPostReducer.PostsState>,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  check(resolve: any, instance : any, route: ActivatedRouteSnapshot) {
    this.authService.checkPermition(route.data.permition, instance).then( (alowed:boolean) => {
      if (alowed) {
        resolve(instance);
      } else {
        // resolve(null);
        this.toastr.error("Nie masz uprawnień do tej sekcji!");
        this.router.navigate(["/"]);
      }
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Post> {
    console.log('RESOLVE');
    return from( new Promise<Post>(resolve => {
      this.store.pipe(
        select(fromPostReducer.selectEntity, 1*route.params.id),
        tap( (result) => {
          if(!result) {
            this.store.dispatch(fromPostActions.fetchOneAction({id: 1*route.params.id}));
          }
        }),
        filter((result) => result != null),
        take(1),
      ).subscribe(instance => this.check(resolve, instance, route));
    }));
  }
}

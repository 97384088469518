
  <div class="card-footer position-relative">
    <div class="overlay"
    *ngIf="post?.comments?.saving"
    style="position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;">
      <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;    padding: 7px 0;">
        <i class="fas fa-3x fa-spinner fa-spin"></i>
      </div>
    </div>
    <form
      #commentForm="ngForm"
      class="d-flex"
      (ngSubmit)="onSubmit(commentForm)"
      (keydown.enter)="onSubmit(commentForm)">
      <img *ngIf="user" class="img-fluid img-circle img-sm align-self-center" [src]="user | userAvatar" alt="Alt Text" [appUserDisactivatable]="user">
      <div class="input-group pl-2">
        <app-editor [baloon]="true" style="flex: 1 1 auto;" class="small-editor" required ngModel name="content"></app-editor>
          <span class="input-group-append pl-2">
              <button type="submit" class="btn btn-primary" (touchEnd)="onSubmit(commentForm)" (click)="onSubmit(commentForm)" ngbTooltip="Wyślij" style="border-radius: 50%;
              aspect-ratio: 1;
              padding: 0;
              height: 2.5rem;">
                <i class="fas fa-paper-plane fa-fw"></i>
              </button>
          </span>
      </div>
    </form>
  </div>

import { Idea } from '@/model/idea.model';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import * as fromActions from '@/store/ideas/ideas.actions';
import * as fromReducers from '@/store/ideas/ideas.reducers';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-idea-modal',
  templateUrl: './edit-idea-modal.component.html',
  styleUrls: ['./edit-idea-modal.component.scss']
})
export class EditIdeaModalComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  private _idea: Idea;

  DateTimeValidator = (fc: FormControl) => {
    const date = new Date(fc.value);
    const isValid = !isNaN(date.valueOf());
    return isValid ? null : {
        invalidDate: true
    };
  };

  constructor(
    public modal: NgbActiveModal,
    private store: Store<fromReducers.State>,
    private toastr: ToastrService,
    protected actions$: Actions,
  ) {
    this.actions$.pipe(
      ofType(fromActions.updateAdminIdea),
      takeUntil(this.unsubscribe$)
    ).subscribe((action) => {
      this.toastr.success("Zapisano");
      this.modal.close();
    });

    this.form = new FormGroup({
      status: new FormControl(null, Validators.required),
      publicUntil:  new FormControl(null, [this.DateTimeValidator.bind(this)]),
      votingUntil:  new FormControl(null, [this.DateTimeValidator.bind(this)]),
    });
   }

  ngOnInit(): void {
    let date = new Date();
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get idea(): Idea { return this._idea; }
  set idea(idea: Idea) {
    this._idea = idea;
    let formValue = {
      status: idea.status,
      votingUntil: idea.publicUntil ? moment(idea.publicUntil).format('YYYY-MM-DD HH:mm:ss') : moment().add(1, 'M').format('YYYY-MM-DD 00:00:59'),
      publicUntil: idea.publicUntil ? moment(idea.publicUntil).format('YYYY-MM-DD HH:mm:ss') : moment().add(3, 'M').format('YYYY-MM-DD 00:59:59'),
    };
    console.log(formValue);
    this.form.patchValue(formValue);
  }

  send() {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    console.log("OK");

    if (this.form.valid) {
      let idea = {...this.form.value, ...{
        votingUntil: this.form.value.votingUntil.toLocaleString('pl-PL'),
        publicUntil: this.form.value.publicUntil.toLocaleString('pl-PL'),
      }}
      this.store.dispatch(fromActions.saveIdea({id: this.idea.id, idea: this.form.value}));
    } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }

}

import { User } from '@/model/user.model';
import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';

@Pipe({
  name: 'userAvatar'
})
export class UserAvatarPipe implements PipeTransform {
  private static regex = /(\b[a-zA-Z])/;
  private readonly colors = ['E57373', 'F06292', 'BA68C8', '9575CD', '7986CB', '64B5F6', '4FC3F7', '4DD0E1', '4DB6AC', '81C784', 'AED581', 'DCE775', 'FFF176', 'FFD54F', 'FFB74D', 'FF8A65', 'A1887F', 'E0E0E0', '90A4AE'];

  transform(user: any, size: number = 45, fallback = true): string {
    return user ? this.getAvatar(user.avatar, `${user.firstname} ${user.lastname}`, user.id, size, fallback) : '/assets/img/default-profile.png';
  }

  protected getAvatar(image: string, name: string, id: number, size: number, fallback: boolean): string {
    if (image) {
      return `${environment.apiUrl}/thumb/${size}x${size}/${image}`;
    } else if (fallback) {
      let color = this.colors[id % this.colors.length];
      name = name.toLowerCase();
      let regResult1 = /(\b[a-zA-Z])/.exec(name);
      let regResult2 = /([ ]\b[a-zA-Z])/.exec(name);
      let result = `${regResult1 ? regResult1[0] : ''} ${regResult2 ? regResult2[0] : ''}`;

      return `${environment.apiUrl}/avatar/${encodeURIComponent(result.trim())}/${size}/${color}.png`;
      // return `https://eu.ui-avatars.com/api/?name=${name}&size=${size}&background=${color}`;
    } else {
      return null;
    }
  }

}

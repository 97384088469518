<section class="content">
  <div class="container pt-3 pb-3">
    <form [formGroup]="form" (ngSubmit)="save()">
      <div class="card card-primary">
        <div class="overlay" *ngIf="(saving | async)">
          <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
            <i class="fas fa-3x fa-spinner fa-spin"></i>
            <p>Zapisywanie...</p>
          </div>
        </div>
        <div class="overlay" *ngIf="(loading | async)">
          <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
            <i class="fas fa-3x fa-spinner fa-spin"></i>
            <p>Ładowanie...</p>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-9">
              <div class="form-group">
                <label for="title">Nazwa</label>
                <input formControlName="title" type="text" [appIsValid]="form.controls.title" class="form-control" placeholder="Nazwa" />
                <div class="invalid-feedback" *ngIf="form.controls.title.errors?.required">
                  Pole jest wymagane
                </div>
              </div>
              <div class="form-group">
                <label for="lastname">Opis</label>
                <app-editor formControlName="description"></app-editor>
                <div class="text-danger" *ngIf="form.controls.description.touched && form.controls.description.errors?.required">
                  Pole jest wymagane
                </div>
              </div>
            </div>
            <div class="form-group col-md-3">
              <label for="inputName">Obrazek</label>
              <div style="max-width: 250px;">
                <app-upload-crop-image
                  [avatar]="true"
                  [(file)] = "imageFile"
                  [image]="reservationCategory?.image | image"
                  [aspectRatio]="1 / 1">
                </app-upload-crop-image>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button class="btn btn-success float-right" type="submit" [disabled]="(saving | async)">
            Zapisz
            <i class="fas fa-angle-right fa-fw"></i>
          </button>
        </div>
      </div>
    </form>
  </div>
</section>

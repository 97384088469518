import { Survey } from '@/model/survey.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import * as fromSurveyAction from '@/store/survey/survey.actions';
import * as fromSurveyReducers from '@/store/survey/survey.reducers';

@Component({
  selector: 'app-survey-admin-tab',
  templateUrl: './survey-admin-tab.component.html',
  styleUrls: ['./survey-admin-tab.component.scss']
})
export class SurveyAdminTabComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  survey: Survey;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<fromSurveyReducers.State>,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe( (data: Data) => {
      this.survey = data.instance;
      if(!data.instance?.id)
        return;
      this.store.pipe(
        select(fromSurveyReducers.selectOneSurvey, this.survey?.id),
        takeUntil(this.unsubscribe$),
        distinctUntilChanged()
      ).subscribe((item) => {
        this.survey = item;
      });
    });
  }

}

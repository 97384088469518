<section class="content">
  <div class="container-xl pt-3 pb-3">
    <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(list | async).length == 0"></nz-empty>
    <div class="search-results"
          infinite-scroll
          [infiniteScrollDistance]="scrollDistance"
          [infiniteScrollUpDistance]="scrollUpDistance"
          [infiniteScrollThrottle]="throttle"
          [immediateCheck]="true"
          (scrolled)="onScroll()"
          (scrolledUp)="onScrollUp()">
      <app-idea-item [item]="item" *ngFor="let item of list | async; let idx = index; trackBy: identify ">
      </app-idea-item>
      <button
        type="button"
        class="btn btn-block btn-outline-primary btn-block"
        *ngIf="(hasMore | async)"
        (click)="loadMore()"
        [ngClass]="{'disabled' : (loading | async)}">
        <i class="fas" [ngClass]="{'fa-spinner fa-spin' : (loading | async), 'fa-sync' : !(loading | async)}"></i>
        załaduj więcej
      </button>
    </div>
  </div>
</section>

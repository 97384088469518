import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Comment } from '@/model/comment.model';
import * as fromPostReducers from '@/store/post/post.reducers';
import * as fromPostActions from '@/store/post/post.actions';
import * as fromIdeaActions from '@/store/ideas/ideas.actions';
import * as fromIdeaReducers from '@/store/ideas/ideas.reducers';
import { Store } from '@ngrx/store';
import { AuthService } from '@/auth/auth.service';
import { User } from '@/model/user.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'sub-comment',
  templateUrl: './sub.component.html',
  styleUrls: ['./sub.component.scss']
})
export class SubComponent implements OnInit {
  @Input() comment: Comment;
  @Output() onReplyClick: EventEmitter<Comment> = new EventEmitter();
  private user: User;
  fragment: string;

  constructor(
    private store: Store<fromPostReducers.PostsState>,
    private ideaStore: Store<fromIdeaReducers.State>,
    authService: AuthService,
    private route: ActivatedRoute
  ) {
    authService.getUser().then(user => this.user = user)
  }

  ngOnInit(): void {
    this.route.fragment.subscribe( (fragment) => {
      this.fragment = fragment;
    })
  }

  replyClick(): void {
    this.onReplyClick.emit(this.comment);
  }

  likeToggle(): void {
    this.comment = {...this.comment, ...{likeSaving:true}};
    if (this.comment.liked) {
      if (this.comment.post) {
        this.store.dispatch(fromPostActions.unlikeCommentAction({comment: this.comment}))
      }
      if (this.comment.idea) {
        this.ideaStore.dispatch(fromIdeaActions.unlikeIdeaCommentAction({comment: this.comment}))
      }
    } else {
      if (this.comment.post) {
        this.store.dispatch(fromPostActions.likeCommentAction({comment: this.comment}))
      }
      if (this.comment.idea) {
        this.ideaStore.dispatch(fromIdeaActions.likeIdeaCommentAction({comment: this.comment}))
      }
    }
  }

  delete() {
    console.log("DELETE");
    if (this.comment.post) {
      this.store.dispatch(fromPostActions.deleteComment({comment: this.comment}));
    }
    if (this.comment.idea) {
      console.log("DELETE idea");
      this.ideaStore.dispatch(fromIdeaActions.deleteIdeaComment({comment: this.comment}))
    }
  }

  canEdit(): boolean {
    if(!this.user)
      return false;
    if(this.user.role == 'admin')
      return true;
    if(this.user.id == this.comment.createdBy.id)
      return true;
    return false;
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Subject } from 'rxjs';
import { TrainingResult } from '@/model/package.model';
import { Multimedia, Slide } from '@/model/multimedia.model';
import { NzTreeHigherOrderServiceToken } from 'ng-zorro-antd/core/tree';

@Component({
  selector: 'app-multimedia-play',
  templateUrl: './multimedia-play.component.html',
  styleUrls: ['./multimedia-play.component.scss']
})
export class MultimediaPlayComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  resetSubject: Subject<void> = new Subject<void>();
  multimedia: Multimedia;
  training: Multimedia;
  slideIndex: number = 0;
  slideResults: TrainingResult[] = [];
  result: TrainingResult;
  constructor(
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data:Data) => {
      this.multimedia = {...this.multimedia, ...data.instance};
      this.training = {...this.training, ...data.instance};
      this.reset();
      // this.slide = this.multimedia.slides[this.slideIndex];
    });
  }

  get slide(): Slide {
    return this.multimedia.slides[this.slideIndex];
  }

  get slideResult(): TrainingResult {
    return this.slideResults[this.slideIndex];
  }

  next() {
    this.slideIndex = Math.min(this.slideIndex + 1, this.training.slides.length);
  }

  back() {
    this.slideIndex = Math.max(this.slideIndex - 1, 0);
  }

  setIdx(idx: number) {
    this.slideIndex = idx;
  }

  reset() {
    this.result = {
      id: 0,
      completed: false,
      completedOn: null,
      score: null,
      scoreMax: null,
      scorePercent: null,
      progression: null,
      timeSpent: null,
      runtimeData: {}
    };
    this.slideResults = [];
    this.multimedia.slides.forEach(slide => {
      this.slideResults.push({
        id: 0,
        completed: false,
        completedOn: null,
        score: null,
        scoreMax: null,
        scorePercent: null,
        progression: null,
        timeSpent: null,
        runtimeData: {}
      });
    })
    this.resetSubject.next();
    this.slideIndex = 0;
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onFinish(result) {
    this.slideResults[this.slideIndex] = result;
  }

  private sidebarOpen = true;
  sidebarToggle() {
    this.sidebarOpen = !this.sidebarOpen;
  }

  sidebarStyle() {
    return {
      "right" : this.sidebarOpen ? '0px' : '-250px'
    }
  }
}

import { Outpost } from "@/model/outpost.model";
import { createPaginationInitialState, Pagination } from "@/model/pagination.model";
import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import * as actions from './outposts.actions';

export interface State extends Pagination<Outpost> {}

export const adapter: EntityAdapter<Outpost> = createEntityAdapter<Outpost>();

export const initialState: State = {
  ...createPaginationInitialState(adapter, false),
  ...{limit: 50}
};

export const reducer = createReducer(
  initialState,
  on(actions.clear, (state) => initialState),
  on(actions.fetch, (state) => { return {...state, ...{loading: true}}}),
  on(actions.fetchOneOutpost, (state) => { return {...state, ...{loading: true}}}),
  on(actions.setPage, (state, action) => { return {...state, ...{page: action.page}}}),
  on(actions.setOrder, (state, action) => { return {...state, ...{order: action.order}}}),
  on(actions.setDirection, (state, action) => { return {...state, ...{asc: action.asc}}}),
  on(actions.setSearch, (state, action) => { return {...state, ...{search: action.search}}}),
  on(actions.setManyOutpost, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: action.total,
        list: adapter.addMany(action.list, initialState.list)
      }
    }
  }),
  on(actions.addManyOutpost, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: action.total,
        list: adapter.addMany(action.list, state.list)
      }
    }
  }),
  on(actions.addOutpost, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: state.total + 1,
        list: adapter.addOne(action.outpost, state.list)
      }
    }
  }),
  on(actions.setOutpost, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.updateOne({ id: action.outpost.id, changes: action.outpost}, state.list)
      }
    }
  }),
  on(actions.removeOutpost, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: state.total - 1,
        list: adapter.removeOne(action.id, state.list)
      }
    }
  }),
);



export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectState = createFeatureSelector<State>('outpost');
export const selectAllOutposts = createSelector(selectState, (state) => selectAll(state.list) )
export const selectOneOutpost = createSelector(selectState, (state: State, id) => state.list.entities[id]);

<div class="content">
  <div class="container-xl">
    <div class="card card-widget position-relative shadow mt-3 mb-4">
      <div class="overlay" *ngIf="(loading | async)">
        <div style="position: sticky; text-align: center">
          <i class="fas fa-3x fa-spinner fa-spin"></i>
          <p>Ładowanie...</p>
        </div>
      </div>
      <div class="overlay" *ngIf="saving">
        <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%,0);text-align: center; padding: 25px 0">
          <i class="fas fa-3x fa-spinner fa-spin"></i>
          <p>Zapisywanie...</p>
        </div>
      </div>
      <div [ngStyle]="backgroundStyle()"></div>
      <div class="card-header pb-1">
        <div class="user-block">
          <img class="img-circle" [src]="orderForm?.createdBy | userAvatar" alt="User Image" [appUserDisactivatable]="orderForm?.createdBy">
          <span class="username">
            <span class="text-decoration-line-through" *ngIf="!orderForm?.createdBy">Użytkownik usunięty</span>
            <a [routerLink]="['/profile', orderForm?.createdBy?.id]"  *ngIf="orderForm?.createdBy" [appUserDisactivatable]="orderForm?.createdBy">
              {{orderForm?.createdBy?.firstname}} {{orderForm?.createdBy?.lastname}}
            </a>
          </span>
          <!-- <span class="description"> {{post.createdAt | amLocale:'pl' | amTimeAgo }}</span> -->
          <span class="description"> {{orderForm?.createdAt | amLocale:'pl' | amCalendar }}</span>
          <!-- <span class="description">
            <span class="btn btn-link p-0 pr-1 text-sm" *ngFor="let tag of offer?.tags">#{{tag}}</span>
          </span> -->

        </div>
        <!-- <div class="card-tools" adminOnly>
          <a class="badge text-sm rounded-pill badge-secondary" ngbTooltip="Lista kandydatów" [routerLink]="['candidates']">
            <i class="fas fa-users"></i>
            {{offer.applicationsCount}}
          </a>
        </div> -->
      </div>
      <div class="card-header pt-1" >
        <h3 *ngIf="orderForm?.id" class="text-primary card-title text-lg m-0">
          {{orderForm?.title}}
        </h3>
      </div>
      <div class="card-body" >
        <div [innerHtml]="orderForm?.description | safeHtml"></div>
      </div>
      <div class="card-footer" >
        <h3 class="card-title">Formularz zamówienia:</h3>
      </div>

      <form [formGroup]="form" (ngSubmit)="save()" *ngIf="orderForm">
        <div class="card-body">

          <div class="form-group">
            <label for="firstname">NUMER PUNKTU POBRAŃ</label>
            <input formControlName="npp" type="text" class="form-control" placeholder="NUMER PUNKTU POBRAŃ" [appIsValid]="form.controls.npp"/>
            <!-- <pre>{{nppCtrl.errors | json}}</pre> -->
            <div class="invalid-feedback" *ngIf="form.controls.npp.errors?.required">
              Pole jest wymagane
            </div>
          </div>
          <div class="form-group" *ngFor="let item of orderForm.inputs" [formGroup]="fieldsCtrl.controls[item.id]">
            <label for="firstname">{{item.label}}</label>

            <input formControlName="value" [type]="item.type" class="form-control" placeholder="{{item.label}}" *ngIf="item.type == 'text' || item.type == 'number'" />

            <select formControlName="value" class="form-control"  *ngIf="item.type == 'select'">
              <option *ngFor="let option of item.options" [value]="option">{{option}}</option>
            </select>

            <div  *ngIf="item.type == 'radio'">
              <label *ngFor="let option of item.options"  class="form-check  pl-0">
                <input type="radio" formControlName="value"  [value]="option"/> {{option}}
              </label>
            </div>

            <div  *ngIf="item.type == 'checkbox'">
              <label *ngFor="let option of getFormArray(item.id); let idx = index"  class="form-check  pl-0">
                <input type="checkbox" [formControl]="option"/> {{item.options[idx]}}
              </label>
            </div>

            <!-- <div class="invalid-feedback" *ngIf="form.controls.firstname.errors?.required">
              Pole jest wymagane
            </div> -->
          </div>
        </div>
        <!-- <pre>{{value | json}}</pre> -->
        <div class="card-footer">
          <button class="btn btn-primary" type="submit" (mouseDown)="save()" (touchEnd)="save()">
            Wyślij
            <i class="fas fa-angle-right fa-fw"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
  </div>


import { AbstractControl, ValidationErrors } from "@angular/forms"

export const youtubeValidator = (control: AbstractControl): ValidationErrors | null => {
  if (control && (control.value !== null || control.value !== undefined)) {
    const url = control.value;

    var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if(url && url.match(p)){
        return null;
    }
    return {youtubeUrl: true};
  }

  return null;
}



export const youtubeExtract = (url): string | null => {
    var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    let m = url.match(p);
    if(m){
        return m[1];
    }
    return null;

  return null;
}

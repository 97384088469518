import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { environment } from "environments/environment";
import { ToastrService } from "ngx-toastr";
import * as fromActions from './order.actions';
import { catchError, switchMap, tap } from "rxjs/operators";
import { plainText } from "@/utils/plainText";
import { selectOrderFormsState, State } from "./order.reducer";
import { OrderForm } from "@/model/order.form.model";

@Injectable()
export class OrderEffects {
  state: State;
  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private store$: Store<State>,
    private toastr: ToastrService
  ) {
    this.store$.pipe(select(selectOrderFormsState)).subscribe((state: State) => {
      this.state = state;
    })
  }

  private pageSize: number = environment.pageSize;

  onError (err, caught): any {
    this.toastr.error(err.message)
    return caught;
  };

  saveOrderFormWorkStage$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.saveOrderFormWorkStage),
    switchMap((action) => {
      let orderForm = {...action.orderForm, ...{short: plainText(action.orderForm.description)}};
      const formData = new FormData();
      if(action.imageFile) {
        formData.append('image', action.imageFile as any, action.imageFile.name);
      }
      if(action.backgroundFile) {
        formData.append('background', action.backgroundFile as any, action.backgroundFile.name);
      }
      formData.append('data', JSON.stringify(orderForm));
      return this.httpClient.post<OrderForm>('<backendhost>/v1/order', formData, { observe: 'body', responseType: 'json'})
    }),
    catchError(this.onError.bind(this)),
    switchMap((orderForm: OrderForm) => [
      fromActions.setOrderForm({orderForm})
      // PostActions.addOneNews({post: response}),
      // PostActions.addMultipleNews({posts: [response]})
    ])
  ));

  deleteOrderForm$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deleteOrderForm),
    switchMap((action) => {
      return this.httpClient.delete<any>(`<backendhost>/v1/order/${action.orderForm.id}`, { observe: 'body', responseType: 'json'})
    }),
    catchError(this.onError.bind(this)),
    tap( () => {
      this.toastr.success('Usunięto formularz')
    }),
    switchMap((orderForm: OrderForm) => [])
  ));

  fetchOrderForms$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchOrderForms),
    switchMap( (action) => {
      let params = new HttpParams();
      params = params.set('skip', this.state.limit * (this.state.page - 1));
      params = params.set('limit', action?.limit ? action.limit : this.state.limit);
      params = params.set('order', this.state.order);
      params = params.set('direction', this.state.asc ? 'asc' : 'desc');
      if(this.state.search)
        params = params.set('search', this.state.search);
      return this.httpClient.get<fromActions.OrderFormPagination>(`<backendhost>/v1/order`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: fromActions.OrderFormPagination) => [fromActions.replaceOrderForm(response)])
  ));

  paginatorFetchChange$ = createEffect(() => this.actions$.pipe(
    ofType(...[
      fromActions.setDirection,
      fromActions.setOrder,
      fromActions.setPage,
      fromActions.setSearch
    ]),
    switchMap( () => [fromActions.fetchOrderForms({})])
  ));

  fetchOneOrderForm$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchOneOrderForm),
    switchMap( (action) => {
      return this.httpClient.get<OrderForm>(`<backendhost>/v1/order/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((orderForm: OrderForm) => [fromActions.setOrderForm({orderForm})])
  ));
}

import { PaginationDTO } from "@/model/pagination.model";
import { Multimedia } from "@/model/multimedia.model";
import { createAction, props } from "@ngrx/store";

export const fetch = createAction("multimedia fetch");
export const fetchOne = createAction("multimedia fetchOne", props<{id: number}>());
export const createMultimedia = createAction( "multimedia createMultimedia",
  props<{item: Multimedia}>()
);
export const saveMultimedia = createAction( "multimedia saveMultimedia",
  props<{item: Multimedia}>()
);
export const removeMultimedia = createAction( "multimedia removeMultimedia",
  props<{id: number}>()
);

export const setMultimedias = createAction( "multimedia setMultimedias",
  props<PaginationDTO<Multimedia>>()
);
export const addMultimedia = createAction( "multimedia addMultimedia",
  props<{item: Multimedia}>()
);
export const updateMultimedia = createAction( "multimedia updateMultimedia",
  props<{item: Multimedia}>()
);
export const deleteMultimedia = createAction( "multimedia deleteMultimedia",
  props<{id: number}>()
);
export const setPage = createAction("multimedia setPage", props<{ page: number }>());
export const setOrder = createAction("multimedia setOrder", props<{ order: string }>());
export const setDirection = createAction("multimedia setDirection", props<{ asc: boolean }>());
export const setSearch = createAction("multimedia setSearch", props<{ search: string }>());

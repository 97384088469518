<ng-select
    [disabled]="disabled"
    [items]="users$ | async"
    [multiple]="multiple"
    bindLabel="name"
    [trackByFn]="trackByFn"
    [minTermLength]="2"
    [loading]="usersLoading | async"
    typeToSearchText="Please enter 2 or more characters"
    [typeahead]="userInput$"
    [ngModel]="user"
    (ngModelChange)="onUserChange($event)"
    [placeholder]="multiple ? 'Wybierz wiele użytkowników' : 'Wybierz użytkownika'"
    >
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
      <div class="d-flex">
      <img [src]="item | userAvatar" class="img-size-50 img-circle" [appUserDisactivatable]="item"/>
      <div class="pl-3">
        <span [appUserDisactivatable]="item">
          {{item.name}}
        </span>
      </div>
      </div>
  </ng-template>
</ng-select>

import { Breadcrumb } from '@/model/breadcrumb.model';
import {Component, HostBinding, OnInit, Renderer2} from '@angular/core';
import {AppService} from '@services/app.service';
import { BreadcrumbsService } from '@services/breadcrumbs.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
    @HostBinding('class') class = 'wrapper';
    public sidebarMenuOpened = true;

    constructor(
      private renderer: Renderer2,
      private appService: AppService,
      private breadcrumbsService: BreadcrumbsService
    ) {}

    ngOnInit() {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'login-page'
        );
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'register-page'
        );
    }

    get breadcrumbs(): Observable<Breadcrumb[]> {
      return this.breadcrumbsService.breadcrumbs$.pipe(map(x => {let arr = [...x]; arr.pop(); return arr;}))
    }

    get title(): Observable<string> {
      return this.breadcrumbsService.breadcrumbs$.pipe(map(x => [...x].pop().label));
    }

    toggleMenuSidebar() {
        if (this.sidebarMenuOpened) {
            this.renderer.removeClass(
                document.querySelector('app-root'),
                'sidebar-open'
            );
            this.renderer.addClass(
                document.querySelector('app-root'),
                'sidebar-collapse'
            );
            this.sidebarMenuOpened = false;
        } else {
            this.renderer.removeClass(
                document.querySelector('app-root'),
                'sidebar-collapse'
            );
            this.renderer.addClass(
                document.querySelector('app-root'),
                'sidebar-open'
            );
            this.sidebarMenuOpened = true;
        }
    }
}

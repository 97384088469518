import { ReservationAsset, ReservationCategory } from '@/model/reservation.model';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import * as fromActions from '@/store/reservations/reservations.actions';
import * as fromReducers from '@/store/reservations/reservations.reducers';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'app-add-reservation-asset',
  templateUrl: './add-reservation-asset.component.html',
  styleUrls: ['./add-reservation-asset.component.scss']
})
export class AddReservationAssetComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  disabled = false;
  uploading = false;
  imageFile: NzUploadFile;
  reservationAsset: ReservationAsset;
  reservationCategory: ReservationCategory;

  constructor(
    private toastr: ToastrService,
    private store: Store<fromReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      title: new FormControl(null, Validators.required),
      description: new FormControl(null),
    });
    this.activatedRoute.paramMap.pipe(
      map((params: ParamMap) => parseInt(params.get('category')))
    ).subscribe( (id: number) => {
      this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectOneAdminReservationCategory,id)).subscribe((result) => {
        if(!result) {
          this.store.dispatch(fromActions.fetchOneReservationCategory({id}));
        }
        if (!result)return;
        this.reservationCategory = result;
        this.reservationAsset = {...this.reservationAsset, ...{category: result.id}};
      });
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  save(): void {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    let valid =  this.form.valid;
    if (valid) {
      this.disabled = true;

      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromActions.addAdminReservationAsset)
      ).subscribe( (action:any) => {
        this.toastr.success("Zapisano zmiany");
        this.router.navigate(['/reservations/admin/assets/', this.reservationCategory.id])
      })

      let value = {...this.reservationAsset, ...this.form.value};
      this.store.dispatch(fromActions.createAdminReservationAsset({reservationAsset: value, image: this.imageFile}));
    } else if(!this.disabled) {
      this.toastr.error("Formularz zawiera błędy");
    } else {
      this.toastr.warning("Trwa zapisywanie");
    }
  }

  get saving(): Observable<boolean> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectReservationState), map( (state) => state.admin.saving));
  }
}


import { Quiz, QuizQuestion } from '@/model/quiz.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Data } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import * as fromQuizReducers from '@/store/quiz/quiz.reducers';
import { map } from 'rxjs/operators';
import { TrainingResult } from '@/model/package.model';

@Component({
  selector: 'app-quiz-player',
  templateUrl: './quiz-player.component.html',
  styleUrls: ['./quiz-player.component.scss']
})
export class QuizPlayerComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  @Input() quiz: Quiz;
  @Input() result: TrainingResult;
  @Output() onFinish: EventEmitter<TrainingResult> = new EventEmitter<TrainingResult>();
  @Input() reset: Observable<void>;
  form: FormGroup;
  questions: QuizQuestion[];
  get closed(): boolean {
    return this.result?.completedOn != null;
  }

  constructor() {
    this.form = new FormGroup({});
  }

  ngOnInit(): void {

    // this.closed = false;
    this.initializeForm();
    if(this.result) {
      this.form.patchValue(this.result.runtimeData);
      // this.closed = this.result.completed;
    }
    if (this.reset) {
      this.reset.subscribe(() => {
        this.repeat();
      })
    }
  }

  repeat() {
    this.form.enable();
    this.form.reset();
    this.form = new FormGroup({});
    this.result = {
      ...this.result,
      ...{
        score:null,
        scoreMax:null,
        scorePercent:null,
        completed: false,
        completedOn: null,
        passed: null,
        runtimeData: {}
      }
    }
    this.initializeForm();
    // this.onFinish.emit(this.result);
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  private seed;
  rand() {
    var t = this.seed += 0x6D2B79F5;
    t = Math.imul(t ^ t >>> 15, t | 1);
    t ^= t + Math.imul(t ^ t >>> 7, t | 61);
    return ((t ^ t >>> 14) >>> 0) / 4294967296;
  }

  shuffle(arr: any[]) {
    return arr
            .map(value => ({ value, sort: this.rand() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value);
  }

  initializeForm() {
    this.questions = fromQuizReducers.questionAdapter.getSelectors().selectAll(this.quiz.questions);;


    if (!this.result.runtimeData.seed) {
      this.seed = Math.ceil(Math.random() * 999999 + 1);
      this.result = {
        ...this.result,
        ...{
          runtimeData: {
            ...this.result.runtimeData,
            ...{
              seed: this.seed
            }
          }
        }
      }

      this.onFinish.emit(this.result);
    } else {
      this.seed = this.result.runtimeData.seed;
    }

    /**
     * Randomize and slice
     */
    this.questions = this.shuffle(this.questions).slice(0,this.quiz.questionNum);
    this.questions = this.questions.map( question => {
        return {
          ...question,
          ...{
            options: this.shuffle(question.options)
          }
        }
    })

    this.questions.forEach((question:QuizQuestion, idx: number) => {
      let correctAnswer;
      let answer;
      // let answers = new FormArray(question.options.map(
      //   option => new FormGroup({
      //     "label": new FormControl(option.label),
      //     "answer": new FormControl(false),
      //     "correct": new FormControl(option.correct),
      //   })
      // ));

      if (['radio', 'select'].indexOf(question.type) != -1) {
        correctAnswer = new FormControl(question.options.filter(o => o.correct).pop().label);
        answer = new FormControl();
      }
      else if (['checkbox'].indexOf(question.type) != -1) {
        // correctAnswer = new FormArray(question.options.map(o => o.correct ? o.label : null).map((v) => new FormControl(v)));
        correctAnswer = new FormArray(question.options.map((o) => new FormControl(o.correct)));
        answer = new FormArray(question.options.map(() => new FormControl(false)));
      }


      let questionsGroup = new FormGroup({
        "answer": answer,
        "correctAnswer": correctAnswer,
        "correct": new FormControl(false),
      });
      questionsGroup.valueChanges.pipe(map(val => [val, questionsGroup])).subscribe(this.isQuestionCorrectListner);
      this.form.addControl(question.id.toString(), questionsGroup);
    });
  }

  private isQuestionCorrectListner([value, question]) {
    let correct = false;
    if (typeof value.answer == "object") {
      correct = JSON.stringify(value.answer) == JSON.stringify(value.correctAnswer);
    } else if (typeof value.answer == "string") {
      correct = value.answer == value.correctAnswer;
    }
    question?.controls.correct.setValue(correct, {emitEvent: false});
  }

  getQuestionGroup(question: QuizQuestion): FormGroup {
    return this.form.controls[question.id.toString()] as FormGroup;
  }

  get correct() {
    return Object.values(this.form.value).map( (q:any) => q.correct).filter(v => v).length;
  }

  submit() {
    // this.closed = true;
    this.form.disable();

    let scorePercent = 100 * (this.correct / this.questions.length);
    this.result = {
      ...this.result,
      ...{
        timeSpent: 0,
        progression: 0,
        completed: true,
        completedOn: new Date(),
        score: this.correct,
        scoreMax: this.questions.length,
        scorePercent: scorePercent,
        /** raw scorm data */
        runtimeData: {
          ...this.form.value,
          ...{
            seed: this.result.runtimeData.seed
          }
        },
        passed: scorePercent >= this.quiz.minScore
    }};
    this.onFinish.emit(this.result);
  }

  labelClass(option) {
    if (!this.closed) return null;
    return option.correct ? 'quiz-correct-label' : 'quiz-wrong-label';
  }

}

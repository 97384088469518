import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { ofType } from '@ngrx/effects';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import * as fromSurveyAction from '@/store/survey/survey.actions';
import * as fromSurveyReducers from '@/store/survey/survey.reducers';
import { Survey } from '@/model/survey.model';

import * as fromMessageActions  from '@/store/messages/messages.actions'
import * as fromMessageReducer  from '@/store/messages/messages.reducers'
import * as fromGroupActions  from '@/store/group/group.actions'
import * as fromGroupReducer  from '@/store/group/group.reducers'
import { User } from '@/model/user.model';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Group } from '@/model/group.model';
import { AppService } from '@services/app.service';
import moment from 'moment';
import { SurveyPublishPotentialComponent } from './survey-publish-potential/survey-publish-potential.component';

@Component({
  selector: 'app-survey-admin-publish',
  templateUrl: './survey-admin-publish.component.html',
  styleUrls: ['./survey-admin-publish.component.scss']
})
export class SurveyAdminPublishComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  @ViewChild('potential') potentialBox: SurveyPublishPotentialComponent;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  typeaheadGroupModel: any;
  typeaheadGroupList: Group[] = [];

  typeaheadUserModel: any;
  typeaheadUsersList: User[] = [];

  formatter = (item: any) => `${item.name}`;
  formatterEmpty = () => null;


  form: FormGroup;
  survey: Survey;

  constructor(
    private toastr: ToastrService,
    private store: Store<fromSurveyReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appService: AppService
  ) {
    this.form = new FormGroup({
      published: new FormControl(false),
      sendPush: new FormControl(false),
      sendSms: new FormControl(false),
      sendEmail: new FormControl(false),
      sendTo: new FormControl('all', Validators.required),
      groups: new FormArray([], null),
      recepients: new FormArray([], []),
      endDate: new FormControl(new Date(),[Validators.required, this.DateTimeValidator.bind(this)]),
    });


    this.form.controls.sendTo.valueChanges.subscribe((value) => {
      switch(value) {
        case 'users':
          this.form.controls.recepients.addValidators([Validators.required]);
          this.form.controls.groups.removeValidators([Validators.required]);
          break;
        case 'groups':
          this.form.controls.recepients.removeValidators([Validators.required]);
          this.form.controls.groups.addValidators([Validators.required]);
          break;
        case 'all':
          this.form.controls.recepients.removeValidators([Validators.required]);
          this.form.controls.groups.removeValidators([Validators.required]);
          break;
      }
    });
  }

  DateTimeValidator = (fc: FormControl) => {
    const date = new Date(fc.value);
    const isValid = !isNaN(date.valueOf());
    return isValid ? null : {dateNotValid: true};
  };

  ngAfterViewInit(): void {
    this.activatedRoute.data.subscribe((data:Data) => {
      this.potentialBox.load(data.instance);
    });
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data:Data) => {
      this.survey = {
        ...this.survey,
        ...data.instance,
        ...{
          endDate : data.instance.endDate
            ? moment(data.instance.endDate).format('YYYY-MM-DD hh:mm:ss')
            : moment().add(1, 'M').add(1, 'd').format('YYYY-MM-DD 00:00:00'),
        }
      };
      this.typeaheadUsersList = [...this.survey.recepients];
      this.typeaheadGroupList = [...this.survey.groups];
      this.form.patchValue(this.survey);
      this.survey.recepients.map( training => {
        this.recepientsCtrl.push(new FormControl(training.id))
      });
      this.survey.groups.map( group => {
        this.groupCtrl.push(new FormControl(group.id))
      });



      this.store.pipe(
        select(fromSurveyReducers.selectOneSurvey, this.survey.id),
        takeUntil(this.unsubscribe$),
        distinctUntilChanged()
      ).subscribe((survey) => {
        this.survey = survey;
        if(this.survey.published) {
          // this.form.controls.sendPush.disable();
          // this.form.controls.sendSms.disable();
          // this.form.controls.sendEmail.disable();
          // this.form.controls.sendTo.disable();
        }
      })
    })
    this.form.controls.sendTo.valueChanges.subscribe(this.updatePotential.bind(this,'sendTo'));
    this.form.controls.groups.valueChanges.subscribe(this.updatePotential.bind(this,'groups'));
    this.form.controls.recepients.valueChanges.subscribe(this.updatePotential.bind(this,'recepients'));
  }

  updatePotential(key, value) {

    let data = {
      ...this.form.value,
      ...{
        [key]: value
      }
    }
    this.potentialBox.load(data);
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  publish() {
    this.form.controls.published.setValue(true);
  }

  save(): void {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    if (this.form.valid) {
      this.survey = {...this.survey, ...this.form.value};
      this.store.dispatch(fromSurveyAction.publishSurvey({survey: this.survey}));
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromSurveyAction.setSurvey.type)
      ).subscribe( (action: any) => {
        this.toastr.success('Zapisano zmiany');
        this.router.navigate(['/survey/admin']);
      });
    } else {
        this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }

  searchGroups: OperatorFunction<string, readonly Group[]> = (text$: Observable<string>) => {
    return text$.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term =>
        this.appService.searchGroups(term)
      ),
    );
  }

  get groupCtrl(): FormArray {
    return this.form.get('groups') as FormArray;
  }

  onGroupsTypeaheadSelect(selectedItem: NgbTypeaheadSelectItemEvent) {
    let group: Group = selectedItem.item;
    this.typeaheadGroupList.push(group);
    this.groupCtrl.push(new FormControl(group.id))
  }

  removeGroup(item: Group) {
    let idx = this.typeaheadGroupList.map(it => it.id).indexOf(item.id);
    this.typeaheadGroupList.splice(idx, 1);
    this.groupCtrl.removeAt(idx);
  }

  searchUsers: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    return text$.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term =>
        this.appService.searchUser(term)
      ),
    );
  }

  get recepientsCtrl(): FormArray {
    return this.form.get('recepients') as FormArray;
  }

  onUsersTypeaheadSelect(selectedItem: NgbTypeaheadSelectItemEvent) {
    let user: User = selectedItem.item;
    this.typeaheadUsersList.push(user);
    this.recepientsCtrl.push(new FormControl(user.id))
  }

  removeUser(item: User) {
    let idx = this.typeaheadUsersList.map(it => it.id).indexOf(item.id);
    this.typeaheadUsersList.splice(idx, 1);
    this.recepientsCtrl.removeAt(idx);
  }
}

import { Pagination, PaginationDTO } from "@/model/pagination.model";
import { Multimedia } from "@/model/multimedia.model";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, State, Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { catchError, map, switchMap } from "rxjs/operators";
import * as fromActions from "./multimedia.actions";
import * as fromReducers from "./multimedia.reducers";
import { StringifyHttpErrorResponse } from "@/utils/http.error.util";

@Injectable()
export class MultimediaEffects {
  state: fromReducers.State;

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private store$: Store<fromReducers.State>,
  ) {
    this.store$.pipe(select(fromReducers.selectState)).subscribe((state: fromReducers.State) => {
      this.state = state;
    })
  }

  onError (err, caught): any {
    this.toastr.error(StringifyHttpErrorResponse(err));
    return caught;
  };

  paginatorChange$ = createEffect(() => this.actions$.pipe(
    ofType(...[
      fromActions.setPage,
      fromActions.setSearch,
      fromActions.setOrder,
      fromActions.setDirection
    ]),
    map( () => fromActions.fetch())
  ));

  fetch$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetch),
    switchMap(() => {
      let params = new HttpParams();
      params = params.set('skip', this.state.limit * (this.state.page - 1));
      params = params.set('limit', this.state.limit);
      params = params.set('order', this.state.order);
      params = params.set('direction', this.state.asc ? 'asc' : 'desc');
      if(this.state.search)
        params = params.set('search', this.state.search);
      return this.httpClient.get<PaginationDTO<Multimedia>>(`<backendhost>/v1/multimedia`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: PaginationDTO<Multimedia>) => [fromActions.setMultimedias(response)])
  ));

  fetchOne$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchOne),
    switchMap((action) => {
      return this.httpClient.get<Multimedia>(`<backendhost>/v1/multimedia/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((item: Multimedia) => [fromActions.addMultimedia({item})])
  ));

  removeMultimedia$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.removeMultimedia),
    switchMap((action) => {
      return this.httpClient.delete<any>(`<backendhost>/v1/multimedia/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map(() => action.id))
    }),
    catchError(this.onError.bind(this)),
    switchMap((id: number) => [fromActions.deleteMultimedia({id})])
  ));

  saveMultimedia$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.saveMultimedia),
    switchMap((action) => {
      return this.httpClient.put<Multimedia>(`<backendhost>/v1/multimedia/${action.item.id}`,
          action.item,
          {
            observe: 'body',
            responseType: 'json'
          });
    }),
    catchError(this.onError.bind(this)),
    switchMap((item: Multimedia) => [fromActions.updateMultimedia({item})])
  ));

  createMultimedia$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createMultimedia),
    switchMap((action) => {
      return this.httpClient.post<Multimedia>(`<backendhost>/v1/multimedia`,
          action.item,
          {
            observe: 'body',
            responseType: 'json'
          });
    }),
    catchError(this.onError.bind(this)),
    switchMap((item: Multimedia) => [fromActions.addMultimedia({item})])
  ));

}

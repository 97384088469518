<ul class="nav nav-tabs">

  <li class="nav-item">
    <a class="nav-link"
      routerLinkActive="active bg-primary"
      [routerLinkActiveOptions]="{ exact: true }"
      [routerLink]="['/account']">
      <i class="fas fa-user"></i>
      Mój Profil
    </a>
  </li>

  <li class="nav-item">
    <a class="nav-link"
      routerLinkActive="active bg-primary"
      [routerLink]="['/account', 'edit']">
      <i class="fas fa-edit "></i>
      Edycja konta
    </a>
  </li>

  <li class="nav-item">
    <a class="nav-link"
      routerLinkActive="active bg-primary"
      [routerLink]="['/account', 'password']">
      <i class="fas fa-lock"></i>
      Zmiana hasła
    </a>
  </li>

  <li class="nav-item">
    <a class="nav-link"
      routerLinkActive="active bg-primary"
      [routerLink]="['/account', 'slots']">
      <i class="fas fa-flask "></i>
      Sloty pracy
    </a>
  </li>

  <li class="nav-item">
    <a class="nav-link"
      routerLinkActive="active bg-primary"
      [routerLink]="['/account', 'workmarket']">
      <i class="fas fa-chart-area "></i>
      Ustawienia giełdy pracy
    </a>
  </li>
</ul>

import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { AuthService } from './auth.service';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { catchError, tap } from 'rxjs/operators';
// import { url } from 'inspector';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${this.authService.getToken()}`),
      url: req.url.replace('<backendhost>', environment.apiUrl),
      withCredentials: true
    });
    // let token = this.storage.get(AuthService.STORAGE_TOKEN_KEY) || null;
    // if (token) {
    //   authReq.headers.set('Authorization', `Bearer ${token}`);
    // }
    return next.handle(authReq).pipe(tap((err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          this.authService.signOut();
        }
      }
    }))
  }


}

import { Group } from '@/model/group.model';
import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { from, Observable, of } from 'rxjs';
import * as fromGroupReducer from '@/store/group/group.reducers';
import * as fromGroupActions from '@/store/group/group.actions';
import { AuthService } from '@/auth/auth.service';
import { Store, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { tap, filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GroupResolver implements Resolve<Group> {
  constructor(
    private store: Store<fromGroupReducer.State>,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  check(resolve: any, instance : any, route: ActivatedRouteSnapshot) {
    this.authService.checkPermition(route.data.permition, instance).then( (alowed:boolean) => {
      if (alowed) {
        resolve(instance);
      } else {
        // resolve(null);
        this.toastr.error("Nie masz uprawnień do tej sekcji!");
        this.router.navigate(["/"]);
      }
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Group> {
    return from( new Promise<Group>(resolve => {
      this.store.pipe(
        select(fromGroupReducer.selectEntity, 1*route.params.id),
        tap( (result) => {
          if(!result) {
            this.store.dispatch(fromGroupActions.fetchOneAction({id: 1*route.params.id}));
          }
        }),
        filter((result) => result != null),
        take(1),
      ).subscribe(instance => this.check(resolve, instance, route));
    }));
  }
}

import { PaginationDTO } from "@/model/pagination.model";
import { Scorm } from "@/model/scorm.model";
import { createAction, props } from "@ngrx/store";

export const fetch = createAction("scorm fetch");
export const fetchOne = createAction("scorm fetchOne", props<{id: number}>());
export const saveScorm = createAction( "scorm saveScorm",
  props<{item: Scorm}>()
);
export const removeScorm = createAction( "scorm removeScorm",
  props<{id: number}>()
);

export const setScorms = createAction( "scorm setScorms",
  props<PaginationDTO<Scorm>>()
);
export const addScorm = createAction( "scorm addScorm",
  props<{item: Scorm}>()
);
export const updateScorm = createAction( "scorm updateScorm",
  props<{item: Scorm}>()
);
export const deleteScorm = createAction( "scorm deleteScorm",
  props<{id: number}>()
);
export const setPage = createAction("scorm setPage", props<{ page: number }>());
export const setOrder = createAction("scorm setOrder", props<{ order: string }>());
export const setDirection = createAction("scorm setDirection", props<{ asc: boolean }>());
export const setSearch = createAction("scorm setSearch", props<{ search: string }>());

import { createPaginationInitialState, Pagination } from "@/model/pagination.model";
import { Slot } from "@/model/slot.model";
import { User } from "@/model/user.model";
import { Vacant, VacantPack } from "@/model/vacant.model";
import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import * as actions from './vacant.toconfirm.actions';

export interface State extends Pagination<VacantPack> {}

export const adapter: EntityAdapter<VacantPack> = createEntityAdapter<VacantPack>();

export const initialState: State = {
  ...createPaginationInitialState(adapter, false)
};

export const reducer = createReducer(
  initialState,
  on(actions.clear, (state) => initialState),
  on(actions.fetch, (state) => { return {...state, ...{loading: true}}}),

  on(actions.setPage, (state, action) => { return {...state, ...{page: action.page}}}),
  on(actions.setOrder, (state, action) => { return {...state, ...{order: action.order}}}),
  on(actions.setDirection, (state, action) => { return {...state, ...{asc: action.asc}}}),
  on(actions.setSearch, (state, action) => { return {...state, ...{search: action.search}}}),
  on(actions.setMany, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: action.total,
        list: adapter.addMany(action.list, adapter.removeAll(state.list))
      }
    }
  }),
  on(actions.removeOne, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: state.total - 1,
        list: adapter.removeOne(action.id, state.list)
      }
    }
  }),

);



export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectState = createFeatureSelector<State>('vacant-toconfirm');
export const selectCurrent = createSelector(selectState, (state) => selectAll(state.list) )
export const selectOne = createSelector(selectState, (state: State, id) => state.list.entities[id]);

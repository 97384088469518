import { Role } from "@/model/role.model";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ToastrService } from "ngx-toastr";
import { catchError, map, switchMap } from "rxjs/operators";

import * as fromActions from "./roles.actions";

@Injectable()
export class RolesEffects {

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private toastr: ToastrService
  ) {}

  onError (err, caught): any {
    this.toastr.error(err.message)
    return caught;
  };

  fetch$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetch),
    switchMap((action) => {
      return this.httpClient.get<Role[]>(`<backendhost>/v1/roles`,
                                        {observe: 'body',responseType: 'json'});
    }),
    catchError(this.onError.bind(this)),
    switchMap((roles) => [fromActions.set({roles})])
  ));

  save$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.save),
    switchMap((action) => {
      return this.httpClient.put<Role>(`<backendhost>/v1/roles/${action.role.id}`,
                                        action.role,
                                        {observe: 'body',responseType: 'json'});
    }),
    catchError(this.onError.bind(this)),
    switchMap((role) => [fromActions.update({role})])
  ));

  create$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.create),
    switchMap((action) => {
      return this.httpClient.post<Role>(`<backendhost>/v1/roles`,
                                        action.role,
                                        {observe: 'body',responseType: 'json'});
    }),
    catchError(this.onError.bind(this)),
    switchMap((role) => [fromActions.add({role})])
  ));

  delete$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deleteRole),
    switchMap((action) => {
      return this.httpClient.delete<any>(`<backendhost>/v1/roles/${action.role.id}`,
                                        {observe: 'body',responseType: 'json'})
                                        .pipe(map(() => action.role));
    }),
    catchError(this.onError.bind(this)),
    switchMap((role) => {
      console.log(role);
      return [fromActions.remove({role})]
    })
  ));
}

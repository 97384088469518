import { OfferCategory } from '@/model/offer.model';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ofType } from '@ngrx/effects';
import { Store, ActionsSubject } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fromOfferCategoryActions from '@/store/offer-category/offer.category.action';
import * as fromOfferCategoryReducers from '@/store/offer-category/offer.category.reducers';

@Component({
  selector: 'app-add-offer-category',
  templateUrl: './add-offer-category.component.html',
  styleUrls: ['./add-offer-category.component.scss']
})
export class AddOfferCategoryComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  category: OfferCategory;
  constructor(
    private toastr: ToastrService,
    private store: Store<fromOfferCategoryReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {}


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  save(): void {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    if (this.form.valid) {
      this.category = {
        ...this.category,
        ...this.form.value
      };
      this.store.dispatch(fromOfferCategoryActions.createOfferCategory({item: this.category}));
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromOfferCategoryActions.addOne.type)
      ).subscribe( (action: any) => {
        this.toastr.success('Dodano nową kategorie');
        this.router.navigate(['/offer/admin/category']);
      });
  } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
  }
  }

}

import { Invitation, TrainingResult } from '@/model/package.model';
import { Scorm } from '@/model/scorm.model';
import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { ScormResult, NgScormPlayerService, NgScormPlayerConfig } from 'ng-scorm-player';
import { Observable, of, Subject } from 'rxjs';
import { debounce, debounceTime, distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import * as fromMyTrainingsAction from '@/store/my-training/my-training.actions';
import * as fromMyTrainingsReducers from '@/store/my-training/my-training.reducers';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import moment from 'moment';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'app-scorm-player',
  templateUrl: './scorm-player.component.html',
  styleUrls: ['./scorm-player.component.scss']
})
export class ScormPlayerComponent implements OnInit {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  private readonly resultUpdate$: Subject<TrainingResult> = new Subject<TrainingResult>();
  @Input() result: TrainingResult;
  scormResult: TrainingResult;
  @Input() scorm: Scorm;
  @Input() dontFinish: boolean = false;
  @Output() onFinish: EventEmitter<TrainingResult> = new EventEmitter<TrainingResult>();
  @Output() onClose: EventEmitter<TrainingResult> = new EventEmitter<TrainingResult>();
  show = false;
  invitation: Invitation;
  private finished = false;
  // player: NgScormPlayerService;

  constructor(
    private http: HttpClient,
    private router: Router,
    private actionsSubject$: ActionsSubject,
    private activatedRoute: ActivatedRoute,
    private player: NgScormPlayerService,
    private scormPlayerConfig: NgScormPlayerConfig,
    private store: Store<fromMyTrainingsReducers.State>,
    private ngZone: NgZone
  ) {
    this.scormPlayerConfig.debug = false;
  }

  ngOnInit(): void {
    this.player.runtimeData = {};
    this.player.scormResult = {
      timeSpent: null,
      progression: null,
      completed: null,
      completedOn: null,
      score: null,
      scoreMax: null,
      scorePercent: null,
      runtimeData: {}
    };
    this.scormResult = this.result;
    this.activatedRoute.data.subscribe( (data: Data) => {

      // this.scorm = data.training;
      this.invitation = data.instance;
      this.player.LMSInitialize();
      this.player.scormResult = {
        timeSpent: this.result.timeSpent,
        progression: this.result.progression,
        completed: this.result.completed,
        completedOn: this.result.completedOn,
        score: this.result.score,
        scoreMax: this.result.scoreMax,
        scorePercent: this.result.scorePercent,
        /** raw scorm data */
        runtimeData: this.result.runtimeData,
      };
      this.player.Initialize();


      this.show = true;
      for (const [key, value] of Object.entries(this.player.scormResult.runtimeData)) {
        this.player.LMSSetValue(key, value as string);
      }
      this.player.setValueEvent.pipe(takeUntil(this.unsubscribe$)).subscribe(this.saveResult.bind(this));
      this.player.finishEvent.pipe(takeUntil(this.unsubscribe$)).subscribe(this.closeResult.bind(this));
      this.player.commitEvent.pipe(takeUntil(this.unsubscribe$)).subscribe(this.commitResult.bind(this));

    });
    this.resultUpdate$.pipe(distinctUntilChanged(),debounceTime(500)).subscribe( result => {
      this.player.Commit();
      // this.http.put<TrainingResult>(`<backendhost>/v1/my-trainings/${this.result.id}`, result).toPromise().then(console.log);
    });
  }
  saveResult(value: ScormResult) {
    value = {...value, ...{completedOn : null, completed: false}};
    this.result = {...this.result, ...value, ...this.translateToScorm12(value)};
    if (this.result.completedOn && !this.finished) {
      console.log("SET AS FINISHED");
      this.finishResult(this.result);
    }
    else {
      this.resultUpdate$.next(this.result);
    }

  }
  commitResult(value: ScormResult) {

    this.result = {
      ...this.result,
      ...value,
      ...this.translateToScorm12(value)
    };
    this.http.put<TrainingResult>(`<backendhost>/v1/my-trainings/${this.result.id}`, this.result).toPromise().then(() => {});
  }


  closeResult(value) {
    this.result = {
      ...this.result,
      ...value,
      ...this.translateToScorm12(value)
    };
    this.commitResult(this.result);
    this.ngZone.run( () => {
      console.log('CLOSE');
      this.onClose.emit(this.result);
    });

  }

  finishResult(value: ScormResult) {
    this.result = {
      ...this.result,
      ...value,
      ...this.translateToScorm12(value),
      ... {
        completed: true,
        completedOn: this.result.completedOn ? this.result.completedOn : new Date()
      }
    };
    this.finished = true;
    this.ngZone.run( () => {
      console.log("FINISHED emit");
      this.onFinish.emit(this.result);
    })
  }

  get runtimeData() {
    return {...this.player.runtimeData, ...this.translateToScorm12(this.player)};
  }

  translateToScorm12(data: any) {
    let additional:any = {};
    let scoreMin = 0;
    let completed = false;
    if (!data?.runtimeData) return;
    let statsues = this.scorm.pointable ? ['passed', 'failed'] : ['completed'];
    console.log(this.scorm.pointable);
    for (const [key, val] of Object.entries(data?.runtimeData)) {
      let value = val as string;
      switch (key) {
        case 'cmi.success_status':
          additional.passed = value == 'passed';
          if (statsues.indexOf(value) != -1) {
            completed = true;
          }
          break;
        case 'cmi.core.completion_status':
        case 'cmi.core.lesson_status':
        case 'cmi.completion_status':
        case 'cmi.lesson_status':
        case 'cmi.success_status':
        case 'cmi.core.success_status':
          // this.player.SetValue('cmi.completion_status', ['completed', 'passed', 'failed'].indexOf(value) != 0 ? 'completed' : value);

          if (statsues.indexOf(value) != -1) {
            completed = true;
          }
          break;
        case 'cmi.core.session_time':
          if (value) {
            const stringPattern = /(?:(\d+):)(?:(\d+):)(?:(\d+).)(?:(\d+))$/;
            const stringParts = stringPattern.exec(value);
            additional.timeSpent = (((stringParts[1] === undefined ? 0 : Number(stringParts[1])) /* Hours */
              * 60 + (stringParts[2] === undefined ? 0 : Number(stringParts[2])) /* Minutes */)
              * 60 + (stringParts[3] === undefined ? 0 : Number(stringParts[3])) /* Seconds */);
          }
          break;
        case 'cmi.score.max':
        case 'cmi.core.score.max':
          additional.scoreMax = parseInt(value);
          break;
        case 'cmi.score.min':
        case 'cmi.core.score.min':
          scoreMin = parseInt(value);
          break;
        case 'cmi.score.raw':
        case 'cmi.core.score.raw':
          additional.score = parseInt(value);
          break;
      }
    }

    additional.scorePercent = 100 * additional.score / (additional.scoreMax - scoreMin);
    additional.scorePercent = additional.scorePercent ? additional.scorePercent : 0;
    console.log(completed);
    if (completed) {
      additional.completed = true;
      additional.completedOn = this.result?.completedOn ? this.result.completedOn : new Date();
      if (!this.scorm.defaultMinScore) {
        additional.passed = additional.scorePercent >= this.scorm.minScore
      }
      if (!this.scorm.pointable) {
        additional.passed = true;
      }
    }
    return additional;
  }



  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  get entryPoint(): string {
    return `/assets/scorm/${this.scorm.code}/${this.scorm.entryPoint}`;
  }

  get scormDir(): string {
    return `/assets/scorm/${this.scorm.code}`;
  }

}



import { getBase64 } from '@/utils/base64';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { CropPopupComponent } from './crop-popup/crop-popup.component';


@Component({
  selector: 'app-upload-crop-image',
  templateUrl: './upload-crop-image.component.html',
  styleUrls: ['./upload-crop-image.component.scss']
})
export class UploadCropImageComponent implements OnInit {
  @Input() image: string;
  @Input() avatar: boolean = false;
  @Input() round: boolean = null;
  @Input() aspectRatio: string;
  @Input() aspectRatioClass: string;

  @Input()  file!: NzUploadFile;
  @Output() fileChange = new EventEmitter<NzUploadFile>();
  @Input() url: string;
  @Output() nzChange = new EventEmitter<any>();
  preview: any;
  uploading = false;
  constructor(
    private modalService: NgbModal,
    private httClient: HttpClient
  ) { }

  ngOnInit(): void {
    this.round === null ? this.avatar : this.round;
    this.aspectRatioClass ? this.aspectRatioClass : this.aspectRatio;
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    getBase64(file as any).then( (preview: string) => {
      // this.preview = preview;
      const modalRef: {componentInstance: CropPopupComponent} = this.modalService.open(CropPopupComponent, {
        size: 'sm',scrollable: true
      });

      modalRef.componentInstance.aspectRatio = this.aspectRatio;
      modalRef.componentInstance.round = this.avatar;
      modalRef.componentInstance.preview = preview;
      modalRef.componentInstance.onCrop.subscribe( (result: any) => {
        if(!result)return;
        console.log(this.url);
        if (this.url) {
          this.handleUpload(result.file);
        } else {
          this.preview = result.preview;
          this.fileChange.emit(result.file);
        }
      })
    })
    return false;
  };

  private handleUpload(file: File) {
    const formData = new FormData();
    formData.append('image', file);
    this.httClient.post<any>(this.url, formData).subscribe((response) => {
      let msg = {
          file: {...file, ...{status: 'done', response: response}}
      }
      console.log(msg);
      this.nzChange.emit(msg);
    })
  }

  handleImageUploadChange(info: { file: NzUploadFile }): void {
    // switch (info.file.status) {
    //   case 'uploading':
    //     this.uploading = true;
    //     break;
    //   case 'done':
    //     this.store.dispatch(fromAuthActions.setProfileAction({profile: info.file.response}));
    //     // this.store.pipe(take(1),select(fromUsersReducers.selectOneUser, this.user.id)).subscribe((user: User | null) => {
    //     //   this.user = user;
    //     // })
    //     this.uploading = false;
    //     break;
    //   case 'error':
    //     this.toastr.error('Błąd uploadu pliku');
    //     this.uploading = false;
    //     break;
    // }
  }
  // imageChangedEvent: any = '';
  // croppedImage: any = '';
  // fileChangeEvent(event: any): void {
  //   this.imageChangedEvent = event;
  // }
  // imageCropped(event: ImageCroppedEvent) {
  //     this.croppedImage = event.base64;
  // }
  // imageLoaded(image: LoadedImage) {
  //     // show cropper
  // }
  // cropperReady() {
  //     // cropper ready
  // }
  // loadImageFailed() {
  //     // show message
  // }
}

import { Slot, SlotNotificationUser } from "@/model/slot.model";
import { createPaginationInitialState, Pagination } from "@/model/pagination.model";
import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import * as actions from './slot.actions';
import * as actionsNototifications from './slot.notification.actions';
import { Outpost } from "@/model/outpost.model";
import { User } from "@/model/user.model";

export interface NotificationState extends Pagination<SlotNotificationUser> {
  slot: Slot
}
export interface State extends Pagination<Slot> {
  outpost: number,
  type: string,
  user: User,
  weekday: boolean,
  saturday: boolean,
  notificationUsers: NotificationState
}

export const adapter: EntityAdapter<Slot> = createEntityAdapter<Slot>();
export const notificationUsersAdapter: EntityAdapter<SlotNotificationUser> = createEntityAdapter<SlotNotificationUser>();

export const notificationInitialState: NotificationState = {
  ...createPaginationInitialState(notificationUsersAdapter, false),
  ...{
    slot: null
  }
}
export const initialState: State = {
  ...createPaginationInitialState(adapter, false),
  ...{
    outpost: null,
    type: null,
    user: null,
    weekday: true,
    saturday: true,
    notificationUsers: notificationInitialState,
    order: null
  }
};

export const reducer = createReducer(
  initialState,
  on(actions.clear, (state) => initialState),
  on(actions.fetch, (state) => { return {...state, ...{loading: true}}}),
  on(actions.fetchMore, (state) => { return {...state, ...{loading: true}}}),
  on(actions.fetchOneSlot, (state) => { return {...state, ...{loading: true}}}),

  on(actions.setOutpost, (state, action) => { return {...state, ...{outpost: action.outpost}}}),
  on(actions.setType, (state, action) => { return {...state, ...{type: action.value}}}),
  on(actions.setUser, (state, action) => { return {...state, ...{user: action.user}}}),
  on(actions.setWeekday, (state, action) => { return {...state, ...{weekday: action.value}}}),
  on(actions.setSaturday, (state, action) => { return {...state, ...{saturday: action.value}}}),
  on(actions.setDafaultDay, (state, action) => { return {...state, ...{weekday: true, saturday: true}}}),


  on(actions.setPage, (state, action) => { return {...state, ...{page: action.page}}}),
  on(actions.setOrder, (state, action) => { return {...state, ...{order: action.order}}}),
  on(actions.setDirection, (state, action) => { return {...state, ...{asc: action.asc}}}),
  on(actions.setSearch, (state, action) => { return {...state, ...{search: action.search}}}),
  on(actions.setManySlot, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: action.total,
        list: adapter.addMany(action.list, adapter.removeAll(state.list))
      }
    }
  }),
  on(actions.addManySlot, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: action.total,
        list: adapter.addMany(action.list, state.list)
      }
    }
  }),
  on(actions.addSlot, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: state.total + 1,
        list: adapter.addOne(action.slot, state.list)
      }
    }
  }),
  on(actions.setSlot, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.updateOne({ id: action.slot.id, changes: action.slot}, state.list)
      }
    }
  }),
  on(actions.removeSlot, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: state.total - 1,
        list: adapter.removeOne(action.id, state.list)
      }
    }
  }),

  on(actionsNototifications.clear, (state) => { return {
    ...state,
    ...{
      notificationUsers: notificationInitialState
    }
  }}),
  on(actionsNototifications.fetch, (state) => { return {
    ...state,
    ...{
      notificationUsers: {
        ...state.notificationUsers,
        ...{loading:true}
      }
    }
  }}),
  on(actionsNototifications.deleteSlotNotificationUser, (state) => { return {
    ...state,
    ...{
      notificationUsers: {
        ...state.notificationUsers,
        ...{loading:true}
      }
    }
  }}),
  on(actionsNototifications.setSlot, (state, action) => { return {
    ...state,
    ...{
      notificationUsers: {
        ...state.notificationUsers,
        ...{slot: action.slot}
      }
    }
  }}),
  on(actionsNototifications.setPage, (state, action) => { return {
    ...state,
    ...{
      notificationUsers: {
        ...state.notificationUsers,
        ...{page: action.page}
      }
    }
  }}),
  on(actionsNototifications.setOrder, (state, action) => { return {
    ...state,
    ...{
      notificationUsers: {
        ...state.notificationUsers,
        ...{order: action.order}
      }
    }
  }}),
  on(actionsNototifications.setDirection, (state, action) => { return {
    ...state,
    ...{
      notificationUsers: {
        ...state.notificationUsers,
        ...{asc: action.asc}
      }
    }
  }}),
  on(actionsNototifications.setSearch, (state, action) => { return {
    ...state,
    ...{
      notificationUsers: {
        ...state.notificationUsers,
        ...{search: action.search}
      }
    }
  }}),

  on(actionsNototifications.setMany, (state, action) => { return {
    ...state,
    ...{
      notificationUsers: {
        ...state.notificationUsers,
        ...{
          loading: false,
          total: action.total,
          list: notificationUsersAdapter.addMany(action.list, notificationUsersAdapter.removeAll(state.notificationUsers.list))
        }
      }
    }
  }}),
  on(actionsNototifications.addOne, (state, action) => {
    let ids: number[] = state.notificationUsers.list.ids as number[];
    let keep = ids.indexOf(action.item.id) >= 0;
    let newList = keep
      ? notificationUsersAdapter.updateOne({id: action.item.id, changes: {added: true}}, state.notificationUsers.list)
      : notificationUsersAdapter.addOne(action.item, state.notificationUsers.list);

      let newTotal = state.notificationUsers.total;
      newTotal += keep ? 0 : 1;
    return {
    ...state,
    ...{
      notificationUsers: {
        ...state.notificationUsers,
        ...{
          loading: false,
          total: newTotal,
          list: newList
        }
      }
    }
  }}),
  on(actionsNototifications.removeOne, (state, action) => {
    let newList = action.keep
      ? notificationUsersAdapter.updateOne({id: action.id, changes: {added: false}}, state.notificationUsers.list)
      : notificationUsersAdapter.removeOne(action.id, state.notificationUsers.list);

    let newTotal = state.notificationUsers.total;
    newTotal -= action.keep ? 0 : 1;
    return {
    ...state,
    ...{
      notificationUsers: {
        ...state.notificationUsers,
        ...{
          loading: false,
          total: newTotal,
          list: newList
        }
      }
    }
  }}),

);



export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectState = createFeatureSelector<State>('slot');
export const selectAllSlots = createSelector(selectState, (state) => selectAll(state.list) )
export const selectOneSlot = createSelector(selectState, (state: State, id) => state.list.entities[id]);

export const selectAllNotificationUsers = createSelector(selectState, (state) => notificationUsersAdapter.getSelectors().selectAll(state.notificationUsers.list) )
export const selectAllNotificationState = createSelector(selectState, (state) => state.notificationUsers )

import { SlotDay, SlotType } from '@/model/slot.model';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import * as fromOutpostActions from '@/store/outpost/outposts.actions';
import * as fromOutpostReducers from '@/store/outpost/outposts.reducers';
import { Outpost } from '@/model/outpost.model';
import { map, takeUntil } from 'rxjs/operators';
import { User, UserWorkMarketPerferences } from '@/model/user.model';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-user-work-market-perferences',
  templateUrl: './user-work-market-perferences.component.html',
  styleUrls: ['./user-work-market-perferences.component.scss']
})
export class UserWorkMarketPerferencesComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  SlotType = Object.values(SlotType);
  form: FormGroup;
  preferences: UserWorkMarketPerferences;
  user: User;
  outposts: Outpost[] = [];
  selectedOutposts: Outpost[] = [];
  loading = false;
  myaccount = false;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private outpostStore: Store<fromOutpostReducers.State>,
  ) {
    this.outpostStore.dispatch(fromOutpostActions.fetch());
    this.form = new FormGroup({
      type: new FormGroup(Object.values(SlotType).reduce((obj,key) => ({...obj, [key]: new FormControl(false)}), {}),[this.CheckboxValidator]),
      day: new FormGroup(Object.values(SlotDay).reduce((obj,key) => ({...obj, [key]: new FormControl(false)}), {}),[this.CheckboxValidator]),
      outpost: new FormGroup({
        outpost: new FormControl()
      }),
    });
    this.form.valueChanges.subscribe(this.setPreferences.bind(this))
  }

  CheckboxValidator = (fc: FormGroup) => {
    let hasCheck = Object.values(fc.value).reduce((a,b) => a || b, false);
    return hasCheck ? null : {required : true};
  };

  private loadOutposts(): void {
    this.loadPreferences();
    // this.loading = true;
    // this.outpostStore.select(fromOutpostReducers.selectAllOutposts).subscribe(list => {
    //   list.forEach(item => {
    //     this.outposts.push(item);
    //     (this.form.controls.outpost as FormGroup).addControl(item.id.toString(), new FormControl(false))
    //   });
    //   this.loadPreferences();
    // })
  }

  private static mapCheckbox(values: Object) {
    return Object.entries(values).filter(([key,val]) => val).map(([key,val]) => key);
  }
  private static mapCheckboxReverse(values: string[]) {
    return Object.values(values).reduce((obj,key) => ({...obj, [key.toString()]: true}), {});
  }

  private setPreferences(values): void {
    this.preferences = {
      type: UserWorkMarketPerferencesComponent.mapCheckbox(values.type),
      day: UserWorkMarketPerferencesComponent.mapCheckbox(values.day),
      outpost: values.outpost.outpost.map(item=>item.id)//UserWorkMarketPerferencesComponent.mapCheckbox(values.outpost),
    }
  }

  private loadPreferences(): void {

    this.http.get<UserWorkMarketPerferences>(`<backendhost>/v1/users/${this.user.id}/work-market-perferences`)
        .subscribe(
          (response)  => {
            this.selectedOutposts = response.outpost;
            this.form.patchValue({
              outpost: {outpost: this.selectedOutposts},//UserWorkMarketPerferencesComponent.mapCheckboxReverse(response.outpost),
              type: UserWorkMarketPerferencesComponent.mapCheckboxReverse(response.type),
              day: UserWorkMarketPerferencesComponent.mapCheckboxReverse(response.day)
            });
            this.loading = false;
          },
          (error)  => {
            this.toastr.error(error.message);
          },
        );
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe(console.log);
    this.activatedRoute.data.subscribe((data:Data) => {
      this.myaccount = data.myaccount ? true : false;
      this.user = data.instance;
      this.loadOutposts();
    })

  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  save(): void {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    if (this.form.valid) {
      this.setPreferences(this.form.value);
      this.http.post<UserWorkMarketPerferences>(`<backendhost>/v1/users/${this.user.id}/work-market-perferences`,this.preferences)
        .subscribe(
          (response)  => {
            this.toastr.success('Zapisano zmiany');
            if(this.myaccount) {
              this.router.navigate(['/']);
            } else {
              this.router.navigate(['/users']);
            }

          },
          (error)  => {
            this.toastr.error(error.message);
          },
        )
    } else {
        this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }

  get outpostLoading(): Observable<boolean>  {
    return this.outpostStore.pipe(
      takeUntil(this.unsubscribe$),
      select(fromOutpostReducers.selectState),
      map( (state:fromOutpostReducers.State)  => state.loading),
    );
  }

  // loading = false;
  // get users$(): Observable<User[]> {
  //   return this.usersStore.pipe(select(fromUsersReducers.selectAllUsers));
  // }
  // usersInput$ = new Subject<string>();
  // trackByFn(item: any) {
  //   return item.id;
  // }

}

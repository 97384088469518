import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { from, Observable, of } from 'rxjs';
import * as fromQuizActions from '@/store/quiz/quiz.actions'
import * as fromQuizReducers from '@/store/quiz/quiz.reducers'
import { AuthService } from '@/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { select, Store } from '@ngrx/store';
import { Quiz } from '@/model/quiz.model';
import { filter, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QuizResolver implements Resolve<Quiz> {constructor(
  private store: Store<fromQuizReducers.State>,
  private authService: AuthService,
  private router: Router,
  private toastr: ToastrService,
) { }

check(resolve: any, instance : any, route: ActivatedRouteSnapshot) {
  this.authService.checkPermition(route.data.permition, instance).then( (alowed:boolean) => {
    if (alowed) {
      resolve(instance);
    } else {
      // resolve(null);
      this.toastr.error("Nie masz uprawnień do tej sekcji!");
      this.router.navigate(["/"]);
    }
  });
}

resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Quiz> {
  return from( new Promise<Quiz>(resolve => {
    let id = 1*(route.params?.category ? route.params?.category : route.params.id);
    this.store.pipe(
      select(fromQuizReducers.selectOneQuiz, id),
      tap( (result) => {
        if(!result) {
          this.store.dispatch(fromQuizActions.fetchOne({id}));
        }
      }),
      filter((result) => result != null),
      take(1),
    ).subscribe(instance => this.check(resolve, instance, route));
  }));
}

}

<section class="content">
  <div class="container">
    <app-package-tab></app-package-tab>
    <div class="card" style="border-top-left-radius: 0;border-top-right-radius: 0;">
      <div class="overlay position-absolute" *ngIf="package?.invitePacks == null" style="top: 0; bottom: 0; left: 0; right: 0">
        <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
          <i class="fas fa-3x fa-spinner fa-spin"></i>
          <p>Ładowanie...</p>
        </div>
      </div>
      <div class="card-header d-flex align-items-center">
        <h3 class="card-title m-0 flex-grow-1 text-bold"><i class="fas fa-users fa-fw"></i> Wysłane zaproszenia</h3>
      </div>
      <div class="table-responsive">
        <table class="table align-middle table-hover">
          <thead>
            <tr>
              <th style="width:1rem">#</th>
              <th class="">Data zakończenia</th>
              <th class="">Data wysyłki</th>
              <th class="">Liczba osób</th>
              <th class="text-right" > </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of package?.invitePacks; let idx = index">
              <th class="align-middle">{{idx + 1}}</th>
              <td class="align-middle">{{item.endDate | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
              <td class="align-middle">{{item.publishedAt | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
              <td class="align-middle">
                <span class="badge rounded-pill bg-primary text-md">
                  <i class="fas fa-users"></i>
                  {{item.invited.total}}
                </span>
              </td>
              <td>
                <button
                    class="btn btn-primary btn-sm elevation-2 mr-2"
                    [routerLink]="['notification', item.id]"
                    ngbTooltip="Edycja powiadomień"
                    checkRolePermition="training.admin.scorm">
                  <i class="fas fa-bell fa-fw fa-lg"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="!package?.invitePacks?.length"></nz-empty>
      </div>
    </div>
  </div>
</section>





<div class="content">
  <div class="container pt-3 pb-3">
    <div class="row">
      <div class="col-12">
        <form [formGroup]="form" (ngSubmit)="save()">
        <div class="card card-primary">
          <div class="overlay" *ngIf="saving | async">
            <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%,0);text-align: center; padding: 25px 0">
              <i class="fas fa-3x fa-spinner fa-spin"></i>
              <p>Zapisywanie...</p>
            </div>
          </div>
          <div class="card-body">

            <div class="form-group">
              <label for="title">Tytuł</label>
              <input formControlName="title" [appIsValid]="form.controls.title" type="text" class="form-control" placeholder="Tytuł" />
              <div class="invalid-feedback" *ngIf="form.controls.title.errors?.required">
                Pole jest wymagane
              </div>
            </div>

            <div class="form-group">
              <label for="description">Opis</label>
              <app-editor formControlName="description"></app-editor>
              <div class="text-danger" *ngIf="form.controls.description.touched && form.controls.description.errors?.required">
                Pole jest wymagane
              </div>
            </div>
            <!-- <table class="table align-middle table-hover">
              <thead>
                <tr>
                  <th style="width:1rem">#</th>
                  <th>Pytanie / Odpowiedz</th>
                  <th class="text-right">
                    <a class="btn btn-success btn-sm elevation-2 text-nowrap" (click)="addFAQ()" >
                      <i class="fas fa-plus fa-fw fa-lg"></i> Dodaj
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let faq of article?.faq; let idx = index;">
                  <td>{{idx + 1}}</td>
                  <td style="min-width: 200px;">
                    <input type="text" class="form-control" [(ngModel)]="faq.question" [ngModelOptions]="{standalone: true}">
                    <app-editor [(ngModel)]="faq.answer" [ngModelOptions]="{standalone: true}"></app-editor>
                  </td>
                  <td class="align-middle text-right">
                    <a
                        class="btn btn-danger btn-sm elevation-2"
                        nz-popconfirm
                        nzPopconfirmTitle="Czy na pewno usunąć?"
                        nzOkText="Tak"
                        nzCancelText="Nie"
                        nzPopconfirmPlacement="left"
                        ngbTooltip="Usuń"
                        (nzOnConfirm)="deleteFAQ(idx)" >
                      <i class="fas fa-trash fa-fw fa-lg"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th style="width:1rem">#</th>
                  <th>Pytanie / Odpowiedz</th>
                  <th class="text-right">
                    <a class="btn btn-success btn-sm elevation-2 text-nowrap" (click)="addFAQ()" >
                      <i class="fas fa-plus fa-fw fa-lg"></i> Dodaj
                    </a>
                  </th>
                </tr>
              </tfoot>
            </table> -->
          </div>
          <div class="card-footer" style="position: relative;" >
            <a id="foot-ancor" style="position: relative;top:-450px"></a>
            <button class="btn btn-success float-right" type="submit">
              Zapisz
              <i class="fas fa-angle-right fa-fw"></i>
            </button>
          </div>
          <div class="card-footer" >
            <h4 class="card-title">Podkategorie</h4>
          </div>
          <table class="table align-middle table-hover">
              <thead>
                <tr>
                  <th style="width:1rem">#</th>
                  <th>Tytuł</th>
                  <th>Priorytet</th>
                  <th class="text-right">
                    <a class="btn btn-success btn-sm elevation-2 text-nowrap" [routerLink]="['add']" [class.disabled]="!category?.id">
                      <i class="fas fa-plus fa-fw fa-lg"></i> Dodaj
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let sub of category?.children; let idx = index;">
                  <td>{{idx + 1}}</td>
                  <td>{{sub.title}}</td>
                  <td>{{priority(sub.priority) | async}}</td>
                  <td class="align-middle text-right">
                    <button class="btn btn-primary btn-sm elevation-2 mr-2" [routerLink]="['edit', sub.id]" ngbTooltip="Edycja">
                      <i class="fas fa-edit fa-fw fa-lg"></i>
                    </button>
                    <a
                        class="btn btn-danger btn-sm elevation-2"
                        nz-popconfirm
                        nzPopconfirmTitle="Czy na pewno usunąć?"
                        nzOkText="Tak"
                        nzCancelText="Nie"
                        nzPopconfirmPlacement="left"
                        ngbTooltip="Usuń"
                        (nzOnConfirm)="deleteSub(sub)" >
                      <i class="fas fa-trash fa-fw fa-lg"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>

            <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="category?.id && category?.children.length == 0"></nz-empty>
            <div style="display: flex; align-items: center; flex-direction: column;" *ngIf="!category?.id">
              <div class="text-center">
                <span class="fa-stack fa-2x">
                  <i class="fas fa-edit fa-stack-1x"></i>
                  <i class="fas fa-ban fa-stack-2x" style="color:Tomato"></i>
                </span>
                <p>Dodawanie podkategorii możliwe będzie po zapisaniu nowej kategorii</p>
              </div>
            </div>
            <!-- <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="category?.children.length == 0"></nz-empty> -->
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

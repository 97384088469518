import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as fromPostReducers from '@/store/post/post.reducers';
import * as fromPostActions from '@/store/post/post.actions';
import * as fromIdeaReducers from '@/store/ideas/ideas.reducers';
import * as fromIdeaActions from '@/store/ideas/ideas.actions';
import { Store } from '@ngrx/store';
import { Post } from '@/model/post.model';
import { FormControl, NgForm } from '@angular/forms';
import { User } from '@/model/user.model';
import { Comment } from '@/model/comment.model';
import { AuthService } from '@/auth/auth.service';
import { Actions, ofType } from '@ngrx/effects';
import { filter } from 'rxjs/operators';
import { Idea } from '@/model/idea.model';

@Component({
  selector: 'comment-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {
  @Input() post: Post;
  @Input() idea: Idea;
  @Input() replyTo: Comment;
  @Output() onReply: EventEmitter<void> = new EventEmitter<void>();
  user: User;
  savingSubsctipyion

  constructor(
    private store: Store<fromPostReducers.PostsState>,
    private ideaStore: Store<fromIdeaReducers.State>,
    private authService: AuthService,
    private actions: Actions
  ) {
  }

  ngOnInit(): void {
    this.authService.getUser().then(user => {this.user = user});
    this.actions.pipe(
      ofType(fromPostActions.addCommentSuccess),
      filter(action => {
        return this.post?.id
          && action.post?.id == this.post?.id
          && (
            (this.replyTo && action.replyTo?.id == this.replyTo?.id)
            || !this.replyTo && !action.replyTo
          )
      })
    ).subscribe( () => {
      this.onReply.emit();
    })
  }


  private submitPost(formModel: NgForm): void {
    this.post = {...this.post, ...{
      comments: {...this.post.comments, ...{saving:true}}
    }}
    this.store.dispatch(fromPostActions.createPostCommentAction({
      post: this.post,
      replyTo: this.replyTo,
      comment: formModel.form.value
    }));
  }

  private submitIdea(formModel: NgForm): void {
    // this.idea = {...this.idea, ...{
    //   comments: {...this.post.comments, ...{saving:true}}
    // }}
    this.store.dispatch(fromIdeaActions.createIdeaCommentAction({
      idea: this.idea,
      replyTo: this.replyTo,
      comment: formModel.form.value
    }));
  }

  onSubmit(formModel: NgForm): void {
    if(!formModel.valid) return;
    if(this.idea) {
      this.submitIdea(formModel);
    } else if(this.post) {
      this.submitPost(formModel);
    }

    formModel.reset();
  }
}

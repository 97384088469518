<div class="content">
  <div class="container pt-3 pb-3">
    <div class="row">
      <div class="col-12">
        <form [formGroup]="form" (ngSubmit)="save()">
        <div class="card card-primary">
          <div class="overlay" *ngIf="saving | async">
            <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%,0);text-align: center; padding: 25px 0">
              <i class="fas fa-3x fa-spinner fa-spin"></i>
              <p>Zapisywanie...</p>
            </div>
          </div>
          <div class="card-body">

            <div class="form-group">
              <label for="title">Tytuł</label>
              <input formControlName="title" [appIsValid]="form.controls.title" type="text" class="form-control" placeholder="Tytuł" />
              <div class="invalid-feedback" *ngIf="form.controls.title.errors?.required">
                Pole jest wymagane
              </div>
            </div>

            <div class="form-group">
              <label for="content">Treść</label>
              <!-- <editor [init]="tinyMceConfig.init" apiKey="{{tinyMceConfig.apiKey}}" formControlName="content"></editor> -->
              <app-editor formControlName="content"></app-editor>
              <div class="text-danger" *ngIf="form.controls.content.touched && form.controls.content.errors?.required">
                Pole jest wymagane
              </div>
            </div>
            <div class="form-group">
              <label for="inputName">Obrazek miniaturka</label>
              <div style="max-width: 250px">
                <app-upload-crop-image *ngIf="article"
                  [avatar]="false"
                  [round]="false"
                  [(file)] = "imageFile"
                  [aspectRatio]="1 / 1">
                </app-upload-crop-image>
                <!-- <nz-upload
                  class="bg-uploader  w-100"
                  nzAccept="image/png, image/jpeg"
                  nzName="image"
                  nzListType="picture-card"
                  [nzShowUploadList]="false"
                  [nzBeforeUpload]="beforeUploadImage"
                >
                  <ng-container *ngIf="!article?.image" class="w-100">
                    <i class="fas fa-3x fa-image"></i>
                  </ng-container>
                  <img *ngIf="article?.image" [src]="article.image | image" style="width: 100%" />
                </nz-upload> -->
              </div>
            </div>
            <div class="form-group">
              <label for="inputName">Obrazek w tle</label>
              <div style="max-width: 100%;">
                <app-upload-crop-image *ngIf="article"
                  [avatar]="false"
                  [round]="false"
                  [(file)] = "backgroundFile"
                  [aspectRatio]="4 / 1">
                </app-upload-crop-image>
                <!-- <nz-upload
                  class="bg-uploader  w-100"
                  nzAccept="image/png, image/jpeg"
                  nzName="image"
                  nzListType="picture-card"
                  [nzShowUploadList]="false"
                  [nzBeforeUpload]="beforeUploadBackground"
                >
                  <ng-container *ngIf="!article?.background" class="w-100">
                    <i class="fas fa-3x fa-image"></i>
                  </ng-container>
                  <img *ngIf="article?.background" [src]="article.background | image" style="width: 100%" />
                </nz-upload> -->
              </div>
            </div>
            <div class="form-group col-md-12">
              <label>Plik Audio</label>

              <div class="d-flex" style="flex-direction: column;" *ngIf="audioPath">
                <audio height="100%" controls>
                  <source [src]="audioPath" type="audio/mp3">
                  Your browser does not support the video tag.
                </audio>
                <button class="btn btn-danger" (click)="clearAudio()">Wyczyść plik audio</button>
              </div>
              <!-- <div *ngIf="slide.audio" plyr style="width: 100%;" [plyrPlaysInline]="true" plyrType="audio" [plyrSources]="audioPath" ></div> -->
              <div>
                <nz-upload
                  nzType="drag"
                  [nzMultiple]="false"
                  [(nzFileList)]="audioFile"
                  [nzBeforeUpload]="beforeUploadAudio"
                >
                <!-- [nzFileType]="'audio/mp3'" -->
                <div>
                  <p class="ant-upload-drag-icon">
                    <i nz-icon nzType="inbox"></i>
                  </p>
                  <p class="ant-upload-text">Kliknij lub przeciągnij plik do tego obszaru, aby przesłać</p>
                  <p class="ant-upload-hint"></p>
                </div>
                </nz-upload>
              </div>
            </div>
            <div class="form-group">
              <label>Tagi</label>
              <tag-input
                [(ngModel)]='article.tags'
                [ngModelOptions]="{standalone: true}"
                theme='bootstrap'
                secondaryPlaceholder="Wpisz nowy tag"></tag-input>
            </div>
            <label>Pytania i odpowiedzi</label>
            <table class="table align-middle table-hover">
              <thead>
                <tr>
                  <th style="width:1rem">#</th>
                  <th>Pytanie / Odpowiedz</th>
                  <th class="text-right">
                    <a class="btn btn-success btn-sm elevation-2 text-nowrap" (click)="addFAQ()" >
                      <i class="fas fa-plus fa-fw fa-lg"></i> Dodaj
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let faq of article?.faq; let idx = index;">
                  <td>{{idx + 1}}</td>
                  <td style="min-width: 200px;">
                    <input type="text" class="form-control" [(ngModel)]="faq.question" [ngModelOptions]="{standalone: true}">
                    <!-- <editor [init]="tinyMceConfigSmall.init" apiKey="{{tinyMceConfig.apiKey}}" [(ngModel)]="faq.answer" [ngModelOptions]="{standalone: true}"></editor> -->
                    <app-editor [(ngModel)]="faq.answer" [ngModelOptions]="{standalone: true}"></app-editor>
                  </td>
                  <td class="align-middle text-right">
                    <a
                        class="btn btn-danger btn-sm elevation-2"
                        nz-popconfirm
                        nzPopconfirmTitle="Czy na pewno usunąć?"
                        nzOkText="Tak"
                        nzCancelText="Nie"
                        nzPopconfirmPlacement="left"
                        ngbTooltip="Usuń"
                        (nzOnConfirm)="deleteFAQ(idx)" >
                      <i class="fas fa-trash fa-fw fa-lg"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th style="width:1rem">#</th>
                  <th>Pytanie / Odpowiedz</th>
                  <th class="text-right">
                    <a class="btn btn-success btn-sm elevation-2 text-nowrap" (click)="addFAQ()" >
                      <i class="fas fa-plus fa-fw fa-lg"></i> Dodaj
                    </a>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="card-footer" style="position: relative;" >
            <a id="foot-ancor" style="position: relative;top:-450px"></a>
            <button class="btn btn-success float-right" type="submit">
              Zapisz
              <i class="fas fa-angle-right fa-fw"></i>
            </button>
          </div>
        </div>
        </form>
      </div>
    </div>
  </div>
</div>

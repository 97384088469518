<div class="card">
  <div class="card-body">

    <div class="form-group">
      <label for="typeahead-prevent-manual-entry">Dodaj użytkownika do listy</label>
      <input id="typeahead-prevent-manual-entry"  type="text" class="form-control" placeholder="Zacznij wpisywać"

            [(ngModel)]="model"
            [ngModelOptions]="{standalone: true}"
            [ngbTypeahead]="search"
            [resultTemplate]="rt"
            [inputFormatter]="formatterEmpty"
            [editable]='false'
            (selectItem)="onUserSelect($event)"/>
    </div>

    <ng-template #rt let-r="result" let-t="term">
      <div class="d-flex flex-md-row">
      <div class="user-panel mr-2">
        <img [src]="r | userAvatar" class="img-circle">
      </div>
      <ngb-highlight class="align-self-center" [result]="formatter(r)" [term]="t"></ngb-highlight>
      </div>
    </ng-template>

  </div>
  <div class="card-header d-flex align-items-center">
    <h3 class="card-title m-0 flex-grow-1 text-bold">
      <i class="fas fa-users fa-fw"></i>
      Lista użytkowników
      <span class="badge text-sm rounded-pill badge-secondary like-counter text-white">
        <i class="fas fa-users fa-fw"></i>
        {{ total | async }}
      </span>
    </h3>
    <div class="card-tools flex-grow-1">
      <div class="input-group">
        <input type="text" name="table_search" class="form-control float-right" placeholder="Szukaj" #searchInput>
        <button class="btn bg-transparent" style="position:absolute; right: 0; transform: translate(-100%,0);" (click)="clearSearch()">
          <i class="fa fa-times"></i>
        </button>
        <span class="input-group-append">
          <div class="input-group-text bg-transparent"><i class="fas fa-search"></i></div>
        </span>
      </div>
    </div>
  </div>
  <div class="table-responsive position-relative">
    <table class="table align-middle table-hover">
      <thead>
        <tr>
          <th style="width:1rem">#</th>
          <th></th>
          <th class="sortable" (click)="setSort('firstname')"  [ngClass]="{'active': (order | async) == 'firstname'}"  >
            Imie
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <th class="sortable" (click)="setSort('lastname')"  [ngClass]="{'active': (order | async) == 'lastname'}"  >
            Nazwisko
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <th class="sortable" (click)="setSort('email')"  [ngClass]="{'active': (order | async) == 'email'}"  >
            Email
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <!-- <th class="sortable" (click)="setSort('endTime')"  [ngClass]="{'active': (order | async) == 'endTime'}"  >
            Koniec
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <th colspan="2" class="sortable" (click)="setSort('user')"  [ngClass]="{'active': (order | async) == 'user'}"  >
            Pracownik
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          -->
          <th></th>
          <th></th>
          <th class="text-right" ></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of list | async; let idx = index; trackBy: identify">
          <th class="align-middle">{{idx + 1}}</th>
          <td class="user-panel" style="width:70px">
            <img
                  [src]="item  | userAvatar: 34"
                  class="img-circle elevation-2"
                  alt="User Image"
                  style="width:45px"
                  [appUserDisactivatable]="item"
              />
          </td>
          <td class="align-middle">
            <span [appUserDisactivatable]="item">{{item.firstname}}</span>
          </td>
          <td class="align-middle">
            <span [appUserDisactivatable]="item">{{item.lastname}}</span>
          </td>
          <td class="align-middle">
            <span [appUserDisactivatable]="item">{{item?.active ? item?.email : '-'}}</span>
          </td>
          <td class="align-middle" *ngIf="item.added">
            <i class="fas fa-check text-success"></i>
            Dodany ręcznie
          </td>
          <td class="align-middle" *ngIf="!item.added">
            <i class="fas fa-cogs text-info"></i>
            Wg ustawień użytkownika
          </td>
          <td class="align-middle">
            <!-- <div class="form-check" *ngIf="item.added">
              <label class="form-check-label">
              <input class="form-check-input" type="checkbox" [ngModel]="item.sms" (ngModelChange)="toggleSms(item)">
                Powiadamiaj SMS
              </label>
            </div> -->
          </td>
          <td class="align-middle text-nowrap text-right" >

            <button
                *ngIf="item.added"
                checkRolePermition="outpost.admin"
                class="btn btn-danger btn-sm elevation-2"
                nz-popconfirm
                nzPopconfirmTitle="Czy na pewno usunąć?"
                nzOkText="Tak"
                nzCancelText="Nie"
                nzPopconfirmPlacement="left"
                ngbTooltip="Usuń z listy"
                container="body"
                (nzOnConfirm)="delete(item)" >
              <i class="fas fa-trash fa-fw fa-lg"></i>
            </button>

          </td>
        </tr>
      </tbody>
    </table>
    <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(list | async).length == 0"></nz-empty>
    <div class="overlay position-absolute" *ngIf="(loading | async)" style="top: 0; bottom: 0; left: 0; right: 0">
      <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
        <i class="fas fa-3x fa-spinner fa-spin"></i>
        <p>Ładowanie...</p>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="(total | async)"  (pageChange)="pageChange($event)">
      <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationEllipsis>...</ng-template>
      <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>
  </div>
</div>

import { KbArticle } from "@/model/article.model";
import { KbFile } from "@/model/file.model";
import { Folder } from "@/model/folder.model";
import { EntityState } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";

export const clearSaving = createAction(
  "kb clearSaving"
);

export const saveSuccess = createAction(
  "kb saveSuccess",
  props<{ id: number, parent: number}>()
);

export const fetchFolder = createAction(
  "kb fetchFolder",
  props<{ id: number}>()
);

export const setFolder = createAction(
  "kb setFolder",
  props<{ folder: Folder}>()
);

export const createFolder = createAction(
  "kb createFolder",
  props<{ folder: Folder, parent: Folder, image: any, background: any}>()
);

export const addFolder = createAction(
  "kb addFolder",
  props<{ folder: Folder}>()
);

export const removeFolder = createAction(
  "kb removeFolder",
  props<{ folder: Folder}>()
);

export const deleteFolder = createAction(
  "kb deleteFolder",
  props<{ folder: Folder}>()
);

export const saveFolder = createAction(
  "kb saveFolder",
  props<{ folder: Folder}>()
);

export const updateFolder = createAction(
  "kb updateFolder",
  props<{ folder: Folder}>()
);

export const updateSelf = createAction(
  "kb updateSelf",
  props<{ folder: Folder}>()
);

export const clearFiles = createAction(
  "kb clearFiles"
);

export const fetchFiles = createAction(
  "kb fetchFiles",
  props<{ id: number}>()
);

export const setFiles = createAction(
  "kb setFiles",
  props<{ files: EntityState<KbFile>}>()
);

export const addFile = createAction(
  "kb addFile",
  props<{ file: KbFile}>()
);

export const updateFile = createAction(
  "kb updateFile",
  props<{ file: KbFile}>()
);

export const deleteFile = createAction(
  "kb deleteFile",
  props<{ file: KbFile}>()
);

export const createArticle = createAction(
  "kb createArticle",
  props<{ article: KbArticle, folder: Folder, image: any, background: any, audioFile: any}>()
);

export const addArticle = createAction(
  "kb addArticle",
  props<{ article: KbArticle}>()
);

export const fetchArticles = createAction(
  "kb fetchArticles",
  props<{ id: number}>()
);

export const setArticles = createAction(
  "kb setArticles",
  props<{ articles: EntityState<KbArticle>}>()
);

export const saveArticle = createAction(
  "kb saveArticle",
  props<{ article: KbArticle, folder: Folder, image: any, background: any, audioFile: any}>()
);

export const updateArticle = createAction(
  "kb updateArticle",
  props<{ article: KbArticle}>()
);

export const deleteArticle = createAction(
  "kb deleteArticle",
  props<{ article: KbArticle}>()
);

<div class="content-header pt-0">
  <div checkRolePermition="training.admin" class="row">
    <div class="col-12 text-right">
      <div ngbDropdown class="d-inline-block">
        <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
          <i class="fas fa-tools fa-fw"></i> Zarządzaj
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <a ngbDropdownItem checkRolePermition="training.admin.scorm"  [routerLink]="['admin']"><i class="fas fa-vote-yea fa-fw"></i> Ankiety</a>
          <a ngbDropdownItem checkRolePermition="training.admin.scorm"  [routerLink]="['admin/archive']"><i class="fas fa-archive fa-fw"></i> Archiwum ankiet</a>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="content">
  <div class="container-fluid">
    <router-outlet></router-outlet>
  </div>
</section>

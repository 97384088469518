import { KbFile } from '@/model/file.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class KbDownloadResolver implements Resolve<KbFile> {
  constructor(
    private httpClient: HttpClient,
    // private activatedRoute: ActivatedRoute
  ) {
    // this.activatedRoute
    //   .paramMap
    //   .pipe(map((pm: ParamMap) => +pm.get('id')))
    //   .subscribe(id => {

    // });
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<KbFile> {
    return this.httpClient
      .get(`${environment.apiUrl}/v1/kb/file/${route.params.id}`, {responseType: 'json'})
      .pipe(map( (file: KbFile) => file));
      // .pipe(
      //   switchMap( file => {
      //     return this.httpClient
      //       .get(`${environment.apiUrl}/v1/kb/file/${route.params.id}/download`, {responseType: 'blob'})
      //       .pipe(map(blob => {
      //         return {
      //           file: file,
      //           blob: blob
      //         }
      //       }))
      //   }),
      //   tap( (response: {file: KbFile, blob:any}) => {
      //     let blob:any = new Blob([response.blob], { type: response.file.mimeType });
      //     // saveAs(blob, file.name);

      //     const url = window.URL.createObjectURL(blob);
      //     var anchor = document.createElement('a');
      //     anchor.href = url;
      //     anchor.download = response.file.name;
      //     document.body.appendChild(anchor);
      //     anchor.click();
      //   }),
      //   map(() => false)
      // )
  }


}

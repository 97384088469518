import htmlToText from 'html-to-text';

const newLineTags = [
  // 'p',
  'h1',
  'h2',
  'h3',
  'h4',
  'li',
  'hr',
];

export const stripTags = (html: string): string => {
  let tmp = document.createElement("DIV");
  html = html ? html : "";
  html = html.replace(/<\/p>/g, ' </p>');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

export const stripTagsNewLine = (html: string): string => {
  let tmp = document.createElement("DIV");

  newLineTags.forEach(tag => {
    html = html.replace(new RegExp('<' + tag + '>', 'gi'), '');
    html = html.replace(new RegExp('<\/' + tag + '>', 'gi'), ' \n');
  });
  html = html.replace(new RegExp('<' + 'p' + '>', 'gi'), '');
  html = html.replace(new RegExp('<\/' + 'p' + '>', 'gi'), ' \n\n');
  // html = html.replace(/<\/p>/g, ' </p>');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

export const plainTextBr = (html:string, maxLength: number = 250): string => {
  var trimmedString = stripTagsNewLine(html).substr(0, maxLength);
  trimmedString = trimmedString.replace(/\n/g, "</br>");
  trimmedString = trimmedString.replace(/<\/br>\s\s*<\/br>/gm, "</br></br>");
  trimmedString = trimmedString.replace(new RegExp('<\/br><\/br><\/br><\/br>', 'gi'), "</br><\/br>");
  trimmedString = trimmedString.replace(new RegExp('<\/br><\/br><\/br>', 'gi'), "</br><\/br>");

  console.log(trimmedString);
  if(trimmedString.length <= maxLength) {
    return trimmedString;
  }
  let spaceIndex = trimmedString.lastIndexOf(" ");
  return trimmedString = spaceIndex > 0 ? trimmedString.substr(0, Math.min(trimmedString.length, spaceIndex)) : trimmedString;
}

export const plainText = (html:string, maxLength: number = 250): string => {
  var trimmedString = stripTags(html).substr(0, maxLength);
  if(trimmedString.length <= maxLength) {
    return trimmedString;
  }
  let spaceIndex = trimmedString.lastIndexOf(" ");
  return trimmedString = spaceIndex > 0 ? trimmedString.substr(0, Math.min(trimmedString.length, spaceIndex)) : trimmedString;
}

export const plainTextWithLink = (html:string, maxLength: number = 250): string => {
  let text = htmlToText.htmlToText(html, {
    limits: {
      maxInputLength: maxLength,
    },
    selectors: [
      { selector: 'a'}
    ]
  })
  const regex = /@(.*)\[(.*)\]/g;
  var match
  while(null != (match= regex.exec(text))) {
    text = text.replace(match[0],`<a href="${match[2]}">@${match[1]}</a>`);
  }
  return text;
}

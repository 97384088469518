import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {PrivacyPolicyComponent} from '@modules/privacy-policy/privacy-policy.component';
import { GroupsComponent } from '@pages/groups/groups.component';
import { AddGroupComponent } from '@pages/groups/add-group/add-group.component';
import { EditGroupComponent } from '@pages/groups/edit-group/edit-group.component';
import { ViewGroupComponent } from '@pages/groups/view-group/view-group.component';
import { NewsComponent } from '@pages/news/news.component';
import { ListNewsComponent } from '@pages/news/list-news/list-news.component';
import { AddNewsComponent } from '@pages/news/add-news/add-news.component';
import { ViewNewsComponent } from '@pages/news/view-news/view-news.component';
import { AdminAuthGuard } from '@guards/admin-auth.guard';
import { ListUsersComponent } from '@pages/users/list-users/list-users.component';
import { AddUserComponent } from '@pages/users/add-user/add-user.component';
import { EditUserComponent } from '@pages/users/edit-user/edit-user.component';
import { EditAccountComponent } from '@pages/account/edit-account/edit-account.component';
import { EditPasswordComponent } from '@pages/account/edit-password/edit-password.component';
import { EditUserPasswordComponent } from '@pages/users/edit-user-password/edit-user-password.component';
import { ViewConversationComponent } from '@pages/chat/view-conversation/view-conversation.component';
import { ListConversationComponent } from '@pages/chat/list-conversation/list-conversation.component';
import { SideChatComponent } from '@pages/chat/side-chat/side-chat.component';
import { RoomAddComponent } from '@pages/chat/room-add/room-add.component';
import { RoomEditComponent } from '@pages/chat/room-edit/room-edit.component';
import { ListNewsInGroupComponent } from '@pages/news/list-news/list-news-in-group.component';
import { ListNewsToAllComponent } from '@pages/news/list-news/list-news-to_all.component';
import { MessagesComponent } from '@pages/messages/messages.component';
import { InboxMessagesComponent } from '@pages/messages/inbox-messages/inbox-messages.component';
import { SentMessagesComponent } from '@pages/messages/sent-messages/sent-messages.component';
import { CreateMessagesComponent } from '@pages/messages/create-messages/create-messages.component';
import { ViewMessageComponent } from '@pages/messages/view-message/view-message.component';
import { StructureComponent } from '@pages/structure/structure.component';
import { AdminStructureComponent } from '@pages/structure/admin-structure/admin-structure.component';
import { AddBranchComponent } from '@pages/structure/admin-structure/add-branch/add-branch.component';
import { EditBranchComponent } from '@pages/structure/admin-structure/edit-branch/edit-branch.component';
import { ListStructureComponent } from '@pages/structure/list-structure/list-structure.component';
import { KbComponent } from '@pages/kb/kb.component';
import { FolderComponent } from '@pages/kb/folder/folder.component';
import { AdminFolderComponent } from '@pages/kb/folder/admin-folder/admin-folder.component';
import { AddFolderComponent } from '@pages/kb/folder/admin-folder/add-folder/add-folder.component';
import { EditFolderComponent } from '@pages/kb/folder/admin-folder/edit-folder/edit-folder.component';
import { FilesFolderComponent } from '@pages/kb/folder/admin-folder/files-folder/files-folder.component';
import { ArticlesFolderComponent } from '@pages/kb/folder/admin-folder/articles-folder/articles-folder.component';
import { AddArticleComponent } from '@pages/kb/folder/admin-folder/articles-folder/add-article/add-article.component';
import { EditArticleComponent } from '@pages/kb/folder/admin-folder/articles-folder/edit-article/edit-article.component';
import { ArticleComponent } from '@pages/kb/folder/article/article.component';
import { OffersComponent } from '@pages/offers/offers.component';
import { AddOfferComponent } from '@pages/offers/admin-offers/add-offer/add-offer.component';
import { ListOffersComponent } from '@pages/offers/list-offers/list-offers.component';
import { OfferFullComponent } from '@pages/offers/offer-full/offer-full.component';
import { OfferCandidatesComponent } from '@pages/offers/offer-candidates/offer-candidates.component';
import { EditOfferComponent } from '@pages/offers/admin-offers/edit-offer/edit-offer.component';
import { EditNewsComponent } from '@pages/news/edit-news/edit-news.component';
import { PrizesComponent } from '@pages/prizes/prizes.component';
import { ListPrizesComponent } from '@pages/prizes/list-prizes/list-prizes.component';
import { AddPrizeComponent } from '@pages/prizes/add-prize/add-prize.component';
import { EditPrizeComponent } from '@pages/prizes/edit-prize/edit-prize.component';
import { UserPrizesComponent } from '@pages/users/user-prizes/user-prizes.component';
import { OrdersComponent } from '@pages/orders/orders.component';
import { AdminOrdersComponent } from '@pages/orders/admin-orders/admin-orders.component';
import { AddOrderFormComponent } from '@pages/orders/admin-orders/add-order-form/add-order-form.component';
import { EditOrderFormComponent } from '@pages/orders/admin-orders/edit-order-form/edit-order-form.component';
import { ListOrdersComponent } from '@pages/orders/list-orders/list-orders.component';
import { MakeOrderComponent } from '@pages/orders/make-order/make-order.component';
import { ListNewsUnseenComponent } from '@pages/news/list-news/list-news-unseen.component';
import { IdeasComponent } from '@pages/ideas/ideas.component';
import { ListIdeasComponent } from '@pages/ideas/list-ideas/list-ideas.component';
import { NewIdeaComponent } from '@pages/ideas/new-idea/new-idea.component';
import { AdminIdeasComponent } from '@pages/ideas/admin-ideas/admin-ideas.component';
import { ReservationsComponent } from '@pages/reservations/reservations.component';
import { AdminReservationsComponent } from '@pages/reservations/admin-reservations/admin-reservations.component';
import { AddReservationCategoryComponent } from '@pages/reservations/admin-reservations/add-reservation-category/add-reservation-category.component';
import { EditReservationCategoryComponent } from '@pages/reservations/admin-reservations/edit-reservation-category/edit-reservation-category.component';
import { AssetsReservationCategoryComponent } from '@pages/reservations/admin-reservations/assets-reservation-category/assets-reservation-category.component';
import { AddReservationAssetComponent } from '@pages/reservations/admin-reservations/add-reservation-asset/add-reservation-asset.component';
import { EditReservationAssetComponent } from '@pages/reservations/admin-reservations/edit-reservation-asset/edit-reservation-asset.component';
import { ListReservationCategoriesComponent } from '@pages/reservations/list-reservation-categories/list-reservation-categories.component';
import { ViewReservationCategoriesComponent } from '@pages/reservations/view-reservation-categories/view-reservation-categories.component';
import { RolePermitionGuardGuard } from '@guards/role-permition-guard.guard';
import { PostResolverResolver } from './resolver/post-resolver.resolver';
import { GroupResolver } from './resolver/group.resolver';
import { ChatRoomResolver } from './resolver/chat-room.resolver';
import { UserResolver } from './resolver/user.resolver';
import { MyAccountResolver } from './resolver/my-account.resolver';
import { OfferResolver } from './resolver/offer.resolver';
import { OrderResolver } from './resolver/order.resolver';
import { ReservationCategoryResolver } from './resolver/reservation-category.resolver';
import { KbFolderResolver } from './resolver/kb-folder.resolver';
import { KbArticleResolver } from './resolver/kb-article.resolver';
import { DownloadComponent } from '@pages/kb/folder/download/download.component';
import { KbDownloadResolver } from './resolver/kb-download.resolver';
import { SearchListComponent } from '@pages/search/search-list/search-list.component';
import { JsonpClientBackend } from '@angular/common/http';
import { SearchFrazeResolver } from './resolver/search-fraze.resolver';
import { ListNewsAllComponent } from '@pages/news/list-news/list-news-all.component';
import { NotificationsComponent } from '@pages/notifications/notifications.component';
import { TrainingComponent } from '@pages/training/training.component';
import { ScormListComponent } from '@pages/training/admin/scorm/scorm-list/scorm-list.component';
import { ScormAddComponent } from '@pages/training/admin/scorm/scorm-add/scorm-add.component';
import { ScormPreviewComponent } from '@pages/training/admin/scorm/scorm-preview/scorm-preview.component';
import { ScormResolver } from './resolver/scorm.resolver';
import { ScormEditComponent } from '@pages/training/admin/scorm/scorm-edit/scorm-edit.component';
import { MultimediaListComponent } from '@pages/training/admin/multi/multimedia-list/multimedia-list.component';
import { MultimediaAddComponent } from '@pages/training/admin/multi/multimedia-add/multimedia-add.component';
import { MultimediaEditComponent } from '@pages/training/admin/multi/multimedia-edit/multimedia-edit.component';
import { MultimediaResolver } from './resolver/multimedia.resolver';
import { GroupListComponent } from '@pages/training/admin/group/group-list/group-list.component';
import { GroupAddComponent } from '@pages/training/admin/group/group-add/group-add.component';
import { GroupEditComponent } from '@pages/training/admin/group/group-edit/group-edit.component';
import { TrainingGroupResolver } from './resolver/training-group.resolver';
import { PackageListComponent } from '@pages/training/admin/package/package-list/package-list.component';
import { PackageAddComponent } from '@pages/training/admin/package/package-add/package-add.component';
import { TrainingPackageResolver } from './resolver/training-package.resolver';
import { PackageInvitationComponent } from '@pages/training/admin/package/package-invitation/package-invitation.component';
import { PackageInvitatedComponent } from '@pages/training/admin/package/package-invitated/package-invitated.component';
import { MyTrainingListComponent } from '@pages/training/my-training/my-training-list/my-training-list.component';
import { MyTrainingStartComponent } from '@pages/training/my-training/my-training-start/my-training-start.component';
import { TrainingInvitationResolver } from './resolver/training-invitation.resolver';
import { MyTrainingPlayerComponent } from '@pages/training/my-training/my-training-player/my-training-player.component';
import { TrainingResolver } from './resolver/training.resolver';
import { InitialTrainingListComponent } from '@pages/training/my-training/my-training-player/initial-training-list/initial-training-list.component';
import { ScormPlayerComponent } from '@pages/training/my-training/my-training-player/scorm-player/scorm-player.component';
import { PackageNotificationComponent } from '@pages/training/admin/package/package-notification/package-notification.component';
import { TrainingInvitationRedirectResolver } from './resolver/training-invitation-redirect.resolver';
import { PackageReportComponent } from '@pages/training/admin/package/package-report/package-report.component';
import { QuizListComponent } from '@pages/training/admin/quiz/quiz-list/quiz-list.component';
import { QuizAddComponent } from '@pages/training/admin/quiz/quiz-add/quiz-add.component';
import { QuizResolver } from './resolver/quiz.resolver';
import { QuizEditComponent } from '@pages/training/admin/quiz/quiz-edit/quiz-edit.component';
import { QuizPlayComponent } from '@pages/training/admin/quiz/quiz-play/quiz-play.component';
import { SurveyComponent } from '@pages/survey/survey.component';
import { SurveyAdminListComponent } from '@pages/survey/admin/survey-admin-list/survey-admin-list.component';
import { SurveyAdminAddComponent } from '@pages/survey/admin/survey-admin-add/survey-admin-add.component';
import { SurveyAdminEditComponent } from '@pages/survey/admin/survey-admin-edit/survey-admin-edit.component';
import { SurveyResolver } from './resolver/survey.resolver';
import { SurveyAdminQuestionsComponent } from '@pages/survey/admin/survey-admin-questions/survey-admin-questions.component';
import { SurveyAdminPublishComponent } from '@pages/survey/admin/survey-admin-publish/survey-admin-publish.component';
import { SurveyListComponent } from '@pages/survey/survey-list/survey-list.component';
import { MySurveyResolver } from './resolver/my-survey.resolver';
import { SurveySubmitComponent } from '@pages/survey/survey-submit/survey-submit.component';
import { SurveyAdminReportComponent } from '@pages/survey/admin/survey-admin-report/survey-admin-report.component';
import { MyTrainingCertificateComponent } from '@pages/training/my-training/my-training-certificate/my-training-certificate.component';
import { PackageArchiveComponent } from '@pages/training/admin/package/package-archive/package-archive.component';
import { MyTrainingOpenComponent } from '@pages/training/my-training/my-training-open/my-training-open.component';
import { MyTrainingOpenStartComponent } from '@pages/training/my-training/my-training-open-start/my-training-open-start.component';
import { PackageListOfPacksComponent } from '@pages/training/admin/package/package-list-of-packs/package-list-of-packs.component';
import { PackageParticipantsComponent } from '@pages/training/admin/package/package-participants/package-participants.component';
import { MultimediaPlayComponent } from '@pages/training/admin/multi/multimedia-play/multimedia-play.component';
import { ViewIdeaComponent } from '@pages/ideas/view-idea/view-idea.component';
import { IdeaResolver } from './resolver/idea.resolver';
import { PackageUserReportComponent } from '@pages/training/admin/package/package-user-report/package-user-report.component';
import { SurveyArchiveListComponent } from '@pages/survey/admin/survey-archive-list/survey-archive-list.component';
import { EditIdeaComponent } from '@pages/ideas/admin-ideas/edit-idea/edit-idea.component';
import { CanDeactivateGuard } from '@guards/can-deactivate.guard';
import { OutpostsComponent } from '@pages/outposts/outposts.component';
import { ListOutpostsComponent } from '@pages/outposts/list-outposts/list-outposts.component';
import { AddOutpostComponent } from '@pages/outposts/add-outpost/add-outpost.component';
import { EditOutpostComponent } from '@pages/outposts/edit-outpost/edit-outpost.component';
import { OutpostResolver } from './resolver/outpost.resolver';
import { SlotsComponent } from '@pages/outposts/slots/slots.component';
import { AddSlotComponent } from '@pages/outposts/slots/add-slot/add-slot.component';
import { SlotResolver } from './resolver/slot.resolver';
import { EditSlotComponent } from '@pages/outposts/slots/edit-slot/edit-slot.component';
import { UserSlotsComponent } from '@pages/users/user-slots/user-slots.component';
import { UserWorkMarketPerferencesComponent } from '@pages/users/user-work-market-perferences/user-work-market-perferences.component';
import { SlotNotificationUsersComponent } from '@pages/outposts/slots/slot-notification-users/slot-notification-users.component';
import { WorkmarketComponent } from '@pages/workmarket/workmarket.component';
import { AddVacantComponent } from '@pages/workmarket/add-vacant/add-vacant.component';
import { ListVacantComponent } from '@pages/workmarket/list-vacant/list-vacant.component';
import { InfoVacantComponent } from '@pages/workmarket/info-vacant/info-vacant.component';
import { AddMyVacantComponent } from '@pages/workmarket/add-my-vacant/add-my-vacant.component';
import { ListToConfirmComponent } from '@pages/workmarket/list-to-confirm/list-to-confirm.component';
import { NewsSeenReportComponent } from '@pages/news/news-seen-report/news-seen-report.component';
import { ListRolesComponent } from '@pages/roles/list-roles/list-roles.component';
import { EditRoleComponent } from '@pages/roles/edit-role/edit-role.component';
import { ViewOrdersComponent } from '@pages/orders/admin-orders/view-orders/view-orders.component';
import { ViewOrdersDetailComponent } from '@pages/orders/admin-orders/view-orders-detail/view-orders-detail.component';
import { ListOfferCategoryComponent } from '@pages/offers/category/list-offer-category/list-offer-category.component';
import { AddOfferCategoryComponent } from '@pages/offers/category/add-offer-category/add-offer-category.component';
import { OfferCategoryListResolver } from './resolver/offer-category-list.resolver';
import { EditOfferCategoryComponent } from '@pages/offers/category/edit-offer-category/edit-offer-category.component';
import { RedmineComponent } from '@pages/redmine/redmine.component';
import { RedmineSelectCategoryComponent } from '@pages/redmine/redmine-select-category/redmine-select-category.component';
import { ListRedmineCategoryComponent } from '@pages/redmine/admin/list-redmine-category/list-redmine-category.component';
import { EditRedmineCategoryComponent } from '@pages/redmine/admin/edit-redmine-category/edit-redmine-category.component';
import { RedmineCategoryResolver } from './resolver/redmine-category.resolver';
import { EditRedmineSubcategoryComponent } from '@pages/redmine/admin/edit-redmine-subcategory/edit-redmine-subcategory.component';
import { RedmineSubCategoryResolver } from './resolver/redmine-sub-category.resolver';
import { RedmineSubCategoryComponent } from '@pages/redmine/redmine-sub-category/redmine-sub-category.component';
import { RedmineCreateIssueComponent } from '@pages/redmine/redmine-create-issue/redmine-create-issue.component';
import { RedmineListIssuesComponent } from '@pages/redmine/redmine-list-issues/redmine-list-issues.component';
import { RedmineIssueDetailComponent } from '@pages/redmine/redmine-issue-detail/redmine-issue-detail.component';
import { RedmineIssueEditComponent } from '@pages/redmine/redmine-issue-edit/redmine-issue-edit.component';
import { CallCenterComponent } from '@pages/call-center/call-center/call-center.component';
import { ListQuestionnaireCallCenterComponent } from '@pages/call-center/admin/list-questionnaire-call-center/list-questionnaire-call-center.component';
import { AddQuestionnaireCallCenterComponent } from '@pages/call-center/admin/add-questionnaire-call-center/add-questionnaire-call-center.component';
import { CallCenterQuestionnaireResolver } from './resolver/call-center-questionnaire.resolver';
import { StatisticsComponent } from '@pages/statistics/statistics/statistics.component';
import { FillQuestionnaireCallCenterComponent } from '@pages/call-center/admin/fill-questionnaire-call-center/fill-questionnaire-call-center.component';
import { ListCallCenterFillComponent } from '@pages/call-center/list-call-center-fill/list-call-center-fill.component';
import { DetailCallCenterFillComponent } from '@pages/call-center/detail-call-center-fill/detail-call-center-fill.component';
import { CallCenterFillResolver } from './resolver/call-center-fill.resolver';
import { CallCenterRaportComponent } from '@pages/call-center/admin/call-center-raport/call-center-raport.component';
import { ListNewsFavoriteComponent } from '@pages/news/list-news/list-news-favorite.component';

const routes: Routes = [
    {
        path: '',
        data: {breadcrumb: 'Home', icon: 'fas fa-home'},
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: 'profile/:id', redirectTo: '/users/:id' },
            {
              path: 'account',
              resolve: {instance: MyAccountResolver},
              data: {breadcrumb: 'Mój profil', myaccount: true},
              children: [
                {
                  path: '',
                  component: ProfileComponent,
                  data: {breadcrumb: null},
                },
                {
                  path: 'edit',
                  component: EditAccountComponent,
                  data: {breadcrumb: 'Edycja konta'},
                },
                {
                  path: 'password',
                  component: EditPasswordComponent,
                  data: {breadcrumb: 'Edycja hasła'},
                },
                {
                  path: 'slots',
                  component: UserSlotsComponent,
                  canActivate: [RolePermitionGuardGuard],
                  data:{breadcrumb: 'Moje sloty pracy'}
                },

                {
                  path: 'workmarket',
                  component: UserWorkMarketPerferencesComponent,
                  canActivate: [RolePermitionGuardGuard],
                  data:{breadcrumb: 'Ustawienia giełdy pracy'}
                },
              ]
            },

            /***
             * POSTS
             */
            { path: 'news', component: NewsComponent,  outlet: 'sidebar', canActivate: [RolePermitionGuardGuard], data:{'permition': 'news.view'}},
            { path: 'news', data:{breadcrumb: 'Newsy'},  children:[
              { path: '', component: ListNewsAllComponent, canActivate: [RolePermitionGuardGuard], data:{'permition': 'news.view', breadcrumb: 'Wszystkie'} },
              { path: 'observed', component: ListNewsComponent, canActivate: [RolePermitionGuardGuard], data:{'permition': 'news.view', breadcrumb: 'Obserwowane'} },
              { path: 'to-all', component: ListNewsToAllComponent , canActivate: [RolePermitionGuardGuard], data:{'permition': 'news.view', breadcrumb: 'Do wszystkich'}},
              { path: 'favorite', component: ListNewsFavoriteComponent , canActivate: [RolePermitionGuardGuard], data:{'permition': 'news.view', breadcrumb: 'Ulubione'}},
              { path: 'unseen', component: ListNewsUnseenComponent, canActivate: [RolePermitionGuardGuard], data:{'permition': 'news.view', breadcrumb: 'Nieprzeczytane newsy'}},
              { path: 'add',
                component: AddNewsComponent,
                canActivate: [RolePermitionGuardGuard],
                canDeactivate: [CanDeactivateGuard],
                data:{
                  'permition': 'news.create',
                  breadcrumb: 'Nowy wpis'
                }
              },
              { path: ':id',
                data:{ breadcrumb: (data) => data.instance.title, permition: 'news.view'},
                resolve: { instance: PostResolverResolver},
                children:[
                { path: '',
                  component: ViewNewsComponent,
                  canActivate: [RolePermitionGuardGuard],
                  resolve: { instance: PostResolverResolver },
                  data:{permition: 'news.view', breadcrumb: ''}
                },
                {
                  path: 'edit', component: EditNewsComponent,
                  resolve: { instance: PostResolverResolver },
                  canActivate: [RolePermitionGuardGuard],
                  data:{permition: 'news.edit', breadcrumb: 'Edycja' }
                },
                {
                  path: 'seen-report', component: NewsSeenReportComponent,
                  resolve: { instance: PostResolverResolver },
                  canActivate: [RolePermitionGuardGuard],
                  data:{permition: 'news.edit', breadcrumb: 'Raport z wyświetleń' }
                },
              ]},
            ]},
            /***
             * GROUPS
            */
            { path: 'groups', data:{breadcrumb: 'Grupy'},  children:[
              { path: '', component: GroupsComponent, canActivate: [RolePermitionGuardGuard], data:{'permition': 'groups.list', breadcrumb: null}  },
              { path: 'add',
                component: AddGroupComponent,
                canActivate: [RolePermitionGuardGuard],
                data:{'permition': 'groups.create', breadcrumb: 'Tworzenie nowej grupy użytkowników'}
              },
              { path: ':id',
                resolve: { instance: GroupResolver },
                data:{'permition': 'groups.list', breadcrumb: (data) => data.instance.name},
                children:[
                  {
                    path: '',
                    component: ViewGroupComponent,
                    canActivate: [RolePermitionGuardGuard],
                    data:{'permition': 'groups.list', breadcrumb: null}
                  },
                  {
                    path: 'edit',
                    component: EditGroupComponent,
                    canActivate: [RolePermitionGuardGuard],
                    data:{'permition': 'groups.edit', breadcrumb: 'Edycja grupy użytkowników'}
                  },
                  {
                    path: 'news',
                    component: ListNewsInGroupComponent,
                    canActivate: [RolePermitionGuardGuard],
                    data:{'permition': 'news.view', breadcrumb: 'Posty w grupie'}
                  },
                ]
              },
            ]},

            /***
             * CHAT
             */
            { path: 'chat', component: SideChatComponent,  outlet: 'sidebar'},
            { path: 'chat',
              data: {breadcrumb: 'Chaty'},
              children:[
              {
                path: '',
                component: ListConversationComponent,
                data: {breadcrumb: null}
              },
              {
                path: ':id',
                resolve: { instance: ChatRoomResolver },
                data: {breadcrumb: (data)=> data.instance.type == 'room' ? `Pokój ${data.instance.name}` : `Czat z ${data.instance.name}`},
                children: [
                  {
                    path: '',
                    component: ViewConversationComponent,
                    data: {breadcrumb: null}
                  },
                  {
                    path: 'room/edit',
                    component: RoomEditComponent,
                    canActivate: [RolePermitionGuardGuard],
                    // resolve: { instance: ChatRoomResolver },
                    data:{'permition': 'chat.room.edit', breadcrumb: 'Edycja pokoju'}
                  },
                ]
              },
              {
                path: 'user/:user',
                component: ViewConversationComponent,
                data: {breadcrumb: 'Nowy czat bezpośredni'}
              },
              {
                path: 'room/add',
                component: RoomAddComponent,
                canActivate: [RolePermitionGuardGuard],
                data:{'permition': 'chat.room.create', breadcrumb: 'Nowy pokój'}
              },
            ]},
            /***
             * MESSSAGES
            */
            { path: 'messages',
              component: MessagesComponent ,
              data: {breadcrumb: 'Wiadomości'},
              children:[
                {
                  path: '',
                  component: InboxMessagesComponent,
                  data: {breadcrumb: 'Skrzynka odbiorcza'}
                },
                {
                  path: 'inbox',
                  component: InboxMessagesComponent,
                  data: {breadcrumb: 'Skrzynka odbiorcza'}
                },
                {
                  path: 'sent',
                  component: SentMessagesComponent,
                  data: {breadcrumb: 'Skrzynka nadawcza'}
                },
                {
                  path: 'create',
                  component: CreateMessagesComponent,
                  data: {breadcrumb: 'Nowa wiadomość'}
                },
                {
                  path: 'view/:id',
                  component: ViewMessageComponent,
                  data: {breadcrumb: 'Podgląd wiadomości'}
                },
            ]},


            /***
             * USERS
            */
            {
              path: 'users',
              data: {breadcrumb: 'Użytkownicy'},
              children: [
                {
                  path: '',
                  component: ListUsersComponent,
                  canActivate: [RolePermitionGuardGuard],
                  data: {permition: 'users.list', breadcrumb: null}
                },
                {
                  path: 'add',
                  component: AddUserComponent,
                  canActivate: [RolePermitionGuardGuard],
                  data:{permition: 'users.create', breadcrumb: 'Nowy Użytkownik'}
                },
                {
                  path: ':id',
                  resolve: { instance: UserResolver },
                  data:{breadcrumb: (data) => `Profil ${data.instance.name}`},
                  children: [
                    {
                      path: '',
                      component: ProfileComponent,
                      data:{breadcrumb: null}
                    },
                    {
                      path: 'edit',
                      component: EditUserComponent,
                      canActivate: [RolePermitionGuardGuard],
                      data:{permition: 'users.edit', breadcrumb: 'Edycja konta'}
                    },
                    {
                      path: 'password',
                      component: EditUserPasswordComponent,
                      canActivate: [RolePermitionGuardGuard],
                      data:{permition: 'users.password', breadcrumb: 'Zmiana hasła'}
                    },
                    {
                      path: 'slots',
                      component: UserSlotsComponent,
                      canActivate: [RolePermitionGuardGuard],
                      data:{permition: 'users.edit', breadcrumb: 'Sloty pracy'}
                    },
                    {
                      path: 'workmarket',
                      component: UserWorkMarketPerferencesComponent,
                      canActivate: [RolePermitionGuardGuard],
                      data:{permition: 'users.edit', breadcrumb: 'Ustawienia giełdy pracy'}
                    },
                    {
                      path: 'prizes',
                      component: UserPrizesComponent,
                      canActivate: [RolePermitionGuardGuard],
                      data:{permition: 'users.prize', breadcrumb: 'Nagrody'}
                    },
                  ]
                },
            ]},

            /***
             * STRUCTURE
            */
            {
              path: 'structure',
              component: StructureComponent,
              data:{breadcrumb: 'Struktura firmy'},
              children:[
                {
                  path: '',
                  component: ListStructureComponent,
                  canActivate: [RolePermitionGuardGuard],
                  data:{'permition': 'structure.list', breadcrumb: null}
                },
                {
                  path: 'admin',
                  canActivate: [RolePermitionGuardGuard],
                  data:{permition: ['structure.create', 'structure.edit', 'structure.delete'], breadcrumb: 'Zarządzanie strukturą'},
                  children:[
                    {
                      path: '',
                      component: AdminStructureComponent,
                      data:{breadcrumb: null}
                    },
                    {
                      path: 'add',
                      component: AddBranchComponent,
                      canActivate: [RolePermitionGuardGuard],
                      data:{'permition': 'structure.create', breadcrumb: 'Nowy dział główny'}
                    },
                    {
                      path: 'add/:parent',
                      component: AddBranchComponent,
                      canActivate: [RolePermitionGuardGuard],
                      data:{'permition': 'structure.create', breadcrumb: 'Nowy dział podrzędny'}
                    },
                    {
                      path: 'edit/:id',
                      component: EditBranchComponent,
                      canActivate: [RolePermitionGuardGuard],
                      data:{'permition': 'structure.edit', breadcrumb: 'Edycja działu'}
                    },
                  ]
                },
            ]},

            /***
             * OFERS
             */
            { path: 'offer',
              component: OffersComponent,
              data:{breadcrumb: 'Oferty Pracy'},
              resolve: {categories: OfferCategoryListResolver},
              children:[
                {
                  path: '',
                  component: ListOffersComponent,
                  canActivate: [RolePermitionGuardGuard],
                  // resolve: {categories: OfferCategoryListResolver},
                  data:{'permition': 'offers.list', breadcrumb: null}
                },
                {
                  path: ':id',
                  canActivate: [RolePermitionGuardGuard],
                  resolve: {instance: OfferResolver},
                  data:{permition: 'offers.list', breadcrumb: (data) => `${data.instance.title}`},
                  children: [
                    {
                      path: '',
                      component: OfferFullComponent,
                      data: {breadcrumb: null}
                    },
                    {
                      path: 'candidates',
                      component: OfferCandidatesComponent,
                      canActivate: [RolePermitionGuardGuard],
                      data:{'permition': 'offers.candidates', breadcrumb: "Lista kandydatów"}
                    },
                    {
                      path: 'edit',
                      component: EditOfferComponent,
                      canActivate: [RolePermitionGuardGuard],
                      data:{'permition': 'offers.create', breadcrumb: "Edycja oferty"}
                    },
                  ]
                },
                {
                  path: 'admin/add',
                  component: AddOfferComponent,
                  canActivate: [RolePermitionGuardGuard],
                  data:{'permition': 'offers.edit', breadcrumb: "Nowa oferta"}
                },
                {
                  path: 'admin/category',
                  canActivate: [RolePermitionGuardGuard],
                  data:{'permition': 'offers.category', breadcrumb: "Kategorie"},

                  // resolve: {categories: OfferCategoryListResolver},
                  children:[
                    {
                      path: '',
                      component: ListOfferCategoryComponent,
                      data:{breadcrumb: ""},
                    },
                    {
                      path: 'add',
                      component: AddOfferCategoryComponent,
                      data:{breadcrumb: "Nowa kategoria"},
                    },
                    {
                      path: ':id/edit',
                      component: EditOfferCategoryComponent,
                      // resolve: {categories: OfferCategoryListResolver},
                      data:{breadcrumb: "Edycja kategorii"},
                    }
                  ]
                },
            ]},

            /***
             * ORDERS
             */
            {
              path: 'orders',
              component: OrdersComponent,
              data:{breadcrumb: "Zamówienia"},
              children:[
                {
                  path: 'admin',
                  canActivate: [RolePermitionGuardGuard],
                  data:{'permition': ['orders.create', 'orders.edit', 'orders.delete'], breadcrumb: 'Zarządzanie'},
                  children: [
                    {
                      path: '',
                      component: AdminOrdersComponent,
                      data: {breadcrumb: null}
                    },
                    {
                      path: 'add',
                      component: AddOrderFormComponent,
                      canActivate: [RolePermitionGuardGuard],
                      canDeactivate: [CanDeactivateGuard],
                      data:{'permition': 'orders.create', breadcrumb: 'Nowy formularz'}
                    },
                    {
                      path: ':id',
                      canActivate: [RolePermitionGuardGuard],
                      resolve: {instance: OrderResolver},
                      data:{permition: 'orders.order', breadcrumb: (data) => `${data.instance.title}`},
                      children:[
                        {
                          path: '',
                          component: ViewOrdersComponent,
                          data: {breadcrumb: null}
                        },
                        {
                          path: 'details/:order',
                          component: ViewOrdersDetailComponent,
                          data: {breadcrumb: 'Szczegóły zamówienia'}
                        },
                        {
                          path: 'edit',
                          component: EditOrderFormComponent,
                          canActivate: [RolePermitionGuardGuard],
                          canDeactivate: [CanDeactivateGuard],
                          data:{'permition': 'orders.edit', breadcrumb: 'Edycja formularza'}
                        }
                      ]
                    }
                  ]
                },
                {
                  path: '',
                  component: ListOrdersComponent,
                  canActivate: [RolePermitionGuardGuard],
                  data:{'permition': 'orders.list', breadcrumb: null}
                },
                {
                  path: ':id',
                  component: MakeOrderComponent,
                  canActivate: [RolePermitionGuardGuard],
                  resolve: {instance: OrderResolver},
                  data:{permition: 'orders.order', breadcrumb: (data) => `${data.instance.title}`},
                },
            ]},

            /***
             * KNOWLAGE BASE
             */
            {
              path: 'kb/:folder',
              component: KbComponent,
              resolve: {instance: KbFolderResolver},
              data: {
                breadcrumb: (data) => `${data.instance.title}`,
                path: (data)=> data.instance.path.map(item => {
                  return {
                    label: item.title,
                    url: `/kb/${item.id}`
                  }
                })
              },
              children:[
                {
                  path: '',
                  component: FolderComponent,
                  canActivate: [RolePermitionGuardGuard],
                  data:{'permition': 'kb.list', breadcrumb: null, path: null}
                },
                {
                  path: 'article/:id',
                  component: ArticleComponent,
                  canActivate: [RolePermitionGuardGuard],
                  resolve: {article: KbArticleResolver},
                  data:{'permition': 'kb.list', breadcrumb: (data)=> data.article.title, path: null},
                },
                {
                  path: 'file/:id',
                  component: DownloadComponent,
                  // canActivate: [RolePermitionGuardGuard],
                  resolve: {file: KbDownloadResolver},
                  data:{'permition': 'kb.list', breadcrumb: (data)=> data.file.title, path: null},
                },
                {
                  path: 'admin',
                  canActivate: [RolePermitionGuardGuard],
                  data:{'permition': 'kb.admin', breadcrumb: 'Zarządzanie', path: null},
                  children: [
                    {
                      path: '',
                      component: AdminFolderComponent,
                      data:{breadcrumb: null, path: null},
                    },
                    {
                      path: 'add',
                      component: AddFolderComponent,
                      canActivate: [RolePermitionGuardGuard],
                      data: {'permition': 'kb.admin.folders.create', breadcrumb: 'Nowy folder', path: null}
                    },
                    {
                      path: 'edit/:id',
                      component: EditFolderComponent,
                      canActivate: [RolePermitionGuardGuard],
                      data:{'permition': 'kb.admin.folders.edit', breadcrumb: 'Edytuj folder', path: null}
                    },
                    {
                      path: 'files',
                      component: FilesFolderComponent,
                      canActivate: [RolePermitionGuardGuard],
                      data:{'permition': 'kb.admin.files', breadcrumb: 'Zarządzaj plikami', path: null}
                    },
                    {
                      path: 'articles',
                      canActivate: [RolePermitionGuardGuard],
                      data:{'permition': 'kb.admin.articles', breadcrumb: 'Zarządzaj artykułami', path: null},
                      children: [
                        {
                          path: '',
                          component: ArticlesFolderComponent,
                          data:{breadcrumb: null, path: null},
                        },
                        {
                          path: 'add',
                          component: AddArticleComponent,
                          canActivate: [RolePermitionGuardGuard],
                          data:{'permition': 'kb.admin.articles.create', breadcrumb: 'Nowy artykuł', path: null}
                        },
                        {
                          path: ':id',
                          canActivate: [RolePermitionGuardGuard],
                          resolve: {article: KbArticleResolver},
                          data:{'permition': 'kb.list', breadcrumb: (data)=> data.article.title, path: null},
                          children: [
                            {
                              path: '',
                              component: ArticleComponent,
                              data:{breadcrumb: null, path: null},
                            },
                            {
                              path: 'edit',
                              component: EditArticleComponent,
                              canActivate: [RolePermitionGuardGuard],
                              data:{'permition': 'kb.admin.articles.edit', breadcrumb: 'Edytyj Artykuł', path: null}
                            },
                          ]
                        },
                      ]
                    },
                  ]
                },
            ]},

            /***
             * IDEAS
             */
            {
              path: 'ideas',
              component: IdeasComponent,
              data: {breadcrumb: 'Zgłoszone Pomysły'},
              children:[
                {
                  path: '',
                  component: ListIdeasComponent,
                  canActivate: [RolePermitionGuardGuard],
                  data:{'permition': 'ideas.list', breadcrumb: null}
                },
                {
                  path: 'add',
                  component: NewIdeaComponent,
                  canActivate: [RolePermitionGuardGuard],
                  data:{'permition': 'ideas.create', breadcrumb: 'Zgłoś nowy pomysł'}
                },
                {
                  path: 'admin',
                  canActivate: [RolePermitionGuardGuard],
                  data:{'permition': 'ideas.edit', breadcrumb: 'Zarządzaj'},
                  children:[
                    {
                      path:'',
                      component: AdminIdeasComponent,
                    },
                    {
                      path:':id',
                      component: EditIdeaComponent,
                      resolve: {idea: IdeaResolver},
                      data:{'permition': 'ideas.edit', breadcrumb: 'Pomysł'},
                    }
                  ]
                },
                {
                  path: ':id',
                  component: ViewIdeaComponent,
                  canActivate: [RolePermitionGuardGuard],
                  resolve: {instance: IdeaResolver},
                  data:{'permition': 'ideas.list', breadcrumb: (data) => `${data.instance.title}`}
                },
            ]},

            /***
             * PRIZES
             */
            {
              path: 'prizes',
              component: PrizesComponent,
              data: {breadcrumb: 'Nagrody'},
              children:[
                {
                  path: '',
                  component: ListPrizesComponent,
                  canActivate: [RolePermitionGuardGuard],
                  data:{'permition': 'prizes.list', breadcrumb: null}
                },
                {
                  path: 'add',
                  component: AddPrizeComponent,
                  canActivate: [RolePermitionGuardGuard],
                  data:{'permition': 'prizes.create', breadcrumb: 'Nowa nagroda'}
                },
                {
                  path: 'edit/:id',
                  component: EditPrizeComponent,
                  canActivate: [RolePermitionGuardGuard],
                  data:{'permition': 'prizes.edit', breadcrumb: 'Edycja nagrody'}
                },
            ]},

            /***
             * RESERVATIONS
             */
            {
              path: 'reservations',
              component: ReservationsComponent,
              data:{breadcrumb: 'Rezerwacje zasobów'},
              children:[
                {
                  path: '',
                  component: ListReservationCategoriesComponent,
                  canActivate: [RolePermitionGuardGuard],
                  data:{'permition': 'reservations.list', breadcrumb: null}
                },
                {
                  path: 'admin',
                  canActivate: [RolePermitionGuardGuard],
                  data:{'permition': 'reservations.admin', breadcrumb: 'Zarządzanie'},
                  children: [
                    {
                      path: '',
                      component: AdminReservationsComponent,
                      data: {breadcrumb: null}
                    },
                    {
                      path: 'add',
                      component: AddReservationCategoryComponent,
                      canActivate: [RolePermitionGuardGuard],
                      data:{'permition': 'reservations.admin.category.create', breadcrumb: 'Nowa kategoria zasobów'}
                    },
                    {
                      path: 'edit/:id',
                      component: EditReservationCategoryComponent,
                      canActivate: [RolePermitionGuardGuard],
                      resolve: {instance: ReservationCategoryResolver},
                      data:{'permition': 'reservations.admin.category.edit', breadcrumb: 'Edycja kategorii zasobów'}
                    },
                    {
                      path: 'assets/:category',
                      canActivate: [RolePermitionGuardGuard],
                      resolve: {instance: ReservationCategoryResolver},
                      data:{'permition': 'reservations.admin.assets', breadcrumb: (data) => `${data.instance.title} :: Zasoby`},
                      children: [
                        {
                          path: '',
                          component: AssetsReservationCategoryComponent,
                          data:{breadcrumb: null},
                        },
                        {
                          path: 'add',
                          component: AddReservationAssetComponent,
                          canActivate: [RolePermitionGuardGuard],
                          data:{'permition': 'reservations.admin.assets.create', breadcrumb: 'Nowy zasób'}
                        },
                        {
                          path: 'edit/:id',
                          component: EditReservationAssetComponent,
                          canActivate: [RolePermitionGuardGuard],
                          data:{'permition': 'reservations.admin.assets.edit', breadcrumb: 'Edycja zasobu'}
                        },
                      ]
                    }
                  ]
                },
                {
                  path: ':id',
                  component: ViewReservationCategoriesComponent,
                  canActivate: [RolePermitionGuardGuard],
                  resolve: {instance: ReservationCategoryResolver},
                  data:{'permition': 'reservations.list', breadcrumb: (data) => data.instance.title}
                },
            ]},
            {
              path: 'search/:fraze',
              component: SearchListComponent,
              // canActivate: [RolePermitionGuardGuard],
              resolve: {title: SearchFrazeResolver},
              data:{breadcrumb: (data) => `Wyszukiwanie frazy "${data.title}"`}
            },
            /***
             * TRAINING
             */
            {
              path: 'training',
              component: TrainingComponent,
              canActivate: [RolePermitionGuardGuard],
              data:{breadcrumb: 'Szkolenia', 'permition': 'training.view'},
              children:[
                {
                  path: '',
                  component: MyTrainingListComponent,
                  canActivate: [RolePermitionGuardGuard],
                  data:{ breadcrumb: null, mode: 'all'}
                },
                {
                  path: 'open',
                  data:{breadcrumb: "Szkolenia ogólno dostępne"},
                  children:[
                    {
                      path: '',
                      data:{breadcrumb: null},
                      component: MyTrainingOpenComponent,
                    },
                    {
                      path: 'start/:id',
                      resolve: {instance: TrainingPackageResolver},
                      data:{breadcrumb: (data) => `${data.instance.name}`},
                      component: MyTrainingOpenStartComponent,
                    },
                  ]
                },
                {
                  path: 'redirect/:package',
                  resolve: {invitation: TrainingInvitationRedirectResolver},
                  data:{breadcrumb: null},
                  component: MyTrainingListComponent,
                },
                {
                  path: 'admin',
                  canActivate: [RolePermitionGuardGuard],
                  data:{'permition': 'training.admin', breadcrumb: 'Zarządzanie szkoleniami'},
                  children: [
                    {
                      path: 'scorm',
                      canActivate: [RolePermitionGuardGuard],
                      data:{'permition': 'training.admin.scorm', breadcrumb: 'SCORM'},
                      children: [
                        {
                          path: '',
                          component: ScormListComponent,
                          data:{'permition': 'training.admin.scorm', breadcrumb: ''},
                        },
                        {
                          path: 'add',
                          component: ScormAddComponent,
                          canActivate: [RolePermitionGuardGuard],
                          data:{'permition': 'training.admin.scorm', breadcrumb: 'Dodaj szkolenie SCORM'},
                        },
                        {
                          path: ':id',
                          canActivate: [RolePermitionGuardGuard],
                          resolve: {instance: ScormResolver},
                          data:{'permition': 'training.admin.scorm', breadcrumb: (data) => `${data.instance.name}`},
                          children: [
                            {
                              path: 'preview',
                              component: ScormPreviewComponent,
                              data:{breadcrumb: ''},
                            },
                            {
                              path: 'edit',
                              component: ScormEditComponent,
                              data:{breadcrumb: 'Edycja'},
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'multimedia',
                      canActivate: [RolePermitionGuardGuard],
                      data:{'permition': 'training.admin.scorm', breadcrumb: 'Multimedialne'},
                      children: [
                        {
                          path: '',
                          component: MultimediaListComponent,
                          data:{'permition': 'training.admin.scorm', breadcrumb: null},
                        },
                        {
                          path: 'add',
                          component: MultimediaAddComponent,
                          canActivate: [RolePermitionGuardGuard],
                          data:{'permition': 'training.admin.scorm', breadcrumb: 'Dodaj szkolenie multimedialne'},
                        },
                        {
                          path: ':id/play',
                          component: MultimediaPlayComponent,
                          resolve: {instance: MultimediaResolver},
                          canActivate: [RolePermitionGuardGuard],
                          data:{'permition': 'training.admin.scorm', breadcrumb: 'Podgląd'},
                        },
                        {
                          path: ':id/edit',
                          canActivate: [RolePermitionGuardGuard],
                          resolve: {instance: MultimediaResolver},
                          component: MultimediaEditComponent,
                          data:{'permition': 'training.admin.scorm', breadcrumb: 'Edycja'},
                        }
                      ]
                    },
                    {
                      path: 'quiz',
                      canActivate: [RolePermitionGuardGuard],
                      data:{'permition': 'training.admin.scorm', breadcrumb: 'Quizy'},
                      children: [
                        {
                          path: '',
                          component: QuizListComponent,
                          data:{'permition': 'training.admin.scorm', breadcrumb: ''},
                        },
                        {
                          path: 'add',
                          component: QuizAddComponent,
                          data:{'permition': 'training.admin.scorm', breadcrumb: 'Nowy Quiz'},
                        },
                        {
                          path: ':id',
                          resolve: {instance: QuizResolver},
                          data:{'permition': 'training.admin.scorm', breadcrumb: 'Nowy Quiz'},
                          children: [
                            {
                              path: 'edit',
                              component: QuizEditComponent,
                              data:{'permition': 'training.admin.scorm', breadcrumb: 'Edycja Quizu'},
                            },
                            {
                              path: 'play',
                              component: QuizPlayComponent,
                              data:{'permition': 'training.admin.scorm', breadcrumb: 'Podgląd Quizu'},
                            },
                            {path: '**', redirectTo: 'edit'}
                          ]
                        },
                      ]
                    },
                    {
                      path: 'group',
                      canActivate: [RolePermitionGuardGuard],
                      data:{'permition': 'training.admin.scorm', breadcrumb: 'Grupy szkoleniowe'},
                      children: [
                        {
                          path: '',
                          component: GroupListComponent,
                          data:{'permition': 'training.admin.scorm', breadcrumb: ''},
                        },
                        {
                          path: 'add',
                          component: GroupAddComponent,
                          canActivate: [RolePermitionGuardGuard],
                          data:{'permition': 'training.admin.scorm', breadcrumb: 'Dodaj grupę szkoleniową'},
                        },
                        {
                          path: ':id/edit',
                          canActivate: [RolePermitionGuardGuard],
                          resolve: {instance: TrainingGroupResolver},
                          component: GroupEditComponent,
                          data:{'permition': 'training.admin.scorm', breadcrumb: 'Edycja'},
                        }
                      ]
                    },
                    {
                      path: 'package',
                      canActivate: [RolePermitionGuardGuard],
                      data:{'permition': 'training.admin.scorm', breadcrumb: null},
                      children: [
                        {
                          path: '',
                          data:{'permition': 'training.admin.scorm', breadcrumb: null},
                          component: PackageListComponent
                        },
                        {
                          path: 'add',
                          component: PackageAddComponent,
                          data:{'permition': 'training.admin.scorm', breadcrumb: 'Nowe szkolenie'},
                        },
                        {
                          path: 'archive',
                          component: PackageArchiveComponent,
                          data:{'permition': 'training.admin.scorm', breadcrumb: 'Archiwym szkoleń'},
                        },
                        {
                          path: 'report',
                          component: PackageUserReportComponent,
                          data:{'permition': 'training.admin.scorm', breadcrumb: 'Raport z uczestników'},
                        },
                        {
                          path: ':id',
                          resolve: {instance: TrainingPackageResolver},
                          data:{'permition': 'training.admin.scorm', breadcrumb: (data) => `${data.instance.name}`},
                          children: [
                            {
                              path: 'edit',
                              component: PackageAddComponent,
                              data:{'permition': 'training.admin.scorm', breadcrumb: 'Edycja'},
                            },
                            {
                              path: 'to-invitate',
                              component: PackageInvitationComponent,
                              data:{'permition': 'training.admin.scorm', breadcrumb: 'Dodaj zaproszenia'},
                            },
                            {
                              path: 'invitated',
                              component: PackageInvitatedComponent,
                              data:{'permition': 'training.admin.scorm', breadcrumb: 'Wyślij zaproszenia'},
                            },
                            {
                              path: 'participants',
                              component: PackageParticipantsComponent,
                              data:{'permition': 'training.admin.scorm', breadcrumb: 'Uczestnicy'},
                            },
                            {
                              path: 'packs',
                              data:{'permition': 'training.admin.scorm', breadcrumb: 'Wysłane zaproszenia'},
                              children:[
                                {
                                  path: '',
                                  component: PackageListOfPacksComponent,
                                  data:{'permition': 'training.admin.scorm', breadcrumb: ''},
                                },
                                {
                                  path: 'notification/:pack',
                                  component: PackageNotificationComponent,
                                  data:{'permition': 'training.admin.scorm', breadcrumb: 'Powiadomienia'},
                                },
                              ]
                            },
                            {
                              path: 'report',
                              component: PackageReportComponent,
                              data:{'permition': 'training.admin.scorm', breadcrumb: 'Raporty'},
                            },
                            {path: '**', redirectTo: 'edit'}
                          ]
                        },
                      ]
                    },
                    {path: '**', redirectTo: 'package'}
                  ]
                },
                {
                  path: ':id',
                  resolve: {instance: TrainingInvitationResolver},
                  data:{breadcrumb: (data) => `${data.instance.package.name}`},
                  children: [
                    {
                      path: '',
                      component: MyTrainingStartComponent,
                      resolve: {invitation: TrainingInvitationResolver},
                      data:{breadcrumb: ''},
                    },
                    {
                      path: 'certificate',
                      component: MyTrainingCertificateComponent,
                      resolve: {invitation: TrainingInvitationResolver},
                      data:{breadcrumb: 'Certyfikat ukończenia'},
                    },
                    {
                      path: 'training/:training',
                      component: MyTrainingPlayerComponent,
                      resolve: {
                        training: TrainingResolver,
                        invitation: TrainingInvitationResolver
                      },
                      data:{breadcrumb: (data)=>`${data.training.name}`},
                    },
                    {
                      path: 'scorm/:training',
                      component: MyTrainingPlayerComponent,
                      resolve: {
                        training: TrainingResolver,
                        invitation: TrainingInvitationResolver
                      },
                      data:{breadcrumb: (data)=>`${data.training.name}`},
                    },
                    {
                      path: 'quiz/:training',
                      component: MyTrainingPlayerComponent,
                      resolve: {
                        training: TrainingResolver,
                        invitation: TrainingInvitationResolver
                      },
                      data:{breadcrumb: (data)=>`${data.training.name}`},
                    },
                    {
                      path: 'multimedia/:training/:slide',
                      component: MyTrainingPlayerComponent,
                      resolve: {
                        training: TrainingResolver,
                        invitation: TrainingInvitationResolver
                      },
                      data:{breadcrumb: (data)=>`${data.training.name}`},
                    }
                  ]
                },
            ]},
            {
              path: 'survey',
              component: SurveyComponent,
              canActivate: [RolePermitionGuardGuard],
              data:{breadcrumb: 'Ankiety', 'permition': 'survey.view'},
              children: [
                {
                  path: 'admin',
                  data:{'permition': 'survey.admin', breadcrumb: 'Zarządzanie ankietami'},
                  children:[
                    {
                      path: '',
                      data:{breadcrumb: null},
                      component: SurveyAdminListComponent
                    },
                    {
                      path: 'archive',
                      data:{breadcrumb: 'Archiwum ankiet'},
                      component: SurveyArchiveListComponent
                    },
                    {
                      path: 'add',
                      data:{breadcrumb: 'Nowa ankieta'},
                      component: SurveyAdminAddComponent
                    },
                    {
                      path: ':id',
                      resolve: {
                        instance: SurveyResolver
                      },
                      data:{breadcrumb: (data) => `${data.instance.name}`},
                      children: [
                        {
                          path: 'edit',
                          data:{breadcrumb: 'Edycja ankiety'},
                          component: SurveyAdminEditComponent
                        },
                        {
                          path: 'questions',
                          data:{breadcrumb: 'Pytania w ankiecie'},
                          component: SurveyAdminQuestionsComponent
                        },
                        {
                          path: 'publish',
                          data:{breadcrumb: 'Grupa docelowa i publikacja'},
                          component: SurveyAdminPublishComponent
                        },
                        {
                          path: 'report',
                          data:{breadcrumb: 'Raport z wyników'},
                          component: SurveyAdminReportComponent
                        },
                        {path: '**', redirectTo: 'edit'}
                      ]
                    },
                  ]
                },
                {
                  path: '',
                  data:{breadcrumb: null},
                  component: SurveyListComponent
                },
                {
                  path: ':id',
                  resolve: {
                    instance: MySurveyResolver
                  },
                  data:{breadcrumb: (data) => `Ankieta: ${data.instance.name}`},
                  component: SurveySubmitComponent
                },
              ]
            },
            {
              path: 'outposts',
              component: OutpostsComponent,
              data:{breadcrumb: 'Placówki', permition:"outpost.view"},
              canActivate: [RolePermitionGuardGuard],
              children:[
                {
                  path: '',
                  data:{breadcrumb: null},
                  component: ListOutpostsComponent,
                },
                {
                  path: 'add',
                  data:{breadcrumb: 'Nowa placówka'},
                  component: AddOutpostComponent,
                },
                {
                  path: ':id',
                  resolve: {
                    instance: OutpostResolver
                  },
                  data:{breadcrumb: (data) => `${data.instance.name}`},
                  children:[
                    {
                      path: '',
                      data: {breadcrumb:'Edycja'},
                      component: EditOutpostComponent
                    },
                    {
                      path: 'slots',
                      data: {breadcrumb:'Sloty'},
                      children:[
                        {
                          path:'',
                          data: {breadcrumb:null},
                          component: SlotsComponent
                        },
                        {
                          path:'add',
                          data: {breadcrumb:'Nowy slot'},
                          component: AddSlotComponent
                        },
                        {
                          path:':slot/edit',
                          resolve: {
                            slot: SlotResolver
                          },
                          data: {
                            breadcrumb:'Edycja slotu'
                          },
                          component: EditSlotComponent
                        },
                        {
                          path:':slot/notification-users',
                          resolve: {
                            slot: SlotResolver
                          },
                          data: {
                            breadcrumb:'Użytkownicy do powiadomienia'
                          },
                          component: SlotNotificationUsersComponent
                        }
                      ]
                    },
                    {path: '**', redirectTo: ''}
                  ]
                },
                {path: '**', redirectTo: ''}
              ]
            },
            {
              path: 'workmarket',
              component: WorkmarketComponent,
              data:{breadcrumb: 'Giełda pracy', permition:"vacant.view"},
              canActivate: [RolePermitionGuardGuard],
              children:[
                {
                  path: '',
                  data:{breadcrumb: 'Preferowane', type: 'prefered'},
                  component: ListVacantComponent
                },
                {
                  path: 'empty',
                  data:{breadcrumb: 'Wszystkie wolne', type: 'empty'},
                  component: ListVacantComponent
                },
                {
                  path: 'reserved',
                  data:{breadcrumb: 'Moje zarezerwowane', type: 'reserved'},
                  component: ListVacantComponent
                },
                {
                  path: 'created',
                  data:{breadcrumb: 'Które utworzyłem', type: 'created'},
                  component: ListVacantComponent
                },
                {
                  path: 'all-reserved',
                  data:{breadcrumb: 'Wszystkie zarezerwowane', type: 'all-reserved'},
                  component: ListVacantComponent
                },
                {
                  path: 'to-confirm',
                  data:{breadcrumb: 'Ogłoszenia do potwierdzenia'},
                  component: ListToConfirmComponent
                },
                {
                  path: 'slot/:slot',
                  resolve: {
                    slot: SlotResolver
                  },
                  data:{
                    breadcrumb: 'Ogłoszenia ze slotu',
                    type: 'slot'
                  },
                  component: ListVacantComponent
                },
                {
                  path: 'add-vacant',
                  data:{breadcrumb: 'Nowe ogłoszenie', permition:"vacant.create"},
                  canActivate: [RolePermitionGuardGuard],
                  component: AddVacantComponent
                },
                {
                  path: 'add-my-vacant',
                  data:{breadcrumb: 'Nowe ogłoszenie', permition:"vacant.createMy"},
                  canActivate: [RolePermitionGuardGuard],
                  component: AddMyVacantComponent
                },
                {
                  path: ':id',
                  data:{breadcrumb: 'Szczegóły ogłoszenia'},
                  component: InfoVacantComponent
                },
              ]
            },
            /**
             * REDMINE
             */
             {
              path: 'redmine',
              data:{breadcrumb: 'Zgłaszanie problemów', permition:"redmine.view"},
              canActivate: [RolePermitionGuardGuard],
              component: RedmineComponent,
              children: [
                {
                  path: '',
                  data:{breadcrumb: 'Przypisane do mnie zgłoszenia', type: 'assigned'},
                  component: RedmineListIssuesComponent
                },
                {
                  path: 'add',
                  data:{breadcrumb: 'Utwórz nowe zgłoszenie'},
                  children: [
                    {
                      path: '',
                      data:{breadcrumb: ''},
                      component: RedmineSelectCategoryComponent
                    },
                    {
                      path: ':category/:id',
                      resolve: {
                        category: RedmineCategoryResolver,
                        subcategory: RedmineSubCategoryResolver,
                      },
                      data:{breadcrumb: (data) => `${data.subcategory.title} / ${data.category.title}`},
                      children: [
                        {
                          path:'',
                          data: {breadcrumb: ''},
                          component: RedmineSubCategoryComponent
                        },
                        {
                          path:'create-issue',
                          data: {breadcrumb: ''},
                          component: RedmineCreateIssueComponent
                        }
                      ]
                    },
                  ]
                },
                {
                  path:'issue/:id',
                  data: {breadcrumb: 'Szczegóły zgłoszenia'},
                  children: [
                    {
                      path: '',
                      data: {breadcrumb: ''},
                      component: RedmineIssueDetailComponent
                    },
                    {
                      path: 'edit',
                      data: {breadcrumb: 'Edycja'},
                      component: RedmineIssueEditComponent
                    }
                  ]
                },
                {
                  path:'issues/created',
                  data: {breadcrumb: 'Moje zgłoszenia', type: 'created'},
                  component: RedmineListIssuesComponent
                },
                {
                  path:'issues/watched',
                  data: {breadcrumb: 'Obserwowane', type: 'watched'},
                  component: RedmineListIssuesComponent
                },
                {
                  path:'issues/assigned',
                  data: {breadcrumb: 'Przypisane do mnie zgłoszenia', type: 'assigned'},
                  component: RedmineListIssuesComponent
                },
                {
                  path: 'admin',
                  data:{breadcrumb: 'Zarządzanie kategoriami', permition:"roles.admin"},
                  canActivate: [RolePermitionGuardGuard],
                  children: [
                    {
                      path: '',
                      data: {breadcrumb: ''},
                      component: ListRedmineCategoryComponent
                    },
                    {
                      path: 'add',
                      data: {breadcrumb: 'Nowa kategoria'},
                      component: EditRedmineCategoryComponent
                    },
                    {
                      path: ':category',
                      data: {breadcrumb: 'Edycja kategorii'},
                      resolve: {
                        instance: RedmineCategoryResolver
                      },
                      children: [
                        {
                          path: '',
                          data: {breadcrumb: ''},
                          component: EditRedmineCategoryComponent
                        },
                        {
                          path: 'add',
                          resolve: {
                            category: RedmineCategoryResolver,
                          },
                          data: {breadcrumb: 'Nowa podkategoria'},
                          component: EditRedmineSubcategoryComponent
                        },
                        {
                          path: 'edit/:id',
                          resolve: {
                            category: RedmineCategoryResolver,
                          },
                          data: {breadcrumb: 'Edycja podkategorii'},
                          component: EditRedmineSubcategoryComponent
                        },
                      ]
                    }
                  ]
                },
              ]
            },
            /**
             * CALL CENTER
             */
            {
              path: 'call-center',
              data:{breadcrumb: 'Kwestionariusze oceny rozmów', permition:['callcenter.view', 'callcenter.fill', 'callcenter.create', 'callcenter.edit', 'callcenter.delete']},
              component: CallCenterComponent,
              canActivate: [RolePermitionGuardGuard],
              children: [
                {
                  path: 'admin',
                  data:{breadcrumb: 'Lista kwestionaruszy', permition:['callcenter.fill', 'callcenter.create', 'callcenter.edit', 'callcenter.delete']},
                  children: [
                    {
                      path: 'add',
                      data: {breadcrumb: 'Nowy kwestionaruszy', permition:['callcenter.create']},
                      component: AddQuestionnaireCallCenterComponent
                    },
                    {
                      path: ':id',
                      resolve: {
                        instance: CallCenterQuestionnaireResolver
                      },
                      data: {breadcrumb: data => `${data.instance?.name}`, permition:['callcenter.edit', 'callcenter.fill']},
                      children: [
                        {
                          path: 'create',
                          resolve: {
                            instance: CallCenterQuestionnaireResolver,
                            questionnaire: CallCenterQuestionnaireResolver
                          },
                          data: {breadcrumb: '', permition:['callcenter.edit']},
                          component: FillQuestionnaireCallCenterComponent
                        },
                        {
                          path: 'edit',
                          resolve: {
                            instance: CallCenterQuestionnaireResolver
                          },
                          data: {breadcrumb: 'Edycja', permition:['callcenter.edit']},
                          component: AddQuestionnaireCallCenterComponent
                        },
                        {
                          path: 'raport',
                          resolve: {
                            instance: CallCenterQuestionnaireResolver
                          },
                          data: {breadcrumb: 'Rapport', permition:['callcenter.fill']},
                          component: CallCenterRaportComponent
                        },
                      ]
                    },
                    {
                      path: '',
                      data: {breadcrumb: null},
                      component: ListQuestionnaireCallCenterComponent
                    }
                  ]
                },
                {
                  path: 'created',
                  data: {breadcrumb: null, fillType: 'created'},
                  component: ListCallCenterFillComponent
                },
                {
                  path: 'all',
                  data: {breadcrumb: null, fillType: 'all'},
                  component: ListCallCenterFillComponent
                },
                {
                  path: 'detail/:id',
                  resolve: {
                    instance: CallCenterFillResolver
                  },
                  data: {breadcrumb: data => `${data.instance?.name}`, permition:['callcenter.view']},
                  component: DetailCallCenterFillComponent
                },
                {
                  path: 'edit/:id',
                  resolve: {
                    instance: CallCenterFillResolver,
                    fill: CallCenterFillResolver,
                  },
                  data: {breadcrumb: 'Edycja', permition:['callcenter.fillEdit']},
                  component: FillQuestionnaireCallCenterComponent
                },
                {
                  path: '',
                  data: {breadcrumb: null, fillType: 'my'},
                  component: ListCallCenterFillComponent
                }
              ]
            },
            /**
             * STATISTICS
             */
            {
              path: 'statistics',
              data:{breadcrumb: 'Statystyki', permition:"statistics.view"},
              canActivate: [RolePermitionGuardGuard],
              component: StatisticsComponent
            },
            /**
             * ROLES
             */
            {
              path: 'roles',
              data:{breadcrumb: 'Zarzadzanie rolami', permition:"roles.admin"},
              canActivate: [RolePermitionGuardGuard],
              children: [
                {
                  path: '',
                  data:{breadcrumb: ''},
                  component: ListRolesComponent
                },
                {
                  path: ':id',
                  data:{breadcrumb: 'Szczegóły ogłoszenia', permition:"roles.admin"},
                  canActivate: [RolePermitionGuardGuard],
                  component: EditRoleComponent
                },
              ]
            },
            { path: 'notifications', component: NotificationsComponent, children: [], data:{breadcrumb: 'Powiadomienia'}},
            { path: '', component: DashboardComponent, children: [], data:{breadcrumb: null}},
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'recover-password/:email/:code',
        component: RecoverPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        canActivate: [NonAuthGuard]
    },
    {
      path: 'public/offer/:id/refferer/:refferer',
      component: OfferFullComponent,
      data: { public: true }
      // canActivate: [NonAuthGuard]s
    },
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {}


import { Outpost } from '@/model/outpost.model';
import { PaginationDTO } from '@/model/pagination.model';
import { Slot } from '@/model/slot.model';
import { Vacant, VacantPack } from '@/model/vacant.model';
import { StringifyHttpErrorResponse } from '@/utils/http.error.util';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import * as fromActions from './vacant.toconfirm.actions';
import * as fromReducers from './vacant.toconfirm.reducers';

@Injectable()
export class VacantToConfirmEffects {
  state: fromReducers.State;

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private store$: Store<fromReducers.State>,
  ) {
    this.store$.pipe(select(fromReducers.selectState)).subscribe((state: fromReducers.State) => {
      this.state = state;
    })
  }

  onError (err, caught): any {
    this.toastr.error(StringifyHttpErrorResponse(err));
    return caught;
  };

  paginatorChange$ = createEffect(() => this.actions$.pipe(
    ofType(...[
      fromActions.setPage,
      fromActions.setSearch,
      fromActions.setOrder,
      fromActions.setDirection
    ]),
    map( () => fromActions.fetch())
  ));

  fetch$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetch),
    switchMap(() => {
      let params = new HttpParams();
      params = params.set('skip', this.state.limit * (this.state.page - 1));
      params = params.set('limit', this.state.limit);
      if(this.state.order)
        params = params.set('order', this.state.order);
      params = params.set('direction', this.state.asc ? 'asc' : 'desc');
      if(this.state.search)
        params = params.set('search', this.state.search);

      return this.httpClient.get<PaginationDTO<VacantPack>>(`<backendhost>/v1/vacant/0/to-confirm`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: PaginationDTO<VacantPack>) => [fromActions.setMany(response)])
  ));

  confirm$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.confirm),
    switchMap((action) => {
      return this.httpClient.post<any>(`<backendhost>/v1/vacant/${action.vacantPack.id}/pack-confirm`, {}, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map(()=>action.vacantPack))
    }),
    catchError(this.onError.bind(this)),
    tap(() => this.toastr.success('Potwierdzono ogłoszenie!')),
    switchMap((vacantPack) => [
      fromActions.removeOne({id: vacantPack.id})
    ])
  ));

  reject$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.reject),
    switchMap((action) => {
      return this.httpClient.post<any>(`<backendhost>/v1/vacant/${action.vacantPack.id}/pack-reject`, {}, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map(()=>action.vacantPack))
    }),
    catchError(this.onError.bind(this)),
    tap(() => this.toastr.success('Odrzucono ogłoszenie!')),
    switchMap((vacantPack) => [
      fromActions.removeOne({id: vacantPack.id})
    ])
  ));

}

<div class="content-header pt-0">
    <div class="row">
      <div class="col-12 text-right">
        <div ngbDropdown class="d-inline-block">
          <button
            class="btn btn-outline-primary"
            [checkRolePermition]="['callcenter.create', 'callcenter.edit', 'callcenter.delete']"
            [routerLink]="['admin']">
                <i class="fas fa-list fa-fw"></i> Lista kwestionaruszy
          </button>
        </div>
      </div>
    </div>
  </div>

  <section class="content">
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </section>

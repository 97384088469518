import { AuthService } from '@/auth/auth.service';
import { User } from '@/model/user.model';
import { Injectable } from '@angular/core';
import * as fromUserActions from '@/store/users/users.actions';
import * as fromUserReducers from '@/store/users/users.reducers';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MyAccountResolver  implements Resolve<User> {
  constructor(
    private store: Store<fromUserReducers.State>,
    private authService: AuthService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
    return from( new Promise<User>(resolve => {
      this.authService.getUser().then( (user:User) => {
        this.store.pipe(
          select(fromUserReducers.selectOneUser, user.id),
          tap( (result) => {
            if(!result) {
              this.store.dispatch(fromUserActions.fetchOneUser({id: user.id}));
            }
          }),
          filter((result) => result != null),
          take(1),
        ).subscribe(resolve);
      })}));
  }
}


import { SearchResult } from "@/model/search.result.model";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import * as fromActions from './search-tag.actions'

export interface State {
  loading: boolean;
  open: boolean;
  term: string;
  total: number;
  results: EntityState<SearchResult>,
}

export const adapter: EntityAdapter<SearchResult> = createEntityAdapter<SearchResult>({
  sortComparer: (a: SearchResult, b: SearchResult) => {
    return a.score == b.score ? (a.id < b.id ? 1 : -1) : (a.score < b.score ? 1 :-1);
  }
});


export const initialState: State = {
  loading: false,
  open: false,
  term: null,
  total: 0,
  results: adapter.getInitialState()
}

export const reducer = createReducer(
  initialState,
  on(fromActions.clear, (state, action) => {
    return {...initialState}
  }),
  on(fromActions.fetch, (state, action) => {
    return {
      ...state,
      ...{
        loading: true,
      }
  }}),
  on(fromActions.setTerm, (state, action) => {
    return {
      ...state,
      ...{
        term: action.term,
        open: true
      }
  }}),
  on(fromActions.addResults, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: action.total,
        results: adapter.addMany(action.results, state.results)
      }
  }}),
);

export const selectSearchState = createFeatureSelector<State>('search-tag');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectSearchResults = createSelector(selectSearchState, (state) => selectAll(state.results) )
export const selectIsLoading = createSelector(selectSearchState, (state) => state.loading )
export const selectHasMore = createSelector(selectSearchState, (state) => state.total && state.total > state.results.ids.length )
export const selectCount = createSelector(selectSearchState, (state) => state.total )
export const selectIsOpen = createSelector(selectSearchState, (state) => state.term ? state.open && state.term.length > 0 : false  )

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { selectReservationState, State } from "./reservations.reducers";
import * as fromActions from './reservations.actions';
import { catchError, map, switchMap } from "rxjs/operators";
import { Idea } from "@/model/idea.model";
import { plainTextWithLink } from "@/utils/plainText";
import { ReservationAsset, ReservationCategory } from "@/model/reservation.model";

@Injectable()
export class ReservationEffects {
  private state: State;
  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private store$: Store<State>,
  ) {
    this.store$.pipe(select(selectReservationState)).subscribe((state: State) => {
      this.state = state;
    })
  }
  onError (err, caught): any {
    this.toastr.error(err.message)
    return caught;
  };

  createReservationCategory$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createReservationCategory),
    switchMap((action) => {
      let reservationCategory = {...action.reservationCategory, ...{short: plainTextWithLink(action.reservationCategory.description)}}
      const formData = new FormData();
      if(action.image) {
        formData.append('image', action.image, action.image.name);
      }
      formData.append('data', JSON.stringify(reservationCategory));
      return this.httpClient.post<ReservationCategory>('<backendhost>/v1/reservation-category', formData, {observe: 'body',responseType: 'json'});
    }),
    catchError(this.onError.bind(this)),
    switchMap((reservationCategory) => [fromActions.addAdminReservationCategory({reservationCategory})])
  ));


  saveReservationCategory$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.saveReservationCategory),
    switchMap((action) => {
      let reservationCategory = {...action.reservationCategory, ...{short: plainTextWithLink(action.reservationCategory.description)}}
      const formData = new FormData();
      if(action.image) {
        formData.append('image', action.image, action.image.name);
      }
      formData.append('data', JSON.stringify(reservationCategory));
      return this.httpClient.post<ReservationCategory>(`<backendhost>/v1/reservation-category/${reservationCategory.id}/update`,
          formData,
          {observe: 'body',responseType: 'json'});
    }),
    catchError(this.onError.bind(this)),
    switchMap((reservationCategory) => [fromActions.updateAdminReservationCategory({id: reservationCategory.id, changes: reservationCategory})])
  ));


  deleteReservationCategory$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deleteReservationCategory),
    switchMap((action) => {
      return this.httpClient.delete<any>(`<backendhost>/v1/reservation-category/${action.id}`).pipe(map(() => action.id))
    }),
    catchError(this.onError.bind(this)),
    switchMap((id: number) => [fromActions.removeAdminReservationCategory({id})])
  ));


  fetchOneReservationCategory$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchOneReservationCategory),
    switchMap((action) => {
      return this.httpClient.get<ReservationCategory>(`<backendhost>/v1/reservation-category/${action.id}`, {observe: 'body',responseType: 'json'});
    }),
    catchError(this.onError.bind(this)),
    switchMap((reservationCategory) => [fromActions.addAdminReservationCategory({reservationCategory})])
  ));

  paginatorFetchChange$ = createEffect(() => this.actions$.pipe(
    ofType(...[
      fromActions.setAdminDirection,
      fromActions.setAdminOrder,
      fromActions.setAdminPage,
      fromActions.setAdminSearch
    ]),
    switchMap( () => [fromActions.fetchAdminReservationCategory()])
  ));

  fetchAdminReservationCategory$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchAdminReservationCategory),
    switchMap( () => {
      let params = new HttpParams();
      params = params.set('skip', this.state.admin.limit * (this.state.admin.page - 1));
      params = params.set('limit', this.state.admin.limit);
      params = params.set('order', this.state.admin.order);
      params = params.set('direction', this.state.admin.asc ? 'asc' : 'desc');
      if(this.state.admin.search)
        params = params.set('search', this.state.admin.search);
      return this.httpClient.get<fromActions.ReservationCategoryPagination>(`<backendhost>/v1/reservation-category`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: fromActions.ReservationCategoryPagination) => [fromActions.replaceAdminReservationCategory(response)])
  ));

  createAdminReservationAsset$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createAdminReservationAsset),
    switchMap((action) => {
      let reservationAsset = {...action.reservationAsset, ...{short: plainTextWithLink(action.reservationAsset.description)}}
      const formData = new FormData();
      if(action.image) {
        formData.append('image', action.image, action.image.name);
      }
      formData.append('data', JSON.stringify(reservationAsset));
      return this.httpClient.post<ReservationAsset>('<backendhost>/v1/reservation-asset', formData, {observe: 'body',responseType: 'json'});
    }),
    catchError(this.onError.bind(this)),
    switchMap((reservationAsset) => [fromActions.addAdminReservationAsset({reservationAsset})])
  ));

  saveAdminReservationAsset$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.saveAdminReservationAsset),
    switchMap((action) => {
      let reservationAsset = {...action.reservationAsset, ...{short: plainTextWithLink(action.reservationAsset.description)}}
      const formData = new FormData();
      if(action.image) {
        formData.append('image', action.image, action.image.name);
      }
      formData.append('data', JSON.stringify(reservationAsset));
      return this.httpClient.post<ReservationAsset>(`<backendhost>/v1/reservation-asset/${reservationAsset.id}/update`, formData, {observe: 'body',responseType: 'json'});
    }),
    catchError(this.onError.bind(this)),
    switchMap((reservationAsset) => [fromActions.updateAdminReservationAsset({reservationAsset})])
  ));

  deleteAdminReservationAsset$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deleteAdminReservationAsset),
    switchMap((action) => {
      let reservationAsset = {...action.reservationAsset}
      return this.httpClient.delete<any>(`<backendhost>/v1/reservation-asset/${reservationAsset.id}`, {observe: 'body',responseType: 'json'})
        .pipe(map( () => reservationAsset));
    }),
    catchError(this.onError.bind(this)),
    switchMap((reservationAsset) => [fromActions.removeAdminReservationAsset({reservationAsset})])
  ));


  // fetchPublicIdeas$ = createEffect(() => this.actions$.pipe(
  //   ofType(fromActions.fetchPublicIdeas),
  //   switchMap( (action) => {
  //     let params = new HttpParams();
  //     params = params.set('skip', this.state.public.list.ids.length);
  //     if(this.state.admin.search)
  //       params = params.set('search', this.state.admin.search);
  //     return this.httpClient.get<fromActions.IdeaPagination>(`<backendhost>/v1/ideas`, {
  //       params: params,
  //       observe: 'body',
  //       responseType: 'json'
  //     });
  //   }),
  //   catchError(this.onError.bind(this)),
  //   switchMap((response: fromActions.IdeaPagination) => [fromActions.addPublicIdeas(response)])
  // ));

  // saveIdea$ = createEffect(() => this.actions$.pipe(
  //   ofType(fromActions.saveIdea),
  //   switchMap( (action) => {
  //     return this.httpClient.put<Idea>(`<backendhost>/v1/ideas/${action.id}`, action.idea, {
  //       observe: 'body',
  //       responseType: 'json'
  //     }).pipe(map(() => {
  //       return {
  //         id: action.id,
  //         changes: action.idea,
  //       }
  //     }));
  //   }),
  //   catchError(this.onError.bind(this)),
  //   switchMap((response: {id: number, changes: Idea}) => [
  //     fromActions.updateAdminIdea(response),
  //     fromActions.updateIdea(response),
  //   ])
  // ));
}

<section class="content">
  <div class="container">
    <form [formGroup]="form" (ngSubmit)="save()">
      <div class="card card-primary">
        <div class="card-body">
          <div class="form-group">
            <label for="inputName">Nazwa SCORM</label>
            <input
              formControlName="name"
              type="text"
              class="form-control"
              placeholder="Nazwa SCORM"
            />
          </div>
          <div class="form-group">

            <div class="form-check">
              <label>
                <input class="form-check-input" type="checkbox" formControlName="pointable"/>
                Szkolenie wymaga zaliczenia
              </label>
            </div>
          </div>

          <div class="form-group">
            <label for="inputName">Próg zaliczenia szkolenia</label>

            <div class="form-check">
              <input class="form-check-input" type="radio" formControlName="defaultMinScore" [value]="true">
              <label class="form-check-label" for="flexRadioDefault1">
                Według punkacji ustalonej w szablonie SCORM
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" formControlName="defaultMinScore" [value]="false">
              <label class="form-check-label" for="flexRadioDefault2">
                Ręcznie ustaw próg
              </label>
            </div>

            <nz-slider
              formControlName="minScore"
              *ngIf="form.value.defaultMinScore == false"
              [nzMin]="0"
              [nzMax]="100"
              nzTooltipVisible="always"
              [nzTipFormatter]="percentFormatter"></nz-slider>
              <!-- <pre>{{form.value | json}}</pre> -->
          </div>
        </div>
        <div class="card-footer">
          <button class="btn btn-success float-right" type="submit">
            Zapisz
            <i class="fas fa-angle-right fa-fw"></i>
          </button>
        </div>
      </div>
    </form>
  </div>
</section>





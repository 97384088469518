import { Post } from "@/model/post.model";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { environment } from "environments/environment";
import { ToastrService } from "ngx-toastr";
import { switchMap, map, catchError } from "rxjs/operators";

import * as fromMessageActions from '@/store/messages/messages.actions';
import { InnerMessage } from "@/model/message.mode";
import { PaginationDTO } from "@/model/pagination.model";
import { select, Store } from "@ngrx/store";
import { DefaultEffects } from "../default/default.effects";
import { ErrorState } from "../error.store";
import { State, selectOfferState } from "./offers.reducers";
import * as fromActions from "./offer.actions";
import { Offer } from "@/model/offer.model";
import { plainText, plainTextBr } from "@/utils/plainText";
import * as fromNotificationActions from '@/store/notification/notification.actions';
import * as fromNotificationReducers from '@/store/notification/notification.reducers';
import { AppNotification } from "@/model/notification.model";
import { Socket } from "ngx-socket-io";

@Injectable()
export class OffersEffects extends DefaultEffects {
  constructor(
    private actions$: Actions,
    httpClient: HttpClient,
    toastr: ToastrService,
    errorStore: Store<ErrorState>,
    private store$: Store<State>,
    private notificationStore: Store<fromNotificationReducers.State>,
    private socket: Socket,
  ) {
    super(httpClient, toastr, errorStore);
    this.store$.pipe(select(selectOfferState)).subscribe((state: State) => {
      this.state = state;
    })
  }
  private state: State;
  private pageSize: number = 12;//environment.pageSize;


  fetch$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetch),
    switchMap((action) => {
      let params = new HttpParams();
      params = params.set('skip', action.skip);
      params = params.set('size', this.pageSize);
      return this.httpClient.get<PaginationDTO<InnerMessage>>('<backendhost>/v1/offers', {
        params: params,
        observe: 'body',
        responseType: 'json'
      })
    }),
    catchError(this.onError.bind(this)),
    switchMap((result: any) => [fromActions.addMany(result)])
  ));

  fetchOne$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchOne),
    switchMap((action) => {
      return this.httpClient.get<Offer>(`<backendhost>/v1/offers/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      })
    }),
    catchError(this.onError.bind(this)),
    switchMap((offer: any) => [fromActions.addOne({offer})])
  ));

  createOffer$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createOffer),
    switchMap((action) => {
      const formData = new FormData();
      let offer = {...action.offer, ...{short: plainTextBr(action.offer.content, 1024)}};
      if(action.background) {
        formData.append('background', action.background, action.background.name);
      }
      formData.append('data', JSON.stringify(offer));
      return this.httpClient.post<Offer>('<backendhost>/v1/offers', formData, { observe: 'body', responseType: 'json'});
    }),
    catchError(this.onError.bind(this)),
    map(offer => { return {...offer, ... {seen: true, seenCount: 1}}}),
    switchMap((offer: Offer) => {
      return [
        fromActions.setAsSeen({offer}),
        fromActions.addOne({offer})
      ];
    })
  ));

  saveOffer$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.saveOne),
    switchMap((action) => {
      const formData = new FormData();
      let offer = {...action.offer, ...{short: plainTextBr(action.offer.content, 1024)}};
      if(action.background) {
        formData.append('background', action.background, action.background.name);
      }
      formData.append('data', JSON.stringify(offer));
      return this.httpClient.post<Offer>(`<backendhost>/v1/offers/${offer.id}/update`, formData, { observe: 'body', responseType: 'json'});
    }),
    catchError(this.onError.bind(this)),
    switchMap((offer: Offer) => [
      fromActions.updateOne({offer})
    ])
  ));

  setAsSeen$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.setAsSeen),
    map( (action) => {
      console.log('setAsSeen', action);
      let url = `<backendhost>/v1/offers/${action.offer.id}/seen` ;
      let offer = action.offer;
      this.socket.emit('post seen', offer.id);
      this.notificationStore.dispatch( fromNotificationActions.remove({notification: {id: `${offer.id}`} as AppNotification}));
      let count = offer?.seenCount ? offer.seenCount : 0;
      this.store$.dispatch(fromActions.updateOne({offer : {id: offer.id, seen: true, seenCount: count++} as Offer}));
      return offer;
      // return this.httpClient.post<void>(url,{}, {observe: 'body', responseType: 'json'}).pipe(map( () => action.offer));
    }),
    switchMap( (offer) => {
      return [
        fromNotificationActions.remove({notification: {id: `${offer.id}`} as AppNotification}),
      ];
    }),
    // catchError(this.onError.bind(this)),
    // switchMap((offer: Offer) => {
    //   let count = offer?.seenCount ? offer.seenCount : 0;
    //   return [
    //     fromNotificationActions.remove({notification: {id: offer.id} as AppNotification}),
    //     fromActions.updateOne({offer : {id: offer.id, seen: true, seenCount: count++} as Offer})
    //   ]
    // })
  ));

  deleteOne$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deleteOne),
    switchMap( (action) => {
      let url = `<backendhost>/v1/offers/${action.offer.id}` ;
      return this.httpClient.delete<any>(url, {observe: 'body', responseType: 'json'}).pipe(map( () => action.offer));
    }),
    catchError(this.onError.bind(this)),
    switchMap((offer: Offer) => [
      fromActions.removeOne({offer})
    ])
  ));

  like$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.likeOfferAction),
      switchMap((action) => {
        let url = `<backendhost>/v1/post/${action.offer.id}/like` ;
        return this.httpClient.post<Offer>(url, {}, {observe: 'body', responseType: 'json'});
      }),
      catchError(this.onError.bind(this)),
      switchMap((response: any) => {
        return [fromActions.updateOne({offer: {id: response.id, liked: true, likeCount: response.likeCount, likeSaving: false} as Offer})];
      })
    );
  });

  unlike$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.unlikeOfferAction),
      switchMap((action) => {
        let url = `<backendhost>/v1/post/${action.offer.id}/like` ;
        return this.httpClient.delete<Offer>(url, {observe: 'body', responseType: 'json'});
      }),
      catchError(this.onError.bind(this)),
      switchMap((response: Offer) => {
        return [fromActions.updateOne({offer: {id: response.id, liked: false, likeCount: response.likeCount, likeSaving: false} as Offer})];
      })
    );
  });

}

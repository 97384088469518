<div class="card-header bg-secondary">
  <h3 class="card-title">
    <span class="badge text-md rounded-pill badge-primary">
      <i class="fas fa-users fa-fw"></i> {{total}}
    </span>
    Osoby które będą zaproszone
  </h3>
</div>

<div class="table-responsive position-relative">
  <table class="table align-middle table-hover">
    <thead>
      <tr>
        <th style="width:1rem">#</th>
        <th></th>
        <th>Imię</th>
        <th>Nazwisko</th>
        <th>Email</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of list; let idx = index; trackBy: identify">
        <th class="align-middle">{{idx + 1 + (page-1) * pageSize}}</th>
        <td class="user-panel" style="width:70px">
          <img [src]="item  | userAvatar: 34" class="img-circle elevation-2" style="width:45px"  [appUserDisactivatable]="item"/>
        </td>
        <td class="align-middle">
          <span [appUserDisactivatable]="item">{{item.firstname}}</span>
        </td>
        <td class="align-middle">
          <span [appUserDisactivatable]="item">{{item.lastname}}</span>
        </td>
        <td class="align-middle">
          <span [appUserDisactivatable]="item">{{item.email}}</span>
        </td>
      </tr>
    </tbody>
  </table>
  <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="!total"></nz-empty>
  <div class="overlay" style="position: absolute; top:0; left:0; bottom:0; right:0" *ngIf="loading">
    <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
      <i class="fas fa-3x fa-spinner fa-spin"></i>
      <p>Ładowanie...</p>
    </div>
  </div>
</div>

<div class="card-footer">
  <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="total"  (pageChange)="pageChange($event)">
    <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
    <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
    <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
    <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
    <ng-template ngbPaginationEllipsis>...</ng-template>
    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
  </ngb-pagination>
</div>

import { Prize } from "@/model/prize.model";
import { User } from "@/model/user.model";
import { createAction, props } from "@ngrx/store";
import { PrizePagination } from "./prize.reducers";

export const reset = createAction(
  "reset prizes"
);
export const clearSaving = createAction(
  "clear saving prizes"
);

export const fetchPrizes = createAction(
  "fetch prizes",
  props<{limit?: number | null}>()
);

export const fetchOnePrize = createAction(
  "fetch one prize",
  props<{id: number}>()
);

export const create = createAction(
  "create prize",
  props<{prize: Prize, image: any}>()
);

export const add = createAction(
  "add prize",
  props<{prize: Prize}>()
);

export const edit = createAction(
  "edit prize",
  props<{id: number, prize: Prize, image: any}>()
);

export const update = createAction(
  "update prize",
  props<{prize: Prize}>()
);

export const deletePrize = createAction(
  "delete prize",
  props<{prize: Prize}>()
);

export const remove = createAction(
  "remove prize",
  props<{prize: Prize}>()
);

export const setPage = createAction(
  "set page prize",
  props<{ page: number }>()
);

export const setOrder = createAction(
  "set order prize",
  props<{ order: string }>()
);

export const setDirection = createAction(
  "set direction prize",
  props<{ direction: string }>()
);

export const setSearch = createAction(
  "set search prize",
  props<{ search: string }>()
);

export const addPrizes = createAction(
  "addPrizes",
  props<PrizePagination>()
);

export const replacePrizes = createAction(
  "replacePrizes",
  props<PrizePagination>()
);

export const setUser = createAction(
  "setUser prizes",
  props<{user: User}>()
);


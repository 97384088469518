import { Component, OnInit } from '@angular/core';
import * as fromGroupReducer from '@/store/group/group.reducers';
import * as fromGroupActions from '@/store/group/group.actions';
import { select, Store } from '@ngrx/store';
import { Group } from '@/model/group.model';
import { AppService } from '@services/app.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  groups: Group[];

  constructor(
    private groupStore: Store<fromGroupReducer.State>,
    private appService: AppService,
    private httpClient: HttpClient
  ) { }

  ngOnInit(): void {
    this.groupStore.dispatch(fromGroupActions.fetchAllAction());
    this.groupStore.pipe(select(fromGroupReducer.selectObservedGroups)).subscribe( (groups: Group[]) => {
      this.groups = groups;
      groups.forEach(group => {
        if(group.lazy) {

          this.groupStore.dispatch(fromGroupActions.unlazyOneAction({id:group.id}))
          // this.groupStore.dispatch(fromGroupActions.fetchOneAction({id:group.id}))
          this.httpClient.get<Group>(`<backendhost>/v1/group/${group.id}`).subscribe(group => {
            this.groupStore.dispatch(fromGroupActions.updateGroupAction({group}))
          });
        }
      });
    });
    this.appService.sideOutletSubject.next(true);
  }

  ngOnDestroy(): void {
    this.appService.sideOutletSubject.next(false);
  }

}

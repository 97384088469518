import {Directive, ViewContainerRef, AfterViewInit, EventEmitter, Output} from '@angular/core';

@Directive({selector: '[inView]'})
export class InViewDirective implements AfterViewInit {
  @Output() inView: EventEmitter<void> = new EventEmitter();
  alreadyRendered: boolean; // cheking if visible already

  constructor(
    private vcRef: ViewContainerRef
  ) {}

  ngAfterViewInit() {
    const commentEl = this.vcRef.element.nativeElement; // template
    const elToObserve = commentEl.parentElement;
    this.setMinWidthHeight(elToObserve);

    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          this.renderContents(entry.isIntersecting)
        });
      }, {threshold: [.7, .8, .9, 1]});
    observer.observe(elToObserve);
  }

  renderContents(isInView) {
    if (isInView && !this.alreadyRendered) {
      this.inView.emit();
      this.alreadyRendered = true;
    }
  }

  setMinWidthHeight(el: any) { // prevent issue being visible all together
    const style = window.getComputedStyle(el);
    const [width, height] = [parseInt(style.width), parseInt(style.height)];
    !width && (el.style.minWidth = '40px');
    !height && (el.style.minHeight = '40px');
  }
}

<form [formGroup]="form" (ngSubmit)="save()" *ngIf="show">
  <div class="card card-primary">
    <div class="overlay" *ngIf="saving | async">
      <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%,0);text-align: center; padding: 25px 0">
        <i class="fas fa-3x fa-spinner fa-spin"></i>
        <p>Zapisywanie...</p>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-8">

          <div class="form-group">
            <label for="title">Nazwa</label>
            <input formControlName="title" [appIsValid]="form.controls.title" type="text" class="form-control" placeholder="Nazwa"/>
            <div class="invalid-feedback" *ngIf="form.controls.title.errors?.required">
              Pole jest wymagane
            </div>
          </div>
          <div class="form-group">
            <label for="inputName">Opis folderu</label>
            <!-- <editor [init]="tinyMceConfig.init" apiKey="{{tinyMceConfig.apiKey}}"  formControlName="description"></editor> -->
            <app-editor formControlName="description"></app-editor>

          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="inputName">Avatar folderu</label>
            <div style="max-width: 250px;">
              <app-upload-crop-image  *ngIf="folder"
                [avatar]="true"
                [aspectRatio]="1 / 1"
                [image]="folder | groupAvatar:250:false"
                url="<backendhost>/v1/kb/folder/{{folder.id}}/image"
                (nzChange)="handleImageUploadChange($event)"
              ></app-upload-crop-image>
              <!-- <nz-upload *ngIf="folder"
                class="avatar-uploader w-100"
                nzAction="<backendhost>/v1/kb/folder/{{folder.id}}/image"
                nzName="image"
                nzListType="picture-card"
                [nzShowUploadList]="false"
                (nzChange)="handleImageUploadChange($event)"
              >
                <ng-container *ngIf="!folder.image" class="w-100">
                  <i class="fas fa-3x" [ngClass]="{
                    'fa-upload' : !uploading,
                    'fa-spinner fa-spin' : uploading
                  }"></i>
                  <div class="ant-upload-text">Upload</div>
                </ng-container>
                <img *ngIf="folder.image" [src]="folder | groupAvatar:250:false" style="width: 100%" />
              </nz-upload> -->
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label for="inputName">Obrazek tła</label>
            <app-upload-crop-image
                *ngIf="folder"
                [aspectRatio]="4 / 1"
                [image]="folder?.background ? (folder.background | image) : null"
                url="<backendhost>/v1/kb/folder/{{folder.id}}/background"
                (nzChange)="handleBackgroundUploadChange($event)"
              ></app-upload-crop-image>
            <!-- <div style="max-width: 100%;"><nz-upload *ngIf="folder"
                class="bg-uploader w-100"
                nzAction="<backendhost>/v1/kb/folder/{{folder.id}}/background"
                nzName="image"
                nzListType="picture-card"
                [nzShowUploadList]="false"
                (nzChange)="handleBackgroundUploadChange($event)"
              >
                <ng-container *ngIf="!folder.background" class="w-100">
                  <i class="fas fa-3x" [ngClass]="{
                    'fa-upload' : !uploading,
                    'fa-spinner fa-spin' : uploading
                  }"></i>
                  <div class="ant-upload-text">Upload</div>
                </ng-container>
                <img *ngIf="folder.background" [src]="folder.background | image" style="width: 100%" />
              </nz-upload>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col-6">
          <a class="btn btn-default" [routerLink]="['../..']">
            <i class="fas fa-angle-left fa-fw"></i>
            Wróć
          </a>
        </div>
        <div class="col-6 text-right">
          <button class="btn btn-success" type="submit" [disabled]="saving | async">
            Zapisz
            <i class="fas fa-angle-right fa-fw"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  </form>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import * as fromKbActions from '@/store/kb/kb.actions';
import * as fromKbReducers from '@/store/kb/kb.reducers';
import { Folder } from '@/model/folder.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Actions, ofType } from '@ngrx/effects';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { getBase64 } from '@/utils/base64';
import { tinyMceConfig } from '@/config/tinymce.config';

@Component({
  selector: 'app-add-folder',
  templateUrl: './add-folder.component.html',
  styleUrls: ['./add-folder.component.scss']
})
export class AddFolderComponent implements OnInit, OnDestroy  {
  unsubscribe$ = new Subject<boolean>();
  show = false;
  form: FormGroup;
  parent: Folder;
  folder: Folder;
  uploading = false;
  imageFile: NzUploadFile;
  backgroundFile: NzUploadFile;
  tinyMceConfig = tinyMceConfig;

  constructor(
    private store: Store<fromKbReducers.State>,
    private router: Router,
    private toastr: ToastrService,
    protected actions$: Actions,
  ) {
    this.actions$.pipe(
      ofType(fromKbActions.saveSuccess),
      takeUntil(this.unsubscribe$)
    ).subscribe((action) => {
      // this.router.navigate(["/kb", action.parent, 'admin' , 'edit', action.id]);
      this.router.navigate(["/kb", action.parent, 'admin']);
      this.toastr.success("Zapisano poprawnie");
    });
  }

  ngOnInit(): void {
    this.initForm();
    this.store.pipe(takeUntil(this.unsubscribe$), select(fromKbReducers.selectFolder)).subscribe((folder:Folder) => {
      if (!folder)return;
      this.show = true;
      this.parent = folder;
      this.form.patchValue({parent: folder.id});
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  get saving(): Observable<boolean>  {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromKbReducers.selectSaving));
  }

  beforeUploadBackground = (file: NzUploadFile): boolean => {
    getBase64(file as any).then( (preview: string) => {
      this.backgroundFile  = file;
      this.folder = {...this.folder, ...{background: preview}};
    })
    return false;
  };

  beforeUploadImage = (file: NzUploadFile): boolean => {
    getBase64(file as any).then( (preview: string) => {
      this.imageFile  = file;
      this.folder = {...this.folder, ...{image: preview}};
    })
    return false;
  };

  save() {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });

    if (this.form.valid) {
      this.store.dispatch(fromKbActions.createFolder({
        folder: this.form.value as Folder,
        parent: this.parent,
        image: this.imageFile,
        background: this.backgroundFile,
      }));
    } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }

  protected initForm(): void {
    this.form = new FormGroup({
      title: new FormControl(null, Validators.required),
      parent: new FormControl(null, Validators.required),
      description: new FormControl(null),
    });
  }
}

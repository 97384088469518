import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-user-disactivatable',
  templateUrl: './user-disactivatable.component.html',
  styleUrls: ['./user-disactivatable.component.scss']
})
export class UserDisactivatableComponent implements OnInit {
  @Input()
  templateRef: TemplateRef<any>;

  content: string;
  tag: string = 'span';

  constructor() { }

  ngOnInit(): void {
  }

}

import { Folder } from '@/model/folder.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Subject } from 'rxjs';
import * as fromKbActions from '@/store/kb/kb.actions';
import * as fromKbReducers from '@/store/kb/kb.reducers';
import { select, Store } from '@ngrx/store';
import { map, take, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { KbFile } from '@/model/file.model';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'app-files-folder',
  templateUrl: './files-folder.component.html',
  styleUrls: ['./files-folder.component.scss']
})
export class FilesFolderComponent implements OnInit, OnDestroy  {
  unsubscribe$ = new Subject<boolean>();
  filesFetched = false;

  constructor(
    private store: Store<fromKbReducers.State>,
    private router: Router,
    private toastr: ToastrService,
    protected actions$: Actions,
  ) {
    this.actions$.pipe(
      ofType(fromKbActions.saveSuccess),
      takeUntil(this.unsubscribe$)
    ).subscribe((action) => {
      this.toastr.success("Zapisano zmiany");
    });
  }
  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  get folder(): Observable<Folder>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromKbReducers.selectFolder),
      // tap( (folder: Folder) => {
      //   if (folder && !this.filesFetched) {
      //     this.filesFetched = true;
      //     this.store.dispatch(fromKbActions.fetchFiles({id: folder.id}))
      //   }
      //   //
      // }),
      // takeWhile(folder => folder == null),
    );
  }

  get files(): Observable<KbFile[]>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromKbReducers.selectFiles),
      map(arr => arr.map( f => Object.assign({},f)))
    );
  }

  identify(file: KbFile, index: number) {
    return file.id;
  }

  isImage(file: KbFile) {
    return file.mimeType.indexOf('image/') === 0;
  }

  updateFile(file: KbFile) {
    this.store.dispatch(fromKbActions.updateFile({file}));
  }

  delete(file: KbFile) {
    this.store.dispatch(fromKbActions.deleteFile({file}));
  }

  handleUploadChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      // case 'uploading':
      //   this.uploading = true;
      //   break;
      case 'done':
        this.store.dispatch(fromKbActions.addFile({file: info.file.response}));
        break;
      case 'error':
        this.toastr.error('Błąd uploadu pliku');
        break;
    }
  }

}

<div class="content-header pt-0">
  <!-- <div class="row mb-2">
    <div class="col-sm-6">
        <h1 class="m-0 text-dark">Zamówienia</h1>
    </div>
    <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
            <li class="breadcrumb-item">Zamówienia</li>
        </ol>
    </div>
  </div> -->
  <div class="row" [checkRolePermition]="['orders.create', 'orders.edit', 'orders.create']">
    <div class="col-12 text-right">
      <a [routerLink]="['/orders/admin']" class="btn btn-primary">
        <i class="fas fa-tools fa-fw"></i> Zarządzaj
      </a>
    </div>
  </div>
</div>

<router-outlet></router-outlet>

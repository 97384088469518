<section class="content">
  <div class="container">
    <div class="card" style="border-top-left-radius: 0;border-top-right-radius: 0;">
      <div class="overlay" *ngIf="loading">
        <i class="fas fa-3x fa-spinner fa-spin"></i>
      </div>
      <div class="card-header d-flex align-items-center">
        <h3 class="card-title m-0 flex-grow-1 text-bold"><i class="fas fa-chart-area fa-fw"></i> Raport </h3>
      </div>
      <form [formGroup]="filterForm">
        <nz-range-picker formControlName="date" class="w-100"></nz-range-picker>
      </form>
      <!-- <pre>{{filterForm.value | json }}</pre> -->
      <div class="card-footer">
        <a class="btn btn-success" (click)="getRaport()">
          <i class="fas fa-check fa-fw"></i>
          Genruj Raport
        </a>
      </div>
    </div>
  </div>
</section>

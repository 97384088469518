<div style="min-height: 10rem; position: relative;">
  <div *ngIf="slide && slideResult">
    <app-content-player
          *ngIf="slide.type == 'content'"
          [result]="slideResult"
          [slide]="slide"
          (onFinish)="saveResult($event)"
          ></app-content-player>
    <app-scorm-player
          *ngIf="slide.type == 'scorm'"
          [result]="slideResult"
          [scorm]="slide.scorm"
          [dontFinish]="true"
          (onFinish)="saveResult($event)"
          (onClose)="saveResult($event)"
          ></app-scorm-player>
    <app-youtube-player
          *ngIf="slide.type == 'youtube'"
          [result]="slideResult"
          [slide]="slide"
          (onFinish)="saveResult($event)"></app-youtube-player>
    <app-video-player
          *ngIf="slide.type == 'video'"
          [result]="slideResult"
          [slide]="slide"
          (onFinish)="saveResult($event)"></app-video-player>
    <app-quiz-player
          *ngIf="slide.type == 'quiz'"
          [result]="slideResult"
          [quiz]="slide.quiz"
          (onFinish)="saveResult($event)"
          ></app-quiz-player>
    <!-- <div *ngIf="slide.audio" plyr style="width: 100%;" [plyrPlaysInline]="true" plyrType="audio" [plyrSources]="audioPathPlyr" [plyrOptions]="audioOptions"></div> -->
    <!-- <div class="d-flex" style="flex-direction: column;width: 100%;" *ngIf="slide.audio">
      <hr>
      <audio height="100%" controls autoplay style="width: 100%;" plyr>
        <source [src]="audioPath" type="{{slide.audio.mimeType}}">
        Your browser does not support the video tag.
      </audio>
    </div> -->
    <plyr *ngIf="audioPathPlyr.length" [plyrPlaysInline]="true" [plyrSources]="audioPathPlyr"  plyrType="audio" (plyrInit)="player = $event" [plyrOptions]="audioOptions"></plyr>
  </div>
  <div *ngIf="!slide" class="p-4 text-center" style="min-height: 40vh;">
    <h3 class="p-3">Szkolenie zakończone</h3>
    <a [routerLink]="['/training', invitation.id]" class="btn btn-primary btn-lg">
      <i class="fas fa-check"></i>
      Zakończ
    </a>
  </div>
  <div class="overlay" *ngIf="slide && !slideResult" style="position: absolute; top:0; left: 0; bottom: 0; right: 0;">
    <div style="position: sticky; text-align: center">
      <i class="fas fa-3x fa-spinner fa-spin"></i>
      <p>Ładowanie...</p>
    </div>
  </div>

</div>
<div class="card-footer">
  <button *ngIf="slideIndex" [routerLink]="['/training', invitation.id, 'multimedia', training.id, slideIndex -1]" class="btn btn-primary">
    <i class="fas fa-chevron-left"></i>
    Poprzedni
  </button>
  <button
        *ngIf="slide"
        (click)="next()"
        class="btn float-right"
        [ngClass]="{
          'btn-primary' : slideResult?.completedOn,
          'btn-secondary' : !slideResult?.completedOn
        }"
        [disabled]="!slideResult?.completedOn"
        >
    Następny
    <i class="fas fa-chevron-right"></i>
  </button>
</div>

<table class="table d-none" *ngIf="result" >
  <thead>
    <th>createdAt</th>
    <th>timeSpent</th>
    <th>score</th>
    <th>scoreMax</th>
    <th>scorePercent</th>
    <th>completed</th>
    <th>completedOn</th>
    <th>passed</th>
  </thead>
  <tbody>
    <tr>
      <td>{{result?.createdAt  | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
      <td>{{result.timeSpent}}</td>
      <td>{{result.score}}</td>
      <td>{{result.scoreMax}}</td>
      <td>{{result.scorePercent}}</td>
      <td>{{result.completed}}</td>
      <td>{{result?.completedOn  | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
      <td>{{result?.passed }}</td>
    </tr>
    <tr>
      <td>-</td>
    </tr>
    <tr *ngFor="let item of slideResults">
      <td>{{item?.createdAt  | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
      <td>{{item.timeSpent}}</td>
      <td>{{item.score}}</td>
      <td>{{item.scoreMax}}</td>
      <td>{{item.scorePercent}}</td>
      <td>{{item.completed}}</td>
      <td>{{item?.completedOn  | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
      <td>{{item?.passed }}</td>
    </tr>
  </tbody>
</table>

import { TrainingPackage } from '@/model/package.model';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Data } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { Survey } from '@/model/survey.model';


@Component({
  selector: 'app-survey-admin-report',
  templateUrl: './survey-admin-report.component.html',
  styleUrls: ['./survey-admin-report.component.scss']
})
export class SurveyAdminReportComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  survey: Survey;
  result: any[] = [];
  loading = false;
  page = 1;
  pageSize = 20;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
  ) {

   }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe( (data: Data) => {
      this.survey = data.instance;
      this.report();
    });
  }

  get pagination(): any[] {
    return [...this.result].splice( (this.page-1)*this.pageSize, this.pageSize);
  }

  report() {
    // if (this.form.valid) {
      this.loading = true;
      this.http.get<any[]>(`<backendhost>/v1/survey/${this.survey.id}/report`)
        .pipe(
          catchError( (err, caught): any => {

            this.toastr.error(err.message);
          })
        )
        .subscribe( (result: any[]) => {
          this.loading = false;
          this.result = result;
        })
    // } else {
    //     this.toastr.error('Formularz jest nieprawidłowy!');
    // }
  }


  generateCSV() {
    let title = `Raport z ankety ${this.survey.name}`;
    let options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: ',',
      showLabels: true,
      // showTitle: true,
      title: title,
      // useBom: true,
      // noDownload: true,
      headers: ["Pytawie", "Odpowiedz", "Liczba odpowiedzi", "Procent"],
      useHeader: false,
      nullToEmptyString: true,
    };
    new AngularCsv(this.result, title, options);
  }
}


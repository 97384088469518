import { AuthService } from '@/auth/auth.service';
import { User } from '@/model/user.model';
import { Injectable } from '@angular/core';
import { Socket, SocketIoModule } from 'ngx-socket-io';
import { catchError, filter, map, take } from 'rxjs/operators';
import * as fromChatActions from '@/store/chat/chat.actions';
import * as fromChatReducers from '@/store/chat/chat.reducers';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { ChatMessage, Conversation } from '@/model/chat.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ofType } from '@ngrx/effects';
import { loginSuccess } from '@/store/auth/auth.actions';

@Injectable()
export class ChatService {
  private initialLoad = false;
  constructor(
    private socket: Socket,
    private authService: AuthService,
    private store: Store<fromChatReducers.State>,
    private actionsSubject : ActionsSubject,
    private httpClient: HttpClient
  ) {

    this.authService.getUser().then( (user:User) => {
      this.load();
      this.socket.emit('set user', user);
      this.socket.fromEvent(`chat:user:${user.id}`).subscribe( (msg: ChatMessage) => {
        this.store.dispatch(fromChatActions.addOneMessageToConvertsations({msg: msg}));
      });
    })
    this.actionsSubject.pipe(ofType(fromChatActions.addConvertsations)).subscribe( () => {this.initialLoad = true;})
    this.actionsSubject.pipe(ofType(loginSuccess)).subscribe(this.load.bind(this));
  }

  public load() {
    this.store.dispatch(fromChatActions.fetchRecentConversations({}))

  }

  get loaded() {
    return this.initialLoad;
  }

  get all(): Observable<Conversation[]> {
    return this.store.pipe(
      select(fromChatReducers.selectConversations),
      map((arr: Conversation[]) => arr.filter((conv: Conversation) => conv.lastMessage))
    );
  }

  get latest(): Observable<Conversation[]> {
    return this.all.pipe(map(x => x.slice(0,6)));
  }

  get count(): Observable<number> {
    return this.all.pipe(
      map( (arr: Conversation[]) => arr.map( (item:Conversation) => item.unseenCount)),
      map( (arr: number[]) => arr.reduce( (a: number, b: number) => a + b, 0))
    );
  }

  getCoversationWithUser(id: number): Observable<number> {
    let url = `<backendhost>/v1/chat/conversation/user/${id}`;
    return this.httpClient.get<{id: number}>(url, {
      observe: 'body',
      responseType: 'json'
    }).pipe(
      catchError( () => null),
      map( (result: any) => result.id)
    );
  }

  setAsSeen(msg: number, conversation: number): void {
    console.log('setAsSeen', msg);
    let url = `<backendhost>/v1/chat/conversation/${conversation}/seen/${msg}`;
    this.httpClient.post<{id: number}>(url, {msg : msg}, {
      observe: 'body',
      responseType: 'json'
    }).toPromise();
    this.store.dispatch(fromChatActions.setAsSeen({conversation}));
  }

  // sendMessage(msg: string) {
  //   this.socket.emit('message', msg);
  // }
  // getMessage() {
  //   return this.socket.fromEvent('message').subscribe(console.log);
  //   // .pipe(map((data) => data.msg));
  // }
}

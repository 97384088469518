import { Outpost } from '@/model/outpost.model';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { ofType } from '@ngrx/effects';
import { Store, ActionsSubject } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as fromOutpostActions from '@/store/outpost/outposts.actions';
import * as fromOutpostReducers from '@/store/outpost/outposts.reducers';
import moment from 'moment';

@Component({
  selector: 'app-edit-outpost',
  templateUrl: './edit-outpost.component.html',
  styleUrls: ['./edit-outpost.component.scss']
})
export class EditOutpostComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  outpost: Outpost;
  constructor(
    private toastr: ToastrService,
    private store: Store<fromOutpostReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      startTime: new FormControl(moment("09:00", "HH:mm").toDate(), Validators.required),
      endTime: new FormControl(moment("17:00", "HH:mm").toDate(), Validators.required),
      management: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data: Data) => {
      this.outpost = data.instance;
      this.form.patchValue({
        ...this.outpost,
        ...{
          startTime: moment(this.outpost.startTime, "HH:mm"),
          endTime: moment(this.outpost.endTime, "HH:mm")
        }
      });
    })
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  save(): void {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    if (this.form.valid) {
      this.outpost = {
        ...this.outpost,
        ...this.form.value,
        ...{
          management : this.form.value.management.map(item => item.id)
        }
      };
      this.store.dispatch(fromOutpostActions.updateOutpost({outpost: this.outpost}));
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromOutpostActions.setOutpost.type)
      ).subscribe( (action: any) => {
        this.toastr.success('Zapisano zmiany');
        // this.router.navigate(['/outposts', action.outpost.id, 'edit']);
        this.router.navigate(['/outposts']);
      });
  } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
  }
  }

}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

import * as fromMultimediaActions from '@/store/multimedia/multimedia.actions';
import * as fromMultimediaReducers from '@/store/multimedia/multimedia.reducers';
import { ActionsSubject, Store } from '@ngrx/store';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Multimedia } from '@/model/multimedia.model';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'app-multimedia-add',
  templateUrl: './multimedia-add.component.html',
  styleUrls: ['./multimedia-add.component.scss']
})
export class MultimediaAddComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  scorm: Multimedia;
  constructor(
    private toastr: ToastrService,
    private store: Store<fromMultimediaReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {
    // this.activatedRoute.data.subscribe( (data: Data) => {
    //   this.scorm = data.instance;
    //   this.form.patchValue(this.scorm);
    // });
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  save(): void {
    if (this.form.valid) {
      this.scorm = {...this.scorm, ...this.form.value};
      this.store.dispatch(fromMultimediaActions.createMultimedia({item: this.scorm}));
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromMultimediaActions.addMultimedia.type)
      ).subscribe( (result: any) => {
        this.toastr.success('Zapisano zmiany');
        this.router.navigate([`/training/admin/multimedia/${result.item.id}/edit`]);
      });
  } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
  }
  }

}

import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import * as fromKbActions from '@/store/kb/kb.actions';
import * as fromKbReducers from '@/store/kb/kb.reducers';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { FAQ, KbArticle } from '@/model/article.model';
import { getBase64 } from '@/utils/base64';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { tinyMceConfig } from '@/config/tinymce.config';
import { Observable, Subject } from 'rxjs';
import { Folder } from '@/model/folder.model';
import { Actions, ofType } from '@ngrx/effects';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-edit-article',
  templateUrl: './edit-article.component.html',
  styleUrls: ['./edit-article.component.scss']
})
export class EditArticleComponent implements OnInit, OnDestroy {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  unsubscribe$ = new Subject<boolean>();
  form: FormGroup;
  imageFile: NzUploadFile;
  backgroundFile: NzUploadFile;
  fileList: NzUploadFile[] = [];
  faqList: FAQ[];
  article: KbArticle = {
    id: null,
    content: null,
    tags: [],
    title: '',
    createdAt: '',
    createdBy: undefined
  };
  tinyMceConfig = tinyMceConfig;
  tinyMceConfigSmall = {...tinyMceConfig, ...{
    init : {...tinyMceConfig.init, ...{height: 150}}
  }};
  @Output() fileListChange: EventEmitter<NzUploadFile[]> = new EventEmitter<NzUploadFile[]>();

  constructor(
    private viewportScroller: ViewportScroller,
    private httpClient: HttpClient,
    private store: Store<fromKbReducers.State>,
    private toastr: ToastrService,
    private router: Router,
    protected actions$: Actions,
    protected activatedRoute: ActivatedRoute,
    private sanitized: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      title: new FormControl(null, Validators.required),
      content: new FormControl(null, Validators.required),
    });
    this.activatedRoute.paramMap.pipe(
      map((params: ParamMap) => parseInt(params.get('id')))
    ).subscribe( (id: number) => {
      this.store.pipe(takeUntil(this.unsubscribe$), select(fromKbReducers.selectArticle,id)).subscribe((article:KbArticle) => {
        if (!article)return;
        this.article = article;
        this.faqList = [...this.article.faq.map(item => { return {...item}})];
        if (this.article.audio) {
          this.audioPath =  `${environment.apiUrl}/v1/audio/${this.article.audio.id}`
        }
        this.form.patchValue({...this.article});
      });
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  get saving(): Observable<boolean>  {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromKbReducers.selectSaving));
  }


  beforeUpload = (file: NzUploadFile): boolean => {
    getBase64(file as any).then( (preview: string) => {
      file.url = preview;
      file.status = 'done';
      this.fileList = this.fileList.concat(file);
    })
    return false;
  };

  beforeUploadBackground = (file: NzUploadFile): boolean => {
    getBase64(file as any).then( (preview: string) => {
      this.backgroundFile  = file;
      this.article = {...this.article, ...{background: preview}};
    })
    return false;
  };

  beforeUploadImage = (file: NzUploadFile): boolean => {
    getBase64(file as any).then( (preview: string) => {
      this.imageFile  = file;
      this.article = {...this.article, ...{image: preview}};
    })
    return false;
  };

  get folder(): Observable<Folder>  {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromKbReducers.selectFolder));
  }

  save () {
    this.folder.pipe(takeUntil(this.unsubscribe$)).subscribe(this.saveArticle.bind(this));
  }


  saveArticle(folder: Folder) {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });



    if (this.form.valid) {
      this.article = {
        ...this.article,
        ...this.form.value,
        ... {
          faq: this.faqList
        }
      };
      let audioFile = this.audioFile?.length ? this.audioFile[0] as any : null;
      this.store.dispatch(fromKbActions.saveArticle({
        article: this.article,
        folder: folder,
        image: this.imageFile as any,
        background: this.backgroundFile as any,
        audioFile: audioFile
      }));
      this.actions$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromKbActions.saveSuccess),
        tap( (action: any) => {
          let fileList = [...this.fileList];
          this.fileList = [];
          fileList.forEach( (file: NzUploadFile, index: number) => {
            file.status = 'uploading';
            this.fileList.push(file);
            this.fileListChange.emit(this.fileList);
            const formData = new FormData();
            formData.append('image', file as any);
            this.httpClient.post<any>(`<backendhost>/v1/kb/article/${action.id}/upload`, formData, {observe: 'body',responseType: 'json'})
              .subscribe(
                () => {
                  file.status = 'success';
                  fileList.splice(index, 1, file);
                  this.fileList = [...fileList];
                  let num = fileList.filter(x => x.status !== 'uploading').length;
                  this.toastr.success(`Wgrano obraz ${num} z ${fileList.length}`, file.name);
                  if (num == fileList.length) {
                    setTimeout(() => {this.router.navigate(['/news']);}, 500);
                  }
                },
                () => {
                  file.status = 'error';
                  fileList.splice(index, 1, file);
                  this.fileList = [...fileList];
                  let num = fileList.filter(x => x.status !== 'uploading').length;
                  this.toastr.error(`Nie powiodło się wgrywanie obrazu ${num} z ${fileList.length}`, file.name);
                  if (num == fileList.length) {
                    setTimeout(() => {this.router.navigate(['/news']);}, 500);
                  }
                })
          })
        })
      ).subscribe( (_) => {
        this.toastr.success('Zapisano artykuł');
        console.log('/kb/' + folder.id + '/admin/articles');
        this.router.navigate(['/kb/' + folder.id + '/admin/articles']);
      });
    } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }

  addFAQ() {
    this.faqList.push({question:"", answer:""});
    this.scrollToBottom();
  }

  deleteFAQ(index: number) {
    // let faq = [...this.article.faq];
    this.faqList.splice(index,1);
    // this.article = {...this.article, ...{faq: faq}}
  }



  scrollToBottom(): void {
    try {
      setTimeout( () => {
        this.viewportScroller.scrollToAnchor('foot-ancor');
      });

    } catch(err) { }
  }

  audioFile: NzUploadFile[] = [];
  beforeUploadAudio = (file: NzUploadFile): boolean => {
    console.log(file);
    this.audioFile = [file];
    getBase64(file as any).then(hash => {
      this.audioPath = this.sanitized.bypassSecurityTrustUrl(hash as string)
    })
    return false;
  };

  audioPath: any;

  clearAudio() {
    this.article.audio = null;
    this.audioFile = [];
    this.audioPath = null;
  }
}

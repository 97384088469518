import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ofType } from '@ngrx/effects';
import { Store, ActionsSubject } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fromSurveyAction from '@/store/survey/survey.actions';
import * as fromSurveyReducers from '@/store/survey/survey.reducers';
import { Survey } from '@/model/survey.model';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-survey-admin-add',
  templateUrl: './survey-admin-add.component.html',
  styleUrls: ['./survey-admin-add.component.scss']
})
export class SurveyAdminAddComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  survey: Survey;
  imageFile: NzUploadFile;
  backgroundFile: NzUploadFile;

  constructor(
    private toastr: ToastrService,
    private store: Store<fromSurveyReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      content: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {}


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  save(): void {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    if (this.form.valid) {
      this.survey = {...this.survey, ...this.form.value};
      this.store.dispatch(fromSurveyAction.createSurvey({
        survey: this.survey,
        image: this.imageFile as any,
        background: this.backgroundFile as any
      }));
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromSurveyAction.addSurvey.type)
      ).subscribe( (action: any) => {
        this.toastr.success('Dodano nową ankietę');
        this.router.navigate(['/survey/admin/', action.survey.id, 'questions']);
      });
  } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
  }
  }

}

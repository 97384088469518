import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';

import * as fromRedmineCategoryActions from '@/store/redmine/redmine.category.actions';
import * as fromRedmineCategoryReducers from '@/store/redmine/redmine.category.reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { RedmineCategory, RedminePriority, RedmineSubCategory } from '@/model/redmine.model';
import { filter, tap, take, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';
import { FAQ } from '@/model/article.model';

@Component({
  selector: 'app-edit-redmine-subcategory',
  templateUrl: './edit-redmine-subcategory.component.html',
  styleUrls: ['./edit-redmine-subcategory.component.scss']
})
export class EditRedmineSubcategoryComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<boolean>();
  form: FormGroup;
  category: RedmineCategory;
  subCategory: RedmineSubCategory = {
    id: null,
    title: null,
    description: null,
    template: null,
    parent: null,
    priority: null,
    faq: [],
  };

  constructor(
    private viewportScroller: ViewportScroller,
    private store: Store<fromRedmineCategoryReducers.State>,
    private toastr: ToastrService,
    private router: Router,
    protected actions$: Actions,
    protected activatedRoute$: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(fromRedmineCategoryActions.fetchPriorities());
    this.form = new FormGroup({
      title: new FormControl(null, Validators.required),
      description: new FormControl(null),
      template: new FormControl(null),
      priority: new FormControl(null, Validators.required),
    });
    this.getPriorities().subscribe(result => {
      this.priorities = result;
        if(!this.form.controls.priority.value) {
          this.form.controls.priority.setValue([...result].filter(item => item.is_default).pop().id);
        }
    });
    this.activatedRoute$.data.subscribe(data => {
      if(!data.category) return;
      this.category = data.category;

      this.activatedRoute$.params.subscribe(params => {
        if(!params.id) return;
        let idx = this.category.children.map(i => i.id).indexOf(parseInt(params.id));
        this.subCategory = {
          ...this.category.children[idx],
          ...{
            faq: [...this.category.children[idx].faq.map(item => { return {...item}})]
          }
        };
        this.form.patchValue(this.subCategory);
      })
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  get saving(): Observable<boolean> {
    return of(false);
  }

  priorities: RedminePriority[] = [];

  getPriorities(): Observable<RedminePriority[]> {
    return this.store.pipe(
      select(fromRedmineCategoryReducers.selectPriorities),
      filter((result) => result.length > 0),
      distinctUntilChanged((a,b) => a.length == b.length),
    );
  }

  addFAQ() {
    this.subCategory = {
      ...this.subCategory,
      ...{
        faq: [...this.subCategory.faq, {question:"", answer:""}]
      }
    }
    this.scrollToBottom();
  }

  deleteFAQ(index: number) {
    let faq = [...this.subCategory.faq];
    faq.splice(index,1);
    this.subCategory = {...this.subCategory, ...{faq: faq}}
  }

  scrollToBottom(): void {
    try {
      setTimeout( () => {
        this.viewportScroller.scrollToAnchor('foot-ancor');
      });

    } catch(err) { }
  }

  save() {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    if (this.form.valid) {
      this.subCategory = {...this.subCategory, ...{parent: this.category.id},...this.form.value};
      this.store.dispatch(fromRedmineCategoryActions.saveSubCategory({item: this.subCategory}));
      this.actions$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromRedmineCategoryActions.setOneSubCategory)
      ).subscribe( (action: any) => {
        this.toastr.success('Zapisano zmiany');
        this.router.navigate(['/redmine/admin',this.subCategory.parent]);
      });
    } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }
}

<div class="content-header">
  <div class="row mb-2">
    <!-- <div class="col-sm-6">
        <h1 class="m-0 text-dark">
          <i class="fas fa-trophy"></i> Nagrody
        </h1>
    </div> -->
    <!-- <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
            <li *ngFor="let bread of (folder | async)?.path" class="breadcrumb-item"><a [routerLink]="['/kb', bread.id]">{{bread.title}}</a></li>
        </ol>
    </div> -->
  </div>
</div>
<section class="content">
  <div class="container-xl pt-3 pb-3">
    <div class="card">
      <div class="overlay" *ngIf="!(user | async) || !(user | async)?.prizes">
        <div style="position: sticky; top:50%; left:50%; align-self: baseline; text-align: center;  padding: 25px 0">
          <i class="fas fa-3x fa-spinner fa-spin"></i>
          <p>Ładowanie...</p>
        </div>
      </div>
      <div class="card-header d-flex align-items-center">
        <h3 class="card-title m-0 flex-grow-1 text-lg">
          <img class="img-circle" [src]="(user | async) | userAvatar:35" *ngIf="(user | async)" [appUserDisactivatable]="(user | async)"/>
          <span [appUserDisactivatable]="(user | async)" *ngIf="(user | async)">{{ (user | async)?.name }}</span>
        </h3>
        <div class="card-tools flex-grow-1">
          <!-- <div class="input-group">
            <input type="text" name="table_search" class="form-control float-right" placeholder="Szukaj" #searchInput>
            <button class="btn bg-transparent" style="position:absolute; right: 0; transform: translate(-100%,0);" (click)="clearSearch()">
              <i class="fa fa-times"></i>
            </button>
            <span class="input-group-append">
              <div class="input-group-text bg-transparent"><i class="fas fa-search"></i></div>
            </span>
          </div> -->
        </div>
      </div>


      <div class="table-responsive">
        <table class="table align-middle table-hover">
          <thead>
            <tr>
              <th style="width:1rem"></th>
              <th>Nazwa</th>
              <th>Opis</th>
              <th>Nadano</th>
              <th colspan=2>Przez</th>
              <th class="text-right" >
                <button  adminOnly class="btn btn-success btn-sm elevation-2 text-nowrap" (click)="add()" >
                  <i class="fas fa-plus fa-fw fa-lg"></i> Dodaj
                </button>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let item of prizes | async; let idx = index; trackBy: identify">
              <td class="align-middle"><img class="img-circle" [src]="item.prize | groupAvatar:55"/></td>
              <td class="align-middle text-nowrap">{{item.prize.title}}</td>
              <td class="align-middle">{{item.prize.short}}...</td>
              <td class="align-middle text-nowrap">{{item.createdAt | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
              <td class="align-middle pr-1 text-right"><img class="img-circle" [src]="item.createdBy | userAvatar:25"/></td>
              <td class="align-middle pl-0 text-nowrap">
                <span class="text-decoration-line-through" *ngIf="!item?.createdBy">Użytkownik usunięty</span>
                {{item.createdBy?.name}}
              </td>
              <td class="align-middle text-nowrap text-right" >
                <button class="btn btn-primary btn-sm elevation-2 mr-2" [routerLink]="['/prizes/edit', item.id]" ngbTooltip="Edycja">
                  <i class="fas fa-edit fa-fw fa-lg"></i>
                </button>
                <a
                    class="btn btn-danger btn-sm elevation-2"
                    nz-popconfirm
                    nzPopconfirmTitle="Czy na pewno usunąć?"
                    nzOkText="Tak"
                    nzCancelText="Nie"
                    nzPopconfirmPlacement="left"
                    ngbTooltip="Usuń"
                    (nzOnConfirm)="delete(item)" >
                  <i class="fas fa-trash fa-fw fa-lg"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="!(prizes | async) || (prizes | async).length == 0"></nz-empty>
      </div>
    </div>
  </div>
</section>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';

import * as fromRedmineCategoryActions from '@/store/redmine/redmine.category.actions';
import * as fromRedmineCategoryReducers from '@/store/redmine/redmine.category.reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { RedmineCategory, RedmineSubCategory } from '@/model/redmine.model';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-redmine-category',
  templateUrl: './edit-redmine-category.component.html',
  styleUrls: ['./edit-redmine-category.component.scss']
})
export class EditRedmineCategoryComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<boolean>();
  form: FormGroup;
  category: RedmineCategory;

  constructor(
    private store: Store<fromRedmineCategoryReducers.State>,
    private toastr: ToastrService,
    private router: Router,
    protected actions$: Actions,
    protected activatedRoute$: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(fromRedmineCategoryActions.fetchPriorities());
    this.form = new FormGroup({
      title: new FormControl(null, Validators.required),
      description: new FormControl(null),
    });
    this.activatedRoute$.data.subscribe(data => {
      if(!data.instance) return;
      this.category = data.instance;
      this.form.patchValue(data.instance);
      this.store.select(fromRedmineCategoryReducers.selectCategory, this.category.id).subscribe(category => {
        this.category = category;
      })
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  get saving(): Observable<boolean> {
    return of(false);
  }

  save() {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    if (this.form.valid) {
      this.category = {...this.category, ...this.form.value};
      if(this.category.id) {
        this.store.dispatch(fromRedmineCategoryActions.updateCategory({item: this.category}));
      } else {
        this.store.dispatch(fromRedmineCategoryActions.createCategory({item: this.category}));
      }
      this.actions$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromRedmineCategoryActions.updateOneCategory)
      ).subscribe( (action: any) => {
        if(this.category.id) {
          this.toastr.success('Zapisano zmiany');
          this.router.navigate(['/redmine/admin']);
        } else {
          this.toastr.success('Utworzono nową kategorie');
          this.router.navigate(['/redmine/admin',action.item.id]);
        }
      });
    } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }

  priority(id): Observable<string> {
    return this.store.pipe(select(fromRedmineCategoryReducers.selectPriority,id),map(result => result ? result.name : ""));
  }

  deleteSub(item: RedmineSubCategory) {
    this.store.dispatch(fromRedmineCategoryActions.deleteSubCategory({item}));
  }
}

import { Quiz, QuizQuestion } from '@/model/quiz.model';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Data } from '@angular/router';
import { Subject } from 'rxjs';
import * as fromQuizReducers from '@/store/quiz/quiz.reducers';
import { map } from 'rxjs/operators';
import { TrainingResult } from '@/model/package.model';

@Component({
  selector: 'app-quiz-play',
  templateUrl: './quiz-play.component.html',
  styleUrls: ['./quiz-play.component.scss']
})
export class QuizPlayComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  resetSubject: Subject<void> = new Subject<void>();
  quiz: Quiz;
  result: TrainingResult = {
    id: 0,
    completed: false,
    completedOn: null,
    score: null,
    scoreMax: null,
    scorePercent: null,
    progression: null,
    timeSpent: null,
    runtimeData: {}
  };
  constructor(
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data:Data) => {
      this.quiz = {...this.quiz, ...data.instance};
    });
  }

  reset() {
    this.result = {
      id: 0,
      completed: false,
      completedOn: null,
      score: null,
      scoreMax: null,
      scorePercent: null,
      progression: null,
      timeSpent: null,
      runtimeData: {}
    };
    this.resetSubject.next();
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onFinish(result) {
    this.result = result;
  }
}

import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { Post } from "@/model/post.model";
import * as PostActions from './post.actions';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { Comment, CommentsDto } from '@/model/comment.model';
import { state } from '@angular/animations';
import { Group } from '@/model/group.model';


export interface PostsState extends EntityState<Post> {
  loading: boolean;
  saving: boolean;
  hasMore: boolean;
}

export const adapter: EntityAdapter<Post> = createEntityAdapter<Post>({
  sortComparer: (a: Post, b: Post) => a.id < b.id ? 1 : 0
});

export const commnetAdapter: EntityAdapter<Comment> = createEntityAdapter<Comment>();

export const initialState: PostsState = {
  loading: false,
  saving: false,
  hasMore: true,
  ids: adapter.getInitialState().ids,
  entities: adapter.getInitialState().entities}

export const reducer = createReducer(
  initialState,
  // members: memberAdapter.getInitialState()
  on(PostActions.clear, () => initialState),
  on(PostActions.fetchNewsAction, (state, _) => { return {...state, ...{loading: true}}}),
  on(PostActions.laodingCompleted, (state, _) => { return {...state, ...{loading: false}}}),
  on(PostActions.createNewPostAction,
    (state, action) => {
      return {...state, ...{saving: true}};
    }
  ),
  on(PostActions.addMultipleNews,
    (state, action) => {
      let list = action.posts.map(item => { return {...item, comments: {...item.comments, ... { list: commnetAdapter.getInitialState()}}}; });
      return {...adapter.addMany(list, state), ...{saving: false}};
    }
  ),
  on(PostActions.removePostAction,
    (state, action) => {
      return {...adapter.removeOne(action.post.id, state), ...{saving: false}};
    }
  ),
  on(PostActions.setHasMore,
    (state, action) => {

      return typeof action.loading === 'undefined'
         ? {...state,  ...{hasMore : action.hasMore}}
         : {...state,  ...{hasMore : action.hasMore, loading: action.loading}}
    }
  ),
  on(PostActions.updateNews, (state, action) => {
    let post = state.entities[action.post.id];
    return {...state, ...adapter.updateOne({id: post.id, changes: action.post}, state), ...{saving: false}};
  }),
  on(PostActions.toogglePostFavorite, (state, action) => {
    let post = state.entities[action.post.id];
    return {...state, ...adapter.updateOne({id: post.id, changes: {favoriteSaving: true}}, state), ...{saving: false}};
  }),
  on(PostActions.addCommentToPostAction, (state, action) => {
    // let comment = {...comment. ... {replye}
    let post = state.entities[action.post.id];
    return adapter.updateOne({
      id: action.post.id,
      changes: {
        comments : {
          count: +post.comments.count + 1,
          list: commnetAdapter.addOne(action.comment, post.comments.list)
        }
      }
    }, state);
  }),
  on(PostActions.addCommentToCommentAction, (state, action) => {
    let post = state.entities[action.post.id];
    let replyTo = post.comments.list.entities[action.replyTo.id];
    return adapter.updateOne({
      id: action.post.id,
      changes: {
        comments : {
          count: +post.comments.count + 1,
          list: commnetAdapter.updateOne({
            id: replyTo.id,
            changes: {
              replies: commnetAdapter.addOne(action.comment,replyTo.replies)
            }
          }, post.comments.list)
        }
      }
    }, state);
  }),
  on(PostActions.addManyCommentsToPostAction, (state, action) => {
    let post = state.entities[action.post.id];
    return adapter.updateOne({
      id: action.post.id,
      changes: {
        comments : {
          count: +post.comments.count,
          list: commnetAdapter.addMany(action.comments, post.comments.list)
        }
      }
    }, state);
  }),
  on(PostActions.updateCommentAction, (state, action) => {
    let post = state.entities[action.post.id];
    let comment = post.comments.list.entities[action.comment.id];
    return adapter.updateOne({
      id: action.post.id,
      changes: {
        comments : {
          count: +post.comments.count,
          list: commnetAdapter.updateOne({
            id: comment.id,
            changes: action.comment
          }, post.comments.list)
        }
      }
    }, state);
  }),
  on(PostActions.updateReplyCommentAction, (state, action) => {
    let post = state.entities[action.post.id];
    let replyTo = post.comments.list.entities[action.replyTo.id];
    console.log(action);
    console.log(replyTo.replies);
    console.log(commnetAdapter.updateOne({id: action.comment.id, changes: action.comment}, replyTo.replies));
    // let change = replyTo.replies.map(it => {return it});
    // change[replyTo.replies.map(it => it.id).indexOf(action.comment.id)] = {...change[replyTo.replies.map(it => it.id).indexOf(action.comment.id)],...action.comment};
    return adapter.updateOne({
      id: action.post.id,
      changes: {
        comments : {
          count: +post.comments.count,
          list: commnetAdapter.updateOne({
            id: replyTo.id,
            changes: {
              replies: commnetAdapter.updateOne({
                id: action.comment.id,
                changes: action.comment
              }, replyTo.replies)
            }
          }, post.comments.list)
        }
      }
    }, state);
  }),
  on(PostActions.setAsSeen, (state, action) => {
    let post = state.entities[action.post.id];
    return post.seen ? state : adapter.updateOne({
      id : action.post.id,
      changes: {
        seen : true,
        seenCount: post.seenCount + 1
      }
    }, state);
  }),
  on(PostActions.deleteComment, (state, action) => {
    console.log(action)
    if(!action.comment.replyTo || !action.comment.replyTo.id) {
      let post = state.entities[action.comment.post.id];
      return adapter.updateOne({
        id: post.id,
        changes: {
          comments : {
            count: +post.comments.count - 1 - action.comment.replies.ids.length,
            list: commnetAdapter.removeOne(action.comment.id, post.comments.list)
          }
        }
      }, state);
    } else {
      let post = state.entities[action.comment.post.id];
      let reply = post.comments.list.entities[action.comment.replyTo.id]
      return adapter.updateOne({
        id: post.id,
        changes: {
          comments : {
            ...post.comments,
            ...{
              count: +post.comments.count - 1,
              list: commnetAdapter.updateOne({
                id: reply.id,
                changes: {
                  replies: commnetAdapter.removeOne(action.comment.id, reply.replies)
                }
              }, post.comments.list)
            }
          }
        }
      }, state);
    }
    return state;
  }),
  // on(PostActions.addCommentToCommentAction, (state, action) => {
  //   let post = state.entities[action.post.id];
  //   let replyTo = post.comments.list.entities[action.replyTo.id];
  //   return adapter.updateOne({
  //     id: action.post.id,
  //     changes: {
  //       comments : {
  //         count: +post.comments.count + 1,
  //         list: commnetAdapter.updateOne({
  //           id: replyTo.id,
  //           changes: {
  //             replies: [...replyTo.replies, action.comment]
  //           }
  //         }, post.comments.list)
  //       }
  //     }
  //   }, state);
  // }),
)

export const selectPostsState = createFeatureSelector<PostsState>('posts');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectAllPosts = createSelector(selectPostsState, selectAll )

export const selectPostsInGroup = createSelector(
  selectAllPosts,
  (state, id: number) => {
    return state.filter( (post: Post) => post.groups.find( (group: Group) => group.id === id))
  }
);

export const selectPostsNoGroup = createSelector(
  selectAllPosts,
  (state) => {
    return state.filter( (post: Post) => post.groups.length === 0)
  }
);

export const selectPostsObserved = createSelector(
  selectAllPosts,
  (state) => {
    return state.filter( (post: Post) => post.groups.length !== 0)
  }
);

export const selectPostsFavorite = createSelector(
  selectAllPosts,
  (state) => {
    return state.filter( (post: Post) => post.favorite === true)
  }
);

export const selectEntity = createSelector(
  selectPostsState,
  (state, id: number) => state.ids.indexOf(id) >= 0 ? state.entities[id] : null
);

export const selectComments = createSelector(
  selectPostsState,
  // (state, id: number) => state.ids.indexOf(id) >= 0 ? Object.values(state.entities[id].comments.list.entities) : []
  (state, id: number) => commnetAdapter.getSelectors().selectAll(state.entities[id].comments.list)
);


export const selectHasMore = createSelector(selectPostsState, (state) => state.hasMore )
export const selectLoading = createSelector(selectPostsState, (state) => state.loading )
export const selectSaving = createSelector(selectPostsState, (state) => state.saving )

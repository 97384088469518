import { User } from '@/model/user.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '@services/app.service';
import { BehaviorSubject, Observable, of, OperatorFunction, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import * as fromGroupReducer from '@/store/group/group.reducers';
import * as fromGroupActions from '@/store/group/group.actions';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { Group, GroupMember } from '@/model/group.model';
import { AppState } from '@/store/app.reducers';
import { AuthService } from '@/auth/auth.service';



@Component({
  selector: 'app-edit-group-members',
  templateUrl: './edit-group-members.component.html',
  styleUrls: ['./edit-group-members.component.scss']
})
export class EditGroupMembersComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  @Input() group: Group;
  user: User;
  groupObserver: BehaviorSubject<Group>;
  members: GroupMember[];
  page = 1;
  pageSize = 20;
  model: any;
  searching = false;
  searchFailed = false;
  isDeleted = false;
  isUpdated = false;
  formatter = (user: User) => `${user.firstname} ${user.lastname}`;
  formatterEmpty = () => null;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private store: Store<fromGroupReducer.State>
  ) {
    authService.getUser().then((user)=> {this.user = user;});
    this.groupObserver = new BehaviorSubject(this.group);
    this.groupObserver.subscribe(this.getMembers.bind(this));
  }

  // canEdit(): boolean {
  //   return this.user && (!this.group.superior || this.authService.checkPermition('create'));
  // }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
      this.store.dispatch(fromGroupActions.fetchGroupMembersAction({group: this.group}));
      this.store.subscribe((data: any) =>{
        this.groupObserver.next(data.groups.entities[this.group.id])
      });
  }

  onPageChange() {
    this.groupObserver.next(this.group)
  }

  private getMembers(data): void {
    if(!this.group)return;
    let result: GroupMember[] = Object.values(data.members.entities);
    result = result.slice( (this.page-1) * this.pageSize, this.page * this.pageSize );
    this.members = result.map( (item: GroupMember) => {return { ...item}; });
  }

  groupMemberIdentity(index: number, member: GroupMember) {
    return member.user.id;
  }

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.appService.searchUser(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),

      tap(() => this.searching = false)
    )

    onUserSelect(selectedItem: NgbTypeaheadSelectItemEvent) {
      let user: User = selectedItem.item;
      this.store.dispatch(fromGroupActions.createNewGroupMemberAction({user: user, group: this.group}));
      this.store.pipe(select(fromGroupReducer.selectEntity, this.group.id), take(1)).subscribe((group: Group | null) => {
        this.group = group;
      })
    }

    deleteMemeber(member: GroupMember): void {
      this.isDeleted = true;
      this.store.dispatch(fromGroupActions.deleteGroupMemberAction({group: this.group, member: member}));
      this.store.pipe(take(1),select(fromGroupReducer.selectEntity, this.group.id)).subscribe((group: Group | null) => {
        this.group = group;
        this.isDeleted = false;
      })
    }

    updateMemeber(member: GroupMember): void {
      this.isUpdated = true;
      this.store.dispatch(fromGroupActions.editGroupMemberAction({group: this.group, member: member}));
      this.store.pipe(take(1),select(fromGroupReducer.selectEntity, this.group.id)).subscribe((group: Group | null) => {
        this.group = group;
        this.isUpdated = false;
      })
    }
}

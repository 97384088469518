import { Outpost } from "./outpost.model";
import { User } from "./user.model";

export enum SlotType {
  rejestrator = "rejestrator",
  pobierający = "pobierający",
  rejestratorPobierający = "rejestrator/pobierający",
  // PPCovidowy = "PP Covidowy"
}

export enum SlotDay {
  WEEKDAY = "WEEKDAY",
  SATURDAY = "SATURDAY",
}

export interface Slot {
  id: number;
  outpost: Outpost;
  type: SlotType;
  day: SlotDay;
  startTime: string,
  endTime: string,
  user: User,
}

export interface SlotNotificationUser extends User {
  added: boolean,
  sms: boolean,
}

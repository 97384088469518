import { PaginationDTO } from "@/model/pagination.model";
import { RedmineIssue } from "@/model/redmine.model";
import { StringifyHttpErrorResponse } from "@/utils/http.error.util";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { map, switchMap, catchError } from "rxjs/operators";

import * as fromActions from './redmine.issue.actions';
import * as fromReducers from './redmine.issue.reducers';

const ENDPOINT = '<backendhost>/v1/redmine-issue';

@Injectable()
export class RedmineIsssueEffects {
  state: fromReducers.State;

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private store$: Store<fromReducers.State>,
  ) {
    this.store$.pipe(select(fromReducers.selectState)).subscribe((state: fromReducers.State) => {
      this.state = state;
    })
  }

  onError (err, caught): any {
    this.toastr.error(StringifyHttpErrorResponse(err));
    return caught;
  };

  paginatorChange$ = createEffect(() => this.actions$.pipe(
    ofType(...[
      fromActions.setPage,
      fromActions.setSearch,
      fromActions.setOrder,
      fromActions.setDirection,
      fromActions.setType,
      fromActions.setStatus,
    ]),
    map( () => fromActions.fetch())
  ));

  fetch$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetch),
    switchMap(() => {
      let params = new HttpParams();
      params = params.set('skip', this.state.limit * (this.state.page - 1));
      params = params.set('limit', this.state.limit);
      params = params.set('order', this.state.order);
      params = params.set('direction', this.state.asc ? 'asc' : 'desc');
      params = params.set('type', this.state.issueType );
      params = params.set('status', this.state.status );
      if(this.state.search)
        params = params.set('search', this.state.search);
      return this.httpClient.get<PaginationDTO<RedmineIssue>>(`${ENDPOINT}`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: PaginationDTO<RedmineIssue>) => [fromActions.setAll(response)])
  ));

  fetchAssignedToMe$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchAssignedToMe),
    switchMap(() => {
      let params = new HttpParams();
      params = params.set('skip', 0);
      params = params.set('limit', this.state.limit);
      params = params.set('order', 'createdAt');
      params = params.set('direction', 'desc');
      params = params.set('type', 'assigned' );
      params = params.set('status', 'open' );
      return this.httpClient.get<PaginationDTO<RedmineIssue>>(`${ENDPOINT}`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: PaginationDTO<RedmineIssue>) => [fromActions.setAssignedToMe(response)])
  ));
  fetchAllCountWhenUpdateList$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetch),
    switchMap(() => [fromActions.fetchAssignedToMe()])
  ));
  fetchAllCount$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchAssignedToMe),
    switchMap(() => this.httpClient.get<{count: number, watched: number, assigned: number, created: number}>(`${ENDPOINT}/0/all-count`, {
        observe: 'body',
        responseType: 'json'
      })
    ),
    catchError(this.onError.bind(this)),
    switchMap((response: {count: number, watched: number, assigned: number, created: number}) => [fromActions.setAllCount(response)])
  ));

  fetchOne$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchOne),
    switchMap((action) => {
      return this.httpClient.get<RedmineIssue>(`${ENDPOINT}/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((item: RedmineIssue) => [fromActions.setIssue({item})])
  ));

  createIssue$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createIssue),
    switchMap((action) => {
      const formData = new FormData();
      if(action.attachments) {
        action.attachments.forEach((attachment) => {
          formData.append('attachments[]', attachment, attachment.name);
        });
      }
      formData.append('data', JSON.stringify(action.item));

      return this.httpClient.post<RedmineIssue>(`${ENDPOINT}`, formData, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((item: RedmineIssue) => [fromActions.setIssue({item})])
  ));

  updateIssue$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.updateIssue),
    switchMap((action) => {
      const formData = new FormData();
      if(action.attachments) {
        action.attachments.forEach((attachment) => {
          formData.append('attachments[]', attachment, attachment.name);
        });
      }
      formData.append('data', JSON.stringify(action.item));
      return this.httpClient.post<RedmineIssue>(`${ENDPOINT}/${action.item.id}/update`, formData, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((item: RedmineIssue) => [
      fromActions.fetchAssignedToMe(),
      fromActions.setIssue({item})
    ])
  ));
}

<div class="float-right d-none d-sm-block">
  <b>Version</b> {{ appVersion }}
  <pre class="d-none">{{ rawVersion }}</pre>
  <pre class="d-none">{{ timestamp }}</pre>
</div>
<strong>
    <span>Copyright &copy; {{ currentYear }}</span>
    <a
        href="https://www.valio.pl/"
        target="_blank"
        rel="noopener noreferrer"
        style="margin: 0"
    >
      Valio.pl</a
    >
    <span>.</span>
</strong>
<span> All rights reserved.</span>

import { Branch } from "@/model/branch.model";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import * as fromActions from '@/store/structure/structure.actions';

export interface State extends EntityState<Branch> {
  fetched: boolean;
}

export const adapter: EntityAdapter<Branch> = createEntityAdapter<Branch>({
  sortComparer: (a: Branch, b: Branch) => {
    // console.log(a.position, b.position, a.position < b.position ? -1 : 1);
    return a.position < b.position ? -1 : 1;
  }
});


export const initialState: State = {...adapter.getInitialState(), ...{ fetched: false}};

let updatePos = (state, changes) => {
  if(state.ids.length == 0) return state;
  state.ids.forEach((id) => {
    if (state.entities[id].children?.ids) {
      state = adapter.updateOne({
        id: id,
        changes: {
          children: updatePos(state.entities[id].children, changes)
        }
      }, state);
    }
  });
  return adapter.updateMany(changes.filter( c => state.ids.includes(c.id)), state);
}

let addOneInTree = (branch: Branch, path: number[], state: EntityState<Branch>) => {
  let id = path.shift();
  if (id != branch.id) {
    return adapter.updateOne({
      id: id,
      changes: {
        children: addOneInTree(branch, path, state.entities[id].children)
      }
    }, state);
  }
  return adapter.addOne(branch, state);
}

let removeOneInTree = (branch: Branch, path: number[], state: EntityState<Branch>) => {
  let id = path.shift();
  if (id != branch.id) {
    return adapter.updateOne({
      id: id,
      changes: {
        children: removeOneInTree(branch, path, state.entities[id].children)
      }
    }, state);
  }
  return adapter.removeOne(id, state);
}

let updateOneInTree = (branch: Branch, path: number[], state: EntityState<Branch>) => {
  let id = path.shift();
  if (id != branch.id) {
    return adapter.updateOne({
      id: id,
      changes: {
        children: updateOneInTree(branch, path, state.entities[id].children)
      }
    }, state);
  }
  return adapter.updateOne({id: id, changes: branch}, state);
}

export const reducer = createReducer(
  initialState,
  on(fromActions.updatePositions, (state, action) => {
    let changes = action.payload.map(item => Object.assign({}, {
      id: item.id,
      changes: {position: item.position}
    }));
    return updatePos(state, changes);
  }),
  on(fromActions.addAll, (state, action) => {
    return {...action.payload, ...{fetched: true}};
  }),
  on(fromActions.addOne, (state, action) => {
    return {...addOneInTree(action.branch, [...action.branch.path], state), ...{fetched: state.fetched}};
  }),
  on(fromActions.removeOne, (state, action) => {
    return {...removeOneInTree(action.branch, [...action.branch.path], state), ...{fetched: state.fetched}};
  }),
  on(fromActions.updateBranch, (state, action) => {
    return {...updateOneInTree(action.branch, [...action.branch.path], state), ...{fetched: state.fetched}};
  })
)

export const selectStructState = createFeatureSelector<State>('structure');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectRoot = createSelector(selectStructState, selectAll)
export const isFetched = createSelector(selectStructState, (state) => state.fetched)

import { Group, GroupMember } from "@/model/group.model";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";

import * as fromActions from "./group.actions"

export interface State extends EntityState<Group> {
  loading: boolean;
  hasMore: boolean;
  total: number;
  pages: Group[][];
  currentFetchPage: number;
}

export const adapter: EntityAdapter<Group> = createEntityAdapter<Group>({
  sortComparer: (a: Group, b: Group) => a.id < b.id ? 1 : -1
});
export const memberAdapter: EntityAdapter<GroupMember> = createEntityAdapter<GroupMember>();

export const initialState: State  = {
  loading: false,
  hasMore: true,
  total: 0,
  ids: adapter.getInitialState().ids,
  entities: adapter.getInitialState().entities,
  pages: [],
  currentFetchPage: 0
};

export const groupReducer = createReducer(
  initialState,
  // members: memberAdapter.getInitialState()

  on(fromActions.clear, () => initialState),

  on(fromActions.unlazyOneAction, (state, action) => adapter.updateOne({id: action.id, changes: {lazy: false}}, state)),

  on(fromActions.addGroupsAction, (state, action) => adapter.addMany(action.payload.map(item => { return {...item, members: memberAdapter.getInitialState() }}), state)),

  on(fromActions.addGroupsFullAction,
    (state, action) => {
      let payload = action.payload.map(item => { return {...item, members: memberAdapter.getInitialState() }});
      let newState = {...state};
      newState.pages[action.page] = payload;
      return adapter.addMany(
        payload,
        {
          ...newState,
          ...{
            total: action.total,
            loading: false
          },
        }
      );
  }),

  on(fromActions.fetchFullAction,
    (state, action) => {
      return {
        ...state,
        ...{
          loading: true,
          currentFetchPage: action.page
        }
      }
  }),


  on(fromActions.updateGroupAction, (state, action) => adapter.updateOne({
    id: action.group.id,
    changes : action.group
  }, state)),
  on(fromActions.removeGroupAction, (state, action) => adapter.removeOne(action.group.id, state)),

  on(fromActions.addMemberToGroupAction, (state, action) => adapter.updateOne({
      id: action.group.id,
      changes: {
        members: memberAdapter.addOne(action.member, state.entities[action.group.id].members)
      }}, state)
  ),
  on(fromActions.addManyMemberToGroupAction, (state, action) => adapter.updateOne({
      id: action.group.id,
      changes: {
        members: memberAdapter.addMany(action.members, state.entities[action.group.id].members)
      }}, state)
  ),
  on(fromActions.removeGroupMemberAction, (state, action) => adapter.updateOne({
      id: action.group.id,
      changes: {
        members: memberAdapter.removeOne(action.member.id, state.entities[action.group.id].members)
      }}, state)
  ),
  on(fromActions.updateGroupMemberAction, (state, action) => adapter.updateOne({
      id: action.group.id,
      changes: {
        members: memberAdapter.updateOne({id: action.member.id, changes: action.member}, state.entities[action.group.id].members)
      }}, state)
  ),
  on(fromActions.updateMyGroupMembershipAction, (state, action) => adapter.updateOne({
    id: action.group.id,
    changes : {
      membership : action.member
    }}, state)
  ),
  on(fromActions.increseGroupMemberCountAction, (state, action) => adapter.updateOne({
      id: action.group.id,
      changes : {
        memberCount : state.entities[action.group.id].memberCount ? state.entities[action.group.id].memberCount+1 : 1
    }}, state)
  ),
  on(fromActions.decreseGroupMemberCountAction, (state, action) => adapter.updateOne({
    id: action.group.id,
    changes : {
      memberCount : state.entities[action.group.id].memberCount ? state.entities[action.group.id].memberCount-1 : 0
    }}, state)
  ),
  on(fromActions.increseGroupObserverCountAction, (state, action) => adapter.updateOne({
    id: action.group.id,
    changes : {
      observersCount : state.entities[action.group.id].observersCount ? state.entities[action.group.id].observersCount+1 : 1
    }}, state)
  ),
  on(fromActions.decreseGroupObserverCountAction, (state, action) => adapter.updateOne({
    id: action.group.id,
    changes : {
      observersCount : state.entities[action.group.id].observersCount ? state.entities[action.group.id].observersCount-1 : 0
    }}, state)
  ),
);

export const selectGroupState = createFeatureSelector<State>('groups');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectEntity = createSelector(
  selectGroupState,
  (state, id: number) => state.ids.indexOf(id) >= 0 ? state.entities[id] : null
);


export const selectAllGroups = createSelector(selectGroupState, selectAll )

export const selectObservedGroups = createSelector(
  selectAllGroups,
  (state) => state.filter( (group: Group) => group.membership?.observing)
);

export const selectCount = createSelector(
  selectGroupState,
  (state) => state.total
);

export const selectCurrentFetchPage = createSelector(
  selectGroupState,
  (state) => state.currentFetchPage
);

export const selectLoading = createSelector(
  selectGroupState,
  (state) => state.loading
);

export const selectPage = createSelector(
  selectGroupState,
  (state, page) => state.pages[page] ? state.pages[page] : []
);


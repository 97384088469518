
// import Mention from 'ckeditor5/packages/ckeditor5-mention/build/mention'

import { HttpClient } from "@angular/common/http";
import { Inject } from "@angular/core";

const items = [
  { id: '@swarley', userId: '1', name: 'Barney Stinson', link: 'https://www.imdb.com/title/tt0460649/characters/nm0000439' },
  { id: '@lilypad', userId: '2', name: 'Lily Aldrin', link: 'https://www.imdb.com/title/tt0460649/characters/nm0004989' },
  { id: '@marshmallow', userId: '3', name: 'Marshall Eriksen', link: 'https://www.imdb.com/title/tt0460649/characters/nm0781981' },
  { id: '@rsparkles', userId: '4', name: 'Robin Scherbatsky', link: 'https://www.imdb.com/title/tt0460649/characters/nm1130627' },
  { id: '@tdog', userId: '5', name: 'Ted Mosby', link: 'https://www.imdb.com/title/tt0460649/characters/nm1102140' }
];

export const getFeedItems = ( queryText ) => {
  // const httpClient =  InjectorInstance.get<HttpClient>(HttpClient);
  return new Promise( resolve => {
      setTimeout( () => {
          const itemsToDisplay = items
              // Filter out the full list of all items to only those matching the query text.
              .filter( isItemMatching )
              // Return 10 items max - needed for generic queries when the list may contain hundreds of elements.
              .slice( 0, 10 );

          resolve( itemsToDisplay );
      }, 100 );
  } );

  // Filtering function - it uses the `name` and `username` properties of an item to find a match.
  function isItemMatching( item ) {
      // Make the search case-insensitive.
      const searchString = queryText.toLowerCase();

      // Include an item in the search results if the name or username includes the current user input.
      return (
          item.name.toLowerCase().includes( searchString ) ||
          item.id.toLowerCase().includes( searchString )
      );
  }
}

export const ckeConfig = {
  // editor: ClassicEditorWithMention,
  config: {
    // plugins: [Link],
    height: 500,
    language: 'pl',
    mention: {
      feeds: [
        {
          marker: '@',
          feed: getFeedItems,
          minimumCharacters: 1
        }
      ]
    },
  }
}

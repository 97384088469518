<div class="content">
  <div class="container-xl">
    <div class="card card-widget position-relative shadow mt-3 mb-4">
      <div class="overlay" *ngIf="(loading | async)">
        <div style="position: sticky; text-align: center">
          <i class="fas fa-3x fa-spinner fa-spin"></i>
          <p>Ładowanie...</p>
        </div>
      </div>
      <div [ngStyle]="backgroundStyle()"></div>
      <div class="card-header pb-1">
        <div class="user-block">
          <img class="img-circle" [src]="orderForm?.createdBy | userAvatar" alt="User Image" [appUserDisactivatable]="orderForm?.createdBy">
          <span class="username">
            <span class="text-decoration-line-through" *ngIf="!orderForm?.createdBy">Użytkownik usunięty</span>
            <a [routerLink]="['/profile', orderForm?.createdBy?.id]"  *ngIf="orderForm?.createdBy" [appUserDisactivatable]="orderForm?.createdBy">
              {{orderForm?.createdBy?.firstname}} {{orderForm?.createdBy?.lastname}}
            </a>
          </span>
          <span class="description"> {{orderForm?.createdAt | amLocale:'pl' | amCalendar }}</span>
        </div>
      </div>
      <div class="card-header pt-1" >
        <h3 *ngIf="orderForm?.id" class="text-primary card-title text-lg m-0">
          {{orderForm?.title}}
        </h3>
      </div>
      <div class="card-body" >
        <div [innerHtml]="orderForm?.description | safeHtml"></div>
      </div>
      <div class="table-responsive position-relative">
        <div class="overlay" *ngIf="state.loading" style="position: absolute; top:0; left: 0; bottom: 0; right: 0">
          <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
            <i class="fas fa-3x fa-spinner fa-spin"></i>
            <p>Ładowanie...</p>
          </div>
        </div>
        <table class="table align-middle table-hover">
          <thead>
            <tr>
              <th style="width:1rem">#</th>
              <th>NPP</th>
              <th>Utworzono</th>
              <th colspan=2>Przez</th>
              <th class="text-right"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of paginator | async; let idx = index">
              <th class="align-middle">{{idx + 1 + state.limit * (state.page - 1)}}</th>
              <th class="align-middle">{{item.npp}}</th>
              <td class="align-middle text-nowrap">{{item.createdAt | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
              <td class="align-middle pr-1 text-right" style="width: 3rem"><img class="img-circle" [src]="item.createdBy | userAvatar:25"/></td>
              <td class="align-middle pl-0 text-nowrap">
                <span class="text-decoration-line-through" *ngIf="!item?.createdBy">Użytkownik usunięty</span>
                {{item?.createdBy?.name}}
              </td>
              <td class="align-middle text-nowrap text-right" >
                <a class="btn btn-primary btn-sm elevation-2 mr-2" [routerLink]="['details', item.id]" ngbTooltip="Zobacz" checkRolePermition="orders.edit">
                  <i class="fas fa-eye fa-fw fa-lg"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
<!-- {{state | json}} -->
      <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="state?.total == 0"></nz-empty>
      <div class="card-footer" >
        <ngb-pagination
          [(page)]="state.page"
          [maxSize]="22"
          [pageSize]="state.limit"
          [collectionSize]="state.total"
          (pageChange)="load($event)">
          <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>

    </div>
  </div>
</div>

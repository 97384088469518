<section class="content">
  <div class="container-xl pt-3 pb-3">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Powiadomienia</h3>
        <!-- <div class="card-tools">
          <div class="input-group">
            <input type="text" name="table_search" class="form-control float-right" placeholder="Szukaj" #searchInput>
            <span class="input-group-append">
              <div class="input-group-text bg-transparent"><i class="fas fa-search"></i></div>
            </span>
          </div>
        </div> -->
      </div>
      <div class="contacts-list list-group">
        <a class="list-group-item list-group-item-action" *ngFor="let item of list | async; trackBy: identify"  [ngClass]="{'bg-primary' : item.important}"  (click)="link(item)">
          <div class="media">
            <div class="ribbon-wrapper ribbon-sm" *ngIf="!item?.seen">
              <div class="ribbon bg-orange text-sm">
                NOWE
              </div>
            </div>
            <div class="media-body" style="overflow: hidden;">
              <h3 class="dropdown-item-title" [ngClass]="{'text-light' : item.important, 'text-primary' : !item.important}" style="overflow: hidden; text-overflow: ellipsis;">
                {{item.title}}
              </h3>
              <p class="text-md text-truncate text-dark float-left"> {{ type(item) }}</p>
              <p class="text-sm text-muted text-nowrap float-right">
                <i class="far fa-clock mr-1"></i> {{item.createdAt | amLocale:'pl' | amCalendar}}
              </p>
            </div>
          </div>
        </a>
      </div>
      <div class="" *ngIf="(count | async) == 0">
        <p class="dropdown-item"><nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Brak powiadomień'"></nz-empty></p>
      </div>
      <div class="card-footer">
        <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="count | async">
          <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
</section>





<ul class="nav nav-tabs">
  <li class="nav-item" *ngIf="!survey?.id && !survey?.archive">
    <a class="nav-link" routerLinkActive="active bg-primary" [routerLink]="['/survey/admin/add']">
      <i class="fas fa-plus"></i> Nowa ankieta
    </a>
  </li>
  <li class="nav-item" *ngIf="survey?.id && !survey?.archive">
    <a class="nav-link"
      [class.disabled]="!survey?.id"
      routerLinkActive="active bg-primary"
      [routerLink]="['/survey/admin', survey?.id ,'edit']">
      <i class="fas fa-edit"></i> Edycja ankiety
    </a>
  </li>
  <li class="nav-item position-relative" >
    <a class="nav-link"
      [class.disabled]="!survey?.id"
      routerLinkActive="active bg-primary"
      [routerLink]="['/survey/admin', survey?.id ,'questions']">
      <i class="fas fa-list"></i>
      Pytania
      <span
          *ngIf="survey?.id"
          class="position-absolute text-md badge rounded-pill bg-orange"
          style="top: 0; right: 0; transform: translate(25%, -50%) !important;">
          {{survey?.questions?.ids?.length}}
      </span>
    </a>
  </li>
  <li class="nav-item"  *ngIf="!survey?.archive">
    <a class="nav-link"
      routerLinkActive="active bg-primary"
      [class.disabled]="!survey?.id || !survey?.questions?.ids?.length"
      [routerLink]="['/survey/admin', survey?.id ,'publish']">
      <i class="fas fa-bell "></i>
      Grupa docelowa i publikacja
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link"
      routerLinkActive="active bg-primary"
      [class.disabled]="!survey?.id || !survey?.published"
      [routerLink]="['/survey/admin', survey?.id ,'report']">
      <i class="fas fa-chart-area "></i>
      Raporty
    </a>
  </li>
</ul>

import { CallCenterQuestionnaire } from '@/model/call-center.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Data } from '@angular/router';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-call-center-raport',
  templateUrl: './call-center-raport.component.html',
  styleUrls: ['./call-center-raport.component.scss']
})
export class CallCenterRaportComponent implements OnInit {
  constructor(
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute
  ) {
    this.filterForm = new FormGroup({
      date: new FormControl([
        DateTime.now().minus({month: 2}).startOf('day').toJSDate(),
        DateTime.now().endOf('day').startOf('second').toJSDate()
      ]),
    });
  }

  questionnaire: CallCenterQuestionnaire;
  filterForm: FormGroup;
  loading = false;

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data: Data) => {
      this.questionnaire = data.instance;
    })
  }

  getRaport() {
    this.loading = true;
    let params = new HttpParams();
    const start = DateTime.fromJSDate(this.filterForm.value.date[0]);
    const end = DateTime.fromJSDate(this.filterForm.value.date[1]);
    params = params.set('start', encodeURIComponent(start.toFormat('yyyy-MM-dd HH:mm:ss')));
    params = params.set('end', encodeURIComponent(end.toFormat('yyyy-MM-dd HH:mm:ss')));
    this.httpClient.get<any[]>(`<backendhost>/v1/call-center-questionnaire/${this.questionnaire.id}/raport`, {
      observe: 'body',
      responseType: 'json',
      params
    }).subscribe(result => this.generateCSV(result))

  }

  generateCSV(result) {
    this.loading = false;
    let title = `Raport z ${this.questionnaire.name}`;
    let options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: ',',
      showLabels: true,
      // showTitle: true,
      title: title,
      // useBom: true,
      // noDownload: true,
      headers: result.headers,
      useHeader: false,
      nullToEmptyString: true,
    };
    new AngularCsv(result.list, title, options);
  }
}

import { ReservationAsset, ReservationCategory } from '@/model/reservation.model';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import * as fromActions from '@/store/reservations/reservations.actions'
import * as fromReducers from '@/store/reservations/reservations.reducers'
import { NgZeeTimeTableData, NgZeeTimeTableOptions, NgZeeTimeTableSelect } from './timetable/timetable.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReservationModalComponent } from './reservation-modal/reservation-modal.component';
import moment from 'moment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-view-reservation-categories',
  templateUrl: './view-reservation-categories.component.html',
  styleUrls: ['./view-reservation-categories.component.scss']
})
export class ViewReservationCategoriesComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  category: ReservationCategory;

  options: NgZeeTimeTableOptions = {
    pxPer5min: 10,
    element: {
      background: '#f97c7c', titleColor: 'white', subTitleColor: '#862424'
    },
    rowLabel: {
      background: '#d83d3d',
      labelColor: 'white'
    }
  };

  constructor(
    private store: Store<fromReducers.State>,
    private activatedRoute: ActivatedRoute,
    protected modalService: NgbModal,
    protected httpClient: HttpClient
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.pipe(
      map((params: ParamMap) => parseInt(params.get('id')))
    ).subscribe( (id: number) => {
      this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectOneAdminReservationCategory,id)).subscribe((result) => {
        if(!result) {
          this.store.dispatch(fromActions.fetchOneReservationCategory({id}));
        }
        if (!result)return;
        this.category = result;
        this.onValueChange(new Date());
      });
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get saving(): Observable<boolean> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectReservationState), map( (state) => state.admin.saving));
  }

  get loading(): Observable<boolean> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectReservationState), map( (state) => state.admin.loading));
  }

  get timetableLabels(): ReservationAsset[] {
    return this.category ? Object.values(this.category.assets.entities) : [];
  }

  loadingTimetable = false;
  onValueChange(value: Date): void {
    this.loadingTimetable = true;
    this.currentDate = value;
    let params = new HttpParams();
    params = params.set('category', this.category.id);
    params = params.set('day', moment(value).format('YYYY-MM-DD'));
    this.httpClient.get<any>('<backendhost>/v1/reservation', {
      params: params,
      observe: 'body',
      responseType: 'json'
    }).subscribe((data) => {
      this.data = data;
      this.loadingTimetable = false;
    })
    this.httpClient.get<any>('<backendhost>/v1/reservation/0/count', {
      params: params,
      observe: 'body',
      responseType: 'json'
    }).subscribe((data) => {
      this.counsts = data;
    })
  }

  counsts: any;

  onReservationDeleted(item: ReservationAsset) {
    let idx = this.counsts.map(x => `${x.day} ${x.id}`).indexOf(moment(this.currentDate).format('YYYY-MM-DD') + ' ' + item.id);
    if (idx < 0)return;
    this.counsts[idx].count--;
    if (this.counsts[idx].count <= 0) {
      this.counsts.splice(idx, 1);
    }
  }

  onPanelChange(change: { date: Date; mode: string }): void {
    // console.log(`Current value: ${change.date}`);
    // console.log(`Current mode: ${change.mode}`);
  }

  currentDate: Date;
  addReservation(event: NgZeeTimeTableSelect) {
    this.currentDate = this.currentDate ? this.currentDate : new Date();
    let startDate = moment(
      this.currentDate.getFullYear() + "-" +
      (this.currentDate.getMonth() + 1) + "-" +
      this.currentDate.getDate() + " " +
      (event.time ? event.time : '09:00')
    );
    let endDate = startDate.clone();
    endDate.add(4,'hours');

    const modalRef = this.modalService.open(ReservationModalComponent, {size: 'xl', scrollable: false});
    modalRef.componentInstance.assets = this.timetableLabels;
    modalRef.componentInstance.currentDate = this.currentDate;
    modalRef.componentInstance.form.patchValue({
      asset: event.asset.id,
      startDate: startDate.format('YYYY-MM-DDTHH:mm:ssZ'),
      endDate: endDate.format('YYYY-MM-DDTHH:mm:ssZ')
    });
    modalRef.componentInstance.updateForm();
    // Object.values(modalRef.componentInstance.controls).forEach( (input: FormControl) => {
    //   input.markAllAsTouched();
    //   input.updateValueAndValidity();
    //   // console.log(input);
    // });
    modalRef.result
    .then(() => {
      this.onValueChange(this.currentDate);
    })
  }

  data: NgZeeTimeTableData = {
  };

  // days: string[] = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
}

<section class="content">
  <div class="container">
    <div class="card card-primary">
      <div class="card-header d-flex align-items-center">
        <h3 class="card-title m-0 flex-grow-1 text-bold"><i class="fas fa-question fa-fw"></i> {{multimedia?.name}}</h3>
      </div>
      <div style="min-height: 10rem; position: relative;">
        <div *ngIf="slide && slideResult">
          <app-content-player
                *ngIf="slide.type == 'content'"
                [result]="slideResult"
                [slide]="slide"
                (onFinish)="onFinish($event)"
                ></app-content-player>
          <app-scorm-player
                *ngIf="slide.type == 'scorm'"
                [result]="slideResult"
                [scorm]="slide.scorm"
                [dontFinish]="true"
                (onFinish)="onFinish($event)"
                (onClose)="onFinish($event)"
                ></app-scorm-player>
          <app-youtube-player
                *ngIf="slide.type == 'youtube'"
                [result]="slideResult"
                [slide]="slide"
                (onFinish)="onFinish($event)"></app-youtube-player>
          <app-video-player
                *ngIf="slide.type == 'video'"
                [result]="slideResult"
                [slide]="slide"
                (onFinish)="onFinish($event)"></app-video-player>
          <app-quiz-player
                *ngIf="slide.type == 'quiz'"
                [result]="slideResult"
                [quiz]="slide.quiz"
                (onFinish)="onFinish($event)"
                ></app-quiz-player>
        </div>
        <div *ngIf="!slide" class="p-4 text-center" style="min-height: 40vh;">
          <h3 class="p-3">Szkolenie zakończone</h3>
          <!-- <a [routerLink]="['/training', invitation.id]" class="btn btn-primary btn-lg">
            <i class="fas fa-check"></i>
            Zakończ
          </a> -->
        </div>
        <div class="overlay" *ngIf="slide && !slideResult" style="position: absolute; top:0; left: 0; bottom: 0; right: 0;">
          <div style="position: sticky; text-align: center">
            <i class="fas fa-3x fa-spinner fa-spin"></i>
            <p>Ładowanie...</p>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button *ngIf="slideIndex" (click)="back()" class="btn btn-primary">
          <i class="fas fa-chevron-left"></i>
          Poprzedni
        </button>
        <button
              *ngIf="slide"
              (click)="next()"
              class="btn float-right"
              [ngClass]="{
                'btn-primary' : slideResult?.completedOn,
                'btn-secondary' : !slideResult?.completedOn
              }"
              [disabled]="!slideResult?.completedOn"
              >
          Następny
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
    <button class="btn btn-primary" (click)="reset()">Resetuj</button>
    <p>&nbsp;</p>
  </div>
</section>


<aside  class="control-sidebar control-sidebar-light" style="display: block" [ngStyle]="sidebarStyle()">
  <a
    (click)="sidebarToggle()"
    class="btn btn-secondary"
    style="transform: translate(-100%, 66px);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    position: absolute;">
    <i class="fas fa-list"></i>
  </a>
  <div class="control-sidebar-content">
    <h5 class="p-3 ">Lista slajdów</h5>
    <div class="list-group">
      <a
        *ngFor="let item of multimedia.slides; let idx = index;"
        class="list-group-item list-group-item-action"
        (click)="setIdx(idx)"
        [class.disabled]="!slideResults[idx]?.completedOn"
        routerLinkActive="active"
        >
        <span class="fa-stack fa-1x">
          <i class="far fa-square fa-stack-2x"></i>
          <i class="fas fa-check text-success fa-stack-2x" *ngIf="slideResults[idx]?.passed == true"></i>
          <i class="fas fa-times text-danger fa-stack-2x" *ngIf="slideResults[idx]?.passed == false"></i>
          <i class="fas fa-check text-info fa-stack-2x" *ngIf="slideResults[idx]?.completedOn && slideResults[idx]?.passed == null"></i>
        </span>
        {{item.name}}
      </a>
    </div>
  </div>
</aside>

<!-- <ng-template #rt let-r="result" let-t="term">
  <div class="d-flex flex-md-row" (click)="onClick(r)">
    <div class="user-panel mr-2 d-flex" style="flex-shrink: 0;">
      <img [src]="r | groupAvatar:45:true:'name':'image'" class="img-circle  align-self-center">
    </div>
    <div>
      <p class="p-0 m-0 text-xs text-muted">{{typeName(r)}}</p>
      <p class="p-0 m-0"><ngb-highlight class="align-self-center text-md" [result]="r.name" [term]="t"></ngb-highlight>
      <p class="p-0 m-0">
        <ngb-highlight
          class="align-self-center text-sm"
          style="white-space: break-spaces;max-height:45px; line-height: 15px;overflow: hidden; display: block;"
          [result]="r.content | truncHighlight"
          [term]="t"></ngb-highlight>
      </p>
    </div>
  </div>
</ng-template> -->

<li class="nav-item" appClickOutside (clickOutside)="clikedOutise()">
  <a class="nav-link position-relative elevation-2 img-circle img-size-40 bg-white text-center"  (click)="clikedOpen()" role="button">
    <i class="fas fa-search"></i>
  </a>
  <div class="navbar-search-block bg-primary" *ngIf="show" style="display: flex;">
    <form class="form-inline elevation-1">
      <div class="input-group input-group-sm">
        <input
          class="form-control form-control-navbar"
          type="search"
          placeholder="Szukaj"
          aria-label="Search"
          [(ngModel)]="term"
          (ngModelChange)="search(term)"
          [ngModelOptions]="{standalone: true}"
        >
        <div class="input-group-append">
          <button class="btn btn-navbar" type="submit">
            <i class="fas fa-search"></i>
          </button>
          <button class="btn btn-navbar" type="button" data-widget="navbar-search" (click)="clear()">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
    </form>
    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right show elevation-3"

      *ngIf="open | async"
      infinite-scroll
      [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollUpDistance]="scrollUpDistance"
      [infiniteScrollThrottle]="throttle"
      [immediateCheck]="true"
      (scrolled)="onScroll()"
      (scrolledUp)="onScrollUp()">
      <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(results | async).length == 0" class="pb-4"></nz-empty>
      <div class="overlay" *ngIf="isLoading | async">
        <div style="position: sticky; top:50%; left:50%; align-self: baseline;text-align: center; padding: 25px 0">
          <i class="fas fa-3x fa-spinner fa-spin"></i>
          <p>Ładowanie...</p>
        </div>
      </div>
      <a class="dropdown-menu-item position-relative dropdown-item" *ngFor="let item of results | async">
          <div class="d-flex flex-md-row" (click)="onClick(item)">
            <div class="user-panel mr-2 d-flex" style="flex-shrink: 0;">
              <img [src]="item | groupAvatar:45:true:'name':'image':'uid'" class="img-circle  align-self-center">
            </div>
            <div>
              <!-- <pre>{{item.id}}  :: {{item.score}}</pre> -->
              <p class="p-0 m-0 text-xs text-muted">{{typeName(item)}}</p>
              <p class="p-0 m-0 text-body"><ngb-highlight class="align-self-center text-md" [result]="item.name" [term]="item"></ngb-highlight></p>
              <p class="p-0 m-0 text-body">
                <ngb-highlight
                  class="align-self-center text-sm text-body"
                  style="white-space: break-spaces;max-height:45px; line-height: 15px;overflow: hidden; display: block;"
                  [result]="item.content | truncHighlight"
                  [term]="item"></ngb-highlight>
              </p>
              <p class="p-0 m-0 text-xs text-muted">{{item.createdAt | amLocale:'pl' | amCalendar }}</p>
            </div>
          </div>
      </a>
      <div class="dropdown-divider" *ngIf="hasMore | async"></div>
      <a (click)="onScroll()" *ngIf="hasMore | async" class="dropdown-item dropdown-footer text-center text-dark">więcej...</a>
    </div>
  </div>
</li>

<div class="content">
  <div class="container pt-3 pb-3">
    <div class="card card-primary card-outline" style="min-height: 150px;">
      <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="!(isLoading | async) && (results | async).length == 0" class="pb-4"></nz-empty>
      <div class="overlay" *ngIf="(isLoading | async)  && (results | async).length == 0">
        <div style="position: sticky; top:50%; left:50%; align-self: baseline;text-align: center; padding: 25px 0">
          <i class="fas fa-3x fa-spinner fa-spin"></i>
          <p>Ładowanie...</p>
        </div>
      </div>
      <div class="card-header">
        <p class="card-title text-md">Znaleziono <strong>{{(total | async)}}</strong> wyników</p>
      </div>
      <div class="contacts-list list-group">
        <a *ngFor="let item of results | async" class="list-group-item list-group-item-action">
          <div class="d-flex flex-md-row" (click)="onClick(item)">
            <div class="user-panel mr-2 d-flex" style="flex-shrink: 0;">
              <img [src]="item | groupAvatar:45:true:'name':'image':'uid'" class="img-circle  align-self-center">
            </div>
            <div>
              <p class="p-0 m-0 text-xs text-muted">{{typeName(item)}}</p>
              <p class="p-0 m-0 text-body"><ngb-highlight class="align-self-center text-md" [result]="item.name" [term]="item"></ngb-highlight></p>
              <p class="p-0 m-0 text-body">
                <ngb-highlight
                  class="align-self-center text-sm text-body"
                  style="white-space: break-spaces;max-height:45px; line-height: 15px;overflow: hidden; display: block;"
                  [result]="item.content | truncHighlight"
                  [term]="item"></ngb-highlight>
              </p>
            </div>
          </div>
        </a>
        <button
          type="button"
          class="btn btn-block btn-outline-primary btn-block"
          *ngIf="(hasMore | async)"
          (click)="loadMore()"
          [ngClass]="{'disabled' : (isLoading | async)}">
          <i class="fas" [ngClass]="{'fa-spinner fa-spin' : (isLoading | async), 'fa-sync' : !(isLoading | async)}"></i>
          załaduj więcej
        </button>
        <!-- <pre>{{ hasMore | async}}</pre>
        <pre>{{ total | async}}</pre>
        <pre>{{ (results | async).length}}</pre> -->
      </div>
      <!-- <div class="card-footer" >
        <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="total | async" (pageChange)="pageChange($event)">
          <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div> -->
  </div>
</div>

<section class="content">
  <div class="container">
    <app-survey-admin-tab></app-survey-admin-tab>
    <div class="card card-primary" style="border-top-left-radius: 0;border-top-right-radius: 0;">

      <div class="table-responsive">
        <table class="table align-middle table-hover">
          <thead>
            <tr>
              <th style="width:1rem">#</th>
              <th>Pytanie</th>
              <th>Rodzaj</th>
              <th>Odpowiedzi</th>
              <th class="text-right" >
                <button *ngIf="!survey.archive" class="btn btn-success btn-sm elevation-2 text-nowrap" (click)="addQuestion()" [disabled]="survey.published">
                  <i class="fas fa-plus fa-fw fa-lg"></i> Dodaj
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of questions; let idx = index; ">
              <td>{{idx+1}}</td>
              <td>{{item.label}}</td>
              <td>{{ getTypeName(item) }}</td>
              <td>
                <ol *ngIf="checkMultiple(item)">
                  <li *ngFor="let option of item.options">
                    {{option.label}}
                    <i class="fas fa-check fa-fw text-success" *ngIf="option.correct"></i>
                  </li>
                </ol>
              </td>
              <td class="text-right text-nowrap"  style="width:1rem">
                <button *ngIf="!survey.archive" class="btn btn-primary btn-sm elevation-2 mr-2" (click)="editQuestion(item)" ngbTooltip="Edycja" [disabled]="survey.published">
                  <i class="fas fa-edit fa-fw fa-lg"></i>
                </button>
                <button
                  *ngIf="!survey.archive"
                  class="btn btn-danger btn-sm elevation-2"
                  nz-popconfirm
                  nzPopconfirmTitle="Czy na pewno usunąć?"
                  nzOkText="Tak"
                  nzCancelText="Nie"
                  nzPopconfirmPlacement="left"
                  ngbTooltip="Usuń"
                  (nzOnConfirm)="deleteQuestion(item)"
                  [disabled]="survey.published">
                  <i class="fas fa-trash fa-fw fa-lg"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="!questions?.length"></nz-empty>
      </div>
    </div>
  </div>
</section>

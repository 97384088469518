export enum AllOrCreated {
  NONE,
  CREATED,
  USER,
  BRANCH,
  ALL,

}


export interface Role {
  id: string;
  name: string;
  default: boolean;
  root: boolean;
  privileges : {
    news : {
      view: boolean,
      create: boolean,
      publicate: {
        all : boolean,
        group: boolean,
        myGroup: boolean,
        sms: boolean,
        push: boolean
        important: boolean,
      },
      edit: AllOrCreated,
      delete: AllOrCreated,
    },
    comments: {
      delete: AllOrCreated
    },
    groups: {
      list: boolean,
      create: boolean,
      edit: AllOrCreated,
      delete: AllOrCreated,
      superior: {
        create: boolean,
        edit: AllOrCreated,
        delete: AllOrCreated,
      }
    },
    chat: {
      room: {
        create: boolean,
        edit: AllOrCreated,
        delete: AllOrCreated,
      }
    },
    messages: {
      send: {
        all : boolean,
        group: boolean,
        myGroup: boolean,
        sms: boolean,
        push: boolean
      }
    }
    users: {
      list: boolean,
      create : boolean,
      edit: boolean,
      delete: boolean,
      password: boolean,
      role: boolean,
      prize: AllOrCreated
    },
    structure: {
      list: boolean,
      create : boolean,
      edit: boolean,
      delete: boolean
    },
    offers: {
      list: boolean,
      candidates: boolean,
      create : boolean,
      edit: boolean,
      delete: boolean,
      category: boolean,
    },
    orders: {
      list: boolean,
      // view: boolean,
      order: boolean,
      create: boolean,
      edit: boolean,
      delete: boolean
    },
    kb: {
      list: boolean,
      admin: {
        folders: {
          create: boolean,
          edit: boolean,
          delete: boolean
        },
        articles: {
          create: boolean,
          edit: boolean,
          delete: boolean
        },
        files: {
          create: boolean,
          edit: boolean,
          delete: boolean
        }
      }
    },
    ideas: {
      list: boolean,
      create: boolean,
      edit: boolean,
      delete: AllOrCreated,
    },
    prizes: {
      list: boolean,
      create: boolean,
      edit: boolean,
      delete: boolean,
    },
    reservations: {
      list: boolean,
      reservation: {
        create: boolean,
        delete: AllOrCreated,
      },
      admin: {
        category: {
          create: boolean,
          edit: boolean,
          delete: boolean,
        },
        assets: {
          create: boolean,
          edit: boolean,
          delete: AllOrCreated,
        },
      },
    },
    training: {
      view: boolean,
      admin: {
        scorm: boolean
      }
    },
    survey: {
      view: boolean,
      admin: boolean
    },
    outpost: {
      view: boolean,
      admin: boolean,
      slot : {
        sign: boolean
        unsign: AllOrCreated
      }
    },
    vacant: {
      view: boolean,
      create: boolean,
      createMy: boolean,
      edit: AllOrCreated,
      delete: AllOrCreated,
      viewAll: boolean,
      reservation: {
        create: boolean,
        delete: AllOrCreated,
      }
    },
    roles: {
      admin: boolean,
      delete: boolean
    },
    redmine: {
      view: boolean,
      // edit: AllOrCreated,
      admin: boolean
    },
    callcenter: {
      view: AllOrCreated,
      fill: boolean,
      fillEdit: AllOrCreated,
      fillDelete: AllOrCreated,
      create: boolean,
      edit: AllOrCreated,
      delete: AllOrCreated,
    },

    statistics: {
      view: boolean
    }
  }
}

import { PaginationDTO } from "@/model/pagination.model";
import { Training } from "@/model/scorm.model";
import { createAction, props } from "@ngrx/store";

export const fetch = createAction("Training fetch");
export const fetchOne = createAction("Training fetchOne", props<{id: number}>());
export const clear = createAction("Training clear");
export const setTrainings = createAction( "Training setTrainings",
  props<PaginationDTO<Training>>()
);
export const addTraining = createAction( "Training addTraining",
  props<{training: Training}>()
);
export const setPage = createAction("Training setPage", props<{ page: number }>());
export const setOrder = createAction("Training setOrder", props<{ order: string }>());
export const setDirection = createAction("Training setDirection", props<{ asc: boolean }>());
export const setSearch = createAction("Training setSearch", props<{ search: string }>());

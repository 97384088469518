import { AuthService } from '@/auth/auth.service';
import { User } from '@/model/user.model';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import * as fromAuthActions from '@/store/auth/auth.actions';
import * as fromAuthReducers from '@/store/auth/auth.reducers';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { tinyMceConfig } from '@/config/tinymce.config';


@Component({
  selector: 'app-edit-account',
  templateUrl: './edit-account.component.html',
  styleUrls: ['./edit-account.component.scss']
})
export class EditAccountComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  user: User;
  tags= [];
  uploading = false;
  tinyMceConfig = {...tinyMceConfig, ...{
    init : {...tinyMceConfig.init, ...{height: 250}}
  }};

  constructor(
    private toastr: ToastrService,
    private store: Store<fromAuthReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {

    this.form = new FormGroup({
      firstname: new FormControl(null, Validators.required),
      lastname: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email],[this.checkEmailUniqe.bind(this)]),
      position: new FormControl(null),
      phone: new FormControl(null),
      hiredAt: new FormControl(null),
      physicalLocation: new FormControl(null),
      aboutMe: new FormControl(null),
      // password: new FormControl(null, [Validators.required]),
      // confirmPassword: new FormControl(null, [Validators.required, this.checkPasswords]),
    });
    this.form.controls.email.disable();
    this.authService.getUser().then( (user: any) => {
        this.user = user;
        let userModel = {...user, ...{ hiredAt: null}};
        if (user.hiredAt) {
          let hiredAtDate = new Date(user.hiredAt);

          userModel.hiredAt = {
            year: hiredAtDate.getUTCFullYear(),
            month: hiredAtDate.getUTCMonth() + 1,
            day: hiredAtDate.getDate()
          }

        }
        if (user.tags) {
          this.tags = user.tags.map(x => { return {value : x, display: x}});
        }
        this.form.patchValue(userModel);
    })

    this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromAuthReducers.selectAuthState),
      map( (state: fromAuthReducers.State) => state.profile),
    ).subscribe( (user:User) => {this.user = user})
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => {
    let pass = this.form?.get('password')?.value;
    let confirmPass = this.form?.get('confirmPassword')?.value
    return pass === confirmPass ? null : { notSame: true }
  }

  private prevEmailValue: string;
  checkEmailUniqe(control: AbstractControl)/* : Observable<ValidationErrors>*/ {
    if(this.user.email == control.value)
      return of(null);
    if(this.prevEmailValue == control.value && control.errors === null)
      return of(null);
    const subject = new BehaviorSubject<string>('');
    return subject.asObservable().pipe(
      takeUntil(this.unsubscribe$),
      distinctUntilChanged(),
      debounceTime(1000),
      switchMap(() => this.authService.checkEmailUniqe(control.value)),
      map(result => {
        if(result) {
          this.prevEmailValue = null;
          control.setErrors({notUniqe: true});
          control.markAsTouched();
          return { notUniqe: true };
        }
        else {
          this.prevEmailValue = control.value;
          control.setErrors(null);
          control.markAllAsTouched();
          return null;;
        }
      })
    )
  }

  get disabled(): Observable<boolean>  {
    return this.store.pipe(takeUntil(this.unsubscribe$),select(fromAuthReducers.selectAuthLoading));
  }

  save(): void {


    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    let valid = this.form.valid;
    this.disabled.pipe(takeUntil(this.unsubscribe$),take(1)).subscribe( (disabled: boolean) => {
      if (valid && !disabled) {
        let hiredAt = this.form.value.hiredAt?.year ? `${this.form.value.hiredAt.year}-${this.form.value.hiredAt.month}-${this.form.value.hiredAt.day}` : null;
        let model = {...this.form.value, ...{ hiredAt: hiredAt, tags: this.tags.map(x => x.value)}};
        this.store.dispatch(fromAuthActions.setLoadingAction());
        this.actionsSubject$.pipe(
          takeUntil(this.unsubscribe$),
          filter((action) => action.type === fromAuthActions.setLoadingAction.type),
        ).subscribe( (action:any) => {
          this.toastr.success("Zapisano zmiany");
          this.router.navigate(['/', 'profile'])
        })
        this.store.dispatch(fromAuthActions.editProfileAction({profile: model}));
      } else if(!disabled) {
        this.toastr.error("Formularz zawiera błędy");
        console.log(this.form.controls);
      } else {
        this.toastr.warning("Trwa zapisywanie");
      }

    })
  }

  handleImageUploadChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.uploading = true;
        break;
      case 'done':
        this.store.dispatch(fromAuthActions.setProfileAction({profile: info.file.response}));
        // this.store.pipe(take(1),select(fromUsersReducers.selectOneUser, this.user.id)).subscribe((user: User | null) => {
        //   this.user = user;
        // })
        this.uploading = false;
        break;
      case 'error':
        this.toastr.error('Błąd uploadu pliku');
        this.uploading = false;
        break;
    }
  }

  handleBackgroundUploadChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.uploading = true;
        break;
      case 'done':
        this.store.dispatch(fromAuthActions.setProfileAction({profile: info.file.response}));
        this.uploading = false;
        break;
      case 'error':
        this.toastr.error('Błąd uploadu pliku');
        this.uploading = false;
        break;
    }
  }

}

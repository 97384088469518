<section class="content">
  <div class="container">
    <div class="card">
      <div class="card-header d-flex align-items-center">
        <h3 class="card-title m-0 flex-grow-1 text-bold"><i class="fas fa-chart-area fa-fw"></i> Raporty</h3>
        <div class="card-tools flex-grow-1">
        </div>
      </div>
      <form [formGroup]="form" (ngSubmit)="report()">
      <div class="card-footer">
        <div class="form-group">
          <label for="typeahead-prevent-manual-entry">Dodaj użytkownika do raportu</label>
          <ng-template #usersElement let-r="result" let-t="term">
            <div class="d-flex flex-md-row">
            <div class="mr-2">
              <!-- <img [src]="r.image" class="img-circle"> -->
              <img class="img-circle elevation-3" style="height: 45px;width: 45px;" [src]="r | userAvatar" alt="User Avatar"/>
            </div>
            <ngb-highlight class="align-self-center" [result]="formatter(r)" [term]="t"></ngb-highlight>
            </div>
          </ng-template>
          <input id="typeahead-prevent-manual-entry"  type="text" class="form-control" placeholder="Zacznij wpisywać"
              [(ngModel)]="typeaheadUserModel"
              [ngModelOptions]="{standalone: true}"
              [ngbTypeahead]="searchUsers"
              [resultTemplate]="usersElement"
              [inputFormatter]="formatterEmpty"
              [editable]='false'
              (selectItem)="onUsersTypeaheadSelect($event)"
              (focus)="focus$.next($any($event).target.value)"
              (click)="click$.next($any($event).target.value)"
              #instanceUser="ngbTypeahead"
              [appIsValid]="form.controls.users"
            />
            <div class="invalid-feedback" *ngIf="form.controls.users.errors?.required">
              Pole jest wymagane
            </div>
          <ul class="list-group mt-3">
            <li *ngFor="let item of typeaheadUsersList; let idx = index" class="list-group-item ">
              <div class="d-flex align-items-center">
                <img class="img-circle elevation-3" [src]="item | userAvatar" alt="{{formatter(item)}}" [appUserDisactivatable]="item"/>
                <p class="pl-3 flex-grow-1  m-0" [appUserDisactivatable]="item">{{formatter(item)}}</p>
                <button class="btn btn-danger btn-sm p-1 elevation-2" (click)="removeUser(item)">
                  <i class="fas fa-times fa-fw fa-lg"></i>
                </button>
              </div>
            </li>
          </ul>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>Raport z </label>
              <div class="form-check">
                <input class="form-check-input" type="radio" formControlName="type" value="invited" id="typeInvited">
                <label class="form-check-label" for="typeInvited"> Zaproszonych</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" formControlName="type" value="started" id="typeStarted">
                <label class="form-check-label" for="typeStarted"> Rozpoczętych</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" formControlName="type" value="uncompleted" id="typeUncompleted">
                <label class="form-check-label" for="typeUncompleted"> Nieukończonych</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" formControlName="type" value="completed" id="typeCompleted">
                <label class="form-check-label" for="typeCompleted"> Ukończonych</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" formControlName="type" value="passed" id="typePassed">
                <label class="form-check-label" for="typePassed"> Zaliczonych</label>
              </div>
            </div>
          </div>
          <div class="col">


            <div class="form-group">
              <label>Data zaproszenia</label>
              <div class="input-group">
                <div class="input-group-text">
                  <input type="checkbox" formControlName="invitationRangeOn">
                </div>
                <nz-range-picker formControlName="invitationRange" [nzDisabled]="!this.form.controls.invitationRangeOn.value"></nz-range-picker>
              </div>
              <div class="text-danger" *ngIf="form.controls.invitationRange.errors?.dateNotValid">
                Data jest nieprawidłowa
              </div>
            </div>

            <div class="form-group">
              <label>Data ukończenia</label>
              <div class="input-group">
                <div class="input-group-text">
                  <input type="checkbox" formControlName="completedRangeOn">
                </div>
                <nz-range-picker formControlName="completedRange" [nzDisabled]="!this.form.controls.completedRangeOn.value"></nz-range-picker>
              </div>
              <div class="text-danger" *ngIf="form.controls.completedRange.errors?.dateNotValid">
                Data jest nieprawidłowa
              </div>
            </div>


          </div>
          <div class="col">

            <div class="form-group">
              <label>Termin realizacji szkoleni</label>
              <div class="input-group">
                <div class="input-group-text">
                  <input type="checkbox" formControlName="endDateRangeOn">
                </div>
                <nz-range-picker formControlName="endDateRange" [nzDisabled]="!this.form.controls.endDateRangeOn.value"></nz-range-picker>
              </div>
              <div class="text-danger" *ngIf="form.controls.endDateRange.errors?.dateNotValid">
                Data jest nieprawidłowa
              </div>
            </div>

          </div>

        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary float-left" type="submit">
          <i class="fas fa-check fa-fw"></i>
          Wygeneruj raport
        </button>
        <a class="btn btn-secondary float-right" [class.disabled]="result.length == 0" (click)="generateCSV()">
          <i class="fas fa-check fa-fw"></i>
          Export do CSV
        </a>
      </div>
      </form>
      <div class="table-responsive position-relative">
        <table class="table align-middle table-hover text-sm">
          <thead>
            <tr>
              <th style="width:1rem">#</th>
              <th>Imię</th>
              <th>Nazwisko</th>
              <th>Email</th>
              <th>Szkolenie</th>
              <th>Data zaproszenia</th>
              <th>Data rozpoczęcia</th>
              <th>Data ukończenia</th>
              <th>Termin realizacji szkoleni</th>
              <th>Ukończone szkolenia</th>
              <th>Liczba szkoleń</th>
              <th>Zaliczone</th>
              <th>Punktów</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of pagination; let idx = index">
              <th class="align-middle">{{idx + 1 + (page-1)*pageSize}}</th>
              <td class="align-middle">{{item.firstname}}</td>
              <td class="align-middle">{{item.lastname}}</td>
              <td class="align-middle">{{item.email}}</td>
              <td class="align-middle">{{item.training}}</td>
              <td class="align-middle">{{item.invitedAt }}</td>
              <td class="align-middle">{{item.startedAt }}</td>
              <td class="align-middle">{{item.completedAt }}</td>
              <td class="align-middle">{{item.endDateAt }}</td>
              <td class="align-middle">{{item.trainingsCompleted }}</td>
              <td class="align-middle">{{item.trainingsTotal }}</td>
              <td class="align-middle">{{item.passed }}</td>
              <td class="align-middle">{{item.score }}</td>
            </tr>
          </tbody>
        </table>
        <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="result.length == 0"></nz-empty>
        <div class="overlay" *ngIf="loading" style="    position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;">
          <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
            <i class="fas fa-3x fa-spinner fa-spin"></i>
            <p>Ładowanie...</p>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="result.length" class="float-left">
          <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
</section>





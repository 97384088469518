import { createPaginationInitialState, Pagination } from "@/model/pagination.model";
import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import * as actions from "./call-center.actions";
import { CallCenterFill } from "@/model/call-center.model";
import { generalError } from "../error.store";

export interface State extends Pagination<CallCenterFill> {
  type: string
}

export const adapter: EntityAdapter<CallCenterFill> = createEntityAdapter<CallCenterFill>();

export const initialState: State = {
  ...{
    type: 'my'
  },
  ...createPaginationInitialState(adapter, false)
};

export const reducer = createReducer(
  initialState,
  on(actions.clear, (state) => initialState),
  on(actions.fetch, (state) => { return {...state, ...{loading: true}}}),
  on(actions.fetchOne, (state) => { return {...state, ...{loading: true}}}),
  on(actions.setPage, (state, action) => { return {...state, ...{page: action.page}}}),
  on(actions.setOrder, (state, action) => { return {...state, ...{order: action.order}}}),
  on(actions.setDirection, (state, action) => { return {...state, ...{asc: action.asc}}}),
  on(actions.setSearch, (state, action) => { return {...state, ...{search: action.search}}}),
  on(actions.setType, (state, action) => { return {...state, ...{type: action.fillType}}}),

  on(actions.createQuestionnaireFill, (state) => { return {...state, ...{saving: true}}}),
  on(actions.createQuestionnaireFillSuccess, (state) => { return {...state, ...{saving: false}}}),
  on(actions.updateQuestionnaireFill, (state) => { return {...state, ...{saving: true}}}),
  on(actions.deleteQuestionnaireFill, (state) => { return {...state, ...{saving: true}}}),
  // on(actions.deleteQuestionnaireFill, (state) => { return {...state, ...{saving: true}}}),
  on(generalError, (state) => { return {...state, ...{saving: false}}}),


  on(actions.setMany, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: action.total,
        list: adapter.addMany(action.list, initialState.list)
      }
    }
  }),
  on(actions.addMany, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: action.total,
        list: adapter.addMany(action.list, state.list)
      }
    }
  }),
  on(actions.addOne, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: state.total + 1,
        list: adapter.addOne(action.item, state.list)
      }
    }
  }),
  on(actions.setOne, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.updateOne({ id: action.item.id, changes: action.item}, state.list)
      }
    }
  }),
  on(actions.removeOne, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        saving: false,
        total: state.total - 1,
        list: adapter.removeOne(action.id, state.list)
      }
    }
  })
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectState = createFeatureSelector<State>('call-center-fill');
export const selectAllFills = createSelector(selectState, (state) => selectAll(state.list) )
export const selectOneFill = createSelector(selectState, (state: State, id: number) => state.list.entities[id]);

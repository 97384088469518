import { Folder } from '@/model/folder.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import * as fromKbActions from '@/store/kb/kb.actions';
import * as fromKbReducers from '@/store/kb/kb.reducers';
import { select, Store } from '@ngrx/store';
import { KbFile } from '@/model/file.model';
import { takeUntil, tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import * as fileSaver from 'file-saver';
import { KbArticle } from '@/model/article.model';

@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.scss']
})
export class FolderComponent implements OnInit, OnDestroy  {
  unsubscribe$ = new Subject<boolean>();
  private filesFetched = false;
  folderViewType = "box";

  constructor(
    private store: Store<fromKbReducers.State>,
    protected actions$: Actions,
    protected httpClient: HttpClient,
  ) {
    this.actions$.pipe(
      ofType(fromKbActions.clearFiles),
      takeUntil(this.unsubscribe$)
    ).subscribe((action) => {
      this.filesFetched = false;
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }



  get loading(): Observable<boolean>  {
    return this.store.pipe(select(fromKbReducers.selectLoading));
  }

  get folder(): Observable<Folder>  {
    return this.store.pipe(
      select(fromKbReducers.selectFolder),
      // tap( (folder: Folder) => {
      //   if (folder && !this.filesFetched) {
      //     this.filesFetched = true;
      //     this.store.dispatch(fromKbActions.fetchFiles({id: folder.id}))
      //   }
      //   //
      // }),
      // takeWhile(folder => folder == null),
    );
  }

  get files(): Observable<KbFile[]>  {
    return this.store.pipe(select(fromKbReducers.selectFiles));
  }

  get articles(): Observable<KbArticle[]>  {
    return this.store.pipe(select(fromKbReducers.selectArticles));
  }


  isImage(file: KbFile) {
    return file.mimeType.indexOf('image/') === 0;
  }

  download(file: KbFile) {
    this.httpClient
      .get(`${environment.apiUrl}/v1/kb/file/${file.id}/download`, {responseType: 'blob'})
      .subscribe(
        (response) => {
          let blob:any = new Blob([response], { type: file.mimeType });
          // saveAs(blob, file.name);

          const url = window.URL.createObjectURL(blob);
          var anchor = document.createElement('a');
        	anchor.href = url;
        	anchor.download = file.origFileName;
        	document.body.appendChild(anchor);
        	anchor.click();
        },
        error => console.log('Error downloading the file'),
        () => console.info('File downloaded successfully')
      );
  }


  get children(): Observable<Folder[]> {
    return this.store.pipe(select(fromKbReducers.selectChildren));
  }

  identify(item: Folder, index: number) {
    return item.id;
  }

  identifyFile(item: KbFile, index: number) {
    return item.id;
  }

  backgroundSubStyle (branch: Folder) {
    if(!branch)
      return null;
    if(!branch.image)
      return null;
    return {
      background : `url(${environment.apiUrl}/thumb/70x70/${branch.image.replace('public','')}) center center`,
      'background-size' : 'cover'
    }
  }

  backgroundStyle (branch: Folder) {
    if(!branch)
      return null;
    if(!branch.background)
      return null;
    return {
      height: 'auto',
      'aspect-ratio': '4 / 1',
      background : `url(${environment.apiUrl}/thumb/1920x0/${branch.background.replace('public','')}) center center`,
      'background-size' : 'cover'
    }
  }

}

import { RedmineCategory, RedmineIssue, RedmineSubCategory } from '@/model/redmine.model';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as fromRedmineIssueActions from '@/store/redmine/redmine.issue.actions';
import * as fromRedmineIssueReducers from '@/store/redmine/redmine.issue.reducers';

@Component({
  selector: 'app-redmine-issue-edit',
  templateUrl: './redmine-issue-edit.component.html',
  styleUrls: ['./redmine-issue-edit.component.scss']
})
export class RedmineIssueEditComponent implements OnInit {
  unsubscribe$ = new Subject<boolean>();
  form: FormGroup;
  category: RedmineCategory;
  subCategory: RedmineSubCategory;
  loading: boolean = true;
  saving: boolean = false;
  issue: RedmineIssue;

  constructor(
    private store: Store<fromRedmineIssueReducers.State>,
    private toastr: ToastrService,
    private router: Router,
    protected actions$: Actions,
    protected activatedRoute$: ActivatedRoute,
  ) {

    this.form = new FormGroup({
      subject: new FormControl(null, Validators.required),
      description: new FormControl(null)
    });
  }

  ngOnInit(): void {
    this.activatedRoute$.data.subscribe(data => {
      this.category = data.category;
      this.subCategory = data.subcategory;
      this.form.patchValue({
        category: this.category.id,
        subCategory: this.subCategory.id,
      })
    })
    this.activatedRoute$.params.subscribe(params => {
      this.store.dispatch(fromRedmineIssueActions.fetchOne({id: params.id}));
      this.actions$.pipe(takeUntil(this.unsubscribe$), ofType(fromRedmineIssueActions.setIssue)).subscribe((action: any) => {
        this.issue = action.item;
        this.loading = false;
        this.form.patchValue(this.issue);
      })
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }



  save() {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    if (this.form.valid) {
      this.saving = true;
      let item = {
        ...{
          id: this.issue.id
        },
        ...this.form.value
      }
      this.store.dispatch(fromRedmineIssueActions.updateIssue({item}));
      this.actions$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromRedmineIssueActions.setIssue)
      ).subscribe( (action: any) => {
        this.toastr.success('Zapisano zmiany');
        this.router.navigate(['/redmine/issue',action.item.id]);
      });
    } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
      console.log(this.form.value);
    }
  }
}

<!-- <pre>{{filterForm.value | json}}</pre>
<pre>{{allCategories | json}}</pre> -->


<div class="content  pt-3 pb-3">
  <div class="container-fluid" [formGroup]="filterForm">
    <div class="row" ngbRadioGroup >
      <div class="col-lg-4 d-none d-lg-block" *ngFor="let cat of categories">

        <!-- <input type="checkbox" [formControlName]="cat.id" class="btn-check" [id]="'cat' + cat.id" autocomplete="off"> -->
        <!-- <label class="btn btn-outline-primary" [for]="'cat' + cat.id">{{cat.name}}</label> -->
        <label class="btn btn-block btn-outline-primary p-3" ngbButtonLabel >
          <input type="radio" [value]="cat.id"  formControlName="categories" ngbButton  autocomplete="off" class="d-none" (click)="clearOtherCategory($event, cat.id)">
          <h4>{{cat.name}}</h4>
        </label>
      </div>
    </div>
  </div>
  <hr>
  <div class="container-fluid">
    <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(offers | async).length == 0 && !(loading | async)"></nz-empty>
    <div class="row"
          infinite-scroll
          [infiniteScrollDistance]="scrollDistance"
          [infiniteScrollUpDistance]="scrollUpDistance"
          [infiniteScrollThrottle]="throttle"
          [immediateCheck]="true"
          (scrolled)="onScroll()"
          (scrolledUp)="onScrollUp()">

      <div class="col-lg-4 d-none d-lg-block">
        <ng-template ngFor let-offer [ngForOf]="offers | async" let-idx="index" [ngForTrackBy]="identify">
          <offer-box [offer]="offer" class="d-block  mt-2 mb-2" *ngIf="idx % 3 == 0"></offer-box>
        </ng-template>
      </div>
      <div class="col-lg-4 d-none d-lg-block">
        <ng-template ngFor let-offer [ngForOf]="offers | async" let-idx="index" [ngForTrackBy]="identify">
          <offer-box [offer]="offer" class="d-block  mt-2 mb-2"  *ngIf="idx % 3 == 1"></offer-box>
        </ng-template>
      </div>
      <div class="col-lg-4 d-none d-lg-block">
        <ng-template ngFor let-offer [ngForOf]="offers | async" let-idx="index" [ngForTrackBy]="identify">
          <offer-box [offer]="offer" class="d-block  mt-2 mb-2"  *ngIf="idx % 3 == 2"></offer-box>
        </ng-template>
      </div>


      <div class="col-md-6 d-none d-md-block d-lg-none">
        <ng-template ngFor let-offer [ngForOf]="offers | async" let-idx="index" [ngForTrackBy]="identify">
          <offer-box [offer]="offer" class="d-block  mt-2 mb-2" *ngIf="idx % 2 == 0"></offer-box>
        </ng-template>
      </div>
      <div class="col-md-6 d-none d-md-block d-lg-none">
        <ng-template ngFor let-offer [ngForOf]="offers | async" let-idx="index" [ngForTrackBy]="identify">
          <offer-box [offer]="offer" class="d-block  mt-2 mb-2"  *ngIf="idx % 2 == 1"></offer-box>
        </ng-template>
      </div>
      <div class="col-12 d-block d-lg-none d-xl-none d-xxl-none">
        <ng-template ngFor let-offer [ngForOf]="offers | async" let-idx="index" [ngForTrackBy]="identify" #cmp>
          <offer-box [offer]="offer" class="d-block  mt-2 mb-2"></offer-box>
        </ng-template>
      </div>

      <button
        type="button"
        class="btn btn-block btn-outline-primary btn-block"
        *ngIf="(hasMore | async)"
        (click)="loadMore()"
        [ngClass]="{'disabled' : (loading | async)}">
        <i class="fas" [ngClass]="{'fa-spinner fa-spin' : (loading | async), 'fa-sync' : !(loading | async)}"></i>
        załaduj więcej
      </button>
    </div>
  </div>
</div>

<section class="content">
  <div class="container">
    <div class="card" style="border-top-left-radius: 0;border-top-right-radius: 0;">
      <div class="card-header d-flex align-items-center">
        <h3 class="card-title m-0 flex-grow-1 text-bold"><i class="fas fa-chart-area fa-fw"></i> Raport </h3>
        <div class="card-tools flex-grow-1">
          <a class="btn btn-success btn-sm float-right" (click)="generateCSV()">
            <i class="fas fa-check fa-fw"></i>
            Export do CSV
          </a>
        </div>
      </div>
      <div class="table-responsive position-relative">
        <table class="table align-middle table-hover text-sm">
          <thead>
            <tr>
              <th style="width:1rem">#</th>
              <th>Imię</th>
              <th>Nazwisko</th>
              <th>Email</th>
              <th>Wyświetlone</th>
              <th>Data wyświetlenia</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of pagination; let idx = index">
              <th class="align-middle">{{idx + 1 + (page-1)*pageSize}}</th>
              <td class="align-middle">{{item.firstname}}</td>
              <td class="align-middle">{{item.lastname}}</td>
              <td class="align-middle">{{item.email}}</td>
              <td class="align-middle">
                <i class="fas" [ngClass]="{
                  'fa-times text-danger': item.seen == 'Nie',
                  'fa-check text-success': item.seen == 'Tak'
                }"></i>
                {{item.seen}}
              </td>
              <td class="align-middle">{{item.createdAt}}</td>
            </tr>
          </tbody>
        </table>
        <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="loading"></nz-empty>
        <div class="overlay" *ngIf="loading" style="    position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;">
          <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
            <i class="fas fa-3x fa-spinner fa-spin"></i>
            <p>Ładowanie...</p>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="result.length" class="float-left">
          <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
</section>





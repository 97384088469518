import { Component, OnInit } from '@angular/core';
import { AddBranchComponent } from '../add-branch/add-branch.component';
import * as fromActions from '@/store/structure/structure.actions';
import * as fromReducers from '@/store/structure/structure.reducers';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { map, takeUntil } from 'rxjs/operators';
import { Branch } from '@/model/branch.model';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-edit-branch',
  templateUrl: './../add-branch/add-branch.component.html',
  styleUrls: ['./../add-branch/add-branch.component.scss']
})
export class EditBranchComponent extends AddBranchComponent {

  constructor(
    actions$: Actions,
    store: Store<fromReducers.State>,
    toastr: ToastrService,
    router: Router,
    activeRoute: ActivatedRoute,
  ) {
    super(actions$, store, toastr, router, activeRoute);
  }

  ngOnInit(): void {
    this.initForm();
    this.store.dispatch(fromActions.fetch());
    this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectRoot)).subscribe( (tree) => {
      this.nodes = this.mapNodes(tree);
    });
    this.activeRoute.paramMap.pipe(
      map((params: ParamMap) => parseInt(params.get('id'))),
    ).subscribe( (id?: number) => {
      // this.form.patchValue({parent: parent});
      this.store.dispatch(fromActions.fetchOne({id}));
      this.actions$.pipe(
        ofType(fromActions.fetchOneSuccess),
        takeUntil(this.unsubscribe$)
      ).subscribe((action) => {
        this.branch = action.branch;
        this.form.patchValue(action.branch);
      });
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  save(): void {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });

    if (this.form.valid) {
      let branch: Branch = {...this.branch, ...this.form.value};
      this.store.dispatch(fromActions.editBranch({branch: branch, image: this.file as any}));
    } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }

}

<ckeditor
  #editor
  [class.ballon]="baloon"
  style="width: 100%;"
  [editor]="Editor"
  [config]="config"
  [(ngModel)]="content"
  (ngModelChange)="onContentChange($event)"
  [ngModelOptions]="{standalone: true}"></ckeditor>
<!-- <pre>{{ config | json }}</pre> -->
<p class="bg-secondary disabled color-palette p-2 m-0 pb-0" *ngIf="!baloon">
  <strong>Dodawanie linków wewnętrznych</strong>
</p>
<p class="bg-secondary disabled color-palette p-2 text-sm pt-0 mb-0" *ngIf="!baloon">
  Zacznij wpisywać zaczynając od <strong>#</strong> aby wyszukać i dodać link do newsa / artykuły / oferty pracy.<br>
  Zacznij wpisywać zaczynając od <strong>@</strong> aby wyszukać i dodać wzmiankę o użytkowniku.<br>
  Minimum <strong>3 znaki</strong> aby uruchomić wyszukiwarkę
</p>

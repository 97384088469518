import { Training } from '@/model/scorm.model';
import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { from, Observable, of } from 'rxjs';
import * as fromTrainingActions from '@/store/training/training.actions';
import * as fromTrainingReducers from '@/store/training/training.reducers';
import { select, Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TrainingResolver implements Resolve<Training> {
  constructor(
    private store: Store<fromTrainingReducers.State>
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Training> {
    return from( new Promise<Training>(resolve => {
      let id = 1*(route.params?.training ? route.params?.training : route.params.id);
      this.store.pipe(
        select(fromTrainingReducers.selectOneTraining, id),
        tap( (result) => {
          if(!result) {
            this.store.dispatch(fromTrainingActions.fetchOne({id}));
          }
        }),
        filter((result) => result != null),
        take(1),
      ).subscribe(instance => resolve(instance));
    }));
  }

}

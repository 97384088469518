import { Component, OnInit } from '@angular/core';
import * as fromActions from '@/store/order/order.actions';
import * as fromReducers from '@/store/order/order.reducer';
import { AddOrderFormComponent } from '../add-order-form/add-order-form.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { OrderForm } from '@/model/order.form.model';

@Component({
  selector: 'app-edit-order-form',
  templateUrl: '../add-order-form/add-order-form.component.html',
  styleUrls: ['../add-order-form/add-order-form.component.scss']
})
export class EditOrderFormComponent extends AddOrderFormComponent {

  constructor(
    protected store: Store<fromReducers.State>,
    protected modalService: NgbModal,
    protected toastr: ToastrService,
    protected actionsSubject$: ActionsSubject,
    protected router: Router,
    protected activatedRoute: ActivatedRoute
  ) {
    super(
      store,
      modalService,
      toastr,
      actionsSubject$,
      router
    );
    this.activatedRoute.paramMap.pipe(
      map((params: ParamMap) => parseInt(params.get('id')))
    ).subscribe( (id: number) => {
      this.store.dispatch(fromActions.fetchOneOrderForm({id}));
      this.store.pipe(
        takeUntil(this.unsubscribe$),
        select(fromReducers.selectOne, id)).subscribe((orderForm: OrderForm) => {
          this.store.dispatch(fromActions.setWorkspace({orderForm}))
        });
    })
  }

}

import { InnerMessage } from "@/model/message.mode";
import { PaginationDTO } from "@/model/pagination.model";
import { createAction, props } from "@ngrx/store";

export const createNewMessage = createAction(
  "create new message",
  props<{ message: InnerMessage }>()
);

export const success = createAction("success");

export const fetchUnseenCount = createAction( "fetchUnseenCount");
export const setUnseenCount = createAction(
  "setUnseenCount",
  props<{count: number}>()
);
export const fetchRecent = createAction( "fetchRecent");
export const addRecent = createAction( "addRecent",
  props<{payload: InnerMessage[]}>()
);
export const addNewMessage = createAction( "addNewMessage",
  props<{message: InnerMessage}>()
);


export const setAsSeen = createAction( "setAsSeen",
  props<{id: number}>()
);

export const fetchInbox = createAction( "fetchInbox",
  props<{limit?: number | null}>()
);
export const setInbox = createAction( "setInbox",
  props<PaginationDTO<InnerMessage>>()
);
export const setPageInbox = createAction("setPageInbox", props<{ page: number }>());
export const setOrderInbox = createAction("setOrderInbox", props<{ order: string }>());
export const setDirectionInbox = createAction("setDirectionInbox", props<{ asc: boolean }>());
export const setSearchInbox = createAction("setSearchInbox", props<{ search: string }>());


export const fetchSent = createAction( "fetchSent",
  props<{limit?: number | null}>()
);
export const setSent = createAction( "setSent",
  props<PaginationDTO<InnerMessage>>()
);
export const setPageSent = createAction("setPageSent", props<{ page: number }>());
export const setOrderSent = createAction("setOrderSent", props<{ order: string }>());
export const setDirectionSent = createAction("setDirectionSent", props<{ asc: boolean }>());
export const setSearchSent = createAction("setSearchSent", props<{ search: string }>());




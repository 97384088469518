<ul class="nav nav-tabs d-block d-lg-flex">
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/training']" [queryParams]="{status: 'active'}" routerLinkActive="active">Dla mnie</a>
  </li>
  <!-- <li class="nav-item">
    <a class="nav-link" [routerLink]="['/training']" [queryParams]="{status: 'all'}" routerLinkActive="active">Wszystkie</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/training']" [queryParams]="{status: 'invited'}" routerLinkActive="active">Zaproszenia</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/training']" [queryParams]="{status: 'progress'}" routerLinkActive="active">W trakcie</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/training']" [queryParams]="{status: 'completed'}" routerLinkActive="active">Ukończone</a>
  </li> -->
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/training']" [queryParams]="{status: 'uncompleted'}" routerLinkActive="active">Rozpoczęte</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/training/open']" routerLinkActive="active">Ogólno dostępne</a>
  </li>
  <!-- <li class="nav-item">
    <a class="nav-link">Otwarte</a>
  </li> -->

</ul>
<div class="card" style="border-top-right-radius:0;border-top-left-radius:0;">
  <div class="overlay" *ngIf="(loading | async)">
    <div style="position: sticky; text-align: center">
      <i class="fas fa-3x fa-spinner fa-spin"></i>
      <p>Ładowanie...</p>
    </div>
  </div>
  <div class="card-header d-flex align-items-center" style="border-top-right-radius:0;border-top-left-radius:0;">
    <!-- <h3 class="card-title m-0 flex-grow-1 text-bold"><i class="fas fa-graduation-cap fa-fw"></i> Szkolenia</h3> -->
    <div class="card-tools flex-grow-1">
      <div class="input-group">
        <input type="text" name="table_search" class="form-control float-right" placeholder="Szukaj" #searchInput>
        <button class="btn bg-transparent" style="position:absolute; right: 0; transform: translate(-100%,0);" (click)="clearSearch()">
          <i class="fa fa-times"></i>
        </button>
        <span class="input-group-append">
          <div class="input-group-text bg-transparent"><i class="fas fa-search"></i></div>
        </span>
      </div>
    </div>
  </div>
  <div class="table-responsive">
    <!--class="table-responsive no-more-tables"-->
    <!-- <pre>{{order | async | json }}</pre> -->
    <table class="table align-middle table-hover">
      <thead>
        <tr>
          <th style="width:1rem">#</th>
          <th data-title="Nazwa" class="sortable" (click)="setSort('name')"  [ngClass]="{'active': (order | async) == 'name'}"  >
            Nazwa
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <th data-title="Szkoleń w paczce">Szkoleń w paczce</th>
          <th data-title="Data zaproszenia" class="sortable" (click)="setSort('invitedAt')"  [ngClass]="{'active': (order | async) == 'invitedAt'}"  >
            Data zaproszenia
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <th data-title="Czas&nbsp;realizacji&nbsp;szkolenia / Termin&nbsp;zakończenia&nbsp;szkolenia" class="sortable" (click)="setSort('endDate')"  [ngClass]="{'active': (order | async) == 'endDate'}"  >
            Czas&nbsp;realizacji&nbsp;szkolenia / Termin&nbsp;zakończenia&nbsp;szkolenia
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <th data-title="Data rozpoczęcia" class="sortable" (click)="setSort('startedAt')"  [ngClass]="{'active': (order | async) == 'startedAt'}"  >
            Data rozpoczęcia
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <th data-title="Data ukończenia" class="sortable" (click)="setSort('completedAt')"  [ngClass]="{'active': (order | async) == 'completedAt'}"  >
            Data ukończenia
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <th data-title="Status">Status</th>
          <th data-title="Zaliczone">Zaliczone</th>
          <th data-title="Certyfikat">Certyfikat</th>

          <th class="buttons text-right" ></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of list | async; let idx = index; trackBy: identify">
          <th class="align-middle hidden-mobile">{{idx + 1 + pageSize*(page-1)}}</th>
          <td data-title="Nazwa" class="align-middle">{{item.package?.name}}</td>
          <td data-title="Szkoleń w paczce" class="align-middle">{{item.package?.trainings.length}}</td>

          <td data-title="Data zaproszenia" *ngIf="item.package.open" colspan="2" class="align-middle text-center">Szkolenie ogólno dostępne</td>
          <td data-title="Data zaproszenia" class="align-middle"  *ngIf="!item.package.open">
            {{item.invitedAt | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}

          </td>
          <td data-title="Termin realizacji szkoleni" class="align-middle"  *ngIf="!item.package.open">
            {{item.invitePack.endDate | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}
          </td>

          <td data-title="Data rozpoczęcia" class="align-middle">
            <span *ngIf="item.startedAt">{{item.startedAt  | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</span>
            <span *ngIf="!item.startedAt"> - nie rozpoczęto - </span>
          </td>
          <td data-title="Data ukończenia" class="align-middle">
            <span *ngIf="item.completedAt">{{item.completedAt  | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</span>
            <span *ngIf="!item.completedAt"> - nie ukończono - </span>
          </td>
          <td data-title="Status" class="align-middle">
            <span *ngIf="item.status == 'completed'" class="badge bg-success text-md">Ukończone</span>
            <span *ngIf="item.status == 'uncompleted'" class="badge badge-danger text-md">Nie ukończone</span>
            <span *ngIf="item.status == 'progress'" class="badge badge-info text-md">W trakcie</span>
            <span *ngIf="item.status == 'invited'" class="badge badge-secondary text-md">Zaproszenie</span>
          </td>
          <td data-title="Zaliczone" class="align-middle text-center">
            <i class="fas fa-check-circle fa-2x text-success" *ngIf="item?.best?.passed == true"></i>
            <i class="fas fa-times-circle fa-2x text-danger" *ngIf="item?.best?.passed == false"></i>
          </td>
          <td data-title="Certyfikat" class="align-middle text-center">
            <button class="btn btn-link" [routerLink]="[item.id,'certificate']" ngbTooltip="Pobierz certyfikat"
              *ngIf="item?.best?.passed && item?.best?.certificate && item.package?.certificate">
              <i class="fas fa-certificate fa-fw fa-lg"></i>
            </button>
          </td>
          <td class="align-middle text-nowrap text-left buttons" *ngIf="item.status != 'uncompleted'">
            <button
              class="btn btn-info btn-sm elevation-2 mr-2"
              [routerLink]="[item.id]"
              [ngbTooltip]="item.startedAt ? 'Kontynuuj' : 'Weź udział'">
              <i class="fas fa-play fa-fw fa-lg"></i>
            </button>

            <button
                *ngIf="item.startedAt"
                checkRolePermition="training.admin.scorm"
                class="btn btn-danger btn-sm elevation-2"
                nz-popconfirm
                nzPopconfirmTitle="Czy na pewno zacząć od początku?"
                nzOkText="Tak"
                nzCancelText="Nie"
                nzPopconfirmPlacement="left"
                ngbTooltip="Zacznij od nowa"
                (nzOnConfirm)="reset(item)" >
              <i class="fas fa-redo fa-fw fa-lg"></i>
            </button>
          </td>
          <td class="align-middle text-nowrap text-right" *ngIf="item.status == 'uncompleted'">
            <span class="badge badge-secondary" *ngIf="item.package.archive">Archiwalne</span>
          </td>
        </tr>
      </tbody>
    </table>
    <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(total | async) == 0"></nz-empty>
  </div>
  <div class="card-footer" >
    <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="total | async" (pageChange)="pageChange($event)">
      <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationEllipsis>...</ng-template>
      <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>
  </div>
</div>

<section class="content">
  <div class="container-xl pt-3 pb-3">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Grupy użytkowników</h3>
        <div class="card-tools">
          <div class="input-group">
            <input type="text" name="table_search" class="form-control float-right" placeholder="Szukaj" #searchInput>
            <button class="btn bg-transparent" style="position:absolute; right: 0; transform: translate(-100%,0);" (click)="clearSearch()">
              <i class="fa fa-times"></i>
            </button>
            <span class="input-group-append">
              <div class="input-group-text bg-transparent"><i class="fas fa-search"></i></div>
            </span>
          </div>
        </div>
      </div>
      <div class="table-responsive position-relative">
        <table class="table align-middle table-hover">
          <thead>
            <tr>
              <th style="width:1rem">#</th>
              <th style="width:1rem"></th>
              <th>Nazwa</th>
              <th class="text-nowrap">Utworzono</th>
              <th class="text-nowrap">Należysz od</th>
              <th></th>
              <th></th>
              <th style="width:2rem"></th>
              <th style="width:2rem"></th>
              <th style="width:2rem"></th>
              <th class="text-right" style="width:2rem">
                <button class="btn btn-success btn-sm elevation-2" [routerLink]="['/groups', 'add']" checkRolePermition="groups.create">
                  <i class="fas fa-plus fa-fw fa-lg"></i> Dodaj
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of paginator | async; let idx = index; trackBy: identify" [ngClass]="{'table-primary': item.superior}">
              <th class="align-middle">{{idx+1 + (page-1)*pageSize}}</th>
              <td class="align-middle p-2" style="min-width: 55px"><img style="min-width: 45px" class="img-circle elevation-2" [src]="item | groupAvatar"/></td>
              <td class="align-middle">{{item.name}}</td>
              <td class="align-middle text-nowrap">{{item.createdAt | amLocale:'pl' | amTimeAgo }}</td>
              <td class="align-middle text-nowrap">
                <span *ngIf="item?.membership">{{item?.membership.createdAt| amLocale:'pl' | amTimeAgo }}</span>
              </td>
              <td class="align-middle">
                <i class="fas fa-users"></i> {{item.memberCount}}
              </td>
              <td class="align-middle">
                <i class="fas fa-eye"></i> {{item.observersCount}}
              </td>

              <td class="align-middle p-1">
                <button
                  style="color: #FFF"
                  checkRolePermition="groups.edit" [instance]="item"
                  class="btn btn-outline-primary btn-sm btn-block text-nowrap elevation-2"
                  *ngIf="!item.membership"
                  (click)="join(item)"
                  [disabled]="!canJoin(item)"
                  [ngClass]="{'btn-primary': canJoin(item), 'btn-secondary': !canJoin(item)}"
                  ngbTooltip="Dołącz do grupy">
                  <i class="fas fa-users fa-fw fa-lg"></i>
                   <!-- Dołącz -->
                </button>
                <button
                  class="btn btn-block btn-sm text-nowrap elevation-2"
                  *ngIf="item.membership"
                  (click)="leave(item)"
                  [disabled]="!canLeave(item)"
                  [ngClass]="{'btn-primary': canLeave(item), 'btn-secondary': !canLeave(item)}"
                  ngbTooltip="Opuść grupę">
                  <i class="fas fa-users-slash fa-fw fa-lg"></i>
                   <!-- Opuść -->
                </button>
              </td>
              <td class="align-middle p-1">
                <button
                  class="btn btn-block btn-sm text-nowrap  elevation-2"
                  *ngIf="!item.membership?.observing"
                  (click)="observe(item,true)"
                  [disabled]="!item.membership"
                  [ngClass]="{'btn-outline-primary': item.membership, 'btn-outline-secondary': !item.membership}"
                  ngbTooltip="Obserwuj grupę">
                  <i class="fas fa-eye fa-fw fa-lg"></i>
                   <!-- Obserwój -->
                </button>
                <button
                  class="btn btn-primary btn-sm btn-block text-nowrap  elevation-2"
                  *ngIf="item.membership?.observing"
                  (click)="observe(item,false)"
                  [disabled]="!canUnobserve(item)"
                  ngbTooltip="Nie obserwuj grupy">
                  <i class="fas fa-eye-slash fa-fw fa-lg"></i>
                   <!-- Nie obserwój -->
                </button>
              </td>
              <td class="align-middle p-1">
                <span class="btn btn-block btn-sm btn-outline-primary text-nowrap  elevation-2"
                  *ngIf="canViewPosts(item)"
                  ngbTooltip="Newsy w grupie"
                  [routerLink]="['/groups', item.id, 'news']">
                  <i class="fas fa-newspaper fa-fw fa-lg"></i>
                </span>
              </td>
              <td class="align-middle text-nowrap  text-right">
                <button

                  [checkRolePermition]="item.superior ? 'groups.superior.edit' : 'groups.edit'" [instance]="item"
                  class="btn btn-primary btn-sm elevation-2 mr-2"
                  [routerLink]="['/groups', item.id, 'edit']"
                  ngbTooltip="Edytuj grupę">
                  <i class="fas fa-edit fa-fw fa-lg"></i>
                </button>
                <span

                    [checkRolePermition]="item.superior ? 'groups.superior.delete' : 'groups.delete'" [instance]="item"
                    class="btn btn-danger btn-sm elevation-2"
                    nz-popconfirm
                    nzPopconfirmTitle="Czy na pewno usunąć?"
                    nzOkText="Tak"
                    nzCancelText="Nie"
                    nzPopconfirmPlacement="left"
                    (nzOnConfirm)="delete(item)"
                    ngbTooltip="Usuń grupę" >
                  <i class="fas fa-trash fa-fw fa-lg"></i>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(paginator | async).length == 0"></nz-empty>
        <div class="overlay" *ngIf="loading | async" style="position: absolute;  top: 0;  bottom: 0; left: 0; right: 0;">
          <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
            <i class="fas fa-3x fa-spinner fa-spin"></i>
            <p>Ładowanie...</p>
          </div>
        </div>
      </div>
      <div class="card-footer" *ngIf="groups">
        <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="total | async">
          <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
</section>





import { tinyMceConfig } from '@/config/tinymce.config';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import * as fromActions from '@/store/structure/structure.actions';
import * as fromReducers from '@/store/structure/structure.reducers';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Branch } from '@/model/branch.model';
import { Subject } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-add-branch',
  templateUrl: './add-branch.component.html',
  styleUrls: ['./add-branch.component.scss']
})
export class AddBranchComponent implements OnInit, OnDestroy  {
  protected readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  form: FormGroup;
  tinyMceConfig = tinyMceConfig;
  expandKeys = [];
  nodes: [];
  branch: Branch;
  file: NzUploadFile;

  constructor(
    protected actions$: Actions,
    protected store: Store<fromReducers.State>,
    protected toastr: ToastrService,
    protected router: Router,
    protected activeRoute: ActivatedRoute,
  ) {
    this.actions$.pipe(
      ofType(fromActions.saveSuccess),
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.router.navigateByUrl("/structure/admin");
      this.toastr.success("Zapisano poprawnie");
    });
  }

  mapNodes(tree) {
    return tree.map( node => {
      return {
        key: node.id,
        title: node.title,
        children: node.children?.ids.length ? this.mapNodes(Object.values(node.children.entities)) : [],
        isLeaf: node.children?.ids.length == 0
      }
    })
  }

  ngOnInit(): void {
    this.initForm();
    this.store.dispatch(fromActions.fetch());
    this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectRoot)).subscribe( (tree) => {
      this.nodes = this.mapNodes(tree);
    });
    this.activeRoute.paramMap.pipe(
      map((params: ParamMap) => parseInt(params.get('parent')))
    ).subscribe( (parent?: number) => {
      this.form.patchValue({parent: parent});
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  save(): void {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });

    if (this.form.valid) {
      let branch: Branch = this.form.value as Branch;
      // const formData = new FormData();
      // formData.append('file', this.myForm.get('fileSource').value);
      this.store.dispatch(fromActions.createBranchAction({branch: branch, image: this.file as any}));
    } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }

  buf: any;
  beforeUpload = (file: NzUploadFile): boolean => {
    getBase64(file as any).then( (preview: string) => {
      this.file = file;
      this.branch = {...this.branch, ...{image: preview}};
    })
    getBuff(file as any).then( (buf: string) => {
      this.buf = buf;
    })
    // console.log(file);
    return false;
  };

  protected initForm(): void {
    this.form = new FormGroup({
      title: new FormControl(null, Validators.required),
      description: new FormControl(null),
      parent: new FormControl(null),
      // groups: new FormArray([], null)
    });

  }
}

const getBuff = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});


import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { switchMap, catchError, debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { DefaultEffects } from "../default/default.effects";
import { ErrorState } from "../error.store";
import * as fromActions from "./search-tag.actions";
import {State, selectSearchState} from "./search-tag.reducers";

@Injectable()
export class SearchTagEffects extends DefaultEffects {
  private state: State;
  constructor(
    private actions$: Actions,
    httpClient: HttpClient,
    toastr: ToastrService,
    errorStore: Store<ErrorState>,
    private store$: Store<State>,

    // private notificationStore: Store<fromNotificationReducers.State>,
  ) {
    super(httpClient, toastr, errorStore);
    this.store$.pipe(select(selectSearchState)).subscribe((state: State) => {
      this.state = state;
    })
  }

  search$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.search),
    debounceTime(300),
    distinctUntilChanged(),
    map(action => action.term),
    switchMap((term: string) => {
      let actions = [];
      actions.push(fromActions.clear());
      actions.push(fromActions.setTerm({term}));
      if(term) actions.push(fromActions.fetch({term}));

      return actions;
    })
  ));

  more$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.more),
    switchMap( action => [
      fromActions.fetch({term: this.state.term, skip: this.state.results.ids.length})
    ])
  ));

  fetch$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetch),
    switchMap((action) => {
      let params = new HttpParams();
      params = params.set('term', action.term);
      params = params.set('skip', action.skip ? action.skip : 0);
      return this.httpClient.get<any>('<backendhost>/v1/search', {params: params, observe: 'body',responseType: 'json'})
    }),
    catchError(this.onError.bind(this)),
    switchMap((result: any) => [fromActions.addResults(result)])
  ));
}

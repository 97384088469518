<div class="card card-primary">
  <div class="card-header">
    <h3 class="card-title">{{subCategory.title}}</h3>
  </div>
  <div class="card-body">
    <div *ngIf="subCategory?.description" class="" bind-innerHTML="subCategory?.description | safeHtml"></div>
    <p>&nbsp;</p>
    <hr *ngIf="subCategory.faq && subCategory.faq.length > 0"/>
    <p *ngIf="subCategory.faq && subCategory.faq.length > 0">&nbsp;</p>
    <div *ngIf="subCategory.faq && subCategory.faq.length > 0">
    <h4>Zanim zgłosisz problem, spróbuj...</h4>
    <ngb-accordion [closeOthers]="true">
      <ngb-panel *ngFor="let item of subCategory.faq" [title]="item.question">
        <ng-template ngbPanelContent>
          <div class="" bind-innerHTML="item?.answer | safeHtml"></div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
    <p>&nbsp;</p>
    </div>
    <a class="btn btn-primary btn-lg elevation-2" [routerLink]="['create-issue']">
      Utwórz zgłoszenie
    </a>
  </div>
</div>

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { fromEvent, Observable, Subject } from 'rxjs';
import * as fromActions from '@/store/ideas/ideas.actions';
import * as fromReducers from '@/store/ideas/ideas.reducers';
import { select, Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { Idea } from '@/model/idea.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditIdeaModalComponent } from './edit-idea-modal/edit-idea-modal.component';
import moment from 'moment';

@Component({
  selector: 'app-admin-ideas',
  templateUrl: './admin-ideas.component.html',
  styleUrls: ['./admin-ideas.component.scss']
})
export class AdminIdeasComponent implements OnInit {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  page = 1;
  pageSize = fromReducers.initialState.admin.limit;
  searchTerm: string;

  constructor(
    private store: Store<fromReducers.State>,
    protected modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(fromActions.reset());
    this.store.dispatch(fromActions.fetchAdminIdeas());
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      takeUntil(this.unsubscribe$),
      map((event: any) => event.target.value),
      filter( (res:string) => res.length > 2),
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((text: string) => {
      this.store.dispatch(fromActions.setAdminSearch({search: text.toLowerCase().trim()}));
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  clearSearch() {
    this.searchInput.nativeElement.value = '';
    this.store.dispatch(fromActions.setAdminSearch({search: null}));
  }

  get paginator(): Observable<Idea[]>  {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectAllAdminIdeas));
  }

  get total(): Observable<number>  {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectIdeasState), map(state => state.admin.total));
  }

  pageChange(page): void {
    this.store.dispatch(fromActions.setAdminPage({page: page}));
  }

  delete(idea: Idea): void {
    this.store.dispatch(fromActions.deleteIdea({idea}));
  }

  hide(idea: Idea): void {
    this.store.dispatch(fromActions.hideIdea({idea}));
  }

  identify(index, item: Idea): number { return item.id;}

  get loading(): Observable<boolean>  {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectIdeasState), map(state => state.admin.loading));
  }

  isPublic(item: Idea): boolean {
    // return !item.publicUntil || moment().isBefore(item.publicUntil);
    return item.publicUntil &&  moment().isBefore(item.publicUntil) && ['analysis', 'voting'].includes(item.status);
  }

  statusName(item: Idea): string {
    if (moment(item.publicUntil).isBefore())
      return "Ukryty";
    switch (item.status) {
      case 'new': return "Nowy";
      case 'analysis': return "Zatwierdzone do analizy";
      case 'rejectred': return "Odrzucony";
      case 'voting':
        if (moment().isBefore(moment(item.votingUntil)))
          return "Trwa głosowanie";
        else
          return "Głosowanie zakończone";
    }
    return item.status;
  }

  edit (item: Idea) {
    const modalRef = this.modalService.open(EditIdeaModalComponent, {size: 'xl', scrollable: false});
    modalRef.componentInstance.idea = item;
  }
}

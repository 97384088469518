import { AuthService } from '@/auth/auth.service';
import { Conversation } from '@/model/chat.model';
import { User } from '@/model/user.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ChatService } from '@services/chat.service';
import { Observable, of, OperatorFunction, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators';
import * as fromChatActions from '@/store/chat/chat.actions';
import * as fromChatReducers from '@/store/chat/chat.reducers';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '@services/app.service';

@Component({
  selector: 'app-list-conversation',
  templateUrl: './list-conversation.component.html',
  styleUrls: ['./list-conversation.component.scss']
})
export class ListConversationComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  user: User;
  searching = false;
  searchFailed = false;
  model: any;
  formatter = (user: User) => `${user.firstname} ${user.lastname}`;
  formatterEmpty = () => null;

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  constructor(
    private chatService: ChatService,
    private router: Router,
    authService: AuthService,
    private store: Store<fromChatReducers.State>,
    private appService: AppService
  ) {
    authService.getUser().then(user => this.user = user);
  }

  get all(): Observable<Conversation[]> {
    return this.chatService.all.pipe(takeUntil(this.unsubscribe$));
  }

  ngOnInit(): void {
    this.router.navigate([{outlets: { sidebar: 'chat'}}]);
  }

  delete(conversation: Conversation) {
    this.store.dispatch(fromChatActions.deleteRoom({conversation}));
  }

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    return text$.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(300),
      distinctUntilChanged(),
      // tap(() => this.searching = true),
      switchMap(term =>
        this.appService.searchUser(term).pipe(
          // tap(() => this.searchFailed = false),
          catchError(() => {
            // this.searchFailed = true;
            return of([]);
          }))
      ),
      // tap(() => this.searching = false)
    );
  }

  onUserSelect(selectedItem: NgbTypeaheadSelectItemEvent) {
    let user: User = selectedItem.item;
    this.router.navigate(['/chat/user', user.id]);
    // this.store.dispatch(fromChatActions.addMemberToRoom({user: user, room: this.conversation}));
    // this.store.pipe(select(fromGroupReducer.selectEntity, this.group.id), take(1)).subscribe((group: Group | null) => {
    //   this.group = group;
    // })
  }

}

import { CallCenterQuestionnaire } from "@/model/call-center.model";
import { PaginationDTO } from "@/model/pagination.model";
import { createAction, props } from "@ngrx/store";


export const clear = createAction("call-center questionnaire clear");
export const fetch = createAction("call-center questionnaire fetch");
export const fetchOne = createAction("call-center questionnaire fetchOne", props<{id: number}>());

export const setPage = createAction("call-center questionnaire setPage", props<{ page: number }>());
export const setOrder = createAction("call-center questionnaire setOrder", props<{ order: string }>());
export const setDirection = createAction("call-center questionnaire setDirection", props<{ asc: boolean }>());
export const setSearch = createAction("call-center questionnaire setSearch", props<{ search: string }>());

export const createQuestionnaire = createAction("call-center questionnaire createQuestionnaire", props<{item: CallCenterQuestionnaire}>());
export const updateQuestionnaire = createAction("call-center questionnaire updateQuestionnaire", props<{item: CallCenterQuestionnaire}>());
export const deleteQuestionnaire = createAction("call-center questionnaire deleteQuestionnaire", props<{id: number}>());

export const setMany = createAction("call-center questionnaire setMany", props<PaginationDTO<CallCenterQuestionnaire>>());
export const addMany = createAction("call-center questionnaire addMany", props<PaginationDTO<CallCenterQuestionnaire>>());
export const addOne = createAction("call-center questionnaire addOne", props<{item: CallCenterQuestionnaire}>());
export const setOne = createAction("call-center questionnaire setOne", props<{item: CallCenterQuestionnaire}>());
export const removeOne = createAction("call-center questionnaire removeOne", props<{id: number}>());

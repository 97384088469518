import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-fill-notification-modal',
  templateUrl: './fill-notification-modal.component.html',
  styleUrls: ['./fill-notification-modal.component.scss']
})
export class FillNotificationModalComponent implements OnInit {

  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  send() {
    this.modal.close();
  }

  dismiss() {
    this.modal.dismiss();
  }

}

import { Injectable } from '@angular/core';
import * as fromRedmineIssueActions from '@/store/redmine/redmine.issue.actions';
import * as fromRedmineIssueReducers from '@/store/redmine/redmine.issue.reducers';
import { Store } from '@ngrx/store';
import { RedmineIssue } from '@/model/redmine.model';
import { Observable } from 'rxjs';
import { AuthService } from '@/auth/auth.service';
import { User } from '@/model/user.model';
import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class RedmineService {

  constructor(
    private store: Store<fromRedmineIssueReducers.State>,
    private authService: AuthService,
    private socket: Socket,
  ) {
    this.load();
    this.authService.getUser().then( (user: User) => {
      if(!user.redmineId) return;
      this.socket.fromEvent(`redmine:user:${user.redmineId}`).subscribe( () => {
        this.store.dispatch(fromRedmineIssueActions.fetchAssignedToMe());
      });
    })
    // setInterval(() => {
    //   this.store.dispatch(fromRedmineIssueActions.fetchAssignedToMe());
    // }, 60 * 1000);
  }

  load() {
    this.store.dispatch(fromRedmineIssueActions.fetchAssignedToMe());
  }

  fetchAssignedToMe() {
    this.store.dispatch(fromRedmineIssueActions.fetchAssignedToMe());
  }

  get all(): Observable<RedmineIssue[]> {
    return this.store.select(fromRedmineIssueReducers.selectAssignedToMe);
  }

  get latest(): Observable<RedmineIssue[]> {
    return this.all;
  }

  get count(): Observable<number> {
    return this.store.select(fromRedmineIssueReducers.selectAssignedToMeCount);
  }

  get allCount(): Observable<number> {
    return this.store.select(fromRedmineIssueReducers.selectAllCount);
  }

  get assignedCount(): Observable<number> {
    return this.store.select(fromRedmineIssueReducers.selectAssignedCount);
  }

  get watchedCount(): Observable<number> {
    return this.store.select(fromRedmineIssueReducers.selectWatchedCount);
  }

  get createdCount(): Observable<number> {
    return this.store.select(fromRedmineIssueReducers.selectCreatedCount);
  }
}

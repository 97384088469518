<div class="sub-page-container" (click)="download()">
	<div class="card widget-user sub-page-widget file-widget">
		<a>
			<div class="widget-user-header bg-primary">
		      <h5 class="widget-user-desc" style="font-size: 1.1rem;">{{item.title}}</h5>
		  </div>
		  <div class="widget-user-image">
		  	<span class="btn btn-circle btn-default img-circle" *ngIf='!isImage()'>
          <i class="far fa-file fa-3x" [class]="item.icon"></i>
        </span>
        <img class="img-circle" [src]="item | groupAvatar:90:true:'name':'path'"  *ngIf='isImage()'/>
		  </div>
		</a>
	  <div class="card-footer first">
	  	<div>
		  	<small><span class="text-muted">Rozmiar:</span> &nbsp;  <strong>{{item.size | fileSize}}</strong></small> <br>
		  	<small><span class="text-muted">Utworzono:</span> &nbsp; <strong>{{item.createdAt  | amDateFormat:'DD.MM.YYYY'}}</strong></small> <br>
        <p><a class="btn btn-link p-0 pr-1 text-sm" *ngFor="let tag of item?.tags" [routerLink]="['/search', tag | lowercase]">#{{tag}}</a></p>
	  	</div>
	  	<hr>
	  	<div [innerHtml]="item.description | safeHtml" class="description-box" ></div>
	  </div>
	</div>
</div>

import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import * as fromMyTrainingsAction from '@/store/my-training/my-training.actions';
import * as fromMyTrainingsReducers from '@/store/my-training/my-training.reducers';
import { Subject } from 'rxjs';
import { TrainingPackage } from '@/model/package.model';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { ActionsSubject, Store } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-my-training-open-start',
  templateUrl: './my-training-open-start.component.html',
  styleUrls: ['./my-training-open-start.component.scss']
})
export class MyTrainingOpenStartComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  package: TrainingPackage;
  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<fromMyTrainingsReducers.State>,
    private router: Router,
    private actionsSubject$: ActionsSubject,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data: Data) => {
      this.package = data.instance;
    })
  }

  start() {
    this.store.dispatch(fromMyTrainingsAction.startOpen({id: this.package.id}));
    this.actionsSubject$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(fromMyTrainingsAction.setOne),
      take(1))
        .subscribe( (action) => {
          console.log(action);
          this.router.navigate(['/training', action.invitation.id]);
        })
  }

  identify(index, item) {
    return item.id;
  }

  backgroundStyle () {
    if(!this.package)
      return null;
    if(!this.package.background)
      return {
        'aspect-ratio': '4',
        'background' : `#ed1a3b`,
        'background-size': 'cover'
      }
    return {
      'aspect-ratio': '16/9',
      'background' : `url(${environment.apiUrl}/thumb/1920x0/${this.package.background.replace('public','')}) center center`,
      'background-size': 'cover'
    }
  }
}

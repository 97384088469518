import { PaginationDTO } from "@/model/pagination.model";
import { Survey, SurveyQuestion } from "@/model/survey.model";
import { createAction, props } from "@ngrx/store";

export const clear = createAction("survey clear");
export const fetch = createAction("survey fetch");
export const fetchOne = createAction("survey fetchOne", props<{id: number}>());

export const setPage = createAction("survey setPage", props<{ page: number }>());
export const setOrder = createAction("survey setOrder", props<{ order: string }>());
export const setDirection = createAction("survey setDirection", props<{ asc: boolean }>());
export const setSearch = createAction("survey setSearch", props<{ search: string }>());
export const setArchive = createAction("survey setArchive", props<{ archive: boolean }>());

export const createSurvey = createAction("survey createSurvey", props<{survey: Survey, image?:any, background?: any}>());
export const updateSurvey = createAction("survey updateSurvey", props<{survey: Survey, image?:any, background?: any}>());
export const publishSurvey = createAction("survey publishSurvey", props<{survey: Survey}>());
export const deleteSurvey = createAction("survey deleteSurvey", props<{id: number}>());

export const setManySurvey = createAction("survey setManySurvey", props<PaginationDTO<Survey>>());
export const addManySurvey = createAction("survey addManySurvey", props<PaginationDTO<Survey>>());
export const addSurvey = createAction("survey addSurvey", props<{survey: Survey}>());
export const setSurvey = createAction("survey setSurvey", props<{survey: Survey}>());
export const removeSurvey = createAction("survey removeSurvey", props<{id: number}>());

export const createSurveyQuestion = createAction("survey createSurveyQuestion", props<{survey: Survey, question: SurveyQuestion}>());
export const updateSurveyQuestion = createAction("survey updateSurveyQuestion", props<{survey: Survey, question: SurveyQuestion}>());
export const deleteSurveyQuestion = createAction("survey deleteSurveyQuestion", props<{survey: Survey, question: SurveyQuestion}>());

export const addSurveyQuestion = createAction("survey addSurveyQuestion", props<{survey: Survey, question: SurveyQuestion}>());
export const setSurveyQuestion = createAction("survey setSurveyQuestion", props<{survey: Survey, question: SurveyQuestion}>());
export const removeSurveyQuestion = createAction("survey removeSurveyQuestion", props<{survey: Survey, question: SurveyQuestion}>());

export const archiveSurvey = createAction("survey archiveSurvey", props<{id: number}>());
export const cloneSurvey = createAction("survey cloneSurvey", props<{id: number}>());

import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import * as fromReducers from '@/store/notification/notification.reducers';
import * as fromActions from '@/store/notification/notification.actions';
import { AppNotification } from '@/model/notification.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ofType } from '@ngrx/effects';
import { loginSuccess } from '@/store/auth/auth.actions';
import { AuthService } from '@/auth/auth.service';
import { Socket } from 'ngx-socket-io';
import { User } from '@/model/user.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private initialLoad = false;
  constructor(
    private store: Store<fromReducers.State>,
    private actionsSubject : ActionsSubject,
    private authService : AuthService,
    private socket: Socket,
  ) {
    this.load();
    this.actionsSubject.pipe(ofType(loginSuccess)).subscribe(this.load.bind(this));
    this.authService.getUser().then( (user: User) => {
      this.socket.fromEvent(`notification:user:${user.id}`).subscribe( (notification: AppNotification) => {
        this.store.dispatch(fromActions.add({notification}));
      });
    })
    // setInterval(() => {
    //   if(!this.authService.isAuthenticated())return;
    //   this.store.dispatch(fromActions.fetch());
    // }, 10 * 1000);
  }

  public load() {
    this.store.dispatch(fromActions.fetch());
    this.actionsSubject.pipe(ofType(fromActions.set)).subscribe( () => {this.initialLoad = true;})
  }

  get loaded() {
    return this.initialLoad;
  }

  count(): Observable<number>{
    return this.store.pipe(select(fromReducers.selectAllNotification), map((arr: AppNotification[]) => arr.filter(x => !x?.seen).length));
  }

  countPosts(): Observable<number>{
    return this.store.pipe(select(fromReducers.selectAllNotification), map((arr: AppNotification[]) => arr.filter(x => x.type == 'post').length));
  }

  list(): Observable<AppNotification[]>{
    return this.store.pipe(select(fromReducers.selectAllNotification));//, map((arr: AppNotification[]) => arr.slice(0,10)));
  }

  recent(): Observable<AppNotification[]>{
    return this.list().pipe(map((arr: AppNotification[]) => arr.slice(0,10)));
  }

  delete(notification: AppNotification) {
    this.store.dispatch(fromActions.deleteNotification({notification}));
  }
}

<section class="content">
  <div class="container pb-3 pt-3">
    <form [formGroup]="form" (ngSubmit)="save()">
      <div class="card card-primary">
        <div class="card-body">
          <div class="form-group">
            <label for="inputName">Nazwa pokoju</label>
            <input
              formControlName="name"
              required
              type="text"
              class="form-control"
              placeholder="Nazwa pokoju"
            />
          </div>
        </div>
        <div class="card-footer">
          <button class="btn btn-success float-right" type="submit">
            Dalej
            <i class="fas fa-angle-right fa-fw"></i>
          </button>
        </div>
      </div>
    </form>
  </div>
</section>

<section class="content">
  <div class="container pt-3 pb-3">
    <form [formGroup]="form" (ngSubmit)="save()">
      <div class="card">
        <div class="card-header d-flex align-items-center">
          <h3 class="card-title"><i class="fas fa-user-lock fa-fw"></i> Edycja roli</h3>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label for="inputName">Nazwa roli</label>
            <input
              formControlName="name"
              type="text"
              class="form-control"
              placeholder="Nazwa roli"
              [appIsValid]="form.controls.name"/>
            <div class="invalid-feedback" *ngIf="form.controls.name.errors?.required">Pole jest wymagane</div>
          </div>
        </div>
      </div>

      <div class="alert alert-warning" role="alert" *ngIf="role?.root">
        Nie można edytować roli administratora
      </div>

      <hr>
      <h3 class="pb-2">Uprawnienia:</h3>
      <div formGroupName="privileges">

          <div class="card card-secondary" formGroupName="news">
            <div class="card-header">Posty</div>
            <table class="table table-bordered mb-0">

              <tr>
                <th class="text-nowrap">Podgląd</th>
                <td style="width:100%"><input type="checkbox" formControlName="view"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Tworzenie</th>
                <td style="width:100%"><input type="checkbox" formControlName="create"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Edycja</th>
                <td style="width:100%">
                  <label class="pr-3">
                    <input type="radio" formControlName="edit" [value]="AllOrCreated.ALL"> Wszystkie
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="edit" [value]="AllOrCreated.CREATED"> Utworzone
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="edit" [value]="AllOrCreated.NONE"> Brak
                  </label>
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">Usuwanie</th>
                <td style="width:100%">
                  <label class="pr-3">
                    <input type="radio" formControlName="delete" [value]="AllOrCreated.ALL"> Wszystkie
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="delete" [value]="AllOrCreated.CREATED"> Utworzone
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="delete" [value]="AllOrCreated.NONE"> Brak
                  </label>
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">Publikacja</th>
                <td style="width:100%; padding:0" formGroupName="publicate">
                  <table class="table table-bordered mb-0">
                    <tr>
                      <th class="text-nowrap">Do wszystkich</th>
                      <td style="width:100%"><input type="checkbox" formControlName="all"></td>
                    </tr>
                    <tr>
                      <th class="text-nowrap">Do grupy</th>
                      <td style="width:100%"><input type="checkbox" formControlName="group"></td>
                    </tr>
                    <tr>
                      <th class="text-nowrap">SMS</th>
                      <td style="width:100%"><input type="checkbox" formControlName="sms"></td>
                    </tr>
                    <tr>
                      <th class="text-nowrap">Powiadomienia PUSH</th>
                      <td style="width:100%"><input type="checkbox" formControlName="push"></td>
                    </tr>
                    <tr>
                      <th class="text-nowrap">Newsy kluczowe</th>
                      <td style="width:100%"><input type="checkbox" formControlName="important"></td>
                    </tr>

                  </table>
                </td>
              </tr>

            </table>
          </div>

          <div class="card card-secondary" formGroupName="comments">
            <div class="card-header">Komentarze</div>
            <table class="table table-bordered mb-0">
              <tr>
                <th class="text-nowrap">Usuwanie</th>
                <td style="width:100%">
                  <label class="pr-3">
                    <input type="radio" formControlName="delete" [value]="AllOrCreated.ALL"> Wszystkie
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="delete" [value]="AllOrCreated.CREATED"> Utworzone
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="delete" [value]="AllOrCreated.NONE"> Brak
                  </label>
                </td>
              </tr>
            </table>
          </div>

          <div class="card card-secondary" formGroupName="groups">
            <div class="card-header">Grupy</div>
            <table class="table table-bordered mb-0">
              <tr>
                <th class="text-nowrap">Podgląd</th>
                <td style="width:100%"><input type="checkbox" formControlName="list"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Tworzenie</th>
                <td style="width:100%"><input type="checkbox" formControlName="create"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Edycja</th>
                <td style="width:100%">
                  <label class="pr-3">
                    <input type="radio" formControlName="edit" [value]="AllOrCreated.ALL"> Wszystkie
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="edit" [value]="AllOrCreated.CREATED"> Utworzone
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="edit" [value]="AllOrCreated.NONE"> Brak
                  </label>
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">Usuwanie</th>
                <td style="width:100%">
                  <label class="pr-3">
                    <input type="radio" formControlName="delete" [value]="AllOrCreated.ALL"> Wszystkie
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="delete" [value]="AllOrCreated.CREATED"> Utworzone
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="delete" [value]="AllOrCreated.NONE"> Brak
                  </label>
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">Nadrzędne</th>
                <td style="width:100%; padding:0" formGroupName="superior">
                  <table class="table table-bordered mb-0">
                    <tr>
                      <th class="text-nowrap">Tworzenie</th>
                      <td style="width:100%"><input type="checkbox" formControlName="create"></td>
                    </tr>

                    <tr>
                      <th class="text-nowrap">Edycja</th>
                      <td style="width:100%">
                        <label class="pr-3">
                          <input type="radio" formControlName="edit" [value]="AllOrCreated.ALL"> Wszystkie
                        </label>
                        <label class="pr-3">
                          <input type="radio" formControlName="edit" [value]="AllOrCreated.CREATED"> Utworzone
                        </label>
                        <label class="pr-3">
                          <input type="radio" formControlName="edit" [value]="AllOrCreated.NONE"> Brak
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <th class="text-nowrap">Usuwanie</th>
                      <td style="width:100%">
                        <label class="pr-3">
                          <input type="radio" formControlName="delete" [value]="AllOrCreated.ALL"> Wszystkie
                        </label>
                        <label class="pr-3">
                          <input type="radio" formControlName="delete" [value]="AllOrCreated.CREATED"> Utworzone
                        </label>
                        <label class="pr-3">
                          <input type="radio" formControlName="delete" [value]="AllOrCreated.NONE"> Brak
                        </label>
                      </td>
                    </tr>

                  </table>
                </td>
              </tr>
            </table>
          </div>


          <div class="card card-secondary" formGroupName="chat">
            <div class="card-header">Chat</div>
            <table class="table table-bordered mb-0">
              <tr>
                <th class="text-nowrap">Pokoje</th>
                <td style="width:100%; padding:0" formGroupName="room">
                  <table class="table table-bordered mb-0">
                    <tr>
                      <th class="text-nowrap">Tworzenie</th>
                      <td style="width:100%"><input type="checkbox" formControlName="create"></td>
                    </tr>

                    <tr>
                      <th class="text-nowrap">Edycja</th>
                      <td style="width:100%">
                        <label class="pr-3">
                          <input type="radio" formControlName="edit" [value]="AllOrCreated.ALL"> Wszystkie
                        </label>
                        <label class="pr-3">
                          <input type="radio" formControlName="edit" [value]="AllOrCreated.CREATED"> Utworzone
                        </label>
                        <label class="pr-3">
                          <input type="radio" formControlName="edit" [value]="AllOrCreated.NONE"> Brak
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <th class="text-nowrap">Usuwanie</th>
                      <td style="width:100%">
                        <label class="pr-3">
                          <input type="radio" formControlName="delete" [value]="AllOrCreated.ALL"> Wszystkie
                        </label>
                        <label class="pr-3">
                          <input type="radio" formControlName="delete" [value]="AllOrCreated.CREATED"> Utworzone
                        </label>
                        <label class="pr-3">
                          <input type="radio" formControlName="delete" [value]="AllOrCreated.NONE"> Brak
                        </label>
                      </td>
                    </tr>

                  </table>
                </td>
              </tr>
            </table>
          </div>

          <div class="card card-secondary" formGroupName="messages">
            <div class="card-header">Wiadomości</div>
            <table class="table table-bordered mb-0">
              <tr>
                <th class="text-nowrap">Wysyłka</th>
                <td style="width:100%; padding:0" formGroupName="send">
                  <table class="table table-bordered mb-0">
                    <tr>
                      <th class="text-nowrap">Do wszystkich</th>
                      <td style="width:100%"><input type="checkbox" formControlName="all"></td>
                    </tr>
                    <tr>
                      <th class="text-nowrap">Do grupy</th>
                      <td style="width:100%"><input type="checkbox" formControlName="group"></td>
                    </tr>
                    <tr>
                      <th class="text-nowrap">SMS</th>
                      <td style="width:100%"><input type="checkbox" formControlName="sms"></td>
                    </tr>
                    <tr>
                      <th class="text-nowrap">Powiadomienia PUSH</th>
                      <td style="width:100%"><input type="checkbox" formControlName="push"></td>
                    </tr>

                  </table>
                </td>
              </tr>
            </table>
          </div>

          <div class="card card-secondary" formGroupName="users">
            <div class="card-header">Użytkownicy</div>
            <table class="table table-bordered mb-0">
              <tr>
                <th class="text-nowrap">Lista</th>
                <td style="width:100%"><input type="checkbox" formControlName="list"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Tworzenie</th>
                <td style="width:100%"><input type="checkbox" formControlName="create"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Edycja</th>
                <td style="width:100%"><input type="checkbox" formControlName="edit"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Usuwanie</th>
                <td style="width:100%"><input type="checkbox" formControlName="delete"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Zmiana hasła</th>
                <td style="width:100%"><input type="checkbox" formControlName="password"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Zmiana roli</th>
                <td style="width:100%"><input type="checkbox" formControlName="role"></td>
              </tr>
              <!-- <tr>
                <th class="text-nowrap">Przyznawanie nagród</th>
                <td style="width:100%">
                  <label class="pr-3">
                    <input type="radio" formControlName="prize" [value]="AllOrCreated.ALL"> Wszystkie
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="prize" [value]="AllOrCreated.CREATED"> Utworzone
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="prize" [value]="AllOrCreated.NONE"> Brak
                  </label>
                </td>
              </tr> -->
            </table>
          </div>

          <div class="card card-secondary" formGroupName="structure">
            <div class="card-header">Struktura firmy</div>
            <table class="table table-bordered mb-0">
              <tr>
                <th class="text-nowrap">Lista</th>
                <td style="width:100%"><input type="checkbox" formControlName="list"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Tworzenie</th>
                <td style="width:100%"><input type="checkbox" formControlName="create"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Edycja</th>
                <td style="width:100%"><input type="checkbox" formControlName="edit"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Usuwanie</th>
                <td style="width:100%"><input type="checkbox" formControlName="delete"></td>
              </tr>
            </table>
          </div>

          <div class="card card-secondary" formGroupName="offers">
            <div class="card-header">Oferty</div>
            <table class="table table-bordered mb-0">
              <tr>
                <th class="text-nowrap">Lista</th>
                <td style="width:100%"><input type="checkbox" formControlName="list"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Tworzenie</th>
                <td style="width:100%"><input type="checkbox" formControlName="create"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Edycja</th>
                <td style="width:100%"><input type="checkbox" formControlName="edit"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Usuwanie</th>
                <td style="width:100%"><input type="checkbox" formControlName="delete"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Lista kandydatów</th>
                <td style="width:100%"><input type="checkbox" formControlName="candidates"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Zarządzanie kategoriami</th>
                <td style="width:100%"><input type="checkbox" formControlName="category"></td>
              </tr>

            </table>
          </div>

          <div class="card card-secondary" formGroupName="orders">
            <div class="card-header">Zamówienia</div>
            <table class="table table-bordered mb-0">
              <tr>
                <th class="text-nowrap">Lista</th>
                <td style="width:100%"><input type="checkbox" formControlName="list"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Tworzenie</th>
                <td style="width:100%"><input type="checkbox" formControlName="create"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Edycja</th>
                <td style="width:100%"><input type="checkbox" formControlName="edit"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Usuwanie</th>
                <td style="width:100%"><input type="checkbox" formControlName="delete"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Składanie zamówień</th>
                <td style="width:100%"><input type="checkbox" formControlName="order"></td>
              </tr>
            </table>
          </div>

          <div class="card card-secondary" formGroupName="kb">
            <div class="card-header">Baza Wiedzy / Benefity / Procedury / FAQ</div>
            <table class="table table-bordered mb-0">
              <tr>
                <th class="text-nowrap">Lista</th>
                <td style="width:100%"><input type="checkbox" formControlName="list"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Zarządzanie</th>
                <td style="width:100%; padding:0" formGroupName="admin">
                  <table class="table table-bordered mb-0">
                    <tr>
                      <th class="text-nowrap">Foldery</th>
                      <td style="width:100%; padding:0">
                        <table class="table table-bordered mb-0" formGroupName="folders">
                          <tr>
                            <th class="text-nowrap">Tworzenie</th>
                            <td style="width:100%"><input type="checkbox" formControlName="create"></td>
                          </tr>
                          <tr>
                            <th class="text-nowrap">Edycja</th>
                            <td style="width:100%"><input type="checkbox" formControlName="edit"></td>
                          </tr>
                          <tr>
                            <th class="text-nowrap">Usuwanie</th>
                            <td style="width:100%"><input type="checkbox" formControlName="delete"></td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <th class="text-nowrap">Artykuły</th>
                      <td style="width:100%; padding:0">
                        <table class="table table-bordered mb-0" formGroupName="articles">
                          <tr>
                            <th class="text-nowrap">Tworzenie</th>
                            <td style="width:100%"><input type="checkbox" formControlName="create"></td>
                          </tr>
                          <tr>
                            <th class="text-nowrap">Edycja</th>
                            <td style="width:100%"><input type="checkbox" formControlName="edit"></td>
                          </tr>
                          <tr>
                            <th class="text-nowrap">Usuwanie</th>
                            <td style="width:100%"><input type="checkbox" formControlName="delete"></td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <th class="text-nowrap">Pliki</th>
                      <td style="width:100%; padding:0">
                        <table class="table table-bordered mb-0" formGroupName="files">
                          <tr>
                            <th class="text-nowrap">Tworzenie</th>
                            <td style="width:100%"><input type="checkbox" formControlName="create"></td>
                          </tr>
                          <tr>
                            <th class="text-nowrap">Edycja</th>
                            <td style="width:100%"><input type="checkbox" formControlName="edit"></td>
                          </tr>
                          <tr>
                            <th class="text-nowrap">Usuwanie</th>
                            <td style="width:100%"><input type="checkbox" formControlName="delete"></td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>

          <div class="card card-secondary" formGroupName="ideas">
            <div class="card-header">Pomysły</div>
            <table class="table table-bordered mb-0">
              <tr>
                <th class="text-nowrap">Lista</th>
                <td style="width:100%"><input type="checkbox" formControlName="list"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Tworzenie</th>
                <td style="width:100%"><input type="checkbox" formControlName="create"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Edycja</th>
                <td style="width:100%"><input type="checkbox" formControlName="edit"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Usuwanie</th>
                <td style="width:100%">
                  <label class="pr-3">
                    <input type="radio" formControlName="delete" [value]="AllOrCreated.ALL"> Wszystkie
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="delete" [value]="AllOrCreated.CREATED"> Utworzone
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="delete" [value]="AllOrCreated.NONE"> Brak
                  </label>
                </td>
              </tr>
            </table>
          </div>

          <div class="card card-secondary" formGroupName="prizes">
            <div class="card-header">Nagrody</div>
            <table class="table table-bordered mb-0">
              <tr>
                <th class="text-nowrap">Lista</th>
                <td style="width:100%"><input type="checkbox" formControlName="list"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Tworzenie</th>
                <td style="width:100%"><input type="checkbox" formControlName="create"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Edycja</th>
                <td style="width:100%"><input type="checkbox" formControlName="edit"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Usuwanie</th>
                <td style="width:100%"><input type="checkbox" formControlName="delete"></td>
              </tr>
            </table>
          </div>

          <div class="card card-secondary" formGroupName="reservations">
            <div class="card-header">Rezerwacje</div>
            <table class="table table-bordered mb-0">
              <tr>
                <th class="text-nowrap">Lista</th>
                <td style="width:100%"><input type="checkbox" formControlName="list"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Rezerwacja</th>
                <td style="width:100%; padding:0" formGroupName="reservation">
                  <table class="table table-bordered mb-0">
                    <tr>
                      <th class="text-nowrap">Tworzenie</th>
                      <td style="width:100%"><input type="checkbox" formControlName="create"></td>
                    </tr>
                    <tr>
                      <th class="text-nowrap">Usuwanie</th>
                      <td style="width:100%">
                        <label class="pr-3">
                          <input type="radio" formControlName="delete" [value]="AllOrCreated.ALL"> Wszystkie
                        </label>
                        <label class="pr-3">
                          <input type="radio" formControlName="delete" [value]="AllOrCreated.CREATED"> Utworzone
                        </label>
                        <label class="pr-3">
                          <input type="radio" formControlName="delete" [value]="AllOrCreated.NONE"> Brak
                        </label>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">Zarządzanie</th>
                <td style="width:100%; padding:0" formGroupName="admin">
                  <table class="table table-bordered mb-0">
                    <tr>
                      <th class="text-nowrap">Kategorie</th>
                      <td style="width:100%; padding:0">
                        <table class="table table-bordered mb-0" formGroupName="category">
                          <tr>
                            <th class="text-nowrap">Tworzenie</th>
                            <td style="width:100%"><input type="checkbox" formControlName="create"></td>
                          </tr>
                          <tr>
                            <th class="text-nowrap">Edycja</th>
                            <td style="width:100%"><input type="checkbox" formControlName="edit"></td>
                          </tr>
                          <tr>
                            <th class="text-nowrap">Usuwanie</th>
                            <td style="width:100%"><input type="checkbox" formControlName="delete"></td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <th class="text-nowrap">Zasoby</th>
                      <td style="width:100%; padding:0">
                        <table class="table table-bordered mb-0" formGroupName="assets">
                          <tr>
                            <th class="text-nowrap">Tworzenie</th>
                            <td style="width:100%"><input type="checkbox" formControlName="create"></td>
                          </tr>
                          <tr>
                            <th class="text-nowrap">Edycja</th>
                            <td style="width:100%"><input type="checkbox" formControlName="edit"></td>
                          </tr>
                          <tr>
                            <th class="text-nowrap">Usuwanie</th>
                            <td style="width:100%">
                              <label class="pr-3">
                                <input type="radio" formControlName="delete" [value]="AllOrCreated.ALL"> Wszystkie
                              </label>
                              <label class="pr-3">
                                <input type="radio" formControlName="delete" [value]="AllOrCreated.CREATED"> Utworzone
                              </label>
                              <label class="pr-3">
                                <input type="radio" formControlName="delete" [value]="AllOrCreated.NONE"> Brak
                              </label>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>

          <div class="card card-secondary" formGroupName="training">
            <div class="card-header">Szkolenia</div>
            <table class="table table-bordered mb-0">
              <tr>
                <th class="text-nowrap">Lista</th>
                <td style="width:100%"><input type="checkbox" formControlName="view"></td>
              </tr>
              <tr formGroupName="admin">
                <th class="text-nowrap">Zarządanie</th>
                <td style="width:100%"><input type="checkbox" formControlName="scorm"></td>
              </tr>
            </table>
          </div>

          <div class="card card-secondary" formGroupName="survey">
            <div class="card-header">Ankiety</div>
            <table class="table table-bordered mb-0">
              <tr>
                <th class="text-nowrap">Lista</th>
                <td style="width:100%"><input type="checkbox" formControlName="view"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Zarządanie</th>
                <td style="width:100%"><input type="checkbox" formControlName="admin"></td>
              </tr>
            </table>
          </div>

          <div class="card card-secondary" formGroupName="outpost">
            <div class="card-header">Placówki</div>
            <table class="table table-bordered mb-0">
              <tr>
                <th class="text-nowrap">Lista</th>
                <td style="width:100%"><input type="checkbox" formControlName="view"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Zarządanie</th>
                <td style="width:100%"><input type="checkbox" formControlName="admin"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Sloty</th>
                <td style="width:100%; padding:0">
                  <table class="table table-bordered mb-0" formGroupName="slot">
                    <tr>
                      <th class="text-nowrap">Zapisanie się</th>
                      <td style="width:100%"><input type="checkbox" formControlName="sign"></td>
                    </tr>
                    <tr>
                      <th class="text-nowrap">Wypisanie się</th>
                      <td style="width:100%">
                        <label class="pr-3">
                          <input type="radio" formControlName="unsign" [value]="AllOrCreated.ALL"> Wszystkie
                        </label>
                        <label class="pr-3">
                          <input type="radio" formControlName="unsign" [value]="AllOrCreated.CREATED"> Moje
                        </label>
                        <label class="pr-3">
                          <input type="radio" formControlName="unsign" [value]="AllOrCreated.NONE"> Brak
                        </label>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>

          <div class="card card-secondary" formGroupName="vacant">
            <div class="card-header">Wakaty</div>
            <table class="table table-bordered mb-0">
              <tr>
                <th class="text-nowrap">Lista</th>
                <td style="width:100%"><input type="checkbox" formControlName="view"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Lista wszystkich</th>
                <td style="width:100%"><input type="checkbox" formControlName="viewAll"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Tworzenie</th>
                <td style="width:100%"><input type="checkbox" formControlName="create"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Tworzenie na własnych slotach</th>
                <td style="width:100%"><input type="checkbox" formControlName="createMy"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Edycja</th>
                <td style="width:100%">
                  <label class="pr-3">
                    <input type="radio" formControlName="edit" [value]="AllOrCreated.ALL"> Wszystkie
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="edit" [value]="AllOrCreated.CREATED"> Utworzone
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="edit" [value]="AllOrCreated.NONE"> Brak
                  </label>
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">Usuwanie</th>
                <td style="width:100%">
                  <label class="pr-3">
                    <input type="radio" formControlName="delete" [value]="AllOrCreated.ALL"> Wszystkie
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="delete" [value]="AllOrCreated.CREATED"> Utworzone
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="delete" [value]="AllOrCreated.NONE"> Brak
                  </label>
                </td>
              </tr>


              <tr>
                <th class="text-nowrap">Rezerwacje</th>
                <td style="width:100%; padding:0">
                  <table class="table table-bordered mb-0" formGroupName="reservation">
                    <tr>
                      <th class="text-nowrap">Tworzenie</th>
                      <td style="width:100%"><input type="checkbox" formControlName="create"></td>
                    </tr>
                    <tr>
                      <th class="text-nowrap">Usuwanie</th>
                      <td style="width:100%">
                        <label class="pr-3">
                          <input type="radio" formControlName="delete" [value]="AllOrCreated.ALL"> Wszystkie
                        </label>
                        <label class="pr-3">
                          <input type="radio" formControlName="delete" [value]="AllOrCreated.CREATED"> Utworzone
                        </label>
                        <label class="pr-3">
                          <input type="radio" formControlName="delete" [value]="AllOrCreated.NONE"> Brak
                        </label>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>

          <div class="card card-secondary" formGroupName="redmine">
            <div class="card-header">Zgłaszanie problemów (Redmine)</div>
            <table class="table table-bordered mb-0">
              <tr>
                <th class="text-nowrap">Zgłaszanie / przeglądanie</th>
                <td style="width:100%"><input type="checkbox" formControlName="view"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Zarządanie kategoriami</th>
                <td style="width:100%"><input type="checkbox" formControlName="admin"></td>
              </tr>
            </table>
          </div>

          <div class="card card-secondary" formGroupName="statistics">
            <div class="card-header">Statystyki osób pracujących na PP</div>
            <table class="table table-bordered mb-0">
              <tr>
                <th class="text-nowrap">Podgląd</th>
                <td style="width:100%"><input type="checkbox" formControlName="view"></td>
              </tr>
            </table>
          </div>
          <div class="card card-secondary" formGroupName="callcenter">
            <div class="card-header">Oceny rozmów</div>
            <table class="table table-bordered mb-0">
              <tr>
                <th class="text-nowrap">Przeglądanie ocen</th>
                <td style="width:100%">
                  <label class="pr-3">
                    <input type="radio" formControlName="view" [value]="AllOrCreated.ALL"> Wszystkie
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="view" [value]="AllOrCreated.CREATED"> Utworzone
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="view" [value]="AllOrCreated.USER"> Moje oceny
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="view" [value]="AllOrCreated.NONE"> Brak
                  </label>
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">Wprowadzanie ocen</th>
                <td style="width:100%"><input type="checkbox" formControlName="fill"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Edycja wprowadzonych ocen</th>
                <td style="width:100%">
                  <label class="pr-3">
                    <input type="radio" formControlName="fillEdit" [value]="AllOrCreated.ALL"> Wszystkie
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="fillEdit" [value]="AllOrCreated.CREATED"> Utworzone
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="fillEdit" [value]="AllOrCreated.NONE"> Brak
                  </label>
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">Usuwanie wprowadzonych ocen</th>
                <td style="width:100%">
                  <label class="pr-3">
                    <input type="radio" formControlName="fillDelete" [value]="AllOrCreated.ALL"> Wszystkie
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="fillDelete" [value]="AllOrCreated.CREATED"> Utworzone
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="fillDelete" [value]="AllOrCreated.NONE"> Brak
                  </label>
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">Tworzenie kwestionariuszy</th>
                <td style="width:100%"><input type="checkbox" formControlName="create"></td>
              </tr>
              <tr>
                <th class="text-nowrap">Edycja kwestionariuszy</th>
                <td style="width:100%">
                  <label class="pr-3">
                    <input type="radio" formControlName="edit" [value]="AllOrCreated.ALL"> Wszystkie
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="edit" [value]="AllOrCreated.CREATED"> Utworzone
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="edit" [value]="AllOrCreated.NONE"> Brak
                  </label>
                </td>
              </tr>
              <tr>
                <th class="text-nowrap">Usuwanie kwestionariuszy</th>
                <td style="width:100%">
                  <label class="pr-3">
                    <input type="radio" formControlName="delete" [value]="AllOrCreated.ALL"> Wszystkie
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="delete" [value]="AllOrCreated.CREATED"> Utworzone
                  </label>
                  <label class="pr-3">
                    <input type="radio" formControlName="delete" [value]="AllOrCreated.NONE"> Brak
                  </label>
                </td>
              </tr>
            </table>
          </div>

          <div class="card card-secondary" formGroupName="roles">
            <div class="card-header">Zarządzanie rolami</div>
            <table class="table table-bordered mb-0">
              <tr>
                <th class="text-nowrap">Zarządanie</th>
                <td style="width:100%"><input type="checkbox" formControlName="admin"></td>
              </tr>
            </table>
          </div>
      </div>


      <button type="submit" class="btn btn-success" [disabled]="role?.root">Zapisz</button>

    </form>
  </div>
</section>

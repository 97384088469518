import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { ofType } from '@ngrx/effects';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import * as fromSurveyAction from '@/store/survey/survey.actions';
import * as fromSurveyReducers from '@/store/survey/survey.reducers';
import { Survey, SurveyQuestion } from '@/model/survey.model';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SurveyAdminQuestionModalComponent } from '../survey-admin-question-modal/survey-admin-question-modal.component';

@Component({
  selector: 'app-survey-admin-questions',
  templateUrl: './survey-admin-questions.component.html',
  styleUrls: ['./survey-admin-questions.component.scss']
})
export class SurveyAdminQuestionsComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  survey: Survey;
  imageFile: NzUploadFile;
  backgroundFile: NzUploadFile;

  constructor(
    private toastr: ToastrService,
    private store: Store<fromSurveyReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data:Data) => {
      this.survey = {...this.survey, ...data.instance};
      this.store.pipe(
        select(fromSurveyReducers.selectOneSurvey, this.survey.id),
        takeUntil(this.unsubscribe$),
        distinctUntilChanged()
      ).subscribe((survey) => {
        this.survey = survey;
      })
    })
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get questions(): SurveyQuestion[] {
    return fromSurveyReducers.questionAdapter.getSelectors().selectAll(this.survey.questions);
  }

  addQuestion() {
    const modalRef = this.modalService.open(SurveyAdminQuestionModalComponent, {size: 'xl',scrollable: false});
    (modalRef.componentInstance as SurveyAdminQuestionModalComponent).survey = this.survey;
  }

  editQuestion(item: SurveyQuestion) {
    const modalRef = this.modalService.open(SurveyAdminQuestionModalComponent, {size: 'xl',scrollable: false});
    (modalRef.componentInstance as SurveyAdminQuestionModalComponent).survey = this.survey;
    (modalRef.componentInstance as SurveyAdminQuestionModalComponent).question = item;
  }

  deleteQuestion(item: SurveyQuestion) {
    this.store.dispatch(fromSurveyAction.deleteSurveyQuestion({survey: this.survey, question: item}));
  }

  getTypeName(item: SurveyQuestion): string {
    return SurveyAdminQuestionModalComponent.types[item.type];
  }

  checkMultiple(item: SurveyQuestion): boolean {
    return true;//QuizQuestionModalComponent.isMultiple(item.type);
  }
}


import { stripTags } from '@/utils/plainText';
import { Pipe, PipeTransform } from '@angular/core';

const charsArond = 50;

@Pipe({
  name: 'truncHighlight'
})
export class TruncHighlightPipe implements PipeTransform {

  transform(str: string): string {
    str = stripTags(str);
    const regex2 = /(?:[^\s\r\n]+[\s\r\n]+[^\s\r\n]*){0,6}test(?:[^\s\r\n]*[\s\r\n]+[^\s\r\n]+){0,6}/u;
    // let m;
    // let res = '';
    // while ((m = regex.exec(str)) !== null) {
    //     // This is necessary to avoid infinite loops with zero-width matches
    //     if (m.index === regex.lastIndex) {
    //         regex.lastIndex++;
    //     }

    //     // The result can be accessed through the `m`-variable.
    //     m.forEach((match, groupIndex) => {
    //         // console.log(`Found match, group ${groupIndex}: ${match}`);
    //         let part = `...${match}... &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`;
    //         part = part.replace('libero', '<strong>libero</strong>');
    //         res += part;
    //         console.log(part, res);
    //     });
    // }
    let s = str;
    s = s.replace(/<[^>]*>?/gm, '');
    var regex = /Integer/gi, result, indices = [];
    while ( (result = regex.exec(s)) ) {
        let begin = Math.max(0, result.index-charsArond);
        let end = Math.min(s.length-1,result.index+ charsArond);
        let sub = s.substr(begin, end - begin);
        console.log(regex2.exec(sub));
    }
    return str;
  }

}

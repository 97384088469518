import { TrainingNotification } from '@/model/package.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { from, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrainingInvitationRedirectResolver implements Resolve<boolean> {

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService
  ) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return from( new Promise<boolean>(resolve => {
      let id = 1*route.params.package;
      console.log(route);
      this.http.get<TrainingNotification>(`<backendhost>/v1/my-trainings/${id}/0/from-package`, {
        observe: 'body',
        responseType: 'json'}
      ).subscribe(
        (invite:TrainingNotification) => {
          this.router.navigate(['/training', invite.id])
        },
        (errorResponse: HttpErrorResponse ) => {
          this.toastr.error(errorResponse.error.message);
          this.router.navigate(['/']);
        }
      )
    }));
  }
}

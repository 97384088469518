import {Component, OnDestroy, OnInit} from '@angular/core';
import * as fromUsersActions from '@/store/users/users.actions'
import * as fromUsersReducers from '@/store/users/users.reducers'
import { select, Store } from '@ngrx/store';
import { ActivatedRoute, Data, ParamMap } from '@angular/router';
import { map, takeUntil, tap } from 'rxjs/operators';
import { User } from '@/model/user.model';
import { Observable, of, Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrizeModalComponent } from './prize-modal/prize-modal.component';
@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  private id: number;
  constructor(
    private activatedRoute: ActivatedRoute,
    private strore$: Store<fromUsersReducers.State>,
    protected modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe( (data:Data) => {this.id = data.instance.id});
    // this.activatedRoute.paramMap.pipe(map((params: ParamMap): number => +params.get('id'))).subscribe((id: number) => {
    //   this.id = id;
    //   this.strore$.dispatch(fromUsersActions.fetchOneUser({id}));
    // })
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  get user(): Observable<User> {
    return this.strore$.pipe(takeUntil(this.unsubscribe$), select(fromUsersReducers.selectOneUser,this.id));
  }

  backgroundStyle() : Observable<Object> {
    return this.user.pipe(map(user => {
      if(!user)
        return of(null);
      if(!user.background)
        return null;
      return {
        // height: '250px',
        'aspect-ratio': '4 / 1',
        background : `url(${environment.apiUrl}/thumb/1920x0/${user.background.replace('public','')}) center center`,
        'background-size' : 'cover'
      }
    }));
  }

  showPrize(item) {
    const modalRef = this.modalService.open(PrizeModalComponent, {size: 'xl',scrollable: true});
    modalRef.componentInstance.usersPrize = item;
  }
}

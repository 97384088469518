<div class="content">
  <div class="container pt-3 pb-3">
    <div class="row">
      <div class="col-12">
        <form [formGroup]="form" (ngSubmit)="save()">
        <div class="card card-primary">
          <div class="overlay" *ngIf="(saving | async) || uploading">
            <div style="position: sticky; top:50%; left:50%; align-self: baseline;text-align: center;  padding: 25px 0">
              <i class="fas fa-3x fa-spinner fa-spin"></i>
              <p *ngIf="saving | async">Zapisywanie...</p>
              <p *ngIf="uploading">Ładowanie obrazów {{fileUploadedCount}} z {{filesToUploadCount}}...</p>
            </div>
          </div>
          <div class="card-body">

            <div class="form-group">
              <label for="title">Tytuł</label>
              <input formControlName="title" [appIsValid]="form.controls.title" type="text" class="form-control" placeholder="Tytuł" />
              <div class="invalid-feedback" *ngIf="form.controls.title.errors?.required">
                Pole jest wymagane
              </div>
            </div>
            <div class="form-group">
              <label for="content">Treść</label>
              <app-editor formControlName="content"></app-editor>
              <div class="text-danger" *ngIf="form.controls.content.touched && form.controls.content.errors?.required">
                Pole jest wymagane
              </div>
            </div>

            <div class="form-group">
              <label for="inputName">Obrazek w tle</label>
              <div style="max-width: 100%;">
                <app-upload-crop-image
                  [avatar]="false"
                  [(file)] = "backgroundFile"
                  [aspectRatio]="4 / 1">
                </app-upload-crop-image>
                <!-- <nz-upload
                  class="bg-uploader  w-100"
                  nzAccept="image/png, image/jpeg"
                  nzName="image"
                  nzListType="picture-card"
                  [nzShowUploadList]="false"
                  [nzBeforeUpload]="beforeUploadBackground"
                >
                  <ng-container *ngIf="!post?.background" class="w-100">
                    <i class="fas fa-3x fa-image"></i>
                  </ng-container>
                  <img *ngIf="post?.background" [src]="post.background | image" style="width: 100%" />
                </nz-upload> -->
              </div>
            </div>
            <div class="form-group col-md-12">
              <label>Plik Audio</label>

              <div class="d-flex" style="flex-direction: column;" *ngIf="audioPath">
                <audio height="100%" controls>
                  <source [src]="audioPath" type="audio/mp3">
                  Your browser does not support the video tag.
                </audio>
                <button class="btn btn-danger" (click)="clearAudio()">Wyczyść plik audio</button>
              </div>
              <!-- <div *ngIf="slide.audio" plyr style="width: 100%;" [plyrPlaysInline]="true" plyrType="audio" [plyrSources]="audioPath" ></div> -->
              <div>
                <nz-upload
                  nzType="drag"
                  [nzMultiple]="false"
                  [(nzFileList)]="audioFile"
                  [nzBeforeUpload]="beforeUploadAudio"
                >
                <!-- [nzFileType]="'audio/mp3'" -->
                <div>
                  <p class="ant-upload-drag-icon">
                    <i nz-icon nzType="inbox"></i>
                  </p>
                  <p class="ant-upload-text">Kliknij lub przeciągnij plik do tego obszaru, aby przesłać</p>
                  <p class="ant-upload-hint"></p>
                </div>
                </nz-upload>
              </div>
            </div>
            <div class="form-group">
              <label>Tagi</label>
              <tag-input
                [(ngModel)]='post.tags'
                [ngModelOptions]="{standalone: true}"
                theme='bootstrap'
                secondaryPlaceholder="Wpisz nowy tag"></tag-input>
            </div>
            <div class="form-group">
              <label>Galeria</label>
              <nz-upload
                nzAccept="image/png, image/jpeg"
                nzType="drag"
                [nzMultiple]="true"
                nzListType="picture"
                [(nzFileList)]="fileList"
                [nzBeforeUpload]="beforeUpload"
              >
              <p class="ant-upload-drag-icon">
                <i nz-icon nzType="inbox"></i>
              </p>
              <p class="ant-upload-text">Kliknij lub przeciągnij plik do tego obszaru, aby przesłać</p>
              <p class="ant-upload-hint"></p>
              </nz-upload>
            </div>
            <div class="form-group">
              <label>Data początku ważności newsa</label>
              <div class="input group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <input type="checkbox"
                      [(ngModel)]='post.startDateActive'
                      [ngModelOptions]="{standalone: true}"
                      aria-label="Checkbox for following text input">
                  </div>
                  <app-date-time-picker formControlName="startDate" name="startDate" [disabled]="!post.startDateActive"></app-date-time-picker>
                </div>
              </div>
              <div class="text-danger" *ngIf="form.controls.startDate.errors?.dateNotValid">
                Data jest nieprawidłowa
              </div>
            </div>
            <div class="form-group">
              <label>Data końca ważności newsa</label>
              <div class="input group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <input type="checkbox"
                      [(ngModel)]='post.endDateActive'
                      [ngModelOptions]="{standalone: true}"
                      aria-label="Checkbox for following text input">
                  </div>
                  <app-date-time-picker formControlName="endDate" name="endDate" [disabled]="!post.endDateActive"></app-date-time-picker>
                </div>
              </div>
              <div class="text-danger" *ngIf="form.controls.endDate.errors?.dateNotValid">
                Data jest nieprawidłowa
              </div>
            </div>
            <div class="form-check" checkRolePermition="news.publicate.push">
              <input class="form-check-input" type="checkbox" formControlName="sendPush" id="sendPushCheckbox">
              <label class="form-check-label" for="sendPushCheckbox">
                Wyślij powiadomienie Push
              </label>
            </div>
            <div class="form-check" checkRolePermition="news.publicate.sms">
              <input class="form-check-input" type="checkbox" formControlName="sendSms" id="sendSMSCheckbox">
              <label class="form-check-label" for="sendSMSCheckbox">
                Wyślij powiadomienie SMS
              </label>
            </div>
            <div class="form-check" checkRolePermition="news.publicate.important">
              <input class="form-check-input" type="checkbox" formControlName="important" id="importantCheckbox">
              <label class="form-check-label" for="importantCheckbox">
                News kluczowy
              </label>
            </div>
          </div>
          <div class="card-footer" style="border-bottom: 1px solid rgba(0, 0, 0, 0.125);">
            <p class="h5">Publikowanie posta</p>
          </div>
          <div class="card-body">

            <div class="form-group">
              <label for="sendTo">Udostępnij post dla</label>
              <div class="form-check" checkRolePermition="news.publicate.all">
                <input class="form-check-input" type="radio" value="all" formControlName="sendTo" id="sendToAll">
                <label class="form-check-label" for="sendToAll">Wszystkich użytkowników</label>
              </div>
              <div class="form-check" [checkRolePermition]="['news.publicate.group', 'news.publicate.myGroup']">
                <input class="form-check-input" type="radio" value="groupsAndUsers" formControlName="sendTo" id="sendToGroups">
                <label class="form-check-label" for="sendToGroups">Wybranych grup i użytkowników</label>
              </div>
            </div>

            <div class="form-group">
              <label for="typeahead-prevent-manual-entry">Wybierz grupy użytkowników</label>
              <ng-template #rt let-r="result" let-t="term">
                <div class="d-flex flex-md-row">
                <div class="mr-2">
                  <img class="img-circle elevation-3" style="height: 45px;width: 45px;" [src]="r | groupAvatar" alt="User Avatar"/>
                </div>
                <ngb-highlight class="align-self-center" [result]="formatter(r)" [term]="t"></ngb-highlight>
                </div>
              </ng-template>
              <input id="typeahead-prevent-manual-entry"  type="text" class="form-control" placeholder="Zacznij wpisywać"
                  [disabled]="form.get('sendTo').value === 'all'"
                  [appIsValid]="form.controls.groups"
                  [(ngModel)]="typeaheadModel"
                  [ngModelOptions]="{standalone: true}"
                  [ngbTypeahead]="search"
                  [resultTemplate]="rt"
                  [inputFormatter]="formatterEmpty"
                  [editable]='false'
                  (selectItem)="onTypeaheadSelect($event)"
                  (focus)="focusGroup$.next($any($event).target.value)"
                  (click)="clickGroup$.next($any($event).target.value)"
                  #instanceGroup="ngbTypeahead"
                />
              <ul class="list-group mt-3">
                <li *ngFor="let item of typeaheadList; let idx = index; trackBy: groupIdentity" class="list-group-item ">
                  <div class="d-flex align-items-center">
                    <img class="img-circle elevation-3" [src]="item | groupAvatar" alt="{{formatter(item)}}"/>
                    <p class="pl-3 flex-grow-1  m-0">{{formatter(item)}}</p>
                    <button class="btn btn-danger btn-sm p-1 elevation-2" (click)="removeGroup(item)">
                      <i class="fas fa-times fa-fw fa-lg"></i>
                    </button>
                  </div>
                </li>
              </ul>
            </div>

            <div class="form-group" >
              <label for="typeahead-prevent-manual-entry">Wybierz użytkowników</label>
              <ng-template #usersElement let-r="result" let-t="term">
                <div class="d-flex flex-md-row">
                <div class="mr-2">
                  <!-- <img [src]="r.image" class="img-circle"> -->
                  <img class="img-circle elevation-3" style="height: 45px;width: 45px;" [src]="r | userAvatar" alt="User Avatar"/>
                </div>
                <ngb-highlight class="align-self-center" [result]="formatter(r)" [term]="t"></ngb-highlight>
                </div>
              </ng-template>
              <input id="typeahead-prevent-manual-entry"  type="text" class="form-control" placeholder="Zacznij wpisywać"
                  [disabled]="form.get('sendTo').value === 'all'"
                  [(ngModel)]="typeaheadUserModel"
                  [ngModelOptions]="{standalone: true}"
                  [ngbTypeahead]="searchUsers"
                  [resultTemplate]="usersElement"
                  [inputFormatter]="formatterEmpty"
                  [editable]='false'
                  (selectItem)="onUsersTypeaheadSelect($event)"
                  (focus)="focus$.next($any($event).target.value)"
                  (click)="click$.next($any($event).target.value)"
                  #instanceUser="ngbTypeahead"
                  [appIsValid]="form.controls.users"
                />

              <ul class="list-group mt-3">
                <li *ngFor="let item of typeaheadUsersList; let idx = index" class="list-group-item ">
                  <div class="d-flex align-items-center">
                    <img class="img-circle elevation-3" [src]="item | userAvatar" alt="{{formatter(item)}}"  [appUserDisactivatable]="item"/>
                    <p class="pl-3 flex-grow-1  m-0"  [appUserDisactivatable]="item">{{formatter(item)}}</p>
                    <button class="btn btn-danger btn-sm p-1 elevation-2" (click)="removeUser(item)">
                      <i class="fas fa-times fa-fw fa-lg"></i>
                    </button>
                  </div>
                </li>
              </ul>
            </div>


          </div>
          <div class="card-footer" style="border-bottom: 1px solid rgba(0, 0, 0, 0.125);">
            <p class="lead">Lista odbiorców</p>
          </div>
          <div class="table-responsive position-relative">
            <table class="table align-middle table-hover">
              <thead>
                <tr>
                  <th style="width:1rem">#</th>
                  <th></th>
                  <th>Imię</th>
                  <th>Nazwisko</th>
                  <th>Email</th>
                  <!-- <th>Grupy użytkowników</th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of selectedUsers.list; let idx = index; trackBy: identify">
                  <th class="align-middle">{{idx + 1 + (selectedUsers.page-1) * 10}}</th>
                  <td class="user-panel" style="width:70px">
                    <img
                          [src]="item  | userAvatar: 34"
                          class="img-circle elevation-2"
                          alt="User Image"
                          style="width:45px"
                      />
                  </td>

                  <td class="align-middle">{{item.firstname}} </td>
                  <td class="align-middle">{{item.lastname}}</td>
                  <td class="align-middle">{{item.email}} </td>
                </tr>
              </tbody>
            </table>
            <!-- <pre>{{ (pagination | async)?.total | json }}</pre> -->
            <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="!selectedUsers.count"></nz-empty>
            <div class="overlay" style="position: absolute; top:0; left:0; bottom:0; right:0" *ngIf="selectedUsers.loading">
              <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
                <i class="fas fa-3x fa-spinner fa-spin"></i>
                <p>Ładowanie...</p>
              </div>
            </div>
          </div>
          <div class="card-footer d-flex">
            <div class="flex-grow-1">
              <ngb-pagination [(page)]="selectedUsers.page" [maxSize]="22" [pageSize]="10" [collectionSize]="selectedUsers.count" (pageChange)="pageChange($event)">
                <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
                <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
                <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
                <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
                <ng-template ngbPaginationEllipsis>...</ng-template>
                <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
              </ngb-pagination>
            </div>
          </div>

          <div class="card-footer">
            <button class="btn btn-success float-right" type="submit" (mouseDown)="save()" (touchEnd)="save()">
              Zapisz
              <i class="fas fa-angle-right fa-fw"></i>
            </button>
          </div>
        </div>
        </form>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';

import * as fromRolesActions from '@/store/roles/roles.actions';
import * as fromRolesReducers from '@/store/roles/roles.reducers';
import { Action, select, Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  constructor(
    private rolesStore: Store<fromRolesReducers.State>,
    private actions$: Actions
  ) {
  }

  init(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      // setTimeout(() => {resolve(true);}, 5000);
      this.rolesStore.dispatch(fromRolesActions.fetch());
      this.actions$.pipe(ofType(fromRolesActions.set), take(1)).subscribe(() => {
        resolve(true);
      })
      // console.log("GO");
      // this.rolesStore.dispatch(fromRolesActions.fetch());
      // return this.rolesStore.pipe(
      //   select(fromRolesReducers.selectRoles),
      //   map(roles => roles.length),
      //   filter(count => count > 0)
      // ).subscribe(count => {
      //   console.log(count);
      //   resolve(true);
      // });
    });
  }
}

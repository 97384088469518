<div class="content-header pt-0">
  <div class="row">
    <div class="col-12 text-right">
      <a [routerLink]="['/redmine/add']" class="btn btn-primary ml-2"  checkRolePermition="redmine.view">
        <i class="fas fa-plus fa-fw"></i> Utwórz nowe zgłoszenie
      </a>
      <a [routerLink]="['/redmine/admin']" class="btn btn-primary ml-2"  checkRolePermition="redmine.admin">
        <i class="fas fa-tools fa-fw"></i> Zarządzaj
      </a>
    </div>
  </div>
</div>

<section class="content">
  <div class="container-fluid">
    <ul class="nav nav-tabs d-block d-lg-flex">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/redmine']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          Przypisane do mnie
          <span class="badge rounded-pill" [ngClass]="{'bg-primary' : (assignedCount | async) > 0 , 'bg-secondary' : (assignedCount | async) == 0}">
            {{assignedCount | async}}
          </span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/redmine/issues', 'created']" routerLinkActive="active">
          Moje zgłoszenia
          <span class="badge rounded-pill" [ngClass]="{'bg-primary' : (createdCount | async) > 0 , 'bg-secondary' : (createdCount | async) == 0}">
            {{createdCount | async}}
          </span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/redmine/issues', 'watched']" routerLinkActive="active">
          Obserwowane
          <span class="badge rounded-pill" [ngClass]="{'bg-primary' : (watchedCount | async) > 0 , 'bg-secondary' : (watchedCount | async) == 0}">
            {{watchedCount | async}}
          </span>
        </a>
      </li>
    </ul>
    <router-outlet></router-outlet>
  </div>
</section>

<div class="content">
  <div class="container pt-3 pb-3">
    <div class="row">
      <div class="col-12">
        <form [formGroup]="form" (ngSubmit)="save()">
        <div class="card card-primary">
          <div class="overlay" *ngIf="saving | async">
            <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%,0);text-align: center; padding: 25px 0">
              <i class="fas fa-3x fa-spinner fa-spin"></i>
              <p>Zapisywanie...</p>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label for="subject">Tytuł</label>
              <input formControlName="subject" [appIsValid]="form.controls.subject" type="text" class="form-control" placeholder="Tytuł" />
              <div class="invalid-feedback" *ngIf="form.controls.subject.errors?.required">
                Pole jest wymagane
              </div>
            </div>
            <div class="form-group">
              <label for="description">Opis</label>
              <app-editor formControlName="description"></app-editor>
              <div class="text-danger" *ngIf="form.controls.description.touched && form.controls.description.errors?.required">
                Pole jest wymagane
              </div>
            </div>
            <div class="form-group">
              <label for="description">Załączniki</label>
              <nz-upload  checkRolePermition="kb.admin.files.create"
                nzType="drag"
                [nzMultiple]="true"
                [(nzFileList)]="fileList"
                [nzBeforeUpload]="beforeUpload"
                >
                <p class="ant-upload-drag-icon">
                  <i nz-icon nzType="inbox"></i>
                </p>
                <p class="ant-upload-text">Kliknij lub przeciągnij plik do tego obszaru, aby przesłać</p>
                <p class="ant-upload-hint"></p>
              </nz-upload>
            </div>
          </div>
          <div class="card-footer" style="position: relative;" >
            <a id="foot-ancor" style="position: relative;top:-450px"></a>
            <button class="btn btn-success float-right" type="submit">
              Zapisz
              <i class="fas fa-angle-right fa-fw"></i>
            </button>
          </div>
        </div>
        </form>
      </div>
    </div>
  </div>
</div>

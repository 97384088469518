<ul class="nav nav-tabs">
  <li class="nav-item" *ngIf="!package?.id">
    <a class="nav-link" routerLinkActive="active bg-primary" [routerLink]="['/training/admin/package/add']">
      <i class="fas fa-edit"></i> Nowe szkolenie
    </a>
  </li>
  <li class="nav-item" *ngIf="package?.id && !package?.archive">
    <a class="nav-link"
      [class.disabled]="!package?.id"
      routerLinkActive="active bg-primary"
      [routerLink]="['/training/admin/package', package.id ,'edit']">
      <i class="fas fa-edit"></i> Edycja szkolenie
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link"
      *ngIf="!package?.archive"
      [class.disabled]="!package?.id || package?.open"
      routerLinkActive="active bg-primary"
      [routerLink]="['/training/admin/package', package?.id ,'to-invitate']">
      <i class="fas fa-plus"></i>
      Dodaj zaproszenia
      <!-- <span class="badge rounded-pill badge-secondary" >{{ package?.toInvite?.total}}</span>
      <pre>{{ package?.toInvite | json }}</pre> -->
    </a>
  </li>
  <li class="nav-item">
    <!-- <pre>{{ package?.current.invited?.total }}</pre> -->
    <a class="nav-link"
      *ngIf="!package?.archive"
      [class.disabled]="!package?.id || package?.open || !package?.current?.endDate || !package?.current.invited?.total || package?.current.published"
      routerLinkActive="active bg-primary"
      [routerLink]="['/training/admin/package', package?.id ,'invitated']">
      <i class="fas fa-paper-plane"></i>
      Wyślij zaproszenia
      <!--
      <pre>{{ package?.toInvite | json }}</pre> -->
      <!-- <pre>{{ [!package?.id , package?.open , !package?.current?.endDate , !package?.current.invited?.total , package?.current.published] | json }}</pre> -->
    </a>
  </li>

  <li class="nav-item position-relative">
    <a class="nav-link"
      *ngIf="!package?.archive"
      routerLinkActive="active bg-primary"
      [class.disabled]="!package?.id || package?.open"
      [routerLink]="['/training/admin/package', package?.id ,'packs']">
      <i class="fas fa-bell "></i>
      Powiadomienia
      <span
        *ngIf="package?.invitePacks?.length || package?.pack_count"
        class="position-absolute text-md badge rounded-pill bg-orange"
        style="top: 0; right: 0; transform: translate(25%, -50%) !important;">
        {{ package?.invitePacks?.length || package?.pack_count }}
      </span>
    </a>
  </li>

  <li class="nav-item position-relative">
    <a class="nav-link"
      *ngIf="!package?.archive"
      routerLinkActive="active bg-primary"
      [class.disabled]="!package?.id || package?.open"
      [routerLink]="['/training/admin/package', package?.id , 'participants']">
      <i class="fas fa-users "></i>
      Uczestnicy
      <span
        *ngIf="package?.invited?.total"
        class="position-absolute text-md badge rounded-pill bg-orange"
        style="top: 0; right: 0; transform: translate(25%, -50%) !important;">
        {{ package?.invited?.total}}
      </span>
    </a>
  </li>

  <li class="nav-item">
    <a class="nav-link"
      routerLinkActive="active bg-primary"
      [class.disabled]="!package?.id"
      [routerLink]="['/training/admin/package', package?.id ,'report']">
      <i class="fas fa-chart-area "></i>
      Raporty
    </a>
  </li>
</ul>

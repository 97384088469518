import { createPaginationInitialState, Pagination } from "@/model/pagination.model";
import { Survey, SurveyQuestion } from "@/model/survey.model";
import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import * as actions from "./survey.actions";

export interface State extends Pagination<Survey> {
  archive: boolean
}

export const adapter: EntityAdapter<Survey> = createEntityAdapter<Survey>();
export const questionAdapter: EntityAdapter<SurveyQuestion> = createEntityAdapter<SurveyQuestion>();

export const initialState: State = {
  ...createPaginationInitialState(adapter, false),
  ...{archive: false}
};

export const reducer = createReducer(
  initialState,
  on(actions.clear, (state) => initialState),
  on(actions.fetch, (state) => { return {...state, ...{loading: true}}}),
  on(actions.fetchOne, (state) => { return {...state, ...{loading: true}}}),
  on(actions.setPage, (state, action) => { return {...state, ...{page: action.page}}}),
  on(actions.setOrder, (state, action) => { return {...state, ...{order: action.order}}}),
  on(actions.setDirection, (state, action) => { return {...state, ...{asc: action.asc}}}),
  on(actions.setSearch, (state, action) => { return {...state, ...{search: action.search}}}),
  on(actions.setArchive, (state, action) => { return {...state, ...{archive: action.archive}}}),

  on(actions.setManySurvey, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: action.total,
        list: adapter.addMany(action.list, initialState.list)
      }
    }
  }),
  on(actions.addManySurvey, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: action.total,
        list: adapter.addMany(action.list, state.list)
      }
    }
  }),
  on(actions.addSurvey, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: state.total + 1,
        list: adapter.addOne(action.survey, state.list)
      }
    }
  }),
  on(actions.setSurvey, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.updateOne({ id: action.survey.id, changes: action.survey}, state.list)
      }
    }
  }),
  on(actions.removeSurvey, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: state.total - 1,
        list: adapter.removeOne(action.id, state.list)
      }
    }
  }),

  on(actions.addSurveyQuestion, (state, action) => {
    let survey = state.list.entities[action.survey.id];
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.updateOne({
          id: survey.id,
          changes: {
            questions: questionAdapter.addOne(action.question, survey.questions)
          }
        }, state.list)
      }
    }
  }),
  on(actions.removeSurveyQuestion, (state, action) => {
    let survey = state.list.entities[action.survey.id];
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.updateOne({
          id: survey.id,
          changes: {
            questions: questionAdapter.removeOne(action.question.id, survey.questions)
          }
        }, state.list)
      }
    }
  }),
  on(actions.setSurveyQuestion, (state, action) => {
    let survey = state.list.entities[action.survey.id];
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.updateOne({
          id: survey.id,
          changes: {
            questions: questionAdapter.updateOne({
              id: action.question.id,
              changes: action.question
            }, survey.questions)
          }
        }, state.list)
      }
    }
  }),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectState = createFeatureSelector<State>('survey');
export const selectAllSurvey = createSelector(selectState, (state) => selectAll(state.list) )
export const selectOneSurvey = createSelector(selectState, (state: State, id) => state.list.entities[id]);

import { Component, OnInit } from '@angular/core';
import * as fromActions from '@/store/ideas/ideas.actions';
import * as fromReducers from '@/store/ideas/ideas.reducers';
import { select, Store } from '@ngrx/store';
import { Observable, of, Subject, zip } from 'rxjs';
import { Idea } from '@/model/idea.model';
import { Comment } from '@/model/comment.model';
import moment from 'moment';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-list-ideas',
  templateUrl: './list-ideas.component.html',
  styleUrls: ['./list-ideas.component.scss']
})
export class ListIdeasComponent implements OnInit {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  showComments = false;

  constructor(
    private store: Store<fromReducers.State>
  ) { }

  ngOnInit(): void {
    this.store.dispatch(fromActions.fetchPublicIdeas({skip:0}));
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  get list(): Observable<Idea[]> {
    return this.store.pipe(select(fromReducers.selectAllPublicIdeas));
  }

  identify(index, item: Idea): number { return item.id;}

  onScroll(): void {
    this.loadMore();
  }

  onScrollUp(): void {
    this.loadMore();
  }

  get hasMore(): Observable<boolean> {
    return this.store.select(fromReducers.selectHasMorePublicIdeas);
  }

  get loading(): Observable<boolean> {
    return this.store.select(fromReducers.selectIdeasState).pipe(map(status => status.public.loading));
  }

  get count(): Observable<number> {
    return this.list.pipe(map(arr => arr.length));
  }


  async loadMore() {
    zip(this.loading, this.hasMore, this.count).pipe(
      filter( (result: [loading: boolean, hasMore: boolean, count: number ]) => !result[0] && result[1]),
      map( (result: [loading: boolean, hasMore: boolean, count: number ]) => result[2]),
    ).subscribe( (skip: number) => {
      this.dispatchMore(skip);
    });
  }

  protected dispatchMore(skip: number) {
    console.log('dispatchMore');
    this.store.dispatch(fromActions.fetchPublicIdeas({skip}));
  }





}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import * as fromTrainingPackageiaActions from '@/store/package/package.actions';
import * as fromTrainingPackageReducers from '@/store/package/package.reducers';
import { TrainingPackage } from '@/model/package.model';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'app-package-list-of-packs',
  templateUrl: './package-list-of-packs.component.html',
  styleUrls: ['./package-list-of-packs.component.scss']
})
export class PackageListOfPacksComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  package: TrainingPackage;

  constructor(
    private store: Store<fromTrainingPackageReducers.State>,
    private activatedRoute: ActivatedRoute,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe( (data: Data) => {
      // this.package = data.instance;

      if (data.instance.archive || data.instance.open) {
        this.router.navigate(['/training/admin/package']);
        return;
      }
      // this.store.pipe(
      //   select(fromTrainingPackageReducers.selectOneTrainingPackage, data.instance.id),
      //   takeUntil(this.unsubscribe$),
      //   // distinctUntilChanged((next,prev) => {
      //   //   console.log(next,prev);
      //   //   return false;
      //   // })
      // ).subscribe((item) => {
      //   console.log(item);
      //   this.package = item;
      // });
      this.store.dispatch(fromTrainingPackageiaActions.fetchOne({id: data.instance.id}));
      this.actionsSubject$.pipe(ofType(fromTrainingPackageiaActions.addTrainingPackage)).subscribe(action => {
        this.package = action.item;
      })
    });

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

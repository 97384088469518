import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ActionsSubject, select, Store } from '@ngrx/store';
import * as fromUsersActions from '@/store/users/users.actions';
import * as fromUsersReducers from '@/store/users/users.reducers';
import * as fromStructureActions from '@/store/structure/structure.actions';
import * as fromStructureReducers from '@/store/structure/structure.reducers';
import { ToastrService } from 'ngx-toastr';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { User } from '@/model/user.model';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { AuthService } from '@/auth/auth.service';
import { tinyMceConfig } from '@/config/tinymce.config';
import * as fromRolesReducers from '@/store/roles/roles.reducers';
import { Role } from '@/model/role.model';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  disabled = false;
  tags= [];
  expandKeys = [];
  nodes: [];
  user: User;
  uploading = false;
  tinyMceConfig = {...tinyMceConfig, ...{
    init : {...tinyMceConfig.init, ...{height: 250}}
  }};


  constructor(
    private toastr: ToastrService,
    private store: Store<fromUsersReducers.State>,
    private rolesStore: Store<fromRolesReducers.State>,
    private structureStore: Store<fromStructureReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.store.dispatch(fromStructureActions.fetch());

    this.structureStore.pipe(takeUntil(this.unsubscribe$), select(fromStructureReducers.selectRoot)).subscribe( (tree) => {
      this.nodes = this.mapNodes(tree);
    });
    this.form = new FormGroup({
      firstname: new FormControl(null, Validators.required),
      lastname: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email],[this.checkEmailUniqe.bind(this)]),
      position: new FormControl(null),
      phone: new FormControl(null),
      hiredAt: new FormControl(null),
      physicalLocation: new FormControl(null),
      aboutMe: new FormControl(null),
      branch: new FormControl(null),
    });

    this.activatedRoute.paramMap.pipe(
      map((params: ParamMap) => parseInt(params.get('id')))
    ).subscribe( (id: number) => {
      this.store.dispatch(fromUsersActions.fetchOneUser({id: id}));
      this.store.pipe(takeUntil(this.unsubscribe$), select(fromUsersReducers.selectOneUser, id)).subscribe((user: User | null) => {
        if(!user)return;
        this.user = user;

        this.authService.checkPermition('users.role', this.user).then((allowed) => {
          if (allowed) {
            this.form.addControl('role', new FormControl('user', [Validators.required]));
          }
        })
        let userModel = {...user, ...{ hiredAt: null}};
        if (user.hiredAt) {
          let hiredAtDate = new Date(user.hiredAt);

          userModel.hiredAt = {
            year: hiredAtDate.getUTCFullYear(),
            month: hiredAtDate.getUTCMonth() + 1,
            day: hiredAtDate.getDate()
          }

        }
        if (user.tags) {
          this.tags = user.tags.map(x => { return {value : x, display: x}});
        }
        this.form.patchValue(userModel);
      })
    })



  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get roles(): Observable<Role[]> {
    return this.rolesStore.pipe(select(fromRolesReducers.selectRoles));
  }

  private prevEmailValue: string;
  checkEmailUniqe(control: AbstractControl)/* : Observable<ValidationErrors>*/ {
    if(this.user?.email == control.value)
      return of(null);
    if(this.prevEmailValue == control.value && control.errors === null)
      return of(null);
    const subject = new BehaviorSubject<string>('');
    return subject.asObservable().pipe(
      takeUntil(this.unsubscribe$),
      distinctUntilChanged(),
      debounceTime(1000),
      switchMap(() => this.authService.checkEmailUniqe(control.value)),
      map(result => {
        if(result) {
          this.prevEmailValue = null;
          control.setErrors({notUniqe: true});
          control.markAsTouched();
          return { notUniqe: true };
        }
        else {
          this.prevEmailValue = control.value;
          control.setErrors(null);
          control.markAllAsTouched();
          return null;;
        }
      })
    )
  }

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => {
    let pass = this.form?.get('password')?.value;
    let confirmPass = this.form?.get('confirmPassword')?.value
    return pass === confirmPass ? null : { notSame: true }
  }

  getModelFromForm(): User {
    let hiredAt = this.form.value.hiredAt?.year ? `${this.form.value.hiredAt.year}-${this.form.value.hiredAt.month}-${this.form.value.hiredAt.day}` : null;
    let model = {...this.form.value, ...{ hiredAt: hiredAt, tags: this.tags.map(x => x.value)}};
    return model;
  }

  save(): void {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    let valid = this.form.valid;
    if (valid && !this.disabled) {
      this.disabled = true;

      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        filter((action) => action.type === fromUsersActions.emitNewUserId.type),
      ).subscribe( (action:any) => {
        this.toastr.success("Zapisano zmiany");
        this.router.navigate(['/','users'])
      })
      this.store.dispatch(fromUsersActions.editUser({id: this.user.id, user: this.getModelFromForm()}));
    } else if(!this.disabled) {
      this.toastr.error("Formularz zawiera błędy");
    } else {
      this.toastr.warning("Trwa zapisywanie");
    }
  }

  handleImageUploadChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.uploading = true;
        break;
      case 'done':
        let update = {...this.getModelFromForm(), ...{ id: info.file.response.id, avatar: info.file.response.avatar}};
        this.store.dispatch(fromUsersActions.updateUser({user: update}));
        this.uploading = false;
        break;
      case 'error':
        this.toastr.error('Błąd uploadu pliku');
        this.uploading = false;
        break;
    }
  }

  handleBackgroundUploadChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.uploading = true;
        break;
      case 'done':
        let update = {...this.getModelFromForm(), ...{ id: info.file.response.id, background: info.file.response.background}};
        this.store.dispatch(fromUsersActions.updateUser({user: update}));
        this.uploading = false;
        break;
      case 'error':
        this.toastr.error('Błąd uploadu pliku');
        this.uploading = false;
        break;
    }
  }



  mapNodes(tree) {
    return tree.map( node => {
      return {
        key: node.id,
        title: node.title,
        children: node.children?.ids.length ? this.mapNodes(Object.values(node.children.entities)) : [],
        isLeaf: node.children?.ids.length == 0
      }
    })
  }
}

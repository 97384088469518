import { createPaginationInitialState, Pagination } from "@/model/pagination.model";
import { RedmineCategory, RedmineIssue, RedminePriority, RedmineSubCategory } from "@/model/redmine.model";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import * as actions from './redmine.issue.actions';

export interface State extends Pagination<RedmineIssue> {
  status: string;
  issueType: string;
  assignedToMe: Pagination<RedmineIssue>;
  allCount: number,
  assignedCount: number,
  watchedCount: number,
  createdCount: number,
}

export const adapter: EntityAdapter<RedmineIssue> = createEntityAdapter<RedmineIssue>();

export const initialState: State = {
  ...createPaginationInitialState(adapter, false),
  ...{
    status: 'open',
    issueType: 'created',
    assignedToMe:createPaginationInitialState(adapter, false),
    allCount: 0,
    assignedCount: 0,
    watchedCount: 0,
    createdCount: 0,
  }
};

export const reducer = createReducer(
  initialState,
  on(actions.clear, (state) => {return {...initialState, ...{
    assignedToMe : state.assignedToMe,
    assignedCount: state.assignedCount,
    watchedCount: state.watchedCount,
    createdCount: state.createdCount,
    allCount: state.allCount,
  }}}),
  on(actions.fetch, (state) => { return {...state, ...{loading: true}}}),
  on(actions.fetchOne, (state) => { return {...state, ...{loading: true}}}),
  on(actions.setPage, (state, action) => { return {...state, ...{page: action.page}}}),
  on(actions.setOrder, (state, action) => { return {...state, ...{order: action.order}}}),
  on(actions.setDirection, (state, action) => { return {...state, ...{asc: action.asc}}}),
  on(actions.setSearch, (state, action) => { return {...state, ...{search: action.search}}}),
  on(actions.setType, (state, action) => { return {...state, ...{issueType: action.issueType}}}),
  on(actions.setStatus, (state, action) => { return {...state, ...{status: action.status}}}),

  on(actions.createIssue, (state) => { return {...state, ...{saving: true}}}),
  on(actions.updateIssue, (state) => { return {...state, ...{saving: true}}}),
  on(actions.setIssue, (state, action) => {
    return {
      ...state,
      ...{
        saving: false,
        list: adapter.setOne(action.item, state.list)
      }
    }
  }),

  on(actions.setAll,(state, action) => {
    let counterField = {};
    switch(state.issueType) {
      case 'created':
        counterField = {createdCount: action.total};
        break;
      case 'assigned':
        counterField = {assignedCount: action.total};
        break;
      case 'watched':
        counterField = {watchedCount: action.total};
        break;
    }
    return {
      ...state,
      ...{
        loading: false,
        total: action.total,
        list: adapter.setAll(action.list, state.list)
      },
      ...counterField
    }
  }),
  on(actions.setAssignedToMe,(state, action) => {
    return {
      ...state,
      ...{
        assignedToMe: {
          ...state.assignedToMe,
          ...{
            loading: false,
            total: action.total,
            list: adapter.setAll(action.list ? action.list : [], state.assignedToMe.list)
          }
        }
      }
    }
  }),
  on(actions.setAllCount,(state, action) => {
    return {
      ...state,
      ...{
        allCount: action.count,
        assignedCount: action.assigned,
        watchedCount: action.watched,
        createdCount: action.created,
      }
    }
  }),

);



export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectState = createFeatureSelector<State>('redmine-issue');
export const selectIssues = createSelector(selectState, (state) => selectAll(state.list) )
export const selectIssue = createSelector(selectState, (state, id) => state.list.entities[id]);

export const selectAssignedToMe = createSelector(selectState, (state) => selectAll(state.assignedToMe.list) )
export const selectAssignedToMeCount = createSelector(selectState, (state) => state.assignedToMe.total )
export const selectAllCount = createSelector(selectState, (state) => state.allCount );
export const selectAssignedCount = createSelector(selectState, (state) => state.assignedCount );
export const selectWatchedCount = createSelector(selectState, (state) => state.watchedCount );
export const selectCreatedCount = createSelector(selectState, (state) => state.createdCount );
export const isSaving = createSelector(selectState, (state) => state.saving );

import { AppNotification } from "@/model/notification.model";
import { createAction, props } from "@ngrx/store";

export const fetch = createAction(
  "notification fetch"
);

export const set = createAction(
  "notification set",
  props<{notifications: AppNotification[]}>()
);

export const add = createAction(
  "notification add",
  props<{notification: AppNotification}>()
);

export const deleteNotification = createAction(
  "notification deleteNotification",
  props<{notification: AppNotification}>()
);



export const remove = createAction(
  "notification remove",
  props<{notification: AppNotification}>()
);


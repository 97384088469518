import { Conversation } from '@/model/chat.model';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ChatService } from '@services/chat.service';
import { interval, Observable, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-chat-dropdown-menu',
  templateUrl: './chat-dropdown-menu.component.html',
  styleUrls: ['./chat-dropdown-menu.component.scss']
})
export class ChatDropdownMenuComponent  implements OnInit, OnDestroy {
  @ViewChild('dropdownMenu', {static: false}) dropdownMenu;
  private readonly unsubscribe$: Subject<void> = new Subject();

  @HostListener('document:click', ['$event'])
  clickout(event) {
      if (!this.elementRef.nativeElement.contains(event.target)) {
          this.hideDropdownMenu();
      }
  }

  constructor(
    private chatService: ChatService,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    // timer(0, 1000 * 40)
    //   .pipe(takeUntil(this.unsubscribe$))
    //   .subscribe(() => {
    //     this.chatService.load();
    //   })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get latest(): Observable<Conversation[]> {
    return this.chatService.latest;
  }

  get all(): Observable<Conversation[]> {
    return this.chatService.all;
  }

  get count(): Observable<number> {
    return this.chatService.count;
  }

  toggleDropdownMenu() {
      if (this.dropdownMenu.nativeElement.classList.contains('show')) {
          this.hideDropdownMenu();
      } else {
          this.showDropdownMenu();
      }
  }

  showDropdownMenu() {
      this.renderer.addClass(this.dropdownMenu.nativeElement, 'show');
  }

  hideDropdownMenu() {
      this.renderer.removeClass(this.dropdownMenu.nativeElement, 'show');
  }
}

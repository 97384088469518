import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject, fromEvent, Observable } from 'rxjs';
import { takeUntil, map, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as fromActions from '@/store/prizes/prizes.actions';
import * as fromReducers from '@/store/prizes/prize.reducers'
import { Prize } from '@/model/prize.model';

@Component({
  selector: 'app-list-prizes',
  templateUrl: './list-prizes.component.html',
  styleUrls: ['./list-prizes.component.scss']
})
export class ListPrizesComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  page = 1;
  pageSize = fromReducers.initialState.limit;
  searchTerm: string;

  constructor(
    private store: Store<fromReducers.State>,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(fromActions.reset());
    this.store.dispatch(fromActions.fetchPrizes({}));
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      takeUntil(this.unsubscribe$),
      map((event: any) => event.target.value),
      filter( (res:string) => res.length > 2),
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((text: string) => {
      this.store.dispatch(fromActions.setSearch({search: text.toLowerCase().trim()}));
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  clearSearch() {
    this.searchInput.nativeElement.value = '';
    this.store.dispatch(fromActions.setSearch({search: null}));
  }

  get paginator(): Observable<Prize[]>  {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectAllPrizes));
  }

  get total(): Observable<number>  {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectCount));
  }

  pageChange(page): void {
    this.store.dispatch(fromActions.setPage({page: page}));
  }

  delete(prize: Prize): void {
    this.store.dispatch(fromActions.deletePrize({prize}));
  }

  identify(index, item: Prize): number { return item.id;}

}

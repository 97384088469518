import { CallCenterFill } from '@/model/call-center.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import * as fromReducers from '@/store/call-center/call-center.reducers';
import * as fromActions from '@/store/call-center/call-center.actions';
import { map, takeUntil } from 'rxjs/operators';
import { AuthService } from '@/auth/auth.service';
import { User } from '@/model/user.model';

@Component({
  selector: 'app-detail-call-center-fill',
  templateUrl: './detail-call-center-fill.component.html',
  styleUrls: ['./detail-call-center-fill.component.scss']
})
export class DetailCallCenterFillComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  fill: CallCenterFill;
  user: User;
  canSeePrivateNotes: boolean = false;

  constructor(
    private store: Store<fromReducers.State>,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
  ) {
    this.loadUser();
  }

  private async loadUser() {
    this.user = await this.authService.getUser();
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data: Data) => {
      this.fill = data.instance;
      this.canSeePrivateNotes = this.fill.user.id !== this.user.id || this.fill.createdBy.id === this.user.id;
    })
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get loading(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromReducers.selectState),
      map( (state:fromReducers.State)  => state.loading),
    );
  }

  rowspan(categoryIndex): number {
    let rowspan = this.fill.categories[categoryIndex].questions.length + 1;
    let i = 1;
    while(categoryIndex + i !== this.fill.categories.length && this.fill.categories[categoryIndex].area === this.fill.categories[categoryIndex + i].area) {
      rowspan += this.fill.categories[categoryIndex + i].questions.length + 1;
      i++;
    }
    return rowspan;
  }

  hasRowspan(categoryIndex): boolean {
    if(categoryIndex === 0) return true;
    return this.fill.categories[categoryIndex].area !== this.fill.categories[categoryIndex - 1].area;
  }
}

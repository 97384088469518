import { Idea } from "@/model/idea.model";
import { Comment } from '@/model/comment.model';
import { createAction, props } from "@ngrx/store";

export interface IdeaPagination {total: number, data: Idea[]}

export const reset = createAction(
  "reset ideas"
);

export const fetchPublicIdeas = createAction(
  "fetch public ideas",
  props<{skip?:number, status?: string, search?: string}>()
);

export const fetchPublicIdea = createAction(
  "fetch public idea",
  props<{id: number}>()
);

export const createNewIdea = createAction(
  "createNewIdea",
  props<{idea: Idea}>()
);
export const addIdea = createAction("addIdea", props<{idea: Idea}>());

export const fetchAdminIdeas = createAction("fetchAdminIdeas");
export const replaceAdminIdeas = createAction(
  "replaceAdminIdeas", props<IdeaPagination>()
);

export const setAdminPage = createAction( "setAdminPage", props<{ page: number }>());
export const setAdminOrder = createAction( "setAdminOrder", props<{ order: string }>());
export const setAdminDirection = createAction("setAdminDirection", props<{ direction: string }>());
export const setAdminSearch = createAction("setAdminSearch", props<{ search: string }>());

export const saveIdea = createAction("saveIdea", props<{ id: number, idea: Idea }>());
export const updateAdminIdea = createAction("updateAdminIdea", props<{ id: number, changes: Idea }>());

export const hideIdea = createAction("hideIdea", props<{ idea: Idea }>());
export const deleteIdea = createAction("deleteIdea", props<{ idea: Idea }>());

export const addPublicIdeas = createAction("addPublicIdeas", props<IdeaPagination>());
export const updateIdea = createAction("updateIdea", props<{ id: number, changes: Idea }>());
export const removeAdminIdea = createAction("removeAdminIdea", props<{ id: number }>());
export const removeIdea = createAction("removeIdea", props<{ id: number }>());
export const voteUp = createAction("voteUp", props<{ idea: Idea }>());
export const voteDown = createAction("voteDown", props<{ idea: Idea }>());


export const fetchIdeaCommentsAction = createAction("fetchIdeaCommentsAction", props<{ idea: Idea }>());
export const createIdeaCommentAction = createAction("createIdeaCommentAction", props<{ idea: Idea, replyTo: Comment, comment: Comment }>());
export const addCommentSuccess = createAction("addIdeaCommentSuccess", props<{ idea: Idea, comment: Comment, replyTo?: Comment }>());
export const addCommentToIdeaAction = createAction("addCommentToIdeaAction", props<{ idea: Idea, comment: Comment }>());
export const addCommentToIdeaCommentAction = createAction("addCommentToIdeaCommentAction", props<{ idea: Idea, comment: Comment, replyTo: Comment }>());
export const addManyCommentsToIdeaAction = createAction("addManyCommentsToIdeaAction", props<{ idea: Idea, comments: Comment[] }>());
export const deleteIdeaComment = createAction(
  "deleteIdeaComment",
  props<{ comment: Comment }>()
);
export const likeIdeaCommentAction = createAction(
  "likeIdeacomment",
  props<{ comment: Comment }>()
);
export const unlikeIdeaCommentAction = createAction(
  "unlikeIdeacomment",
  props<{ comment: Comment }>()
);
export const updateIdeaCommentAction = createAction(
  "updateIdeacomment",
  props<{ comment: Comment, idea: Idea }>()
);

export const updateIdeaReplyCommentAction = createAction(
  "updateIdeaReplyCommentAction",
  props<{ comment: Comment, idea: Idea, replyTo: Comment }>()
);


<section class="content">
  <div class="container pt-3 pb-3">
    <form [formGroup]="form" (ngSubmit)="save()">
      <app-account-tabs></app-account-tabs>
      <div class="card" style="border-top-left-radius: 0;border-top-right-radius: 0;">
        <div class="card-body">
          <div class="row">
            <div class="col-md-8">
              <div class="form-group">
                <label for="firstname">Imię</label>
                <input formControlName="firstname" type="text" [appIsValid]="form.controls.firstname" class="form-control" placeholder="Imię" />
                <div class="invalid-feedback" *ngIf="form.controls.firstname.errors?.required">
                  Pole jest wymagane
                </div>
              </div>
              <div class="form-group">
                <label for="lastname">Nazwisko</label>
                <input formControlName="lastname" type="text" [appIsValid]="form.controls.lastname" class="form-control" placeholder="Nazwisko" />
                <div class="invalid-feedback" *ngIf="form.controls.lastname.errors?.required">
                  Pole jest wymagane
                </div>
              </div>
              <div class="form-group">
                <label for="email">E-Mail</label>
                <input formControlName="email" type="email" readonly [appIsValid]="form.controls.email" class="form-control" placeholder="E-Mail" />
                <div class="invalid-feedback" *ngIf="form.controls.email.errors?.required">
                  Pole jest wymagane
                </div>
                <div class="invalid-feedback" *ngIf="form.controls.email.errors?.email">
                  Email nie jest poprawny
                </div>
                <div class="invalid-feedback" *ngIf="form.controls.email.errors?.notUniqe">
                  Email już istnieje w bazie
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label for="inputName">Avatar użytkownika</label>
                <!-- <app-upload-crop-image></app-upload-crop-image> -->
                <div style="max-width: 220px;">
                  <app-upload-crop-image  *ngIf="user"
                    [avatar]="true"
                    [aspectRatio]="1 / 1"
                    [image]="user | userAvatar:250:false"
                    url="<backendhost>/v1/users/{{user.id}}/upload"
                    (nzChange)="handleImageUploadChange($event)"
                  ></app-upload-crop-image>
                  <!-- <nz-upload *ngIf="user"
                    class="avatar-uploader w-100"
                    nzAction="<backendhost>/v1/users/{{user.id}}/upload"
                    nzName="image"
                    nzListType="picture-card"
                    [nzShowUploadList]="false"
                    (nzChange)="handleImageUploadChange($event)"
                  >
                    <ng-container *ngIf="!user.avatar" class="w-100">
                      <i class="fas fa-3x" [ngClass]="{
                        'fa-upload' : !uploading,
                        'fa-spinner fa-spin' : uploading
                      }"></i>
                      <div class="ant-upload-text">Upload</div>
                    </ng-container>
                    <img *ngIf="user.avatar" [src]="user | userAvatar:250:false" style="width: 100%" />
                  </nz-upload> -->
                </div>
                </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label for="inputName">Obrazek tła</label>
                <div style="max-width: 100%;">
                  <app-upload-crop-image  *ngIf="user"
                    [avatar]="false"
                    [aspectRatio]="4 / 1"
                    [image]="user?.background ? (user.background | image) : null"
                    url="<backendhost>/v1/users/{{user.id}}/background"
                    (nzChange)="handleBackgroundUploadChange($event)"
                  ></app-upload-crop-image>
                  <!-- <nz-upload *ngIf="user"
                    class="bg-uploader w-100"
                    nzAction="<backendhost>/v1/users/{{user.id}}/background"
                    nzName="image"
                    nzListType="picture-card"
                    [nzShowUploadList]="false"
                    (nzChange)="handleBackgroundUploadChange($event)"
                  >
                    <ng-container *ngIf="!user.background" class="w-100">
                      <i class="fas fa-3x" [ngClass]="{
                        'fa-upload' : !uploading,
                        'fa-spinner fa-spin' : uploading
                      }"></i>
                      <div class="ant-upload-text">Upload</div>
                    </ng-container>
                    <img *ngIf="user.background" [src]="user.background | image" style="width: 100%" />
                  </nz-upload> -->
                </div>
                </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="phone">Telefon</label>
                <input formControlName="phone" type="text" class="form-control" placeholder="Telefon" />
              </div>
              <div class="form-group">
                <label for="physicalLocation">Fizyczna lokalizacja</label>
                <input formControlName="physicalLocation" type="text" class="form-control" placeholder="Fizyczna lokalizacja" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="position">Stanowisko</label>
                <input formControlName="position" type="text" class="form-control" placeholder="Stanowisko" />
              </div>
              <div class="form-group">
                <label for="hiredAt">Zatrudniony od</label>
                <div class="input-group">
                  <input formControlName="hiredAt" type="text" [appIsValid]="form.controls.hiredAt" class="form-control" ngbDatepicker placeholder="yyyy-mm-dd"  #d="ngbDatepicker">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                      <i class="far fa-calendar"></i>
                    </button>
                  </div>
                  <div class="invalid-feedback" *ngIf="form.controls.hiredAt.errors?.ngbDate?.invalid">
                    Format daty jest nieprawidłowy
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="position">O mnie</label>
                <!-- <editor [init]="tinyMceConfig.init" apiKey="{{tinyMceConfig.apiKey}}"  formControlName="aboutMe"></editor> -->
                <app-editor formControlName="aboutMe"></app-editor>
              </div>
              <div class="form-group">
                <label>Tagi</label>
                <tag-input
                  [(ngModel)]='tags'
                  [ngModelOptions]="{standalone: true}"
                  theme='bootstrap'
                  secondaryPlaceholder="Wpisz nowy tag"></tag-input>
              </div>
            </div>
          </div>

        </div>
        <div class="card-footer">
          <button class="btn btn-success float-right" type="submit" [disabled]="disabled | async">
            <i class="fas fa-spinner fa-spin fa-fw" *ngIf="disabled | async"></i>
            Dalej
            <i class="fas fa-angle-right fa-fw"></i>
          </button>
        </div>
      </div>
    </form>
  </div>
</section>

import { ReservationCategory } from '@/model/reservation.model';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import * as fromActions from '@/store/reservations/reservations.actions';
import * as fromReducers from '@/store/reservations/reservations.reducers';
import { Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'app-add-reservation-category',
  templateUrl: './add-reservation-category.component.html',
  styleUrls: ['./add-reservation-category.component.scss']
})
export class AddReservationCategoryComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  disabled = false;
  uploading = false;
  imageFile: NzUploadFile;
  reservationCategory: ReservationCategory;

  constructor(
    private toastr: ToastrService,
    private store: Store<fromReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      title: new FormControl(null, Validators.required),
      description: new FormControl(null, Validators.required),
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  save(): void {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    let valid =  this.form.valid;
    if (valid) {
      this.disabled = true;

      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromActions.addAdminReservationCategory)
      ).subscribe( (action:any) => {
        this.toastr.success("Zapisano zmiany");
        this.router.navigate(['/reservations/admin'])
      })

      this.store.dispatch(fromActions.createReservationCategory({reservationCategory: this.form.value,image: this.imageFile}));
    } else if(!this.disabled) {
      this.toastr.error("Formularz zawiera błędy");
    } else {
      this.toastr.warning("Trwa zapisywanie");
    }
  }

  get saving(): Observable<boolean> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectReservationState), map( (state) => state.admin.saving));
  }
}

import { Component, OnInit } from '@angular/core';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import * as fromScormActions from '@/store/scorm/scorm.actions';
import * as fromScormReducers from '@/store/scorm/scorm.reducers';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-scorm-add',
  templateUrl: './scorm-add.component.html',
  styleUrls: ['./scorm-add.component.scss']
})
export class ScormAddComponent implements OnInit {
  uploading = false;
  constructor(
    private toastr: ToastrService,
    private store: Store<fromScormReducers.State>,
    private router: Router,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
  }

  handleUploadChange(info: { file: NzUploadFile }): void {
    console.log(info);
    switch (info.file.status) {
      case 'uploading':
        this.uploading = true;
        break;
      case 'done':
        this.store.dispatch(fromScormActions.addScorm({item: info.file.response}));
        this.uploading = false;
        const formData = new FormData();
        console.log(info.file);
        formData.append('scorm', info.file.originFileObj as any, info.file.response.code + '.zip');
        this.http.post<any>('/assets/scorm/scorm.php',formData).subscribe((res) => {
          console.log(res);
          this.toastr.success('Dodano szkolenie SCORM');
          this.router.navigate(['/training/admin/scorm']);

        });
        break;
      case 'error':
        info.file.error.error.error.map( e => {
          this.toastr.error(e);
        });
        this.uploading = false;
        break;
    }
  }

}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environments/environment';
import { merge, Observable, of, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import * as fromReducers from '@/store/search/search.reducers';
import * as fromActions from '@/store/search/search.actions';
import { select, Store } from '@ngrx/store';
import { SearchResult } from '@/model/search.result.model';
import { KbFile } from '@/model/file.model';
@Component({
  selector: 'app-search-btn',
  templateUrl: './search-btn.component.html',
  styleUrls: ['./search-btn.component.scss']
})
export class SearchBtnComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  term: any;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  show = false;
  // @ViewChild("searchBlock") searchBlock: ElementRef;

  constructor(
    private store: Store<fromReducers.State>,
    private httpClient: HttpClient,
    private router: Router,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {}


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get results(): Observable<SearchResult[]> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectSearchResults));
  }

  get hasMore(): Observable<boolean> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectHasMore));
  }

  get isLoading(): Observable<boolean> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectIsLoading));
  }

  get open(): Observable<boolean> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectIsOpen));
  }

  search(term: string) {
    this.store.dispatch(fromActions.search({term}));
  }

  clear() {
    this.term = "";
    this.show = false;
    this.store.dispatch(fromActions.clear());
  }

  onScroll(): void {
    console.log("TEST");
    this.store.dispatch(fromActions.more());
  }

  onScrollUp(): void {
    console.log("TEST");
  }

  typeName (t:any) {
    switch(t.type) {
      case 'post': return 'News';
      case 'article': return 'Artykuł';
      case 'user': return 'Użytkownik';
      case 'offer': return 'Oferta pracy';
      case 'file': return 'Plik';
      case 'faq': return 'FAQ';
    }
  }

  clikedOpen() {
    // console.log(this.searchBlock.nativeElement.style.display != 'none');
    // if (this.searchBlock.nativeElement.style.display != 'none') {
    //   this.renderer.setStyle(this.searchBlock.nativeElement, 'display', 'none');
    // }
    this.show = true;
  }

  clikedOutise() {
    // console.log(this.searchBlock.nativeElement.style.display != 'none');
    // if (this.searchBlock.nativeElement.style.display != 'none') {
    //   this.renderer.setStyle(this.searchBlock.nativeElement, 'display', 'none');
    // }
    this.show = false;
  }

  onClick(item) {
    switch(item.type) {
      case 'file':
        return this.download(item);
      default:
        this.router.navigateByUrl(item.url);
    }
    this.clear();
  }

  download(item) {
    this.httpClient
      .get(`${environment.apiUrl}/v1/kb/file/${item.uid}`, {responseType: 'json'})
      .subscribe(
        (file: KbFile) => {
          console.log(file);
          this.httpClient
            .get(`${environment.apiUrl}/v1/kb/file/${file.id}/download`, {responseType: 'blob'})
            .subscribe(
              (response) => {
                let blob:any = new Blob([response], { type: file.mimeType });
                // saveAs(blob, file.name);

                const url = window.URL.createObjectURL(blob);
                var anchor = document.createElement('a');
              	anchor.href = url;
              	anchor.download = file.name;
              	document.body.appendChild(anchor);
              	anchor.click();
              },
              error => console.log('Error downloading the file'),
              () => console.info('File downloaded successfully')
            )
        }
    );
  }
}

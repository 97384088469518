import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import * as fromRedmineCategoryActions from '@/store/redmine/redmine.category.actions';
import * as fromRedmineCategoryReducers from '@/store/redmine/redmine.category.reducers';
import { RedmineCategory } from '@/model/redmine.model';
import { Router } from '@angular/router';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject, fromEvent, Observable } from 'rxjs';
import { takeUntil, map, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-redmine-select-category',
  templateUrl: './redmine-select-category.component.html',
  styleUrls: ['./redmine-select-category.component.scss']
})
export class RedmineSelectCategoryComponent implements OnInit {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  searchTerm: string;
  page = 1;
  pageSize = 20;
  constructor(
    protected store: Store<fromRedmineCategoryReducers.State>,
    protected actionsSubject: ActionsSubject,
    protected router: Router,
    protected toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.store.dispatch(fromRedmineCategoryActions.fetch());
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      takeUntil(this.unsubscribe$),
      map((event: any) => event.target.value),
      filter( (res:string) => res.length > 2),
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((text: string) => {
      this.store.dispatch(fromRedmineCategoryActions.setSearch({search: text.toLowerCase().trim()}));
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  clearSearch() {
    this.searchInput.nativeElement.value = '';
    this.store.dispatch(fromRedmineCategoryActions.setSearch({search: null}));
  }

  pageChange(page): void {
    this.store.dispatch(fromRedmineCategoryActions.setPage({page: page}));
  }

  identify(index, entity: RedmineCategory){ return entity.id }

  get list(): Observable<RedmineCategory[]>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromRedmineCategoryReducers.selectState),
      map( (state:fromRedmineCategoryReducers.State)  => fromRedmineCategoryReducers.selectAll(state.list))
    );
  }

  get total(): Observable<number>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromRedmineCategoryReducers.selectState),
      map( (state:fromRedmineCategoryReducers.State)  => state.total)
    );
  }

  get loading(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromRedmineCategoryReducers.selectState),
      map( (state:fromRedmineCategoryReducers.State)  => state.loading),
    );
  }

}

import { AppNotification } from '@/model/notification.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@services/notification.service';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  page = 1;
  pageSize = 10;

  constructor(
    private service: NotificationService,
    private router: Router
  ) { }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  get count(): Observable<number> {
    return this.service.count();
  }

  get list(): Observable<AppNotification[]>{
    return this.service.list().pipe(
      takeUntil(this.unsubscribe$),
      distinctUntilChanged( (x: any[], y: any[]) => x.length == y.length),
      filter(list => list.length > 0),
      map(list => list.slice(this.pageSize*(this.page - 1), this.pageSize*(this.page)))
    );
  }

  identify (item: AppNotification, index: number) {
    return item.id;
  }

  type (item: AppNotification) {
    if(item.subtitle)
      return item.subtitle;
    switch (item.type) {
      case 'post' : return 'News';
      case 'offer' : return 'Oferta pracy';
      case 'comment_idea' : return 'Komentarz do pomysłu';
      case 'comment_post' : return 'Komentarz do posta';
      case 'comment_reply' : return 'Odpowiedz na komentarz';
      case 'comment_mention' : return 'Wzmianka w komentarzu';
      case 'post_mention' : return 'Wzmianka w poście';
      case 'post_article' : return 'Wzmianka w atykule';
      case 'redmine' : return 'Zgłoszenie';
      default :
        return item.type;
    }
  }

  link (item: AppNotification) {
    switch (item.type) {
      case 'post' :
        this.router.navigate(['/news', item.id]);
        break;
      case 'offer' :
        this.router.navigate(['/offer', item.id]);
        break;
      default :
        this.router.navigate([item.url.split('#')[0]], {fragment: item.url.split('#')[1] });
        this.service.delete(item);
        break;
    }
  }

}

import { Post } from '@/model/post.model';
import {Component} from '@angular/core';
import { ListNewsComponent } from '@pages/news/list-news/list-news.component';
import { takeUntil, filter, map, take } from 'rxjs/operators';
import * as fromPostReducers from '@/store/post/post.reducers';
import * as fromPostActions from '@/store/post/post.actions';
import * as fromUsersAction from '@/store/users/users.actions';
import * as fromUsersReducers from '@/store/users/users.reducers';

import { ActionsSubject, select, Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { ChatService } from '@services/chat.service';
import { Observable } from 'rxjs';
import { MessageService } from '@services/messages/message.service';
import { InnerMessage } from '@/model/message.mode';
import { HttpClient } from '@angular/common/http';
import { User } from '@/model/user.model';
import { NotificationService } from '@services/notification.service';
import { RedmineService } from '@services/redmine.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends ListNewsComponent {


  constructor(
    store: Store<fromPostReducers.PostsState>,
    private usersStore: Store<fromUsersReducers.State>,
    router: Router,
    activatedRoute: ActivatedRoute,
    actionsSubject$: ActionsSubject,
    private chatService: ChatService,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private redmineService: RedmineService,
  ) {
    super(store, router, activatedRoute, actionsSubject$);
  }

  get msg(): Observable<number> {
    // return this.messageService.messages.pipe(takeUntil(this.unsubscribe$),map( (arr:InnerMessage[]) => arr.filter(m => !m.seen).length));
    return this.messageService.unseenCount;
  }

  get chat(): Observable<number> {
    return this.chatService.count;
  }

  get newPosts(): Observable<number> {
    // return this.notificationService.countPosts();
    return this.notificationService.count();
  }

  get redmineAll(): Observable<number> {
    return this.redmineService.allCount;
  }

  get redmineAssigned(): Observable<number> {
    return this.redmineService.count;
  }

  protected dispatchMore() {
    this.store.dispatch(fromPostActions.fetchNewsAction({observed: false, before: this.oldestId }));
  }

  ngOnInit(): void {
    this.onInit();
    this.store.dispatch(fromPostActions.fetchNewsAction({observed: false}));
    this.store.dispatch(fromUsersAction.fetchRecentUsers());

  }

  get recentUsers(): Observable<User[]> {
    return this.usersStore.pipe(takeUntil(this.unsubscribe$), select(fromUsersReducers.selectUsersState), map(state => state.recentUsers));
  }

  get posts(): Observable<Post[]> {
    return this.store.pipe(takeUntil(this.unsubscribe$),select(fromPostReducers.selectAllPosts));//.pipe(tap(console.log))
  }

  protected onInit(): void {
    // this.router.navigate([{outlets: { sidebar: 'news'}}]);
    this.store.dispatch(fromPostActions.setHasMore({hasMore: true, loading: true}));
    this.actionsSubject$.pipe(
      takeUntil(this.unsubscribeLoadBetween$),
      filter((action) => action.type === fromPostActions.addMultipleNews.type),
      map( (action:any) => action.posts)
    ).subscribe( (newPosts:Post[]) => {
      this.posts.pipe(takeUntil(this.unsubscribe$),take(1)).subscribe((allPosts: Post[]) => {
        let L = newPosts.length;
        this.newestId = L && this.newestId < newPosts[0].id ? newPosts[0].id : this.newestId;
        this.oldestId = L && (!this.oldestId || this.oldestId > newPosts[L-1].id) ? newPosts[L-1].id : this.oldestId;
        if(allPosts.length == 0) {

        }
        else if (this.oldestId > allPosts[allPosts.length-1].id) {
          this.loading.pipe(take(1),filter(x => x)).subscribe( () => {
            this.store.dispatch(fromPostActions.fetchNewsAction({observed: false, before: this.oldestId}));
          });
        }
        else {
          // this.unsubscribeLoadBetween$.next();
          // this.unsubscribeLoadBetween$.complete();
        }
      });
    })
  }
}

<div class="content-header pt-0">
  <div  [checkRolePermition]="['offers.create', 'offers.category']" class="row">
    <div class="col-12 text-right">
      <a [routerLink]="['admin/category']" class="btn btn-primary mr-1" checkRolePermition="offers.category" >
        <i class="fas fa-list fa-fw"></i> Kategorie
      </a>
      <a [routerLink]="['admin/add']" class="btn btn-primary ml-1" checkRolePermition="offers.create" >
        <i class="fas fa-plus fa-fw"></i> Nowa oferta
      </a>
    </div>
  </div>
</div>
<!--
<section class="content">
  <div class="container-xl"> -->
    <router-outlet></router-outlet>
  <!-- </div> -->
<!-- </section> -->
<!-- <pre>{{folder | async | json}}</pre> -->

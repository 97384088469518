import { User } from "@/model/user.model";
import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import * as fromActions from './auth.actions';
import * as fromReducers from './auth.reducers';

import * as fromUsersActions from '@/store/users/users.actions';
import * as fromUsersReducers from '@/store/users/users.reducers';
import { StringifyHttpErrorResponse } from "@/utils/http.error.util";

const STORAGE_TOKEN_KEY = 'USER_TOKEN';
const STORAGE_USER_KEY = 'USER_JSON';

@Injectable()
export class AuthEffects {
  constructor (
    private actions$: Actions,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private store$: Store<fromReducers.State>,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {}

  onError (err: HttpErrorResponse, caught): any {
    this.toastr.error(StringifyHttpErrorResponse(err));
    return caught;
  };

  login$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.loginAction),
    switchMap( (action) => {
      return this.httpClient.post<{token: string, user: User}>('<backendhost>/v1/auth/login', {
          email: action.email,
          password: action.password
        }, {
          observe: 'response',
          // responseType: 'json',
          withCredentials: true
        });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: HttpResponse<{token: string, user: User}>) => {
      console.log(response);
      console.log(response.headers);
      console.log(response.headers.keys());
      let actionsToDispatch = [
        fromActions.setTokenAction({token: response.body.token}),
        fromActions.saveTokenAction({token: response.body.token}),
        fromActions.saveUserLocal({user: response.body.user}),
        fromActions.setProfileAction({profile: response.body.user}),
        fromActions.loginSuccess(),
      ];
      return actionsToDispatch;
    })
  ));

  signout$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.signoutAction),
    tap( () => {this.storage.remove(STORAGE_TOKEN_KEY)}),
    tap( () => {this.storage.remove(STORAGE_USER_KEY)}),
    map( () => fromActions.clearUserAction())
  ));

  saveToken$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.saveTokenAction),
    switchMap( (action) => {
      this.storage.set(STORAGE_TOKEN_KEY, action.token);
      return [];
    })
  ));

  saveUserLocal$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.saveUserLocal),
    switchMap( (action) => {
      this.storage.set(STORAGE_USER_KEY, action.user);
      return [];
    })
  ));

  fetchToken$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchTokenAction),
    map( (action) => this.storage.get(STORAGE_TOKEN_KEY) || null),
    map( (token: string) => fromActions.setTokenAction({token: token}))
  ));

  updateProfile$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.editProfileAction),
    switchMap( (action) => {
      // this.store$.dispatch(fromActions.setLoadingAction());
      return this.httpClient.put<User>('<backendhost>/v1/user', action.profile, {
          observe: 'body',
          responseType: 'json'
        });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: User) => {
      let actionsToDispatch = [
        fromActions.setProfileAction({profile: response}),
        fromUsersActions.updateUser({user:response})
      ];
      return actionsToDispatch;
    })
  ));

  fetchProfile$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchProfileAction),
    map(() => fromActions.setLoadingAction()),
    switchMap( (_) => {
      return this.httpClient.get<User>('<backendhost>/v1/user', {
          observe: 'body',
          responseType: 'json'
        });
    }),
    catchError((err: HttpErrorResponse, caught): any => {
      switch (err.status) {
        case 401:
          this.toastr.error("Błąd autoryzacji");
          break;
        case 405:
          this.toastr.error("Nie masz uprawnień");
          break;
        default:
          if(this.storage.has(STORAGE_USER_KEY)) {
            // return this.storage.get(STORAGE_USER_KEY)
            this.store$.dispatch(fromActions.setProfileAction({profile: this.storage.get(STORAGE_USER_KEY)}));

          } else {
            this.toastr.error(StringifyHttpErrorResponse(err));
          }

          break;
      }
      return caught;
    }),
    switchMap((response: User) => {
      let actionsToDispatch = [
        fromActions.setProfileAction({profile: response}),
      ];
      return actionsToDispatch;
    })
  ));

  setProfileAction$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.setProfileAction),
    map((action:any) => fromActions.saveUserLocal({user: action.profile})),
  ));
}

<div class="card">
  <div class="card-header">
    <h3 class="card-title">Wysłane</h3>
    <div class="card-tools flex-grow-1">
      <div class="input-group">
        <input type="text" name="table_search" class="form-control float-right" placeholder="Szukaj" #searchInput>
        <button class="btn bg-transparent" style="position:absolute; right: 0; transform: translate(-100%,0);" (click)="clearSearch()">
          <i class="fa fa-times"></i>
        </button>
        <span class="input-group-append">
          <div class="input-group-text bg-transparent"><i class="fas fa-search"></i></div>
        </span>
      </div>
    </div>
  </div>

  <div class="table-responsive">
    <div class="overlay" *ngIf="loading | async">
      <i class="fas fa-3x fa-sync-alt fa-spin"></i>
    </div>
    <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(messages | async).length == 0"></nz-empty>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th style="width:1px">#</th>
          <th>Do</th>
          <th class="sortable" (click)="setSort('title')"  [ngClass]="{'active': (order | async) == 'title'}"  >
            Tytuł
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <th class="sortable" (click)="setSort('createdAt')"  [ngClass]="{'active': (order | async) == 'createdAt'}"  >
            Wysłano
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of messages | async; let idx = index; trackBy: identify" style="cursor:pointer" [routerLink]="['/messages/view', item.id]">
          <td class="align-middle text-right">{{idx+1 + (page-1)* pageSize}}</td>
          <td class="align-middle" *ngIf="item.sendTo == 'all'"><strong>Wszystkich</strong></td>

          <td class="align-middle" *ngIf="item.sendTo == 'groups'"><strong>Grup:</strong> &nbsp;
            <a *ngFor="let group of item.groups; let gIdx = index" [routerLink]="['/groups', group.id]">
              <img class="img-circle elevation-2" [src]="group | groupAvatar: 20"/>
              {{group.name}}
            </a>
          </td>

          <td class="align-middle" *ngIf="item.sendTo == 'users'"><strong>Użytkowników:</strong> &nbsp;
            <a *ngFor="let user of item.recipients; let rIdx = index" [routerLink]="['/profile', user.id]">
              <img class="img-circle elevation-2" [src]="user | userAvatar: 20" [appUserDisactivatable]="user"/>
              <span [appUserDisactivatable]="user">{{user.name}}</span>
            </a>
          </td>


          <td class="align-middle">{{item.title}}</td>
          <td class="align-middle text-nowrap">{{item.createdAt | amLocale:'pl' | amTimeAgo }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="card-footer" >
    <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="total | async" (pageChange)="pageChange($event)">
      <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationEllipsis>...</ng-template>
      <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>
  </div>
</div>

<div class="card card-outline card-primary">
    <div class="card-header text-center">
        <a [routerLink]="['/']" class="h1"><b>Intranet</b> - ŚLA</a>
    </div>
    <div class="card-body">
        <p class="login-box-msg">
          Jesteś tylko o krok od nowego hasła, odzyskaj hasło teraz
        </p>
        <form [formGroup]="recoverPasswordForm" (ngSubmit)="recoverPassword()">
            <div class="input-group mb-3">
              <input formControlName="email" type="email" class="form-control" readonly/>
              <div class="input-group-append">
                  <div class="input-group-text">
                      <span class="fas fa-at"></span>
                  </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input formControlName="code" type="text" class="form-control" readonly/>
              <div class="input-group-append">
                  <div class="input-group-text">
                      <span class="fas fa-barcode"></span>
                  </div>
              </div>
            </div>
            <div class="input-group mb-3">
                <input
                    formControlName="pass"
                    type="password"
                    class="form-control"
                    placeholder="Hasło"
                    autocomplete="off"
                    [appIsValid]="recoverPasswordForm.controls.pass"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                    </div>
                </div>

                <div class="invalid-feedback" *ngIf="recoverPasswordForm.controls.pass.errors?.required">
                  Pole jest wymagane
                </div>
                <div class="invalid-feedback" *ngIf="recoverPasswordForm.controls.pass.errors?.weekPassword">
                  Hasło musi mieć conajmniej 8 znaków, w tym przynajmniej jedną małą i dużą literą, cyfrę i znak specjalny.
                </div>
            </div>
            <password-strength-meter [password]="recoverPasswordForm.controls.pass.value"></password-strength-meter>
            <div class="input-group mb-3">
                <input
                    formControlName="repass"
                    type="password"
                    class="form-control"
                    placeholder="Potwierdz hasło"
                    autocomplete="off"
                    [appIsValid]="recoverPasswordForm.controls.repass"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                    </div>
                </div>
                <div class="invalid-feedback" *ngIf="recoverPasswordForm.controls.repass.errors?.required">
                  Pole jest wymagane
                </div>
                <div class="invalid-feedback" *ngIf="recoverPasswordForm.controls.repass.errors?.notSame">
                  Hasła nie są identyczne
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <app-button [type]="'submit'" [block]="true">
                        Zmień hasło
                    </app-button>
                </div>
            </div>
        </form>

        <p class="mt-3 mb-1">
            <a [routerLink]="['/login']">Login</a>
        </p>
    </div>
</div>

<div class="modal-header">
  <h4 class="modal-title">Witaj!</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>Cześć {{user?.firstname}}! Witaj w świecie badaj.to 😊 Cieszymy się, że jesteś z nami.</p>
</div>
<div class="modal-header card-footer justify-content-start pt-2 pb-2">
  <h5 class="card-title">Masz:</h5>
</div>
<div class="modal-body"  style="min-height: 95px; overflow: hidden; background: #eee;" >
  <nz-empty
    nzNotFoundImage="simple"
    [nzNotFoundContent]="'Nie masz żadnych powiadomień i wiadomości.'"
    *ngIf="newPostsLoaded && msgLoaded && chatLoaded && !(newPosts | async) && !(msg | async) && !(chat | async)">
  </nz-empty>
  <div class="overlay" *ngIf="!newPostsLoaded || !msgLoaded || !chatLoaded" style="background-color: rgba(255, 255, 255, 0.7);">
    <i class="fas fa-3x fa-spinner fa-spin"></i>
  </div>
  <div class="info-box shadow-sm" [routerLink]="['/news/unseen']" style="cursor: pointer" *ngIf="newPosts | async">
    <span class="info-box-icon bg-success"><i class="far fa-comments"></i></span>
    <div class="info-box-content">
      <span class="info-box-text">Nowe posty</span>
      <span class="info-box-number">{{newPosts | async}}</span>
    </div>
  </div>
  <div class="info-box shadow-sm" [routerLink]="['/messages/inbox']" style="cursor: pointer" *ngIf="msg | async">
    <span class="info-box-icon bg-info"><i class="far fa-envelope"></i></span>
    <div class="info-box-content">
      <span class="info-box-text">Nieprzeczytane wiadomości</span>
      <span class="info-box-number">{{msg | async}}</span>
    </div>
  </div>
  <div class="info-box shadow-sm" [routerLink]="['/chat']" style="cursor: pointer" *ngIf="chat | async">
    <span class="info-box-icon bg-orange"><i class="far fa-comments"></i></span>
    <div class="info-box-content">
      <span class="info-box-text">Nowe wiadomości chat</span>
      <span class="info-box-number">{{chat | async}}</span>
    </div>
  </div>
</div>
<div class="modal-header card-footer justify-content-start pt-2 pb-2">
  <h5 class="card-title">Poznaj nasz zespół, przedstawiamy:</h5>
</div>
<div class="modal-body position-relative" style="min-height: 95px; overflow: hidden;">
  <div class="overlay" *ngIf="!randomUser" style="background-color: rgba(255, 255, 255, 0.7);">
    <i class="fas fa-3x fa-spinner fa-spin"></i>
  </div>
  <div class="user-block float-none" *ngIf="randomUser">
    <img class="img-circle" [src]="randomUser | userAvatar: 60" alt="User Image" style="width: 60px; height: 60px;">
    <p class="username mb-1" style="margin-left:75px"><a [routerLink]="['/profile', randomUser.id]">{{randomUser.name}}</a></p>
    <p class="description mb-1" style="margin-left:75px">Utworzono  {{randomUser.createdAt | amLocale:'pl' | amCalendar }}</p>
    <p class="description mb-1" style="margin-left:75px">Z działu  <strong>{{ getBranchTitle() }}</strong></p>
  </div>
</div>

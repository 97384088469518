import { AuthService } from '@/auth/auth.service';
import { Post } from '@/model/post.model';
import { User } from '@/model/user.model';
import { Comment } from '@/model/comment.model';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as fromPostReducers from '@/store/post/post.reducers';
import * as fromPostActions from '@/store/post/post.actions';

import { SlideInOutAnimation } from '@/animations';
import { Observable, Observer } from 'rxjs';
import { Router } from '@angular/router';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';
import { ImagePipe } from '@/pipes/image.pipe';
import { environment } from 'environments/environment';
import { Actions, ofType } from '@ngrx/effects';
import { filter } from 'rxjs/operators';
import { KbImage } from '@/model/file.model';
import { PlyrComponent } from 'ngx-plyr';

@Component({
  selector: 'post-box',
  templateUrl: './post-box.component.html',
  styleUrls: ['./post-box.component.scss'],
  animations: [SlideInOutAnimation]
})
export class PostBoxComponent implements OnInit {
  @ViewChild('addComments') private addCommentsElement: ElementRef;
  @Input() post: Post;
  @Input() small: boolean = true;
  user: User;
  showComments = false;
  moreAnimationState = 'out';
  showOverlay = false;
  showCommentOverlay = true;
  private imagePipe: ImagePipe;
  @ViewChild(PlyrComponent)
  plyr: PlyrComponent;
  player: Plyr;


  constructor(
    private store: Store<fromPostReducers.PostsState>,
    private router: Router,
    private authService: AuthService,
    private lightbox: Lightbox,
    private lightboxConfig: LightboxConfig,
    private actions: Actions
  ) {
    this.imagePipe = new ImagePipe();
    this.lightboxConfig.showZoom = true;
    this.lightboxConfig.centerVertically = true;
    this.lightboxConfig.wrapAround = true;


  }

  audioOptions = {
    autoplay: false,
    muted: true,
    i18n: {
      speed: 'Prędkość',
      normal: 'Normalna',
    }
  }
  audioPathPlyr = []

  ngAfterViewInit(): void {
    this.actions.pipe(
      ofType(fromPostActions.fetchPostCommentsAction),
      filter(action => action?.post?.id == this?.post?.id))
        .subscribe(() => this.showCommentOverlay = true);
    this.actions.pipe(
      ofType(fromPostActions.addManyCommentsToPostAction),
      filter(action => action?.post?.id == this?.post?.id))
        .subscribe(() => this.showCommentOverlay = false);
    setTimeout(() => {
      this.audioPathPlyr = this.post?.audio ? [{
        src: `${environment.apiUrl}/v1/audio/${this.post?.audio?.id}`,
        type: this.post.audio?.mimeType
      }] : [];
      if(!this.small) {
        setTimeout(() => {
          if(this.player)
            this.player.play();
        }, 100);
      }
    });
  }

  ngOnInit(): void {
    this.authService.getUser().then(user => {this.user = user});

  }

  toggleMore(): void {
    this.moreAnimationState = this.moreAnimationState === 'out' ? 'in' : 'out';
  }

  toggleShowComments(): void {
    // if (this.small) {
    //   this.router.navigate(['/news', this.post.id], {fragment: 'comments'});
    // } else if (!this.showComments){
    //   this.ShowComments();
    // } else {
    //   this.showComments = false;
    // }
    this.showComments = !this.showComments;
    if (this.showComments) {
      this.store.dispatch(fromPostActions.fetchPostCommentsAction({post: this.post}));
    }
  }

  private _images3cols;
  get images3cols(): KbImage[][] {
    if(!this.post) return [[],[],[]];
    if(this._images3cols) return this._images3cols;
    let height = [0,0,0];
    let result = [[],[],[]];
    this.post?.images.forEach(image => {
      let idx = height.indexOf(Math.min(...height));
      // console.log(height, idx, result);
      result[idx].push(image);
      // console.log(image.height);
      height[idx] = height[idx] + image.height / image.width;
    })
    this._images3cols = result;
    return result;
  }


  get comments(): Observable<Comment[]> {
    // this.store.dispatch(fromPostActions.fetchPostCommentsAction({post: this.post}));
    return this.store.pipe(select(fromPostReducers.selectComments, this.post.id));
    // .subscribe((comments: Comment[]) => {
    //   observer.next(comments);
    //   this.ShowComments();
    // })
  };

  ShowComments(): void {
    // this.showComments = true;
    setTimeout( () => {
      // scroll(this.addCommentsElement.nativeElement);
      // this.addCommentsElement.nativeElement.scrollTop = this.addCommentsElement.nativeElement.scrollHeight;
      try {
        console.log(this.addCommentsElement.nativeElement.scrollHeight);
        // window.scroll = this.addCommentsElement.nativeElement.scrollHeight + 1000;
      } catch(err) { }
    }, 10);
  }

  seen () {
    // console.log("SEEEEN");
    if (!this.post.seen && !this.post.important) {
      this.store.dispatch(fromPostActions.setAsSeen({post: this.post}));
    }
  }

  confirmSeen() {
    this.store.dispatch(fromPostActions.setAsSeen({post: this.post}));
  }

  like() {

    this.post = {...this.post, ...{likeSaving:true}};
    if (!this.post.liked) {
      this.store.dispatch(fromPostActions.likePostAction({post:this.post}));
    }
    else {
      this.store.dispatch(fromPostActions.unlikePostAction({post:this.post}));
    }

  }

  open(id: number): void {
    let index = this.post.images.map(img => img.id).indexOf(id);
    let images: any[] = this.post.images.map(img => {
      return {
        src: this.imagePipe.transform(img),
        // caption: img.name,
      }
    });
    this.lightbox.open(images, index);
  }

  close(): void {
    // close lightbox programmatically
    this.lightbox.close();
  }

  backgroundStyle () {

    if(!this.post)
      return null;
    if(!this.post.background)
      return null;
    return {
      'aspect-ratio': '4',
      'background' : `url(${environment.apiUrl}/thumb/1920x0/${this.post.background.replace('public','')}) center center`,
      'background-size': 'cover'
    }
  }

  delete() {
    this.showOverlay = true;
    this.store.dispatch(fromPostActions.deletePostAction({post:this.post}));
  }

  tooggleFavorite() {
    if(this.post.favoriteSaving) {
      return;
    }
    this.store.dispatch(fromPostActions.toogglePostFavorite({post:this.post}));
  }

  commentIdenity(item: Comment, index: number) {
    return item.id;
  }

}

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as fromUsersActions from '@/store/users/users.actions';
import * as fromUsersReducers from '@/store/users/users.reducers';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { User } from '@/model/user.model';
import { fromEvent, Observable, Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { debounceTime, distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@/auth/auth.service';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss']
})
export class ListUsersComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  @ViewChild('paginatorElem', { read: ElementRef }) paginatorElem: ElementRef;
  @ViewChild('paginatorElem', { static: true }) paginatorComp: NgbPagination;
  page = 1;
  maxSize = 22;
  pageSize = fromUsersReducers.initialState.limit;
  searchTerm: string;

  constructor(
    private store: Store<fromUsersReducers.State>,
    private authService: AuthService,
    private actionsSubject$: ActionsSubject,
  ) { }

  ngOnInit(): void {

    this.store.dispatch(fromUsersActions.clearFilters());
    this.store.dispatch(fromUsersActions.fetchUsers({}));
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      takeUntil(this.unsubscribe$),
      map((event: any) => event.target.value),
      filter( (res:string) => res.length > 2),
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((text: string) => {
      this.store.dispatch(fromUsersActions.setSearch({search: text.toLowerCase().trim()}));
    });
  }

  private decresePaginatroMaxSize() {
    let C = this.paginatorElem.nativeElement.parentNode.offsetWidth - 40;
    let P = this.paginatorElem.nativeElement.children[0].offsetWidth;
    if(C>=P || this.maxSize == 1)return;
    this.maxSize -= Math.ceil((P-C)/44);
  }

  ngAfterViewInit(): void {
    let resizeObserver = new ResizeObserver(entries => {
      this.maxSize = 22;
    })
    let resizeObserver2 = new ResizeObserver(entries => {
      this.decresePaginatroMaxSize();
    })
    resizeObserver2.observe(this.paginatorElem.nativeElement.children[0]);
    resizeObserver.observe(this.paginatorElem.nativeElement.parentNode);
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  clearSearch() {
    this.searchInput.nativeElement.value = '';
    this.store.dispatch(fromUsersActions.setSearch({search: null}));
  }

  get paginator(): Observable<User[]>  {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromUsersReducers.selectAllUsers));
  }

  get total(): Observable<number>  {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromUsersReducers.selectUsersCount));
  }

  pageChange(page): void {
    this.store.dispatch(fromUsersActions.setPage({page: page}));
  }

  delete(item: User): void {
    this.store.dispatch(fromUsersActions.deleteUser({user: item}));
  }

  toggleDeactivated() {
    this.store.dispatch(fromUsersActions.toggleDeactivated());
  }

  get deactivated() : Observable<boolean> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromUsersReducers.selectUsersState), map(state => state.deactivated));
  }

  activationToggle(item: User): void {
    const id = item.id;
    const user = {
      ...item,
      active: !item.active
    }
    this.actionsSubject$.pipe(
      ofType(fromUsersActions.updateUser),
      take(1),
    ).subscribe( (action: any) => {
      this.store.dispatch(fromUsersActions.fetchUsers({}));
      this.authService.getUser().then(currentUser => {
        console.log(currentUser.id === id, currentUser.id, id);
        if(currentUser.id === id) {
          this.authService.signOut();
        }
      })

    });

    this.store.dispatch(fromUsersActions.editUser({id, user}));

  }

  identify(index, item: User): number { return item.id;}

  get loading(): Observable<boolean>  {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromUsersReducers.selectUsersState), map(state => state.loading));
  }

  get saving(): Observable<boolean>  {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromUsersReducers.selectUsersState), map(state => state.saving));
  }

}


<div class="content-header pt-0">
  <div   checkRolePermition="reservations.admin" class="row">
    <div class="col-12 text-right">
      <a [routerLink]="['/reservations/admin']" class="btn btn-primary">
        <i class="fas fa-tools fa-fw"></i> Zarządzaj
      </a>
    </div>
  </div>
</div>

<router-outlet></router-outlet>

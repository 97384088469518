import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionsSubject, Store } from '@ngrx/store';

import * as fromActions from '@/store/quiz/quiz.actions';
import * as fromReducers from '@/store/quiz/quiz.reducers'
import { Quiz, QuizQuestion } from '@/model/quiz.model';
import { ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { pairwise, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-quiz-question-modal',
  templateUrl: './quiz-question-modal.component.html',
  styleUrls: ['./quiz-question-modal.component.scss']
})
export class QuizQuestionModalComponent implements OnInit {
  quiz: Quiz;
  form: FormGroup;
  private _question: QuizQuestion;
  static multiple = ['checkbox', 'radio', 'select'];
  public static types = {
    // 'text': 'Tekstowe',
    // 'number': 'Liczbowe',
    'radio': 'Jednokrotnego wyboru',
    'checkbox': 'Wielokrotnego wyboru',
    'select': 'Lista rozwijana',
  }

  constructor(
    public modal: NgbActiveModal,
    private store: Store<fromReducers.State>,
    private actionsSubject$: ActionsSubject,
    private toastr: ToastrService
  ) {
    this.form = new FormGroup({
      label: new FormControl(null, Validators.required),
      type: new FormControl('radio', Validators.required),
      options: new FormArray([], null),
    });
    this.prevValue = this.optionsCtrl.value;
    this.optionsCtrl
      .valueChanges
      // .pipe(startWith(this.optionsCtrl.value), pairwise())
      // .subscribe(([prev, next]: [any[], any[]]) => {
      .subscribe((values:any[]) => {

        let prev = this.prevValue.map(item => item.correct);
        let next = values.map(item => item.correct);
        console.log(prev, next);
        if (JSON.stringify(prev) == JSON.stringify(next)) return;
        if (next.length == prev.length) {
          if (next.filter(x => x).length == 0)
          {
            this.setCorrctValues(prev);
          } else if ( ['checkbox'].indexOf(this.form.controls.type.value) == -1) {
            let diff = next.map( (val,idx) => val != prev[idx]);
            this.setCorrctValues(diff);
          }
        } else if (next.length < prev.length) {
          if(next.filter(x => x).length == 0)
            this.setCorrctValues([true]);
        }
        this.prevValue = this.optionsCtrl.value;
      });
    this.form.controls.type.valueChanges.subscribe( type => {
      if ( ['checkbox'].indexOf(type) == -1) {
        let correctValues = this.prevValue.map(item => item.correct);
        if(correctValues.filter(x => x).length > 1) {
          let newValues = Array(correctValues.length).fill(false);
          newValues[correctValues.indexOf(true)] = true;
          this.setCorrctValues(newValues);
        }
      }
    })
    this.addOption();
  }


  ngOnInit(): void {}

  get question(): QuizQuestion {
    return this._question;
  }
  set question(value: QuizQuestion) {
    this._question = value;
    value.options.forEach( option => {
      if(value.options.length != this.optionsCtrl.controls.length) {
        this.optionsCtrl.push(this.createOptionGroup(option), {emitEvent: false})
      }

    })
    this.form.patchValue(value);
    this.prevValue = this.optionsCtrl.value;
  }
  private prevValue;
  private setCorrctValues(values: boolean[]) {
    values.forEach((val, idx) => {
      let optionGroup = this.optionsCtrl.controls[idx] as FormGroup;
      optionGroup?.controls.correct.setValue(val, {emitEvent: false});
    })
  }

  get optionsCtrl(): FormArray {
    return this.form.get('options') as FormArray;
  }

  getOptionFormGroup(idx: number): FormGroup {
    return this.optionsCtrl.controls[idx] as FormGroup;
  }

  createOptionGroup(value? : any): FormGroup {
    let group = new FormGroup({
      label: new FormControl(null, Validators.required),
      correct: new FormControl(false)
    });
    group.patchValue(value);
    return group;
  }

  addOption() {
    this.optionsCtrl.push(this.createOptionGroup());

    if (this.optionsCtrl.controls.length == 1) {
      this.setCorrctValues([true]);
    }

    this.prevValue = this.optionsCtrl.value;
  }

  delOption(idx: number) {
    this.optionsCtrl.controls.splice(idx,1);
    this.optionsCtrl.updateValueAndValidity();
    if (this.optionsCtrl.controls.length == 0) {
      this.addOption();
    }

    this.prevValue = this.optionsCtrl.value;
  }

  public static isMultiple(type: string): boolean {
    return this.multiple.includes(type);
  }

  public checkMultiple(type: string): boolean {
    return QuizQuestionModalComponent.isMultiple(type);
  }

  save(): void {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
     if (this.form.valid) {
      this.question = {...this.question, ...this.form.value};
      if (this.question.id) {
        this.store.dispatch(fromActions.updateQuizQuestion({quiz: this.quiz, question: this.question}));
      } else {
        this.store.dispatch(fromActions.createQuizQuestion({quiz: this.quiz, question: this.question}));
      }
      this.actionsSubject$.pipe(ofType(fromActions.addQuizQuestion,fromActions.setQuizQuestion)).subscribe(() => {
        this.toastr.success("Zapisano pytanie");
        this.modal.close();
      })

    } else {
      // this.toastr.warning("Trwa zapisywanie");
    }
  }

  correctChange(event: Event, index) {
    event.preventDefault();
    console.log('change', event)
    return true;
  }

}

import { AuthService } from "@/auth/auth.service";
import { CallCenterFill, CallCenterQuestionnaire } from "@/model/call-center.model";
import { PaginationDTO } from "@/model/pagination.model";
import { Survey } from "@/model/survey.model";
import { User } from "@/model/user.model";
import { StringifyHttpErrorResponse } from "@/utils/http.error.util";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";
import { DefaultEffects } from "../default/default.effects";
import { ErrorState } from "../error.store";

import * as fromActions from "./call-center.actions";
import * as fromReducers from "./call-center.reducers";

@Injectable()
export class CallCenterFillEffects extends DefaultEffects{
  private state: fromReducers.State;
  constructor(
    private actions$: Actions,
    private store: Store<fromReducers.State>,
    httpClient: HttpClient,
    toastr: ToastrService,
    errorStore: Store<ErrorState>
  ) {
    super(httpClient, toastr, errorStore);
    this.store.pipe(select(fromReducers.selectState)).subscribe((state: fromReducers.State) => {
        this.state = state;
      })
  }

  paginatorChange$ = createEffect(() => this.actions$.pipe(
    ofType(...[
      fromActions.setPage,
      fromActions.setSearch,
      fromActions.setOrder,
      fromActions.setDirection
    ]),
    map( () => fromActions.fetch())
  ));

  fetch$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetch),
    switchMap(() => {
      let params = new HttpParams();
      params = params.set('skip', this.state.limit * (this.state.page - 1));
      params = params.set('limit', this.state.limit);
      params = params.set('order', this.state.order);
      params = params.set('type', this.state.type);
      params = params.set('direction', this.state.asc ? 'asc' : 'desc');
      if(this.state.search)
        params = params.set('search', this.state.search);
      return this.httpClient.get<PaginationDTO<CallCenterFill>>(`<backendhost>/v1/call-center-fill`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: PaginationDTO<CallCenterFill>) => [fromActions.setMany(response)])
  ));

  fetchOne$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchOne),
    switchMap((action) => {
      return this.httpClient.get<CallCenterFill>(`<backendhost>/v1/call-center-fill/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((item: CallCenterFill) => [fromActions.addOne({item})])
  ));

  createQuestionnaire$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createQuestionnaireFill),
    switchMap((action) => {
      return this.httpClient.post<CallCenterFill>(`<backendhost>/v1/call-center-fill`, action.item,
       {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((item: CallCenterFill) => [
      // fromActions.addOne({item}),
      fromActions.createQuestionnaireFillSuccess({item})
    ])
  ));

  updateQuestionnaire$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.updateQuestionnaireFill),
    switchMap((action) => {
      return this.httpClient.put<CallCenterFill>(`<backendhost>/v1/call-center-fill/${action.item.id}`, action.item,
       {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((item: CallCenterFill) => [
      fromActions.setOne({item}),
      fromActions.createQuestionnaireFillSuccess({item})
    ])
  ));

  deleteQuestionnaireFill$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deleteQuestionnaireFill),
    switchMap((action) => {
      return this.httpClient
                  .delete<any>(`<backendhost>/v1/call-center-fill/${action.id}`)
                  .pipe(map(()=> action.id));;
    }),
    catchError(this.onError.bind(this)),
    switchMap((id: number) => [fromActions.removeOne({id})])
  ));

  sendFillNotify$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.sendFillNotify),
    switchMap((action) => {
      return this.httpClient.get<any>(`<backendhost>/v1/call-center-fill/${action.item.id}/notify`,
       {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap(() => [])
  ));
}

import { Component, ComponentFactoryResolver, ElementRef, OnDestroy, OnInit, QueryList, Renderer2, ViewChild, ViewChildren, ViewContainerRef, ViewRef } from '@angular/core';

import * as fromOfferReducer from '@/store/offer/offers.reducers';
import * as fromOfferActions from '@/store/offer/offer.actions';
import { select, Store } from '@ngrx/store';
import { Observable, of, Subject, zip } from 'rxjs';
import { Offer, OfferCategory } from '@/model/offer.model';
import { distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { OfferBoxComponent } from '../offer-box/offfer-box.component';
import { EntityState } from '@ngrx/entity';
import { ActivatedRoute, Data } from '@angular/router';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import * as fromOfferCategoryReducers from '@/store/offer-category/offer.category.reducers';
declare var $:any;
@Component({
  selector: 'app-list-offers',
  templateUrl: './list-offers.component.html',
  styleUrls: ['./list-offers.component.scss']
})
export class ListOffersComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  skip = 0;
  categories: OfferCategory[];
  filterForm: FormGroup;
  constructor(
    private store: Store<fromOfferReducer.State>,
    private categoryStore: Store<fromOfferCategoryReducers.State>,
    private componentFactoryResolver: ComponentFactoryResolver,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
  ) {
    this.filterForm = new FormGroup({
      categories: new FormControl(null)
    })
    this.categoriesFormControl.valueChanges.subscribe((change) => {
      console.log(change);
    })
  }

  ngAfterViewInit() {
  }

  get categoriesFormControl(): FormControl {
    return this.filterForm.controls.categories as FormControl;
  }

  get allCategories(): boolean {
    return false;// !Object.values(this.categoriesFormGroup.value).reduce((a,b) => a || b) as boolean;
  }

  clearOtherCategory(event: PointerEvent, id) {
    if(this.categoriesFormControl.value == id)
      this.categoriesFormControl.setValue(null,{emitEvent: true});
      $(event.target).parent().removeClass('active');
  }

  ngOnInit(): void {
    this.store.dispatch(fromOfferActions.fetch({skip: this.skip}));
    this.activatedRoute.parent.data.subscribe((data:Data) => {
      this.categories = data.categories;
    });
    this.categoryStore.pipe(
      takeUntil(this.unsubscribe$),
      select(fromOfferCategoryReducers.selectAllOfferCategory)
    ).subscribe(categories => {
      this.categories = categories;
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  get offers(): Observable<Offer[]> {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      // distinctUntilChanged(),
      select(fromOfferReducer.selectAllOffers),
      tap( arr => { this.skip = arr.length;}),
      map(list => {
        return list.filter((offer) => {
          if(this.categoriesFormControl.value == null) return true;
          return offer.category.map(c => c.id).indexOf(this.categoriesFormControl.value) != -1;
        })
      })
    );
  }

  identify(item: Offer, index: number) {
    return item.id;
  }

  onScrollUp() {
  }

  onScroll() {
    this.loadMore();
  }

  prev(index: number): Observable<Offer> {
    return index ? this.offers.pipe(map( (arr:Offer[]) => arr[index-1])) : of(null);
  }
  before(post:Offer, index: number): Observable<boolean> {
    return index ?
      this.prev(index).pipe(
        map( (prev:Offer) => {
          return new Date(prev.createdAt).getDate() !== new Date(post.createdAt).getDate()
        })
      )
      : of(true);
  }

  get loading(): Observable<boolean> {
    return this.store.pipe(select(fromOfferReducer.selectLoadding));
  }

  get hasMore(): Observable<boolean> {
    return this.store.pipe(select(fromOfferReducer.selectCount),map(total => total > this.skip));
  }

  async loadMore() {
    zip(this.loading, this.hasMore).pipe(
      map( (arr: boolean[]) => !arr[0] && arr[1]),
      filter(s => s)
    ).subscribe( (s: any) => {
      this.store.dispatch(fromOfferActions.fetch({skip: this.skip}));
    });
  }
}

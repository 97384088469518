<div class="modal-header">
  <h4 class="modal-title">{{idea.title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [innerHtml]="idea.description | safeHtml" style="border-bottom: 1px solid #dee2e6;"></div>
<form [formGroup]="form" (ngSubmit)="send()">
<div class="modal-body">
    <div class="form-group">
      <label for="email">Status zgłoszenia</label>
      <select formControlName="status" [appIsValid]="form.controls.status" class="form-control">
        <option value="new">Nowe</option>
        <option value="analysis">Zatwierdzone do analizy</option>
        <option value="voting">Udostępniony do głosowania</option>
        <option value="rejectred">Odrzucone</option>
      </select>
      <div class="invalid-feedback" *ngIf="form.controls.status.errors?.required">
        Pole jest wymagane
      </div>
    </div>
    <div class="form-group" *ngIf="form.value.status == 'voting'">
      <label for="reffererName">Głosowanie dostępne do</label>
      <app-date-time-picker formControlName="votingUntil" name="votingUntil"  [appIsValid]="form.controls.votingUntil"></app-date-time-picker>
      <div class="invalid-feedback" *ngIf="form.controls.votingUntil.errors?.required">
        Pole jest wymagane
      </div>
      <div class="invalid-feedback" *ngIf="form.controls.votingUntil.errors?.invalidDate">
        Format daty jest nieprawidłowy
      </div>
    </div>
    <div class="form-group">
      <label for="reffererName">Zgłoszony pomysł publiczny do</label>
      <app-date-time-picker formControlName="publicUntil" name="publicUntil"  [appIsValid]="form.controls.publicUntil"></app-date-time-picker>
      <div class="invalid-feedback" *ngIf="form.controls.publicUntil.errors?.required">
        Pole jest wymagane
      </div>
      <div class="invalid-feedback" *ngIf="form.controls.publicUntil.errors?.invalidDate">
        Format daty jest nieprawidłowy
      </div>
    </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" type="submit">
    <i class="fas fa-check fa-fw"></i>
    Zapisz
  </button>
</div>
</form>

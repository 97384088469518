<section class="content">
  <div class="container">
      <div class="row">
          <div class="col-lg-12">
            <form [formGroup]="form" (ngSubmit)="save()">
            <div class="card card-primary">
              <div class="card-body">
                <div class="form-group">
                  <label for="inputName">Nazwa quizu</label>
                  <input
                    formControlName="name"
                    type="text"
                    class="form-control"
                    placeholder="Nazwa quizu"
                  />
                </div>
                <div class="form-group">
                  <label for="inputName">Próg zaliczenia szkolenia</label>
                  <nz-slider
                    formControlName="minScore"
                    [nzMin]="0"
                    [nzMax]="100"
                    nzTooltipVisible="always"
                    [nzTipFormatter]="percentFormatter"></nz-slider>
                </div>
                <div class="form-group">
                  <label for="inputName">Liczba wyświetlanych pytań</label>
                  <nz-slider
                    *ngIf="showSilder"
                    formControlName="questionNum"
                    [nzMin]="1"
                    [nzMax]="questions.length"
                    nzTooltipVisible="always"></nz-slider>
                </div>
              </div>
              <div class="card-footer">
                <button class="btn btn-success float-right" type="submit">
                  Zapisz
                  <i class="fas fa-angle-right fa-fw fa-lg"></i>
                </button>
              </div>
              <div class="card-header">
                <div class="card-title">Pytania</div>
              </div>
              <div class="table-responsive">
                <table class="table align-middle table-hover">
                  <thead>
                    <tr>
                      <th style="width:1rem">#</th>
                      <th>Pytanie</th>
                      <th>Rodzaj</th>
                      <th>Odpowiedzi</th>
                      <th class="text-right" >
                        <span class="btn btn-success btn-sm elevation-2 text-nowrap" (click)="addQuestion()">
                          <i class="fas fa-plus fa-fw fa-lg"></i> Dodaj
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of questions; let idx = index; ">
                      <td>{{idx+1}}</td>
                      <td>{{item.label}}</td>
                      <td>{{ getTypeName(item) }}</td>
                      <td>
                        <ol *ngIf="checkMultiple(item)">
                          <li *ngFor="let option of item.options">
                            {{option.label}}
                            <i class="fas fa-check fa-fw text-success" *ngIf="option.correct"></i>
                          </li>
                        </ol>
                      </td>
                      <td class="text-right text-nowrap"  style="width:1rem">
                        <a class="btn btn-primary btn-sm elevation-2 mr-2" (click)="editQuestion(item)" ngbTooltip="Edycja">
                          <i class="fas fa-edit fa-fw fa-lg"></i>
                        </a>
                        <a
                          class="btn btn-danger btn-sm elevation-2"
                          nz-popconfirm
                          nzPopconfirmTitle="Czy na pewno usunąć?"
                          nzOkText="Tak"
                          nzCancelText="Nie"
                          nzPopconfirmPlacement="left"
                          ngbTooltip="Usuń"
                          (nzOnConfirm)="deleteQuestion(item)" >
                          <i class="fas fa-trash fa-fw fa-lg"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="!questions?.length"></nz-empty>
              </div>

            </div>
            </form>
          </div>
      </div>
  </div>
</section>

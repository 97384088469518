<div class="modal-header">
  <h4 class="modal-title">Poleć oferę</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (ngSubmit)="send()">
<div class="modal-body">
    <div class="form-group">
      <label for="email">E-Mail osoby polecanej</label>
      <input formControlName="email" type="email" [appIsValid]="form.controls.email" class="form-control" placeholder="E-Mail" />
      <div class="invalid-feedback" *ngIf="form.controls.email.errors?.required">
        Pole jest wymagane
      </div>
      <div class="invalid-feedback" *ngIf="form.controls.email.errors?.email">
        Email nie jest poprawny
      </div>
    </div>
    <div class="form-group">
      <label for="reffererName">Twoje imię</label>
      <input formControlName="reffererName" type="text" [appIsValid]="form.controls.reffererName" class="form-control" placeholder="Twoje imię" />
      <div class="invalid-feedback" *ngIf="form.controls.reffererName.errors?.required">
        Pole jest wymagane
      </div>
    </div>
    <div class="form-group">
      <label for="email">Twój E-Mail</label>
      <input formControlName="reffererEmail" type="email" [appIsValid]="form.controls.reffererEmail" class="form-control" placeholder="Twój E-Mail" />
      <div class="invalid-feedback" *ngIf="form.controls.reffererEmail.errors?.required">
        Pole jest wymagane
      </div>
      <div class="invalid-feedback" *ngIf="form.controls.reffererEmail.errors?.email">
        Email nie jest poprawny
      </div>
    </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" type="submit">
    <i class="fas fa-paper-plane fa-fw"></i>
    Wyślij
  </button>
</div>
</form>

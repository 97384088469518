import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { fromEvent, Observable, Subject } from 'rxjs';
import * as fromActions from '@/store/reservations/reservations.actions';
import * as fromReducers from '@/store/reservations/reservations.reducers';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { ReservationCategory } from '@/model/reservation.model';

@Component({
  selector: 'app-admin-reservations',
  templateUrl: './admin-reservations.component.html',
  styleUrls: ['./admin-reservations.component.scss']
})
export class AdminReservationsComponent implements OnInit {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  page = 1;
  pageSize = fromReducers.initialState.admin.limit;
  searchTerm: string;

  constructor(
    private store: Store<fromReducers.State>
  ) { }

  ngOnInit(): void {
    this.store.dispatch(fromActions.reset());
    this.store.dispatch(fromActions.fetchAdminReservationCategory());
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      takeUntil(this.unsubscribe$),
      map((event: any) => event.target.value),
      filter( (res:string) => res.length > 2),
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((text: string) => {
      this.store.dispatch(fromActions.setAdminSearch({search: text.toLowerCase().trim()}));
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  clearSearch() {
    this.searchInput.nativeElement.value = '';
    this.store.dispatch(fromActions.setAdminSearch({search: null}));
  }

  get paginator(): Observable<ReservationCategory[]>  {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectAllAdminReservationCategories));
  }

  get total(): Observable<number>  {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectReservationState), map(state => state.admin.total));
  }

  pageChange(page): void {
    this.store.dispatch(fromActions.setAdminPage({page: page}));
  }

  delete(item: ReservationCategory): void {
    this.store.dispatch(fromActions.deleteReservationCategory({id: item.id}));
  }

  identify(index, item: ReservationCategory): number { return item.id;}

  get loading(): Observable<boolean>  {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectReservationState), map(state => state.admin.loading));
  }

}

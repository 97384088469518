import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { fromEvent, Observable, Subject } from 'rxjs';

import * as fromTrainingPackageActions from '@/store/package/package.actions';
import * as fromTrainingPackageReducers from '@/store/package/package.reducers';
import { debounceTime, distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { TrainingPackage } from '@/model/package.model';


@Component({
  selector: 'app-package-archive',
  templateUrl: './package-archive.component.html',
  styleUrls: ['./package-archive.component.scss']
})
export class PackageArchiveComponent implements OnInit {
    private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
    searchTerm: string;
    page = 1;
    pageSize = 20;
    constructor(
      protected store: Store<fromTrainingPackageReducers.State>,
    ) { }

    ngOnInit(): void {
      this.store.dispatch(fromTrainingPackageActions.clear());
      this.store.dispatch(fromTrainingPackageActions.setArchive({archive: true}));
      this.store.dispatch(fromTrainingPackageActions.fetch());
      this.store.pipe(
        take(1),
        takeUntil(this.unsubscribe$),
        select(fromTrainingPackageReducers.selectState),
        map(state => state.search)
      ).subscribe((search:string) => {
        this.searchInput.nativeElement.value = search
      });
      fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
        takeUntil(this.unsubscribe$),
        map((event: any) => event.target.value),
        filter( (res:string) => res.length > 2),
        debounceTime(1000),
        distinctUntilChanged()
      ).subscribe((text: string) => {
        this.store.dispatch(fromTrainingPackageActions.setSearch({search: text.toLowerCase().trim()}));
      });
    }

    ngOnDestroy() {
      this.unsubscribe$.next(true);
      this.unsubscribe$.complete();
    }

    clearSearch() {
      this.searchInput.nativeElement.value = '';
      this.store.dispatch(fromTrainingPackageActions.setSearch({search: null}));
    }

    get list(): Observable<TrainingPackage[]> {
      return this.store.pipe(takeUntil(this.unsubscribe$), select(fromTrainingPackageReducers.selectAllTrainingPackage));
    }

    delete(item: TrainingPackage): void {
      this.store.dispatch(fromTrainingPackageActions.removeTrainingPackage({id: item.id}));
    }

    archive(item: TrainingPackage): void {
      this.store.dispatch(fromTrainingPackageActions.archiveTrainingPackage({id: item.id}));
    }

    identify(index, entity: TrainingPackage){ return entity.id }

    get total(): Observable<number>  {
      return this.store.pipe(
        takeUntil(this.unsubscribe$),
        select(fromTrainingPackageReducers.selectState),
        map( (state:fromTrainingPackageReducers.State)  => state.total)
      );
    }

    get direction(): Observable<boolean>  {
      return this.store.pipe(
        takeUntil(this.unsubscribe$),
        select(fromTrainingPackageReducers.selectState),
        map( (state:fromTrainingPackageReducers.State)  => state.asc),
        tap( asc => this.asc = asc)
      );
    }

    get loading(): Observable<boolean>  {
      return this.store.pipe(
        takeUntil(this.unsubscribe$),
        select(fromTrainingPackageReducers.selectState),
        map( (state:fromTrainingPackageReducers.State)  => state.loading),
      );
    }

    get order(): Observable<string>  {
      return this.store.pipe(
        takeUntil(this.unsubscribe$),
        select(fromTrainingPackageReducers.selectState),
        map( (state:fromTrainingPackageReducers.State)  => state.order),
        tap( sort => this.sort = sort)
      );
    }

    private sort: string;
    private asc: boolean;
    setSort(sort: string): void {
      if(sort == this.sort) {
        this.store.dispatch(fromTrainingPackageActions.setDirection({asc: !this.asc}));
      } else {
        this.store.dispatch(fromTrainingPackageActions.setDirection({asc: false }));
      }
      this.store.dispatch(fromTrainingPackageActions.setOrder({order: sort}));
    }

    toggleDirection(): void {
      this.store.dispatch(fromTrainingPackageActions.setDirection({asc: !this.asc}));
    }

    pageChange(page): void {
      this.store.dispatch(fromTrainingPackageActions.setPage({page: page}));
    }

  }

import { Component, OnDestroy, OnInit } from '@angular/core';
import * as fromKbActions from '@/store/kb/kb.actions';
import * as fromKbReducers from '@/store/kb/kb.reducers';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { Folder } from '@/model/folder.model';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-admin-folder',
  templateUrl: './admin-folder.component.html',
  styleUrls: ['./admin-folder.component.scss']
})
export class AdminFolderComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();

  constructor(
    private store: Store<fromKbReducers.State>
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get children(): Observable<Folder[]> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromKbReducers.selectChildren));
  }

  identify(item: Folder, index: number) {
    return item.id;
  }

  delete(folder: Folder) {
    this.store.dispatch(fromKbActions.deleteFolder({folder}));
  }

}

import {
    Component,
    HostBinding,
    OnDestroy,
    OnInit,
    Renderer2
} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '@/auth/auth.service';
import { of } from 'rxjs';
import { PasswordStrengthValidator } from '@/utils/pass.strength';

@Component({
    selector: 'app-recover-password',
    templateUrl: './recover-password.component.html',
    styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'login-box';

    public recoverPasswordForm: FormGroup;
    public isAuthLoading = false;

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private router: Router
    ) {}

    checkPasswords: ValidatorFn = (ctrl: AbstractControl):  ValidationErrors | null => {
      let pass = this.recoverPasswordForm?.get('pass')?.value;
      let confirmPass = this.recoverPasswordForm?.get('repass')?.value
      return pass === confirmPass ? null : { notSame: true }
    }

    ngOnInit(): void {
        this.renderer.addClass(
            document.querySelector('app-root'),
            'login-page'
        );
        this.recoverPasswordForm = new FormGroup({
            pass: new FormControl(null, [Validators.required, PasswordStrengthValidator]),
            repass: new FormControl(null, [Validators.required, this.checkPasswords.bind(this)]),
            code: new FormControl(null, Validators.required),
            email: new FormControl(null, Validators.required)
        });
        this.activatedRoute.paramMap
        .pipe(map((pm: ParamMap) => {
          return {
            email: pm.get('email'),
            code:  pm.get('code')
          }
        })).subscribe( (data: any) => {
          this.recoverPasswordForm.patchValue(data);
        })
    }

    recoverPassword() {
        if (this.recoverPasswordForm.valid) {
          this.authService.recoverPassword(this.recoverPasswordForm.value)
          .pipe(catchError((err, caught) => {
            let msg = Object.values(err.error.error).map( x => Object.values(x).join()).join();
            this.toastr.error(msg,'Formularz zawiera błędy');
            return of(err.error);
          }))
          .subscribe((response: any) => {
            if(response?.ok) {
              this.toastr.success(this.recoverPasswordForm.value.email, `Zresetowano hasło`);
              this.router.navigate(['/login']);
            } else {
              // this.toastr.error(response?.error);
            }

          })
        } else {
            // console.log(this.recoverPasswordForm.errors);
            this.toastr.error('Formularz zawiera błędy');
        }
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'login-page'
        );
    }
}

import { PaginationDTO } from '@/model/pagination.model';
import { Post } from '@/model/post.model';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';

export interface SeenReportItem {
  id: number;
}

@Component({
  selector: 'app-news-seen-report',
  templateUrl: './news-seen-report.component.html',
  styleUrls: ['./news-seen-report.component.scss']
})
export class NewsSeenReportComponent implements OnInit {
  post: Post;
  total: number = 0;
  result: SeenReportItem[] = [];
  loading = true;
  page = 1;
  pageSize = 20;

  constructor(
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data: Data) => {
      this.post = data.instance;
      this.httpClient.get<PaginationDTO<SeenReportItem>>(`<backendhost>/v1/posts/${this.post.id}/seen-raport`, {
        observe: 'body',
        responseType: 'json'
      }).subscribe(
        (response) => {
          this.total = response.total;
          this.result = response.list;
          this.loading = false;
        }
      )
    })
  }

  get pagination(): SeenReportItem[] {
    return this.result.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
  }

  generateCSV() {
    let title = `Raport z wyświetleń ${this.post.title}`;
    let options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: ',',
      showLabels: true,
      // showTitle: true,
      title: title,
      // useBom: true,
      // noDownload: true,
      headers: ["Imię", "Nazwisko", "Email", "Wyświetlone", "Data wyświetlenia"],
      useHeader: false,
      nullToEmptyString: true,
    };
    new AngularCsv(this.result, title, options);
  }
}

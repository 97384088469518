<div class="content">
<div class="container-xl" *ngIf="offer">
  <div class="card card-widget position-relative shadow mt-3 mb-4">
    <div class="overlay" *ngIf="showOverlay">
      <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%,0);text-align: center; padding: 25px 0">
        <i class="fas fa-3x fa-spinner fa-spin"></i>
        <p>Zapisywanie...</p>
      </div>
    </div>
    <div class="ribbon-wrapper ribbon-lg" *ngIf="!offer?.seen && !isPublic">
      <div class="ribbon bg-orange text-lg">
        NOWE
      </div>
    </div>
    <div [ngStyle]="backgroundStyle()"></div>
    <div class="card-header pb-1">
      <div class="user-block">
        <img class="img-circle" [src]="offer?.createdBy | userAvatar" alt="User Image" [appUserDisactivatable]="offer?.createdBy">
        <span class="username">
          <span class="text-decoration-line-through" *ngIf="!offer?.createdBy">Użytkownik usunięty</span>
          <a [routerLink]="['/profile', offer?.createdBy?.id]" *ngIf="offer?.createdBy" [appUserDisactivatable]="offer?.createdBy">
            {{offer?.createdBy?.firstname}} {{offer?.createdBy?.lastname}}
          </a>
        </span>
        <!-- <span class="description"> {{post.createdAt | amLocale:'pl' | amTimeAgo }}</span> -->
        <span class="description"> {{offer?.createdAt | amLocale:'pl' | amCalendar }}</span>
        <span class="description">
          <a class="btn btn-link p-0 pr-1 text-sm" *ngFor="let tag of offer?.tags" [routerLink]="['/search', tag | lowercase]">#{{tag}}</a>
        </span>

      </div>
      <div class="card-tools" adminOnly>
        <a class="badge text-sm rounded-pill badge-secondary" ngbTooltip="Lista kandydatów" [routerLink]="['candidates']" checkRolePermition="offers.candidates">
          <i class="fas fa-users"></i>
          {{offer.applicationsCount}}
        </a>

        <a class="btn btn-tool" ngbTooltip="Edytuj ofertę" [routerLink]="['/offer', offer.id, 'edit']" checkRolePermition="offers.edit">
          <i class="fas fa-edit"></i>
        </a>
        <button
          checkRolePermition="offers.delete"
          type="button"
          class="btn btn-tool"
          nz-popconfirm
          nzPopconfirmTitle="Czy na pewno usunąć?"
          nzOkText="Tak"
          nzCancelText="Nie"
          nzPopconfirmPlacement="left"
          ngbTooltip="Usuń"
          (nzOnConfirm)="delete()">
          <i class="fas fa-trash"></i>
        </button>
      </div>
    </div>
    <div class="card-header pt-1" >
      <h3 *ngIf="offer?.id" class="text-primary card-title text-lg m-0">
        {{offer?.title}}
      </h3>
    </div>
    <div class="card-body" >
      <div [innerHtml]="offer.content | safeHtml"></div>
    </div>
    <div class="card-footer" >
      <h3 class="card-title">Aplikuj:</h3>
    </div>

    <form [formGroup]="form" (ngSubmit)="save()">
      <div class="card-body">
        <div class="form-group">
          <label for="firstname">Imię</label>
          <input formControlName="firstname" type="text" [appIsValid]="form.controls.firstname" class="form-control" placeholder="Imię" />
          <div class="invalid-feedback" *ngIf="form.controls.firstname.errors?.required">
            Pole jest wymagane
          </div>
        </div>
        <div class="form-group">
          <label for="lastname">Nazwisko</label>
          <input formControlName="lastname" type="text" [appIsValid]="form.controls.lastname" class="form-control" placeholder="Nazwisko" />
          <div class="invalid-feedback" *ngIf="form.controls.lastname.errors?.required">
            Pole jest wymagane
          </div>
        </div>
        <div class="form-group">
          <label for="email">E-Mail</label>
          <input formControlName="email" type="text" [appIsValid]="form.controls.email" class="form-control" placeholder="E-Mail" />
          <div class="invalid-feedback" *ngIf="form.controls.email.errors?.required">
            Pole jest wymagane
          </div>
          <div class="invalid-feedback" *ngIf="form.controls.email.errors?.email">
            Email nie jest poprawny
          </div>
        </div>
        <div class="form-group">
          <label>CV i list motywacyjny</label>
          <nz-upload [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload" [nzMultiple]="true" [nzRemove]="onFileRemove" class="d-block">
            <span class="btn btn-default"><i class="fas fa-upload fa-fw"></i> Załaduj</span>
          </nz-upload>
          <input formControlName="files" type="number"  [appIsValid]="form.controls.files" [value]="fileList.length" class="d-none"/>
          <div class="invalid-feedback" *ngIf="form.controls.files.errors?.required">
            Pole jest wymagane
          </div>
          <div class="invalid-feedback" *ngIf="form.controls.files.errors?.min">
            Pole jest wymagane
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary" type="submit" (mouseDown)="save()" (touchEnd)="save()">
          Aplikuj
          <i class="fas fa-angle-right fa-fw"></i>
        </button>
        <a class="btn btn-default ml-3" *ngIf="!isPublic" (click)="reffer()">
          Poleć
          <i class="fas fa-paper-plane fa-fw"></i>
        </a>
      </div>
    </form>

    <div class="card-footer card-footer-buttons" *ngIf="!isPublic">
      <a class="text-sm float-left" (click)="like()" [ngClass]="{'link-black': !offer?.liked, 'link-primary  text-bold': offer?.liked}">

        <span class="badge text-sm rounded-pill" [ngClass]="{'badge-secondary': !offer?.liked, 'badge-primary': offer?.liked}">
          <i class="fa-thumbs-up" [ngClass]="{'far': !offer?.liked, 'fas': offer?.liked}" *ngIf="!offer?.likeSaving"></i>
          <i class="fa-spinner fa-spin fas" *ngIf="offer?.likeSaving"></i>
          {{offer?.likeCount ? offer.likeCount : 0}}
        </span>
        <span class="d-none d-sm-inline"> Lubię to</span>
      </a>
      <span class="pl-3 text-black text-sm float-left" (click)="seen()">
        <span class="badge text-sm rounded-pill" [ngClass]="{'badge-secondary': !offer?.seen, 'badge-primary': offer?.seen}">
          <i class="far fa-eye"></i>
          {{ offer?.seenCount }}
        </span>
        <span class="d-none d-sm-inline"> wyświetleń</span>
      </span>
      <a class="pl-3 text-black text-sm float-left" (click)="share()">
        <span class="badge text-sm rounded-pill">
          <i class="fas fa-share"></i>
        </span>
        <span class="d-none d-sm-inline"> udostępnij</span>
      </a>
    </div>
  </div>
</div>
</div>


<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Przytnij obrazek</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
<image-cropper
  [ngClass]="{'crop-round' : round}"
  [imageBase64]="preview"
  [maintainAspectRatio]="true"
  [aspectRatio]="aspectRatio"
  (imageCropped)="imageCropped($event)"
  format="png"
></image-cropper>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="save()">Zapisz</button>
</div>
<!--
<image-cropper
  [imageBase64]="preview"
  [maintainAspectRatio]="true"
  [aspectRatio]="1 / 1"
  format="png"
  (imageCropped)="imageCropped($event)"
  (imageLoaded)="imageLoaded($event)"
  (cropperReady)="cropperReady()"
  (loadImageFailed)="loadImageFailed()"
></image-cropper> -->

import { Survey } from '@/model/survey.model';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject, fromEvent, Observable } from 'rxjs';
import { take, takeUntil, map, filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

import * as fromActions from "@/store/call-center/call-center.actions";
import * as fromReducers from "@/store/call-center/call-center.reducers";
import * as fromRolesReducers from '@/store/roles/roles.reducers';
import { CallCenterFill } from '@/model/call-center.model';
import { AuthService } from '@/auth/auth.service';
import { User } from '@/model/user.model';
import { AllOrCreated, Role } from '@/model/role.model';

@Component({
  selector: 'app-list-call-center-fill',
  templateUrl: './list-call-center-fill.component.html',
  styleUrls: ['./list-call-center-fill.component.scss']
})
export class ListCallCenterFillComponent implements OnInit {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  searchTerm: string;
  page = 1;
  pageSize = 20;
  role: Role;
  constructor(
    protected store: Store<fromReducers.State>,
    protected router: Router,
    protected actionsSubject: ActionsSubject,
    protected toastr: ToastrService,
    protected authService: AuthService,
    protected rolesStore: Store<fromRolesReducers.State>,
    protected activatedRoute: ActivatedRoute,
  ) {
    authService.getUser().then( (user: User) => {
      this.rolesStore
        .pipe(select(fromRolesReducers.selectRole, user.role))
        .subscribe((role: Role) => {
          this.role = role;
        })
    })
  }

  ngOnInit(): void {
    this.store.dispatch(fromActions.clear());
    this.activatedRoute.data.subscribe((data: Data) => {

      this.store.dispatch(fromActions.setType({fillType: data.fillType}));
      this.store.dispatch(fromActions.fetch());
    })
    this.store.pipe(
      take(1),
      takeUntil(this.unsubscribe$),
      select(fromReducers.selectState),
      map(state => state.search)
    ).subscribe((search:string) => {
      this.searchInput.nativeElement.value = search
    });
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      takeUntil(this.unsubscribe$),
      map((event: any) => event.target.value),
      filter( (res:string) => res.length > 2),
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((text: string) => {
      this.store.dispatch(fromActions.setSearch({search: text.toLowerCase().trim()}));
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  clearSearch() {
    this.searchInput.nativeElement.value = '';
    this.store.dispatch(fromActions.setSearch({search: null}));
  }

  get list(): Observable<CallCenterFill[]> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectAllFills));
  }

  delete(item: CallCenterFill): void {
    this.store.dispatch(fromActions.deleteQuestionnaireFill({id: item.id}));
  }

  identify(index, entity: CallCenterFill){ return entity.id }

  get total(): Observable<number>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromReducers.selectState),
      map( (state:fromReducers.State)  => state.total)
    );
  }

  get direction(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromReducers.selectState),
      map( (state:fromReducers.State)  => state.asc),
      tap( asc => this.asc = asc)
    );
  }

  get loading(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromReducers.selectState),
      map( (state:fromReducers.State)  => state.loading),
    );
  }
  get saving(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromReducers.selectState),
      map( (state:fromReducers.State)  => state.saving),
    );
  }

  get order(): Observable<string>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromReducers.selectState),
      map( (state:fromReducers.State)  => state.order),
      tap( sort => this.sort = sort)
    );
  }

  private sort: string;
  private asc: boolean;
  setSort(sort: string): void {
    if(sort == this.sort) {
      this.store.dispatch(fromActions.setDirection({asc: !this.asc}));
    } else {
      this.store.dispatch(fromActions.setDirection({asc: false }));
    }
    this.store.dispatch(fromActions.setOrder({order: sort}));
  }

  toggleDirection(): void {
    this.store.dispatch(fromActions.setDirection({asc: !this.asc}));
  }

  pageChange(page): void {
    this.store.dispatch(fromActions.setPage({page: page}));
  }

  public AllOrCreated = AllOrCreated;
  checkViewPermition(scope: AllOrCreated[]): any {
    return scope.indexOf(this.role.privileges.callcenter.view) >= 0;
  }
}

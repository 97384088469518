import { Invitation, TrainingPackage, TrainingResult } from '@/model/package.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import * as fromMyTrainingsAction from '@/store/my-training/my-training.actions';
import * as fromMyTrainingsReducers from '@/store/my-training/my-training.reducers';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { ofType } from '@ngrx/effects';
import { TrainingComponent } from '@pages/training/training.component';
import { Training } from '@/model/scorm.model';
import { environment } from 'environments/environment';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-my-training-start',
  templateUrl: './my-training-start.component.html',
  styleUrls: ['./my-training-start.component.scss']
})
export class MyTrainingStartComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();

  invitation: Invitation
  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<fromMyTrainingsReducers.State>,
    private router: Router,
    private actionsSubject$: ActionsSubject,
  ) {

  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data: Data) => {
      this.invitation = data.invitation;
    })
    this.store.pipe(
      select(fromMyTrainingsReducers.selectOneMyTraining, this.invitation.id),
      takeUntil(this.unsubscribe$),
    ).subscribe( (invitation) => {
      this.invitation = invitation;
    })
  }

  start() {
    this.store.dispatch(fromMyTrainingsAction.start({id: this.invitation.id}));
    this.actionsSubject$.pipe(ofType(fromMyTrainingsAction.update)).subscribe( (action) => {
      this.invitation = action.invitation;
      // this.router.navigate(['training', this.invitation.id ,'initial']);
    })
  }
  identify(index, item) {
    return item.id;
  }

  result(training: Training): TrainingResult {
    return Object.values(this.invitation.results.entities).filter((item) => item.training?.id == training.id && item.slide == null).pop();
  }

  backgroundStyle () {
    if(!this.invitation.package)
      return null;
    if(!this.invitation.package.background)
      return {
        'aspect-ratio': '4',
        'background' : `#ed1a3b`,
        'background-size': 'cover'
      }
    return {
      'aspect-ratio': '16/9',
      'background' : `url(${environment.apiUrl}/thumb/1920x0/${this.invitation.package.background.replace('public','')}) center center`,
      'background-size': 'cover'
    }
  }

  reset(training: Training) {
    let invitation = this.invitation;
    this.store.dispatch(fromMyTrainingsAction.resetTraining({training, invitation}));
  }

  resetHard() {
    let invitation = this.invitation;
    this.store.dispatch(fromMyTrainingsAction.resetHard({invitation}));
  }
}

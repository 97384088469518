
import { Outpost } from '@/model/outpost.model';
import { PaginationDTO } from '@/model/pagination.model';
import { Slot } from '@/model/slot.model';
import { Vacant } from '@/model/vacant.model';
import { StringifyHttpErrorResponse } from '@/utils/http.error.util';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import * as fromActions from './vacant.actions';
import * as fromReducers from './vacant.reducers';

@Injectable()
export class VacantEffects {
  state: fromReducers.State;

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private store$: Store<fromReducers.State>,
  ) {
    this.store$.pipe(select(fromReducers.selectState)).subscribe((state: fromReducers.State) => {
      this.state = state;
    })
  }

  onError (err, caught): any {
    this.toastr.error(StringifyHttpErrorResponse(err));
    return caught;
  };

  paginatorChange$ = createEffect(() => this.actions$.pipe(
    ofType(...[
      fromActions.setPage,
      fromActions.setSearch,
      fromActions.setOrder,
      fromActions.setDirection
    ]),
    map( () => fromActions.fetch())
  ));

  fetch$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetch),
    switchMap(() => {
      let params = new HttpParams();
      if(this.state.type)
        params = params.set('type', this.state.type);
      if(this.state.user)
        params = params.set('user', this.state.user?.id);



      params = params.set('skip', this.state.limit * (this.state.page - 1));
      params = params.set('limit', this.state.limit);
      if(this.state.order)
        params = params.set('order', this.state.order);
      params = params.set('direction', this.state.asc ? 'asc' : 'desc');

      if(this.state.slot) {
        params = params.set('slot', this.state.slot.id);
      }

      if(this.state.filter) {
        console.log(this.state.filter);
        if(this.state.filter.type) {
          params = params.set('filter_type', this.state.filter.type);
        }
        if(this.state.filter.outpost) {
          params = params.set('filter_outpost', this.state.filter.outpost);
        }
        if(this.state.filter.date) {
          params = params.set('filter_date_start', this.state.filter.date.start);
          params = params.set('filter_date_end'  , this.state.filter.date.end);
        }
        if(this.state.filter.reservedBy) {
          params = params.set('filter_reservedBy', this.state.filter.reservedBy);
        }
      }
      if(this.state.search)
        params = params.set('search', this.state.search);

      return this.httpClient.get<PaginationDTO<Outpost>>(`<backendhost>/v1/vacant`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: PaginationDTO<Vacant>) => [fromActions.setMany(response)])
  ));

  fetchOne$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchOne),
    switchMap((action) => {
      return this.httpClient.get<Slot>(`<backendhost>/v1/vacant/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((vacant: Vacant) => [fromActions.addOne({vacant})])
  ));

  createVacant$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createVacant),
    switchMap((action) => {
      return this.httpClient.post<PaginationDTO<Vacant>>(`<backendhost>/v1/vacant`, action.data, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: PaginationDTO<Vacant>) => [fromActions.addMany(response)])
  ));

  reserveVacant$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.reserveVacant),
    switchMap((action) => {
      return this.httpClient.post<any>(`<backendhost>/v1/vacant/${action.vacant.id}/reserve`, {}, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    tap(() => {
      this.toastr.success("Zarezerwowano");
    }),
    switchMap(() => [
      fromActions.reserveSuccess(),
      fromActions.fetch()
    ])
  ));

  unreserveVacant$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.unreserveVacant),
    switchMap((action) => {
      return this.httpClient.post<any>(`<backendhost>/v1/vacant/${action.vacant.id}/unreserve`, {}, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    tap(() => {
      this.toastr.success("Usunięto rezerwacje");
    }),
    switchMap(() => [
      fromActions.reserveSuccess(),
      fromActions.fetch()
    ])
  ));

  deleteVacant$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deleteVacant),
    switchMap((action) => {
      return this.httpClient.delete<any>(`<backendhost>/v1/vacant/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map(() => action.id))
    }),
    catchError(this.onError.bind(this)),
    tap(() => {
      this.toastr.success("Usunięto ogłoszenie");
    }),
    switchMap((id) => [
      fromActions.removeOne({id})
    ])
  ));

  repeatNotification$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.repeatNotification),
    switchMap((action) => {
      return this.httpClient.post<any>(`<backendhost>/v1/vacant/${action.id}/repeat-notification`, {}, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    tap(() => this.toastr.success('Wysłano powiadomienia!')),
    switchMap(() => [])
  ));
}

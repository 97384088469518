import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as fromTrainingPackageiaActions from '@/store/package/package.actions';
import * as fromTrainingPackageReducers from '@/store/package/package.reducers';
import { Invitation, TrainingInvitePack, TrainingPackage } from '@/model/package.model';
import { fromEvent, Observable, of, Subject } from 'rxjs';
import { PaginationDTO } from '@/model/pagination.model';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { AppService } from '@services/app.service';

@Component({
  selector: 'app-package-pack-list-of-invited',
  templateUrl: './package-pack-list-of-invited.component.html',
  styleUrls: ['./package-pack-list-of-invited.component.scss']
})
export class PackagePackListOfInvitedComponent implements  OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  @Input() package: TrainingPackage;
  @Input() invitePack: TrainingInvitePack;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  searchTerm: string;
  page = 1;
  pageSize = 20;
  list: PaginationDTO<Invitation> = {
    total: 0,
    list: [],
  };

  constructor(
    private store: Store<fromTrainingPackageReducers.State>
  ) {}

  ngOnInit(): void {

    this.store.dispatch(fromTrainingPackageiaActions.fetchInvited({
      package: this.package,
      invitePack: this.invitePack,
      page: 1
    }));
    this.store.pipe(
      select(fromTrainingPackageReducers.selectOneTrainingPackage, this.package.id),
      takeUntil(this.unsubscribe$),
      distinctUntilChanged()
    ).subscribe((item) => {
      this.package = item;
    });
    this.store.pipe(
      select(fromTrainingPackageReducers.selectTrainingInvited, this.package.id),
      takeUntil(this.unsubscribe$),
      distinctUntilChanged()
    ).subscribe((list) => {
      if(list.list.length)
        this.list = list;
    });

    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      takeUntil(this.unsubscribe$),
      map((event: any) => event.target.value),
      filter( (res:string) => res.length > 2),
      debounceTime(1000),
      distinctUntilChanged())
        .subscribe((text: string) => {
          this.searchTerm = text.toLowerCase().trim();
          this.store.dispatch(fromTrainingPackageiaActions.fetchInvited({
              package: this.package,
              invitePack: this.invitePack,
              page: this.page,
              search: this.searchTerm
            }));
        });
  }

  identify(index, entity: any){ return entity.id }

  get pagination(): Observable<PaginationDTO<Invitation>> {
    return of(this.list);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  clearSearch() {
    this.searchInput.nativeElement.value = '';
  }


  pageChange(page): void {
    this.page = page;
    this.store.dispatch(fromTrainingPackageiaActions.fetchInvited({
      package: this.package,
      invitePack: this.invitePack,
      page: this.page,
      search: this.searchTerm
    }));
  }

  delete(item: (Invitation) ) {
    this.store.dispatch(fromTrainingPackageiaActions.deleteInvited({
      invited: item,
      invitePack: this.invitePack,
      package: this.package
    }));
  }
}

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as fromChatActions from '@/store/chat/chat.actions';
import * as fromChatReducers from '@/store/chat/chat.reducers';
import * as fromUsersActions from '@/store/users/users.actions';
import * as fromUsersReducers from '@/store/users/users.reducers';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AuthService } from '@/auth/auth.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { User } from '@/model/user.model';
import { ChatMessage, Conversation } from '@/model/chat.model';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { ChatService } from '@services/chat.service';
import { KbFile } from '@/model/file.model';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { EditorComponent } from '@components/editor/editor.component';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { UploadFilter } from 'ng-zorro-antd/upload';
import { ToastrService } from 'ngx-toastr';

const MAX_UPLOAD_SIZE = 10 * 1024 * 1024;
@Component({
  selector: 'app-view-conversation',
  templateUrl: './view-conversation.component.html',
  styleUrls: ['./view-conversation.component.scss']
})
export class ViewConversationComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @ViewChild('ckEditor') private ckEditor: EditorComponent;
  @ViewChild('uploadBtn') private uploadBtn: ElementRef;
  @ViewChild('emojiPopoverBtn') private emojiPopoverBtn: NgbPopover;

  replyTo: ChatMessage;

  uploadFilter: UploadFilter[] = [{name: "size", fn: (fileList) => {
    return fileList.filter((file) => {
      if (file.size > MAX_UPLOAD_SIZE) {
        this.toastr.error("Plik za duży. Maksymalny rozmiar pliku 10MB");
        return false;
      }
      return true;
    });
  }}]

  emojiI18n = {
    search: 'Szukaj',
    emojilist: 'Lista emoji',
    notfound: 'Nie znaleziono emotikonów',
    clear: 'Wyczyść',
    categories: {
      search: 'Wyniki Wyszukiwania',
      recent: 'Często używane',
      people: 'Buźki i ludzie',
      nature: 'Zwierzęta i przyroda',
      foods: 'Żywność i napoje',
      activity: 'Aktywność',
      places: 'Podróże i miejsca',
      objects: 'Przedmioty',
      symbols: 'Symbole',
      flags: 'Flagi',
      custom: 'Niestandardowy',
    },
    skintones: {
      0: "Domyślny odcień skóry",
      1: "Jasna karnacja",
      2: "Średnio jasny odcień skóry",
      3: "Średni odcień skóry",
      4: "Średnio ciemna karnacja",
      5: "Ciemna karnacja",
    },
  };

  openDialog = false;
  fileList = [];
  // files: KbFile[] = [];
  disabled = false;
  message: ChatMessage;
  id: number;
  user: User;
  to: number;
  lastMessage: number;
  lastSeenMessage: number;
  constructor(
    private store: Store<fromChatReducers.State>,
    private usersStore: Store<fromUsersReducers.State>,
    private authService: AuthService,
    private router: Router,
    private actionsSubject$: ActionsSubject,
    private chatService: ChatService,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private toastr: ToastrService
  ) {
    // this.insertEmoji$ = new BehaviorSubject<string>();
    this.authService.getUser().then(user => {this.user = user;});
    this.message = {
      id: null,
      content: null,
      createdAt: null,
      createdBy: null,
      conversation: null,
      files: [],
    }
  }

  get conversation(): Observable<Conversation> {
    if(!this?.id && !this.to) return of(null);
    if(!this?.id) {
      return this.usersStore.pipe(
        takeUntil(this.unsubscribe$),
        select(fromUsersReducers.selectOneUser, this.to),
        filter(user => user != null),
        take(1),
        map( (user: User) => {
          if(!user) return null;
          return {
            id: null,
            name: `${user.firstname} ${user.lastname}`,
            type: 'direct',
            membersFull: [user],
            image: user.avatar,
            unseenCount: 0,
            lastMessage: null,
            messages: null,
            // loaded: true
          }
        }),
      )
    }
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromChatReducers.selectConversation, this.id),
    )
  }

  identify(msg: ChatMessage, index: number) {
    return msg.id ? msg.id : msg.tempId;
  }

  get canEdit(): Observable<boolean> {
    return this.conversation.pipe(
      takeUntil(this.unsubscribe$),
      map( (c:Conversation) => {
        return c ? this.user && c.type === 'room' && (c?.createdBy?.id === this.user.id || this.user.role === 'admin') : false;
      })
    );
  }

  get newestMessage(): Observable<ChatMessage> {
    return this.messages.pipe(
      map( (arr:ChatMessage[]) => arr && arr.length ? arr.filter(msg => msg.id).pop() : null)
    );
  }

  get messages(): Observable<ChatMessage[]> {
    if(!this.conversation) return of([]);
    return this.conversation.pipe(
      takeUntil(this.unsubscribe$),
      map(conv => {
        return conv && conv.messages ? Object.values(conv.messages.entities) : []
      }),
      distinctUntilChanged( (x: ChatMessage[], y: ChatMessage[]) => x.length == y.length)// && x[x.length-1].id == y[y.length-1].id)
    );
  }

  onSubmit(formModel: NgForm): void {
    if(!formModel.value.content && !this.files.length) return;
    if (this.id) {
      this.store.dispatch(fromChatActions.createNewMessage({
        content: formModel.form.value.content,
        conversation: this.id,
        files: this.files,
        replyTo: this.replyTo
      }));
    }
    else if (this.to && !this.disabled) {
      this.disabled = true;
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        filter((action) => action.type === fromChatActions.addOneConvertsationWithMessage.type),
      ).subscribe( (action:any) => {
        this.router.navigate(['/chat', action.msg.conversation.id ])
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
      });

      this.store.dispatch(fromChatActions.createNewMessageAndConverrsation({
        content: formModel.form.value.content,
        to: this.to,
        files: this.files
      }));
    }
    formModel.reset();
    this.reply(null);
    this.fileList = [];
  }

  scrollToBottom(): void {
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight + 1000;
    } catch(err) { }
  }

  delete() {
    this.conversation.subscribe( (conversation:Conversation) => {
      this.store.dispatch(fromChatActions.deleteRoom({conversation}));
      this.router.navigateByUrl("/chat");
    })
  }


  ngOnInit(): void {
    this.router.navigate([{outlets: { sidebar: 'chat'}}]);

    this.activatedRoute
      .paramMap
      .pipe(map((pm: ParamMap) => {
        return {
          conversation: +pm.get('id'),
          user:  +pm.get('user')
        }
      }))
      .subscribe( (map: any) => {
        if(map.conversation) {
          this.id = map.conversation;
          this.store.dispatch(fromChatActions.fetchMessages({conversation : this.id}));
        }
        else {
          this.to = map.user;
          this.chatService.getCoversationWithUser(this.to).subscribe( (id:number) => {
            if (id) this.router.navigate(['/chat', id ]);
            else this.usersStore.dispatch(fromUsersActions.fetchOneUser({id: this.to}))
          })
        }

        console.log("HELLO");
        this.newestMessage
          .pipe(
            takeUntil(this.unsubscribe$),
            filter(msg => msg != null),
            distinctUntilChanged( (x:ChatMessage, y: ChatMessage) => {
              let xId = x.id ? x.id : x.tempId;
              let yId = y.id ? y.id : y.tempId;
              return xId == yId
            }),
            // tap(console.log),
          ).subscribe( (msg: ChatMessage) => {

            this.chatService.setAsSeen(msg.id, this.id);
            this.lastSeenMessage = msg.id;
            setTimeout(() => {this.scrollToBottom()}, 100);
          })
      })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleChange(event) {
    // if (event.type == "success") {
    //   this.files = [...this.files, ...[event.file.response]];
    // }
    // console.log(event.type);
  }

  get files() {
    return this.fileList.map(file => file.response).filter(file => file?.id)
  }

  paperclipClick() {
    this.uploadBtn.nativeElement.click();
  }

  fileSrc(file: KbFile): string {
    return `${environment.apiUrl}/${file.path}`;
  }
  fileSrcThumb(file: KbFile): string {
    return `${environment.apiUrl}/thumb/300x0/${file.path}`;
  }
  fileSrcBig(file: KbFile): string {
    return `${environment.apiUrl}/thumb/1920x0/${file.path}`;
  }

  isImage(file: KbFile): boolean {
    return file.mimeType.indexOf("image/") == 0;
  }
  isVideo(file: KbFile): boolean {
    return file.mimeType.indexOf("video/") == 0;
  }
  isFile(file: KbFile): boolean {
    return !this.isImage(file) && !this.isVideo(file);
  }

  download(file: KbFile): void {
    this.httpClient
      .get(`${environment.apiUrl}/v1/kb/file/${file.id}/download`, {responseType: 'blob'})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          let blob:any = new Blob([response], { type: file.mimeType });
          // saveAs(blob, file.name);

          const url = window.URL.createObjectURL(blob);
          var anchor = document.createElement('a');
        	anchor.href = url;
        	anchor.download = file.name;
        	document.body.appendChild(anchor);
        	anchor.click();
        },
        error => console.log('Error downloading the file'),
        () => console.info('File downloaded successfully')
      );
  }

  addEmoji(event) {
    console.log("OK", this.emojiPopoverBtn);
    // let hex = "😀".codePointAt(0).toString(16);
    // let emo = String.fromCodePoint("0x"+hex);
    // this.ckEditor.insertText(`&#${event.emoji.unified.toString(10)};`);
    this.ckEditor.insertText(event.emoji.native);
    this.emojiPopoverBtn.close();
  }

  emojisToShowFilter = ((emoji) => {
    let code = emoji.split("-")[0];
    code = parseInt(code, 16);
    if( code >= 0x1F601 && code <= 0x1F64F)
      return true;
    if( code >= 0x2702 && code <= 0x27B0)
      return true;
    if( code >= 0x1F680 && code <= 0x1F6C0)
      return true;
    // if( code >= 0x1F170 && code <= 0x1F251)
    //   return true;
      // 0x24C2
    return false;
  })

  reply(msg: ChatMessage) {
    this.replyTo = msg;
  }
}

import { Offer } from '@/model/offer.model';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-offer-reffer',
  templateUrl: './offer-reffer.component.html',
  styleUrls: ['./offer-reffer.component.scss']
})
export class OfferRefferComponent implements OnInit {
  offer: Offer;
  form: FormGroup;
  constructor(
    public modal: NgbActiveModal,
    private httpClient: HttpClient,
    private toaster: ToastrService,
  ) {

    this.form = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      reffererName: new FormControl(null, [Validators.required]),
      reffererEmail: new FormControl(null, [Validators.required, Validators.email]),
      url: new FormControl(null, [Validators.required]),
    });
  }


  ngOnInit(): void {
  }

  send () {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    if (this.form.valid && this.form.enabled) {
      this.modal.close();
      this.httpClient
        .post<any>(`<backendhost>/v1/offers/${this.offer.id}/reffer`, this.form.value, { observe: 'body', responseType: 'json'})
        .subscribe(() => {
          this.toaster.success('Wysłano rekomendacje');
        })
      } else if (this.form.enabled){
        this.toaster.error('Formularz jest nieprawidłowy!');
      } else {
      }
  }

}

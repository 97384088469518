import { CallCenterFill } from "@/model/call-center.model";
import { PaginationDTO } from "@/model/pagination.model";
import { createAction, props } from "@ngrx/store";


export const clear = createAction("call-center clear");
export const fetch = createAction("call-center fetch");
export const fetchOne = createAction("call-center fetchOne", props<{id: number}>());

export const setPage = createAction("call-center setPage", props<{ page: number }>());
export const setOrder = createAction("call-center setOrder", props<{ order: string }>());
export const setDirection = createAction("call-center setDirection", props<{ asc: boolean }>());
export const setSearch = createAction("call-center setSearch", props<{ search: string }>());
export const setType = createAction("call-center setType", props<{ fillType: string }>());

export const createQuestionnaireFill = createAction("call-center createQuestionnaireFill", props<{item: CallCenterFill}>());
export const createQuestionnaireFillSuccess = createAction("call-center createQuestionnaireFillSuccess", props<{item: CallCenterFill}>());
export const updateQuestionnaireFill = createAction("call-center updateQuestionnaireFill", props<{item: CallCenterFill}>());
export const sendFillNotify = createAction("call-center sendFillNotify", props<{item: CallCenterFill}>());
export const deleteQuestionnaireFill = createAction("call-center deelteQuestionnaireFill", props<{id: number}>());

export const setMany = createAction("call-center setMany", props<PaginationDTO<CallCenterFill>>());
export const addMany = createAction("call-center addMany", props<PaginationDTO<CallCenterFill>>());
export const addOne = createAction("call-center addOne", props<{item: CallCenterFill}>());
export const setOne = createAction("call-center setOne", props<{item: CallCenterFill}>());
export const removeOne = createAction("call-center removeOne", props<{id: number}>());

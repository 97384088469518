<div class="content">
  <div class="container pt-3 pb-3">
    <app-group-card [group]="group" *ngIf="group"></app-group-card>
    <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(posts | async).length == 0"></nz-empty>
    <div class="search-results timeline"
          infinite-scroll
          [infiniteScrollDistance]="scrollDistance"
          [infiniteScrollUpDistance]="scrollUpDistance"
          [infiniteScrollThrottle]="throttle"
          [immediateCheck]="true"
          (scrolled)="onScroll()"
          (scrolledUp)="onScrollUp()">

      <!-- <div *ngFor="let post of (posts | async); let idx = index; trackBy: identify">
        <post-box (inView)="onPostView(post)" [post]="post"></post-box>
      </div> -->
      <ng-template ngFor let-post [ngForOf]="posts | async" let-idx="index" [ngForTrackBy]="identify">
        <div class="time-label" *ngIf="before(post, idx) | async">
          <span class="bg-primary">
            <i class="far fa-calendar fa-fw"></i>
            {{post.createdAt | amDateFormat: 'DD.MM.YYYY' }}
          </span>
        </div>

        <div>
          <!-- <i class="fas fa-bullhorn bg-orange"></i> -->
          <post-box [post]="post" class="timeline-item d-block"></post-box>
        </div>
      </ng-template>
      <button
        type="button"
        class="btn btn-block btn-outline-primary btn-block"
        *ngIf="(hasMore | async)"
        (click)="loadMore()"
        [ngClass]="{'disabled' : (loading | async)}">
        <i class="fas" [ngClass]="{'fa-spinner fa-spin' : (loading | async), 'fa-sync' : !(loading | async)}"></i>
        załaduj więcej
      </button>
       <!-- <pre>{{ state | async | json}}</pre> -->
    </div>
  </div>
</div>

import { createPaginationInitialState, Pagination } from "@/model/pagination.model";
import { Multimedia } from "@/model/multimedia.model";
import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import * as actions from "./multimedia.actions";

export interface State extends Pagination<Multimedia> {}

export const adapter: EntityAdapter<Multimedia> = createEntityAdapter<Multimedia>();

export const initialState: State = createPaginationInitialState(adapter, false);

export const reducer = createReducer(
  initialState,
  on(actions.setMultimedias, (state, action) => {
    return {
      ...state,
      ...{
        total: action.total,
        loading: false,
        list: adapter.addMany(action.list, state.list)
      }
    }
  }),
  on(actions.addMultimedia, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.addOne(action.item, state.list)
      }
    }
  }),
  on(actions.updateMultimedia, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.updateOne({id : action.item.id, changes: action.item}, state.list)
      }
    }
  }),
  on(actions.deleteMultimedia, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.removeOne(action.id, state.list)
      }
    }
  }),

  on(actions.fetch, (state, action) => { return {...state, ...{loading: true}}}),
  on(actions.setPage, (state, action) => { return {...state, ...{page: action.page}}}),
  on(actions.setOrder, (state, action) => { return {...state, ...{order: action.order}}}),
  on(actions.setDirection, (state, action) => { return {...state, ...{asc: action.asc}}}),
  on(actions.setSearch, (state, action) => { return {...state, ...{search: action.search}}}),
);



export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectState = createFeatureSelector<State>('multimedia');
export const selectAllMultimedia = createSelector(selectState, (state) => selectAll(state.list) )
export const selectOneMultimedia = createSelector(selectState, (state: State, id) => state.list.entities[id]);

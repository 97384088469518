<section class="content pt-3 pb-3">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3">
        <a [routerLink]="['/messages', 'create']" class="btn btn-primary btn-block mb-3" >Nowa wiadomość</a>
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Foldery</h3>
            <div class="card-tools">
            </div>
          </div>
          <div class="card-body p-0">
            <ul class="nav nav-pills flex-column">
              <li class="nav-item active">
                <a [routerLink]="['/messages', 'inbox']" class="nav-link" routerLinkActive="active">
                  <i class="fas fa-inbox"></i> Odbiorcza
                  <!-- <span class="badge bg-primary float-right">12</span> -->
                </a>
              </li>
              <li class="nav-item">
                <a [routerLink]="['/messages', 'sent']" class="nav-link" routerLinkActive="active">
                  <i class="far fa-envelope"></i> Wysłane
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-md-9">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</section>

import { Multimedia, Slide } from '@/model/multimedia.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SlideService {

  constructor(private http: HttpClient) {}


  save(slide: Slide, multimedia: Multimedia, video: any, audio: any) {
    const formData = new FormData();
    if(video) {
      formData.append('video', video, video.name);
    }
    if(audio) {
      formData.append('audio', audio, audio.name);
    }
    formData.append('data', JSON.stringify(slide));
    return this.http.post<Slide>(`<backendhost>/v1/multimedia/${multimedia.id}/slide`, formData, {observe: 'body',responseType: 'json'});
  }

  delete(slide: Slide) {
    return this.http.delete<Slide>(`<backendhost>/v1/multimedia/${slide.id}/delete-slide`, {observe: 'body',responseType: 'json'}).subscribe();
  }
}

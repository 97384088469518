import { ReservationAsset, ReservationCategory } from '@/model/reservation.model';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subject } from 'rxjs';
import * as fromActions from '@/store/reservations/reservations.actions';
import * as fromReducers from '@/store/reservations/reservations.reducers';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'app-assets-reservation-category',
  templateUrl: './assets-reservation-category.component.html',
  styleUrls: ['./assets-reservation-category.component.scss']
})
export class AssetsReservationCategoryComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  reservationCategory: ReservationCategory;

  constructor(
    private toastr: ToastrService,
    private store: Store<fromReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.pipe(
      map((params: ParamMap) => parseInt(params.get('category')))
    ).subscribe( (id: number) => {
      this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectOneAdminReservationCategory,id)).subscribe((result) => {
        if(!result) {
          this.store.dispatch(fromActions.fetchOneReservationCategory({id}));
        }
        if (!result)return;
        this.reservationCategory = result;
      });
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get list(): Observable<ReservationAsset[]> {
    return this.reservationCategory ? this.store.pipe(select(fromReducers.selectAdminCategoryAssets,this.reservationCategory.id)) : of([]);
  }

  identify(index, item: ReservationAsset): number { return item.id;}

  delete(reservationAsset: ReservationAsset) {
    this.store.dispatch(fromActions.deleteAdminReservationAsset({reservationAsset}));
  }

}

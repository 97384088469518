import { Component, forwardRef, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import ClassicEditor from 'ckeditor5/packages/ckeditor5-build-classic/build/ckeditor';
// import {Enter} from 'ckeditor5/packages/ckeditor5-enter';
// import BalloonEditor from 'ckeditor5/packages/ckeditor5-build-balloon/build/ckeditor';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GroupAvatarPipe } from '@/pipes/group-avatar.pipe';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { environment } from 'environments/environment';
import { AuthService } from '@/auth/auth.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';

let itemToUrl = (item) => {
  switch(item.type) {
    case 'post': return `/news/${item.objId}`;
    case 'article': return `/news/${item.objId}`;
    case 'user': return 'Użytkownik';
    case 'offer': return 'Oferta pracy';
    case 'file': return 'Plik';
    case 'faq': return 'FAQ';
  }
}
export function MentionCustomization( editor ) {
  // The upcast converter will convert view <a class="mention" href="" data-user-id="">
    // elements to the model 'mention' text attribute.
    editor.conversion.for( 'upcast' ).elementToAttribute( {
      view: {
          name: 'a',
          key: 'data-mention',
          classes: 'mention',
          attributes: {
              href: true,
              'data-user-id': true
          }
      },
      model: {
          key: 'mention',
          value: viewItem => {
              // The mention feature expects that the mention attribute value
              // in the model is a plain object with a set of additional attributes.
              // In order to create a proper object use the toMentionAttribute() helper method:
              const mentionAttribute = editor.plugins.get( 'Mention' ).toMentionAttribute( viewItem, {
                  // Add any other properties that you need.
                  link: viewItem.getAttribute( 'href' ),
                  userId: viewItem.getAttribute( 'data-user-id' )
              } );

              return mentionAttribute;
          }
      },
      converterPriority: 'high'
  } );

  // Downcast the model 'mention' text attribute to a view <a> element.
  editor.conversion.for( 'downcast' ).attributeToElement( {
      model: 'mention',
      view: ( modelAttributeValue, { writer } ) => {
          // Do not convert empty attributes (lack of value means no mention).
          if ( !modelAttributeValue ) {
              return;
          }

          return writer.createAttributeElement( 'a', {
              class: 'mention',
              'data-mention': modelAttributeValue.id,
              'data-obj-id': modelAttributeValue.objId,
              'app-mention-tag':true,
              'data-obj-type': modelAttributeValue.type,
              href: modelAttributeValue.url
          }, {
              // Make mention attribute to be wrapped by other attribute elements.
              priority: 20,
              // Prevent merging mentions together.
              id: modelAttributeValue.uid
          } );
      },
      converterPriority: 'high'
  } );
}
@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorComponent),
      multi: true
    }
  ]
})
export class EditorComponent implements OnInit, OnDestroy, ControlValueAccessor,  OnChanges {
  private readonly unsubscribe$: Subject<void> = new Subject();
  @ViewChild( 'editor' ) editorComponent: CKEditorComponent;
  @Input() content: string;
  @Input() baloon: boolean = false;
  Editor = ClassicEditor;

  defConfig = {
    height: 500 ,
    language: 'pl',
    extraPlugins : [ MentionCustomization ],
    // removePlugins : [ 'Enter' ],
    toolbar: [
      'bold','italic','underline','strikethrough','|',
      'heading','fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor', '|',
      'alignment', '|',
      'bulletedList', 'numberedList', 'todoList', '|',
      'outdent', 'indent', '|',
			'link', 'uploadImage', 'blockQuote', 'insertTable', '|', 'mediaEmbed' ,'|',
      'sourceediting',
    ],
    mediaEmbed: {
      previewsInData: true
    },
    simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: `${environment.apiUrl}/v1/images`,

        // Enable the XMLHttpRequest.withCredentials property.
        withCredentials: true,

        // Headers sent along with the XMLHttpRequest to the upload server.
        headers: {
            // origin: 'http://localhost:4200/',
            // authorization: 'Bearer <JSON Web Token>'
        }
    },
    // link: {
		// 	decorators: {
		// 		isExternal: {
		// 			mode: 'automatic',
		// 			// callback: url => url.startsWith( 'http://' ),
    //       callback: (url) => {
    //         // @ts-ignore
    //         window.tmpUrl = url.startsWith( 'http://' ) || url.startsWith( 'https://' ) ? url : `http://${url}`;
    //         return true
    //       },
		// 			attributes: {
		// 				target: '_blank',
		// 				rel: 'noopener noreferrer',
    //         // @ts-ignore
    //         href: window.tmpUrl
		// 			}
		// 		},
    //   }
    // },
    mention: {
      feeds: [
        {
          marker: '#',
          feed: this.getFeedItems.bind(this),
          itemRenderer: this.customItemRenderer.bind(this),
          minimumCharacters: 3
        },
        {
          marker: '@',
          feed: this.getFeedUserItems.bind(this),
          itemRenderer: this.customUserItemRenderer.bind(this),
          minimumCharacters: 3
        }
      ]
    },
  }

  get config() {
    let config = {...this.defConfig, ...{
      simpleUpload : {
        ...this.defConfig.simpleUpload,
        headers: {
          ...this.defConfig.simpleUpload.headers,
          ...{
            Authorization: `Bearer ${this.authService.getToken()}`,
          }
        }
      }
    }}
    return this.baloon ? {...config, ...{ height: 20, toolbar: []}} : config;
  }

  constructor(
    protected avatarPipe: GroupAvatarPipe,
    protected httpClient: HttpClient,
    protected authService: AuthService,
  ) {

  }
  onChange: any = () => { }
  onContentChange: any = (value) => {
    this.onChange(value)
  }
  onTouch: any = () => {}
  writeValue(obj: any): void {
    this.content = obj;
    // throw new Error('Method not implemented.');
  }
  registerOnChange(fn: any): void {
    this.onChange = fn
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }

  insertText (text: string)  {
    // this.editorComponent.editorInstance.focus();

    // setTimeout( () => {
      this.editorComponent.editorInstance.model.change( writer => {
        const insertPosition = this.editorComponent.editorInstance.model.document.selection.getFirstPosition();
        writer.insertText( text, {}, insertPosition );
        // writer.insertText( text );
      });
    // });
  }

  ngOnInit(): void {
    // setTimeout( () => {
    //   console.log(this.editorComponent.editorInstance);
    //   // const insertPosition = this.editorComponent.editorInstance.model.document.selection.getFirstPosition();
    //   // const viewFragment = this.editorComponent.editorInstance.data.processor.toView(`::TEST::`);
    //   // const modelFragment = this.editorComponent.editorInstance.data.toModel(viewFragment);
    //   // // this.mod.insertContent(modelFragment);
    //   this.editorComponent.editorInstance.model.change( writer => {
    //     const insertPosition = this.editorComponent.editorInstance.model.document.selection.getFirstPosition();
    //     writer.insertText( '🙀', { bold: true }, insertPosition );
    // } );
    // }, 2000);

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
  }

  customItemRenderer( item ) {
    const itemElement = document.createElement('span');
    itemElement.className = 'dropdown-item';
    itemElement.id = `mention-list-item-id-${ item.id }`;
    itemElement.innerHTML = `
      <div class="d-flex flex-md-row">
        <div class="user-panel mr-2 d-flex" style="flex-shrink: 0;">
          <img src="${this.avatarPipe.transform(item,35,true,'name','image','uid')}" class="img-circle  align-self-center">
        </div>
        <div>
          <p class="p-0 m-0 text-xs text-muted">${this.typeName(item)}</p>
          <p class="p-0 m-0 text-body">${item.name}</p>
        </div>
      </div>`;
    return itemElement;
  }

  customUserItemRenderer( item ) {
    const itemElement = document.createElement('span');
    itemElement.className = 'dropdown-item';
    itemElement.id = `mention-list-item-id-${ item.id }`;
    itemElement.innerHTML = `
      <div class="d-flex flex-md-row">
        <div class="user-panel mr-2 d-flex" style="flex-shrink: 0;">
          <img src="${this.avatarPipe.transform(item,35,true,'name','image','objId')}" class="img-circle  align-self-center">
        </div>
        <div>
          <p class="p-0 m-0 text-body">${item.name}</p>
        </div>
      </div>`;
    return itemElement;
  }

  typeName (t:any) {
    switch(t.type) {
      case 'post': return 'News';
      case 'article': return 'Artykuł';
      case 'user': return 'Użytkownik';
      case 'offer': return 'Oferta pracy';
      case 'file': return 'Plik';
      case 'faq': return 'FAQ';
    }
  }

  getFeedItems(term: string) {
    return new Promise( resolve => {
      let params = new HttpParams();
      params = params.set('term', term);
      params = params.set('skip', 0);
      params = params.set('limit', 100);
      params = params.set('parts', 'post,article,files,faq');
      this.httpClient
        .get<any>('<backendhost>/v1/search', {params: params, observe: 'body',responseType: 'json'})
        .subscribe((response) => {
          let items = response.results.map(it => {
            return {...it, ...{
              id: `#${it.name}`,
              objId: it.id
            }};
          })
          resolve(items)
        });
    });
  }

  getFeedUserItems(term: string) {
    return new Promise( resolve => {
      let params = new HttpParams();
      params = params.set('term', term);
      params = params.set('skip', 0);
      params = params.set('limit', 100);
      params = params.set('parts', 'user');
      this.httpClient
        .get<any>('<backendhost>/v1/search', {params: params, observe: 'body',responseType: 'json'})
        .subscribe((response) => {
          let items = response.results.map(it => {
            return {...it, ...{
              id: `@${it.name}`,
              objId: it.id
            }};
          })
          resolve(items);
        });
    });
  }
}

<div class="card">
  <div class="card-header d-flex align-items-center">
    <h3 class="card-title m-0 flex-grow-1 text-bold"><i class="fas fa-folder-open fa-fw"></i> Foldery</h3>
    <!-- <div class="card-tools flex-grow-1">
      <div class="input-group">
        <input type="text" name="table_search" class="form-control float-right" placeholder="Szukaj" #searchInput>
        <button class="btn bg-transparent" style="position:absolute; right: 0; transform: translate(-100%,0);" (click)="clearSearch()">
          <i class="fa fa-times"></i>
        </button>
        <span class="input-group-append">
          <div class="input-group-text bg-transparent"><i class="fas fa-search"></i></div>
        </span>
      </div>
    </div> -->
  </div>
  <div class="table-responsive">
    <table class="table align-middle table-hover">
      <thead>
        <tr>
          <th style="width:1rem">#</th>
          <th style="width:1rem"></th>
          <th>Nazwa</th>
          <th>Utworzono</th>
          <th colspan="2">Przez</th>

          <th class="text-right" >
            <button  checkRolePermition="kb.admin.folders.create" class="btn btn-success btn-sm elevation-2 text-nowrap" [routerLink]="['add']" >
              <i class="fas fa-plus fa-fw fa-lg"></i> Dodaj
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of children | async; let idx = index; trackBy: identify">
          <th class="align-middle">{{idx + 1}}</th>
          <td class="align-middle"><img class="img-circle" [src]="item | groupAvatar:45:true:'title'"/></td>
          <td class="align-middle">{{item.title}}</td>
          <td class="align-middle">{{item.createdAt | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
          <td class="user-panel" style="width:1px">
            <img
                  [src]="item.createdBy  | userAvatar: 34"
                  class="img-circle elevation-2"
                  alt="User Image"
                  [appUserDisactivatable]="item.createdBy"
              />
          </td>
          <td>
            <span class="text-decoration-line-through" *ngIf="!item?.createdBy">Użytkownik usunięty</span>
            <strong  [appUserDisactivatable]="item.createdBy">{{item.createdBy?.firstname}} {{item.createdBy?.lastname}}</strong> <br>
            <small  [appUserDisactivatable]="item.createdBy">{{item.createdBy?.active ? item.createdBy?.email : '-' }}</small>
          </td>
          <td class="align-middle text-nowrap text-right" >
            <button class="btn btn-info btn-sm elevation-2 mr-2" [routerLink]="['/kb', item.id]" ngbTooltip="Pokaż">
              <i class="fas fa-folder-open fa-fw fa-lg"></i>
            </button>
            <button class="btn btn-info btn-sm elevation-2 mr-2" [routerLink]="['/kb', item.id, 'admin']" ngbTooltip="Zarządzaj" checkRolePermition="kb.admin">
              <i class="fas fa-tools fa-fw fa-lg"></i>
            </button>
            <button class="btn btn-primary btn-sm elevation-2 mr-2" [routerLink]="['edit', item.id]" ngbTooltip="Edycja" checkRolePermition="kb.admin.folders.edit">
              <i class="fas fa-edit fa-fw fa-lg"></i>
            </button>
            <a
                checkRolePermition="kb.admin.folders.delete"
                class="btn btn-danger btn-sm elevation-2"
                nz-popconfirm
                nzPopconfirmTitle="Czy na pewno usunąć?"
                nzOkText="Tak"
                nzCancelText="Nie"
                nzPopconfirmPlacement="left"
                ngbTooltip="Usuń"
                (nzOnConfirm)="delete(item)" >
              <i class="fas fa-trash fa-fw fa-lg"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

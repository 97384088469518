<div class="content-header pt-0">
  <div class="row">
    <div class="col-12 text-right">
      <div ngbDropdown class="d-inline-block">
        <button class="btn btn-primary" checkRolePermition="vacant.create" [routerLink]="['add-vacant']">
          <i class="fas fa-plus fa-fw"></i> Dodaj ogłoszenie
        </button>
        <button class="btn btn-primary" checkRolePermition="vacant.createMy" [routerLink]="['add-my-vacant']">
          <i class="fas fa-plus fa-fw"></i> Dodaj ogłoszenie
        </button>
      </div>
    </div>
  </div>
</div>

<section class="content">
  <div class="container-fluid">
    <router-outlet></router-outlet>
  </div>
</section>

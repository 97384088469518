import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '@/auth/auth.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserPagination } from '@/store/users/users.reducers';
import { environment } from 'environments/environment';
import { Group } from '@/model/group.model';
import { TrainingPackage } from '@/model/package.model';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    sideOutletSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public user: any = null;

    constructor(
      private http: HttpClient,
      private router: Router,
      private toastr: ToastrService,
      protected authService: AuthService
    ) {}

    redirectToshop() {
      this.http.get<any>(`<backendhost>/v1/shop/uid`).subscribe((resonse) => {
        if (resonse.uid) {
          // window.open(`${environment.shopUrl}?uid=${resonse.uid}`, '_blank');
          let url = `${environment.shopUrl}?uid=${resonse.uid}`;
          var parentOpener = window.opener;
          window.opener = null;
          window.open(url,"_blank");
          window.opener = parentOpener;
        } else {
          this.toastr.error('Nie udało się pobrać UID');
        }

      })
    }

    redirectToshopUrl(): Promise<string> {
      return new Promise<string>( resolve => {
        this.http.get<any>(`<backendhost>/v1/shop/uid`).subscribe((resonse) => {
            let url = `${environment.shopUrl}?uid=${resonse.uid}`;
            resolve(url);
        });
      })
      // this.http.get<any>(`<backendhost>/v1/shop/uid`).subscribe((resonse) => {
      //   if (resonse.uid) {
      //     // window.open(`${environment.shopUrl}?uid=${resonse.uid}`, '_blank');
      //     let url = `${environment.shopUrl}?uid=${resonse.uid}`;
      //     var parentOpener = window.opener;
      //     window.opener = null;
      //     window.open(url,"_blank");
      //     window.opener = parentOpener;
      //   } else {
      //     this.toastr.error('Nie udało się pobrać UID');
      //   }

      // })
    }

    // async loginByAuth({email, password}) {
    //     try {
    //       this.authService.loginByAuth(email, password)
    //         .catch(msg => this.toastr.error(msg))
    //         .then(() => {
    //           // localStorage.setItem('token', token);
    //           this.router.navigate(['/']);
    //           // this.authService.getProfile();
    //         })
    //     } catch (error) {
    //         this.toastr.error(error.response.data.message);
    //     }
    // }

    // async getProfile() {
    //     // try {
    //     //     this.user = await Gatekeeper.getProfile();
    //     //     console.log(this.user);
    //     // } catch (error) {
    //     //     this.logout();
    //     //     throw error;
    //     // }
    //     this.user = this.authService.getUser();
    // }

    logout() {
        /**
         * TODO
         */

        // localStorage.removeItem('token');
        // localStorage.removeItem('gatekeeper_token');
        // this.user = null;
        // this.router.navigate(['/login']);
    }
    searchGroups(term: string): Observable<Group[]> {
      if (term === '') {
        return of([]);
      }
      const params = new HttpParams({
        fromObject: {
          search: term
        }
      });
      return this.http
        .get<Group[]>('<backendhost>/v1/group', {params: params}).pipe(
          map( (result: Group[]) => {
            return result;
          })
        );
    }
    searchUser(term: string) {
      if (term === '') {
        return of([]);
      }
      const params = new HttpParams({
        fromObject: {
          search: term
        }
      });
      return this.http
        .get<UserPagination>('<backendhost>/v1/users', {params: params}).pipe(
          map( (result: UserPagination) => {
            return result.data;
          })
        );
    }
    searchUserNotInPackage(trainingPackage: TrainingPackage, term: string) {
      if (term === '') {
        return of([]);
      }
      const params = new HttpParams({
        fromObject: {
          search: term
        }
      });
      return this.http
        .get<UserPagination>(`<backendhost>/v1/training-package/${trainingPackage.id}/get-invitable`, {params: params}).pipe(
          map( (result: UserPagination) => {
            return result.data;
          })
        );
    }

    kbRoots(): Observable<Object[]> {
      return this.http.get<Object[]>('<backendhost>/v1/kb/folder');
    }
}

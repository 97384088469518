import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-tabs',
  templateUrl: './account-tabs.component.html',
  styleUrls: ['./account-tabs.component.scss']
})
export class AccountTabsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

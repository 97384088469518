import { Component, ElementRef, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-share-link-popup',
  templateUrl: './share-link-popup.component.html',
  styleUrls: ['./share-link-popup.component.scss']
})
export class ShareLinkPopupComponent implements OnInit {

  url: string;
  constructor(
    public modal: NgbActiveModal,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  copyInputMessage(inputElement){
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.toastr.info("Zapisano link do schowka")
  }
}

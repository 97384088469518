import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

interface Result {
  date: string,
  image: string
}

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
  public statistics = [];
  public loading = true;

  constructor(public http: HttpClient) { }

  ngOnInit(): void {
    this.http.get<Result[]>('<backendhost>/v1/statistics').subscribe((result: Result[]) => {
      this.statistics = result.map(({date, image}) => {
        return {
          date,
          image: `${environment.apiUrl}${image}`
        }
      });
      this.loading = false;
    });
  }

}

import { AuthService } from '@/auth/auth.service';
import { Prize } from '@/model/prize.model';
import { selectOne, State } from '@/store/prizes/prize.reducers';
import { selectPrizesState } from '@/store/prizes/prize.reducers';
import { add, edit, fetchOnePrize } from '@/store/prizes/prizes.actions';
import { getBase64 } from '@/utils/base64';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ofType } from '@ngrx/effects';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';

@Component({
  selector: 'app-edit-prize',
  templateUrl: './edit-prize.component.html',
  styleUrls: ['./edit-prize.component.scss']
})
export class EditPrizeComponent implements  OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  disabled = false;
  uploading = false;
  imageFile: NzUploadFile;
  prize: Prize;

  constructor(
    private toastr: ToastrService,
    private store: Store<State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      title: new FormControl(null, Validators.required),
      description: new FormControl(null, Validators.required),
    });
    this.activatedRoute.paramMap.pipe(
      map((params: ParamMap) => parseInt(params.get('id')))
    ).subscribe( (id: number) => {
      this.store.dispatch(fetchOnePrize({id: id}));
      this.store.pipe(takeUntil(this.unsubscribe$), select(selectOne, id)).subscribe((prize: Prize | null) => {
        if(!prize)return;
        this.prize = prize;
        this.form.patchValue(prize);
      })
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  beforeUploadImage = (file: NzUploadFile): boolean => {
    getBase64(file as any).then( (preview: string) => {
      this.imageFile  = file;
      this.prize = {...this.prize, ...{image: preview}};
    })
    return false;
  };

  save(): void {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    let valid =  this.form.valid;
    if (valid) {
      this.disabled = true;

      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(add)
      ).subscribe( (action:any) => {
        this.toastr.success("Zapisano zmiany");
        this.router.navigate(['/prizes'])
      })

      this.store.dispatch(edit({
        id: this.prize.id,
        prize: {...this.prize, ...this.form.value},
        image: this.imageFile,
      }));
    } else if(!this.disabled) {
      this.toastr.error("Formularz zawiera błędy");
    } else {
      this.toastr.warning("Trwa zapisywanie");
    }
  }

  get saving(): Observable<boolean> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(selectPrizesState), map( (state:State) => state.saving));
  }


}

<nav class="main-header navbar navbar-expand navbar-primary navbar-light">
    <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link" (click)="toggleMenuSidebar.emit()" role="button"><i class="fas fa-bars"></i></a>
        </li>
        <li class="d-none d-lg-block pl-3">
          <span class="slogan">W naszej firmie pracujemy zespołowo</span>
        </li>
    </ul>
    <ul class="navbar-nav ml-auto">
        <app-search-btn></app-search-btn>
        <app-messages-dropdown-menu></app-messages-dropdown-menu>
        <app-chat-dropdown-menu></app-chat-dropdown-menu>
        <app-redmine-dropdown-menu checkRolePermition="redmine.view"></app-redmine-dropdown-menu>
        <app-notifications-dropdown-menu></app-notifications-dropdown-menu>
        <app-user-dropdown-menu></app-user-dropdown-menu>
    </ul>
</nav>

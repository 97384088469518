import { OrderForm, OrderFormInput } from "@/model/order.form.model";
import { createAction, props } from "@ngrx/store";
import { NzUploadFile } from "ng-zorro-antd/upload";

export interface OrderFormPagination {
  total: number;
  data: OrderForm[];
}

export interface OrderFormWorkStage {
  orderForm: OrderForm,
  imageFile: any,
  backgroundFile: any
}

export const reset = createAction(
  "reset orders"
);
export const clearSaving = createAction(
  "clear saving orders"
);

export const fetchOrderForms = createAction(
  "fetch OrderForms",
  props<{limit?: number | null}>()
);

export const fetchOneOrderForm = createAction(
  "fetch one OrderForm",
  props<{id: number}>()
);

export const setOrderForm = createAction(
  "set OrderForm",
  props<{orderForm: OrderForm}>()
);

// export const updateOrderForm = createAction(
//   "update OrderForm",
//   props<{orderForm: OrderForm}>()
// );

export const deleteOrderForm = createAction(
  "delete OrderForm",
  props<{orderForm: OrderForm}>()
);

export const removeOrderForm = createAction(
  "remove OrderForm",
  props<{orderForm: OrderForm}>()
);

export const setPage = createAction(
  "set page OrderForm",
  props<{ page: number }>()
);

export const setOrder = createAction(
  "set order OrderForm",
  props<{ order: string }>()
);

export const setDirection = createAction(
  "set direction OrderForm",
  props<{ direction: string }>()
);

export const setSearch = createAction(
  "set search OrderForm",
  props<{ search: string }>()
);

export const addOrderForms = createAction(
  "addOrderForms",
  props<OrderFormPagination>()
);

export const replaceOrderForm = createAction(
  "replace OrderForm",
  props<OrderFormPagination>()
);


export const cleanWorkspace = createAction(
  "cleanWorkspace OrderForm"
);

export const setWorkspace = createAction(
  "setWorkspace OrderForm",
  props<{orderForm: OrderForm}>()
);

export const setWorkspaceOrderFormInput = createAction(
  "setWorkspaceOrderFormInput OrderForm",
  props<{input: OrderFormInput}>()
);

export const saveOrderFormWorkStage = createAction(
  "saveOrderFormWorkStage OrderForm",
  props<{
    orderForm: OrderForm,
    imageFile: any,
    backgroundFile: any,
  }>()
);

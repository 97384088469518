<section class="content">
  <div class="container">
      <div class="row">
          <div class="col-lg-12">
            <form [formGroup]="form" (ngSubmit)="save()">
            <app-survey-admin-tab></app-survey-admin-tab>
            <div class="card card-primary" style="border-top-left-radius: 0;border-top-right-radius: 0;">
              <div class="card-body">
                <div class="form-group">
                  <label for="inputName">Tytuł ankiety</label>
                  <input formControlName="name" type="text" class="form-control" placeholder="Tytuł ankiety" [appIsValid]="form.controls.name"/>
                  <div class="invalid-feedback" *ngIf="form.controls.name.errors?.required">Pole jest wymagane</div>
                </div>
                <div class="row">
                  <div class="col col-md-3 form-group">
                    <label for="inputName">Awatar</label>
                    <div style="max-width: 100%;">
                      <app-upload-crop-image
                        [avatar]="true"
                        [(file)] = "imageFile"
                        [image]="survey?.image ? (survey.image | image) : null"
                        [aspectRatio]="1 / 1">
                      </app-upload-crop-image>
                    </div>
                  </div>
                  <div class="col form-group">
                    <label for="inputName">Obrazek w tle</label>
                    <div style="max-width: 100%;">
                      <app-upload-crop-image
                        [avatar]="false"
                        [(file)] = "backgroundFile"
                        [image]="survey?.background ? (survey.background | image) : null"
                        [aspectRatioClass]="'4'"
                        [aspectRatio]="4 / 1">
                      </app-upload-crop-image>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="inputName">Opis ankiety</label>
                  <app-editor formControlName="content" [appIsValid]="form.controls.content"></app-editor>
                  <div class="invalid-feedback" *ngIf="form.controls.content.errors?.required">Pole jest wymagane</div>
                </div>
              </div>
              <div class="card-footer">
                <button class="btn btn-success float-right" type="submit" >
                  Zapisz
                  <i class="fas fa-angle-right fa-fw fa-lg"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
  </div>
</section>

import { Outpost } from '@/model/outpost.model';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { ofType } from '@ngrx/effects';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import * as fromSlotActions from '@/store/slot/slot.actions';
import * as fromSlotReducers from '@/store/slot/slot.reducers';
import * as fromUsersActions from '@/store/users/users.actions';
import * as fromUsersReducers from '@/store/users/users.reducers';
import { Slot, SlotDay, SlotType } from '@/model/slot.model';
import moment from 'moment';
import { User } from '@/model/user.model';
import { SlotController, SlotTimeValidator } from '../slot.time.validator';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-edit-slot',
  templateUrl: './edit-slot.component.html',
  styleUrls: ['./edit-slot.component.scss']
})
export class EditSlotComponent implements OnInit, SlotController {
  private readonly unsubscribe$: Subject<void> = new Subject();
  SlotType = Object.values(SlotType);
  form: FormGroup;
  outpost: Outpost;
  slot: Slot;
  constructor(
    private toastr: ToastrService,
    private store: Store<fromSlotReducers.State>,
    private usersStore: Store<fromUsersReducers.State>,

    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
  ) {
    this.form = new FormGroup({
      type: new FormControl(SlotType.rejestrator, Validators.required),
      day: new FormControl(SlotDay.WEEKDAY, Validators.required),
      outpost: new FormControl(null, Validators.required),
      startTime: new FormControl(moment("09:00", "HH:mm").toDate(), [Validators.required, SlotTimeValidator(this)]),
      endTime: new FormControl(moment("17:00", "HH:mm").toDate(), [Validators.required, SlotTimeValidator(this)]),
      user: new FormControl(null,[],[this.validateUserSlotAvailable.bind(this)]),
    });
  }

  validateUserSlotAvailable(ctrl: AbstractControl): Observable<ValidationErrors | null> {
    let  data = {
      ...this.slot,
      ...{user: ctrl.value}
    }
    return this.http.post<{valid:boolean}>('<backendhost>/v1/slot/0/validate', data).pipe(

      catchError((err, caught): any => {
        this.toastr.error(err.error?.message ? err.error.message : err.message);
        return caught;
      }),
      map((resonse: {valid:boolean}) => {
        return resonse.valid ? null : {occupied : true}
      })
    );


  }

  ngOnInit(): void {
    this.usersStore.dispatch(fromUsersActions.clearFilters());
    this.usersStore.dispatch(fromUsersActions.fetchUsers({limit:40}));
    this.usersInput$
      .pipe(distinctUntilChanged(),debounceTime(500))
      .subscribe((search) => this.usersStore.dispatch(fromUsersActions.setSearch({search})));
    this.activatedRoute.data.subscribe((data: Data) => {
      this.outpost = data.instance;
      this.slot = data.slot;
      this.form.patchValue({
        ...this.slot,
        ...{
          startTime: moment(this.slot.startTime, "HH:mm"),
          endTime: moment(this.slot.endTime, "HH:mm")
        }
      })
    })
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  save(): void {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
      // console.log(input);
    });
    if (this.form.valid) {
      this.slot = {...this.slot, ...this.form.value};
      this.store.dispatch(fromSlotActions.updateSlot({slot: this.slot}));
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromSlotActions.setSlot.type)
      ).subscribe( (action: any) => {
        this.toastr.success('Zapisano zmiany');
        // this.router.navigate(['/outposts', action.outpost.id, 'edit']);
        this.router.navigate(['/outposts', action.slot.outpost.id,'slots']);
      });
    } else {
        this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }

  loading = false;
  get users$(): Observable<User[]> {
    return this.usersStore.pipe(select(fromUsersReducers.selectAllUsers));
  }
  usersInput$ = new Subject<string>();
  trackByFn(item: any) {
    return item.id;
  }

}

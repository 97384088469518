import { AuthService } from "@/auth/auth.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { select, Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import * as fromActions from "./prizes.actions";
import {State, PrizePagination, selectPrizesState} from "./prize.reducers"
import { Prize } from "@/model/prize.model";
import { User } from "@/model/user.model";
import { plainText } from "@/utils/plainText";
import { StringifyHttpErrorResponse } from "@/utils/http.error.util";

@Injectable()
export class PrizeEffects {
  user: User;
  state: State;

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private store$: Store<State>,
    private authService: AuthService,
    private toastr: ToastrService
  ) {
    this.authService.getUser().then(user => {this.user = user});
    this.store$.pipe(select(selectPrizesState)).subscribe((state: State) => {
      this.state = state;
    })
  }

  onError (err, caught): any {
    this.toastr.error(StringifyHttpErrorResponse(err));
    this.store$.dispatch(fromActions.clearSaving());
    return caught;
  };

  paginatorChange$ = createEffect(() => this.actions$.pipe(
    ofType(...[
      fromActions.setPage,
      fromActions.setSearch,
      fromActions.setOrder,
      fromActions.setDirection
    ]),
    map( () => fromActions.fetchPrizes({}))
  ));

  fetchUsers$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchPrizes),
    switchMap( (action) => {
      let params = new HttpParams();
      params = params.set('skip', this.state.limit * (this.state.page - 1));
      params = params.set('limit', action?.limit ? action.limit : this.state.limit);
      if(this.state.user)
        params = params.set('user', this.state.user.id);
      params = params.set('order', this.state.order);
      params = params.set('direction', this.state.asc ? 'asc' : 'desc');
      if(this.state.search)
        params = params.set('search', this.state.search);
      return this.httpClient.get<PrizePagination>(`<backendhost>/v1/prizes`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: PrizePagination) => [fromActions.replacePrizes(response)])
  ));

  createPrize$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.create),
    switchMap( (action) => {
      const formData = new FormData();
      let prize = {...action.prize, ...{short: plainText(action.prize.description, 200)}};
      if(action.image) {
        formData.append('image', action.image, action.image.name);
      }
      formData.append('data', JSON.stringify(prize));
      return this.httpClient.post<Prize>(`<backendhost>/v1/prizes`, formData, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((prize: Prize) => [
      // fromActions.emitNewUserId({id: response.id}),
      fromActions.add({prize})
    ])
  ));

  fetchOnePrize$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchOnePrize),
    switchMap( (action) => {
      return this.httpClient.get<Prize>(`<backendhost>/v1/prizes/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((prize: Prize) => [fromActions.add({prize})])
  ));

  editOnePrize$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.edit),
    switchMap( (action) => {
      const formData = new FormData();
      let prize = {...action.prize, ...{id: action.id, short: plainText(action.prize.description, 200)}};
      if(action.image) {
        formData.append('image', action.image, action.image.name);
      }
      formData.append('data', JSON.stringify(prize));
      return this.httpClient.post<Prize>(`<backendhost>/v1/prizes/${action.id}/update`, formData, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((prize: Prize) => [
      fromActions.update({prize})
    ])
  ));

  deleteUser$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deletePrize),
    switchMap( (action) => {
      return this.httpClient.delete<any>(`<backendhost>/v1/prizes/${action.prize.id}`, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map( () => action.prize));
    }),
    catchError(this.onError.bind(this)),
    switchMap((prize: Prize) => [
      fromActions.remove({prize})
    ])
  ));
}

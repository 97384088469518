
import { createAction, props } from "@ngrx/store";
import { SearchResult } from "../../model/search.result.model";
// SearchResult
export const search = createAction(
  "search search",
  props<{ term: string }>()
);

export const setTerm = createAction(
  "search setTerm",
  props<{ term: string }>()
);

export const fetch = createAction(
  "search fetch",
  props<{ term: string, skip?:number }>()
);

export const clear = createAction(
  "search clear"
);

export const more = createAction(
  "search more"
);

export const addResults = createAction(
  "search addResults",
  props<{ results: SearchResult[], total: number }>()
);

import { EntityAdapter, EntityState } from "@ngrx/entity";
import { environment } from "environments/environment";

export interface PaginationDTO<T> {
  list: T[];
  total: number;
  loading?: boolean;
}

export interface Pagination<T> {
  loading: boolean;
  list: EntityState<T>;
  total: number;
  page: number;
  order: string;
  readonly limit: number;
  asc: boolean;
  search: string;
  saving: boolean;
}

export function createPaginationInitialState<T>(adapter: EntityAdapter<T>, asc:boolean=true, limit:number = environment.pageSize): Pagination<T> {
  return {
    loading: false,
    total: 0,
    page: 1,
    order: 'id',
    asc: asc,
    search: null,
    limit: limit,
    list: adapter.getInitialState(),
    saving: false
  };
}

<div class="content-header pt-0">
  <div checkRolePermition="training.admin" class="row">
    <div class="col-12 text-right">
      <div ngbDropdown class="d-inline-block">
        <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
          <i class="fas fa-tools fa-fw"></i> Zarządzaj
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <a ngbDropdownItem checkRolePermition="training.admin.scorm"  [routerLink]="['admin/scorm']"><i class="fas fa-folder-open fa-fw"></i> SCORM</a>
          <a ngbDropdownItem checkRolePermition="training.admin.scorm"  [routerLink]="['admin/multimedia']"><i class="fas fa-film fa-fw"></i> Multimedialne</a>
          <a ngbDropdownItem checkRolePermition="training.admin.scorm"  [routerLink]="['admin/quiz']"><i class="fas fa-question fa-fw"></i> Quizy</a>
          <a ngbDropdownItem checkRolePermition="training.admin.scorm"  [routerLink]="['admin/group']"><i class="fas fa-users fa-fw"></i> Grupy szkoleniowe</a>
          <a ngbDropdownItem checkRolePermition="training.admin.scorm"  [routerLink]="['admin/package']"><i class="fas fa-graduation-cap fa-fw"></i> Szkolenia</a>
          <a ngbDropdownItem checkRolePermition="training.admin.scorm"  [routerLink]="['admin/package/archive']"><i class="fas fa-archive fa-fw"></i> Archiwum szkoleń</a>
          <a ngbDropdownItem checkRolePermition="training.admin.scorm"  [routerLink]="['admin/package/report']"><i class="fas fa-chart-area fa-fw"></i> Raport z uczestników</a>
          <!-- <a ngbDropdownItem checkRolePermition="training.admin.scorm" [routerLink]="['admin/articles']"><i class="fas fa-newspaper fa-fw"></i> Artykułami</a> -->
          <!-- <a ngbDropdownItem checkRolePermition="training.admin.scorm"    [routerLink]="['admin/files']"><i class="fas fa-upload fa-fw"></i> Plikami</a> -->
          <!-- <a ngbDropdownItem checkRolePermition="training.admin.scorm"  [routerLink]="['admin/edit',(folder | async)?.id]"><i class="fas fa-edit fa-fw"></i> Edytuj folder</a> -->
        </div>
      </div>
    </div>
  </div>
</div>

<section class="content">
  <div class="container-fluid">
    <router-outlet></router-outlet>
  </div>
</section>

import { PaginationDTO } from '@/model/pagination.model';
import { Survey } from '@/model/survey.model';
import { User } from '@/model/user.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-survey-publish-potential',
  templateUrl: './survey-publish-potential.component.html',
  styleUrls: ['./survey-publish-potential.component.scss']
})
export class SurveyPublishPotentialComponent implements OnInit {
  @Input() survey: Survey;
  pageSize: number = 10;
  page: number = 1;
  total: number = 0;
  loading: boolean = false;
  list: User[] = [];
  data: Survey;

  constructor(
    private httpClient: HttpClient
  ) { }

  ngOnInit(): void {
  }

  identify(item: User, index: number) {
    return item.id;
  }

  pageChange(page) {
    this.page = page;
    this.load(this.data);
  }

  load(data: Survey) {
    this.data = data;
    this.loading = true;
    let params = new HttpParams();
    params = params.set('skip', this.pageSize * (this.page - 1));
    params = params.set('limit', this.pageSize);
    this.httpClient.post<PaginationDTO<User>>(`<backendhost>/v1/survey/${this.survey.id}/potential`, data, {
        params: params,
        observe: 'body',
        responseType: 'json'
      }).subscribe((result) => {
        this.loading = false;
        this.list = result.list;
        this.total = result.total;
      })
  }

}

import { Pagination, PaginationDTO } from "@/model/pagination.model";
import { Invitation, ToInvite, TrainingNotification, TrainingPackage } from "@/model/package.model";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, State, Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import * as fromActions from "./package.actions";
import * as fromReducers from "./package.reducers";
import { pipe } from "rxjs";
import { StringifyHttpErrorResponse } from "@/utils/http.error.util";

@Injectable()
export class TrainingPackageEffects {
  state: fromReducers.State;

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private store$: Store<fromReducers.State>,
  ) {
    this.store$.pipe(select(fromReducers.selectState)).subscribe((state: fromReducers.State) => {
      this.state = state;
    })
  }

  onError (err, caught): any {
    this.toastr.error(StringifyHttpErrorResponse(err));
    return caught;
  };

  paginatorChange$ = createEffect(() => this.actions$.pipe(
    ofType(...[
      fromActions.setPage,
      fromActions.setSearch,
      fromActions.setOrder,
      fromActions.setDirection
    ]),
    map( () => fromActions.fetch())
  ));

  fetch$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetch),
    switchMap(() => {
      let params = new HttpParams();
      params = params.set('skip', this.state.limit * (this.state.page - 1));
      params = params.set('limit', this.state.limit);
      params = params.set('order', this.state.order);
      params = params.set('archive', +this.state.archive);
      params = params.set('open', +this.state.open);
      params = params.set('direction', this.state.asc ? 'asc' : 'desc');
      if(this.state.search)
        params = params.set('search', this.state.search);
      return this.httpClient.get<PaginationDTO<TrainingPackage>>(`<backendhost>/v1/training-package`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: PaginationDTO<TrainingPackage>) => [fromActions.setTrainingPackages(response)])
  ));

  fetchOne$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchOne),
    switchMap((action) => {
      return this.httpClient.get<TrainingPackage>(`<backendhost>/v1/training-package/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((item: TrainingPackage) => [fromActions.addTrainingPackage({item})])
  ));

  removeTrainingPackage$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.removeTrainingPackage),
    switchMap((action) => {
      return this.httpClient.delete<any>(`<backendhost>/v1/training-package/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map(() => action.id))
    }),
    catchError(this.onError.bind(this)),
    switchMap((id: number) => [fromActions.deleteTrainingPackage({id})])
  ));

  saveTrainingPackage$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.saveTrainingPackage),
    switchMap((action) => {
      const formData = new FormData();
      if(action.background) {
        formData.append('background', action.background, action.background.name);
      }
      formData.append('data', JSON.stringify(action.item));
      return this.httpClient.post<TrainingPackage>(`<backendhost>/v1/training-package/${action.item.id}/update`,
          formData,
          {
            observe: 'body',
            responseType: 'json'
          });
    }),
    catchError(this.onError.bind(this)),
    switchMap((item: TrainingPackage) => [fromActions.updateTrainingPackage({item})])
  ));

  cloneTrainingPackage$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.cloneTrainingPackage),
    switchMap((action) => {
      return this.httpClient.post<TrainingPackage>(`<backendhost>/v1/training-package/${action.id}/clone`, {}, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((item: TrainingPackage) => [fromActions.addTrainingPackage({item})])
  ));

  archiveTrainingPackage$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.archiveTrainingPackage),
    switchMap((action) => {
      return this.httpClient.get<TrainingPackage>(`<backendhost>/v1/training-package/${action.id}/archive`,
          {
            observe: 'body',
            responseType: 'json'
          }).pipe(map(()=> action.id));
    }),
    catchError(this.onError.bind(this)),
    switchMap((id: number) => [fromActions.deleteTrainingPackage({id})])
  ));

  createTrainingPackage$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createTrainingPackage),
    switchMap((action) => {
      const formData = new FormData();
      if(action.background) {
        formData.append('background', action.background, action.background.name);
      }
      formData.append('data', JSON.stringify(action.item));
      return this.httpClient.post<TrainingPackage>(`<backendhost>/v1/training-package`,
          formData,
          {
            observe: 'body',
            responseType: 'json'
          });
    }),
    catchError(this.onError.bind(this)),
    switchMap((item: TrainingPackage) => [fromActions.addTrainingPackage({item})])
  ));

  addUserToInvite$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.addUserToInvite),
    switchMap((action) => {
      return this.httpClient.post<ToInvite>(`<backendhost>/v1/training-package/${action.package.id}/add-to-invite`,
          action.user,
          {
            observe: 'body',
            responseType: 'json'
          }).pipe(
            map(result => {
              return {
                package: action.package,
                toInvite: result
              }
            })
          );
    }),
    catchError(this.onError.bind(this)),
    tap(() => {
      this.toastr.success("Dodano zaproszenie");
    }),
    switchMap((result) => [fromActions.addToInvite(result)])
  ));

  deleteToInvite$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deleteToInvite),
    switchMap((action) => {
      return this.httpClient.post<ToInvite>(`<backendhost>/v1/training-package/${action.package.id}/delete-to-invite`,
          action.toInvite,
          {
            observe: 'body',
            responseType: 'json'
          }).pipe(
            map(result => {
              return {
                package: action.package,
                toInvite: action.toInvite
              }
            })
          );
    }),
    catchError(this.onError.bind(this)),
    switchMap((result) => [fromActions.removeToInvite(result)])
  ));

  fetchToInvite$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchToInvite),
    switchMap((action) => {
      let params = new HttpParams();
      params = params.set('skip', this.state.limit * (action.page - 1));
      params = params.set('limit', this.state.limit);
      // params = params.set('order', this.state.order);
      // params = params.set('direction', this.state.asc ? 'asc' : 'desc');
      if(this.state.search)
        params = params.set('search', action.search);
      return this.httpClient.get<PaginationDTO<ToInvite>>(`<backendhost>/v1/training-package/${action.package.id}/get-to-invite`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      }).pipe(map((restult: PaginationDTO<ToInvite>) => {
        return {
          package: action.package,
          invited: restult
        }
      }));
    }),
    catchError(this.onError.bind(this)),
    switchMap((response) => [fromActions.setToInvite(response)])
  ));


  sendInvitations$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.sendInvitations),
    switchMap((action) => {

      return this.httpClient.post<{count: number, ok: boolean}>(`<backendhost>/v1/training-package/${action.package.id}/send-invitations`,
      action.package.current,
      {
        observe: 'body',
        responseType: 'json'
      }).pipe(map((restult: {count: number, ok: boolean}) => {
        return {
          ...action.package,
          ...{
            invited: {
              total: restult.count + (action.package?.current.invited?.total ? action.package?.current.invited?.total : 0)
            }
          }
        }
      }));
    }),
    catchError(this.onError.bind(this)),
    switchMap((item) => {
      return [fromActions.updateTrainingPackage({item})]
    })
  ));

  fetchInvited$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchInvited),
    switchMap((action) => {
      let params = new HttpParams();
      params = params.set('skip', this.state.limit * (action.page - 1));
      params = params.set('limit', this.state.limit);
      // params = params.set('order', this.state.order);
      // params = params.set('direction', this.state.asc ? 'asc' : 'desc');
      if(this.state.search)
        params = params.set('search', action.search);
      return this.httpClient.get<PaginationDTO<Invitation>>(`<backendhost>/v1/training-package/${action.invitePack.id}/get-invited`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      }).pipe(map((restult: PaginationDTO<Invitation>) => {
        return {
          package: action.package,
          invited: restult
        }
      }));
    }),
    catchError(this.onError.bind(this)),
    switchMap((response) => [fromActions.setInvited(response)])
  ));


  createNotification$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createNotification),
    switchMap((action) => {
      return this.httpClient.get<TrainingNotification>(`<backendhost>/v1/training-package/${action.package.id}/create-notification`, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map((restult: TrainingNotification) => {
        return {
          package: action.package,
          notification: restult
        }
      }));
    }),
    catchError(this.onError.bind(this)),
    tap( () => this.toastr.success('Dodano powiadomienie')),
    switchMap((response) => [fromActions.addNotification(response)])
  ));

  deleteNotification$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deleteNotification),
    switchMap((action) => {
      return this.httpClient.get<any>(`<backendhost>/v1/training-package/${action.notification.id}/delete-notification`, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map(() => {
        return {
          package: action.package,
          notification: action.notification
        }
      }));
    }),
    catchError(this.onError.bind(this)),
    tap( () => this.toastr.success('Usunięto powiadomienie')),
    switchMap((response) => [fromActions.removeNotification(response)])
  ));

  updateNotification$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.updateNotification),
    switchMap((action) => {
      return this.httpClient.post<TrainingNotification>(`<backendhost>/v1/training-package/${action.notification.id}/update-notification`,
      action.notification,
      {
        observe: 'body',
        responseType: 'json'
      }).pipe(map((result: TrainingNotification) => {
        return {
          package: action.package,
          notification: result
        }
      }));
    }),
    catchError(this.onError.bind(this)),
    tap( () => this.toastr.success('Zapisano zmiany')),
    switchMap((response) => [fromActions.setNotification(response)])
  ));

  publishPackage$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.publishPackage),
    switchMap((action) => {
      return this.httpClient.get<TrainingPackage>(`<backendhost>/v1/training-package/${action.package.id}/publish`, { observe: 'body', responseType: 'json'});
    }),
    catchError(this.onError.bind(this)),
    switchMap((item) => [fromActions.updateTrainingPackage({item})])
  ));


  deleteInvited$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deleteInvited),
    switchMap((action) => {
      return this.httpClient.post<TrainingPackage>(`<backendhost>/v1/training-package/${action.invited.id}/delete-invited`,
      {}, { observe: 'body', responseType: 'json'}).pipe(map(() => {
        return {
          package : action.package,
          invitePack : action.invitePack,
          invited: action.invited,
        }
      }));
    }),
    catchError(this.onError.bind(this)),
    switchMap((result) => {
      let distpatched = [];

      if (result.invitePack.id == result.package.current.id) {
        distpatched = [...distpatched, ...[fromActions.removeInvited(result)]]
      }
      return distpatched;
    })
  ));


  saveInvitationPack$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.saveInvitationPack),
    switchMap((action) => {
      return this.httpClient.post<TrainingPackage>(`<backendhost>/v1/training-package/${action.package.id}/save-invitation-pack`,
      action.package.current, { observe: 'body', responseType: 'json'}).pipe(map(() => {
        return {
          package : action.package
        }
      }));
    }),
    catchError(this.onError.bind(this)),
    switchMap((result) => {
      return [
        fromActions.updateTrainingPackage({item: result.package}),
        fromActions.fetchToInvite({package: result.package, page: 1})
      ]
    })
  ));

  fetchParticipants$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchParticipants),
    switchMap((action) => {
      let params = new HttpParams();
      params = params.set('skip', this.state.limit * (action.page - 1));
      params = params.set('limit', this.state.limit);
      if(this.state.search)
        params = params.set('search', action.search);
      return this.httpClient.get<PaginationDTO<Invitation>>(`<backendhost>/v1/training-package/${action.package.id}/get-participants`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      }).pipe(map((restult: PaginationDTO<Invitation>) => {
        return {
          package: action.package,
          invited: restult
        }
      }));
    }),
    catchError(this.onError.bind(this)),
    switchMap((response) => [fromActions.setParticipants(response)])
  ));
}

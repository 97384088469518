import { Outpost } from "@/model/outpost.model";
import { FormControl, ValidatorFn } from "@angular/forms";
import moment from "moment";


export interface SlotController {
  outpost: Outpost
}
export function SlotTimeValidator(ctrl: SlotController): ValidatorFn {
  return  (fc: FormControl) => {
    const time = moment(fc.value, "HH:mm");
    const minTime = moment(ctrl.outpost?.startTime, "HH:mm");
    const maxTime = moment(ctrl.outpost?.endTime, "HH:mm");

    // if (time.isBefore(minTime)) {
    //   return {slotTimeLow: true}
    // }
    // if (time.isAfter(maxTime)) {
    //   return {slotTimeHigh: true}
    // }
    return null;
  };
};

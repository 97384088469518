import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store, select, ActionsSubject } from '@ngrx/store';
import { Subject, fromEvent, Observable } from 'rxjs';
import { take, takeUntil, map, filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import * as fromMyTrainingsAction from '@/store/my-training/my-training.actions';
import * as fromMyTrainingsReducers from '@/store/my-training/my-training.reducers';
import { Invitation } from '@/model/package.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'app-my-training-list',
  templateUrl: './my-training-list.component.html',
  styleUrls: ['./my-training-list.component.scss']
})
export class MyTrainingListComponent implements OnInit {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  searchTerm: string;
  page = 1;
  pageSize = 20;
  constructor(
    protected store: Store<fromMyTrainingsReducers.State>,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    private actionsSubject$: ActionsSubject,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams
                       .pipe(map(params => params.status))
                       .subscribe((status) => {
                          if(!status) {
                            this.router.navigate(['/training'],{queryParams: {status: 'active'}})
                          } else {
                            this.store.dispatch(fromMyTrainingsAction.setMode({mode: status}));
                          }
                       })


    this.store.dispatch(fromMyTrainingsAction.fetch());
    this.store.pipe(
      take(1),
      takeUntil(this.unsubscribe$),
      select(fromMyTrainingsReducers.selectState),
      map(state => state.search)
    ).subscribe((search:string) => {
      this.searchInput.nativeElement.value = search
    });
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      takeUntil(this.unsubscribe$),
      map((event: any) => event.target.value),
      filter( (res:string) => res.length > 2),
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((text: string) => {
      this.store.dispatch(fromMyTrainingsAction.setSearch({search: text.toLowerCase().trim()}));
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  clearSearch() {
    this.searchInput.nativeElement.value = '';
    this.store.dispatch(fromMyTrainingsAction.setSearch({search: null}));
  }

  get list(): Observable<Invitation[]> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromMyTrainingsReducers.selectAllMyTrainings));
  }

  identify(index, entity: any){ return entity.id }

  get total(): Observable<number>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromMyTrainingsReducers.selectState),
      map( (state:fromMyTrainingsReducers.State)  => state.total)
    );
  }

  get direction(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromMyTrainingsReducers.selectState),
      map( (state:fromMyTrainingsReducers.State) => state.asc),
      tap( asc => this.asc = asc)
    );
  }

  get loading(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromMyTrainingsReducers.selectState),
      map( (state:fromMyTrainingsReducers.State)  => state.loading)
    );
  }

  get order(): Observable<string>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromMyTrainingsReducers.selectState),
      map( (state:fromMyTrainingsReducers.State)  => state.order),
      tap( sort => this.sort = sort)
    );
  }

  private sort: string;
  private asc: boolean;
  setSort(sort: string): void {
    if(sort == this.sort) {
      this.store.dispatch(fromMyTrainingsAction.setDirection({asc: !this.asc}));
    } else {
      this.store.dispatch(fromMyTrainingsAction.setDirection({asc: false }));
    }
    this.store.dispatch(fromMyTrainingsAction.setOrder({order: sort}));
  }

  toggleDirection(): void {
    this.store.dispatch(fromMyTrainingsAction.setDirection({asc: !this.asc}));
  }

  pageChange(page): void {
    this.store.dispatch(fromMyTrainingsAction.setPage({page: page}));
  }

  reset(invitation: Invitation) {
    this.store.dispatch(fromMyTrainingsAction.reset({invitation}));
    this.actionsSubject$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(fromMyTrainingsAction.update),
      take(1))
        .subscribe(() => {
          this.router.navigate(['/training', invitation.id]);
        })
  }
}

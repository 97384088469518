import { Idea } from '@/model/idea.model';
import { Comment } from '@/model/comment.model';
import { Component, Input, OnInit } from '@angular/core';
import * as fromActions from '@/store/ideas/ideas.actions';
import * as fromReducers from '@/store/ideas/ideas.reducers';
import { ActionsSubject, Store } from '@ngrx/store';
import moment from 'moment';
import { ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-idea-item',
  templateUrl: './idea-item.component.html',
  styleUrls: ['./idea-item.component.scss']
})
export class IdeaItemComponent implements OnInit {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  @Input() item: Idea;
  showComments = false;
  showCommentOverlay = true;

  constructor(
    private store: Store<fromReducers.State>,
    private actionsSubject: ActionsSubject,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    this.actionsSubject.pipe(
      ofType(fromActions.addManyCommentsToIdeaAction),
      takeUntil(this.unsubscribe$))
        .subscribe(() => this.showCommentOverlay = false);
  }

  commentIdenity(item: Comment, index: number) {
    return item.id;
  }

  toggleShowComments() {
    this.showComments = !this.showComments;
    if(this.showComments && this.item.comments.count != this.item.comments?.list.ids.length) {
      this.store.dispatch(fromActions.fetchIdeaCommentsAction({idea: this.item}));
    }
  }

  comments(): Comment[] {
    return this.item.comments?.list ? Object.values(this.item.comments?.list.entities) : [];
  };


  delete(idea: Idea) {

    this.store.dispatch(fromActions.deleteIdea({idea}));
  }

  statusClass(item: Idea): string {
    if(!this.isPublic(item)) return "bg-secondary";
    switch (item.status) {
      case 'new': return "bg-warning";
      case 'analysis': return "bg-secondary";
      case 'rejectred': return "bg-danger";
      case 'voting':
        if (moment().isBefore(moment(item.votingUntil)))
          return "bg-success";
        else
          return "bg-info";
    }
    return item.status;
  }

  statusName(item: Idea): string {
    if(!this.isPublic(item)) return "Ukryty";
    switch (item.status) {
      case 'new': return "Nowy";
      case 'analysis': return "Analiza";
      case 'rejectred': return "Odrzucony";
      case 'voting':
        if (moment().isBefore(moment(item.votingUntil)))
          return "Trwa głosowanie";
        else
          return "Głosowanie zakończone";
    }
    return item.status;
  }
  isPublic(item: Idea): boolean {
    // return !item.publicUntil || moment().isBefore(item.publicUntil);
    return item.publicUntil &&  moment().isBefore(item.publicUntil) && ['analysis', 'voting'].includes(item.status);
  }

  votinOpen(item: Idea): boolean {

    return item.status == 'voting' &&  moment().isBefore(moment(item.votingUntil));
  }
  voteUp(idea: Idea) {
    this.store.dispatch(fromActions.voteUp({idea}));
  }
  voteDown(idea: Idea) {
    this.store.dispatch(fromActions.voteDown({idea}));
  }

  notVoted(idea: Idea): boolean {
    return typeof idea.myVote == "boolean" && idea.myVote === false;
  }
  votedUp(idea: Idea): boolean {
    return typeof idea.myVote == "string" && idea.myVote === "up";
  }
  votedDown(idea: Idea): boolean {
    return typeof idea.myVote == "string" && idea.myVote === "down";
  }
}

import { InnerMessage } from '@/model/message.mode';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MessageService } from '@services/messages/message.service';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import * as fromMessagersActions from '@/store/messages/messages.actions';
import * as fromMessagersReducers from '@/store/messages/messages.reducers';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';


interface InnerMessageDto extends InnerMessage{
  unseenCount: number;
}
@Component({
  selector: 'app-view-message',
  templateUrl: './view-message.component.html',
  styleUrls: ['./view-message.component.scss']
})
export class ViewMessageComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  message: InnerMessage;
  constructor(
    private httpClient: HttpClient,
    private activeRoute: ActivatedRoute,
    private messageService: MessageService,
    private store: Store<fromMessagersReducers.State>,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activeRoute.paramMap.pipe(
      map( (params: ParamMap) => +params.get('id')),
      takeUntil(this.unsubscribe$),
      switchMap(  (id: number) => this.httpClient.get<InnerMessageDto>(`<backendhost>/v1/messages/${id}`, { observe: 'body',responseType: 'json'})),
      catchError( (err, caught): any => {

        this.toastr.error(err.error.message);
        if (err.status == 404)
          setTimeout(() => {
            this.router.navigateByUrl("/")
          }, 1000)
        // return caught;
      })
    ).subscribe( (msg: InnerMessageDto) => {
      this.message = msg;
      this.messageService.setAsSeen(msg.id);
      this.store.dispatch(fromMessagersActions.setUnseenCount({count: msg.unseenCount}))
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

}

<section class="content">
  <div class="container-xl pt-3 pb-3">
    <div class="card">
      <div class="overlay" *ngIf="(loading | async)">
        <div style="position: sticky; top:50%; left:50%; align-self: baseline;text-align: center; padding: 25px 0">
          <i class="fas fa-3x fa-spinner fa-spin"></i>
          <p>Ładowanie...</p>
        </div>
      </div>
      <div class="overlay" *ngIf="(saving | async)">
        <div style="position: sticky; top:50%; left:50%; align-self: baseline;text-align: center; padding: 25px 0">
          <i class="fas fa-3x fa-spinner fa-spin"></i>
          <p>Zapisywanie...</p>
        </div>
      </div>
      <div class="card-header d-flex align-items-center">
        <h3 class="card-title m-0 flex-grow-1">Użytkownicy</h3>
        <div class="card-tools flex-grow-1">
          <div class="input-group">
            <input type="text" name="table_search" class="form-control float-right" placeholder="Szukaj" #searchInput>
            <button class="btn bg-transparent" style="position:absolute; right: 0; transform: translate(-100%,0);" (click)="clearSearch()">
              <i class="fa fa-times"></i>
            </button>
            <span class="input-group-append">
              <div class="input-group-text bg-transparent"><i class="fas fa-search"></i></div>
            </span>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table align-middle table-hover">
          <thead>
            <tr>
              <th style="width:1rem">#</th>
              <th style="width:5rem"></th>
              <th>Imię</th>
              <th>Nazwisko</th>
              <th>Email</th>
              <th>Utworzono</th>
              <th>Rola</th>

              <th class="text-right" colspan="7">
                <button
                  checkRolePermition="users.delete"
                  class="btn  btn-sm elevation-1 text-nowrap mr-3"
                  [ngClass]="{'btn-default' : !(deactivated | async), 'btn-primary' : deactivated | async}"
                  (click)="toggleDeactivated()"
                >
                  <i class="fas fa-trash fa-fw fa-lg"></i> Pokaż nieaktywnych
                </button>
                <button  checkRolePermition="users.create" class="btn btn-success btn-sm elevation-2 text-nowrap" [routerLink]="['/users', 'add']" >
                  <i class="fas fa-plus fa-fw fa-lg"></i> Dodaj
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of paginator | async; let idx = index; trackBy: identify">
              <th class="align-middle">{{idx + 1 + pageSize * (page - 1)}}</th>
              <td class="align-middle user-panel" style="width:70px">
                <img class="img-circle" [src]="item | userAvatar" *ngIf="item.active"/>
                <img class="img-circle" [src]="item | userAvatar" *ngIf="!item.active" [appUserDisactivatable]="item"/>
              </td>
              <td class="align-middle">
                <span *ngIf="item.active">{{item.firstname}}</span>
                <span *ngIf="!item.active" [appUserDisactivatable]="item">{{item.firstname}}</span>
              </td>
              <td class="align-middle">
                <span *ngIf="item.active">{{item.lastname}}</span>
                <span *ngIf="!item.active" [appUserDisactivatable]="item">{{item.lastname}}</span>
              </td>
              <td class="align-middle">
                <span *ngIf="item.active">{{item.email}}</span>
                <span *ngIf="!item.active" [appUserDisactivatable]="item"> - </span>
              </td>
              <td class="align-middle">{{item.createdAt | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
              <td class="align-middle">{{item.role | roleName}}</td>
              <td class="align-middle text-nowrap p-0" style="width:0">
                <button class="btn btn-info btn-sm elevation-2  m-1" [routerLink]="['/users', item.id]" ngbTooltip="Profil">
                  <i class="fas fa-user fa-fw fa-lg"></i>
                </button>
              </td>
              <td class="align-middle text-nowrap p-0" style="width:0">
                <button class="btn btn-primary btn-sm elevation-2  m-1" [routerLink]="['/users', item.id, 'edit']" ngbTooltip="Edycja" checkRolePermition="users.edit">
                  <i class="fas fa-edit fa-fw fa-lg"></i>
                </button>
              </td>
              <td class="align-middle text-nowrap p-0" style="width:0">
                <button class="btn btn-primary btn-sm elevation-2  m-1" [routerLink]="['/users', item.id, 'password']"  ngbTooltip="Edytuj hasło" checkRolePermition="users.password">
                  <i class="fas fa-lock fa-fw fa-lg"></i>
                </button>
              </td>
              <td class="align-middle text-nowrap p-0" style="width:0">
                <button
                  class="btn btn-primary btn-sm elevation-2 m-1"
                  [routerLink]="['/users', item.id, 'prizes']"
                  ngbTooltip="Nagrody"
                  [instance]="item"
                  checkRolePermition="users.prize">
                  <i class="fas fa-trophy fa-fw fa-lg"></i>
                </button>
              </td>
              <td class="align-middle text-nowrap p-0" style="width:0">
                <button
                  class="btn btn-primary btn-sm elevation-2 m-1"
                  [routerLink]="['/users', item.id, 'slots']"
                  ngbTooltip="Sloty pracy"
                  [instance]="item"
                  checkRolePermition="users.edit">
                  <i class="fas fa-flask fa-fw fa-lg"></i>
                </button>
              </td>
              <td class="align-middle text-nowrap p-0" style="width:0">
                <button
                  class="btn btn-primary btn-sm elevation-2 m-1"
                  [routerLink]="['/users', item.id, 'workmarket']"
                  ngbTooltip="Ustawienia giełdy pracy"
                  [instance]="item"
                  checkRolePermition="users.edit">
                  <i class="fas fa-cogs fa-fw fa-lg"></i>
                </button>
              </td>
              <td class="align-middle text-nowrap p-0 pr-2" style="width:0" ngbDropdown  container="body">
                <!-- <button
                  [checkRolePermition]="['users.delete', 'users.edit']"
                  type="button"
                  class="btn btn-danger btn-sm elevation-2 m-1"
                  ngbTooltip="Usuń lub {{item.active ? 'dezaktywuj' : 'aktywuj'}}"
                  ngbDropdownToggle>
                  <i class="fas fa-trash fa-fw fa-lg"></i>
                </button> -->
                <!-- <div ngbDropdownMenu> -->
                  <button
                      *ngIf="!item.active"
                      [checkRolePermition]="['users.delete']"
                      class="btn btn-warning btn-sm elevation-2 m-1"
                      ngbTooltip="Przywróć do aktywnych"
                      (click)="activationToggle(item)" >
                    <i class="fas fa-power-off fa-fw fa-lg"></i>
                  </button>
                  <button
                      *ngIf="item.active"
                      class="btn btn-danger btn-sm elevation-2 m-1"
                      [checkRolePermition]="['users.delete']"
                      nz-popconfirm
                      nzPopconfirmTitle="Czy na pewno usunąć?"
                      nzOkText="Tak"
                      nzCancelText="Nie"
                      nzPopconfirmPlacement="left"
                      ngbTooltip="Usuń"
                      (nzOnConfirm)="delete(item)" >
                    <i class="fas fa-trash fa-fw fa-lg"></i>
                  </button>
                <!-- </div> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(paginator | async).length == 0"></nz-empty>
      <div class="card-footer" >
        <ngb-pagination style="display: inline-flex;" #paginatorElem [(page)]="page" [pageSize]="pageSize" [collectionSize]="total | async" [maxSize]="maxSize" (pageChange)="pageChange($event)">
          <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
  <!-- [collectionSize]="total | async" [maxSize]="8"  -->
</section>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import * as fromKbActions from '@/store/kb/kb.actions';
import * as fromKbReducers from '@/store/kb/kb.reducers';
import { Folder } from '@/model/folder.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Actions, ofType } from '@ngrx/effects';
import { tinyMceConfig } from '@/config/tinymce.config';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-edit-folder',
  templateUrl: './edit-folder.component.html',
  styleUrls: ['./edit-folder.component.scss']
})
export class EditFolderComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  show = false;
  form: FormGroup;
  parent: Folder;
  folder: Folder;
  tinyMceConfig = tinyMceConfig;
  uploading = false;

  constructor(
    private store: Store<fromKbReducers.State>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    protected actions$: Actions,
  ) {
    this.actions$.pipe(
      ofType(fromKbActions.saveSuccess),
      takeUntil(this.unsubscribe$)
    ).subscribe((action) => {
      // console.log(action);
      this.router.navigate(["/kb", action.parent, 'admin']);
      this.toastr.success("Zapisano poprawnie");
    });
  }

  ngOnInit(): void {
    this.initForm();
    this.activatedRoute.paramMap.pipe(
      map((params: ParamMap) => parseInt(params.get('id')))
    ).subscribe( (id: number) => {
      this.store.pipe(takeUntil(this.unsubscribe$), select(fromKbReducers.selectFolder)).subscribe((folder:Folder) => {
        if (!folder)return;
        this.show = true;
        this.parent = folder;
        this.folder = folder.id == id ? folder : folder.children.entities[id];
        this.form.patchValue({...this.folder, ...{parent: folder.id}});
      });
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  get saving(): Observable<boolean>  {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromKbReducers.selectSaving));
  }

  save() {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });

    if (this.form.valid) {
      let update = {...this.folder,...this.form.value}
      this.store.dispatch(fromKbActions.saveFolder({folder: update}));
    } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }

  handleImageUploadChange(info: { file: NzUploadFile }): void {
    console.log(info);
    switch (info.file.status) {
      case 'uploading':
        this.uploading = true;
        break;
      case 'done':
        let update = {...this.folder,...this.form.value, ...{ image: info.file.response.image}};
        if (this.parent.id == this.folder.id)
          this.store.dispatch(fromKbActions.updateSelf({folder: update}));
        else
          this.store.dispatch(fromKbActions.updateFolder({folder: update}));
        this.uploading = false;
        break;
      case 'error':
        this.toastr.error('Błąd uploadu pliku');
        this.uploading = false;
        break;
    }
  }

  handleBackgroundUploadChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.uploading = true;
        break;
      case 'done':
        let update = {...this.folder,...this.form.value, ...{ background: info.file.response.background}};
        if (this.parent.id == this.folder.id)
          this.store.dispatch(fromKbActions.updateSelf({folder: update}));
        else
          this.store.dispatch(fromKbActions.updateFolder({folder: update}));
        this.uploading = false;
        break;
      case 'error':
        this.toastr.error('Błąd uploadu pliku');
        this.uploading = false;
        break;
    }
  }

  protected initForm(): void {
    this.form = new FormGroup({
      title: new FormControl(null, Validators.required),
      parent: new FormControl(null, Validators.required),
      description: new FormControl(null),
    });
  }

}

<div class="container pt-3 pb-3">
  <div class="row">
    <div class="col-12">
      <form [formGroup]="form" (ngSubmit)="save()">
      <div class="card card-primary">
        <div class="card-body">
          <div class="form-group">
            <label for="title">Tytuł</label>
            <input formControlName="title" [appIsValid]="form.controls.title" type="text" class="form-control" placeholder="Tytuł" />
            <div class="invalid-feedback" *ngIf="form.controls.title.errors?.required">
              Pole jest wymagane
            </div>
          </div>
          <div class="form-group">
            <label for="description">Opis</label>
            <!-- <editor [init]="tinyMceConfig.init" apiKey="{{tinyMceConfig.apiKey}}" formControlName="description"></editor> -->
            <app-editor formControlName="description"></app-editor>
            <div class="text-danger" *ngIf="form.controls.description.touched && form.controls.description.errors?.required">
              Pole jest wymagane
            </div>
          </div>
          <div class="form-group">
            <label for="description">Oddział nadrzędny</label>
            <nz-tree-select style="width: 100%"
              formControlName="parent"
              [nzExpandedKeys]="expandKeys"
              [nzNodes]="nodes"
              nzShowSearch
              nzPlaceHolder="Wybierz"

            ></nz-tree-select>
          </div>
          <div class="form-group">
            <label for="inputName">Obrazek w tle</label>
            <div style="max-width: 100%;">
              <app-upload-crop-image
                [avatar]="false"
                [image]="branch?.image | image"
                [(file)] = "file"
                [aspectRatio]="16 / 9"
                [aspectRatioClass]="'16-9'"
              >
              </app-upload-crop-image>
              <!-- <nz-upload
                class="bg-uploader  w-100"
                nzAccept="image/png, image/jpeg"
                nzName="image"
                nzListType="picture-card"
                [nzShowUploadList]="false"
                [nzBeforeUpload]="beforeUpload"
              >
                <ng-container *ngIf="!branch?.image" class="w-100">
                  <i class="fas fa-3x fa-image"></i>
                </ng-container>
                <img *ngIf="branch?.image" [src]="branch.image | image" style="width: 100%" />
              </nz-upload> -->
            </div>
          </div>
          <!-- <div class="form-group">
            <label>Tagi</label>
            <tag-input
              [(ngModel)]='post.tags'
              [ngModelOptions]="{standalone: true}"
              theme='bootstrap'
              secondaryPlaceholder="Wpisz nowy tag"></tag-input>
          </div> -->
        </div>
        <div class="card-footer">
          <button class="btn btn-success float-right" type="submit">
            Zapisz
            <i class="fas fa-angle-right fa-fw"></i>
          </button>
        </div>
      </div>
      </form>
    </div>
  </div>
</div>

import { OfferCategory } from '@/model/offer.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import * as fromOfferCategoryActions from '@/store/offer-category/offer.category.action';
import * as fromOfferCategoryReducers from '@/store/offer-category/offer.category.reducers';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-list-offer-category',
  templateUrl: './list-offer-category.component.html',
  styleUrls: ['./list-offer-category.component.scss']
})
export class ListOfferCategoryComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();

  constructor(
    private store: Store<fromOfferCategoryReducers.State>,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    // this.activatedRoute.data.subscribe( (data: Data) => {
    //   this.list = data.categories;
    //   console.log(data);
    // })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get list(): Observable<OfferCategory[]> {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromOfferCategoryReducers.selectAllOfferCategory)
    );
  }

  delete(id: number): void {
    this.store.dispatch(fromOfferCategoryActions.deleteOfferCategory({id}))
  }


  identify(index, entity: OfferCategory){ return entity.id }
}

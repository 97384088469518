import { AuthService } from '@/auth/auth.service';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {Component, ElementRef, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AdminAuthGuard } from '@guards/admin-auth.guard';
import { DownloadComponent } from '@pages/kb/folder/download/download.component';
import { AppService } from '@services/app.service';
import { ResizedEvent } from 'angular-resize-event';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    @ViewChild('container', {static: false}) container: ElementRef;
    @ViewChild('containerOutlet', {static: true}) containerOutlet: ElementRef;

    @ViewChild('nav',{static: false}) nav: ElementRef;
    @ViewChild('btn',{static: false}) btn: ElementRef;
    private containerHeigh;
    private navHeigh;
    public user;
    public kbRoots: any[];
    private overlayScrollbars: any;
    goToShopUrl: string;

    constructor(
      public authService: AuthService,
      public appService: AppService,
      public http: HttpClient,
      public adminAuthGuard: AdminAuthGuard,
      private router: Router,
      private activeRoutre: ActivatedRoute,
      private renderer: Renderer2,
      @Inject(DOCUMENT) private document: Document
    ) {
    //   private shopUrl;
    // get goToShopUrl(): Observable<string> {
    //   // return of(this.appService.redirectToshopUrl);
      this.appService.redirectToshopUrl().then( url => {
        this.goToShopUrl = url;
      });
    //   return this.http.get<any>(`<backendhost>/v1/shop/uid`).pipe(take(1),map(resonse =>  `${environment.shopUrl}?uid=${resonse.uid}`));
    // }
    }

    onSidebarScroll() {
      // @ts-expect-error
      let scrolValue = OverlayScrollbars(document.getElementById("sidebar")).scroll();
      if (scrolValue.position.y >= scrolValue.max.y) {
        this.renderer.addClass(this.btn.nativeElement, 'd-none');
        this.renderer.removeClass(this.btn.nativeElement, 'd-block');
      } else {
        this.renderer.addClass(this.btn.nativeElement, 'd-block');
        this.renderer.removeClass(this.btn.nativeElement, 'd-none');
      }
    }

    private initOverlayScrollbarTimer: any;
    private initOverlayScrollbarEvent() {
      // @ts-expect-error
      let instance = OverlayScrollbars(document.getElementById("sidebar"));
      if(instance) {
        instance.options('callbacks.onScroll', this.onSidebarScroll.bind(this));
        instance.options('scrollbars.autoHide', "never");
      }
      else {
        // @ts-expect-error
        instance = OverlayScrollbars(document.getElementById("sidebar"), {
          className: 'os-theme-light',
          sizeAutoCapable: true,
          scrollbars: {
            autoHide: "never",
            clickScrolling: true
          }
        });
        instance.options('callbacks.onScroll', this.onSidebarScroll.bind(this));
      }

    }

    ngAfterViewInit() {
      this.router.events.subscribe((event:any) => {
        if(event instanceof NavigationEnd) {
          if (this.document.body.clientWidth <= 768) {
            this.renderer.addClass(document.body.querySelector('app-root'), 'sidebar-collapse');
            this.renderer.removeClass(document.body.querySelector('app-root'), 'sidebar-open');
          }
          // 767.98px

        }
      });
      this.initOverlayScrollbarTimer = setTimeout(this.initOverlayScrollbarEvent.bind(this), 500);
      this.resizeObserver.observe(this.nav.nativeElement);
      this.resizeObserver.observe(this.container.nativeElement);
      this.appService.sideOutletSubject.subscribe((on:boolean) => {
        this.renderer.addClass(this.containerOutlet.nativeElement, on ? 'd-block' : 'd-none');
        if (on) {
          this.renderer.addClass(this.container.nativeElement, 'side-outlet-on');
        } else {
          this.renderer.removeClass(this.container.nativeElement, 'side-outlet-on');
        }
      });

    }

    scrollDown() {
      let self = this;
      // @ts-expect-error
      let scrolValue = OverlayScrollbars(document.getElementById("sidebar")).scroll();
      // @ts-expect-error
      OverlayScrollbars(document.getElementById("sidebar")).scroll(scrolValue.max.y, 700, undefined, function() {
        self.renderer.addClass(self.btn.nativeElement, 'd-none');
        self.renderer.removeClass(self.btn.nativeElement, 'd-block');
       });
    }

    resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        if(entry.contentBoxSize) {
          if (entry.target == this.container.nativeElement) {
            // this.renderer.setStyle(this.btn.nativeElement, 'width', `${entry.contentRect.width + entry.contentRect.x}px`);
            this.renderer.setStyle(this.btn.nativeElement, 'top', `${entry.contentRect.height + 57}px`);
            this.renderer.setStyle(this.btn.nativeElement, 'left', `${entry.contentRect.width - 22}px`);
            this.containerHeigh = entry.contentRect.height
          } else {
            this.navHeigh = entry.contentRect.height
          }
          if (this.navHeigh > this.containerHeigh) {
            this.renderer.addClass(this.btn.nativeElement, 'd-block');
            this.renderer.removeClass(this.btn.nativeElement, 'd-none');
          } else {
            this.renderer.addClass(this.btn.nativeElement, 'd-none');
            this.renderer.removeClass(this.btn.nativeElement, 'd-block');
          }
        }
      }
    });

    ngOnInit() {
      this.authService.getUser().then(user => {this.user = user});
      this.appService.kbRoots().subscribe( result => {
        this.kbRoots = result;
      });
    }
    onResized(event: ResizedEvent) {
      console.log(event);
    }

    gotToShop() {
      this.appService.redirectToshop();
    }


}


<li class="nav-item dropdown ml-2">
    <a class="nav-link position-relative elevation-2 img-circle img-size-40 bg-white" data-toggle="dropdown" (click)="toggleDropdownMenu()">
        <i class="far fa-envelope fa-lg"></i>
        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-orange" *ngIf="count | async">{{count | async}}</span>
        <!-- <span class="fa-layers fa-fw" style="background:MistyRose">
          <i class="fas fa-comments"></i>
          <span class="fa-layers-counter" style="background:Tomato">13</span>
        </span> -->

    </a>
    <div  #dropdownMenu class="dropdown-menu dropdown-menu-lg dropdown-menu-right">

        <a [routerLink]="['/messages/inbox']" class="dropdown-item dropdown-footer text-center text-dark">Wszystkie wiadomości</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item   position-relative" *ngFor="let item of latest | async; trackBy: identify" [routerLink]="['/messages/view', item.id]">
            <!-- Message Start -->
            <div class="media">
                <div class="img-size-50 mr-3 flex-shrink-0">
                  <div class="position-relative">
                    <img [src]="item.createdBy | userAvatar" class="img-size-50 img-circle" [appUserDisactivatable]="item.createdBy"/>
                    <!-- <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary mr-3 mt-2" *ngIf="item.unseenCount">
                      {{item.unseenCount }}
                    </span> -->
                  </div>
                </div>
                <div class="media-body">
                    <h3 class="dropdown-item-title text-primary">
                      {{item.title}}
                    </h3>
                    <p class="text-md text-truncate text-dark">
                      <span class="text-decoration-line-through" *ngIf="!item?.createdBy">Użytkownik usunięty</span>
                      <span *ngIf="item?.createdBy" [appUserDisactivatable]="item.createdBy">{{item.createdBy?.name }}</span>
                    </p>
                    <p class="text-sm text-muted text-nowrap">
                        <i class="far fa-clock mr-1"></i> {{item.createdAt | amLocale:'pl' | amCalendar}}
                    </p>
                    <div class="ribbon-wrapper ribbon-sm" *ngIf="!item.seen">
                      <div class="ribbon bg-orange text-sm">
                        NOWE
                      </div>
                    </div>
                </div>
            </div>
        </a>
        <div class="dropdown-divider"></div>
        <a [routerLink]="['/messages/inbox']" class="dropdown-item dropdown-footer text-center text-dark">Wszystkie wiadomości</a>
    </div>
</li>

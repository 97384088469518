import { Component, OnInit } from '@angular/core';
import { RedmineService } from '@services/redmine.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-redmine',
  templateUrl: './redmine.component.html',
  styleUrls: ['./redmine.component.scss']
})
export class RedmineComponent implements OnInit {

  constructor(private service: RedmineService) { }

  ngOnInit(): void {
  }

  get assignedCount(): Observable<number> {
    return this.service.assignedCount;
  }

  get watchedCount(): Observable<number> {
    return this.service.watchedCount;
  }

  get createdCount(): Observable<number> {
    return this.service.createdCount;
  }

}

<div class="content">
<div class="container-xl" *ngIf="offer">
  <div class="card card-widget position-relative shadow mt-3 mb-4">
    <div [ngStyle]="backgroundStyle()"></div>
    <div class="card-header pb-1">
      <div class="user-block">
        <img class="img-circle" [src]="offer?.createdBy | userAvatar" alt="User Image" [appUserDisactivatable]="offer?.createdBy">
        <span class="username">
          <span class="text-decoration-line-through" *ngIf="!offer?.createdBy">Użytkownik usunięty</span>
          <a [routerLink]="['/profile', offer?.createdBy?.id]" *ngIf="offer?.createdBy" [appUserDisactivatable]="offer?.createdBy">
            {{offer?.createdBy?.firstname}} {{offer?.createdBy?.lastname}}
          </a>
        </span>
        <!-- <span class="description"> {{post.createdAt | amLocale:'pl' | amTimeAgo }}</span> -->
        <span class="description"> {{offer?.createdAt | amLocale:'pl' | amCalendar }}</span>
        <span class="description">
          <a class="btn btn-link p-0 pr-1 text-sm" *ngFor="let tag of offer?.tags" [routerLink]="['/search', tag | lowercase]">#{{tag}}</a>
        </span>
      </div>
    </div>
    <div class="card-header pt-1" >
      <h3 *ngIf="offer?.id" class="text-primary card-title text-lg m-0">
        {{offer?.title}}
      </h3>
    </div>
    <div class="table-responsive">
      <table class="table align-middle table-hover">
        <thead>
          <tr>
            <th style="width:1rem">#</th>
            <th style="width:1rem"></th>
            <th style="width:1rem">Imię</th>
            <th style="width:1rem">Nazwisko</th>
            <th style="width:1rem">Email</th>
            <th style="width:1rem">Złożono</th>
            <th style="width:1rem">Polecony przez</th>
            <th style="width:1rem">Pliki</th>

          </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of candidates; let idx = index">
              <th class="align-middle">{{idx+1}}</th>
              <td class="align-middle"><img style="width:35px" class="img-circle elevation-2" [src]="item.user | userAvatar:35"/></td>
              <td class="align-middle">{{item.firstname}}</td>
              <td class="align-middle">{{item.lastname}}</td>
              <td class="align-middle">{{item.email}}</td>
              <td class="align-middle text-nowrap">{{item.createdAt | amLocale:'pl' | amTimeAgo }}</td>


              <td class="align-middle" *ngIf="item.refferer">
                <a [routerLink]="['/profile', item.refferer.id]" class="btn btn-link text-left">
                  <img style="width:25px" class="img-circle elevation-2" [src]="item.refferer | userAvatar:25" [appUserDisactivatable]="item.refferer"/>
                  <span [appUserDisactivatable]="item.refferer">{{item.refferer.name}}</span>
                </a>
              </td>
              <td class="align-middle" *ngIf="!item.refferer"> -- brak --</td>

              <td class="align-middle text-nowrap">
                <ol>
                  <li *ngFor="let file of item.files">
                    <a (click)="download(file)">{{file.name}}</a>
                  </li>
                </ol>
              </td>
            </tr>
          </tbody>
      </table>

  </div>
</div>
</div>

import { RedmineIssueAttachment } from '@/model/redmine.model';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-redmine-issue-detail-attachments',
  templateUrl: './redmine-issue-detail-attachments.component.html',
  styleUrls: ['./redmine-issue-detail-attachments.component.scss']
})
export class RedmineIssueDetailAttachmentsComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  @Input()
  attachments: RedmineIssueAttachment[];

  constructor(
    protected httpClient: HttpClient,
    protected toastr: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  download(attachment: RedmineIssueAttachment) {
    this.httpClient
      .get(`${environment.apiUrl}/v1/redmine-issue/0/download`, {
        responseType: 'blob',
        params: {
          file: encodeURIComponent(attachment.content_url)
        }
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          let blob:any = new Blob([response], { type: attachment.content_type });

          const url = window.URL.createObjectURL(blob);
          var anchor = document.createElement('a');
        	anchor.href = url;
        	anchor.download = attachment.filename;
        	document.body.appendChild(anchor);
        	anchor.click();
        },
        error => {
          this.toastr.error('Nie udało się pobrać pliku. ' + error)
        },
        () => console.info('File downloaded successfully')
      );
  }
}

<div class="content">
  <div class="container pt-3 pb-3">
    <div class="card card-primary card-outline direct-chat direct-chat-primary">
      <div class="overlay dark" *ngIf="!(conversation | async)">
        <i class="fas fa-3x fa-spinner fa-spin"></i>
      </div>
      <div class="card-header">
        <a class="card-title" *ngIf="(conversation | async)?.type == 'direct' && (conversation | async)?.membersFull" [routerLink]="['/profile',(conversation | async)?.membersFull[0]?.id]">

          <img [src]="(conversation | async) | groupAvatar" class="img-circle img-sie-40 elevation-3" />
          <span class="text-lg p-3">{{ (conversation | async)?.name }}</span>
        </a>
        <h3 class="card-title" *ngIf="(conversation | async)?.type == 'room'">
          <img [src]="(conversation | async) | groupAvatar" class="img-circle img-sie-40 elevation-3" />
          <span class="text-lg p-3">{{ (conversation | async)?.name }}</span>
        </h3>
        <div class="card-tools">

          <span class="position-relative" *ngIf="(conversation | async)?.type === 'room'">
            <a [routerLink]="['/profile', u.id]" *ngFor="let u of (conversation | async)?.membersFull | slice: 0 :  5" [title]="u.name">
              <img [src]="u | userAvatar" style="width: 35px;margin-left: -8px;" class="img-circle img-sie-40 elevation-1" [appUserDisactivatable]="u"/>
            </a>
          </span>
          <button  *ngIf="canEdit | async" type="button" class="btn btn-tool" title="Edit" data-widget="chat-pane-toggle" [routerLink]="['/chat/'+ id + '/room/edit']">
            <i class="fas fa-edit"></i>
          </button>
          <a
            *ngIf="canEdit | async"
            class="btn btn-tool"
            nz-popconfirm
            nzPopconfirmTitle="Czy na pewno usunąć?"
            nzOkText="Tak"
            nzCancelText="Nie"
            nzPopconfirmPlacement="left"
            ngbTooltip="Usuń"
            (nzOnConfirm)="delete()" >
            <i class="fas fa-trash"></i>
          </a>
        </div>
      </div>
      <div class="card-body">
        <div class="direct-chat-messages" #scrollMe>
          <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="!(messages | async) || (messages | async).length == 0"></nz-empty>
          <div class="direct-chat-msg d-flex flex-column" *ngFor="let msg of messages | async; trackBy: identify" [ngClass]="{'right' : msg.createdBy?.id === user?.id}">
            <div [ngClass]="{'right align-self-end' : msg.createdBy?.id === user?.id}">
              <div class="direct-chat-infos clearfix">
                <span class="direct-chat-name float-left pr-3">
                  <span class="text-decoration-line-through" *ngIf="!msg?.createdBy">Użytkownik usunięty</span>
                  {{msg.createdBy?.name}}
                </span>
                <span class="direct-chat-timestamp float-right" *ngIf="!msg.saving">{{msg.createdAt | amLocale: 'pl' | amTimeAgo}}</span>
                <span class="direct-chat-timestamp float-right" *ngIf="msg.saving">
                  <i class="fas fa-spinner fa-spin"></i> Wysyłanie {{msg.tempId}}
                </span>
              </div>
              <img class="direct-chat-img elevation-3" [src]="msg.createdBy | userAvatar" alt="Message User Image">
              <div *ngFor="let file of msg.files" class="d-block" style="margin: 5px 50px;">
                <a *ngIf="isImage(file)" [href]="fileSrcBig(file)" target="_blank">
                  <img [src]="fileSrcThumb(file)" class="img-rounded"/>
                </a>
                <video *ngIf="isVideo(file)" [src]="fileSrc(file)" controls class="img-rounded"></video>
                <a *ngIf="isFile(file)" class="badge badge-secondary badge-pill text-md d-inline-block p2" (click)="download(file)">
                  <span class="fa-stack">
                    <i class="fas fa-circle fa-stack-2x"></i>
                    <i class="fas fa-file fa-stack-1x fa-inverse text-secondary"></i>
                  </span>
                  {{file.name}}
                </a>
              </div>

              <div class="direct-chat-text" *ngIf="msg.content && msg.content != 'null'">
                <div class="callout text-secondary p-2" *ngIf="msg.replyTo">
                  <div class="text-sm text-mutted">
                    <span>{{msg.replyTo.createdAt | amLocale: 'pl' | amTimeAgo}}</span>&nbsp; <strong>{{msg.replyTo.createdBy.name}}</strong>
                  </div>
                  <div *ngIf="msg.replyTo.content && msg.replyTo.content != 'null'" [innerHtml]="msg.replyTo.content | safeHtml"></div>
                  <div *ngFor="let file of msg.replyTo.files" class="d-block">
                    <a *ngIf="isImage(file)">
                      <img [src]="fileSrcThumb(file)" class="img-rounded"/>
                    </a>
                    <video *ngIf="isVideo(file)" [src]="fileSrc(file)" controls class="img-rounded"></video>
                    <a *ngIf="isFile(file)" class="badge badge-secondary badge-pill text-md d-inline-block p2">
                      <span class="fa-stack">
                        <i class="fas fa-circle fa-stack-2x"></i>
                        <i class="fas fa-file fa-stack-1x fa-inverse text-secondary"></i>
                      </span>
                      {{file.name}}
                    </a>
                  </div>
                </div>
                <div [innerHtml]="msg.content | safeHtml"></div>
              </div>
            </div>

            <div class="direct-chat-infos clearfix  pt-2 text-right"  *ngIf="msg.createdBy?.id != user?.id">
              <a class="text-sm" (click)="reply(msg)">
                <i class="fas fa-reply"></i> odpowiedz
              </a>
            </div>

          </div>
        </div>
      </div>
      <div class="card-footer">
        <form #msgForm="ngForm" (ngSubmit)="onSubmit(msgForm)" (keydown.enter)="onSubmit(msgForm)" class="position-relative">
          <div class="position-relative" *ngIf="replyTo">
            <button class="btn btn-secondary btn-xs p-1 elevation-2 img-circle position-absolute right-0" style="right:0" (click)="reply(null)">
              <i class="fas fa-times fa-fw fa-lg"></i>
            </button>
            <strong><span class="text-muted">Odpowiedz</span> &nbsp; <span>{{replyTo.createdBy.name}}</span></strong>
            <p [innerHtml]="replyTo.content | safeHtml" *ngIf="replyTo.content && replyTo.content != 'null'"></p>
            <div *ngFor="let file of replyTo.files" class="d-block" style="margin: 5px 50px;">
              <a *ngIf="isImage(file)">
                <img [src]="fileSrcThumb(file)" class="img-rounded"/>
              </a>
              <video *ngIf="isVideo(file)" [src]="fileSrc(file)" controls class="img-rounded"></video>
              <a *ngIf="isFile(file)" class="badge badge-secondary badge-pill text-md d-inline-block p2">
                <span class="fa-stack">
                  <i class="fas fa-circle fa-stack-2x"></i>
                  <i class="fas fa-file fa-stack-1x fa-inverse text-secondary"></i>
                </span>
                {{file.name}}
              </a>
            </div>
          </div>
          <div class="input-group">
            <div>
              <a class="btn btn-link position-relative"
                style="padding: 0.375rem 0.16rem 0.375rem 0rem;"
                [class.disabled]="disabled"
                ngbTooltip="Dodaj plik"
                (click)="paperclipClick()">
                <i class="fas fa-paperclip fa-fw"></i>
              </a>
            </div>
            <div>
              <ng-template #emojiPopContent>
                <emoji-mart
                    (emojiClick)="addEmoji($event)"
                    [isNative]="true"
                    [perLine]="8"
                    emoji=""
                    set="facebook"
                    [emojiSize]="20"
                    [emojisToShowFilter]="emojisToShowFilter"
                    [exclude]="'flags'"
                    title="Wybierz"
                    [i18n]="emojiI18n"></emoji-mart>
              </ng-template>
              <a
                #emojiPopoverBtn="ngbPopover"
                class="btn btn-link position-relative"
                style="padding: 0.375rem 0.375rem 0.375rem 0.16rem;"
                [class.disabled]="disabled"
                ngbTooltip="Emoji"
                [ngbPopover]="emojiPopContent"
                popoverClass="emoji-popover"
                [autoClose]="'outside'"
                >
                <i class="far fa-smile fa-fw"></i>
              </a>

            </div>
            <div style="flex: 1 1 auto;">
              <app-editor  #ckEditor [baloon]="true"   class="small-editor" required ngModel name="content"></app-editor>
              <nz-upload
                nzAction="<backendhost>/v1/file"
                [class.d-none]="fileList.length == 0"
                [(nzFileList)]="fileList"
                [nzFilter]="uploadFilter"
                nzListType="picture-card"
                class="mt-2"
              >
                <i class="fas fa-plus-circle fa-3x fa-fw text-primary" #uploadBtn></i>
              </nz-upload>
            </div>
            <div class="ml-2">
              <button (click)="onSubmit(msgForm)" class="btn btn-primary" [disabled]="disabled" ngbTooltip="Wyślij" style="border-radius: 50%;
              aspect-ratio: 1;
              width: 40px;
              height: 40px;
              padding: 0;
              height: 2.5rem;">
                <i class="fas fa-paper-plane fa-fw"></i>
              </button>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>

<!-- <pre>{{messages | async | json}}</pre> -->

<div class="card">
  <div class="card-header d-flex align-items-center">
    <h3 class="card-title m-0 flex-grow-1 text-bold"><i class="fas fa-flask fa-fw"></i> Sloty</h3>
    <!-- <div class="card-tools flex-grow-1">
      <div class="input-group">
        <input type="text" name="table_search" class="form-control float-right" placeholder="Szukaj" #searchInput>
        <button class="btn bg-transparent" style="position:absolute; right: 0; transform: translate(-100%,0);" (click)="clearSearch()">
          <i class="fa fa-times"></i>
        </button>
        <span class="input-group-append">
          <div class="input-group-text bg-transparent"><i class="fas fa-search"></i></div>
        </span>
      </div>
    </div> -->
  </div>
  <div class="table-responsive position-relative">
    <table class="table align-middle table-hover">
      <thead>
        <tr>
          <th style="width:1rem">#</th>
          <th class="sortable" (click)="setSort('type')"  [ngClass]="{'active': (order | async) == 'type'}"  >
            Rodzaj
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <th class="sortable" (click)="setSort('day')"  [ngClass]="{'active': (order | async) == 'day'}"  >
            Dzień
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <th class="sortable" (click)="setSort('startTime')"  [ngClass]="{'active': (order | async) == 'startTime'}"  >
            Początek
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <th class="sortable" (click)="setSort('endTime')"  [ngClass]="{'active': (order | async) == 'endTime'}"  >
            Koniec
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <th colspan="2" class="sortable" (click)="setSort('user')"  [ngClass]="{'active': (order | async) == 'user'}"  >
            Pracownik
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>

          <th>Utworzono</th>
          <th class="text-right" >
            <button  checkRolePermition="outpost.admin" class="btn btn-success btn-sm elevation-2 text-nowrap" [routerLink]="['add']" >
              <i class="fas fa-plus fa-fw fa-lg"></i> Dodaj
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of list | async; let idx = index; trackBy: identify">
          <th class="align-middle">{{idx + 1}}</th>
          <td class="align-middle">{{item.type}}</td>
          <td class="align-middle">{{item.day == "WEEKDAY" ? "Dzień roboczy" : "Sobota"}}</td>
          <td class="align-middle">{{item.startTime}}</td>
          <td class="align-middle">{{item.endTime}}</td>


          <td class="user-panel" style="width:70px" *ngIf="item?.user">
            <img
                  [src]="item.user  | userAvatar: 34"
                  class="img-circle elevation-2"
                  alt="User Image"
                  style="width:45px"
                  [appUserDisactivatable]="item?.user"
              />
          </td>
          <td *ngIf="item?.user">
            <strong [appUserDisactivatable]="item?.user">{{item.user?.firstname}} {{item.user?.lastname}}</strong> <br>
            <small [appUserDisactivatable]="item?.user">{{item.user?.email}}</small>
          </td>
          <td *ngIf="!item?.user" colspan="2">
            - brak -
          </td>
          <td class="align-middle">{{item.createdAt | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
          <td class="align-middle text-nowrap text-right" >
            <button
              class="btn btn-success  btn-sm elevation-2 mr-2"
              *ngIf="item.user == null"
              checkRolePermition="outpost.slot.sign"

              nz-popconfirm
              nzPopconfirmTitle="Czy na pewno chcesz zająć slot?"
              nzOkText="Tak"
              nzCancelText="Nie"
              nzPopconfirmPlacement="left"
              (nzOnConfirm)="sign(item)"
              ngbTooltip="Zajmij slot">
              <i class="fas fa-check"></i>
              Zajmij slot
            </button>
            <button
              class="btn btn-danger btn-sm elevation-2 mr-2"
              *ngIf="item.user != null"
              checkRolePermition="outpost.slot.unsign"
              [instance]="item"
              userProperty="user"

              nz-popconfirm
              nzPopconfirmTitle="Czy na pewno zwolnić slot?"
              nzOkText="Tak"
              nzCancelText="Nie"
              nzPopconfirmPlacement="left"
              (nzOnConfirm)="unsign(item)"
              ngbTooltip="Zwolnij slot"
              >
              <i class="fas fa-check"></i>
              Zwolnij slot
            </button>

            <button
              class="btn btn-primary btn-sm elevation-2 mr-2"
              [routerLink]="[item.id, 'edit']"
              ngbTooltip="Edycja"
              checkRolePermition="outpost.admin">
              <i class="fas fa-edit fa-fw fa-lg"></i>
            </button>

            <button
              class="btn btn-primary btn-sm elevation-2 mr-2"
              [routerLink]="[item.id, 'notification-users']"
              ngbTooltip="Użytkownicy do powiadomienia"
              container="body"
              checkRolePermition="outpost.admin">
              <i class="fas fa-users fa-fw fa-lg"></i>
            </button>

            <button
                checkRolePermition="outpost.admin"
                class="btn btn-danger btn-sm elevation-2"
                nz-popconfirm
                nzPopconfirmTitle="Czy na pewno usunąć?"
                nzOkText="Tak"
                nzCancelText="Nie"
                nzPopconfirmPlacement="left"
                ngbTooltip="Usuń"
                (nzOnConfirm)="delete(item)" >
              <i class="fas fa-trash fa-fw fa-lg"></i>
            </button>

          </td>
        </tr>
      </tbody>
    </table>
    <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(list | async).length == 0"></nz-empty>
    <div class="overlay position-absolute" *ngIf="(loading | async)" style="top: 0; bottom: 0; left: 0; right: 0">
      <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
        <i class="fas fa-3x fa-spinner fa-spin"></i>
        <p>Ładowanie...</p>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="(total | async)"  (pageChange)="pageChange($event)">
      <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationEllipsis>...</ng-template>
      <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>
  </div>
</div>

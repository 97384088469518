import { User } from '@/model/user.model';
import { ComponentFactoryResolver, Directive, ElementRef,  Input, Renderer2, ViewContainerRef } from '@angular/core';
import { UserDisactivatableComponent } from '@components/user-disactivatable/user-disactivatable.component';

@Directive({
  selector: '[appUserDisactivatable]'
})
export class UserDisactivatableDirective {
  @Input()
  set appUserDisactivatable(_user: User) {
		this.user = _user;
	}
  @Input()
  user: User;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
		private renderer: Renderer2,
    private viewContainer: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {

  }

  ngOnInit() {
    if (!this.user || !this.user.hasOwnProperty('active') || this.user.active) return;
    let tagRegEx: RegExp = /<(\w+)/;
    let result = tagRegEx.exec(this.elementRef.nativeElement.outerHTML);
    if(!result)
      return;
    if (result[1] === 'img') {
      this.renderer.addClass(this.elementRef.nativeElement, 'user-inactive-avatar');
    }
    else {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(UserDisactivatableComponent);
      const componentRef =   this.viewContainer.createComponent(componentFactory);

      setTimeout(() => {
        const content = this.elementRef.nativeElement.innerHTML;
        this.elementRef.nativeElement.innerHTML = null;
        this.renderer.addClass(this.elementRef.nativeElement, 'user-inactive');
        this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', 'disabled');

        componentRef.instance.content = content;
        componentRef.instance.tag = result[1];
      })
    }
  }

}

import { Prize, UsersPrize } from '@/model/prize.model';
import { User } from '@/model/user.model';
import { Component, OnInit } from '@angular/core';
import * as fromUsersActions from '@/store/users/users.actions';
import * as fromUsersReducers from '@/store/users/users.reducers';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddPrizeComponent } from '@pages/prizes/add-prize/add-prize.component';
import { AddPrizeModalComponent } from '../add-prize-modal/add-prize-modal.component';

@Component({
  selector: 'app-user-prizes',
  templateUrl: './user-prizes.component.html',
  styleUrls: ['./user-prizes.component.scss']
})
export class UserPrizesComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  id: number;

  constructor(
    private toastr: ToastrService,
    private store: Store<fromUsersReducers.State>,
    private activatedRoute: ActivatedRoute,
    protected modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.pipe(
      map((params: ParamMap) => parseInt(params.get('id')))
    ).subscribe( (id: number) => {
      this.id = id;
      this.store.dispatch(fromUsersActions.fetchOneUser({id: id}));
      // this.store.pipe(takeUntil(this.unsubscribe$), select(fromUsersReducers.selectOneUser, id)).subscribe((user: User | null) => {
      //   if(!user)return;
      //   this.user = user;
      //   let userModel = {...user, ...{ hiredAt: null}};
      //   if (user.hiredAt) {
      //     let hiredAtDate = new Date(user.hiredAt);

      //     userModel.hiredAt = {
      //       year: hiredAtDate.getUTCFullYear(),
      //       month: hiredAtDate.getUTCMonth() + 1,
      //       day: hiredAtDate.getDate()
      //     }

      //   }
      //   if (user.tags) {
      //     this.tags = user.tags.map(x => { return {value : x, display: x}});
      //   }
      //   this.form.patchValue(userModel);
      // })
    })
  }

  get user() : Observable<User> {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromUsersReducers.selectOneUser, this.id),
    );
  }

  get prizes(): Observable<UsersPrize[]> {
    return this.user.pipe(map(user => user?.prizes));
  }

  delete(item){
    this.user.pipe(take(1)).subscribe(user => {
      this.store.dispatch(fromUsersActions.deleteUserPrize({user: user, usersPrize: item}));
    });
  }

  identify(item: Prize, index: number){
    return item.id;
  }

  add() {
    this.user.pipe(take(1)).subscribe(user => {
      const modalRef = this.modalService.open(AddPrizeModalComponent, {size: 'xl',scrollable: false});
      modalRef.componentInstance.user = user;
    });
  }

}

import { PaginationDTO } from "@/model/pagination.model";
import { Invitation, ToInvite, TrainingInvitePack, TrainingNotification, TrainingPackage } from "@/model/package.model";
import { createAction, props } from "@ngrx/store";
import { User } from "@/model/user.model";

export const success = createAction("training-package success");
export const clear = createAction("training-package clear");
export const fetch = createAction("training-package fetch");
export const fetchOne = createAction("training-package fetchOne", props<{id: number}>());
export const saveTrainingPackage = createAction( "training-package saveTrainingPackage",
  props<{item: TrainingPackage, background: any}>()
);
export const cloneTrainingPackage = createAction( "training-package cloneTrainingPackage",
  props<{id: number}>()
);
export const removeTrainingPackage = createAction( "training-package removeTrainingPackage",
  props<{id: number}>()
);
export const archiveTrainingPackage = createAction( "training-package archiveTrainingPackage",
  props<{id: number}>()
);

export const setTrainingPackages = createAction( "training-package setTrainingPackages",
  props<PaginationDTO<TrainingPackage>>()
);
export const createTrainingPackage = createAction( "training-package createTrainingPackage",
  props<{item: TrainingPackage, background: any}>()
);
export const addTrainingPackage = createAction( "training-package addTrainingPackage",
  props<{item: TrainingPackage}>()
);
export const updateTrainingPackage = createAction( "training-package updateTrainingPackage",
  props<{item: TrainingPackage}>()
);
export const deleteTrainingPackage = createAction( "training-package deleteTrainingPackage",
  props<{id: number}>()
);
export const setPage = createAction("training-package setPage", props<{ page: number }>());
export const setOrder = createAction("training-package setOrder", props<{ order: string }>());
export const setDirection = createAction("training-package setDirection", props<{ asc: boolean }>());
export const setSearch = createAction("training-package setSearch", props<{ search: string }>());
export const setArchive = createAction("training-package setArchive", props<{ archive: boolean }>());
export const setOpen = createAction("training-package setOpen", props<{ open: boolean }>());

export const fetchToInvite = createAction(
  "training-package fetchToInvite",
  props<{ package: TrainingPackage, page: number, search?: string }>()
);

export const setToInvite = createAction(
  "training-package setToInvite",
  props<{ package: TrainingPackage, invited: PaginationDTO<ToInvite> }>()
);

export const addUserToInvite = createAction(
  "training-package addUserToInvite",
  props<{ package: TrainingPackage, user: User }>()
);

export const addToInvite = createAction(
  "training-package addToInvite",
  props<{ package: TrainingPackage, toInvite: ToInvite }>()
);

export const deleteToInvite = createAction(
  "training-package deleteToInvite",
  props<{ package: TrainingPackage, toInvite: ToInvite }>()
);

export const removeToInvite = createAction(
  "training-package removeToInvite",
  props<{ package: TrainingPackage, toInvite: ToInvite }>()
);

export const sendInvitations = createAction(
  "training-package sendInvitations",
  props<{ package: TrainingPackage }>()
);

export const fetchInvited = createAction(
  "training-package fetchInvited",
  props<{ package: TrainingPackage, invitePack: TrainingInvitePack, page: number, search?: string }>()
);

export const setInvited = createAction(
  "training-package setInvited",
  props<{ package: TrainingPackage, invited: PaginationDTO<Invitation> }>()
);
export const deleteInvited = createAction(
  "training-package deleteInvited",
  props<{ package: TrainingPackage, invitePack: TrainingInvitePack, invited: Invitation }>()
);
export const removeInvited = createAction(
  "training-package removeInvited",
  props<{ package: TrainingPackage, invited: Invitation }>()
);

export const createNotification = createAction(
  "training-package createNotification",  props<{ package: TrainingPackage }>()
);
export const updateNotification = createAction(
  "training-package updateNotification",  props<{ package: TrainingPackage, notification: TrainingNotification }>()
);
export const deleteNotification = createAction(
  "training-package deleteNotification",  props<{ package: TrainingPackage, notification: TrainingNotification }>()
);

export const addNotification = createAction(
  "training-package addNotification",  props<{ package: TrainingPackage, notification: TrainingNotification }>()
);
export const setNotification = createAction(
  "training-package setNotification",  props<{ package: TrainingPackage, notification: TrainingNotification }>()
);
export const removeNotification = createAction(
  "training-package removeNotification",  props<{ package: TrainingPackage, notification: TrainingNotification }>()
);

export const publishPackage = createAction(
  "training-package publishPackage",  props<{ package: TrainingPackage}>()
);

export const saveInvitationPack = createAction(
  "training-package saveInvitationPack",  props<{ package: TrainingPackage}>()
);

export const fetchParticipants = createAction(
  "training-package fetchParticipants",  props<{ package: TrainingPackage, page: number, search?: string }>()
);

export const setParticipants = createAction(
  "training-package setParticipants",  props<{ package: TrainingPackage, invited: PaginationDTO<Invitation> }>()
);

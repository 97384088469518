<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">

        <div class="card card-secondary">
          <div class="card-header">
            <h3 class="card-title">Ustawienia grupy</h3>
            <!-- <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                <i class="fas fa-minus"></i>
              </button>
            </div> -->
          </div>
          <form [formGroup]="form" (ngSubmit)="save()">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-8">
                  <div class="form-group">
                    <label for="inputName">Nazwa grupy</label>
                    <input formControlName="name" type="text" class="form-control" placeholder="Nazwa grupy"/>
                  </div>
                  <div class="form-group" checkRolePermition="groups.superior.create">
                    <label for="inputName">
                      <input  formControlName="superior"  type="checkbox" [value]="true"/>
                      Grupa nadrzędna
                    </label>
                  </div>
                  <div class="form-group">
                    <label for="inputName">Opis grupy</label>
                    <!-- <editor [init]="tinyMceConfig.init" apiKey="{{tinyMceConfig.apiKey}}"  formControlName="content"></editor> -->
                    <app-editor formControlName="content"></app-editor>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="inputName">Avatar grupy</label>
                    <div style="max-width: 250px;">
                      <app-upload-crop-image  *ngIf="group"
                        [avatar]="true"
                        [aspectRatio]="1 / 1"
                        [image]="group | groupAvatar:250:false"
                        url="<backendhost>/v1/group/{{group.id}}/upload"
                        (nzChange)="handleImageUploadChange($event)"
                      ></app-upload-crop-image>
                      <!-- <nz-upload *ngIf="group"
                        class="avatar-uploader w-100"
                        nzAction="<backendhost>/v1/group/{{group.id}}/upload"
                        nzName="image"
                        nzListType="picture-card"
                        [nzShowUploadList]="false"
                        (nzChange)="handleImageUploadChange($event)"
                      >
                        <ng-container *ngIf="!group.image" class="w-100">
                          <i class="fas fa-3x" [ngClass]="{
                            'fa-upload' : !uploading,
                            'fa-spinner fa-spin' : uploading
                          }"></i>
                          <div class="ant-upload-text">Upload</div>
                        </ng-container>
                        <img *ngIf="group.image" [src]="group | groupAvatar:250:false" style="width: 100%" />
                      </nz-upload> -->
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label for="inputName">Obrazek tła</label>
                    <div style="max-width: 100%;">
                      <app-upload-crop-image  *ngIf="group"
                        [avatar]="false"
                        [aspectRatio]="4 / 1"
                        [image]="group?.background ? (group.background | image) : null"
                        url="<backendhost>/v1/group/{{group.id}}/background"
                        (nzChange)="handleBackgroundUploadChange($event)"
                      ></app-upload-crop-image>
                      <!-- <nz-upload *ngIf="group"
                        class="bg-uploader w-100"
                        nzAction="<backendhost>/v1/group/{{group.id}}/background"
                        nzName="image"
                        nzListType="picture-card"
                        [nzShowUploadList]="false"
                        (nzChange)="handleBackgroundUploadChange($event)"
                      >
                        <ng-container *ngIf="!group.background" class="w-100">
                          <i class="fas fa-3x" [ngClass]="{
                            'fa-upload' : !uploading,
                            'fa-spinner fa-spin' : uploading
                          }"></i>
                          <div class="ant-upload-text">Upload</div>
                        </ng-container>
                        <img *ngIf="group.background" [src]="group.background | image" style="width: 100%" />
                      </nz-upload> -->
                    </div>
                    </div>
                </div>
              </div>
              <div class="car-footer">
                <button type="submit" class="btn btn-success">Zapisz</button>
              </div>
            </div>
          </form>
        </div>
          <!-- <button class="btn btn-success float-right" type="submit">
            Dalej
            <i class="fas fa-angle-right fa-fw"></i>
          </button> -->




        <app-edit-group-members [group]="group" *ngIf="group"></app-edit-group-members>
      </div>
    </div>
  </div>
  <!-- <div class="container-fluid">
      <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header p-2">
                <ul ngbNav #nav="ngbNav" class="nav nav-pills">
                  <li ngbNavItem>
                    <a ngbNavLink>First</a>

<ng-template ngbNavContent>

<form [formGroup]="form" (ngSubmit)="save()">
  <div class="form-group">
    <label for="inputName">Nazwa grupy</label>
    <input
      formControlName="name"
      type="text"
      class="form-control"
      placeholder="Nazwa grupy"
    />
  </div>
  <button class="btn btn-success float-right" type="submit">
    Dalej
    <i class="fas fa-angle-right fa-fw"></i>
  </button>
</form>
</ng-template>
                  </li>
                  <li ngbNavItem>
                    <a ngbNavLink>Second</a>
<ng-template ngbNavContent>
<app-edit-group-members></app-edit-group-members>
</ng-template>
                  </li>
                </ul>
              </div>
              <div class="card-body" [ngbNavOutlet]="nav"></div>
            </div>
          </div>
      </div>
  </div> -->
</section>





import { RedmineSubCategory } from '@/model/redmine.model';
import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { from, Observable, of } from 'rxjs';
import * as fromActions from '@/store/redmine/redmine.category.actions';
import * as fromReducers from '@/store/redmine/redmine.category.reducers';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RedmineSubCategoryResolver implements Resolve<RedmineSubCategory> {
  constructor(
    private store: Store<fromReducers.State>,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RedmineSubCategory> {
    return from( new Promise<RedmineSubCategory>(resolve => {
      let category = 1*route.params.category;
      let id = 1*route.params.id;
      this.store.pipe(
        select(fromReducers.selectCategory, category),
        filter((result) => result != null),
        take(1),
      ).subscribe(instance => {
        resolve(fromReducers.adapterSub.addMany(instance.children, fromReducers.adapterSub.getInitialState()).entities[id]);
      });
    }));
  }
}

import { Survey } from '@/model/survey.model';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject, fromEvent, Observable } from 'rxjs';
import { take, takeUntil, map, filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

import * as fromActions from "@/store/call-center/call-center-questionnaire.actions";
import * as fromReducers from "@/store/call-center/call-center-questionnaire.reducers";
import { CallCenterQuestionnaire } from '@/model/call-center.model';

@Component({
  selector: 'app-list-questionnaire-call-center',
  templateUrl: './list-questionnaire-call-center.component.html',
  styleUrls: ['./list-questionnaire-call-center.component.scss']
})
export class ListQuestionnaireCallCenterComponent implements OnInit {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  searchTerm: string;
  page = 1;
  pageSize = 20;
  constructor(
    protected store: Store<fromReducers.State>,
    protected router: Router,
    protected actionsSubject: ActionsSubject,
    protected toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.store.dispatch(fromActions.clear());
    this.store.dispatch(fromActions.fetch());
    this.store.pipe(
      take(1),
      takeUntil(this.unsubscribe$),
      select(fromReducers.selectState),
      map(state => state.search)
    ).subscribe((search:string) => {
      this.searchInput.nativeElement.value = search
    });
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      takeUntil(this.unsubscribe$),
      map((event: any) => event.target.value),
      filter( (res:string) => res.length > 2),
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((text: string) => {
      this.store.dispatch(fromActions.setSearch({search: text.toLowerCase().trim()}));
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  clearSearch() {
    this.searchInput.nativeElement.value = '';
    this.store.dispatch(fromActions.setSearch({search: null}));
  }

  get list(): Observable<CallCenterQuestionnaire[]> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectAllQuestionnaires));
  }

  delete(item: CallCenterQuestionnaire): void {
    this.store.dispatch(fromActions.deleteQuestionnaire({id: item.id}));
  }

  identify(index, entity: CallCenterQuestionnaire){ return entity.id }

  get total(): Observable<number>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromReducers.selectState),
      map( (state:fromReducers.State)  => state.total)
    );
  }

  get direction(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromReducers.selectState),
      map( (state:fromReducers.State)  => state.asc),
      tap( asc => this.asc = asc)
    );
  }

  get loading(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromReducers.selectState),
      map( (state:fromReducers.State)  => state.loading),
    );
  }

  get saving(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromReducers.selectState),
      map( (state:fromReducers.State)  => state.saving),
    );
  }

  get order(): Observable<string>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromReducers.selectState),
      map( (state:fromReducers.State)  => state.order),
      tap( sort => this.sort = sort)
    );
  }

  private sort: string;
  private asc: boolean;
  setSort(sort: string): void {
    if(sort == this.sort) {
      this.store.dispatch(fromActions.setDirection({asc: !this.asc}));
    } else {
      this.store.dispatch(fromActions.setDirection({asc: false }));
    }
    this.store.dispatch(fromActions.setOrder({order: sort}));
  }

  toggleDirection(): void {
    this.store.dispatch(fromActions.setDirection({asc: !this.asc}));
  }

  pageChange(page): void {
    this.store.dispatch(fromActions.setPage({page: page}));
  }

  countQuestions(item: CallCenterQuestionnaire): number {
    return  item.categories.map(cat => cat.questions.length).reduce((a,b)=> a+b, 0);
  }
}

<li class="nav-item dropdown ml-2">
  <a class="nav-link position-relative elevation-2 img-circle img-size-40 bg-white" data-toggle="dropdown" (click)="toggleDropdownMenu()">
      <i class="far fa-comments fa-lg"></i>
      <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-orange" *ngIf="count | async">{{count | async}}</span>
  </a>
  <div #dropdownMenu class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
    <a (click)="hideDropdownMenu()"
      [routerLink]="['/chat']"
      class="dropdown-item dropdown-footer text-center text-dark">
      Wszystkie konwersacje
    </a>
    <div class="dropdown-divider"></div>
    <a class="dropdown-item" *ngFor="let item of latest | async" [routerLink]="['/chat', item.id]" (click)="hideDropdownMenu()">
        <!-- Message Start -->
        <div class="media">
            <div class="img-size-50 mr-3 flex-shrink-0">
              <div class="position-relative">
                <img [src]="item | groupAvatar" class="img-size-50 img-circle"/>
                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary mr-3 mt-2" *ngIf="item.unseenCount">
                  {{item.unseenCount }}
                </span>
              </div>
            </div>
            <div class="media-body">
                <h3 class="dropdown-item-title text-dark text-decoration-line-through" *ngIf="!item.name && item.type == 'direct'">
                  Użytkownik usunięty
                </h3>
                <h3 class="dropdown-item-title text-primary" *ngIf="item.name || item.type != 'direct'">
                  {{item.name}}
                  <!-- <span class="badge badge-info" *ngIf="item.unseenCount">{{item.unseenCount }}</span> -->
                </h3>
                <p class="text-md text-truncate text-dark preview">
                  <span *ngIf="item.lastMessage.short" [innerHtml]="item.lastMessage.short | safeHtml"></span>
                  <span *ngIf="!item.lastMessage.short && item.lastMessage.files?.length">przesyła plik</span>
                </p>
                <p class="text-sm text-muted text-nowrap">
                    <i class="far fa-clock mr-1"></i> {{item.lastMessage.createdAt | amLocale:'pl' | amCalendar}}
                </p>
            </div>
        </div>
    </a>
    <div class="dropdown-divider"></div>
    <a (click)="hideDropdownMenu()"
      [routerLink]="['/chat']"
      class="dropdown-item dropdown-footer text-center text-dark">
      Wszystkie konwersacje
    </a>
  </div>
</li>

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject, fromEvent, Observable } from 'rxjs';
import { take, takeUntil, map, filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

import * as fromOutpostActions from '@/store/outpost/outposts.actions';
import * as fromOutpostReducers from '@/store/outpost/outposts.reducers';
import { Outpost } from '@/model/outpost.model';

@Component({
  selector: 'app-list-outposts',
  templateUrl: './list-outposts.component.html',
  styleUrls: ['./list-outposts.component.scss']
})
export class ListOutpostsComponent implements OnInit {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  searchTerm: string;
  page = 1;
  pageSize = 50;
  constructor(
    protected store: Store<fromOutpostReducers.State>,
    protected router: Router,
    protected actionsSubject: ActionsSubject,
    protected toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.store.dispatch(fromOutpostActions.clear());
    this.store.dispatch(fromOutpostActions.fetch());
    this.store.pipe(
      take(1),
      takeUntil(this.unsubscribe$),
      select(fromOutpostReducers.selectState),
      map(state => state.search)
    ).subscribe((search:string) => {
      this.searchInput.nativeElement.value = search
    });
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      takeUntil(this.unsubscribe$),
      map((event: any) => event.target.value),
      filter( (res:string) => res.length > 2),
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((text: string) => {
      this.store.dispatch(fromOutpostActions.setSearch({search: text.toLowerCase().trim()}));
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  clearSearch() {
    this.searchInput.nativeElement.value = '';
    this.store.dispatch(fromOutpostActions.setSearch({search: null}));
  }

  get list(): Observable<Outpost[]> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromOutpostReducers.selectAllOutposts));
  }

  delete(item: Outpost): void {
    this.store.dispatch(fromOutpostActions.deleteOutpost({id: item.id}));
  }

  identify(index, entity: Outpost){ return entity.id }

  get total(): Observable<number>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromOutpostReducers.selectState),
      map( (state:fromOutpostReducers.State)  => state.total)
    );
  }

  get direction(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromOutpostReducers.selectState),
      map( (state:fromOutpostReducers.State)  => state.asc),
      tap( asc => this.asc = asc)
    );
  }

  get loading(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromOutpostReducers.selectState),
      map( (state:fromOutpostReducers.State)  => state.loading),
    );
  }

  get order(): Observable<string>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromOutpostReducers.selectState),
      map( (state:fromOutpostReducers.State)  => state.order),
      tap( sort => this.sort = sort)
    );
  }

  private sort: string;
  private asc: boolean;
  setSort(sort: string): void {
    if(sort == this.sort) {
      this.store.dispatch(fromOutpostActions.setDirection({asc: !this.asc}));
    } else {
      this.store.dispatch(fromOutpostActions.setDirection({asc: false }));
    }
    this.store.dispatch(fromOutpostActions.setOrder({order: sort}));
  }

  toggleDirection(): void {
    this.store.dispatch(fromOutpostActions.setDirection({asc: !this.asc}));
  }

  pageChange(page): void {
    this.store.dispatch(fromOutpostActions.setPage({page: page}));
  }

}


import { AuthService } from '@/auth/auth.service';
import { ChatMessage, Conversation } from '@/model/chat.model';
import { Group } from '@/model/group.model';
import { User } from '@/model/user.model';
import * as  fromChatActions from '@/store/chat/chat.actions';
import * as  fromChatReducers from '@/store/chat/chat.reducers';
import { plainText } from '@/utils/plainText';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { switchMap, catchError, map } from 'rxjs/operators';
import { DefaultEffects } from '../default/default.effects';
import { ErrorState } from '../error.store';


@Injectable()
export class ChatEffects extends DefaultEffects{
  private user: User;
  constructor(
    private actions$: Actions,
    private store: Store<fromChatReducers.State>,
    httpClient: HttpClient,
    toastr: ToastrService,
    errorStore: Store<ErrorState>,
    authService: AuthService
  ) {
    super(httpClient, toastr, errorStore);
    authService.getUser().then(user => {
      this.user = user;
    })
  }



  fetchRecentConversations$ = createEffect(() => this.actions$.pipe(
    ofType(fromChatActions.fetchRecentConversations),
    switchMap((action) => {
      return this.httpClient.get<Conversation[]>('<backendhost>/v1/chat', {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    map((response: any) => fromChatActions.addConvertsations({payload: response}))
  ));

  fetchMessages$ = createEffect(() => this.actions$.pipe(
    ofType(fromChatActions.fetchMessages),
    switchMap((action) => {
      return this.httpClient.get<fromChatActions.GetMessagesDTO>(`<backendhost>/v1/chat/conversation/${action.conversation}`, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    map((response: fromChatActions.GetMessagesDTO) => fromChatActions.addMessagesToConvertsations({
      conversation: response.conversation,
      messages: response.messages,
      total: response.total
    }))
  ));

  createNewMessage$ = createEffect(() => this.actions$.pipe(
    ofType(fromChatActions.createNewMessage),
    switchMap((action) => {
      let url = `<backendhost>/v1/chat/conversation/${action.conversation}`;
      let payload = {
          content: action.content,
          files: action.files,
          tempId: Math.floor(Math.random() * ((Number.MAX_SAFE_INTEGER-1)/2)) + Number.MAX_SAFE_INTEGER,
          short: null,
          replyTo: action.replyTo ? action.replyTo.id : null
      };
      if (payload.content) {
        payload.content = payload.content.replace('</p>', '<br>');
        payload.content = payload.content.replace('<p>', '');
        payload.content = payload.content.replace('<br>&nbsp;<br>', '');
        payload.content = payload.content.replace('<br><p>&nbsp;</p>', '');

        if (payload.content) {
          payload.short = plainText(payload.content);
        }
      }

      let tempMsp: ChatMessage = {
        ...payload,
        ...{
          id: null,
          createdAt: moment().toISOString(),
          createdBy: this.user,
          saving: true,
          conversation: {
            id: action.conversation,
            name: "",
            image: ""
          }
        }
      };
      // this.store.dispatch(fromChatActions.creatTempMessage({msg: tempMsp}));
      return this.httpClient.post<ChatMessage>(url, payload, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: ChatMessage) => [
      // fromChatActions.removeMessageFromConvertsations({id: response.tempId, conversation: response.conversation.id}),
      fromChatActions.addMyMessageToConvertsations({msg: response})
    ])
  ));

  createNewMessageAndConverrsation$ = createEffect(() => this.actions$.pipe(
    ofType(fromChatActions.createNewMessageAndConverrsation),
    switchMap((action) => {
      let url = `<backendhost>/v1/chat`;
      let payload = {
        content: action.content,
        to: action.to,
        files: action.files,
        short: null,
      };
      if ( payload.content) {
        payload.content = payload.content.replace('</p>', '<br>');
        payload.content = payload.content.replace('<p>', '');
        payload.content = payload.content.replace('<br>&nbsp;<br>', '');
        payload.content = payload.content.replace('<br><p>&nbsp;</p>', '');

        if(payload.content) {
          payload.short = plainText(payload.content);
        }
      }
      return this.httpClient.post<ChatMessage>(url, payload, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    map((response: ChatMessage) => fromChatActions.addOneConvertsationWithMessage({msg: response}))
  ));

  createNewRoom$ = createEffect(() => this.actions$.pipe(
    ofType(fromChatActions.createNewRoom),
    switchMap((action) => {
      let url = `<backendhost>/v1/chat/room`;
      let payload = { name: action.name};
      return this.httpClient.post<Conversation>(url, payload, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    map((response: Conversation) => fromChatActions.addEmptyConversation({conversation: response}))
  ));

  fetchRoom$ = createEffect(() => this.actions$.pipe(
    ofType(fromChatActions.fetchRoom),
    switchMap((action) => {
      let url = `<backendhost>/v1/chat/room/${action.id}`;
      return this.httpClient.get<Conversation>(url, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    map((response: Conversation) => fromChatActions.addConvertsations({payload: [response]}))
  ));

  addMemberToRoom$ = createEffect(() => this.actions$.pipe(
    ofType(fromChatActions.addMemberToRoom),
    switchMap((action) => {
      let url = `<backendhost>/v1/chat/room/${action.room.id}/member/${action.user.id}`;
      return this.httpClient.post<Conversation>(url,{ }, { observe: 'body', responseType: 'json'});
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: Conversation) => [])
  ));

  deleteMemberFromRoom$ = createEffect(() => this.actions$.pipe(
    ofType(fromChatActions.deleteMemberFromRoom),
    switchMap((action) => {
      let url = `<backendhost>/v1/chat/room/${action.room.id}/member/${action.user.id}`;
      return this.httpClient.delete<Conversation>(url, { observe: 'body', responseType: 'json'});
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: Conversation) => [])
  ));

  saveRoom$ = createEffect(() => this.actions$.pipe(
    ofType(fromChatActions.saveRoom),
    switchMap((action) => {
      let url = `<backendhost>/v1/chat/room/${action.conversation.id}`;
      return this.httpClient.put<Conversation>(url,action.conversation, { observe: 'body', responseType: 'json'});
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: Conversation) => [fromChatActions.updateRoom({conversation: response})])
  ));

  deleteRoom$ = createEffect(() => this.actions$.pipe(
    ofType(fromChatActions.deleteRoom),
    switchMap((action) => {
      let url = `<backendhost>/v1/chat/room/${action.conversation.id}`;
      return this.httpClient
        .delete<Conversation>(url, { observe: 'body', responseType: 'json'})
        .pipe(map(()=> action.conversation));
    }),
    catchError(this.onError.bind(this)),
    switchMap((conversation: Conversation) => [fromChatActions.removeRoom({conversation})])
  ));
}

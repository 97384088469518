import { SlideType, Slide, Multimedia } from '@/model/multimedia.model';
import { Scorm } from '@/model/scorm.model';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import * as fromScormActions from '@/store/scorm/scorm.actions';
import * as fromScormReducers from '@/store/scorm/scorm.reducers';
import * as fromQuizActions from '@/store/quiz/quiz.actions';
import * as fromQuizReducers from '@/store/quiz/quiz.reducers';
import { select, Store } from '@ngrx/store';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { youtubeValidator } from '@/utils/youtube.validator';
import { HttpClient } from '@angular/common/http';
import { SlideService } from '@services/slide.service';
import { ToastrService } from 'ngx-toastr';
import { Quiz } from '@/model/quiz.model';
import { environment } from 'environments/environment';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-multimedia-slide',
  templateUrl: './multimedia-slide.component.html',
  styleUrls: ['./multimedia-slide.component.scss']
})
export class MultimediaSlideComponent implements OnInit {
  SlideType = SlideType;
  slide: Slide;
  multimedia: Multimedia;
  form: FormGroup;
  loading = false;
  get scorms$(): Observable<Scorm[]> {
    return this.scormStore.pipe(select(fromScormReducers.selectAllScorm));
  }
  scormInput$ = new Subject<string>();

  get quizes$(): Observable<Quiz[]> {
    return this.quizStore.pipe(select(fromQuizReducers.selectAllQuiz));
  }
  quizInput$ = new Subject<string>();

  constructor(
    public modal: NgbActiveModal,
    protected scormStore: Store<fromScormReducers.State>,
    protected quizStore: Store<fromQuizReducers.State>,
    protected slideService: SlideService,
    protected toastr: ToastrService
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {
    this.scormStore.dispatch(fromScormActions.fetch());
    this.scormInput$
      .pipe(distinctUntilChanged(),debounceTime(500))
      .subscribe((search) => this.scormStore.dispatch(fromScormActions.setSearch({search})));

    this.quizStore.dispatch(fromQuizActions.fetch());
    this.quizInput$
      .pipe(distinctUntilChanged(),debounceTime(500))
      .subscribe((search) => this.quizStore.dispatch(fromQuizActions.setSearch({search})));
  }

  trackByFn(item: any) {
    return item.id;
  }

  setSlide(slide: Slide) {
    this.slide = slide;
    switch (slide.type) {
      case 'content':
        this.form.addControl('content', new FormControl(null, Validators.required));
        this.form.addControl('completeTimeout', new FormControl(environment.slideCompleteTimeout*1e-3, Validators.required));
        break;
      case 'scorm':
        this.form.addControl('scorm', new FormControl(null, Validators.required));
        break;
      case 'youtube':
        this.form.addControl('url', new FormControl(null, [Validators.required, youtubeValidator]));
        this.form.addControl('completeTimeout', new FormControl(environment.slideCompleteTimeout*1e-3, Validators.required));
        break;
      case 'video':
        // this.form.addControl('video', new FormControl(null, [Validators.required, youtubeValidator]));
        this.form.addControl('completeTimeout', new FormControl(environment.slideCompleteTimeout*1e-3, Validators.required));
        break;
      case 'quiz':
        this.form.addControl('quiz', new FormControl(null, [Validators.required]));
        break;
    }
    this.form.patchValue(slide);
  }

  save(): void {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
      // input.addAsyncValidators()
    });
    if (this.form.valid) {

      this.slide = {...this.slide, ...this.form.value};
      let file = this.videoFile.length > 0 ? this.videoFile[0] : null;
      let audioFile = this.audioFile.length > 0 ? this.audioFile[0] : null;
      console.log(audioFile);
      this.slideService.save(this.slide, this.multimedia, file as any, audioFile as any).subscribe((slide) => {
        if(slide)
          this.modal.close(slide);
      });
    } else {
          this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }

  videoFile: NzUploadFile[] = [];
  beforeUpload = (file: NzUploadFile): boolean => {
    this.videoFile = [file];
    return false;
  };

  get videoPath() {
    return `${environment.apiUrl}/v1/video/${this.slide.video.name}`;
  }

  audioFile: NzUploadFile[] = [];
  beforeUploadAudio = (file: NzUploadFile): boolean => {
    console.log(file);
    this.audioFile = [file];
    return false;
  };

  get audioPath() {
    // return ;
    return [{
      src: `${environment.apiUrl}/${this.slide.audio.path}`,
      type:this.slide.audio.mimeType
    }]
  }
}

import { Component, OnInit } from '@angular/core';
import * as fromVacantActions from '@/store/vacant/vacant.toconfirm.actions';
import * as fromVacantReducers from '@/store/vacant/vacant.toconfirm.reducers';
import { Observable, Subject } from 'rxjs';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { VacantPack } from '@/model/vacant.model';
import { map, takeUntil, tap } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { SlotType } from '@/model/slot.model';

@Component({
  selector: 'app-list-to-confirm',
  templateUrl: './list-to-confirm.component.html',
  styleUrls: ['./list-to-confirm.component.scss']
})
export class ListToConfirmComponent implements OnInit {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  // @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  // searchTerm: string;
  SlotType = Object.values(SlotType);
  page = 1;
  pageSize = 20;
  filterForm: FormGroup;
  constructor(
    protected store: Store<fromVacantReducers.State>,
    protected router: Router,
    protected actionsSubject: ActionsSubject,
    protected activatedRoute: ActivatedRoute,
    protected toastr: ToastrService
  ) {
    this.filterForm = new FormGroup({
      type: new FormControl(null),
      outpost: new FormControl(null),
      date: new FormControl(null/*,[this.DateTimeValidator.bind(this)]*/),
    });
  }

  ngOnInit(): void {
    this.store.dispatch(fromVacantActions.clear());
    this.store.dispatch(fromVacantActions.fetch());

  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  get list(): Observable<VacantPack[]> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromVacantReducers.selectCurrent));
  }

  identify(index, entity: VacantPack){ return entity.id }

  get total(): Observable<number>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromVacantReducers.selectState),
      map( (state:fromVacantReducers.State)  => state.total)
    );
  }

  get direction(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromVacantReducers.selectState),
      map( (state:fromVacantReducers.State)  => state.asc),
      tap( asc => this.asc = asc)
    );
  }

  get loading(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromVacantReducers.selectState),
      map( (state:fromVacantReducers.State)  => state.loading),
    );
  }

  get order(): Observable<string>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromVacantReducers.selectState),
      map( (state:fromVacantReducers.State)  => state.order),
      tap( sort => this.sort = sort)
    );
  }

  private sort: string;
  private asc: boolean;
  setSort(sort: string): void {
    if(sort == this.sort) {
      this.store.dispatch(fromVacantActions.setDirection({asc: !this.asc}));
    } else {
      this.store.dispatch(fromVacantActions.setDirection({asc: false }));
    }
    this.store.dispatch(fromVacantActions.setOrder({order: sort}));
  }

  toggleDirection(): void {
    this.store.dispatch(fromVacantActions.setDirection({asc: !this.asc}));
  }

  pageChange(page): void {
    this.store.dispatch(fromVacantActions.setPage({page: page}));
  }

  confirm(vacantPack: VacantPack) {
    this.store.dispatch(fromVacantActions.confirm({vacantPack}));
  }

  reject(vacantPack: VacantPack) {
    this.store.dispatch(fromVacantActions.reject({vacantPack}));
  }
}

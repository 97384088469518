import { tinyMceConfig } from '@/config/tinymce.config';
import { User } from '@/model/user.model';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppService } from '@services/app.service';
import { merge, Observable, of, OperatorFunction, pipe, Subject } from 'rxjs';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import * as fromGroupReducer from '@/store/group/group.reducers';
import * as fromGroupActions from '@/store/group/group.actions';

import * as fromOfferReducer from '@/store/offer/offers.reducers';
import * as fromOfferActions from '@/store/offer/offer.actions';

import { Offer, OfferCategory } from '@/model/offer.model';
import { Group } from '@/model/group.model';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { HttpClient } from '@angular/common/http';
import { ofType } from '@ngrx/effects';
import * as fromOfferCategoryReducers from '@/store/offer-category/offer.category.reducers';
// import { ckeConfig } from '@/config/cke.config';

@Component({
  selector: 'app-edit-offer',
  templateUrl: './edit-offer.component.html',
  styleUrls: ['./edit-offer.component.scss']
})
export class EditOfferComponent implements OnInit {
  @ViewChild('instance', {static: true}) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  offer: Offer ={
    id: null,
    title: null,
    content: null,
    tags: [],
    category: []
  }


  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  tinyMceConfig = tinyMceConfig;
  typeaheadModel: any;
  typeaheadList: Group[] = [];
  formatter = (group: Group) => `${group.name}`;
  formatterEmpty = () => null;
  groups: Group[];
  previewImage: string | undefined = '';
  previewVisible = false;
  fileList: NzUploadFile[] = [];
  backgroundFile: NzUploadFile;
  @Output() fileListChange: EventEmitter<NzUploadFile[]> = new EventEmitter<NzUploadFile[]>();
  categories: OfferCategory[] = [];

  constructor(
    private store: Store<fromOfferReducer.State>,
    private categoryStore: Store<fromOfferCategoryReducers.State>,
    private toastr: ToastrService,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activeRoute.parent.parent.data.subscribe(data => {
      this.categories = data.categories;
    });
    this.categoryStore.pipe(
      takeUntil(this.unsubscribe$),
      select(fromOfferCategoryReducers.selectAllOfferCategory)
    ).subscribe(categories => {
      this.categories = categories;
    });
    // this.store.dispatch(fromGroupActions.fetchAllAction());
    // this.store.pipe(select(fromGroupReducer.selectAllGroups)).subscribe( (groups: Group[]) => {
    //   this.groups = groups;
    // });
    this.form = new FormGroup({
      title: new FormControl(null, Validators.required),
      content: new FormControl(null, Validators.required),
      category: new FormControl(null, Validators.required),
      // groups: new FormArray([], null),
      startDate:  new FormControl(
        {
          value: new Date(),
          disabled:true
        }, {
          validators: [
            this.DateTimeValidator.bind(this)
          ]
        }
      ),
      endDate: new FormControl(
        {
          value: new Date(),
          disabled:true
        }, {
          validators: [
            this.DateTimeValidator.bind(this)
          ]
        }
      ),
    });
    this.activeRoute.paramMap.pipe(
      map((params: ParamMap) => parseInt(params.get('id')))
    ).subscribe( (id?: number) => {
      this.store.dispatch(fromOfferActions.fetchOne({id}));
      this.store.pipe(select(fromOfferReducer.selectOfferById, id)).subscribe (offer => {
        this.offer = offer;
        this.form.patchValue({
          ...offer,
          ...{
            category: offer.category.map(cat => cat.id)
          }
        });
      })
    })
  }



  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get saving(): Observable<boolean> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromOfferReducer.selectSaving));
  }

  get loading(): Observable<boolean> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromOfferReducer.selectLoadding));
  }

  save(): void {
    if (this.offer.startDateActive) {
      this.form.get('startDate').enable();
    } else {
      this.form.get('startDate').disable();
    }
    if (this.offer.endDateActive) {
      this.form.get('endDate').enable();
    } else {
      this.form.get('endDate').disable();
    }
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });

    if (this.form.valid) {

      let offer = {...this.offer, ...this.form.value, ...{
        startDate : this.offer.startDateActive ? this.form.value.startDate.toLocaleString('pl-PL') : null,
        endDate : this.offer.endDateActive ? this.form.value.endDate.toLocaleString('pl-PL') : null
      }};
      // console.log(offer);
      // return;
      this.store.dispatch(fromOfferActions.saveOne({offer: offer, background: this.backgroundFile as any}));
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromOfferActions.updateOne)
      ).subscribe( (_) => {
        this.toastr.success('Zapisano zmiany!');
        this.router.navigate(['/offer']);
      });
    } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }

  // search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => text$.pipe(
  //     debounceTime(300),
  //     distinctUntilChanged(),
  //     switchMap((term: string) => this.appService.searchUser(term).pipe())
  // )
  search: OperatorFunction<string, readonly Group[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(takeUntil(this.unsubscribe$), debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      takeUntil(this.unsubscribe$),
      map(term => (term === '' ? this.groups : this.groups.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))),
      map( (arr:Group[]) => arr.filter(item => this.typeaheadList.map(x => x.id).indexOf(item.id) === -1)),
      map( (arr:Group[]) => arr.slice(0, 10))
    );
  }

  get groupCtrl(): FormArray {
    return this.form.get('groups') as FormArray;
  }

  onTypeaheadSelect(selectedItem: NgbTypeaheadSelectItemEvent) {
    let group: Group = selectedItem.item;
    if (this.typeaheadList.map(x => x.id).indexOf(selectedItem.item.id) !== -1)return;
    this.typeaheadList.push(group);
    this.groupCtrl.push(new FormControl(group.id))
  }

  removeGroup(item: Group) {
    let idx = this.typeaheadList.map(it => it.id).indexOf(item.id);
    this.typeaheadList.splice(idx, 1);
    this.groupCtrl.removeAt(idx);
  }

  groupIdentity(index, item: Group) {
    return item.id;
  }



  DateTimeValidator = (fc: FormControl) => {
    const date = new Date(fc.value);
    const isValid = !isNaN(date.valueOf());
    return isValid ? null : {
        isValid: {
            valid: false
        }
    };
  };
  beforeUpload = (file: NzUploadFile): boolean => {
    getBase64(file as any).then( (preview: string) => {
      file.url = preview;
      file.status = 'done';
      // file.percent = 50;
      this.fileList = this.fileList.concat(file);
    })
    return false;
  };

  handlePreview = async (file: NzUploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  };

  beforeUploadBackground = (file: NzUploadFile): boolean => {
    getBase64(file as any).then( (preview: string) => {
      this.backgroundFile  = file;
      this.offer = {...this.offer, ...{background: preview}};
    })
    return false;
  };
}

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});


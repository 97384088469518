import { dataURLtoFile } from '@/utils/base64';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { BehaviorSubject } from 'rxjs';

export interface CropPopupResult {
  preview: string,
  file: File
}

@Component({
  selector: 'app-crop-popup',
  templateUrl: './crop-popup.component.html',
  styleUrls: ['./crop-popup.component.scss']
})
export class CropPopupComponent implements OnInit {
  aspectRatio: any;
  round = false;
  preview: any;
  result: CropPopupResult;
  onCrop: BehaviorSubject<CropPopupResult> = new BehaviorSubject(null);

  constructor(
    public modal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  imageCropped(event: ImageCroppedEvent) {
    this.result = {preview: event.base64, file: dataURLtoFile(event.base64, 'crop.png')};
  }

  save() {
    this.onCrop.next(this.result);
    this.modal.dismiss();
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Action, ActionsSubject, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import * as fromGroupReducer from '@/store/group/group.reducers';
import * as fromGroupActions from '@/store/group/group.actions';
import { filter, takeUntil } from 'rxjs/operators';
import { TypedAction } from '@ngrx/store/src/models';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthService } from '@/auth/auth.service';
import { User } from '@/model/user.model';

@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.scss']
})
export class AddGroupComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  user: User;
  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private store: Store<fromGroupReducer.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router
  ) {
    this.authService.getUser().then( (user: User) => {
      this.user = user;
    })
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      superior: new FormControl(null),
    });
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  save(): void {
    if (this.form.valid) {
      this.store.dispatch(fromGroupActions.createNewGroupAction(this.form.value));
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        filter((action) => action.type === fromGroupActions.addGroupsAction.type)
      ).subscribe( (result: any) => {
        this.toastr.success('Utworzono nową grupę!');
        this.router.navigate(['/groups', result.payload[0].id, 'edit']);
      });
  } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
  }
  }

}

import { Prize } from "@/model/prize.model";
import { User } from "@/model/user.model";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { environment } from "environments/environment";
import * as fromActions from "./prizes.actions";


export interface PrizePagination {
  total: number;
  data: Prize[];
}

export interface State {
  loading: boolean;
  saving: boolean;
  list: EntityState<Prize>;
  total: number;
  page: number;
  order: string;
  readonly limit: number;
  asc: boolean;
  search: string;
  user?: User
}

export const adapter: EntityAdapter<Prize> = createEntityAdapter<Prize>({
  // sortComparer: (a: Post, b: Post) => a.id < b.id ? 1 : 0
});

export const initialState: State = {
  loading: false,
  saving: false,
  list: adapter.getInitialState(),
  page: 1,
  total: 0,
  order: 'id',
  limit: environment.pageSize,
  asc: true,
  search: null
}

export const reducer = createReducer(
  initialState,
  on(fromActions.reset, (state, action) => {
    return initialState
  }),
  on(fromActions.fetchOnePrize, (state, action) => {
    return {...state, ...{ loading: true }}
  }),
  on(fromActions.fetchPrizes, (state, action) => {
    return {...state, ...{ loading: true }}
  }),
  on(fromActions.addPrizes, (state, action) => {
    let list = adapter.addMany(action.data, state.list);
    return {...state, ...{
      total: action.total,
      loading: false,
      saving: false,
      list: list
    }}
  }),
  on(fromActions.replacePrizes, (state, action) => {
    let list = adapter.removeAll(state.list);
    list = adapter.addMany(action.data, list);
    return {...state, ...{
      total: action.total,
      loading: false,
      saving: false,
      list: list
    }}
  }),

  on(fromActions.clearSaving, (state, action) => {
    return {...state, ...{ saving: false }}
  }),
  on(fromActions.create, (state, action) => {
    return {...state, ...{ saving: true }}
  }),
  on(fromActions.edit, (state, action) => {
    return {...state, ...{ saving: true }}
  }),

  on(fromActions.setPage, (state, action) => {
    return {...state, ...{ page: action.page }}
  }),
  on(fromActions.setSearch, (state, action) => {
    return {...state, ...{ search: action.search }}
  }),
  on(fromActions.setOrder, (state, action) => {
    return {...state, ...{ order: action.order }}
  }),
  on(fromActions.setDirection, (state, action) => {
    return {...state, ...{ direction: action.direction }}
  }),
  on(fromActions.setUser, (state, action) => {
    return {...state, ...{ user: action.user }}
  }),
  on(fromActions.add, (state, action) => {
    let list = adapter.addOne(action.prize, state.list);
    return {...state, ...{
      loading: false,
      saving: false,
      list: list
    }}
  }),
  on(fromActions.remove, (state, action) => {
    let list = adapter.removeOne(action.prize.id, state.list);
    return {...state, ...{
      loading: false,
      saving: false,
      list: list
    }}
  }),
  on(fromActions.update, (state, action) => {
    let list = adapter.updateOne({ id: action.prize.id, changes: action.prize}, state.list);
    return {...state, ...{
      loading: false,
      saving: false,
      list: list
    }}
  }),

)
export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectPrizesState = createFeatureSelector<State>('prizes');

export const selectAllPrizes = createSelector(selectPrizesState, (state) => selectAll(state.list) )
export const selectCount= createSelector(selectPrizesState, (state) => state.total )
export const selectPageSize = createSelector(selectPrizesState, (state) => state.limit )
export const selectOne = createSelector(selectPrizesState, (state: State, id: number) => {
  return state.list.entities[id];
})

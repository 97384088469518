import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import * as fromRedmineIssueActions from '@/store/redmine/redmine.issue.actions';
import * as fromRedmineIssueReducers from '@/store/redmine/redmine.issue.reducers';
import { fromEvent, Observable, of, Subject } from 'rxjs';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { RedmineIssue } from '@/model/redmine.model';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '@/auth/auth.service';
import { User } from '@/model/user.model';
import { FormControl, FormGroup } from '@angular/forms';

import * as fromNotificationReducers from '@/store/notification/notification.reducers';
@Component({
  selector: 'app-redmine-list-issues',
  templateUrl: './redmine-list-issues.component.html',
  styleUrls: ['./redmine-list-issues.component.scss']
})
export class RedmineListIssuesComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  searchTerm: string;
  me: User;
  page = 1;
  pageSize = 20;
  form: FormGroup;
  constructor(
    protected store: Store<fromRedmineIssueReducers.State>,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected actionsSubject$: ActionsSubject,
    protected authService: AuthService,
    protected storeNotification: Store<fromNotificationReducers.State>,
  ) {

    this.form = new FormGroup({
      status: new FormControl("open"),
      invitationRangeOn: new FormControl(false),
      invitationRange: new FormControl(null,[this.DateTimeValidator.bind(this)]),
      completedRangeOn: new FormControl(false),
      completedRange: new FormControl(null,[this.DateTimeValidator.bind(this)]),

      endDateRangeOn: new FormControl(false),
      endDateRange: new FormControl(null,[this.DateTimeValidator.bind(this)]),


    });
    this.form.controls.status.valueChanges.subscribe(status => {
      this.store.dispatch(fromRedmineIssueActions.setStatus({status}));
    })
    this.authService.getUser().then(user => {
      this.me = user;
    })
  }

  DateTimeValidator = (fc: FormControl) => {
    if(!fc?.value) {
      return null;
    }
    const date1 = new Date(fc?.value[0]);
    const date2 = new Date(fc?.value[1]);
    const isValid = !isNaN(date1.valueOf()) && !isNaN(date2.valueOf());
    return isValid ? null : {dateNotValid: true};
  };

  ngOnInit(): void {
    this.store.dispatch(fromRedmineIssueActions.clear());
    this.activatedRoute.data.subscribe(data => {
      this.store.dispatch(fromRedmineIssueActions.setType({issueType: data.type}));
      // this.store.dispatch(fromRedmineIssueActions.fetch());
    })
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      takeUntil(this.unsubscribe$),
      map((event: any) => event.target.value),
      filter( (res:string) => res.length > 2),
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((text: string) => {
      this.store.dispatch(fromRedmineIssueActions.setSearch({search: text.toLowerCase().trim()}));
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  clearSearch() {
    this.searchInput.nativeElement.value = '';
    this.store.dispatch(fromRedmineIssueActions.setSearch({search: null}));
  }

  get list(): Observable<RedmineIssue[]> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromRedmineIssueReducers.selectIssues));
  }

  identify(index, entity: any){ return entity.id }

  get total(): Observable<number>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromRedmineIssueReducers.selectState),
      map( (state:fromRedmineIssueReducers.State)  => state.total)
    );
  }

  get direction(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromRedmineIssueReducers.selectState),
      map( (state:fromRedmineIssueReducers.State) => state.asc),
      tap( asc => this.asc = asc)
    );
  }

  get loading(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromRedmineIssueReducers.selectState),
      map( (state:fromRedmineIssueReducers.State)  => state.loading)
    );
  }

  get order(): Observable<string>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromRedmineIssueReducers.selectState),
      map( (state:fromRedmineIssueReducers.State)  => state.order),
      tap( sort => this.sort = sort)
    );
  }

  private sort: string;
  private asc: boolean;
  setSort(sort: string): void {
    if(sort == this.sort) {
      this.store.dispatch(fromRedmineIssueActions.setDirection({asc: !this.asc}));
    } else {
      this.store.dispatch(fromRedmineIssueActions.setDirection({asc: false }));
    }
    this.store.dispatch(fromRedmineIssueActions.setOrder({order: sort}));
  }

  toggleDirection(): void {
    this.store.dispatch(fromRedmineIssueActions.setDirection({asc: !this.asc}));
  }

  pageChange(page): void {
    this.store.dispatch(fromRedmineIssueActions.setPage({page: page}));
  }


  hasNotification(item: RedmineIssue): Observable<boolean> {
    return item.notification ? this.storeNotification.select(fromNotificationReducers.hasNotification, item.id) : of(false);
  }
}

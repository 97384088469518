import { Prize, UsersPrize } from "@/model/prize.model";
import { User } from "@/model/user.model";
import { createAction, props } from "@ngrx/store";
import { UserPagination } from "./users.reducers";

export const clearFilters = createAction(
  "clearFilters users"
);

export const fetchUsers = createAction(
  "fetch users",
  props<{limit?: number | null}>()
);

export const fetchOneUser = createAction(
  "fetch one user",
  props<{id: number}>()
);

export const fetchRecentUsers = createAction("fetch recent users");

export const setRecentUsers = createAction(
  "set recent users",
  props<{users: User[]}>()
);

export const createUser = createAction(
  "create user",
  props<{user: User}>()
);

export const editUser = createAction(
  "edit user",
  props<{id: number, user: User}>()
);

export const updateUser = createAction(
  "update user",
  props<{user: User}>()
);

export const deleteUser = createAction(
  "delete user",
  props<{user: User}>()
);

export const removeUser = createAction(
  "remove user",
  props<{user: User}>()
);


export const emitNewUserId = createAction(
  "emit new id",
  props<{id: number}>()
);

export const addOneUser = createAction(
  "add one user",
  props<{user: User}>()
);

export const setPage = createAction(
  "set page",
  props<{ page: number }>()
);

export const setOrder = createAction(
  "set order",
  props<{ order: string }>()
);

export const setDirection = createAction(
  "set direction",
  props<{ direction: string }>()
);

export const setSearch = createAction(
  "set search",
  props<{ search: string }>()
);

export const setDeactivated = createAction(
  "set deactivated",
  props<{ deactivated: boolean }>()
);

export const toggleDeactivated = createAction(
  "toggle deactivated"
);



export const addUsers = createAction(
  "add users",
  props<UserPagination>()
);

export const replaceUsers = createAction(
  "replace users",
  props<UserPagination>()
);

export const createUserPrize = createAction(
  "createUserPrize",
  props<{user: User, prize: Prize, usersPrize: UsersPrize}>()
);

export const addUserPrize = createAction(
  "addUserPrize",
  props<{user: User, usersPrize: UsersPrize}>()
);

export const deleteUserPrize = createAction(
  "deleteUserPrize",
  props<{user: User, usersPrize: UsersPrize}>()
);


<section class="content">
  <div class="container-fluid" #target>
    <div class="card widget-user">
      <ng-container ngFor #statistic *ngFor="let item of statistics">
        <div class="card-footer mt-0 pt-3">
          <h3 class="card-title text-bold text-center">{{item.date | amLocale:'pl' | amDateFormat: 'MMMM YYYY' | titlecase}}</h3>
        </div>
        <div class="card-body">
          <img [src]="item.image" class="img-fluid" [alt]="item.date | amLocale:'pl' | amDateFormat: 'MMMM YYYY' | titlecase" />
        </div>
      </ng-container>
      <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="statistics.length == 0"></nz-empty>
      <div class="overlay" *ngIf="loading">
        <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(0, 0);text-align: center; padding: 45px 0">
          <i class="fas fa-3x fa-spinner fa-spin"></i>
          <p>Ładowanie...</p>
        </div>
      </div>
    </div>
  </div>
</section>

import { TrainingPackage } from '@/model/package.model';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Data } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
interface RaportItemDTO {
  firstname: string;
  lastname: string;
  email: string;
  invitedAt: string;
  startedAt: string;
  completedAt: string;
  endDateAt: string;
  trainingsCompleted: number;
  trainingsTotal: number;
  passed: boolean;
  score: number;
}
interface RaportItem {
  firstname: string;
  lastname: string;
  email: string;
  invitedAt: string;
  startedAt: string;
  completedAt: string;
  endDateAt: string;
  trainingsCompleted: number;
  trainingsTotal: number;
  passed: string;
  score: string;
}

@Component({
  selector: 'app-package-report',
  templateUrl: './package-report.component.html',
  styleUrls: ['./package-report.component.scss']
})
export class PackageReportComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  package: TrainingPackage;
  result: RaportItem[] = [];
  loading = false;
  page = 1;
  pageSize = 20;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.form = new FormGroup({
      type: new FormControl("invited"),
      invitationRangeOn: new FormControl(false),
      invitationRange: new FormControl(null,[this.DateTimeValidator.bind(this)]),
      completedRangeOn: new FormControl(false),
      completedRange: new FormControl(null,[this.DateTimeValidator.bind(this)]),

      endDateRangeOn: new FormControl(false),
      endDateRange: new FormControl(null,[this.DateTimeValidator.bind(this)]),


    });
   }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe( (data: Data) => {
      this.package = data.instance;
    });
  }

  get pagination(): RaportItem[] {
    return [...this.result].splice( (this.page-1)*this.pageSize, this.pageSize);
  }

  report() {
    if (this.form.valid) {
      this.loading = true;
      this.http.post<RaportItemDTO[]>(`<backendhost>/v1/training-package/${this.package.id}/report`, this.form.value)
        .pipe(
          catchError( (err, caught): any => {

            this.toastr.error(err.message);
          })
        )
        .subscribe( (result: RaportItemDTO[]) => {
          this.loading = false;
          this.result = result.map(item => {
            return {
              ...item,
              ...{
                passed: item.passed == true ? "TAK" : (item.passed == false ? "NIE" : null),
                score: item.score == null ? null : `${item.score}%`
              }
            }
          });
        })
    } else {
        this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }

  get typeName(): string {
    switch (this.form.controls.type.value) {
      case 'invited':
          return 'zaproszonych';
      case 'started':
        return 'rozpoczetych';
      case 'uncompleted':
          return 'nieukończonych';
      case 'completed':
          return 'ukończonych';
      case 'passed':
          return 'zaliczonych'
    }
  }

  generateCSV() {
    let title = `Raport ze szkolenia ${this.typeName} szkolenia ${this.package.name}`;
    let options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: ',',
      showLabels: true,
      // showTitle: true,
      title: title,
      // useBom: true,
      // noDownload: true,
      headers: ["Imię", "Nazwisko", "Email", "Data zaproszenia", "Data rozpoczęcia", "Data ukończenia", "Ukończone szkolenia", "Liczba szkoleń", "Zaliczone"],
      useHeader: false,
      nullToEmptyString: true,
    };
    new AngularCsv(this.result, title, options);
  }

  DateTimeValidator = (fc: FormControl) => {
    if(!fc?.value) {
      return null;
    }
    const date1 = new Date(fc?.value[0]);
    const date2 = new Date(fc?.value[1]);
    const isValid = !isNaN(date1.valueOf()) && !isNaN(date2.valueOf());
    return isValid ? null : {dateNotValid: true};
  };
}

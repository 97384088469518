import { PaginationDTO } from "@/model/pagination.model";
import { Slot } from "@/model/slot.model";
import { User } from "@/model/user.model";
import { Vacant, VacantCreateDTO, VacantPack } from "@/model/vacant.model";
import { createAction, props } from "@ngrx/store";

export interface VacantFilter {
  type?: string,
  outpost?: number,
  date? : {
    start: string,
    end: string
  }
}

export const clear = createAction("vacantToConfirm clear");
export const fetch = createAction("vacantToConfirm fetch");

export const setPage = createAction("vacantToConfirm setPage", props<{ page: number }>());
export const setOrder = createAction("vacantToConfirm setOrder", props<{ order: string }>());
export const setDirection = createAction("vacantToConfirm setDirection", props<{ asc: boolean }>());
export const setSearch = createAction("vacantToConfirm setSearch", props<{ search: string }>());

export const setMany = createAction("vacantToConfirm setMany", props<PaginationDTO<VacantPack>>());
export const removeOne = createAction("vacantToConfirm removeOne", props<{id: number}>());

export const confirm = createAction("vacantToConfirm confirm", props<{vacantPack: VacantPack}>());
export const reject = createAction("vacantToConfirm reject", props<{vacantPack: VacantPack}>());

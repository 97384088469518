<img class="img-circle img-sm" [src]="comment.createdBy | userAvatar" alt="User Image"  [appUserDisactivatable]="comment.createdBy">
<div class="comment-text  text-sm">
  <div class="comment-pill bg-stanout" [class.bg-warning]="fragment == 'comment' + comment.id ">
    <p class="m-0">
      <span class="text-decoration-line-through" *ngIf="!comment?.createdBy">Użytkownik usunięty</span>
      <strong *ngIf="comment?.createdBy" [appUserDisactivatable]="comment.createdBy">{{comment.createdBy?.firstname}} {{comment.createdBy?.lastname}}</strong>
    </p>
    <p class="m-0" [innerHtml]="comment.content"></p>
    <a class="badge text-sm rounded-pill badge-secondary like-counter text-white" (click)="likeToggle()">
      <span class="badge text-xs rounded-pill text-white" [ngClass]="{'badge-default': !comment.liked, 'badge-primary': comment.liked}">
        <i class="fa-thumbs-up fas" *ngIf="!comment?.likeSaving"></i>
        <i class="fa-spinner fa-spin fas" *ngIf="comment?.likeSaving"></i>
      </span>
      <span class="text-white">{{comment.likeCount}}</span>
    </a>
  </div>
  <span class="comment-footer">
    <a (click)="likeToggle()" [class.text-primary]="comment.liked" [class.text-muted]="!comment.liked">Lubie to!</a> |
    <a (click)="replyClick()" class="text-muted">Odpowiedz</a> |
    {{comment.createdAt | amLocale:'pl' | amTimeAgo }}
    <a
      checkRolePermition="comments.delete" [instance]="comment"
      nz-popconfirm
      nzPopconfirmTitle="Czy na pewno usunąć?"
      nzOkText="Tak"
      nzCancelText="Nie"
      nzPopconfirmPlacement="left"
      ngbTooltip="Usuń"
      (nzOnConfirm)="delete()" >
    <i class="fas fa-trash fa-fw"></i></a>
  </span>
  <!-- <pre>{{comment | json}}</pre> -->
</div>

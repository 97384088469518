import { AuthService } from '@/auth/auth.service';
import { User } from '@/model/user.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import * as fromUsersActions from '@/store/users/users.actions';
import * as fromUsersReducers from '@/store/users/users.reducers';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { PasswordStrengthValidator } from '@/utils/pass.strength';


@Component({
  selector: 'app-edit-user-password',
  templateUrl: './edit-user-password.component.html',
  styleUrls: ['./edit-user-password.component.scss']
})
export class EditUserPasswordComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  user: User;
  disabled = false;

  constructor(
    private toastr: ToastrService,
    private store: Store<fromUsersReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    this.form = new FormGroup({
      password: new FormControl(null, [Validators.required, PasswordStrengthValidator]),
      confirmPassword: new FormControl(null, [Validators.required, this.checkPasswords]),
    });

    this.activatedRoute.paramMap.pipe(
      map((params: ParamMap) => parseInt(params.get('id')))
    ).subscribe( (id: number) => {
      this.store.dispatch(fromUsersActions.fetchOneUser({id: id}));
      this.store.pipe(takeUntil(this.unsubscribe$), select(fromUsersReducers.selectOneUser, id)).subscribe((user: User | null) => {
        this.user = user;
        this.form.patchValue(user);
      })
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => {
    let pass = this.form?.get('password')?.value;
    let confirmPass = this.form?.get('confirmPassword')?.value
    return pass === confirmPass ? null : { notSame: true }
  }

  save(): void {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    let valid = this.form.valid;
    if (valid && !this.disabled) {
      this.disabled = true;

      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        filter((action) => action.type === fromUsersActions.emitNewUserId.type),
      ).subscribe( (action:any) => {
        this.toastr.success("Zapisano zmiany");
        this.router.navigate(['/','users'])
      })

      this.store.dispatch(fromUsersActions.editUser({id: this.user.id, user: this.form.value}));
    } else if(!this.disabled) {
      this.toastr.error("Formularz zawiera błędy");
    } else {
      this.toastr.warning("Trwa zapisywanie");
    }
  }

}

import { ChatMessage, Conversation } from "@/model/chat.model";
import { KbFile } from "@/model/file.model";
import { User } from "@/model/user.model";
import { createAction, props } from "@ngrx/store";

export interface GetMessagesDTO {
  conversation: Conversation,
  messages: ChatMessage[],
  total: number
}

export const fetchRecentConversations = createAction(
  "fetch recent conversations",
  props<{ skip?: number }>()
);

export const addConvertsations = createAction(
  "add conversations",
  props<{ payload: [Conversation] }>()
);

export const addOneConvertsationWithMessage = createAction(
  "add one conversations with message",
  props<{ msg: ChatMessage }>()
);

export const fetchMessages = createAction(
  "fetch messages",
  props<{ conversation: number, skip?: number }>()
);

export const addMessagesToConvertsations = createAction(
  "add messages to conversations",
  props<GetMessagesDTO>()
);
export const removeMessageFromConvertsations = createAction(
  "removeMessageToConvertsations",
  props<{id: number, conversation: number}>()
);


export const addOneMessageToConvertsations = createAction(
  "add one message to conversations",
  props<{msg: ChatMessage}>()
);

export const addMyMessageToConvertsations = createAction(
  "add my message to conversations",
  props<{msg: ChatMessage}>()
);

export const createNewMessage = createAction(
  "create new messages",
  props<{content: string, conversation: number, files: KbFile[], replyTo: ChatMessage}>()
);
export const creatTempMessage = createAction(
  "create temp messages",
  props<{msg: ChatMessage}>()
);

export const createNewMessageAndConverrsation = createAction(
  "create new messages and convertsation",
  props<{content: string, to: number, files: KbFile[]}>()
);

export const setAsSeen = createAction(
  "set as seen",
  props<{conversation: number}>()
);

export const createNewRoom = createAction(
  "create new room",
  props<{name: string}>()
);

export const fetchRoom = createAction(
  "fetch room",
  props<{ id: number }>()
);

export const updateRoom = createAction(
  "update room",
  props<{conversation: Conversation}>()
);

export const addEmptyConversation = createAction(
  "add empty conversation",
  props<{conversation: Conversation}>()
);

export const saveRoom = createAction(
  "save room",
  props<{conversation: Conversation}>()
);

export const addMemberToRoom = createAction(
  "add member to room",
  props<{room: Conversation, user: User}>()
);


export const deleteMemberFromRoom = createAction(
  "delete member from room",
  props<{room: Conversation, user: User}>()
);

export const deleteRoom = createAction(
  "deleteRoom",
  props<{conversation: Conversation}>()
);

export const removeRoom = createAction(
  "removeRoom",
  props<{conversation: Conversation}>()
);

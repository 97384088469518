<div class="card">
  <div class="card-header d-flex align-items-center">
    <h3 class="card-title m-0 flex-grow-1 text-bold"><i class="fas fa-users fa-fw"></i> Grupy szkoleniowe</h3>
    <div class="card-tools flex-grow-1">
      <div class="input-group">
        <input type="text" name="table_search" class="form-control float-right" placeholder="Szukaj" #searchInput>
        <button class="btn bg-transparent" style="position:absolute; right: 0; transform: translate(-100%,0);" (click)="clearSearch()">
          <i class="fa fa-times"></i>
        </button>
        <span class="input-group-append">
          <div class="input-group-text bg-transparent"><i class="fas fa-search"></i></div>
        </span>
      </div>
    </div>
  </div>
  <div class="table-responsive position-relative">
    <table class="table align-middle table-hover">
      <thead>
        <tr>
          <th style="width:1rem">#</th>
          <th>Nazwa</th>
          <th>Członków</th>
          <th>Utworzono</th>
          <th colspan="2">Przez</th>

          <th class="text-right" >
            <button  checkRolePermition="training.admin.scorm" class="btn btn-success btn-sm elevation-2 text-nowrap" [routerLink]="['add']" >
              <i class="fas fa-plus fa-fw fa-lg"></i> Dodaj
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of list | async; let idx = index; trackBy: identify">
          <th class="align-middle">{{idx + 1}}</th>
          <td class="align-middle">{{item.name}}</td>
          <td class="align-middle text-center">
            <span class="badge text-sm rounded-pill" [ngClass]="{'badge-secondary': item?.count == 0, 'badge-primary': item?.count > 0}">
              {{item.count}}
            </span>
          </td>
          <td class="align-middle">{{item.createdAt | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
          <td class="user-panel" style="width:70px">
            <img
                  [src]="item.createdBy  | userAvatar: 34"
                  class="img-circle elevation-2"
                  alt="User Image"
                  style="width:45px"
                  [appUserDisactivatable]="item.createdBy"
              />
          </td>
          <td>
            <span class="text-decoration-line-through" *ngIf="!item?.createdBy">Użytkownik usunięty</span>
            <strong [appUserDisactivatable]="item.createdBy">{{item.createdBy?.firstname}} {{item.createdBy?.lastname}}</strong> <br>
            <small [appUserDisactivatable]="item.createdBy">{{ item?.createdBy?.active ? item?.createdBy?.email : '-' }}</small>
          </td>
          <td class="align-middle text-nowrap text-right" >
            <!-- <button class="btn btn-info btn-sm elevation-2 mr-2" [routerLink]="[item.id]" ngbTooltip="Podgląd" checkRolePermition="training.admin.scorm">
              <i class="fas fa-play fa-fw fa-lg"></i>
            </button> -->
            <button class="btn btn-primary btn-sm elevation-2 mr-2" [routerLink]="[item.id, 'edit']" ngbTooltip="Edycja" checkRolePermition="training.admin.scorm">
              <i class="fas fa-edit fa-fw fa-lg"></i>
            </button>
            <a
                checkRolePermition="training.admin.scorm"
                class="btn btn-danger btn-sm elevation-2"
                nz-popconfirm
                nzPopconfirmTitle="Czy na pewno usunąć?"
                nzOkText="Tak"
                nzCancelText="Nie"
                nzPopconfirmPlacement="left"
                ngbTooltip="Usuń"
                (nzOnConfirm)="delete(item)" >
              <i class="fas fa-trash fa-fw fa-lg"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(list | async).length == 0"></nz-empty>
    <div class="overlay position-absolute" *ngIf="(loading | async)" style="top: 0; bottom: 0; left: 0; right: 0">
      <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
        <i class="fas fa-3x fa-spinner fa-spin"></i>
        <p>Ładowanie...</p>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="(total | async)"  (pageChange)="pageChange($event)">
      <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationEllipsis>...</ng-template>
      <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>
  </div>
</div>

<div class="card" style="border-top-right-radius:0;border-top-left-radius:0;min-height:400px" *ngIf="(loading)">
  <div class="overlay" >
    <div style="position: sticky; text-align: center">
      <i class="fas fa-3x fa-spinner fa-spin"></i>
      <p>Ładowanie...</p>
    </div>
  </div>
</div>

<div class="card" style="border-top-right-radius:0;border-top-left-radius:0;" *ngIf="issue">
  <div class="overlay" *ngIf="saving | async">
    <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%,0);text-align: center; padding: 25px 0">
      <i class="fas fa-3x fa-spinner fa-spin"></i>
      <p>Zapisywanie...</p>
    </div>
  </div>
  <div class="card-header d-flex align-items-center" style="border-top-right-radius:0;border-top-left-radius:0;">
    <h3 class="card-title">{{issue.subject}}</h3>
    <div class="card-tools flex-grow-1 text-right">
      <a class="btn btn-tool" *ngIf="me.email == issue.author?.email"
        [routerLink]="['edit']"
        ngbTooltip="Edytuj">
        <i class="fas fa-edit"></i>
      </a>
    </div>
  </div>
  <div class="card-body" [innerHtml]="markdownHtml(issue.description) | safeHtml" style="font-size: 0.9em;line-height: 1.4em;"></div>

  <table class="table align-middle table-sm d-none d-lg-table" style="font-size: 0.9em;">
    <tbody>
      <tr>
        <th>Kategoria</th>
        <td>{{issue.category}} / {{issue.subCategory}}</td>
        <th>Status</th>
        <td *ngIf="issue?.status">{{issue.status.name}}</td>
      </tr>
      <tr>
        <th>Przypisane do</th>
        <td>
          <div class="d-flex">
            <div>
              <img [src]="issue?.assigned_to | userAvatar:25" class="img-circle elevation-2 mr-2" alt="User Image" style="width:25px" [appUserDisactivatable]="issue?.assigned_to"/>
            </div>
            <div class="info flex-grow-1">
              <strong [appUserDisactivatable]="issue?.assigned_to">{{ issue?.assigned_to ? issue?.assigned_to.name : 'Brak przypisanego użytkownika' }}</strong> <br/>
              <span [appUserDisactivatable]="issue?.assigned_to">{{issue?.assigned_to?.active ? issue?.assigned_to?.email : '-' }}</span>
            </div>
          </div>
        </td>
        <th>Utworzone przez</th>
        <td *ngIf="issue?.author">
          <div class="d-flex">
            <div>
              <img [src]="issue?.author | userAvatar:25" class="img-circle elevation-2 mr-2" alt="User Image" style="width:25px" [appUserDisactivatable]="issue?.author"/>
            </div>
            <div class="info flex-grow-1">
              <strong [appUserDisactivatable]="issue?.author">{{ issue?.author?.name }}</strong> <br/>
              <span [appUserDisactivatable]="issue?.author">{{ issue?.author?.active ? issue?.author?.email : '-' }}</span>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th>Data utworzenia</th>
        <td>{{issue?.created_on | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm'}}</td>
        <th>Data aktualizacji</th>
        <td>{{issue?.updated_on | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm'}}</td>
      </tr>
      <tr>
        <th>Obserwujący</th>
        <td colspan="3">
          <div class="list-group">
          <div class="d-flex list-group-item" *ngFor="let watcher of issue?.watchers">
            <div><img [src]="watcher | userAvatar:25" class="img-circle elevation-2 mr-2" alt="User Image" style="width:25px"/></div>
            <div class="info flex-grow-1">
              <strong>{{ watcher?.name }}</strong> <br/>
              {{ watcher?.email }}
            </div>
          </div>
        </div>
        </td>
      </tr>
      <tr *ngIf="issue?.attachments && issue.attachments.length > 0">
        <th>Pliki</th>
        <td colspan="3">
          <app-redmine-issue-detail-attachments [attachments]="issue.attachments"></app-redmine-issue-detail-attachments>
        </td>
      </tr>
    </tbody>
  </table>

  <table class="table align-middle table-sm d-table d-lg-none" style="font-size: 0.9em;">
    <tbody>
      <tr>
        <th>Kategoria</th>
        <td>{{issue.category}} / {{issue.subCategory}}</td>
      </tr>
      <tr *ngIf="issue?.status">
        <th>Status</th>
        <td>{{issue.status.name}}</td>
      </tr>
      <tr>
        <th>Przypisane do</th>
        <td>
          <div class="d-flex">
            <img [src]="issue?.assigned_to | userAvatar:45" class="img-circle elevation-2 mr-2" alt="User Image" style="width:45px" [appUserDisactivatable]="issue?.assigned_to"/>
            <div class="info flex-grow-1">
              <strong [appUserDisactivatable]="issue?.assigned_to">{{ issue?.assigned_to ? issue?.assigned_to.name : 'Brak przypisanego użytkownika' }}</strong> <br/>
              <span [appUserDisactivatable]="issue?.assigned_to">{{ issue?.assigned_to?.active ? issue?.assigned_to?.email : '-' }}</span>
            </div>
          </div>
        </td>
      </tr>
      <tr *ngIf="issue?.author">
        <th>Utworzone przez</th>
        <td colspan="2">
          <div class="d-flex">
            <img [src]="issue?.author | userAvatar:45" class="img-circle elevation-2 mr-2" alt="User Image" [appUserDisactivatable]="issue?.author"/>
            <div class="info flex-grow-1">
              <strong [appUserDisactivatable]="issue?.author">{{ issue?.author?.name }}</strong> <br/>
              <span [appUserDisactivatable]="issue?.author">{{ issue?.author?.active ? issue?.author?.email : '-' }}</span>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th>Data utworzenia</th>
        <td colspan="2">{{issue?.created_on | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm'}}</td>
      </tr>
      <tr>
        <th>Data aktualizacji</th>
        <td colspan="2">{{issue?.updated_on | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm'}}</td>
      </tr>
      <tr>
        <th>Obserwujący</th>
        <td colspan="2">
          <div class="list-group">
          <div class="d-flex list-group-item" *ngFor="let watcher of issue?.watchers">
            <img [src]="watcher | userAvatar:45" class="img-circle elevation-2 mr-2" alt="User Image"/>
            <div class="info flex-grow-1">
              <strong>{{ watcher?.name }}</strong> <br/>
              {{ watcher?.email }}
            </div>
          </div>
        </div>
        </td>
      </tr>
      <tr *ngIf="issue?.attachments && issue.attachments.length > 0">
        <th>Pliki</th>
        <td colspan="3">
          <app-redmine-issue-detail-attachments [attachments]="issue.attachments"></app-redmine-issue-detail-attachments>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="card-footer">
      <button type="button" class="btn btn-outline-primary" *ngIf="issue.status.id != 3" (click)="closeIssue(3)"> Zamknij zadanie</button>
      <button type="button" class="btn btn-outline-primary" *ngIf="issue.status.id == 3" (click)="openIssue(7)"> Otwórz ponownie zadanie</button>
  </div>
  <ngb-accordion [closeOthers]="true"
    [disableTooltip]="issue.status.id != 3"
    ngbTooltip="Nie można dodawać komentarzy do zamkniętych zgłoszeń. Otwórz ponownie zgłoszenie aby odać komentarz.">
    <ngb-panel
      title="Dodaj komentarz"
      [disabled]="issue.status.id == 3"
      >
      <ng-template ngbPanelContent>
        <form [formGroup]="commentForm" (ngSubmit)="addComment()">
        <app-editor  formControlName="notes" [appIsValid]="commentForm.controls.notes" ></app-editor>
        <div class="invalid-feedback" *ngIf="commentForm.controls.notes.errors?.required">
          Pole jest wymagane
        </div>
        <div class="form-group">
          <label for="description">Załączniki</label>
          <nz-upload  checkRolePermition="kb.admin.files.create"
            nzType="drag"
            [nzMultiple]="true"
            [(nzFileList)]="fileList"
            [nzBeforeUpload]="beforeUpload"
            >
            <p class="ant-upload-drag-icon">
              <i nz-icon nzType="inbox"></i>
            </p>
            <p class="ant-upload-text">Kliknij lub przeciągnij plik do tego obszaru, aby przesłać</p>
            <p class="ant-upload-hint"></p>
          </nz-upload>
        </div>
        <button class="btn btn-success float-right" type="submit">
          Zapisz
          <i class="fas fa-angle-right fa-fw"></i>
        </button>
        </form>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
  <div class="card-footer">
    <h3 class="card-title">Komentarze</h3>
  </div>
  <div class="card-footer">
    <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="issue.comments.length == 0"></nz-empty>
    <div>
      <div class="timeline-item d-block" *ngFor="let comment of issue.comments">
        <div class="card card-widget position-relative shadow mt-3 mb-4">
          <div class="card-header pb-1">
            <div class="user-block">
              <img class="img-circle" [src]="comment?.user | userAvatar" alt="User Image" [appUserDisactivatable]="comment?.user">
              <span class="username" [appUserDisactivatable]="comment?.user">
                {{comment?.user.name}}
              </span>
              <span class="description"> {{comment?.created_on | amLocale:'pl' | amCalendar }}</span>
            </div>
          </div>
          <div class="card-body" [innerHtml]="markdownHtml(comment?.notes) | safeHtml"></div>
          <div class="card-footer" *ngIf="commentHasAttachments(comment)">
            <strong>Załączniki:</strong>
          </div>
          <app-redmine-issue-detail-attachments *ngIf="commentHasAttachments(comment)" [attachments]="commentAttachments(comment?.details)"></app-redmine-issue-detail-attachments>
        </div>
      </div>
    </div>
  </div>

</div>

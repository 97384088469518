import { AuthService } from '@/auth/auth.service';
import { User } from '@/model/user.model';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  async check(): Promise<boolean> {
    return new Promise( async (resolve, reject) => {
      if(!this.authService.isAuthenticated()) {
        this.router.navigate(['/','login']);
        resolve(false);
      }
      let user: User = await this.authService.getUser();
      if (user.role !== 'admin') {
        resolve(false);
        this.toastr.error("Nie masz uprawnień do tej sekcji!");
      }
      else {
        resolve(true);
      }
    })
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
      | Observable<boolean | UrlTree>
      | Promise<boolean | UrlTree>
      | boolean
      | UrlTree
  {
      return this.check();
  }

  canActivateChild(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
  ):
      | Observable<boolean | UrlTree>
      | Promise<boolean | UrlTree>
      | boolean
      | UrlTree {
      return this.canActivate(next, state);
  }
}

import { AuthService } from '@/auth/auth.service';
import { State } from '@/store/auth/auth.reducers';
import { createUser, emitNewUserId } from '@/store/users/users.actions';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of, Subject, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import * as fromRolesReducers from '@/store/roles/roles.reducers';
import { Role } from '@/model/role.model';
import { PasswordStrengthValidator } from '@/utils/pass.strength';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  disabled = false;
  uploading = false;

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => {
    let pass = this.form?.get('password')?.value;
    let confirmPass = this.form?.get('confirmPassword')?.value
    return pass === confirmPass ? null : { notSame: true }
  }

  constructor(
    private toastr: ToastrService,
    private store: Store<State>,
    private rolesStore: Store<fromRolesReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      firstname: new FormControl(null, Validators.required),
      lastname: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email],[this.checkEmailUniqe.bind(this)]),
      password: new FormControl(null, [Validators.required, PasswordStrengthValidator]),
      confirmPassword: new FormControl(null, [Validators.required, this.checkPasswords]),
      // role: new FormControl('user', [Validators.required]),
    });
    this.form.get('email').statusChanges.subscribe(console.log);

    this.authService.checkPermition('users.role', null).then((allowed) => {
      if (allowed) {
        this.form.addControl('role', new FormControl('user', [Validators.required]));
      }
    })
  //   this.formSubmitSubject$
  // .pipe(
  //   tap(() => this.form.markAsDirty()),
  //   switchMap(() =>
  //     this.form.statusChanges.pipe(
  //       startWith(this.form.status),
  //       filter(status => {
  //         console.log(status);
  //         return status !== 'PENDING';
  //       } ),
  //       take(1)
  //     )
  //   ),
  //   filter(status => status === 'VALID')
  // )
  // .subscribe(validationSuccessful => this.submitForm());
  }

  get roles(): Observable<Role[]> {
    return this.rolesStore.pipe(select(fromRolesReducers.selectRoles));
  }

  private prevEmailValue: string;
  checkEmailUniqe(control: AbstractControl)/* : Observable<ValidationErrors>*/ {

    if(this.prevEmailValue == control.value && control.errors === null)
      return of(null);
    const subject = new BehaviorSubject<string>('');
    return subject.asObservable().pipe(
      takeUntil(this.unsubscribe$),
      distinctUntilChanged(),
      debounceTime(1000),
      switchMap(() => this.authService.checkEmailUniqe(control.value)),
      map(result => {
        if(result) {
          this.prevEmailValue = null;
          control.setErrors({notUniqe: true});
          control.markAsTouched();
          return { notUniqe: true };
        }
        else {
          this.prevEmailValue = control.value;
          control.setErrors(null);
          control.markAllAsTouched();
          return null;;
        }
      })
    )
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  save(): void {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    let valid =  this.form.valid;
    if (valid && !this.disabled) {
      this.disabled = true;

      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        filter((action) => action.type === emitNewUserId.type),
      ).subscribe( (action:any) => {
        this.toastr.success("Zapisano zmiany");
        this.router.navigate(['/','users','edit',action.id])
      })

      this.store.dispatch(createUser({user: this.form.value}));
    } else if(!this.disabled) {
      this.toastr.error("Formularz zawiera błędy");
    } else {
      this.toastr.warning("Trwa zapisywanie");
    }
  }


}

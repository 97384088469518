import { Component, OnInit } from '@angular/core';
import * as fromReducers from '@/store/search-tag/search-tag.reducers';
import * as fromActions from '@/store/search-tag/search-tag.actions';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';
import { SearchResult } from '@/model/search.result.model';
import { KbFile } from '@/model/file.model';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.scss']
})
export class SearchListComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  page: number = 1;
  pageSize: number = 5;
  term: string;

  constructor(
    private store: Store<fromReducers.State>,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private router: Router
  ) { }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {

    this.store.dispatch(fromActions.clear());
    this.activatedRoute.paramMap
      .pipe(map((pm: ParamMap) => pm.get('fraze')))
      .subscribe( (term:string) => {
        this.term = term;
        this.store.dispatch(fromActions.search({term}));
      });
  }

  get results(): Observable<SearchResult[]> {
    let first = (this.page - 1)*this.pageSize;
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromReducers.selectSearchResults),
      distinctUntilChanged( (x:SearchResult[], y: SearchResult[]) => x.length == y.length),
      // tap( (result) => {
      //   console.log(result.slice((this.page - 1)*this.pageSize, this.pageSize))
      // }),
      // tap((result)=>{
      //   if(result.length < (this.page - 1)*this.pageSize) {
      //     this.store.dispatch(fromActions.fetch({term: this.term, skip: first}));

      //   }
      // }),
      // map((result: SearchResult[]) => result.slice(first, this.pageSize))
      map((result: SearchResult[]) => result)
    );
  }
  get hasMore(): Observable<boolean> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectHasMore));
  }

  get total(): Observable<number> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectCount));
  }

  get isLoading(): Observable<boolean> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectIsLoading));
  }

  get open(): Observable<boolean> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectIsOpen));
  }

  pageChange(page) {

    this.page = page;
    let first = (this.page - 1)*this.pageSize;
    this.store.dispatch(fromActions.fetch({term: this.term, skip: first}));
  }

  loadMore() {
    this.isLoading.subscribe(loading => {
      if(loading)return;
      this.store.dispatch(fromActions.more());
    });
  }

  typeName (t:any) {
    switch(t.type) {
      case 'post': return 'News';
      case 'article': return 'Artykuł';
      case 'user': return 'Użytkownik';
      case 'offer': return 'Oferta pracy';
      case 'file': return 'Plik';
      case 'faq': return 'FAQ';
    }
  }

  onClick(item) {
    switch(item.type) {
      case 'file':
        return this.download(item);
      default:
        this.router.navigateByUrl(item.url);
    }
    // this.clear();
  }

  download(item) {
    this.httpClient
      .get(`${environment.apiUrl}/v1/kb/file/${item.uid}`, {responseType: 'json'})
      .subscribe(
        (file: KbFile) => {
          console.log(file);
          this.httpClient
            .get(`${environment.apiUrl}/v1/kb/file/${file.id}/download`, {responseType: 'blob'})
            .subscribe(
              (response) => {
                let blob:any = new Blob([response], { type: file.mimeType });
                // saveAs(blob, file.name);

                const url = window.URL.createObjectURL(blob);
                var anchor = document.createElement('a');
              	anchor.href = url;
              	anchor.download = file.name;
              	document.body.appendChild(anchor);
              	anchor.click();
              },
              error => console.log('Error downloading the file'),
              () => console.info('File downloaded successfully')
            )
        }
    );
  }

}

import { OfferCategory } from "@/model/offer.model";
import { createAction, props } from "@ngrx/store";


export const fetch = createAction("offer-categories fetch");
export const createOfferCategory = createAction("offer-categories create", props<{ item: OfferCategory }>());
export const updateOfferCategory = createAction("offer-categories save", props<{ item: OfferCategory }>());
export const deleteOfferCategory = createAction("offer-categories delete", props<{ id: number }>());

export const addMany = createAction("offer-categories addMany", props<{ list: OfferCategory[] }>());
export const addOne = createAction("offer-categories addOne", props<{ item: OfferCategory }>());
export const updateOne = createAction("offer-categories updateOne", props<{ item: OfferCategory }>());
export const removeOne = createAction("offer-categories removeOne", props<{ id: number }>());


import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { fromEvent, Observable, Subject } from 'rxjs';

import * as fromScormActions from '@/store/scorm/scorm.actions';
import * as fromScormReducers from '@/store/scorm/scorm.reducers';
import { debounceTime, distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { Scorm } from '@/model/scorm.model';

@Component({
  selector: 'app-scorm-list',
  templateUrl: './scorm-list.component.html',
  styleUrls: ['./scorm-list.component.scss']
})
export class ScormListComponent implements OnInit {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  searchTerm: string;
  page = 1;
  pageSize = 20;
  constructor(
    protected store: Store<fromScormReducers.State>,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(fromScormActions.fetch());
    this.store.pipe(
      take(1),
      takeUntil(this.unsubscribe$),
      select(fromScormReducers.selectState),
      map(state => state.search)
    ).subscribe((search:string) => {
      this.searchInput.nativeElement.value = search
    });
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      takeUntil(this.unsubscribe$),
      map((event: any) => event.target.value),
      filter( (res:string) => res.length > 2),
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((text: string) => {
      this.store.dispatch(fromScormActions.setSearch({search: text.toLowerCase().trim()}));
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  clearSearch() {
    this.searchInput.nativeElement.value = '';
    this.store.dispatch(fromScormActions.setSearch({search: null}));
  }

  get list(): Observable<Scorm[]> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromScormReducers.selectAllScorm));
  }

  delete(item: Scorm): void {
    this.store.dispatch(fromScormActions.removeScorm({id: item.id}));
  }

  identify(index, entity: Scorm){ return entity.id }

  get total(): Observable<number>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromScormReducers.selectState),
      map( (state:fromScormReducers.State)  => state.total)
    );
  }

  get direction(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromScormReducers.selectState),
      map( (state:fromScormReducers.State)  => state.asc),
      tap( asc => this.asc = asc)
    );
  }

  get loading(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromScormReducers.selectState),
      map( (state:fromScormReducers.State)  => state.loading),
    );
  }

  get order(): Observable<string>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromScormReducers.selectState),
      map( (state:fromScormReducers.State)  => state.order),
      tap( sort => this.sort = sort)
    );
  }

  private sort: string;
  private asc: boolean;
  setSort(sort: string): void {
    if(sort == this.sort) {
      this.store.dispatch(fromScormActions.setDirection({asc: !this.asc}));
    } else {
      this.store.dispatch(fromScormActions.setDirection({asc: false }));
    }
    this.store.dispatch(fromScormActions.setOrder({order: sort}));
  }

  toggleDirection(): void {
    this.store.dispatch(fromScormActions.setDirection({asc: !this.asc}));
  }

  pageChange(page): void {
    this.store.dispatch(fromScormActions.setPage({page: page}));
  }

}

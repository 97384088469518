import { AllOrCreated, Role } from '@/model/role.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import * as fromRolesActions from '@/store/roles/roles.actions';
import * as fromRolesReducers from '@/store/roles/roles.reducers';
import { select, Store } from '@ngrx/store';
import { map, takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Actions, ofType } from '@ngrx/effects';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.scss']
})
export class EditRoleComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();

  form: FormGroup;
  role: Role;
  AllOrCreated = AllOrCreated;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private store: Store<fromRolesReducers.State>,
    private toastr: ToastrService,
    protected actions$: Actions,

  ) {
    this.form = new FormGroup({
      // id: new FormControl(null),
      name: new FormControl(null, Validators.required),
      privileges: new FormGroup({


        news: new FormGroup({
          view: new FormControl(false),
          create: new FormControl(false),
          publicate: new FormGroup({
            all : new FormControl(false),
            group: new FormControl(false),
            myGroup: new FormControl(false),
            sms: new FormControl(false),
            push: new FormControl(false),
            important: new FormControl(false),
          }),
          edit: new FormControl(AllOrCreated.NONE),
          delete: new FormControl(AllOrCreated.NONE),
        }),

        comments: new FormGroup({
          delete: new FormControl(AllOrCreated.NONE),
        }),

        groups: new FormGroup({
          list: new FormControl(false),
          create: new FormControl(false),
          edit: new FormControl(AllOrCreated.NONE),
          delete: new FormControl(AllOrCreated.NONE),
          superior: new FormGroup({
            create: new FormControl(false),
            edit: new FormControl(AllOrCreated.NONE),
            delete: new FormControl(AllOrCreated.NONE),
          })
        }),

        chat: new FormGroup({
          room: new FormGroup({
            create: new FormControl(false),
            edit: new FormControl(AllOrCreated.NONE),
            delete: new FormControl(AllOrCreated.NONE),
          })
        }),

        messages: new FormGroup({
          send: new FormGroup({
            all : new FormControl(false),
            group: new FormControl(false),
            myGroup: new FormControl(false),
            sms: new FormControl(false),
            push: new FormControl(false),
          })
        }),

        users: new FormGroup({
          list: new FormControl(false),
          create : new FormControl(false),
          edit: new FormControl(false),
          delete: new FormControl(false),
          password: new FormControl(false),
          role: new FormControl(false),
          prize: new FormControl(AllOrCreated.NONE),
        }),

        structure: new FormGroup({
          list: new FormControl(false),
          create : new FormControl(false),
          edit: new FormControl(false),
          delete: new FormControl(false),
        }),

        offers: new FormGroup({
          list: new FormControl(false),
          candidates: new FormControl(false),
          create : new FormControl(false),
          edit: new FormControl(false),
          delete: new FormControl(false),
          category: new FormControl(false),
        }),

        orders: new FormGroup({
          list: new FormControl(false),
          order: new FormControl(false),
          create: new FormControl(false),
          edit: new FormControl(false),
          delete: new FormControl(false)
        }),

        kb: new FormGroup({
          list: new FormControl(false),
          admin: new FormGroup({
            folders: new FormGroup({
              create: new FormControl(false),
              edit: new FormControl(false),
              delete:new FormControl(false),
            }),
            articles: new FormGroup({
              create: new FormControl(false),
              edit: new FormControl(false),
              delete: new FormControl(false),
            }),
            files: new FormGroup({
              create: new FormControl(false),
              edit: new FormControl(false),
              delete: new FormControl(false),
            })
          })
        }),

        ideas: new FormGroup({
          list: new FormControl(false),
          create: new FormControl(false),
          edit: new FormControl(false),
          delete: new FormControl(AllOrCreated.NONE),
        }),

        prizes: new FormGroup({
          list: new FormControl(false),
          create: new FormControl(false),
          edit: new FormControl(false),
          delete: new FormControl(false),
        }),


        reservations: new FormGroup({
          list: new FormControl(false),
          reservation: new FormGroup({
            create: new FormControl(false),
            delete: new FormControl(AllOrCreated.NONE),
          }),
          admin: new FormGroup({
            category: new FormGroup({
              create: new FormControl(false),
              edit: new FormControl(false),
              delete: new FormControl(false),
            }),
            assets: new FormGroup({
              create: new FormControl(false),
              edit: new FormControl(false),
              delete: new FormControl(AllOrCreated.NONE),
            }),
          }),
        }),

        training: new FormGroup({
          view: new FormControl(false),
          admin: new FormGroup({
            scorm: new FormControl(false),
          })
        }),
        survey: new FormGroup({
          view: new FormControl(false),
          admin: new FormControl(false),
        }),

        outpost: new FormGroup({
          view: new FormControl(false),
          admin: new FormControl(false),
          slot :  new FormGroup({
            sign: new FormControl(false),
            unsign: new FormControl(AllOrCreated.NONE),
          })
        }),

        vacant: new FormGroup({
          view: new FormControl(false),
          create: new FormControl(false),
          createMy: new FormControl(false),
          edit: new FormControl(AllOrCreated.NONE),
          delete: new FormControl(AllOrCreated.NONE),
          viewAll: new FormControl(false),
          reservation: new FormGroup({
            create: new FormControl(false),
            delete: new FormControl(AllOrCreated.NONE),
          })
        }),

        redmine: new FormGroup({
          view: new FormControl(false),
          admin: new FormControl(false),
        }),
        // callcenter: {
        //   view: AllOrCreated,
        //   fill: boolean,
        //   create: boolean,
        //   edit: AllOrCreated,
        //   delete: AllOrCreated,
        // }
        callcenter: new FormGroup({
          view: new FormControl(AllOrCreated.NONE),
          fill: new FormControl(false),
          fillEdit: new FormControl(AllOrCreated.NONE),
          fillDelete: new FormControl(AllOrCreated.NONE),
          create: new FormControl(false),
          edit: new FormControl(AllOrCreated.NONE),
          delete: new FormControl(AllOrCreated.NONE),
        }),

        roles: new FormGroup({
          admin: new FormControl(false),
        }),

        statistics: new FormGroup({
          view: new FormControl(false),
        })

      }),
    });
  }

  ngOnInit(): void {
    this.activeRoute.paramMap.pipe(
      map((params: ParamMap) => params.get('id'))
    ).subscribe( (id?: string) => {
      this.store.pipe(select(fromRolesReducers.selectRole, id)).subscribe (role => {
        this.role = role;
        this.form.patchValue(role);

        if(role?.root) {
          this.disableAll(this.form.controls);
        }
      })
    })
  }

  private disableAll(controls) {
    Object.values(controls).forEach(ctrl => {
      (ctrl as FormControl).disable();
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  save() {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });

    if (this.form.valid) {
      let role = {...this.role,...this.form.value};
      if(role.id) {
        this.store.dispatch(fromRolesActions.save({role}));
        this.actions$.pipe(
          ofType(fromRolesActions.update),
          takeUntil(this.unsubscribe$)
        ).subscribe((action) => {
          this.toastr.success("Zapisano");
          this.router.navigate(['/roles']);
        });
      }
      else {
        this.store.dispatch(fromRolesActions.create({role}));
        this.actions$.pipe(
          ofType(fromRolesActions.add),
          takeUntil(this.unsubscribe$)
        ).subscribe((action) => {
          this.toastr.success("Zapisano");
          this.router.navigate(['/roles']);
        });
      }
    } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }

}

import { createPaginationInitialState, Pagination } from "@/model/pagination.model";
import { Training } from "@/model/scorm.model";
import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import * as actions from "./training.actions";

export interface State extends Pagination<Training> {}

export const adapter: EntityAdapter<Training> = createEntityAdapter<Training>();

export const initialState: State = createPaginationInitialState(adapter, false);

export const reducer = createReducer(
  initialState,
  on(actions.setTrainings, (state, action) => {
    return {
      ...state,
      ...{
        total: action.total,
        loading: false,
        list: adapter.addMany(action.list, adapter.getInitialState())
      }
    }
  }),
  on(actions.addTraining, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.addOne(action.training, state.list)
      }
    }
  }),
  on(actions.clear, (state, action) => {return {...initialState}}),
  on(actions.fetch, (state, action) => { return {...state, ...{loading: true}}}),
  on(actions.setPage, (state, action) => { return {...state, ...{page: action.page}}}),
  on(actions.setOrder, (state, action) => { return {...state, ...{order: action.order}}}),
  on(actions.setDirection, (state, action) => { return {...state, ...{asc: action.asc}}}),
  on(actions.setSearch, (state, action) => { return {...state, ...{search: action.search}}}),
);



export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectState = createFeatureSelector<State>('training');
export const selectAllTraining = createSelector(selectState, (state) => selectAll(state.list) )
export const selectOneTraining = createSelector(selectState, (state: State, id) => state.list.entities[id]);

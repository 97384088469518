<section class="content">
  <div class="container pt-3 pb-3">
    <div class="card card-primary">
      <div class="overlay" *ngIf="(saving | async)">
        <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
          <i class="fas fa-3x fa-spinner fa-spin"></i>
          <p>Zapisywanie...</p>
        </div>
      </div>
      <div class="overlay" *ngIf="(loading | async)">
        <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
          <i class="fas fa-3x fa-spinner fa-spin"></i>
          <p>Ładowanie...</p>
        </div>
      </div>

      <div class="card-header"><h3 class="card-title">{{category?.title}}</h3></div>
      <div class="card-body" [innerHtml]="category?.description | safeHtml"></div>
      <div class="card-body">
        <div class="card card-primary  collapsed-card">
          <div class="card-header" data-card-widget="collapse" style="cursor: pointer">
            <h3 class="card-title">
              Dzień: <strong>{{currentDate  | amDateFormat:'D.MM.YYYY'}}</strong>. Wybierz inny dzień.

            </h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <nz-calendar class="ant-picker-calendar-full"
            [nzFullscreen]="true"
            (nzSelectChange)="onValueChange($event)"
            (nzPanelChange)="onPanelChange($event)"
            >
            <div *nzDateCell="let date" class="events">
              <ng-container [ngSwitch]="date  | amDateFormat:'YYYY-MM-DD'">
                  <ng-container *ngFor="let item of counsts">
                    <p *ngSwitchCase="item.day" class="mb-1">
                      <span class="badge badge-info">{{ item.count }}</span>
                      {{item.title}}
                    </p>
                  </ng-container>
              </ng-container>
            </div>
          </nz-calendar>
          </div>
        </div>
        <div class="position-relative">
          <div class="position-absolute overlay" style="width:100%; height: 100%" *ngIf="loadingTimetable">
            <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
              <i class="fas fa-3x fa-spinner fa-spin"></i>
              <p>Ładowanie...</p>
            </div>
          </div>
          <app-timetable
            (onSelect)="addReservation($event)"
            (onDelete)="onReservationDeleted($event)"
            class="d-block w-100"
            [data]="data"
            [rows]="timetableLabels"
            [options]="options"
          ></app-timetable>
          <p class="bg-secondary disabled color-palette p-2 text-sm pt-0">
            Klinknij na tablice aby dodać rezerwacje
          </p>

        </div>
      </div>
    </div>
  </div>
</section>

import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import * as fromRolesReducers from '@/store/roles/roles.reducers';
import { Role } from '@/model/role.model';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable } from '@firebase/util';
import { AsyncPipe } from '@angular/common';

@Pipe({
  name: 'roleName'
})
export class RoleNamePipe implements PipeTransform, OnDestroy {
  private asyncPipe: AsyncPipe;
  constructor(
    private store: Store<fromRolesReducers.State>,
    private cdr: ChangeDetectorRef
  ) {
    this.asyncPipe = new AsyncPipe(this.cdr);
  }

  transform(value: string, ...args: unknown[]): string {
    return this.asyncPipe.transform(this.store.pipe(
      select(fromRolesReducers.selectRole, value),
      map((role: Role) => role ? role.name : value)
    ));
  }

  ngOnDestroy() {
    this.asyncPipe.ngOnDestroy();
  }

}

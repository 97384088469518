
import { ChatMessage, Conversation } from "@/model/chat.model";
import { User } from "@/model/user.model";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import * as fromChatActions from "./chat.actions"

export interface State extends EntityState<Conversation> {}


export const adapter: EntityAdapter<Conversation> = createEntityAdapter<Conversation>({
  sortComparer: (a: Conversation, b: Conversation) => {
    if (!a.lastMessage) return 1;
    if (!b.lastMessage) return -1;
    return a.lastMessage?.createdAt < b.lastMessage?.createdAt ? 1 : -1
  }
});
export const messageAdapter: EntityAdapter<ChatMessage> = createEntityAdapter<ChatMessage>({
  selectId: (msg: ChatMessage) => {
    return msg.id ? msg.id : msg.tempId;
  },
  sortComparer: (a: ChatMessage, b: ChatMessage) => {
    return a.createdAt < b.createdAt ? 1 : -1;
  }
});

export const initialState: State = adapter.getInitialState();

export const reducers = createReducer(
  initialState,

  on(fromChatActions.addConvertsations, (state, action) => {
    action.payload.forEach( (conv:Conversation) => {
      if (state.entities[conv.id]) {
        state = adapter.updateOne({
          id: conv.id,
          changes: conv
        }, state);
      } else {
        state = adapter.addOne(conv, state);
      }
    })
    return state;
  }),

  on(fromChatActions.addMessagesToConvertsations, (state, action) => {
    let conv = state.entities[action.conversation.id];
    if (conv) {
      let list = messageAdapter.addMany(action.messages, conv.messages ? conv.messages : messageAdapter.getInitialState());
      let changes = {...action.conversation, ...{messages: list}}
      return adapter.updateOne({
        id: action.conversation.id,
        changes : changes
      }, state)
    }
    else {
      conv = {
        ...action.conversation,
        ...{
          lastMessage: action.messages[0],
          unseenCount: 0,
          messages : messageAdapter.addMany(action.messages, messageAdapter.getInitialState())
        }
      }
      return adapter.addOne(conv, state);
    }
  }),
  on(fromChatActions.creatTempMessage, (state, action) => {
    let conv = state.entities[action.msg.conversation.id];
    if (conv) {
      let list = messageAdapter.addOne(action.msg, conv?.messages ? conv.messages: messageAdapter.getInitialState());
      let changes = {
        ...conv,
        ...{
          lastMessage: action.msg,
          // unseenCount: conv.unseenCount + 1,
          messages: list
        }
      }
      return adapter.updateOne({
        id: action.msg.conversation.id,
        changes : changes
      }, state)
    }
  }),

  on(fromChatActions.removeMessageFromConvertsations, (state, action) => {
    let conv = state.entities[action.conversation];
    if (conv) {
      let changes = {
        ...conv,
        ...{
          messages: messageAdapter.removeOne(action.id, conv.messages)
        }
      }
      return adapter.updateOne({
        id: action.conversation,
        changes : changes
      }, state)
    }
  }),
  on(fromChatActions.addMyMessageToConvertsations, (state, action) => {
    let conv = state.entities[action.msg.conversation.id];
    if (conv) {
      console.log(conv);
      let list = messageAdapter.addOne(action.msg, conv?.messages ? conv.messages: messageAdapter.getInitialState());

      let changes = {
        ...conv,
        ...{
          lastMessage: action.msg,
          // unseenCount: conv.unseenCount + 1,
          messages: list
        }
      }
      return adapter.updateOne({
        id: action.msg.conversation.id,
        changes : changes
      }, state)
    }
    else {
      let newConv = {
          id: action.msg.conversation.id,
          name: action.msg.conversation.name,
          image: action.msg.conversation.image,
          lastMessage: action.msg,
          unseenCount: 0,
          messages : messageAdapter.addOne(action.msg, messageAdapter.getInitialState())
      }
      return adapter.addOne(newConv, state);
    }
  }),
  on(fromChatActions.addOneMessageToConvertsations, (state, action) => {
    let conv = state.entities[action.msg.conversation.id];
    if (conv) {
      console.log(conv);
      let list = messageAdapter.addOne(action.msg, conv?.messages ? conv.messages: messageAdapter.getInitialState());
      let changes = {
        ...conv,
        ...{
          lastMessage: action.msg,
          unseenCount: conv.unseenCount + 1,
          messages: list
        }
      }
      return adapter.updateOne({
        id: action.msg.conversation.id,
        changes : changes
      }, state)
    }
    else {
      let newConv = {
          id: action.msg.conversation.id,
          name: action.msg.conversation.name,
          image: action.msg.conversation.image,
          lastMessage: action.msg,
          unseenCount: 1,
          messages : messageAdapter.addOne(action.msg, messageAdapter.getInitialState())
      }
      return adapter.addOne(newConv, state);
    }
  }),
  on(fromChatActions.addOneConvertsationWithMessage, (state, action) => {
    let newConv = {
      id: action.msg.conversation.id,
      name: action.msg.conversation.name,
      image: action.msg.conversation.image,
      lastMessage: action.msg,
      unseenCount: 1,
      messages : messageAdapter.addOne(action.msg, messageAdapter.getInitialState())
    }
    return adapter.addOne(newConv, state);
  }),
  on(fromChatActions.setAsSeen, (state, action) => {
    console.log('setAsSeen', action);
    return adapter.updateOne({id: action.conversation, changes: {unseenCount:0}}, state);
  }),
  on(fromChatActions.addEmptyConversation, (state, action) => {
    let conv = {
      ...action.conversation,
      ...{
        lastMessage: null,
        unseenCount: 0,
        messages : messageAdapter.getInitialState()
      }
    }
    return adapter.addOne(conv, state);
  }),
  on(fromChatActions.updateRoom, (state, action) => {
    let update = {
      id: action.conversation.id,
      changes: action.conversation
    }
    return adapter.updateOne(update, state);
  }),
  on(fromChatActions.removeRoom, (state, action) => {
    return adapter.removeOne(action.conversation.id, state);
  }),
  on(fromChatActions.addMemberToRoom, (state, action) => {
    let id = action.room.id;
    let membersFull = state.entities[id].membersFull.reduce( (acc, cur) => { acc[cur.id] = cur; return acc; }, {});
    membersFull[action.user.id] = action.user;

    let mF: User[] = Object.values(membersFull);
    let update = {
      id: id,
      changes: {
        members:  Object.keys(membersFull).map(x => +x),
        membersFull: mF
      }
    }
    return adapter.updateOne(update, state);
  }),
  on(fromChatActions.  deleteMemberFromRoom, (state, action) => {
    let id = action.room.id;
    let membersFull = state.entities[id].membersFull.reduce( (acc, cur) => { acc[cur.id] = cur; return acc; }, {});
    delete membersFull[action.user.id];

    let mF: User[] = Object.values(membersFull);
    let update = {
      id: id,
      changes: {
        members:  Object.keys(membersFull).map(x => +x),
        membersFull: mF
      }
    }
    return adapter.updateOne(update, state);
  }),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectChatState = createFeatureSelector<State>('chats');

export const selectConversations = createSelector(selectChatState, selectAll );

export const selectConversation = createSelector(selectChatState, (state, id: number) => state.entities[id]);

import { Component, OnInit } from '@angular/core';
import * as fromVacantActions from '@/store/vacant/vacant.actions';
import * as fromVacantReducers from '@/store/vacant/vacant.reducers';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';
import { Vacant } from '@/model/vacant.model';
import { Subject } from 'rxjs';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'app-info-vacant',
  templateUrl: './info-vacant.component.html',
  styleUrls: ['./info-vacant.component.scss']
})
export class InfoVacantComponent implements OnInit {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  vacant: Vacant;

  constructor(
    private store: Store<fromVacantReducers.State>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private actionsSubject: ActionsSubject
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.store.dispatch(fromVacantActions.fetchOne({id: params.id}))
      this.store.pipe(
        select(fromVacantReducers.selectOne, params.id),
        takeUntil(this.unsubscribe$))
        .subscribe((vacant: Vacant) => {
          this.vacant = vacant;
        })
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  reserve() {
    let vacant: Vacant = this.vacant;
    this.store.dispatch(fromVacantActions.reserveVacant({vacant}));
    this.actionsSubject.pipe(
      ofType(fromVacantActions.reserveSuccess),
      takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.router.navigate(['workmarket']);
        })
  }

  unreserve() {
    let vacant: Vacant = this.vacant;
    this.store.dispatch(fromVacantActions.unreserveVacant({vacant}));
    this.actionsSubject.pipe(
      ofType(fromVacantActions.reserveSuccess),
      takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.router.navigate(['workmarket']);
        })
  }

}

import { Outpost } from '@/model/outpost.model';
import { Slot, SlotType } from '@/model/slot.model';
import { User } from '@/model/user.model';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable } from 'rxjs';
import { takeUntil, map, tap, distinctUntilChanged, debounceTime, take } from 'rxjs/operators';
import * as fromSlotActions from '@/store/slot/slot.actions';
import * as fromSlotReducers from '@/store/slot/slot.reducers';
import * as fromOutpostActions from '@/store/outpost/outposts.actions';
import * as fromOutpostReducers from '@/store/outpost/outposts.reducers';
import * as fromUsersActions from '@/store/users/users.actions';
import * as fromUsersReducers from '@/store/users/users.reducers';
import * as fromVacantActions from '@/store/vacant/vacant.actions';
import * as fromVacantReducers from '@/store/vacant/vacant.reducers';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@/auth/auth.service';

@Component({
  selector: 'app-add-vacant',
  templateUrl: './add-vacant.component.html',
  styleUrls: ['./add-vacant.component.scss']
})
export class AddVacantComponent implements OnInit {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  SlotType = Object.values(SlotType);
  user: User;
  page = 1;
  pageSize = 20;
  myaccount = false;
  countSms = 0;
  filterForm: FormGroup;
  constructor(
    private store: Store<fromSlotReducers.State>,
    // private outpostStore: Store<fromOutpostReducers.State>,
    private usersStore: Store<fromUsersReducers.State>,
    private vacantStore: Store<fromVacantReducers.State>,
    private router: Router,
    private actionsSubject: ActionsSubject,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthService,
  ) {
    this.filterForm = new FormGroup({
      type: new FormControl(null),
      outpost: new FormControl(null),
      user: new FormControl(null),
      date: new FormControl(null,[Validators.required ,this.DateTimeValidator.bind(this)]),
      slots: new FormGroup({}),
      comment: new FormControl(null),
      sendPush: new FormControl(true),
      sendEmail: new FormControl(true),
      sendSms: new FormControl(false),
      notificationUsers: new FormControl(null),
      notifications: new FormArray([
        new FormGroup({
          daysBefore: new FormControl(2),
          sms: new FormControl(false),
          push: new FormControl(true),
          email: new FormControl(true)
        })
      ])
    });
  }

  DateTimeValidator = (fc: FormControl) => {
    if(!fc?.value) {
      return null;
    }
    const date1 = new Date(fc?.value[0]);
    const date2 = new Date(fc?.value[1]);
    const isValid = !isNaN(date1.valueOf()) && !isNaN(date2.valueOf());
    return isValid ? null : {dateNotValid: true};
  };

  ngOnInit(): void {
    this.store.dispatch(fromOutpostActions.clear());

    this.authService.getUser().then((user) => {
      this.filterForm.controls.notificationUsers.setValue([user])
    });

    // this.outpostStore.dispatch(fromOutpostActions.fetch());

    this.filterForm.controls.outpost.valueChanges.subscribe((outpost: number) => {
      this.store.dispatch(fromSlotActions.setOutpost({outpost}));
      this.store.dispatch(fromSlotActions.fetch());
    })
    this.filterForm.controls.type.valueChanges.subscribe((value: string) => {
      this.store.dispatch(fromSlotActions.setType({value}));
      this.store.dispatch(fromSlotActions.fetch());
    })
    this.filterForm.controls.user.valueChanges.subscribe((user: User) => {
      this.store.dispatch(fromSlotActions.setUser({user}));
      this.store.dispatch(fromSlotActions.fetch());
    })

    this.filterForm.controls.slots.valueChanges.pipe(
      map((value) => Object.entries(value).filter(([key,value]) => value).map(([key,value]) => +key)),
      distinctUntilChanged((next, prev) => {
        return JSON.stringify(next) == JSON.stringify(prev);
      })
    ).subscribe((value) => {
      this.httpClient.put<Slot>(`<backendhost>/v1/slot/0/count-sms`, {slots: value}, {
        observe: 'body',
        responseType: 'json'
      }).subscribe((response: any) => {
        this.countSms = +response.result;
        // if(this.countSms) {
        //   this.filterForm.controls.sendSms.enable();
        // }
        // else {
        //   this.filterForm.controls.sendSms.disable();
        // }
      })
    })

    this.filterForm.controls.date.valueChanges.subscribe((values: [Date,Date] | null) => {
      if(!values[0] || !values[1]) {
        this.store.dispatch(fromSlotActions.setDafaultDay());
        this.store.dispatch(fromSlotActions.fetch());
        return;
      }
      let startMnt = moment(values[0]);
      let endMnt = moment(values[1]);
      let day = {
        WEEKDAY: false,
        SATURDAY: false,
      }
      for(let i=0; i <= Math.min(6,moment.duration(endMnt.diff(startMnt)).asDays()); i++) {
        switch(moment(values[0]).add(i, 'day').day()) {
          case 0: break;
          case 6: day.SATURDAY = true;break;
          default: day.WEEKDAY = true;break;
        }
      };
      this.store.dispatch(fromSlotActions.setWeekday({value: day.WEEKDAY}));
      this.store.dispatch(fromSlotActions.setSaturday({value: day.SATURDAY}));
      this.store.dispatch(fromSlotActions.fetch());
    })
    this.usersStore.dispatch(fromUsersActions.clearFilters());
    this.usersStore.dispatch(fromUsersActions.fetchUsers({limit:40}));
    this.usersInput$
      .pipe(distinctUntilChanged(),debounceTime(500))
      .subscribe((search) => this.usersStore.dispatch(fromUsersActions.setSearch({search})));
  }

  // get outposts(): Observable<Outpost[]> {
  //   return this.outpostStore.pipe(takeUntil(this.unsubscribe$), select(fromOutpostReducers.selectAllOutposts));
  // }

  loadMore() {
    this.list.pipe(take(1),map(list => list.length)).subscribe((skip:number) => {
      this.store.dispatch(fromSlotActions.fetchMore({skip}))
    })

  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  get slotsCtrl(): FormGroup {
    return this.filterForm.controls.slots as FormGroup;
  }

  get list(): Observable<Slot[]> {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromSlotReducers.selectAllSlots),
      tap((list:Slot[]) => {
        // this.filterForm.controls.slot = new FormGroup({});
        let ids = list.map(item => item.id.toString());
        Object.keys(this.slotsCtrl.controls).forEach((slot:string) => {
          if(ids.indexOf(slot) < 0) {
            this.slotsCtrl.removeControl(slot);
          }

        })
        ids.forEach((slot:string) => {
          this.slotsCtrl.addControl(slot, new FormControl(false));
        })
      })
    );
  }

  toggleSlot(id:string) {
    this.slotsCtrl.controls[id].setValue(!this.slotsCtrl.controls[id].value);
  }

  identify(index, entity: Outpost){ return entity.id }

  get total(): Observable<number>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromSlotReducers.selectState),
      map( (state:fromSlotReducers.State)  => state.total)
    );
  }

  get direction(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromSlotReducers.selectState),
      map( (state:fromSlotReducers.State)  => state.asc),
      tap( asc => this.asc = asc)
    );
  }

  get loading(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromSlotReducers.selectState),
      map( (state:fromSlotReducers.State)  => state.loading),
    );
  }

  get order(): Observable<string>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromSlotReducers.selectState),
      map( (state:fromSlotReducers.State)  => state.order),
      tap( sort => this.sort = sort)
    );
  }

  private sort: string;
  private asc: boolean;
  setSort(sort: string): void {
    if(sort == this.sort) {
      this.store.dispatch(fromSlotActions.setDirection({asc: !this.asc}));
    } else {
      this.store.dispatch(fromSlotActions.setDirection({asc: false }));
    }
    this.store.dispatch(fromSlotActions.setOrder({order: sort}));
  }

  toggleDirection(): void {
    this.store.dispatch(fromSlotActions.setDirection({asc: !this.asc}));
  }

  pageChange(page): void {
    this.store.dispatch(fromSlotActions.setPage({page: page}));
  }

  userInputLoading = false;
  get users$(): Observable<User[]> {
    return this.usersStore.pipe(select(fromUsersReducers.selectAllUsers));
  }
  usersInput$ = new Subject<string>();
  trackByFn(item: any) {
    return item.id;
  }

  save():void {
    Object.values(this.filterForm.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    if (this.filterForm.valid) {
      this.vacantStore.dispatch(fromVacantActions.createVacant({data: {
        notifications: this.notificationsCtrl.value,
        slots: Object.entries(this.filterForm.controls.slots.value)
                     .filter(([key,active]: [string,boolean]) => active)
                     .map(([key,active]: [string,boolean]) => +key),
        comment: this.filterForm.controls.comment.value,
        startDate: moment(this.filterForm.controls.date.value[0]).format('YYYY-MM-DD 00:00:00'),
        endDate: moment(this.filterForm.controls.date.value[1]).format('YYYY-MM-DD 00:00:00'),

        sendEmail: this.filterForm.controls.sendEmail.value,
        sendPush: this.filterForm.controls.sendPush.value,
        sendSms: this.filterForm.controls.sendSms.value,

        notificationUsers: this.filterForm.controls.notificationUsers.value.map((user) => user.id),
      }}))
      this.actionsSubject.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromVacantActions.addMany)
      ).subscribe( (action: any) => {
        this.toastr.success(`Dodano ${action.list.length} ogłoszeń`);
        // this.router.navigate(['/outposts', action.outpost.id, 'edit']);
        this.router.navigate(['/workmarket']);
      });

    } else {
      this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }

  @ViewChild('smsCheckbox') private smsCheckbox: ElementRef;
  toggleSms() {
    setTimeout(() => {
      this.filterForm.controls.sendSms.setValue(!this.filterForm.controls.sendSms.value);
    })
  }
  toggleNotificationSms(ctrl: FormControl) {
    setTimeout(() => {
      ctrl.setValue(!ctrl.value);
    })
  }

  get notificationsCtrl(): FormArray {
    return this.filterForm.controls.notifications as FormArray;
  }

  get notificationsList(): FormGroup[] {
    let list = [...this.notificationsCtrl.controls as FormGroup[]];
    list.sort((a,b) => {
      return b.controls.daysBefore.value - a.controls.daysBefore.value;
    });
    return list;
  }

  addNotification() {
    this.notificationsCtrl.push(new FormGroup({
        daysBefore: new FormControl(this.notificationsList.map(item => item.controls.daysBefore.value).reduce((prev, current)=> Math.max(prev, current),1) + 1),
        sms: new FormControl(false),
        push: new FormControl(true),
        email: new FormControl(true)
    }));
  }

  // get outpostLoading(): Observable<boolean>  {
  //   return this.outpostStore.pipe(
  //     takeUntil(this.unsubscribe$),
  //     select(fromOutpostReducers.selectState),
  //     map( (state:fromOutpostReducers.State)  => state.loading),
  //   );
  // }

}

import { AuthService } from '@/auth/auth.service';
import { User } from '@/model/user.model';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subject, timer } from 'rxjs';
import * as fromAuthActions from '@/store/auth/auth.actions';
import * as fromAuthReducers from '@/store/auth/auth.reducers';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { PasswordStrengthValidator } from '@/utils/pass.strength';


@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss']
})
export class EditPasswordComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  user: User;

  constructor(
    private toastr: ToastrService,
    private store: Store<fromAuthReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {

    this.form = new FormGroup({
      currentPassword: new FormControl(null, []),
      password: new FormControl(null, [Validators.required, PasswordStrengthValidator]),
      confirmPassword: new FormControl(null, [Validators.required, this.checkPasswords]),
    });
    this.form.controls.currentPassword.addAsyncValidators([this.checkCurrentPassword.bind(this)]);

    this.authService.getUser().then( (user: User) => {
        this.user = user;
        this.form.patchValue(user);
    })



  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => {
    let pass = this.form?.get('password')?.value;
    let confirmPass = this.form?.get('confirmPassword')?.value
    return pass === confirmPass ? null : { notSame: true }
  }

  checkCurrentPassword(control: AbstractControl) : Observable<ValidationErrors> {
    return timer(500).pipe(
      takeUntil(this.unsubscribe$),
      switchMap(() => this.authService.checkPassword(control.value)),
      tap(() => {
        control.markAllAsTouched();;
      }),
      map(result => result ? null : { wrongPass: true })
    );
  }

  get disabled(): Observable<boolean>  {
    return this.store.pipe(takeUntil(this.unsubscribe$),select(fromAuthReducers.selectAuthLoading));
  }

  save(): void {

    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
      // input.addAsyncValidators()
    });
    let valid = Object.values(this.form.controls).map(ctrl => ctrl.errors === null).reduce((a,b) => a && b, true);
    this.disabled.pipe(takeUntil(this.unsubscribe$),take(1)).subscribe( (disabled: boolean) => {
      console.log(this.form.errors, disabled)
      if (valid && !disabled) {

        this.actionsSubject$.pipe(
          takeUntil(this.unsubscribe$),
          filter((action) => action.type === fromAuthActions.setProfileAction.type),
        ).subscribe( (action:any) => {
          this.toastr.success("Zapisano zmiany");
          this.router.navigate(['/', 'profile'])
        })
        this.store.dispatch(fromAuthActions.editProfileAction({profile: this.form.value}));
      } else if(!disabled) {
        this.toastr.error("Formularz zawiera błędy");
      } else {
        this.toastr.warning("Trwa zapisywanie");
      }

    })
  }

}

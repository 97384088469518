import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import * as fromReducers from '@/store/call-center/call-center.reducers';
import * as fromActions from '@/store/call-center/call-center.actions';
import { CallCenterFill, CallCenterQuestionnaire } from '@/model/call-center.model';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FillNotificationModalComponent } from '../fill-notification-modal/fill-notification-modal.component';

@Component({
  selector: 'app-fill-questionnaire-call-center',
  templateUrl: './fill-questionnaire-call-center.component.html',
  styleUrls: ['./fill-questionnaire-call-center.component.scss']
})
export class FillQuestionnaireCallCenterComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  questionnaire: CallCenterQuestionnaire;
  fill: CallCenterFill;

  constructor(
    private toastr: ToastrService,
    private store: Store<fromReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected modalService: NgbModal,
  ) {
    let dateTime = new Date();
    dateTime.setHours(12);
    dateTime.setMinutes(0)
    dateTime.setSeconds(0);
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      user: new FormControl(null, Validators.required),
      date: new FormControl(dateTime, Validators.required),
      filePath: new FormControl(null, Validators.required),
      categories: new FormArray([this.emptyCategoryGroup]),
      score: new FormControl(0, Validators.required),
      comment: new FormControl(null),
      commentPrivate: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data: Data) => {
      if(data.questionnaire) {
        this.questionnaire = data.questionnaire;
      }
      if(data.fill) {
        this.fill = data.fill;
        this.questionnaire = data.fill.questionnaire;
      }
      this.questionnaire.categories.forEach((category, categoryIndex) => {
        if(categoryIndex !== 0){
          this.addCategory();
        }
        category.questions.forEach((question, index) => {
          if(index === 0)return;
          this.addQuestion(categoryIndex);
        })
      })
      this.form.patchValue(this.questionnaire);
      this.form.patchValue(this.fill);
    })
  }

  private get emptyCategoryGroup(): FormGroup {
    const group = new FormGroup({
      label: new FormControl(null),
      area: new FormControl(''),
      weight: new FormControl(0),
      questions: new FormArray([this.emptyQuestionGroup])
    });
    return group;
  }

  private get emptyQuestionGroup(): FormGroup {
    const group =  new FormGroup({
      label: new FormControl(null),
      weight: new FormControl(0),
      answer: new FormControl(false),
      score: new FormControl(0),
      comment: new FormControl(null),
      commentPrivate: new FormControl(null),
    });
    group.controls.comment.valueChanges.subscribe(value => {
      Object.values(this.categories).forEach( (category: FormGroup) => {
        category.controls.questions.updateValueAndValidity();
      });
    });
    group.controls.commentPrivate.valueChanges.subscribe(value => {
      Object.values(this.categories).forEach( (category: FormGroup) => {
        category.controls.questions.updateValueAndValidity();
      });
    });

    group.controls.answer.valueChanges.subscribe(value => {
      if(value === 'true') {
        group.controls.answer.setValue(true);
        return;
      }
      else if(value === 'false') {
        group.controls.answer.setValue(false);
        return;

      }
      else if(value === 'null') {
        group.controls.answer.setValue(null);
        return;
      }
      group.controls.score.setValue(value ? group.controls.weight.value : 0)
      Object.values(this.categories).forEach( (category: FormGroup) => {
        category.controls.questions.updateValueAndValidity();
      });
      let sum = [...this.categories.keys()]
                  .map(i => this.questions(i))
                  .map(questions => questions.map(q => q.value.score).reduce((a,b) => a+b, 0))
                  .reduce((a,b) => a+b, 0);
      this.form.controls.score.setValue(sum);
    })
    return group;
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isValid(ctrl: FormControl) {
    return ctrl.errors && ctrl.touched;
  }

  questions(categoryIndex: number): FormGroup[] {
    const category = this.categories[categoryIndex];
    const questionGroup = category.controls.questions as FormArray;
    return questionGroup.controls as FormGroup[];
  }

  get categories(): FormGroup[] {
    return (this.form.controls.categories as FormArray).controls as FormGroup[]
  }

  private addCategory(): void {
    (this.form.controls.categories as FormArray).controls.push(this.emptyCategoryGroup);
    this.form.controls.categories.updateValueAndValidity();
  }

  private addQuestion(categoryIndex: number): void {
    const category = this.categories[categoryIndex];
    const questionGroup = category.controls.questions as FormArray;
    questionGroup.controls.push(this.emptyQuestionGroup);
    Object.values(this.categories).forEach( (category: FormGroup) => {
      category.controls.questions.updateValueAndValidity();
    });
  }



  save() {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
      // input.addAsyncValidators()
    });
    let valid =  this.form.valid;
    if (valid) {
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromActions.createQuestionnaireFillSuccess)
      ).subscribe( (action:any) => {
        this.toastr.success("Zapisano ocenę");
        const nextRoute = this.fill?.id ? ['call-center/created'] : ['/call-center/admin']
        const modalRef = this.modalService.open(FillNotificationModalComponent, {size: 'xl', scrollable: false});
        // modalRef.componentInstance.fill = data.idea;
        modalRef.result
            .then(() => {
              this.store.dispatch(fromActions.sendFillNotify({item: action.item}));
              this.toastr.success("Wysłano powiadomienie");
              modalRef.close();
              this.router.navigate(nextRoute)
            })
            .catch(() => {
              this.router.navigate(nextRoute)
            })
      })
      if(!this.fill?.id) {
        this.store.dispatch(fromActions.createQuestionnaireFill({
          item: {
            ...this.form.value,
            questionnaire: this.questionnaire.id
          }
        }));
      } else {
        this.store.dispatch(fromActions.updateQuestionnaireFill({
          item: {
            ...this.fill,
            ...this.form.value,
          }
        }));
      }
    }
    else {
      this.toastr.error("Formularz zawiera błędy");
    }
  }

  get loading(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromReducers.selectState),
      map( (state:fromReducers.State)  => state.loading),
    );
  }

  get saving(): Observable<boolean>  {
    return this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(fromReducers.selectState),
      map( (state:fromReducers.State)  => state.saving),
    );
  }

  rowspan(categoryIndex): number {
    let rowspan = this.questions(categoryIndex).length + 1;
    let i = 1;
    while(categoryIndex + i !== this.categories.length && this.categories[categoryIndex].value.area === this.categories[categoryIndex + i].value.area) {
      rowspan += this.questions(categoryIndex + i).length + 1;
      i++;
    }
    return rowspan;
  }

  hasRowspan(categoryIndex): boolean {
    if(categoryIndex === 0) return true;
    return this.categories[categoryIndex].value.area !== this.categories[categoryIndex - 1].value.area;
  }

}

import { ReservationAsset, ReservationCategory } from "@/model/reservation.model";
import { createAction, props } from "@ngrx/store";

export interface ReservationCategoryPagination {total: number, data: ReservationCategory[]}

export const fetchAdminReservationCategory = createAction("fetchAdminReservationCategory");
export const fetchOneReservationCategory = createAction("fetchOneReservationCategory", props<{id: number}>());

export const setAdminPage = createAction( "setAdminPage Reservation", props<{ page: number }>());
export const setAdminOrder = createAction( "setAdminOrder Reservation", props<{ order: string }>());
export const setAdminDirection = createAction("setAdminDirection Reservation", props<{ direction: string }>());
export const setAdminSearch = createAction("setAdminSearch Reservation", props<{ search: string }>());
export const reset = createAction("reset reservation");
export const replaceAdminReservationCategory = createAction("replaceAdminReservationCategory", props<ReservationCategoryPagination>());

export const createReservationCategory = createAction("createReservationCategory", props<{ reservationCategory: ReservationCategory, image: any }>());
export const saveReservationCategory = createAction("saveReservationCategory", props<{ reservationCategory: ReservationCategory, image: any }>());
export const deleteReservationCategory = createAction("deleteReservationCategory", props<{ id: number }>());

export const addAdminReservationCategory = createAction("addAdminReservationCategory", props<{ reservationCategory: ReservationCategory }>());
export const updateAdminReservationCategory = createAction("updateAdminReservationCategory", props<{ id: number, changes: ReservationCategory }>());
export const removeAdminReservationCategory = createAction("removeAdminReservationCategory", props<{ id: number }>());

export const createAdminReservationAsset = createAction("createAdminReservationAsset", props<{ reservationAsset: ReservationAsset, image: any }>());
export const addAdminReservationAsset = createAction("addAdminReservationAsset", props<{ reservationAsset: ReservationAsset }>());

export const saveAdminReservationAsset = createAction("saveAdminReservationAsset", props<{ reservationAsset: ReservationAsset, image: any }>());
export const updateAdminReservationAsset = createAction("updateAdminReservationAsset", props<{ reservationAsset: ReservationAsset }>());

export const deleteAdminReservationAsset = createAction("deleteAdminReservationAsset", props<{ reservationAsset: ReservationAsset }>());
export const removeAdminReservationAsset = createAction("removeAdminReservationAsset", props<{ reservationAsset: ReservationAsset }>());

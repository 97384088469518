<section class="content">
    <div class="container">
        <form [formGroup]="form" (ngSubmit)="save()">
            <div class="card card-primary" style="border-top-left-radius: 0;border-top-right-radius: 0;">
                <div class="overlay position-absolute" *ngIf="(loading | async)" style="top: 0; bottom: 0; left: 0; right: 0">
                    <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
                      <i class="fas fa-3x fa-spinner fa-spin"></i>
                      <p>Ładowanie...</p>
                    </div>
                </div>
                <div class="overlay position-absolute" *ngIf="(saving | async)" style="top: 0; bottom: 0; left: 0; right: 0">
                    <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
                      <i class="fas fa-3x fa-spinner fa-spin"></i>
                      <p>Zapisywanie...</p>
                    </div>
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <label for="inputName">Nazwa formularza oceny</label>
                        <input formControlName="name" type="text" class="form-control" placeholder="Nazwa formularza oceny" [appIsValid]="form.controls.name"/>
                        <div class="invalid-feedback" *ngIf="form.controls.name.errors?.required">Pole jest wymagane</div>
                    </div>
                    <div class="form-group">
                      <label for="inputName">Rodzaj</label>
                      <label class="form-check  pl-0">
                        <input type="radio" formControlName="outgoing"  [value]="'Wychodzący'"/> Wychodzący
                      </label>
                      <label class="form-check  pl-0">
                        <input type="radio" formControlName="outgoing"  [value]="'Przychodzący'"/> Przychodzący
                      </label>
                    </div>

                    <div class="form-group">
                        <label for="inputName">Obszary</label>
                        <table class="table align-middle table-hover table-bordered">
                            <tr *ngFor="let areaCtrl of areas; let areaIndex=index">
                                <td>{{areaIndex + 1 }}</td>
                                <td>
                                <input [formControl]="areaCtrl" type="text" class="form-control" placeholder="Nazwa obszaru" [class.is-invalid]="areaCtrl.errors?.required"/>
                                <div class="invalid-feedback" *ngIf="areaCtrl.errors?.required">Pole jest wymagane</div>
                                </td>
                                <th width="1">
                                    <span class="btn btn-danger btn-sm elevation-2" (click)="delArea(areaIndex)">
                                        <i class="fas fa-trash fa-fw fa-lg"></i>
                                    </span>
                                </th>
                            </tr>
                            <tr>
                                <th colspan="3">
                                    <span class="btn btn-success btn-sm elevation-2" (click)="addArea()">
                                        <i class="fas fa-plus fa-fw fa-lg"></i>
                                        Dodaj obszar
                                    </span>
                                </th>
                            </tr>
                        </table>
                    </div>

                    <div class="form-group">
                        <label for="inputName">Pytania</label>
                        <table class="table align-middle table-hover table-bordered">
                            <ng-container *ngFor="let category of categories; let categoryIndex=index" [formGroup]="category">
                                <thead>
                                    <tr>
                                        <th class="bg-info align-top" colspan="2">
                                            <input formControlName="label" type="text" class="form-control" placeholder="Nazwa kategorii" [class.is-invalid]="isValid(category.controls.label)"/>
                                            <div class="invalid-feedback" *ngIf="category.controls.label.errors?.required">Pole jest wymagane</div>
                                        </th>
                                        <th class="bg-info align-top">
                                            <select formControlName="area" type="text" class="form-control" placeholder="Obszar" [class.is-invalid]="isValid(category.controls.area)">
                                                <option value="">- brak -</option>
                                                <option *ngFor="let areaCtrl of validAreas" [value]="areaCtrl.value">{{areaCtrl.value}}</option>
                                            </select>
                                            <div class="invalid-feedback" *ngIf="category.controls.area.errors?.required">Pole jest wymagane</div>
                                        </th>
                                        <th class="bg-info align-top">
                                            <input formControlName="weight" type="number" class="form-control" readonly  min="0" max="100"/>
                                        </th>
                                        <th class="bg-info" width="1">
                                            <div class="btn-group-vertical">
                                                <span class="btn btn-default btn-xs" (click)="moveCategoryUp(categoryIndex)" style="padding-top: 0.1rem; padding-bottom: 0.1rem; line-height: 1;">
                                                    <i class="fas fa-chevron-up"></i>
                                                </span>
                                                <span class="btn btn-default btn-xs" (click)="moveCategoryDown(categoryIndex)" style="padding-top: 0.1rem; padding-bottom: 0.1rem; line-height: 1;">
                                                    <i class="fas fa-chevron-down"></i>
                                                </span>
                                            </div>
                                        </th>
                                        <th class="bg-info" width="1">
                                            <span class="btn btn-danger btn-sm elevation-2" (click)="delCategory(categoryIndex)">
                                                <i class="fas fa-trash fa-fw fa-lg"></i>
                                            </span>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>#</th>
                                        <th>Pytanie</th>
                                        <th></th>
                                        <th width="150">Waga</th>
                                        <th colspan="2"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let question of questions(categoryIndex); let i = index" [formGroup]="question">
                                        <th>
                                            {{i + 1}}
                                        </th>
                                        <th  colspan="2">
                                            <input formControlName="label" type="text" class="form-control" placeholder="Pytanie" [class.is-invalid]="isValid(question.controls.label)"/>
                                            <div class="invalid-feedback" *ngIf="question.controls.label.errors?.required">Pole jest wymagane</div>
                                        </th>
                                        <th>
                                            <input formControlName="weight" type="number" class="form-control" placeholder="Pytanie" min="0" max="100"/>
                                        </th>
                                        <th width="1">
                                            <div class="btn-group-vertical">
                                                <span class="btn btn-default btn-xs" (click)="moveQuestionUp(categoryIndex, i)" style="padding-top: 0.1rem; padding-bottom: 0.1rem; line-height: 1;">
                                                    <i class="fas fa-chevron-up"></i>
                                                </span>
                                                <span class="btn btn-default btn-xs" (click)="moveQuestionDown(categoryIndex, i)" style="padding-top: 0.1rem; padding-bottom: 0.1rem; line-height: 1;">
                                                    <i class="fas fa-chevron-down"></i>
                                                </span>
                                            </div>
                                        </th>
                                        <th width="1">
                                            <span class="btn btn-danger btn-sm elevation-2" (click)="delQuestion(categoryIndex, i)">
                                                <i class="fas fa-trash fa-fw fa-lg"></i>
                                            </span>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th colspan="5">
                                            <span class="btn btn-success btn-sm elevation-2" (click)="addQuestion(categoryIndex)">
                                                <i class="fas fa-plus fa-fw fa-lg"></i>
                                                Dodaj pytanie
                                            </span>
                                        </th>
                                    </tr>
                                </tbody>
                            </ng-container>
                            <tfoot>
                                <tr>
                                    <th colspan="6">
                                        <span class="btn btn-success btn-sm elevation-2" (click)="addCategory()">
                                            <i class="fas fa-plus fa-fw fa-lg"></i>
                                            Dodaj kategorie
                                        </span>
                                    </th>
                                </tr>
                                <tr>
                                    <th colspan="3" class="text-right">
                                        Suma:
                                    </th>
                                    <th class="text-right">
                                        {{weightSum}}
                                    </th>
                                    <th colspan="2"></th>
                                </tr>
                                <tr *ngIf="weightSum != 100">
                                    <th colspan="4" class="text-right text-danger">
                                        Suma wag nie sumuje się do 100!
                                    </th>
                                    <th  colspan="2"></th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <!-- <pre>{{form.value | json}}</pre> -->
                <div class="card-footer">
                    <button class="btn btn-success float-right" type="submit" [disabled]="weightSum != 100">
                        Zapisz
                        <i class="fas fa-angle-right fa-fw fa-lg"></i>
                    </button>
                </div>
            </div>
        </form>
    </div>
</section>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outposts',
  templateUrl: './outposts.component.html',
  styleUrls: ['./outposts.component.scss']
})
export class OutpostsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Offer } from "@/model/offer.model";
import { createAction, props } from "@ngrx/store";

export const fetch = createAction(
  "offers fetch",
  props<{ skip: number }>()
);

export const fetchOne = createAction(
  "offers fetchOne",
  props<{ id: number }>()
);

export const addMany = createAction(
  "offers addMany",
  props<{ offers: Offer[], total: number }>()
);

export const createOffer = createAction(
  "offers createOffer",
  props<{ offer: Offer, background?: any }>()
);

export const addOne = createAction(
  "offers addOne",
  props<{ offer: Offer }>()
);

export const saveOne = createAction(
  "offers saveOne",
  props<{ offer: Offer, background?: any }>()
);

export const updateOne = createAction(
  "offers updateOne",
  props<{ offer: Offer }>()
);

export const deleteOne = createAction(
  "offers deleteOne",
  props<{ offer: Offer }>()
);

export const removeOne = createAction(
  "offers removeOne",
  props<{ offer: Offer }>()
);

export const setAsSeen = createAction(
  "offers setAsSeen",
  props<{ offer: Offer }>()
);

export const likeOfferAction = createAction(
  "offers likeOfferAction",
  props<{ offer: Offer }>()
);

export const unlikeOfferAction = createAction(
  "offers unlikeOfferAction",
  props<{ offer: Offer }>()
);


import { tinyMceConfig } from '@/config/tinymce.config';
import { Group } from '@/model/group.model';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef, NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, OperatorFunction, Observable, merge, of } from 'rxjs';
import { takeUntil, filter, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import * as fromMessageActions  from '@/store/messages/messages.actions'
import * as fromMessageReducer  from '@/store/messages/messages.reducers'
import * as fromGroupActions  from '@/store/group/group.actions'
import * as fromGroupReducer  from '@/store/group/group.reducers'
import { User } from '@/model/user.model';
import { generalError } from '@/store/error.store';
import { ofType } from '@ngrx/effects';
import { plainText } from '@/utils/plainText';
import { HttpClient } from '@angular/common/http';
import { SmsConfirmModalComponent } from '@components/sms-confirm-modal/sms-confirm-modal.component';

@Component({
  selector: 'app-create-messages',
  templateUrl: './create-messages.component.html',
  styleUrls: ['./create-messages.component.scss']
})
export class CreateMessagesComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('instanceGroup', {static: true}) instanceGroup: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  form: FormGroup;
  tinyMceConfig = tinyMceConfig;
  typeaheadGroupModel: any;
  typeaheadGroupList: Group[] = [];

  typeaheadUserModel: any;
  typeaheadUsersList: User[] = [];

  formatter = (group: Group) => `${group.name}`;
  formatterEmpty = () => null;
  groups: Group[];
  shortened = "";

  constructor(
    private appService: AppService,
    private storeMessage: Store<fromMessageReducer.State>,
    private storeGroup: Store<fromGroupReducer.State>,
    private httpClient: HttpClient,
    // private storePost: Store<fromPostReducer.PostsState>,
    private toastr: ToastrService,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    protected modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.storeGroup.dispatch(fromGroupActions.fetchAllAction());

    this.storeGroup.pipe(takeUntil(this.unsubscribe$), select(fromGroupReducer.selectAllGroups)).subscribe( (groups: Group[]) => {
      this.groups = groups;
    });
    this.form = new FormGroup({
      title: new FormControl(null, Validators.required),
      content: new FormControl(null, Validators.required),
      sendPush: new FormControl(false),
      sendSms: new FormControl(false),
      sendEmail: new FormControl(false),
      sendTo: new FormControl(null, Validators.required),
      groups: new FormArray([], null),
      recepients: new FormArray([], [Validators.required]),
    });
    this.form.patchValue({ sendTo: 'users' });
    this.form.controls.content.valueChanges.subscribe( (value) => {
      this.shortened = plainText(value, 160);
    });

    this.form.controls.sendTo.valueChanges.subscribe((value) => {
      switch(value) {
        case 'users':
          this.form.controls.recepients.addValidators([Validators.required]);
          this.form.controls.groups.removeValidators([Validators.required]);
          break;
        case 'groups':
          this.form.controls.recepients.removeValidators([Validators.required]);
          this.form.controls.groups.addValidators([Validators.required]);
          break;
        case 'all':
          this.form.controls.recepients.removeValidators([Validators.required]);
          this.form.controls.groups.removeValidators([Validators.required]);
          break;
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onError() {
    console.log("OK");
  }

  submit() {
      this.actionsSubject$.pipe(takeUntil(this.unsubscribe$),ofType(generalError.type)).subscribe(action => {
        this.form.enable();
      });
      let message = {...this.form.value};
      this.storeMessage.dispatch( fromMessageActions.createNewMessage({message}));
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        filter((action) => action.type === fromMessageActions.success.type)
      ).subscribe( (_) => {
        this.form.enable();
        this.toastr.success('Wysłano wiadomość!');
        this.router.navigate(['/messages/inbox']);
      });
  }

  save(): void {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
    });
    if (this.form.valid && this.form.enabled) {
      this.form.disable();
      if(this.form.value.sendSms) {
        this.httpClient.post<{count: number}>(`<backendhost>/v1/messages/0/count-sms`, this.form.value).subscribe( (result: {count: number}) => {
          const modalRef: NgbModalRef = this.modalService.open(SmsConfirmModalComponent);
          modalRef.componentInstance.count = result.count;
          modalRef.result
          .then(() => {
            // this.form.enable();
            this.submit();
          })
          .catch(() => {
            this.form.enable();
            this.form.patchValue({sendSms: false});
          })
        });

      } else {
        this.submit();
        // this.form.enable();
        // this.form.reset();
      }

    } else if (this.form.enabled){
      // this.form.enable();
      this.toastr.error('Formularz jest nieprawidłowy!');

      console.log( Object.values(this.form.controls).map((c,k) => c.errors), Object.keys(this.form.controls));
    } else {
    }
  }

  searchGroups: OperatorFunction<string, readonly Group[]> = (text$: Observable<string>) => {
    // const debouncedText$ = text$.pipe(takeUntil(this.unsubscribe$), debounceTime(200), distinctUntilChanged());
    // const clicksWithClosedPopup$ = this.click$.pipe(takeUntil(this.unsubscribe$), filter(() => !this.instanceGroup.isPopupOpen()));
    // const inputFocus$ = this.focus$;

    // return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
    //   takeUntil(this.unsubscribe$),
    //   map(term => (term === '' ? this.groups
    //     : this.groups.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    // );
    return text$.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term =>
        this.appService.searchGroups(term)
      ),
    );
  }

  get groupCtrl(): FormArray {
    return this.form.get('groups') as FormArray;
  }

  onGroupsTypeaheadSelect(selectedItem: NgbTypeaheadSelectItemEvent) {
    let group: Group = selectedItem.item;
    this.typeaheadGroupList.push(group);
    this.groupCtrl.push(new FormControl(group.id))
  }

  removeGroup(item: Group) {
    let idx = this.typeaheadGroupList.map(it => it.id).indexOf(item.id);
    this.typeaheadGroupList.splice(idx, 1);
    this.groupCtrl.removeAt(idx);
  }

  searchUsers: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    return text$.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term =>
        this.appService.searchUser(term)
      ),
    );
  }

  get recepientsCtrl(): FormArray {
    return this.form.get('recepients') as FormArray;
  }

  onUsersTypeaheadSelect(selectedItem: NgbTypeaheadSelectItemEvent) {
    let user: User = selectedItem.item;
    this.typeaheadUsersList.push(user);
    this.recepientsCtrl.push(new FormControl(user.id))
  }

  removeUser(item: User) {
    let idx = this.typeaheadUsersList.map(it => it.id).indexOf(item.id);
    this.typeaheadUsersList.splice(idx, 1);
    this.recepientsCtrl.removeAt(idx);
  }

}

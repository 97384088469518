<div class="content">
  <div class="container pt-3 pb-3">
    <app-group-card [group]="group | async" *ngIf="group | async" [linkToMembers]="false"></app-group-card>
    <!-- <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(posts | async).length == 0"></nz-empty> -->
    <div class="card">

      <div class="card-header">
        <h3 class="card-title">Członkowie grupy</h3>
      </div>
      <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="members.length == 0"></nz-empty>
      <table class="table" *ngIf="members.length">
        <thead>
          <tr>
            <th style="width:1px">#</th>
            <th colspan=2>Użytkownik</th>
            <th>Należy ok</th>
          </tr>
        </thead>
        <tbody *ngIf="group | async">
          <tr *ngFor="let item of members; let idx = index; trackBy: groupMemberIdentity" >
            <td  >{{idx+1 + (page-1)*pageSize}}</td>
            <td class="user-panel" style="width:1px">
              <img
                    [src]="item.user | userAvatar: 34"
                    class="img-circle elevation-2"
                    alt="User Image"
                    [appUserDisactivatable]="item.user"
                />
            </td>
            <td>
                <strong [appUserDisactivatable]="item.user">{{item.user.firstname}} {{item.user.lastname}}</strong> <br>
                <small [appUserDisactivatable]="item.user">{{item.user?.active ? item.user?.email : '-' }}</small>
            </td>
            <td>
              <span>{{item.createdAt | amUtc | amLocal | amDateFormat:'DD.MM.YYYY HH:mm'}}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="card-footer" *ngIf="members.length">
        <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="members.length" (pageChange)="onPageChange()">
          <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
          <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
  </div>
  <!-- <pre>{{ group | async | json}}</pre> -->
</div>

import { OfferCategory } from "@/model/offer.model";
import { StringifyHttpErrorResponse } from "@/utils/http.error.util";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { catchError, map, switchMap } from "rxjs/operators";

import * as fromActions from './offer.category.action'
import * as fromReducers from './offer.category.reducers'

@Injectable()
export class OfferCategoryEffects{
  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private store$: Store<fromReducers.State>,
  ) {}

  onError (err, caught): any {
    this.toastr.error(StringifyHttpErrorResponse(err));
    return caught;
  };


  fetch$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetch),
    switchMap(() => {
      return this.httpClient.get<OfferCategory[]>('<backendhost>/v1/offer-category', {
        observe: 'body',
        responseType: 'json'
      })
    }),
    catchError(this.onError.bind(this)),
    switchMap((result: any) => [fromActions.addMany({list: result})])
  ));

  createOfferCategory$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createOfferCategory),
    switchMap((action) => {
      return this.httpClient.post<OfferCategory>('<backendhost>/v1/offer-category', action.item, { observe: 'body', responseType: 'json'});
    }),
    catchError(this.onError.bind(this)),
    switchMap((item: OfferCategory) => {
      return [
        fromActions.addOne({item})
      ];
    })
  ));

  updateOfferCategory$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.updateOfferCategory),
    switchMap((action) => {
      return this.httpClient.put<OfferCategory>(`<backendhost>/v1/offer-category/${action.item.id}`, action.item, { observe: 'body', responseType: 'json'});
    }),
    catchError(this.onError.bind(this)),
    switchMap((item: OfferCategory) => [
      fromActions.updateOne({item})
    ])
  ));

  deleteOfferCategory$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deleteOfferCategory),
    switchMap( (action) => {
      let url = `<backendhost>/v1/offer-category/${action.id}` ;
      return this.httpClient.delete<any>(url, {observe: 'body', responseType: 'json'}).pipe(map( () => action.id));
    }),
    catchError(this.onError.bind(this)),
    switchMap((id: number) => [
      fromActions.removeOne({id})
    ])
  ));

}

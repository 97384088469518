

<ul class="nav nav-tabs">
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active bg-primary" [routerLink]="['/workmarket']" [routerLinkActiveOptions]="{ exact: true }">
      Preferowane
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active bg-primary" [routerLink]="['/workmarket','empty']" [routerLinkActiveOptions]="{ exact: true }">
      Wszystkie wolne
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active bg-primary" [routerLink]="['/workmarket','reserved']" [routerLinkActiveOptions]="{ exact: true }">
      Moje zarezerwowane
    </a>
  </li>
  <li class="nav-item" [checkRolePermition]="['vacant.create','vacant.createMy']">
    <a class="nav-link" routerLinkActive="active bg-primary" [routerLink]="['/workmarket','created']" [routerLinkActiveOptions]="{ exact: true }">
      Które utworzyłem
    </a>
  </li>
  <li class="nav-item" checkRolePermition="vacant.viewAll">
    <a class="nav-link" routerLinkActive="active bg-primary" [routerLink]="['/workmarket','all-reserved']" [routerLinkActiveOptions]="{ exact: true }">
      Wszystkie zarezerwowane
    </a>
  </li>
  <li style="flex-grow: 1;"></li>
  <li class="nav-item" checkRolePermition="vacant.viewAll">
    <a class="nav-link"
      style="border-color: #dee2e6 #dee2e6 #fff;"
      routerLinkActive="active bg-primary"
      [routerLink]="['/workmarket','to-confirm']"
      [routerLinkActiveOptions]="{ exact: true }">
      <span class="badge rounded-pill bg-secondary">
        {{total | async}}
      </span>
      Ogłoszenia do potwierdzenia
    </a>
  </li>
</ul>
<div class="card">
  <div class="table-responsive position-relative">
    <table class="table align-middle table-hover">
      <thead>
        <tr>
          <th class="align-middle" style="width:1rem" rowspan="2">#</th>
          <th class="align-middle sortable" (click)="setSort('user')"  [ngClass]="{'active': (order | async) == 'user'}" colspan="2" rowspan="2">
            Użytkownik
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <th class="align-middle sortable" (click)="setSort('startDate')"  [ngClass]="{'active': (order | async) == 'startDate'}"  rowspan="2">
            Od
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <th class="align-middle sortable" (click)="setSort('endDate')"  [ngClass]="{'active': (order | async) == 'endDate'}"  rowspan="2">
            Do
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <th class="align-middle sortable" (click)="setSort('createdAt')"  [ngClass]="{'active': (order | async) == 'createdAt'}" rowspan="2">
            Utworzono
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <th class="text-center" colspan="3">Sloty</th>
          <th class="align-middle text-right" rowspan="2"></th>
        </tr>
        <tr>
          <th>Placówka</th>
          <th>Rodzaj</th>
          <th>Dzień tygodnia</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of list | async; let idx = index; trackBy: identify">
        <tr>
          <th class="align-middle" [attr.rowspan]="item.slots.length">{{idx + 1 + (page-1)*pageSize}}</th>
          <td class="user-panel" style="width:70px" [attr.rowspan]="item.slots.length">
            <img
                  [src]="item.createdBy  | userAvatar: 34"
                  class="img-circle elevation-2"
                  alt="User Image"
                  style="width:45px"
                  [appUserDisactivatable]="item.createdBy"
              />
          </td>
          <td [attr.rowspan]="item.slots.length">
            <strong [appUserDisactivatable]="item.createdBy">{{item.createdBy?.firstname}} {{item.createdBy?.lastname}}</strong> <br>
            <small [appUserDisactivatable]="item.createdBy">{{item.createdBy?.email}}</small>
          </td>
          <td class="align-middle" [attr.rowspan]="item.slots.length">{{item.startDate | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD (dddd)' }}</td>
          <td class="align-middle" [attr.rowspan]="item.slots.length">{{item.endDate | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD (dddd)' }}</td>
          <td class="align-middle" [attr.rowspan]="item.slots.length">{{item.createdAt | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>

          <td class="align-middle">{{item.slots[0].outpost.name}}</td>
          <td class="align-middle">{{item.slots[0].type}}</td>
          <td class="align-middle">{{item.slots[0].day == "WEEKDAY" ? "Dzień roboczy" : "Sobota"}}</td>

          <td class="align-middle text-right" [attr.rowspan]="item.slots.length">
            <button
              class="btn btn-success  btn-sm elevation-2 mr-2"
              nz-popconfirm
              nzPopconfirmTitle="Czy na pewno?"
              nzOkText="Tak"
              nzCancelText="Nie"
              nzPopconfirmPlacement="left"
              (nzOnConfirm)="confirm(item)"
              ngbTooltip="Potwierdź">
              <i class="fas fa-check"></i>
              Potwierdź
            </button>
            <button
              class="btn btn-danger btn-sm elevation-2 mr-2"
              nz-popconfirm
              nzPopconfirmTitle="Czy na pewno?"
              nzOkText="Tak"
              nzCancelText="Nie"
              nzPopconfirmPlacement="left"
              (nzOnConfirm)="reject(item)"
              ngbTooltip="Odrzuć"
              >
              <i class="fas fa-check"></i>
              Odrzuć
            </button>
          </td>
        </tr>
        <tr *ngFor="let slot of item.slots | slice:1">
          <td class="align-middle">{{slot.outpost.name}}</td>
          <td class="align-middle">{{slot.type}}</td>
          <td class="align-middle">{{slot.day == "WEEKDAY" ? "Dzień roboczy" : "Sobota"}}</td>
        </tr>
      </ng-container>
      </tbody>
    </table>
    <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(list | async).length == 0"></nz-empty>
    <div class="overlay position-absolute" *ngIf="(loading | async)" style="top: 0; bottom: 0; left: 0; right: 0">
      <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
        <i class="fas fa-3x fa-spinner fa-spin"></i>
        <p>Ładowanie...</p>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="(total | async)"  (pageChange)="pageChange($event)">
      <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationEllipsis>...</ng-template>
      <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>
  </div>
</div>

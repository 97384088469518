import { Quiz } from "./quiz.model";
import { Scorm, Training } from "./scorm.model";
import { User } from "./user.model";

export interface Slide {
  id: number;
  type: string;
  name: string;
  position: number;
  content?: string;
  scorm?: Scorm,
  quiz?: Quiz,
  url?: string,
  completeTimeout?: number;
  video?: any;
  audio?: any;
}

export const SlideType = (slide: Slide): string => {
  switch (slide.type) {
    case 'content':
      return "Blok z treścią";
    case 'scorm':
      return "Szkolenie SCORM";
    case 'youtube':
      return "Youtube";
    case 'video':
      return "Plik Video";
    case 'quiz':
      return "Quiz";
  }
}

export interface Multimedia extends Training {
  slides: Slide[];
}

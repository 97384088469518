import { Slot, SlotNotificationUser } from "@/model/slot.model";
import { PaginationDTO } from "@/model/pagination.model";
import { createAction, props } from "@ngrx/store";
import { User } from "@/model/user.model";

export const clear = createAction("slot-notification clear");
export const fetch = createAction("slot-notification fetch");

export const setSlot = createAction("slot-notification setSlot", props<{ slot: Slot }>());
export const setPage = createAction("slot-notification setPage", props<{ page: number }>());
export const setOrder = createAction("slot-notification setOrder", props<{ order: string }>());
export const setDirection = createAction("slot-notification setDirection", props<{ asc: boolean }>());
export const setSearch = createAction("slot-notification setSearch", props<{ search: string }>());

export const createSlotNotificationUser = createAction("slot-notification createSlotNotificationUser", props<{user: User}>());
export const deleteSlotNotificationUser = createAction("slot-notification deleteSlotNotificationUser", props<{id: number}>());
export const setSlotNotificationUserSms = createAction("slot-notification setSlotNotificationUserSms", props<{id: number}>());
export const unsetSlotNotificationUserSms = createAction("slot-notification unsetSlotNotificationUserSms", props<{id: number}>());

export const setMany = createAction("slot-notification setMany", props<PaginationDTO<SlotNotificationUser>>());
export const addOne = createAction("slot-notification addOne", props<{item: SlotNotificationUser}>());
export const removeOne = createAction("slot-notification removeOne", props<{id: number, keep: number}>());

import { Component, OnDestroy, OnInit } from '@angular/core';
import * as fromPostReducers from '@/store/post/post.reducers';
import * as fromPostActions from '@/store/post/post.actions';

import * as fromGroupReducers from '@/store/group/group.reducers';
import * as fromGroupActions from '@/store/group/group.actions';


import { ActionsSubject, select, Store } from '@ngrx/store';
import { merge, Observable, of, Subject, zip } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { distinctUntilChanged, filter, map, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Post } from '@/model/post.model';
import { Group } from '@/model/group.model';

@Component({
  selector: 'app-list-news',
  templateUrl: './list-news.component.html',
  styleUrls: ['./list-news.component.scss']
})
export class ListNewsComponent implements OnInit, OnDestroy {
  protected readonly unsubscribeLoadBetween$: Subject<void> = new Subject();
  protected readonly unsubscribe$: Subject<void> = new Subject();
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  // posts: Post[];
  newestId = null;
  oldestId = null;
  groupId = null;
  group: Group;

  constructor(
    protected store: Store<fromPostReducers.PostsState>,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected actionsSubject$: ActionsSubject,
  ) {}

  protected onInit(): void {
    this.router.navigate([{outlets: { sidebar: 'news'}}]);
    this.store.dispatch(fromPostActions.clear());
    this.store.dispatch(fromPostActions.setHasMore({hasMore: true, loading: true}));
    this.actionsSubject$.pipe(
      takeUntil(this.unsubscribeLoadBetween$),
      filter((action) => action.type === fromPostActions.addMultipleNews.type),
      map( (action:any) => action.posts)
    ).subscribe( (newPosts:Post[]) => {
      this.posts.pipe(take(1)).subscribe((allPosts: Post[]) => {
        let L = newPosts.length;
        this.newestId = L && this.newestId < newPosts[0].id ? newPosts[0].id : this.newestId;
        this.oldestId = L && (!this.oldestId || this.oldestId > newPosts[L-1].id) ? newPosts[L-1].id : this.oldestId;
        if(allPosts.length == 0) {

        }
        else if (this.oldestId > allPosts[allPosts.length-1].id) {
          this.loading.pipe(take(1),filter(x => x)).subscribe( () => {
            this.store.dispatch(fromPostActions.fetchNewsAction({observed: true, before: this.oldestId}));
          });
        }
        else {
          // this.unsubscribeLoadBetween$.next();
          // this.unsubscribeLoadBetween$.complete();
        }
      });
    })
  }

  ngOnInit(): void {
    this.onInit();
    this.store.dispatch(fromPostActions.fetchNewsAction({observed: true}));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.unsubscribeLoadBetween$.next();
    this.unsubscribeLoadBetween$.complete();
  }

  // get state(): Observable<fromPostReducers.PostsState> {
  //   return this.store.select(fromPostReducers.selectPostsState).pipe(tap( state => {
  //     let L = state.ids.length;
  //     this.newestId = L && this.newestId < state.ids[0] ? state.ids[0] : this.newestId;
  //     this.oldestId = L && this.oldestId < state.ids[L-1] ? state.ids[L-1] : this.oldestId;
  //     console.log("UPDATE", state);
  //   }))
  // }

  get hasMore(): Observable<boolean> {
    return this.store.select(fromPostReducers.selectHasMore);
  }

  get loading(): Observable<boolean> {
    return this.store.select(fromPostReducers.selectLoading);
  }

  get posts(): Observable<Post[]> {
    return this.store.select(fromPostReducers.selectPostsObserved);//.pipe(tap(console.log))
  }

  prev(index: number): Observable<Post> {
    return index ? this.posts.pipe(map( (arr:Post[]) => arr[index-1])) : of(null);
  }

  before(post:Post, index: number): Observable<boolean> {
    return index ?
      this.prev(index).pipe(
        map( (prev:Post) => {
          return new Date(prev.createdAt).getDate() !== new Date(post.createdAt).getDate()
        })
      )
      : of(true);
  }

  onScroll(): void {
    this.loadMore();
  }

  onScrollUp(): void {
    // this.loadMore();
  }

  async loadMore() {
    zip(this.loading, this.hasMore).pipe(
      map( (arr: boolean[]) => !arr[0] && arr[1]),
      filter(s => s)
    ).subscribe( (s: any) => {
      this.dispatchMore();
    });
  }

  protected dispatchMore() {
    this.store.dispatch(fromPostActions.fetchNewsAction({observed: true, before: this.oldestId }));
  }

  identify(index,post:Post){ return post.id }

  onPostView(post: Post): void {
    if (post.seen === false) {
      this.store.dispatch(fromPostActions.setAsSeen({post: post}));
    }
  }
}

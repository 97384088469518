<div class="card widget-user">
  <div
    *ngIf="(folder | async)?.image"
    class="widget-user-header bg-primary text-white"
    style="height:0"
    [ngStyle]="backgroundStyle(folder | async)">
  </div>


  <div class="overlay dark" *ngIf="(loading | async)">
    <i class="fas fa-3x fa-spinner fa-spin"></i>
  </div>
  <div class="card-body" [innerHtml]="(folder | async)?.description | safeHtml" *ngIf="(folder | async)?.description"></div>
  <nz-empty
    nzNotFoundImage="simple"
    [nzNotFoundContent]="'Nic tu nie ma'"
    *ngIf="(children | async).length == 0 && (files | async).length == 0 && (articles | async).length == 0"
  >
  </nz-empty>
  <div class="card-footer mt-0 pt-3"  *ngIf="(children | async).length > 0">
    <h3 class="card-title text-bold text-center">Podfoldery:</h3>
  </div>
  <div class="card-body"  *ngIf="(children | async).length > 0">
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let item of children | async; let idx = index; trackBy: identify" [ngbTooltip]="item.title">
        <div class="info-box shadow" [routerLink]="['/kb', item.id]" style="cursor: pointer;">
          <span class="info-box-icon bg-primary" [ngStyle]="backgroundSubStyle(item)"><i class="far fa-folder-open"></i></span>
          <div class="info-box-content"  style="overflow: hidden;">
            <span class="info-box-text">{{item.title}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer mt-0 pt-3" *ngIf="(articles | async).length > 0">
    <h3 class="card-title text-bold text-center">{{ (folder | async).articleLabel}}:</h3>
  </div>
  <div class="card-header d-flex" *ngFor="let article of articles | async; let idx = index">
    <div class="pr-3" style="cursor:pointer" [routerLink]="['article', article.id]">
      <img [src]="article | groupAvatar:45:true:'title'" />
    </div>
    <div class="pr-3 flex-grow-1" style="cursor:pointer" [routerLink]="['article', article.id]">
      <p class="text-bold text-md">{{article.title}}</p>
      <p class="text-sm">{{article.short}}</p>
    </div>
    <div class="user-block">
      <img class="img-circle" [src]="article.createdBy | userAvatar" alt="User Image"  [appUserDisactivatable]="article.createdBy">
      <span class="username">
        <span class="text-decoration-line-through" *ngIf="!article?.createdBy">Użytkownik usunięty</span>
        <a  *ngIf="article?.createdBy" [routerLink]="['/profile', article.createdBy?.id]"  [appUserDisactivatable]="article.createdBy">{{article.createdBy?.name}}</a>
      </span>
      <span class="description">Utworzono  {{article?.createdAt | amLocale:'pl' | amCalendar }}</span>
    </div>
  </div>
  <div class="card-footer mt-0 pt-3" *ngIf="(files | async).length > 0">
    <h3 class="card-title text-bold text-center">Pliki:</h3>
    <div class="btn-group btn-group-toggle float-right" ngbRadioGroup name="radioBasic" [(ngModel)]="folderViewType">
      <label ngbButtonLabel [ngClass]="{'btn-primary': folderViewType == 'box', 'btn-default' : folderViewType !== 'box'}">
        <input ngbButton type="radio" value="box"> <i class="fas fa-th-large fa-fw"></i>
      </label>
      <label ngbButtonLabel [ngClass]="{'btn-primary': folderViewType == 'list', 'btn-default' : folderViewType !== 'list'}">
        <input ngbButton type="radio" value="list"> <i class="fas fa-list fa-fw"></i>
      </label>
    </div>
  </div>
  <div class="table-responsive" *ngIf="folderViewType == 'list'">
    <table class="table align-middle table-hover">
      <thead>
        <tr>
          <th style="width:1rem">#</th>
          <th style="width:1rem"></th>
          <th>Tytuł pliku</th>
          <th>Opis</th>
          <th>Rozmiar</th>
          <th>Utworzono</th>
          <th class="text-right" ></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let file of files | async; let idx = index; trackBy: identify" >
          <td class="align-middle">{{idx + 1}}</td>
          <td class="align-middle">
            <span class="btn btn-circle btn-default img-circle" style="width:45px; height: 45px;" *ngIf='!isImage(file)'>
              <i class="fas fa-file"></i>
            </span>
            <img class="img-circle" [src]="file | groupAvatar:45:true:'name':'path'"  *ngIf='isImage(file)'/>
          </td>
          <td class="align-middle">{{file.title}}</td>
          <td class="align-middle">{{file.description}}</td>
          <td class="align-middle">{{file.size | fileSize}}</td>
          <td class="align-middle">{{file.createdAt | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
          <td class="align-middle text-right">
            <a class="btn btn-primary btn-sm elevation-2" (click)="download(file)">
              <i class="fas fa-download fa-fw fa-lg"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="card-body" *ngIf="folderViewType == 'box'">
    <div class="row">
      <div class="col-lg-2 col-md-3 col-sm-4 col-6" *ngFor="let item of files | async; let idx = index; trackBy: identifyFile">
        <app-file-item [item]="item"></app-file-item>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <div [ngStyle]="backgroundStyle()"></div>
  <div class="card-header pb-1">
    <div class="user-block">
      <img class="img-circle" [src]="article?.createdBy | userAvatar" alt="User Image" [appUserDisactivatable]="article.createdBy">
      <span class="username">
        <span class="text-decoration-line-through" *ngIf="!article?.createdBy">Użytkownik usunięty</span>
        <a [routerLink]="['/profile', article?.createdBy?.id]" *ngIf="article.createdBy" [appUserDisactivatable]="article.createdBy">
          {{article?.createdBy?.firstname}} {{article?.createdBy?.lastname}}
        </a>
      </span>
      <!-- <span class="description"> {{post.createdAt | amLocale:'pl' | amTimeAgo }}</span> -->
      <span class="description"> {{article?.createdAt | amLocale:'pl' | amCalendar }}</span>
      <span class="description">
        <a class="btn btn-link p-0 pr-1 text-sm" *ngFor="let tag of article?.tags" [routerLink]="['/search', tag | lowercase]">#{{tag}}</a>
      </span>

    </div>
    <div class="card-tools d-none">
      <button type="button" class="btn btn-tool" title="Mark as read">
        <i class="far fa-circle"></i>
      </button>
      ...
    </div>
  </div>
  <div class="card-header pt-2" >
    <h3 class="text-primary card-title text-lg m-0">
      {{article?.title}}
    </h3>
  </div>
  <div class="card-body" *ngIf="audioPathPlyr.length">
    <plyr *ngIf="audioPathPlyr.length"
      [plyrPlaysInline]="false"
      [plyrSources]="audioPathPlyr"
      plyrType="audio"
      (plyrInit)="player = $event"
      [plyrOptions]="audioOptions"></plyr>
  </div>
  <div class="card-body" [innerHtml]="article?.content | safeHtml"></div>
  <div class="card-body" *ngIf="article?.faq?.length > 0">
    <ngb-accordion [closeOthers]="true" [activeIds]="fragment">
      <ngb-panel [id]="'faq-' + faq.id" [title]="faq.question" *ngFor="let faq of article?.faq; let idx = index" [cardClass]="'card m-0'">
        <ng-template ngbPanelContent style="position: relative;">
          <a id="faq-{{faq.id}}-ancor"  style="position: relative;top:-150px"></a>
          <div [innerHtml]="faq.answer | safeHtml"></div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</div>
<!--  -->

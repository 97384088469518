import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import * as fromActions from '@/store/structure/structure.actions';
import * as fromReducers from '@/store/structure/structure.reducers';
import { select, Store } from '@ngrx/store';
import { Branch } from '@/model/branch.model';
import { filter, take, takeUntil } from 'rxjs/operators';
import { environment } from '@/../environments/environment';
import { User } from '@/model/user.model';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { ImagePipe } from '@/pipes/image.pipe';

@Component({
  selector: 'app-list-structure',
  templateUrl: './list-structure.component.html',
  styleUrls: ['./list-structure.component.scss']
})
export class ListStructureComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  private imagePipe: ImagePipe;
  loading = false;


  constructor(
    private store: Store<fromReducers.State>,
    private httpClient: HttpClient,
    private renderer: Renderer2,
  ) {
    this.imagePipe = new ImagePipe()
  }

  list: Branch[];
  members: User[];
  current: Branch;

  ngOnInit(): void {
    this.store.pipe(
      select(fromReducers.isFetched),
      take(1),
      takeUntil(this.unsubscribe$),
      filter( feteched => !feteched)
    ).subscribe((fetched) => {
      this.store.dispatch(fromActions.fetch());
    });
    this.store.pipe(takeUntil(this.unsubscribe$), select(fromReducers.selectRoot)).subscribe((list) => {
      this.list = list;
      this.current = list[0];
      this.fetchMembers();
    })
  }

  ngAfterContentInit(): void {
    // // @ts-expect-error
    // let instance = OverlayScrollbars(document.getElementById("treeMap"));
    // if(instance) {
    //   instance.options('callbacks.onScroll', this.onSidebarScroll.bind(this));
    //   instance.options('scrollbars.autoHide', "never");
    // }
    // else {
    //   // @ts-expect-error
    //   instance = OverlayScrollbars(document.getElementById("treeMap"), {
    //     className: 'os-theme-light',
    //     sizeAutoCapable: true,
    //     scrollbars: {
    //       autoHide: "never",
    //       clickScrolling: true
    //     }
    //   });
    //   instance.options('callbacks.onScroll', this.onSidebarScroll.bind(this));
    // }

  }

  onSidebarScroll() {
    // @ts-expect-error
    let scrolValue = OverlayScrollbars(document.getElementById("sidebar")).scroll();
    // if (scrolValue.position.y >= scrolValue.max.y) {
    //   this.renderer.addClass(this.btn.nativeElement, 'd-none');
    //   this.renderer.removeClass(this.btn.nativeElement, 'd-block');
    // } else {
    //   this.renderer.addClass(this.btn.nativeElement, 'd-block');
    //   this.renderer.removeClass(this.btn.nativeElement, 'd-none');
    // }
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  fetchMembers() {
    this.members = [];
    if(!this.current?.id)return;
    this.loading = true;
    this.httpClient
      .get<User[]>(`<backendhost>/v1/branch/${this.current.id}/members`, {observe: 'body',responseType: 'json'})
      .subscribe( (members: User[]) => {
        this.members = members;
        this.loading = false;
      });
  }

  identify(item: Branch, index: number) {
    return item.id;
  }

  show (branch: Branch, target: HTMLElement) {

    window.scrollTo(0, 0);
    this.current = null;
    setTimeout(() => {
      this.current = branch;
      this.fetchMembers();
    },100);
  }

  backgroundStyle (branch: Branch) {
    if(!branch)
      return null;
    if(!branch.image)
      return null;
    return {
      background : `url(${this.imagePipe.transform(branch.image)}) no-repeat center center`,
      'height' : 'auto',
      'background-size' : 'cover'
    }
  }
  // background: url({{current.image | image}}) center center;
}


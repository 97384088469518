import { User } from "@/model/user.model";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { environment } from "environments/environment";
import * as fromActions from "./users.actions";


export interface UserPagination {
  total: number;
  data: User[];
}

export interface State {
  loading: boolean;
  list: EntityState<User>;
  total: number;
  page: number;
  order: string;
  readonly limit: number;
  asc: boolean;
  search: string;
  recentUsers: User[];
  saving: boolean;
  deactivated: boolean;
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>({
  // sortComparer: (a: Post, b: Post) => a.id < b.id ? 1 : 0
});

export const initialState: State = {
  loading: false,
  list: adapter.getInitialState(),
  page: 1,
  total: 0,
  order: 'id',
  limit: environment.pageSize,
  asc: true,
  search: null,
  recentUsers: [],
  saving: false,
  deactivated: false
}

export const reducer = createReducer(
  initialState,
  on(fromActions.clearFilters, (state, action) => {
    return initialState
  }),
  on(fromActions.replaceUsers, (state, action) => {
    let list = adapter.removeAll(state.list);
    list = adapter.addMany(action.data, list);
    return {...state, ...{
      total: action.total,
      loading: false,
      list: list
    }}
  }),
  on(fromActions.fetchUsers, (state) => {
    return {...state, ...{ loading: true }}
  }),
  on(fromActions.setPage, (state, action) => {
    return {...state, ...{ page: action.page }}
  }),
  on(fromActions.setSearch, (state, action) => {
    return {...state, ...{ search: action.search }}
  }),
  on(fromActions.setOrder, (state, action) => {
    return {...state, ...{ order: action.order }}
  }),
  on(fromActions.setDirection, (state, action) => {
    return {...state, ...{ direction: action.direction }}
  }),
  on(fromActions.setDeactivated, (state, action) => {
    return {...state, ...{ direction: action.deactivated }}
  }),
  on(fromActions.toggleDeactivated, (state) => {
    return {...state, ...{ deactivated: !state.deactivated }}
  }),

  on(fromActions.addOneUser, (state, action) => {
    let list = adapter.setOne(action.user, state.list);
    return {...state, ...{
      loading: false,
      list: list
    }}
  }),
  on(fromActions.removeUser, (state, action) => {
    let list = adapter.removeOne(action.user.id, state.list);
    return {...state, ...{
      loading: false,
      list: list
    }}
  }),
  on(fromActions.editUser, (state, action) => {
    return {...state, ...{
      saving: true,
    }}
  }),
  on(fromActions.updateUser, (state, action) => {
    let list = adapter.updateOne({ id: action.user.id, changes: action.user}, state.list);
    return {...state, ...{
      loading: false,
      saving: false,
      list: list
    }}
  }),
  on(fromActions.addUserPrize, (state, action) => {
    let prizes = state.list.entities[action.user.id]?.prizes;
    prizes = prizes ? [...prizes] : [];
    prizes.unshift(action.usersPrize);
    let list = adapter.updateOne({
      id: action.user.id,
      changes: {
        prizes: prizes
      }
    }, state.list);
    return {...state, ...{
      loading: false,
      list: list
    }}
  }),
  on(fromActions.deleteUserPrize, (state, action) => {
    let prizes = state.list.entities[action.user.id]?.prizes;
    prizes = prizes ? [...prizes] : [];
    prizes.splice(prizes.map(x => x.id).indexOf(action.usersPrize.id),1);
    let list = adapter.updateOne({
      id: action.user.id,
      changes: {
        prizes: prizes
      }
    }, state.list);
    return {...state, ...{
      loading: false,
      list: list
    }}
  }),
  on(fromActions.setRecentUsers, (state, action) => {
    return {...state, ...{recentUsers: action.users}};
  })

)
export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectUsersState = createFeatureSelector<State>('users');

export const selectAllUsers= createSelector(selectUsersState, (state) => selectAll(state.list) )
export const selectUsersCount= createSelector(selectUsersState, (state) => state.total )
export const selectUsersPageSize = createSelector(selectUsersState, (state) => state.limit )
export const selectOneUser = createSelector(selectUsersState, (state: State, id: number) => {
  return state.list.entities[id];
})

import { Branch } from "@/model/branch.model";
import { EntityState } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";

export const saveSuccess = createAction(
  "structure saveSuccess",
  props<{ id?: number}>()
);

export const fetch = createAction(
  "structure fetch",
);

export const fetchOne = createAction(
  "structure fetch One",
  props<{ id?: number}>()
);

export const fetchOneSuccess = createAction(
  "structure fetchOneSuccess",
  props<{ branch: Branch }>()
);

export const createBranchAction = createAction(
  "structure createBranchAction",
  props<{ branch: Branch, image?: File }>()
);


export const addAll = createAction(
  "structure addAll",
  props<{ payload: EntityState<Branch> }>()
);

export const addOne = createAction(
  "structure addOne",
  props<{ branch: Branch }>()
);


export const deleteOne = createAction(
  "structure deleteOne",
  props<{ branch: Branch }>()
);

export const removeOne = createAction(
  "structure removeOne",
  props<{ branch: Branch}>()
);

export const editBranch = createAction(
  "structure fetch editBranch",
  props<{ branch: Branch, image?: any }>()
);

export const updateBranch = createAction(
  "structure fetch updateBranch",
  props<{ branch: Branch }>()
);

export const updatePositions = createAction(
  "structure updatePositions",
  props<{ payload: {id: number, position: number}[] }>()
);

<div class="content">
  <div class="container-fluid">
    <div class="card card-widget position-relative shadow mt-3 mb-4">
      <!-- <div class="overlay" *ngIf="showOverlay">
        <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%,0);text-align: center; padding: 25px 0">
          <i class="fas fa-3x fa-spinner fa-spin"></i>
          <p>Ładowanie...</p>
        </div>
      </div> -->
      <div class="card-header pt-1" >
        <h3 *ngIf="invitation.package?.id" class="text-primary card-title text-lg m-0">
          {{invitation.package?.name}}
        </h3>
      </div>
      <div [ngStyle]="backgroundStyle()"></div>
      <div class="card-body" >
        <div [innerHtml]="invitation.package.content | safeHtml"></div>
        <h4 *ngIf="!invitation.package.open">
          <small>Termin realizacji szkoleni: </small>
          {{invitation.invitePack.endDate | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}
        </h4>
      </div>
      <div class="card-footer text-center" *ngIf="!invitation.started">
        <button (click)="start()" class="btn btn-primary btn-lg">Rozpocznij</button>
      </div>
      <div class="table-responsive" *ngIf="invitation.started">
        <table class="table align-middle table-hover">
          <thead>
            <tr>
              <th style="width:1rem">#</th>
              <th>Nazwa</th>
              <th>Status</th>
              <th>Data rozpoczęcia</th>
              <th>Data ukończenia</th>
              <th>Punktów</th>
              <th>Zaliczone</th>
              <th class="text-right" ></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of invitation.package.trainings; let idx = index; trackBy: identify">
              <th class="align-middle">{{idx + 1}}</th>
              <td class="align-middle">{{item.name}}</td>
              <td class="align-middle">
                <span *ngIf="result(item)?.completedOn" class="badge  bg-success text-md">Ukończone</span>
                <span *ngIf="!result(item)?.completedOn && result(item)?.createdAt" class="badge badge-info text-md">Rozpoczęte</span>
                <span *ngIf="!result(item)" class="badge badge-secondary text-md">Oczekuje</span>
              </td>
              <td class="align-middle">
                <span *ngIf="result(item)?.createdAt">{{ result(item)?.createdAt  | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</span>
                <span *ngIf="!result(item)"> - nie rozpoczęto - </span>
              </td>
              <td class="align-middle">
                <span *ngIf="result(item)?.completedOn">{{ result(item)?.completedOn  | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</span>
                <span *ngIf="!result(item)?.completedOn"> - nie ukończono - </span>
              </td>
              <td class="align-middle">
                <span *ngIf="result(item)?.completedOn">
                  {{result(item)?.scorePercent}}%
                </span>

              </td>
              <td class="align-middle text-center">
                <i class="fas fa-check-circle fa-2x text-success" *ngIf="result(item)?.passed == true"></i>
                <i class="fas fa-times-circle fa-2x text-danger" *ngIf="result(item)?.passed == false"></i>
              </td>
              <td class="align-middle text-nowrap text-right" >

                  <button class="btn btn-info btn-sm elevation-2 mr-2" *ngIf="!result(item)?.completedOn && item.type == 'Multimedialne'"
                  [routerLink]="['/training', invitation.id, 'multimedia', item.id, 0]"
                  ngbTooltip="Rozpocznij szkolenie">
                    <i class="fas fa-play fa-fw fa-lg"></i>
                  </button>

                  <button class="btn btn-info btn-sm elevation-2 mr-2" *ngIf="!result(item)?.completedOn && item.type != 'Multimedialne'"
                  [routerLink]="['/training', invitation.id, 'training', item.id]"
                  ngbTooltip="Rozpocznij szkolenie">
                    <i class="fas fa-play fa-fw fa-lg"></i>
                  </button>


                  <button *ngIf="result(item)"
                      class="btn btn-danger btn-sm elevation-2 mr-2"
                      nz-popconfirm
                      nzPopconfirmTitle="Czy na pewno zresetować?"
                      nzOkText="Tak"
                      nzCancelText="Nie"
                      nzPopconfirmPlacement="left"
                      ngbTooltip="Zresetuj"
                      (nzOnConfirm)="reset(item)" >
                    <i class="fas fa-times fa-fw fa-lg"></i>
                  </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer text-center" *ngIf="invitation?.best?.completedAt">
        <h3 class="p-3">Szkolenie zakończone</h3>
        <a [routerLink]="['/training', invitation.id, 'certificate']" class="btn btn-primary btn-lg" *ngIf="invitation?.best?.passed && invitation?.best?.certificate && invitation.package?.certificate">
          <i class="fas fa-certificate"></i>
          Pobierz certyfikat
        </a>
      </div>
    </div>
  </div>
  </div>

  <!-- <a (click)="resetHard()" class="btn btn-primary">Hard reset</a> -->

import { ReservationAsset } from '@/model/reservation.model';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';

@Component({
  selector: 'app-reservation-modal',
  templateUrl: './reservation-modal.component.html',
  styleUrls: ['./reservation-modal.component.scss']
})
export class ReservationModalComponent implements OnInit {
  assets: ReservationAsset[];
  form: FormGroup;
  saving = false;
  constructor(
    public modal: NgbActiveModal,
    private httpClient: HttpClient,
    private toaster: ToastrService,
  ) {

    this.form = new FormGroup({
      asset: new FormControl(null, [Validators.required],  [this.checkIfAvailable.bind(this, 'asset')]),
      startDate: new FormControl(null, [Validators.required, this.DateTimeValidator.bind(this), this.checkStartDate.bind(this)], [this.checkIfAvailable2.bind(this)]),
      endDate: new FormControl(null, [Validators.required, this.DateTimeValidator.bind(this), this.checkEndDate.bind(this)], [this.checkIfAvailable2.bind(this)]),
    });
  }

  DateTimeValidator = (fc: FormControl) => {
    const date = new Date(fc.value);
    const isValid = !isNaN(date.valueOf());
    return isValid ? null : {
        inValidDate: true
    };
  };

  checkIfAvailable2(control: AbstractControl) : Observable<ValidationErrors | null> {
    setTimeout( () => {this.form.controls.asset.updateValueAndValidity();});
    return of(null);
  }

  checkStartDate(control: AbstractControl) {
    return this.form && moment(control.value).isAfter(this.form.value.endDate) ? {isAfterEnd: true} : null;
  }

  checkEndDate(control: AbstractControl) {
    return this.form && moment(control.value).isBefore(this.form.value.startDate) ? {isBeforeStart: true} : null;
  }

  checkIfAvailable(name: string, control: AbstractControl) : Observable<ValidationErrors | null> {
    let value = {...this.form.value};
    value[name] = control.value;

    return this.httpClient
        .post<any>('<backendhost>/v1/reservation/0/validate', value)
        .pipe(
          // delay(500),
          map(result => {
            if(!result.valid) {
              this.form.controls.startDate.setErrors({taken: true});
              this.form.controls.endDate.setErrors({taken: true});
              control.setErrors({taken: true});
              return { taken: true };
            }
            else {
              this.form.controls.startDate.setErrors(null);
              this.form.controls.endDate.setErrors(null);
              control.setErrors(null);
              return null;
            }
          })
        )
  }


  ngOnInit(): void {
  }

  updateForm() {
    Object.values(this.form.controls).forEach( (input: FormControl) => {
      input.markAllAsTouched();
      input.updateValueAndValidity();
      // console.log(input);
    });
  }

  send(): void {
    if (this.saving) return;
    this.updateForm();
    this.saving = true;
    let subscribtion = this.form.statusChanges.subscribe((status: string) => {
      switch(status) {
        case 'INVALID':
          this.toaster.error("Formularz zawiera błędy");
          this.saving = false;
          subscribtion.unsubscribe();
          break;
        case 'VALID':
          this.httpClient.post<any>('<backendhost>/v1/reservation', this.form.value).subscribe(
            () => {
              this.saving = false;
              this.toaster.success('Zapisano rezerwacje');
              this.modal.close();
            },
            (error) => {
              this.saving = false;
              this.toaster.error(error.message)
            }
          )
          subscribtion.unsubscribe();
          break;
      }
    });
  }

}

<div class="card">
  <div class="card-header d-flex">
    <h3 class="card-title d-none d-sm-block flex-grow-1">Skrzynka odbiorcza</h3>
    <div class="card-tools flex-grow-1">
      <div class="input-group">
        <input type="text" name="table_search" class="form-control float-right" placeholder="Szukaj" #searchInput>
        <button class="btn bg-transparent" style="position:absolute; right: 0; transform: translate(-100%,0);" (click)="clearSearch()">
          <i class="fa fa-times"></i>
        </button>
        <span class="input-group-append">
          <div class="input-group-text bg-transparent"><i class="fas fa-search"></i></div>
        </span>
      </div>
    </div>
  </div>
  <div class="table-responsive">
    <div class="overlay" *ngIf="loading | async">
      <i class="fas fa-3x fa-sync-alt fa-spin"></i>
    </div>
    <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="(messages | async).length == 0"></nz-empty>
    <div class="list-group d-bloc d-sm-none">
      <div class="list-group-item">
        <div class="form-group">
          <label>Sortuj po</label>
          <div class="input-group">
            <select class="form-control" [ngModel]="order | async"  (change)="setSort($event.target.value)">
              <option value="createdAt">Data</option>
              <option value="title">Tytuł</option>
              <option value="id" class="d-none">Data</option>
            </select>
            <span class="input-group-append">
              <div class="btn btn-secondary" (click)="toggleDirection()">
                <i class="fas fa-sort-amount-up-alt fa-fw" *ngIf="(direction | async) == true"></i>
                <i class="fas fa-sort-amount-down-alt fa-fw" *ngIf="(direction | async) == false"></i>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div
        class="media list-group-item d-flex  list-group-item-action"
        *ngFor="let item of messages | async; let idx = index; trackBy: identify"
        style="cursor:pointer"
        [routerLink]="['/messages/view', item.id]">
        <div class="img-size-50 mr-3 flex-shrink-0">
          <div class="position-relative">
            <img [src]="item.createdBy | userAvatar" class="img-size-50 img-circle" [appUserDisactivatable]="item.createdBy"/>
          </div>
        </div>
        <div class="media-body">
          <h3 class="dropdown-item-title text-primary">
            {{item.title}}
          </h3>
          <p class="text-md text-truncate text-dark">
            <span class="text-decoration-line-through" *ngIf="!item?.createdBy">Użytkownik usunięty</span>
            <span [appUserDisactivatable]="item.createdBy">{{item.createdBy?.name }}</span>
          </p>
          <p class="text-sm text-muted text-nowrap">
            <i class="far fa-clock mr-1"></i> {{item.createdAt | amLocale:'pl' | amCalendar}}
          </p>
          <div class="ribbon-wrapper ribbon-sm" *ngIf="!item.seen">
            <div class="ribbon bg-orange text-sm">
              NOWE
            </div>
          </div>
        </div>
      </div>
    </div>
    <table class="table table-striped table-hover d-none d-sm-table">
      <thead>
        <tr>
          <th style="width:1px">#</th>
          <th style="width:1px" colspan="2">Od</th>
          <th class="sortable" (click)="setSort('title')"  [ngClass]="{'active': (order | async) == 'title'}"  >
            Tytuł
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
          <th class="sortable" (click)="setSort('createdAt')"  [ngClass]="{'active': (order | async) == 'createdAt'}"  >
            Wysłano
            <i class="fas fa-sort-amount-up-alt fa-fw float-right" *ngIf="(direction | async) == true"></i>
            <i class="fas fa-sort-amount-down-alt fa-fw float-right" *ngIf="(direction | async) == false"></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of messages | async; let idx = index; trackBy: identify" style="cursor:pointer" [routerLink]="['/messages/view', item.id]">
          <td class="align-middle  text-right">{{idx+1 + (page-1)* pageSize}}</td>
          <td style="width:50px" class="align-middle"><img class="img-circle elevation-2" [src]="item.createdBy | userAvatar" [appUserDisactivatable]="item.createdBy"/></td>
          <td class="align-middle">
            <span class="text-decoration-line-through" *ngIf="!item?.createdBy">Użytkownik usunięty</span>
            <span [appUserDisactivatable]="item.createdBy">{{item.createdBy?.name}}</span>
          </td>
          <td class="align-middle">{{item.title}}</td>
          <td class="align-middle text-nowrap position-relative">
            {{item.createdAt | amLocale:'pl' | amTimeAgo }}
            <div class="ribbon-wrapper" *ngIf="!item.seen">
              <div class="ribbon bg-orange">
                Nowa
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="card-footer" >
    <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="total | async" (pageChange)="pageChange($event)">
      <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationEllipsis>...</ng-template>
      <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>
  </div>
</div>

import { AuthService } from '@/auth/auth.service';
import { User } from '@/model/user.model';
import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[adminOnly]'
})
export class AdminOnlyDirective {

  constructor(
    element: ElementRef,
    private authService: AuthService,
    private renderer: Renderer2,
  ) {
    this.renderer.addClass(element.nativeElement, 'd-none');
    this.authService.getUser().then( (user: User) => {
      if (user.role !== 'admin') {
        element.nativeElement.remove();
      }
      else {
        this.renderer.removeClass(element.nativeElement, 'd-none');
      }
    })
  }

}

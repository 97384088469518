import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { from, Observable, of } from 'rxjs';
import * as fromKbReducers from '@/store/kb/kb.reducers';
import * as fromKbActions from '@/store/kb/kb.actions';
import { Folder } from '@/model/folder.model';
import { AuthService } from '@/auth/auth.service';
import { Store, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { tap, filter, take, takeUntil } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';

@Injectable({
  providedIn: 'root'
})
export class KbFolderResolver implements Resolve<Folder> {
  constructor(
    private store: Store<fromKbReducers.State>,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    protected actions$: Actions,
  ) { }

  check(resolve: any, instance : any, route: ActivatedRouteSnapshot) {
    this.authService.checkPermition(route.data.permition, instance).then( (alowed:boolean) => {
      if (alowed) {
        resolve(instance);
      } else {
        // resolve(null);
        this.toastr.error("Nie masz uprawnień do tej sekcji!");
        this.router.navigate(["/"]);
      }
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Folder> {
    return from( new Promise<Folder>(resolve => {
      let id = 1*route.params.folder;

      this.store.dispatch(fromKbActions.clearFiles());
      this.store.dispatch(fromKbActions.fetchFolder({id}));
      this.store.dispatch(fromKbActions.fetchFiles({id}));
      this.store.dispatch(fromKbActions.fetchArticles({id}));


       this.store.pipe(
        select(fromKbReducers.selectFolder, id),
        filter((result) => result != null),
        take(1),
      ).subscribe(instance => this.check(resolve, instance, route));

      this.actions$.pipe(ofType(fromKbActions.updateSelf)).subscribe( () => {
        this.store.dispatch(fromKbActions.clearFiles());
      })
    }));
  }
}

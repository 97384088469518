import { Conversation } from '@/model/chat.model';
import { InnerMessage } from '@/model/message.mode';
import {
    Component,
    HostListener,
    ElementRef,
    ViewChild,
    Renderer2,
    OnDestroy,
    OnInit
} from '@angular/core';
import { MessageService } from '@services/messages/message.service';
import { interval, Observable, Subject, timer } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-messages-dropdown-menu',
    templateUrl: './messages-dropdown-menu.component.html',
    styleUrls: ['./messages-dropdown-menu.component.scss']
})
export class MessagesDropdownMenuComponent implements OnInit, OnDestroy {
    @ViewChild('dropdownMenu', {static: false}) dropdownMenu;
    private readonly unsubscribe$: Subject<void> = new Subject();

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.hideDropdownMenu();
        }
    }

    constructor(
      private messageService: MessageService,
      private elementRef: ElementRef,
      private renderer: Renderer2
    ) {}

    ngOnInit(): void {
      // timer(1000 * 10,1000 * 40)
      //   .pipe(takeUntil(this.unsubscribe$))
      //   .subscribe(() => {
      //     this.messageService.load();
      //   })
    }

    ngOnDestroy() {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    }

    get latest(): Observable<InnerMessage[]> {
      return this.messageService.messages.pipe(map(arr => arr.slice(0,6)));
    }



    get count(): Observable<number> {
      // return this.messageService.messages.pipe(map( (arr:InnerMessage[]) => arr.filter(m => !m.seen).length));
      return this.messageService.unseenCount;
    }

    identify(index, object: InnerMessage) {
      return object.id;
    }

    toggleDropdownMenu() {
        if (this.dropdownMenu.nativeElement.classList.contains('show')) {
            this.hideDropdownMenu();
        } else {
            this.showDropdownMenu();
        }
    }

    showDropdownMenu() {
        this.renderer.addClass(this.dropdownMenu.nativeElement, 'show');
    }

    hideDropdownMenu() {
        this.renderer.removeClass(this.dropdownMenu.nativeElement, 'show');
    }
}

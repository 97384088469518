import { Injectable } from '@angular/core';
import * as fromMessageActions from '@/store/messages/messages.actions'
import * as fromMessageReducers from '@/store/messages/messages.reducers'
import { ActionsSubject, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { InnerMessage } from '@/model/message.mode';
import { map } from 'rxjs/operators';
import { Socket } from 'ngx-socket-io';
import { AuthService } from '@/auth/auth.service';
import { User } from '@/model/user.model';
import { ofType } from '@ngrx/effects';
import { loginSuccess } from '@/store/auth/auth.actions';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private initialLoad = false;

  constructor(
    private socket: Socket,
    private authService: AuthService,
    private store: Store<fromMessageReducers.State>,
    private actionsSubject : ActionsSubject
  ) {
    this.load();
    this.actionsSubject.pipe(ofType(loginSuccess)).subscribe(this.load.bind(this));

    this.authService.getUser().then( (user: User) => {
      this.socket.fromEvent(`msg:user:${user.id}`).subscribe( (message: InnerMessage) => {
        this.store.dispatch(fromMessageActions.addNewMessage({message}));
      });
    })
  }

  public load() {
    this.store.dispatch(fromMessageActions.fetchUnseenCount());
    this.store.dispatch(fromMessageActions.fetchRecent());
    this.actionsSubject.pipe(ofType(fromMessageActions.setUnseenCount)).subscribe( () => {this.initialLoad = true;});
  }

  get loaded() {
    return this.initialLoad;
  }

  get messages(): Observable<InnerMessage[]> {
    return this.store.pipe(select(fromMessageReducers.selectAllRecent));
  }

  get unseenCount(): Observable<number> {
    return this.store.pipe(select(fromMessageReducers.selectUnseenCount));
  }

  setAsSeen(id: number): void {
    this.store.dispatch(fromMessageActions.setAsSeen({id:id}))
  }
}

<section class="content">
  <div class="container">


    <form [formGroup]="form" (ngSubmit)="send()">
    <app-package-tab></app-package-tab>
    <div class="card" style="border-top-left-radius: 0;border-top-right-radius: 0;">
      <div class="card-body">

          <div class="form-group" *ngIf="!form.value.open">
            <label>Termin realizacji szkolenia</label>
            <app-date-time-picker formControlName="endDate" name="endDate" [appIsValid]="form.controls.endDate"></app-date-time-picker>
            <div class="text-danger" *ngIf="form.controls.endDate.errors?.required">Pole jest wymagane</div>
            <div class="text-danger" *ngIf="form.controls.endDate.errors?.dateNotValid">
              Data jest nieprawidłowa
            </div>
          </div>

          <div class="form-group">
            <label for="inputName">Wszyscy użytkownicy</label>
            <div class="form-check">
              <label class="form-check-label">
                <input class="form-check-input" type="checkbox" formControlName="all"/>
                Zaproś wszystkich
              </label>
            </div>
          </div>

          <div class="form-group" *ngIf="!form.value.open">
            <label for="typeahead-prevent-manual-entry">Wybierz grupy użytkowników</label>
            <ng-template #userGroupElement let-r="result" let-t="term">
              <div class="d-flex flex-md-row">
              <div class="mr-2">
                <img class="img-circle elevation-3" style="height: 45px;width: 45px;" [src]="r | groupAvatar" alt="User Avatar"/>
              </div>
              <ngb-highlight class="align-self-center" [result]="formatter(r)" [term]="t"></ngb-highlight>
              </div>
            </ng-template>
              <div class="position-relative">
                <input id="typeahead-prevent-manual-entry"
                  type="text" class="form-control"
                  placeholder="Zacznij wpisywać"
                  [disabled]="form.controls.all.value"
                  [(ngModel)]="typeaheadUserGroupModel"
                  [ngModelOptions]="{standalone: true}"
                  [ngbTypeahead]="searchUserGroups"
                  [resultTemplate]="userGroupElement"
                  [inputFormatter]="formatterEmpty"
                  [editable]='false'
                  (selectItem)="onUserGroupsTypeaheadSelect($event)"
                  (focus)="focusUserGroup$.next($any($event).target.value)"
                  (click)="clickUserGroup$.next($any($event).target.value)"
                  #instanceUserGroup="ngbTypeahead"
                  [appIsValid]="form.controls.userGroups"
                />
                <i class="fas fa-chevron-down" style="position: absolute; right:0; top:0; margin: 0.7rem;"></i>
                <div class="invalid-feedback" *ngIf="form.controls.userGroups.errors?.required">
                  Pole jest wymagane
                </div>
              </div>
              <ul class="list-group">
                <li *ngFor="let item of package.current?.userGroups; let idx = index; trackBy: identify" class="list-group-item ">
                  <div class="d-flex align-items-center">
                    <img class="img-circle elevation-3" [src]="item | groupAvatar" alt="{{formatter(item)}}"/>
                    <p class="pl-3 flex-grow-1  m-0">{{formatter(item)}}</p>
                    <a class="btn btn-danger btn-sm p-1 elevation-2" (click)="removeUserGroup(item)">
                      <i class="fas fa-times fa-fw fa-lg"></i>
                    </a>
                  </div>
                </li>
              </ul>
          </div>


          <div class="form-group">
            <label for="typeahead-prevent-manual-entry">Wybierz grupy szkoleniowe</label>
              <ng-template #groupElement let-r="result" let-t="term">
                <div class="d-flex flex-md-row">
                <div class="mr-2">
                  <img class="img-circle elevation-3" style="height: 45px;width: 45px;" [src]="r | groupAvatar" alt="User Avatar"/>
                </div>
                <ngb-highlight class="align-self-center" [result]="formatter(r)" [term]="t"></ngb-highlight>
                </div>
              </ng-template>
              <div class="position-relative">
                <input id="typeahead-prevent-manual-entry"  type="text" class="form-control" placeholder="Zacznij wpisywać"
                  [disabled]="form.controls.all.value"
                  [(ngModel)]="typeaheadGroupModel"
                  [ngModelOptions]="{standalone: true}"
                  [ngbTypeahead]="searchGroups"
                  [resultTemplate]="groupElement"
                  [inputFormatter]="formatterEmpty"
                  [editable]='false'
                  (selectItem)="onGroupsTypeaheadSelect($event)"
                  (focus)="focusGroup$.next($any($event).target.value)"
                  (click)="clickGroup$.next($any($event).target.value)"
                  #instanceGroup="ngbTypeahead"
                  [appIsValid]="form.controls.groups"
                />
                <i class="fas fa-chevron-down" style="position: absolute; right:0; top:0; margin: 0.7rem;"></i>
                <div class="invalid-feedback" *ngIf="form.controls.groups.errors?.required">
                  Pole jest wymagane
                </div>
              </div>
              <ul class="list-group">
                <li *ngFor="let item of package.current?.groups; let idx = index; trackBy: identify" class="list-group-item ">
                  <div class="d-flex align-items-center">
                    <img class="img-circle elevation-3" [src]="item | groupAvatar" alt="{{formatter(item)}}"/>
                    <p class="pl-3 flex-grow-1  m-0">{{formatter(item)}}</p>
                    <a class="btn btn-danger btn-sm p-1 elevation-2" (click)="removeGroup(item)">
                      <i class="fas fa-times fa-fw fa-lg"></i>
                    </a>
                  </div>
                </li>
              </ul>
          </div>

          <!-- [disabled]="form.controls.all.value" -->
          <div class="form-group">
            <label for="typeahead-prevent-manual-entry">Dodaj użytkownika do listy</label>
            <input id="typeahead-prevent-manual-entry"  type="text" class="form-control" placeholder="Zacznij wpisywać"

                  [(ngModel)]="model"
                  [ngModelOptions]="{standalone: true}"
                  [ngbTypeahead]="search"
                  [resultTemplate]="rt"
                  [inputFormatter]="formatterEmpty"
                  [editable]='false'
                  (selectItem)="onUserSelect($event)"/>
          </div>
      </div>
      <ng-template #rt let-r="result" let-t="term">
        <div class="d-flex flex-md-row">
        <div class="user-panel mr-2">
          <img [src]="r | userAvatar" class="img-circle">
        </div>
        <ngb-highlight class="align-self-center" [result]="formatter(r)" [term]="t"></ngb-highlight>
        </div>
      </ng-template>

      <div class="card-footer d-flex align-items-center">
        <h3 class="card-title m-0 flex-grow-1 text-bold"><i class="fas fa-paper-plane fa-fw"></i> Lista zaproszeń do wysłania</h3>
        <div class="card-tools flex-grow-1">
          <div class="input-group">
            <input type="text" name="table_search" class="form-control float-right" placeholder="Szukaj" #searchInput>
            <button class="btn bg-transparent" style="position:absolute; right: 0; transform: translate(-100%,0);" (click)="clearSearch()">
              <i class="fa fa-times"></i>
            </button>
            <span class="input-group-append">
              <div class="input-group-text bg-transparent"><i class="fas fa-search"></i></div>
            </span>
          </div>
        </div>
      </div>
      <div class="table-responsive position-relative">
        <table class="table align-middle table-hover">
          <thead>
            <tr>
              <th style="width:1rem">#</th>
              <th></th>
              <th>Imię</th>
              <th>Nazwisko</th>
              <th>Email</th>
              <th>Grupy szkoleniowe</th>
              <th>Grupy użytkowników</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of (pagination | async).list; let idx = index; trackBy: identify">
              <th class="align-middle">{{idx + 1 + (page-1) * pageSize}}</th>
              <!-- <td class="align-middle">{{item.createdAt | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td> -->
              <td class="user-panel" style="width:70px">
                <img
                      [src]="item  | userAvatar: 34"
                      class="img-circle elevation-2"
                      alt="User Image"
                      style="width:45px"
                      [appUserDisactivatable]="item"
                  />
              </td>

              <td class="align-middle">
                <span [appUserDisactivatable]="item">{{item.firstname}}</span>
              </td>
              <td class="align-middle">
                <span [appUserDisactivatable]="item">{{item.lastname}}</span>
              </td>
              <td class="align-middle">
                <span [appUserDisactivatable]="item">{{item.email}}</span>
              </td>
              <td class="align-middle">
                <!-- <span class="btn btn-link p-0 pr-1 text-sm" *ngFor="let group of item?.groups">#{{group.name}}</span> -->
                <span class="p-0 pr-1 text-sm" *ngFor="let group of item?.groups">{{group.name}}, </span>

              </td>
              <td class="align-middle">
                <!-- <span class="btn btn-link p-0 pr-1 text-sm" *ngFor="let group of item?.groups">#{{group.name}}</span> -->
                <span class="p-0 pr-1 text-sm" *ngFor="let group of item?.userGroups">{{group.name}}, </span>

              </td>
              <td>
                <a
                    class="btn btn-danger btn-sm elevation-2"
                    nz-popconfirm
                    nzPopconfirmTitle="Czy na pewno usunąć?"
                    nzOkText="Tak"
                    nzCancelText="Nie"
                    nzPopconfirmPlacement="left"
                    ngbTooltip="Usuń"
                    (nzOnConfirm)="delete(item)" >
                  <i class="fas fa-trash fa-fw fa-lg"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <pre>{{ (pagination | async)?.total | json }}</pre> -->
        <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="!(pagination | async)?.total"></nz-empty>
        <div class="overlay" style="position: absolute; top:0; left:0; bottom:0; right:0" *ngIf="(pagination | async).loading">
          <div style="position: sticky; top:50%; left:50%; align-self: baseline; transform: translate(-50%, 0%);text-align: center;  padding: 25px 0">
            <i class="fas fa-3x fa-spinner fa-spin"></i>
            <p>Ładowanie...</p>
          </div>
        </div>
      </div>
      <div class="card-footer d-flex">
        <div class="flex-grow-1">
          <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="(pagination | async).total"  (pageChange)="pageChange($event)">
            <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
            <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
            <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
            <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
            <ng-template ngbPaginationEllipsis>...</ng-template>
            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
          </ngb-pagination>
        </div>
        <div class="">
          <button class="btn btn-primary">
            <i class="fas fa-paper-plane fa-fw"></i>
            Dodaj zaproszenia
          </button>
        </div>
      </div>
    </div>
    </form>
  </div>
</section>





import { Group, GroupMember } from "@/model/group.model";
import { User } from "@/model/user.model";
import { createAction, props } from "@ngrx/store";

export const clear = createAction(
  "clear group"
);

export const createNewGroupAction = createAction(
  "create new group",
  props<{ name: string, superior?:boolean }>()
);

export const addGroupsAction = createAction(
  "add groups",
  props<{ payload: Group[] }>()
);

export const addGroupsFullAction = createAction(
  "add groups full",
  props<{ payload: Group[], total: number, page: number }>()
);

export const updateGroupAction = createAction(
  "update group",
  props<{ group: Group }>()
);

export const deleteGroupAction = createAction(
  "delete group",
  props<{ group: Group }>()
);

export const removeGroupAction = createAction(
  "remoe group",
  props<{ group: Group }>()
);

export const editGroupAction = createAction(
  "edit group",
  props<{ group: Group}>()
);

export const fetchOneAction = createAction(
  "fetch one group",
  props<{ id: number }>()
);

export const unlazyOneAction = createAction(
  "unlazy one group",
  props<{ id: number }>()
);

export const fetchFullAction = createAction(
  "fetch full groups",
  props<{ page: number, search: string }>()
);

export const fetchAllAction = createAction(
  "fetch all groups"
);

export const updateMyGroupMembershipAction = createAction(
  "update my group membership",
  props<{ group: Group, member: GroupMember | null }>()
);

export const createNewGroupMemberAction = createAction(
  "create new group member",
  props<{ group: Group, user: User }>()
);

export const joinGroup = createAction(
  "joinGroup",
  props<{ group: Group }>()
);

export const deleteGroupMemberAction = createAction(
  "delete group member",
  props<{ group: Group, member: GroupMember }>()
);

export const editGroupMemberAction = createAction(
  "edit group member",
  props<{ group: Group, member: GroupMember }>()
);

export const fetchGroupMembersAction = createAction(
  "fetch group members",
  props<{ group: Group}>()
);

export const addMemberToGroupAction = createAction(
  "add member to group",
  props<{ group: Group, member: GroupMember }>()
);

export const addManyMemberToGroupAction = createAction(
  "add many members to group",
  props<{ group: Group, members: GroupMember[] }>()
);

export const updateGroupMemberAction = createAction(
  "update group member",
  props<{ group: Group, member: GroupMember }>()
);

export const removeGroupMemberAction = createAction(
  "remove member from group",
  props<{ group: Group, member: GroupMember }>()
);

export const increseGroupMemberCountAction = createAction(
  "increse group member count",
  props<{ group: Group }>()
);

export const decreseGroupMemberCountAction = createAction(
  "decrese group member count",
  props<{ group: Group }>()
);

export const increseGroupObserverCountAction = createAction(
  "increse group observer count",
  props<{ group: Group }>()
);

export const decreseGroupObserverCountAction = createAction(
  "decrese group observer count",
  props<{ group: Group }>()
);

export const observeGroupAction = createAction(
  "observe group",
  props<{ group: Group, member: GroupMember, observe: boolean }>()
);

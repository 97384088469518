import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromRolesActions from '@/store/roles/roles.actions';
import * as fromRolesReducers from '@/store/roles/roles.reducers';
import { Observable } from 'rxjs';
import { Role } from '@/model/role.model';

@Component({
  selector: 'app-list-roles',
  templateUrl: './list-roles.component.html',
  styleUrls: ['./list-roles.component.scss']
})
export class ListRolesComponent implements OnInit {

  constructor(
    private rolesStore: Store<fromRolesReducers.State>,
  ) { }

  ngOnInit(): void {
  }

  get list(): Observable<Role[]> {
    return this.rolesStore.pipe(select(fromRolesReducers.selectRoles));
  }

  delete(role: Role): void {
    this.rolesStore.dispatch(fromRolesActions.deleteRole({role}));
  }

  identify(index, item: Role): string { return item.id;}

}

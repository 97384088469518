import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, Injectable, NgModule} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProfileComponent} from '@pages/profile/profile.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesDropdownMenuComponent} from '@modules/main/header/messages-dropdown-menu/messages-dropdown-menu.component';
import {NotificationsDropdownMenuComponent} from '@modules/main/header/notifications-dropdown-menu/notifications-dropdown-menu.component';
import {AppButtonComponent} from './components/app-button/app-button.component';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';

import {CommonModule, registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import {UserDropdownMenuComponent} from '@modules/main/header/user-dropdown-menu/user-dropdown-menu.component';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {LanguageDropdownComponent} from '@modules/main/header/language-dropdown/language-dropdown.component';
import {PrivacyPolicyComponent} from './modules/privacy-policy/privacy-policy.component';
import { PostBoxComponent } from './components/post-box/post-box.component';
import { AuthService } from './auth/auth.service';
import { AuthInterceptor } from './auth/auth.interceptor';
import { EffectsModule } from '@ngrx/effects';
import { PostsEffects } from './store/post/post.effects';
import { reducers } from './store/app.reducers';
import { StoreModule } from '@ngrx/store';
import { PostListComponent } from './components/post-list/post-list.component';
import { MomentModule } from 'ngx-moment';
import 'moment/locale/pl';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LoadingCardComponent } from './components/loading-card/loading-card.component';
import { CommentComponent } from './components/comment/comment.component';
import { AddComponent } from './components/comment/add/add.component';
import { InsideComponent } from './components/comment/inside/inside.component';
import { SubComponent } from './components/comment/sub/sub.component';
import { GroupsComponent } from './pages/groups/groups.component';
import { AddGroupComponent } from './pages/groups/add-group/add-group.component';
import { GroupEffects } from './store/group/group.effects';
import { EditGroupComponent } from './pages/groups/edit-group/edit-group.component';
import { NgbDatepickerI18n, NgbDatepickerI18nDefault, NgbDateStruct, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EditGroupMembersComponent } from './pages/groups/edit-group-members/edit-group-members.component';
import { FaCheckboxComponent } from './components/fa-checkbox/fa-checkbox.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { GroupCardComponent } from './pages/groups/group-card/group-card.component';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { ViewGroupComponent } from './pages/groups/view-group/view-group.component';
import { NewsComponent } from './pages/news/news.component';
import { AddNewsComponent } from './pages/news/add-news/add-news.component';
import { ListNewsComponent } from './pages/news/list-news/list-news.component';
import { GroupAvatarPipe } from './pipes/group-avatar.pipe';
import { ViewNewsComponent } from './pages/news/view-news/view-news.component';
import { AdminOnlyDirective } from './directives/admin-only.directive';
import { AuthEffects } from './store/auth/auth.effects';
import { UserAvatarPipe } from './pipes/user-avatar.pipe';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { ListUsersComponent } from './pages/users/list-users/list-users.component';
import { UsersEffects } from './store/users/users.effects';
import { AddUserComponent } from './pages/users/add-user/add-user.component';
import { EditUserComponent } from './pages/users/edit-user/edit-user.component';
import { IsValidDirective } from './directives/is-valid.directive';
import { EditAccountComponent } from './pages/account/edit-account/edit-account.component';
import { EditPasswordComponent } from './pages/account/edit-password/edit-password.component';
import { EditUserPasswordComponent } from './pages/users/edit-user-password/edit-user-password.component';
import { ServiceWorkerModule,  SwRegistrationOptions } from '@angular/service-worker';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from '../environments/environment';
import { ChatEffects } from './store/chat/chat.effects';
import { ChatService } from '@services/chat.service';
import { ViewConversationComponent } from './pages/chat/view-conversation/view-conversation.component';
import { ListConversationComponent } from './pages/chat/list-conversation/list-conversation.component';
import { InViewDirective } from './directives/in-view.directive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SideChatComponent } from './pages/chat/side-chat/side-chat.component';
import { RoomAddComponent } from './pages/chat/room-add/room-add.component';
import { RoomEditComponent } from './pages/chat/room-edit/room-edit.component';
import { ListNewsInGroupComponent } from '@pages/news/list-news/list-news-in-group.component';
import { ListNewsToAllComponent } from '@pages/news/list-news/list-news-to_all.component';
import { TruncHighlightPipe } from './pipes/trunc-highlight/trunc-highlight.pipe';
import { MessagesComponent } from './pages/messages/messages.component';
import { InboxMessagesComponent } from './pages/messages/inbox-messages/inbox-messages.component';
import { CreateMessagesComponent } from './pages/messages/create-messages/create-messages.component';
import { SentMessagesComponent } from './pages/messages/sent-messages/sent-messages.component';
import { MessagesEffects } from './store/messages/messages.effects';
import { ViewMessageComponent } from './pages/messages/view-message/view-message.component';
import { ChatDropdownMenuComponent } from './modules/main/header/chat-dropdown-menu/chat-dropdown-menu/chat-dropdown-menu.component';
import { MessageService } from '@services/messages/message.service';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzIconModule, NZ_ICONS } from 'ng-zorro-antd/icon';
import { TagInputModule } from 'ngx-chips';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker/date-time-picker.component';
import { IconDefinition } from '@ant-design/icons-angular';
import { PictureTwoTone, DeleteOutline, InboxOutline, DownOutline, DownloadOutline, FileTwoTone} from '@ant-design/icons-angular/icons';
import { NZ_I18N, pl_PL } from 'ng-zorro-antd/i18n';
import { ImagePipe } from './pipes/image.pipe';
import { LightboxModule } from 'ngx-lightbox';
import { NGB_DATEPICKER_18N_FACTORY } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-i18n';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { GuardDirective } from './directives/guard/guard.directive';
import { AdminAuthGuard } from '@guards/admin-auth.guard';
import { StructureComponent } from './pages/structure/structure.component';
import { AdminStructureComponent } from './pages/structure/admin-structure/admin-structure.component';
import { NzTreeViewModule } from 'ng-zorro-antd/tree-view';
import { ItemStructureComponent } from './pages/structure/admin-structure/item-structure/item-structure.component';
import { AddBranchComponent } from './pages/structure/admin-structure/add-branch/add-branch.component';
import { StructureEffects } from './store/structure/structure.effects';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { EditBranchComponent } from './pages/structure/admin-structure/edit-branch/edit-branch.component';
import { ListStructureComponent } from './pages/structure/list-structure/list-structure.component';
import { FolderComponent } from './pages/kb/folder/folder.component';
import { AdminFolderComponent } from './pages/kb/folder/admin-folder/admin-folder.component';
import { KbComponent } from './pages/kb/kb.component';
import { AddFolderComponent } from './pages/kb/folder/admin-folder/add-folder/add-folder.component';
import { KbEffects } from './store/kb/kb.effects';
import { EditFolderComponent } from './pages/kb/folder/admin-folder/edit-folder/edit-folder.component';
import { FilesFolderComponent } from './pages/kb/folder/admin-folder/files-folder/files-folder.component';
import { FileSizePipe } from './pipes/filesize.pipe';
import { FileItemComponent } from './pages/kb/folder/file-item/file-item.component';
import { ArticlesFolderComponent } from './pages/kb/folder/admin-folder/articles-folder/articles-folder.component';
import { AddArticleComponent } from './pages/kb/folder/admin-folder/articles-folder/add-article/add-article.component';
import { EditArticleComponent } from './pages/kb/folder/admin-folder/articles-folder/edit-article/edit-article.component';
import { ArticleComponent } from './pages/kb/folder/article/article.component';
import { OffersComponent } from './pages/offers/offers.component';
import { AdminOffersComponent } from './pages/offers/admin-offers/admin-offers.component';
import { AddOfferComponent } from './pages/offers/admin-offers/add-offer/add-offer.component';
import { ListOffersComponent } from './pages/offers/list-offers/list-offers.component';
import { OffersEffects } from './store/offer/offers.effects';
import { OfferBoxComponent } from '@pages/offers/offer-box/offfer-box.component';
import { OfferFullComponent } from '@pages/offers/offer-full/offer-full.component';
import { OfferCandidatesComponent } from '@pages/offers/offer-candidates/offer-candidates.component';
import { EditOfferComponent } from '@pages/offers/admin-offers/edit-offer/edit-offer.component';
import { NotificationEffects } from './store/notification/notification.effets';
import { SearchBtnComponent } from './modules/main/header/search-btn/search-btn.component';
import { WelcomePopupComponent } from './modules/main/welcome-popup/welcome-popup.component';
import { ShareLinkPopupComponent } from './components/share-link-popup/share-link-popup.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { UploadCropImageComponent } from './components/upload-crop-image/upload-crop-image.component';
import { CropPopupComponent } from './components/upload-crop-image/crop-popup/crop-popup.component';
import { EditorComponent } from './components/editor/editor.component';
import { SearchEffects } from './store/search/search.effects';
import { MentionTagComponent } from './components/mention-tag/mention-tag.component';
import { EditNewsComponent } from './pages/news/edit-news/edit-news.component';
import { OfferRefferComponent } from './pages/offers/offer-reffer/offer-reffer.component';
import { ListPrizesComponent } from './pages/prizes/list-prizes/list-prizes.component';
import { PrizesComponent } from './pages/prizes/prizes.component';
import { AddPrizeComponent } from './pages/prizes/add-prize/add-prize.component';
import { PrizeEffects } from './store/prizes/prizes.effects';
import { EditPrizeComponent } from './pages/prizes/edit-prize/edit-prize.component';
import { UserPrizesComponent } from './pages/users/user-prizes/user-prizes.component';
import { AddPrizeModalComponent } from './pages/users/add-prize-modal/add-prize-modal.component';
import { PrizeModalComponent } from './pages/profile/prize-modal/prize-modal.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { AdminOrdersComponent } from './pages/orders/admin-orders/admin-orders.component';
import { AddOrderFormComponent } from './pages/orders/admin-orders/add-order-form/add-order-form.component';
import { OrderFormInputModalComponent } from './pages/orders/admin-orders/order-form-input-modal/order-form-input-modal.component';
import { OrderEffects } from './store/order/order.effects';
import { EditOrderFormComponent } from './pages/orders/admin-orders/edit-order-form/edit-order-form.component';
import { ListOrdersComponent } from './pages/orders/list-orders/list-orders.component';
import { MakeOrderComponent } from './pages/orders/make-order/make-order.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { MessagingService } from '@services/messaging.service';
import { SmsConfirmModalComponent } from './components/sms-confirm-modal/sms-confirm-modal.component';
import { ListNewsUnseenComponent } from '@pages/news/list-news/list-news-unseen.component';
import { IdeasComponent } from './pages/ideas/ideas.component';
import { ListIdeasComponent } from './pages/ideas/list-ideas/list-ideas.component';
import { NewIdeaComponent } from './pages/ideas/new-idea/new-idea.component';
import { AdminIdeasComponent } from './pages/ideas/admin-ideas/admin-ideas.component';
import { IdeasEffects } from './store/ideas/ideas.effects';
import { EditIdeaModalComponent } from './pages/ideas/admin-ideas/edit-idea-modal/edit-idea-modal.component';
import { ReservationsComponent } from './pages/reservations/reservations.component';
import { AdminReservationsComponent } from './pages/reservations/admin-reservations/admin-reservations.component';
import { ReservationEffects } from './store/reservations/reservations.effects';
import { AddReservationCategoryComponent } from './pages/reservations/admin-reservations/add-reservation-category/add-reservation-category.component';
import { EditReservationCategoryComponent } from './pages/reservations/admin-reservations/edit-reservation-category/edit-reservation-category.component';
import { AssetsReservationCategoryComponent } from './pages/reservations/admin-reservations/assets-reservation-category/assets-reservation-category.component';
import { AddReservationAssetComponent } from './pages/reservations/admin-reservations/add-reservation-asset/add-reservation-asset.component';
import { EditReservationAssetComponent } from './pages/reservations/admin-reservations/edit-reservation-asset/edit-reservation-asset.component';
import { ListReservationCategoriesComponent } from './pages/reservations/list-reservation-categories/list-reservation-categories.component';
import { ViewReservationCategoriesComponent } from './pages/reservations/view-reservation-categories/view-reservation-categories.component';
import '@angular/common/locales/global/pl';
import { TimetableComponent } from './pages/reservations/view-reservation-categories/timetable/timetable.component';
import { ReservationModalComponent } from './pages/reservations/view-reservation-categories/reservation-modal/reservation-modal.component';
import { CheckRolePermitionDirective } from './directives/check-role-permition.directive';
import { RoleNamePipe } from './pipes/role-name.pipe';
import { BreadcrumbsService } from '@services/breadcrumbs.service';
import { DownloadComponent } from './pages/kb/folder/download/download.component';
import { IdeaItemComponent } from './pages/ideas/list-ideas/idea-item/idea-item.component';
import { clearState } from './store/clear.state';
import { SearchListComponent } from './pages/search/search-list/search-list.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { SearchTagEffects } from './store/search-tag/search-tag.effects';
import { ListNewsAllComponent } from '@pages/news/list-news/list-news-all.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { ScormListComponent } from './pages/training/admin/scorm/scorm-list/scorm-list.component';
import { ScormAddComponent } from './pages/training/admin/scorm/scorm-add/scorm-add.component';
import { TrainingComponent } from './pages/training/training.component';
import { ScormEffects } from './store/scorm/scorm.effects';
import { NgScormPlayerModule } from 'ng-scorm-player';
import { ScormEditComponent } from './pages/training/admin/scorm/scorm-edit/scorm-edit.component';
import { ScormPreviewComponent } from './pages/training/admin/scorm/scorm-preview/scorm-preview.component';
import { MultimediaListComponent } from './pages/training/admin/multi/multimedia-list/multimedia-list.component';
import { MultimediaAddComponent } from './pages/training/admin/multi/multimedia-add/multimedia-add.component';
import { MultimediaEditComponent } from './pages/training/admin/multi/multimedia-edit/multimedia-edit.component';
import { MultimediaEffects } from './store/multimedia/multimedia.effects';
import { MultimediaSlideComponent } from './pages/training/admin/multi/multimedia-slide/multimedia-slide.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { GroupListComponent } from './pages/training/admin/group/group-list/group-list.component';
import { GroupAddComponent } from './pages/training/admin/group/group-add/group-add.component';
import { GroupEditComponent } from './pages/training/admin/group/group-edit/group-edit.component';
import { TrainingGroupEffects } from './store/training-group/training-group.effects';
import { PackageListComponent } from './pages/training/admin/package/package-list/package-list.component';
import { PackageAddComponent } from './pages/training/admin/package/package-add/package-add.component';
import { TrainingEffects } from './store/training/training.effects';
import { TrainingPackageEffects } from './store/package/package.effects';
import { PackageEditComponent } from './pages/training/admin/package/package-edit/package-edit.component';
import { PackageInvitationComponent } from './pages/training/admin/package/package-invitation/package-invitation.component';
import { PackageTabComponent } from './pages/training/admin/package/package-tab/package-tab.component';
import { PackageInvitatedComponent } from './pages/training/admin/package/package-invitated/package-invitated.component';
import { MyTrainingListComponent } from './pages/training/my-training/my-training-list/my-training-list.component';
import { MyTrainingsEffects } from './store/my-training/my-training.effects';
import { MyTrainingStartComponent } from './pages/training/my-training/my-training-start/my-training-start.component';
import { MyTrainingPlayerComponent } from './pages/training/my-training/my-training-player/my-training-player.component';
import { ScormPlayerComponent } from './pages/training/my-training/my-training-player/scorm-player/scorm-player.component';
import { InitialTrainingListComponent } from './pages/training/my-training/my-training-player/initial-training-list/initial-training-list.component';
import { MultimediaPlayerComponent } from './pages/training/my-training/my-training-player/multimedia-player/multimedia-player.component';
import { YoutubePlayerComponent } from './pages/training/my-training/my-training-player/youtube-player/youtube-player.component';
import { NgxYoutubePlayerModule } from "ngx-youtube-player";
import { PackageNotificationComponent } from './pages/training/admin/package/package-notification/package-notification.component';
import { PackageReportComponent } from './pages/training/admin/package/package-report/package-report.component';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { QuizListComponent } from './pages/training/admin/quiz/quiz-list/quiz-list.component';
import { QuizEffects } from './store/quiz/quiz.effects';
import { QuizAddComponent } from './pages/training/admin/quiz/quiz-add/quiz-add.component';
import { QuizEditComponent } from './pages/training/admin/quiz/quiz-edit/quiz-edit.component';
import { QuizQuestionModalComponent } from './pages/training/admin/quiz/quiz-question-modal/quiz-question-modal.component';
import { QuizPlayComponent } from './pages/training/admin/quiz/quiz-play/quiz-play.component';
import { QuizPlayerComponent } from './pages/training/my-training/my-training-player/quiz-player/quiz-player.component';
import { ContentPlayerComponent } from './pages/training/my-training/my-training-player/content-player/content-player.component';
import { SurveyComponent } from './pages/survey/survey.component';
import { SurveyAdminListComponent } from './pages/survey/admin/survey-admin-list/survey-admin-list.component';
import { SurveyAdminAddComponent } from './pages/survey/admin/survey-admin-add/survey-admin-add.component';
import { SurveyAdminEditComponent } from './pages/survey/admin/survey-admin-edit/survey-admin-edit.component';
import { SurveyAdminQuestionModalComponent } from './pages/survey/admin/survey-admin-question-modal/survey-admin-question-modal.component';
import { SurveyEffects } from './store/survey/survey.effects';
import { SurveyAdminTabComponent } from './pages/survey/admin/survey-admin-tab/survey-admin-tab.component';
import { SurveyAdminQuestionsComponent } from './pages/survey/admin/survey-admin-questions/survey-admin-questions.component';
import { SurveyAdminPublishComponent } from './pages/survey/admin/survey-admin-publish/survey-admin-publish.component';
import { MySurveyEffects } from './store/my-survey/my-survey.effects';
import { SurveyListComponent } from './pages/survey/survey-list/survey-list.component';
import { SurveySubmitComponent } from './pages/survey/survey-submit/survey-submit.component';
import { SurveyAdminReportComponent } from './pages/survey/admin/survey-admin-report/survey-admin-report.component';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { MyTrainingCertificateComponent } from './pages/training/my-training/my-training-certificate/my-training-certificate.component';
import { PackageArchiveComponent } from './pages/training/admin/package/package-archive/package-archive.component';
import { MyTrainingOpenComponent } from './pages/training/my-training/my-training-open/my-training-open.component';
import { MyTrainingOpenStartComponent } from './pages/training/my-training/my-training-open-start/my-training-open-start.component';
import { PackageNotificationEditorComponent } from './pages/training/admin/package/package-notification-editor/package-notification-editor.component';
import { PackagePackListOfInvitedComponent } from './pages/training/admin/package/package-pack-list-of-invited/package-pack-list-of-invited.component';
import { PackageListOfPacksComponent } from './pages/training/admin/package/package-list-of-packs/package-list-of-packs.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { PackageParticipantsComponent } from './pages/training/admin/package/package-participants/package-participants.component';
import { MultimediaPlayComponent } from './pages/training/admin/multi/multimedia-play/multimedia-play.component';
import { ViewIdeaComponent } from './pages/ideas/view-idea/view-idea.component';
import { PackageUserReportComponent } from './pages/training/admin/package/package-user-report/package-user-report.component';
import { SurveyArchiveListComponent } from './pages/survey/admin/survey-archive-list/survey-archive-list.component';
import { SurveyPublishParticipantsComponent } from './pages/survey/admin/survey-admin-publish/survey-publish-participants/survey-publish-participants.component';
import { SurveyPublishPotentialComponent } from './pages/survey/admin/survey-admin-publish/survey-publish-potential/survey-publish-potential.component';
import { EditIdeaComponent } from './pages/ideas/admin-ideas/edit-idea/edit-idea.component';
import { CanDeactivateGuard } from '@guards/can-deactivate.guard';
import { OutpostsComponent } from './pages/outposts/outposts.component';
import { ListOutpostsComponent } from './pages/outposts/list-outposts/list-outposts.component';
import { AddOutpostComponent } from './pages/outposts/add-outpost/add-outpost.component';
import { EditOutpostComponent } from './pages/outposts/edit-outpost/edit-outpost.component';
import { OutpostEffects } from './store/outpost/outposts.efects';
import { SlotsComponent } from './pages/outposts/slots/slots.component';
import { AddSlotComponent } from './pages/outposts/slots/add-slot/add-slot.component';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { SlotEffects } from './store/slot/slot.effects';
import { EditSlotComponent } from './pages/outposts/slots/edit-slot/edit-slot.component';
import { UserSlotsComponent } from './pages/users/user-slots/user-slots.component';
import { UserWorkMarketPerferencesComponent } from './pages/users/user-work-market-perferences/user-work-market-perferences.component';
import { UserTabsComponent } from './pages/users/user-tabs/user-tabs.component';
import { AccountTabsComponent } from './pages/account/account-tabs/account-tabs.component';
import { SlotNotificationUsersComponent } from './pages/outposts/slots/slot-notification-users/slot-notification-users.component';
import { WorkmarketComponent } from './pages/workmarket/workmarket.component';
import { AddVacantComponent } from './pages/workmarket/add-vacant/add-vacant.component';
import { VacantEffects } from './store/vacant/vacant.effects';
import { ListVacantComponent } from './pages/workmarket/list-vacant/list-vacant.component';
import { OutpostInputComponent } from './components/outpost-input/outpost-input.component';
import { InfoVacantComponent } from './pages/workmarket/info-vacant/info-vacant.component';
import { UserInputComponent } from './components/user-input/user-input.component';
import { AddMyVacantComponent } from './pages/workmarket/add-my-vacant/add-my-vacant.component';
import { VacantToConfirmEffects } from './store/vacant/vacant.toconfirm.effects';
import { ListToConfirmComponent } from './pages/workmarket/list-to-confirm/list-to-confirm.component';
import { NewsSeenReportComponent } from './pages/news/news-seen-report/news-seen-report.component';
import { ListRolesComponent } from './pages/roles/list-roles/list-roles.component';
import { EditRoleComponent } from './pages/roles/edit-role/edit-role.component';
import { RolesEffects } from './store/roles/roles.effects';
import { AppInitService } from '@services/app-init.service';
import { ViewOrdersComponent } from './pages/orders/admin-orders/view-orders/view-orders.component';
import { ViewOrdersDetailComponent } from './pages/orders/admin-orders/view-orders-detail/view-orders-detail.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { OfferCategoryEffects } from './store/offer-category/offer.category.effects';
import { ListOfferCategoryComponent } from './pages/offers/category/list-offer-category/list-offer-category.component';
import { AddOfferCategoryComponent } from './pages/offers/category/add-offer-category/add-offer-category.component';
import { EditOfferCategoryComponent } from './pages/offers/category/edit-offer-category/edit-offer-category.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { VideoPlayerComponent } from './pages/training/my-training/my-training-player/video-player/video-player.component';
import { RedmineComponent } from './pages/redmine/redmine.component';
import { ListRedmineCategoryComponent } from './pages/redmine/admin/list-redmine-category/list-redmine-category.component';
import { RedmineSelectCategoryComponent } from './pages/redmine/redmine-select-category/redmine-select-category.component';
import { EditRedmineCategoryComponent } from './pages/redmine/admin/edit-redmine-category/edit-redmine-category.component';
import { RedmineCategoryEffects } from './store/redmine/redmine.category.effects';
import { EditRedmineSubcategoryComponent } from './pages/redmine/admin/edit-redmine-subcategory/edit-redmine-subcategory.component';
import { RedmineSubCategoryComponent } from './pages/redmine/redmine-sub-category/redmine-sub-category.component';
import { RedmineCreateIssueComponent } from './pages/redmine/redmine-create-issue/redmine-create-issue.component';
import { RedmineIsssueEffects } from './store/redmine/redmine.issue.effects';
import { RedmineListIssuesComponent } from './pages/redmine/redmine-list-issues/redmine-list-issues.component';
import { RedmineIssueDetailComponent } from './pages/redmine/redmine-issue-detail/redmine-issue-detail.component';
import { NgxMarkdownItModule } from "ngx-markdown-it";
import { RedmineIssueEditComponent } from './pages/redmine/redmine-issue-edit/redmine-issue-edit.component';
import { RedmineService } from '@services/redmine.service';
import { RedmineDropdownMenuComponent } from './modules/main/header/redmine-dropdown-menu/redmine-dropdown-menu.component';
import { PlyrModule } from 'ngx-plyr';
import { UserDisactivatableDirective } from './directives/user-disactivatable.directive';
import { UserDisactivatableComponent } from './components/user-disactivatable/user-disactivatable.component';
import { UpdatePopupComponent } from './modules/main/update-popup/update-popup.component';
import { CallCenterComponent } from './pages/call-center/call-center/call-center.component';
import { ListQuestionnaireCallCenterComponent } from './pages/call-center/admin/list-questionnaire-call-center/list-questionnaire-call-center.component';
import { CallCenterQuestionnaireEffects } from './store/call-center/call-center-questionnaire.effects';
import { AddQuestionnaireCallCenterComponent } from './pages/call-center/admin/add-questionnaire-call-center/add-questionnaire-call-center.component';
import { RedmineIssueDetailAttachmentsComponent } from './pages/redmine/redmine-issue-detail-attachments/redmine-issue-detail-attachments.component';
import { StatisticsComponent } from './pages/statistics/statistics/statistics.component';
import { FillQuestionnaireCallCenterComponent } from './pages/call-center/admin/fill-questionnaire-call-center/fill-questionnaire-call-center.component';
import { CallCenterFillEffects } from './store/call-center/call-center.effects';
import { ListCallCenterFillComponent } from './pages/call-center/list-call-center-fill/list-call-center-fill.component';
import { DetailCallCenterFillComponent } from './pages/call-center/detail-call-center-fill/detail-call-center-fill.component';
import { FillNotificationModalComponent } from './pages/call-center/admin/fill-notification-modal/fill-notification-modal.component';
import { CallCenterRaportComponent } from './pages/call-center/admin/call-center-raport/call-center-raport.component';
import { ListNewsFavoriteComponent } from '@pages/news/list-news/list-news-favorite.component';

const icons: IconDefinition[] = [ PictureTwoTone, DeleteOutline, InboxOutline, DownOutline, DownloadOutline, FileTwoTone ];

export function initializeAppFactory(appInitService: AppInitService): () => Promise<boolean> {
  return () => appInitService.init();
}
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  weekdays = ['Po', 'Wt', 'Śr', 'Cz', 'Pt', 'So', 'Nd'];
  months = ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'];
  weekLabel = 'tydz'


  getWeekdayLabel(weekday: number): string { return this.weekdays[weekday-1]; }
  getWeekLabel(): string { return this.weekLabel; }
  getMonthShortName(month: number): string { return this.months[month-1]; }
  getMonthFullName(month: number): string { return this.getMonthShortName(month); }
  getDayAriaLabel(date: NgbDateStruct): string { return `${date.day}-${date.month}-${date.year}`; }
}
registerLocaleData(localeEn, 'en-EN');
@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        BlankComponent,
        ProfileComponent,
        DashboardComponent,
        MessagesDropdownMenuComponent,
        NotificationsDropdownMenuComponent,
        AppButtonComponent,
        UserDropdownMenuComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageDropdownComponent,
        PrivacyPolicyComponent,
        PostBoxComponent,
        OfferBoxComponent,
        OfferFullComponent,
        OfferCandidatesComponent,
        PostListComponent,
        LoadingCardComponent,
        CommentComponent,
        AddComponent,
        InsideComponent,
        SubComponent,
        GroupsComponent,
        AddGroupComponent,
        EditGroupComponent,
        EditGroupMembersComponent,
        FaCheckboxComponent,
        GroupCardComponent,
        ViewGroupComponent,
        NewsComponent,
        AddNewsComponent,
        ListNewsComponent,
        ListNewsAllComponent,
        ListNewsInGroupComponent,
        ListNewsToAllComponent,
        ListNewsFavoriteComponent,
        ListNewsUnseenComponent,
        GroupAvatarPipe,
        ViewNewsComponent,
        AdminOnlyDirective,
        GuardDirective,
        UserAvatarPipe,
        ImagePipe,
        ListUsersComponent,
        AddUserComponent,
        EditUserComponent,
        IsValidDirective,
        EditAccountComponent,
        EditPasswordComponent,
        EditUserPasswordComponent,
        ViewConversationComponent,
        ListConversationComponent,
        InViewDirective,
        SideChatComponent,
        RoomAddComponent,
        RoomEditComponent,
        TruncHighlightPipe,
        MessagesComponent,
        InboxMessagesComponent,
        CreateMessagesComponent,
        SentMessagesComponent,
        ViewMessageComponent,
        ChatDropdownMenuComponent,
        DateTimePickerComponent,
        StructureComponent,
        AdminStructureComponent,
        ItemStructureComponent,
        AddBranchComponent,
        EditBranchComponent,
        ListStructureComponent,
        FolderComponent,
        AdminFolderComponent,
        KbComponent,
        AddFolderComponent,
        EditFolderComponent,
        FilesFolderComponent,
        FileSizePipe,
        FileItemComponent,
        ArticlesFolderComponent,
        AddArticleComponent,
        EditArticleComponent,
        ArticleComponent,
        OffersComponent,
        AdminOffersComponent,
        AddOfferComponent,
        EditOfferComponent,
        ListOffersComponent,
        SearchBtnComponent,
        WelcomePopupComponent,
        ShareLinkPopupComponent,
        UploadCropImageComponent,
        CropPopupComponent,
        EditorComponent,
        MentionTagComponent,
        EditNewsComponent,
        OfferRefferComponent,
        ListPrizesComponent,
        PrizesComponent,
        AddPrizeComponent,
        EditPrizeComponent,
        UserPrizesComponent,
        AddPrizeModalComponent,
        PrizeModalComponent,
        OrdersComponent,
        AdminOrdersComponent,
        AddOrderFormComponent,
        OrderFormInputModalComponent,
        EditOrderFormComponent,
        ListOrdersComponent,
        MakeOrderComponent,
        SmsConfirmModalComponent,
        IdeasComponent,
        ListIdeasComponent,
        NewIdeaComponent,
        AdminIdeasComponent,
        EditIdeaModalComponent,
        ReservationsComponent,
        AdminReservationsComponent,
        AddReservationCategoryComponent,
        EditReservationCategoryComponent,
        AssetsReservationCategoryComponent,
        AddReservationAssetComponent,
        EditReservationAssetComponent,
        ListReservationCategoriesComponent,
        ViewReservationCategoriesComponent,
        TimetableComponent,
        ReservationModalComponent,
        CheckRolePermitionDirective,
        RoleNamePipe,
        DownloadComponent,
        IdeaItemComponent,
        SearchListComponent,
        ClickOutsideDirective,
        SafeHtmlPipe,
        NotificationsComponent,
        ScormListComponent,
        ScormAddComponent,
        TrainingComponent,
        ScormEditComponent,
        ScormPreviewComponent,
        MultimediaListComponent,
        MultimediaAddComponent,
        MultimediaEditComponent,
        MultimediaSlideComponent,
        GroupListComponent,
        GroupAddComponent,
        GroupEditComponent,
        PackageListComponent,
        PackageAddComponent,
        PackageEditComponent,
        PackageInvitationComponent,
        PackageTabComponent,
        PackageInvitatedComponent,
        MyTrainingListComponent,
        MyTrainingStartComponent,
        MyTrainingPlayerComponent,
        ScormPlayerComponent,
        InitialTrainingListComponent,
        MultimediaPlayerComponent,
        YoutubePlayerComponent,
        PackageNotificationComponent,
        PackageReportComponent,
        QuizListComponent,
        QuizAddComponent,
        QuizEditComponent,
        QuizQuestionModalComponent,
        QuizPlayComponent,
        QuizPlayerComponent,
        ContentPlayerComponent,
        SurveyComponent,
        SurveyAdminListComponent,
        SurveyAdminAddComponent,
        SurveyAdminEditComponent,
        SurveyAdminQuestionModalComponent,
        SurveyAdminTabComponent,
        SurveyAdminQuestionsComponent,
        SurveyAdminPublishComponent,
        SurveyListComponent,
        SurveySubmitComponent,
        SurveyAdminReportComponent,
        MyTrainingCertificateComponent,
        PackageArchiveComponent,
        MyTrainingOpenComponent,
        MyTrainingOpenStartComponent,
        PackagePackListOfInvitedComponent,
        PackageNotificationEditorComponent,
        PackageListOfPacksComponent,
        PackageParticipantsComponent,
        MultimediaPlayComponent,
        ViewIdeaComponent,
        PackageUserReportComponent,
        SurveyArchiveListComponent,
        SurveyPublishParticipantsComponent,
        SurveyPublishPotentialComponent,
        EditIdeaComponent,
        OutpostsComponent,
        ListOutpostsComponent,
        AddOutpostComponent,
        EditOutpostComponent,
        SlotsComponent,
        AddSlotComponent,
        EditSlotComponent,
        UserSlotsComponent,
        UserWorkMarketPerferencesComponent,
        UserTabsComponent,
        AccountTabsComponent,
        SlotNotificationUsersComponent,
        WorkmarketComponent,
        AddVacantComponent,
        ListVacantComponent,
        OutpostInputComponent,
        InfoVacantComponent,
        UserInputComponent,
        AddMyVacantComponent,
        ListToConfirmComponent,
        NewsSeenReportComponent,
        ListRolesComponent,
        EditRoleComponent,
        ViewOrdersComponent,
        ViewOrdersDetailComponent,
        ListOfferCategoryComponent,
        AddOfferCategoryComponent,
        EditOfferCategoryComponent,
        VideoPlayerComponent,
        RedmineComponent,
        ListRedmineCategoryComponent,
        RedmineSelectCategoryComponent,
        EditRedmineCategoryComponent,
        EditRedmineSubcategoryComponent,
        RedmineSubCategoryComponent,
        RedmineCreateIssueComponent,
        RedmineListIssuesComponent,
        RedmineIssueDetailComponent,
        RedmineIssueEditComponent,
        RedmineDropdownMenuComponent,
        UserDisactivatableDirective,
        UserDisactivatableComponent,
        UpdatePopupComponent,
        CallCenterComponent,
        ListQuestionnaireCallCenterComponent,
        AddQuestionnaireCallCenterComponent,
        FillQuestionnaireCallCenterComponent,
        ListCallCenterFillComponent,
        DetailCallCenterFillComponent,
        FillNotificationModalComponent,
        RedmineIssueDetailAttachmentsComponent,
        StatisticsComponent,
        CallCenterRaportComponent,
    ],
    imports: [
        NgxMarkdownItModule,
        NgxGoogleAnalyticsModule.forRoot(environment.ga),
        // NgxGoogleAnalyticsRouterModule,
        NzDatePickerModule,
        CommonModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,

        NgxYoutubePlayerModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 10000,
            extendedTimeOut: 3000,
            positionClass: 'toast-top-full-width',
            preventDuplicates: true,
            progressBar: false
        }),
        PlyrModule,
        StoreModule.forRoot(reducers, {
          metaReducers: [clearState],
          runtimeChecks: {

            strictStateImmutability: false,
            strictActionImmutability: true,
            strictStateSerializability: true,
            strictActionSerializability: false,
            strictActionWithinNgZone: true,
            strictActionTypeUniqueness: true,
          }
        }),
        EffectsModule.forRoot([
          PostsEffects,
          GroupEffects,
          AuthEffects,
          UsersEffects,
          ChatEffects,
          MessagesEffects,
          StructureEffects,
          KbEffects,
          OffersEffects,
          NotificationEffects,
          SearchEffects,
          SearchTagEffects,
          PrizeEffects,
          OrderEffects,
          IdeasEffects,
          ReservationEffects,
          ScormEffects,
          MultimediaEffects,
          TrainingEffects,
          TrainingPackageEffects,
          TrainingGroupEffects,
          MyTrainingsEffects,
          QuizEffects,
          SurveyEffects,
          MySurveyEffects,
          OutpostEffects,
          SlotEffects,
          VacantEffects,
          VacantToConfirmEffects,
          RolesEffects,
          OfferCategoryEffects,
          RedmineCategoryEffects,
          RedmineIsssueEffects,
          CallCenterQuestionnaireEffects,
          CallCenterFillEffects
        ]),
        MomentModule,
        InfiniteScrollModule,
        NgbModule,
        EditorModule,
        NzUploadModule,
        NzBreadCrumbModule,
        NzSelectModule,
        NzEmptyModule,
        NzModalModule,
        NzIconModule.forRoot(icons),
        NzPopconfirmModule,
        NzTreeSelectModule,
        NzCalendarModule,
        NzTimePickerModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: true,
          // Register the ServiceWorker as soon as the app is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerImmediately'
        }),
        SocketIoModule.forRoot({ url: environment.nodeUrl, options: {} }),
        FontAwesomeModule,
        TagInputModule,
        LightboxModule,
        ImageCropperModule,
        CKEditorModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireMessagingModule,
        PasswordStrengthMeterModule,
        NgScormPlayerModule.forChild({ debug: true }),
        NgSelectModule,
        NzSliderModule,
        PickerModule
    ],
    providers: [
      AdminAuthGuard,
      CanDeactivateGuard,
      AuthService,
      ChatService,
      RedmineService,
      MessageService,
      MessagingService,
      GroupAvatarPipe,
      BreadcrumbsService,
      { provide: NZ_I18N, useValue: pl_PL },
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
      { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
      { provide: NgbDatepickerI18nDefault, useClass: CustomDatepickerI18n },
      { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
      {
        provide: SwRegistrationOptions,
        useFactory: () => ({enabled: location.search.includes('sw=true')}),
      },
      {provide: Window, useValue: window},
      {
        provide: APP_INITIALIZER,
        useFactory: initializeAppFactory,
        deps: [AppInitService],
        multi: true
       }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

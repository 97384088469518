import { CallCenterQuestionnaire } from '@/model/call-center.model';
import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { from, Observable, of } from 'rxjs';
import * as fromReducers from '@/store/call-center/call-center-questionnaire.reducers';
import * as fromActions from '@/store/call-center/call-center-questionnaire.actions';
import { select, Store } from '@ngrx/store';
import { AuthService } from '@/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { tap, filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CallCenterQuestionnaireResolver implements Resolve<CallCenterQuestionnaire> {
    constructor(
      private store: Store<fromReducers.State>,
      private authService: AuthService,
      private router: Router,
      private toastr: ToastrService,
    ) { }

    check(resolve: any, instance : any, route: ActivatedRouteSnapshot) {
      this.authService.checkPermition(route.data.permition, instance).then( (alowed:boolean) => {
        console.log(alowed);
        if (alowed) {
          resolve(instance);
        } else {
          // resolve(null);
          this.toastr.error("Nie masz uprawnień do tej sekcji!");
          this.router.navigate(["/"]);
        }
      });
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CallCenterQuestionnaire> {
      return from( new Promise<CallCenterQuestionnaire>(resolve => {
        this.store.pipe(
          select(fromReducers.selectOnequestionnaire, 1*route.params.id),
          tap( (result) => {
            if(!result) {
              this.store.dispatch(fromActions.fetchOne({id: 1*route.params.id}));
            }
          }),
          filter((result) => result != null),
          take(1),
        ).subscribe(instance => this.check(resolve, instance, route));
      }));
    }
  }

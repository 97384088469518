import {
    Component,
    HostBinding,
    OnDestroy,
    OnInit,
    Renderer2
} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import { AuthService } from '@/auth/auth.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'login-box';
    public forgotPasswordForm: FormGroup;
    public isAuthLoading = false;

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.renderer.addClass(
            document.querySelector('app-root'),
            'login-page'
        );
        this.forgotPasswordForm = new FormGroup({
            email: new FormControl(null, Validators.required)
        });
    }

    forgotPassword() {
        if (this.forgotPasswordForm.valid) {
          // this.authService.remindPassowrd(this.forgotPasswordForm.value.email).subscribe(console.log)
          this.authService.remindPassowrd(this.forgotPasswordForm.value.email).subscribe(
            (response: any) => {
              if(response?.ok) {
                this.toastr.success(this.forgotPasswordForm.value.email, `Wysłano kod resetu hasła na adres`);
              } else {
                this.toastr.error(response?.error);
              }
            },
            (response: any) => {
              this.toastr.error(response?.error.error);
            }
          )
        } else {
            this.toastr.error('Formulkarz zawiera błędy');
        }
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'login-page'
        );
    }
}

import { TrainingPackage } from "@/model/package.model";
import { PaginationDTO } from "@/model/pagination.model";
import { RedmineCategory, RedminePriority, RedmineSubCategory } from "@/model/redmine.model";
import { StringifyHttpErrorResponse } from "@/utils/http.error.util";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { map, switchMap, catchError } from "rxjs/operators";

import * as fromActions from './redmine.category.actions';
import * as fromReducers from './redmine.category.reducers';

const ENDPOINT = '<backendhost>/v1/redmine-category';

@Injectable()
export class RedmineCategoryEffects {
  state: fromReducers.State;

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private store$: Store<fromReducers.State>,
  ) {
    this.store$.pipe(select(fromReducers.selectState)).subscribe((state: fromReducers.State) => {
      this.state = state;
    })
  }

  onError (err, caught): any {
    this.toastr.error(StringifyHttpErrorResponse(err));
    return caught;
  };

  paginatorChange$ = createEffect(() => this.actions$.pipe(
    ofType(...[
      fromActions.setPage,
      fromActions.setSearch,
      fromActions.setOrder,
      fromActions.setDirection
    ]),
    map( () => fromActions.fetch())
  ));

  fetch$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetch),
    switchMap(() => {
      let params = new HttpParams();
      params = params.set('skip', this.state.limit * (this.state.page - 1));
      params = params.set('limit', this.state.limit);
      params = params.set('order', this.state.order);
      params = params.set('direction', this.state.asc ? 'asc' : 'desc');
      if(this.state.search)
        params = params.set('search', this.state.search);
      return this.httpClient.get<PaginationDTO<RedmineCategory>>(`${ENDPOINT}`, {
        params: params,
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((response: PaginationDTO<RedmineCategory>) => [fromActions.setAll(response)])
  ));

  fetchOne$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchOne),
    switchMap((action) => {
      return this.httpClient.get<RedmineCategory>(`${ENDPOINT}/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((item: RedmineCategory) => [fromActions.updateOneCategory({item})])
  ));

  createCategory$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createCategory),
    switchMap((action) => {
      return this.httpClient.post<RedmineCategory>(`${ENDPOINT}`, action.item, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((item: RedmineCategory) => [fromActions.updateOneCategory({item})])
  ));

  updateCategory$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.updateCategory),
    switchMap((action) => {
      return this.httpClient.put<RedmineCategory>(`${ENDPOINT}/${action.item.id}`, action.item, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((item: RedmineCategory) => [fromActions.updateOneCategory({item})])
  ));

  deleteCategory$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deleteCategory),
    switchMap((action) => {
      return this.httpClient.delete<RedmineCategory>(`${ENDPOINT}/${action.id}`, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map(() => action.id));
    }),
    catchError(this.onError.bind(this)),
    switchMap((id: number) => [fromActions.removeOneCategory({id})])
  ));

  deleteSubCategory$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deleteSubCategory),
    switchMap((action) => {
      return this.httpClient.delete<any>(`${ENDPOINT}/${action.item.id}/subcategory-delete`, {
        observe: 'body',
        responseType: 'json'
      }).pipe(map(() => action.item));
    }),
    catchError(this.onError.bind(this)),
    switchMap((item: RedmineSubCategory) => [fromActions.removeOneSubCategory({item})])
  ));


  saveSubCategory$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.saveSubCategory),
    switchMap((action) => {
      let id = action.item.id ? action.item.id : 0;
      return this.httpClient.post<RedmineSubCategory>(`${ENDPOINT}/${id}/subcategory`, action.item, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((item: RedmineSubCategory) => [fromActions.setOneSubCategory({item})])
  ));

  fetchPriorities$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.fetchPriorities),
    switchMap((action) => {
      return this.httpClient.get<RedminePriority[]>(`${ENDPOINT}/0/priorities`, {
        observe: 'body',
        responseType: 'json'
      });
    }),
    catchError(this.onError.bind(this)),
    switchMap((items: RedminePriority[]) => [fromActions.setPriorities({items})])
  ));
}

import { InnerMessage } from "@/model/message.mode";
import { createPaginationInitialState, Pagination } from "@/model/pagination.model";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import * as actions from "./messages.actions";

export interface State {
  unseenCount: number;
  recent: EntityState<InnerMessage>,
  inbox: Pagination<InnerMessage>,
  sent: Pagination<InnerMessage>
}

export const adapter: EntityAdapter<InnerMessage> = createEntityAdapter<InnerMessage>({
  sortComparer: (a: InnerMessage, b: InnerMessage) => a.id < b.id ? 1 : 0
});

export const initialState: State = {
  unseenCount: 0,
  recent: adapter.getInitialState(),
  inbox: createPaginationInitialState(adapter, false, 10),
  sent: createPaginationInitialState(adapter, false, 10),
}

export const reducer = createReducer(
  initialState,
  on(actions.addRecent, (state, action) => {
    return {
      ...state,
      ...{ recent: adapter.addMany(action.payload, state.recent)}
    }
  }),
  on(actions.addNewMessage, (state, action) => {
    return {
      ...state,
      ...{
        unseenCount: state.unseenCount + (action.message.seen ? 0 : 1),
        recent: adapter.addOne(action.message, state.recent)
      }
    }
  }),
  on(actions.setInbox, (state, action) => {
    return {
      ...state,
      ...{
        inbox: {
          ...state.inbox,
          ...{
            total: action.total,
            loading: false,
            list: adapter.addMany(action.list,adapter.getInitialState())
          }
        }
      }
    }
  }),
  on(actions.fetchInbox, (state, action) => { return {...state, ...{inbox: {...state.inbox, ...{loading: true}}}}}),
  on(actions.setPageInbox, (state, action) => { return {...state, ...{inbox: {...state.inbox, ...{page: action.page}}}}}),
  on(actions.setSearchInbox, (state, action) => { return {...state, ...{inbox: {...state.inbox, ...{search: action.search}}}}}),
  on(actions.setOrderInbox, (state, action) => { return {...state, ...{inbox: {...state.inbox, ...{order: action.order}}}}}),
  on(actions.setDirectionInbox, (state, action) => { return {...state, ...{inbox: {...state.inbox, ...{asc: action.asc}}}}}),
  on(actions.setSent, (state, action) => {
    console.log(action);
    return {
      ...state,
      ...{
        sent: {
          ...state.sent,
          ...{
            total: action.total,
            loading: false,
            list: adapter.addMany(action.list,adapter.getInitialState())
          }
        }
      }
    }
  }),
  on(actions.fetchSent, (state, action) => { return {...state, ...{sent: {...state.sent, ...{loading: true}}}}}),
  on(actions.setPageSent, (state, action) => { return {...state, ...{sent: {...state.sent, ...{page: action.page}}}}}),
  on(actions.setSearchSent, (state, action) => { return {...state, ...{sent: {...state.sent, ...{search: action.search}}}}}),
  on(actions.setOrderSent, (state, action) => { return {...state, ...{sent: {...state.sent, ...{order: action.order}}}}}),
  on(actions.setDirectionSent, (state, action) => { return {...state, ...{sent: {...state.sent, ...{asc: action.asc}}}}}),
  on(actions.setAsSeen, (state, action) => {
    return {
      ...state,
      ...{ recent: adapter.updateOne({id: action.id, changes: {seen: true}}, state.recent)}
    }
  }),
  on(actions.setUnseenCount, (state, action) => {
    return {
      ...state,
      ...{ unseenCount: action.count}
    }
  }),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectState = createFeatureSelector<State>('messages');
export const selectAllInbox = createSelector(selectState, (state) => selectAll(state.inbox.list) )
export const selectAllSent = createSelector(selectState, (state) => selectAll(state.sent.list) )
export const selectAllRecent = createSelector(selectState, (state) => selectAll(state.recent) )
export const selectUnseenCount = createSelector(selectState, (state) => state.unseenCount )

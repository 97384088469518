import { createPaginationInitialState, Pagination } from "@/model/pagination.model";
import { RedmineCategory, RedminePriority, RedmineSubCategory } from "@/model/redmine.model";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import * as actions from './redmine.category.actions';

export interface State extends Pagination<RedmineCategory> {
  priorities: EntityState<RedminePriority>
}

export const adapter: EntityAdapter<RedmineCategory> = createEntityAdapter<RedmineCategory>();
export const adapterSub: EntityAdapter<RedmineSubCategory> = createEntityAdapter<RedmineSubCategory>();
export const adapterPriority: EntityAdapter<RedminePriority> = createEntityAdapter<RedminePriority>();

export const initialState: State = {
  ...createPaginationInitialState(adapter, false),
  ...{
    priorities: adapterPriority.getInitialState()
  }
};

export const reducer = createReducer(
  initialState,
  on(actions.clear, () => initialState),
  on(actions.fetch, (state) => { return {...state, ...{loading: true}}}),
  on(actions.fetchOne, (state) => { return {...state, ...{loading: true}}}),
  on(actions.setPage, (state, action) => { return {...state, ...{page: action.page}}}),
  on(actions.setOrder, (state, action) => { return {...state, ...{order: action.order}}}),
  on(actions.setDirection, (state, action) => { return {...state, ...{asc: action.asc}}}),
  on(actions.setSearch, (state, action) => { return {...state, ...{search: action.search}}}),


  /**
   * RedmineCategory
   */
  on(actions.setAll,(state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: action.total,
        list: adapter.setAll(action.list, state.list)
      }
    }
  }),
  on(actions.setAll,(state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        total: action.total,
        list: adapter.setAll(action.list, state.list)
      }
    }
  }),
  on(actions.addOneCategory,(state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.addOne(action.item, state.list)
      }
    }
  }),
  on(actions.updateOneCategory,(state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.setOne(action.item, state.list)
      }
    }
  }),
  on(actions.removeOneCategory,(state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.removeOne(action.id, state.list)
      }
    }
  }),
  on(actions.setOneSubCategory,(state, action) => {
    let category = state.list.entities[action.item.parent];
    console.log(action.item);
    let children = adapterSub.addMany(category.children,adapterSub.getInitialState());
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.updateOne({
          id: category.id,
          changes: {
            children: adapterSub.getSelectors().selectAll(adapterSub.setOne(action.item, children))
          }
        }, state.list)
      }
    }
  }),
  on(actions.removeOneSubCategory,(state, action) => {
    let category = state.list.entities[action.item.parent];
    console.log(action.item);
    let children = adapterSub.addMany(category.children,adapterSub.getInitialState());
    return {
      ...state,
      ...{
        loading: false,
        list: adapter.updateOne({
          id: category.id,
          changes: {
            children: adapterSub.getSelectors().selectAll(adapterSub.removeOne(action.item.id, children))
          }
        }, state.list)
      }
    }
  }),

  on(actions.setPriorities, (state, action) => {
    return {
      ...state,
      ...{
        priorities: adapterPriority.setAll(action.items, state.priorities)
      }
    }
  })
);



export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectState = createFeatureSelector<State>('redmine-category');
export const selectCategory = createSelector(selectState, (state, id) => state.list.entities[id]);
export const selectPriorities = createSelector(selectState, (state) => adapterPriority.getSelectors().selectAll(state.priorities));
export const selectPriority = createSelector(selectState, (state,id) => state.priorities.entities[id]);


<ng-select
    [disabled]="disabled"
    [items]="outposts$ | async"
    [multiple]="multiple"
    bindLabel="name"
    [trackByFn]="trackByFn"
    [minTermLength]="2"
    [loading]="outpostLoading | async"
    typeToSearchText="Please enter 2 or more characters"
    [typeahead]="outpostInput$"
    [ngModel]="outpost"
    (ngModelChange)="onOutpostChange($event)"
    [placeholder]="multiple ? 'Wybierz wiele placówek' : 'Wybierz placówkę'"
    >
</ng-select>

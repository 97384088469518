import { AuthService } from '@/auth/auth.service';
import { Branch } from '@/model/branch.model';
import { InnerMessage } from '@/model/message.mode';
import { User } from '@/model/user.model';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChatService } from '@services/chat.service';
import { MessageService } from '@services/messages/message.service';
import { NotificationService } from '@services/notification.service';
import { Observable, of } from 'rxjs';
import { takeUntil, map, take } from 'rxjs/operators';

@Component({
  selector: 'app-welcome-popup',
  templateUrl: './welcome-popup.component.html',
  styleUrls: ['./welcome-popup.component.scss']
})
export class WelcomePopupComponent implements OnInit {
  user: User;

  constructor(
    // private modalService: NgbModal,
    public modal: NgbActiveModal,
    private authService: AuthService,

    private chatService: ChatService,
    private messageService: MessageService,
    private notificationService: NotificationService,
  ) {
    authService.getUser().then(user => {this.user = user;})
    authService.getRandomUser().then((user) => {
      this.randomUser = user;
    });
  }

  ngOnInit(): void {
  }

  get msg(): Observable<number> {
    return this.messageService.messages.pipe(/*takeUntil(this.unsubscribe$),*/map( (arr:InnerMessage[]) => arr.filter(m => !m.seen).length));
  }
  get msgLoaded(): boolean {
    return this.messageService.loaded;
  }

  get chat(): Observable<number> {
    return this.chatService.count;
  }
  get chatLoaded(): boolean {
    return this.chatService.loaded;
  }

  get newPosts(): Observable<number> {
    return this.notificationService.countPosts();
  }
  get newPostsLoaded(): boolean {
    return this.notificationService.loaded;
  }

  randomUser: User;

  getBranchTitle(): string {
    let branch = this.randomUser.branch as unknown as Branch;
    return  branch.title;
  }


}

import { Component, OnInit } from '@angular/core';
import * as fromPostReducers from '@/store/post/post.reducers';
import * as fromPostActions from '@/store/post/post.actions';

import * as fromGroupReducers from '@/store/group/group.reducers';
import * as fromGroupActions from '@/store/group/group.actions';


import { ActionsSubject, select, Store } from '@ngrx/store';
import { merge, Observable, Subject, zip } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Post } from '@/model/post.model';
import { Group } from '@/model/group.model';
import { ListNewsComponent } from './list-news.component';

@Component({
  // selector: 'app-list-news',
  templateUrl: './list-news.component.html',
  styleUrls: ['./list-news.component.scss']
})
export class ListNewsUnseenComponent extends ListNewsComponent {
  constructor(
    store: Store<fromPostReducers.PostsState>,
    router: Router,
    activatedRoute: ActivatedRoute,
    actionsSubject$: ActionsSubject,
  ) {
    super(store, router, activatedRoute, actionsSubject$);
  }

  ngOnInit(): void {
    this.onInit();
    this.groupId = 0;
    this.store.dispatch(fromPostActions.fetchNewsAction({unseen: true}));
    this.store.dispatch(fromPostActions.setHasMore({hasMore: true}));
  }

  get posts(): Observable<Post[]> {
    return this.store.pipe(takeUntil(this.unsubscribe$), select(fromPostReducers.selectPostsNoGroup))
  }

  protected dispatchMore() {
    this.store.dispatch(fromPostActions.fetchNewsAction({before: this.oldestId, unseen:true }));
  }
}

import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Data, ParamMap } from '@angular/router';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { KbFile } from '@/model/file.model';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
  private readonly unsubscribe$: Subject<void> = new Subject();
  file: KbFile

  constructor(
    private _location: Location,
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute
  ) {
    activatedRoute.data.subscribe( (data: Data) => {
      this.file = data.file;
      this.download(data.file);
    });
  }

  ngOnInit(): void {
    // this._location.back();
  }
  private download(file: KbFile) {
    console.log(file);
    this.httpClient
      .get(`${environment.apiUrl}/v1/kb/file/${file.id}/download`, {responseType: 'blob'})
      // .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          let blob:any = new Blob([response], { type: file.mimeType });
          // saveAs(blob, file.name);

          const url = window.URL.createObjectURL(blob);
          var anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = file.name;
          document.body.appendChild(anchor);
          anchor.click();

          setTimeout(() => {});
        },
        error => console.log('Error downloading the file'),
        () => console.info('File downloaded successfully')
      );
  }
}

import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { Store, select, ActionsSubject } from '@ngrx/store';
import * as fromTrainingPackageiaActions from '@/store/package/package.actions';
import * as fromTrainingPackageReducers from '@/store/package/package.reducers';
import { Invitation, TrainingInvitePack, TrainingPackage } from '@/model/package.model';
import { fromEvent, Observable, of, Subject } from 'rxjs';
import { PaginationDTO } from '@/model/pagination.model';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-package-participants',
  templateUrl: './package-participants.component.html',
  styleUrls: ['./package-participants.component.scss']
})
export class PackageParticipantsComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  package: TrainingPackage;
  searchTerm: string;
  page = 1;
  pageSize = 20;
  list: PaginationDTO<Invitation> = {
    total: 0,
    list: [],
  };

  constructor(
    private store: Store<fromTrainingPackageReducers.State>,
    private activatedRoute: ActivatedRoute,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe( (data: Data) => {
      this.package = data.instance;

      if (data.instance.archive || data.instance.open) {
        this.router.navigate(['/training/admin/package']);
        return;
      }

      this.store.dispatch(fromTrainingPackageiaActions.fetchParticipants({
        package: this.package,
        page: 1
      }));
      // this.store.pipe(
      //   select(fromTrainingPackageReducers.selectTrainingParticipants, this.package.id),
      //   takeUntil(this.unsubscribe$),
      //   distinctUntilChanged()
      // ).subscribe((item) => {
      //   this.package = item;
      // });
      // this.store.pipe(
      //   select(fromTrainingPackageReducers.selectTrainingInvited, this.package.id),
      //   takeUntil(this.unsubscribe$),
      //   distinctUntilChanged()
      // ).subscribe((list) => {
      //   if(list.list.length)
      //     this.list = list;
      // });

      fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
        takeUntil(this.unsubscribe$),
        map((event: any) => event.target.value),
        filter( (res:string) => res.length > 2),
        debounceTime(1000),
        distinctUntilChanged())
          .subscribe((text: string) => {
            this.searchTerm = text.toLowerCase().trim();
            this.store.dispatch(fromTrainingPackageiaActions.fetchParticipants({
                package: this.package,
                page: this.page,
                search: this.searchTerm
              }));
          });
    });
  }

  identify(index, entity: any){ return entity.id }

  get pagination(): Observable<PaginationDTO<Invitation>> {
    return this.package
            ? this.store.pipe(select(fromTrainingPackageReducers.selectTrainingParticipants, this.package.id),takeUntil(this.unsubscribe$))
            : of({total: 0, list: []});
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  clearSearch() {
    this.searchInput.nativeElement.value = '';
  }


  pageChange(page): void {
    this.page = page;
    this.store.dispatch(fromTrainingPackageiaActions.fetchParticipants({
      package: this.package,
      page: this.page,
      search: this.searchTerm
    }));
  }
}

import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { ActionsSubject, Store } from '@ngrx/store';
import { from, Observable, of } from 'rxjs';

import * as fromOfferCategoryActions from '@/store/offer-category/offer.category.action';
import * as fromOfferCategoryReducers from '@/store/offer-category/offer.category.reducers';
import { ofType } from '@ngrx/effects';
import { take } from 'rxjs/operators';
import { OfferCategory } from '@/model/offer.model';

@Injectable({
  providedIn: 'root'
})
export class OfferCategoryListResolver implements Resolve<OfferCategory[]> {

  constructor(
    private store: Store<fromOfferCategoryReducers.State>,
    private actions$: ActionsSubject
  ){}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OfferCategory[]> {
    // return of(true);
    return from( new Promise<OfferCategory[]>(resolve => {
      this.store.dispatch(fromOfferCategoryActions.fetch());
      this.actions$.pipe(
        ofType(fromOfferCategoryActions.addMany),
        take(1)
      ).subscribe((action) => {
        resolve(action.list);
      });
    }));
  }
}

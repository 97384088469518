<section class="content">
  <div class="container-xl pt-3 pb-3">
    <div class="card">
      <div class="overlay" *ngIf="!reservationCategory">
        <div style="position: sticky; text-align: center">
          <i class="fas fa-3x fa-spinner fa-spin"></i>
          <p>Ładowanie...</p>
        </div>
      </div>
      <div class="card-header d-flex align-items-center">
        <h3 class="card-title m-0 flex-grow-1">{{reservationCategory?.title}} :: Zasoby</h3>
        <!-- <div class="card-tools flex-grow-1">
          <div class="input-group">
            <input type="text" name="table_search" class="form-control float-right" placeholder="Szukaj" #searchInput>
            <button class="btn bg-transparent" style="position:absolute; right: 0; transform: translate(-100%,0);" (click)="clearSearch()">
              <i class="fa fa-times"></i>
            </button>
            <span class="input-group-append">
              <div class="input-group-text bg-transparent"><i class="fas fa-search"></i></div>
            </span>
          </div>
        </div> -->
      </div>
      <div class="table-responsive" *ngIf="reservationCategory">
        <table class="table align-middle table-hover">
          <thead>
            <tr>
              <th style="width:1rem">#</th>
              <th colspan="2">Tytuł</th>
              <th>Utworzono</th>
              <th colspan=2>Przez</th>
              <th class="text-right" >
                <button  checkRolePermition="reservations.admin.assets.create" class="btn btn-success btn-sm elevation-2 text-nowrap" [routerLink]="['add']" >
                  <i class="fas fa-plus fa-fw fa-lg"></i> Dodaj
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of list | async; let idx = index; trackBy: identify">
              <th class="align-middle">{{idx + 1}}</th>
              <td class="align-middle">
                <img [src]="item | groupAvatar: 60" class="img-circle elevation-2"/>
              </td>
              <td class="align-middle">
                {{item.title}}
              </td>
              <td class="align-middle text-nowrap">{{item.createdAt | amLocale:'pl' | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
              <td class="align-middle pr-1 text-right"><img class="img-circle" [src]="item.createdBy | userAvatar:25" [appUserDisactivatable]="item.createdBy"/></td>
              <td class="align-middle pl-0 text-nowrap">
                <span class="text-decoration-line-through" *ngIf="!item?.createdBy">Użytkownik usunięty</span>
                <span [appUserDisactivatable]="item.createdBy">{{item.createdBy?.name}}</span>
              </td>
              <td class="align-middle text-nowrap text-right" >
                <a
                  checkRolePermition="reservations.admin.assets.edit"
                  class="btn btn-primary btn-sm elevation-2 mr-2"
                  [routerLink]="['edit', item.id]"
                  ngbTooltip="Edycja">
                  <i class="fas fa-edit fa-fw fa-lg"></i>
                </a>
                <a
                    checkRolePermition="reservations.admin.assets.delete"
                    class="btn btn-danger btn-sm elevation-2"
                    nz-popconfirm
                    nzPopconfirmTitle="Czy na pewno usunąć?"
                    nzOkText="Tak"
                    nzCancelText="Nie"
                    nzPopconfirmPlacement="left"
                    ngbTooltip="Usuń"
                    (nzOnConfirm)="delete(item)" >
                  <i class="fas fa-trash fa-fw fa-lg"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Nic tu nie ma'" *ngIf="!(list | async) || (list | async).length == 0"></nz-empty>

    </div>
  </div>
</section>

export const environment = {
  production: false,
  apiUrl: 'https://api.sla.bender.usermd.net',
  nodeUrl: 'https://node.sla.bender.usermd.net',
  shopUrl: 'https://sla.pl/sklep/',
  pageSize: 20,
  slideCompleteTimeout: 2000,
  ga: 'G-N1D8NBH1P5',
  firebase: {
    apiKey: "AIzaSyC-IXo4jZBUsCZrwwIp5zz2RwhG6xrdqRY",
    authDomain: "sla-intranex.firebaseapp.com",
    projectId: "sla-intranex",
    storageBucket: "sla-intranex.appspot.com",
    messagingSenderId: "165082276002",
    appId: "1:165082276002:web:b4375a578a74965f3ef7fb",
    measurementId: "G-N1D8NBH1P5"
  }
};

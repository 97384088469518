import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

import * as fromMultimediaActions from '@/store/multimedia/multimedia.actions';
import * as fromMultimediaReducers from '@/store/multimedia/multimedia.reducers';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Multimedia, Slide, SlideType } from '@/model/multimedia.model';
import { ofType } from '@ngrx/effects';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MultimediaSlideComponent } from '../multimedia-slide/multimedia-slide.component';
import { SlideService } from '@services/slide.service';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-multimedia-edit',
  templateUrl: './multimedia-edit.component.html',
  styleUrls: ['./multimedia-edit.component.scss']
})
export class MultimediaEditComponent implements OnInit {
  SlideType = SlideType;
  private readonly unsubscribe$: Subject<void> = new Subject();
  form: FormGroup;
  multimedia: Multimedia;
  constructor(
    private toastr: ToastrService,
    private store: Store<fromMultimediaReducers.State>,
    private actionsSubject$: ActionsSubject,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected modalService: NgbModal,
    protected slideService: SlideService
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe( (data: Data) => {
      this.multimedia = data.instance;
      this.form.patchValue(this.multimedia);

      this.store.pipe(
        select(fromMultimediaReducers.selectOneMultimedia, this.multimedia.id),
        takeUntil(this.unsubscribe$),
        distinctUntilChanged()
      ).subscribe((multimedia) => {
        this.multimedia = multimedia;
      })
    });
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  save(): void {
    if (this.form.valid) {
      this.multimedia = {...this.multimedia, ...this.form.value};
      this.store.dispatch(fromMultimediaActions.saveMultimedia({item: this.multimedia}));
      this.actionsSubject$.pipe(
        takeUntil(this.unsubscribe$),
        ofType(fromMultimediaActions.updateMultimedia.type)
      ).subscribe( (result: any) => {
        this.toastr.success('Zapisano zmiany');
        this.router.navigate([`/training/admin/multimedia`]);
      });
    } else {
        this.toastr.error('Formularz jest nieprawidłowy!');
    }
  }

  addSlide(type: string) {
    const modalRef = this.modalService.open(MultimediaSlideComponent, {size: 'xl', scrollable: false});
    let slide: Slide = {
      id: null,
      name: null,
      type: type,
      position: this.multimedia.slides ? this.multimedia.slides.length : 1
    }
    modalRef.componentInstance.setSlide(slide);
    modalRef.componentInstance.multimedia = this.multimedia;
    modalRef.result
    .then( (slide) => {
      let copy = [...this.multimedia.slides];
      copy.push(slide);
      this.multimedia = {...this.multimedia, ...{ slides: copy}};
    })
  }

  editSlide(slide: Slide) {
    const modalRef = this.modalService.open(MultimediaSlideComponent, {size: 'xl', scrollable: false});
    modalRef.componentInstance.setSlide(slide);
    modalRef.componentInstance.multimedia = this.multimedia;
    modalRef.result
    .then( (slide) => {
      let pos = this.multimedia.slides.map(item => item.id).indexOf(slide.id);
      let copy = [...this.multimedia.slides];
      copy[pos] = {...slide};
      this.multimedia = {...this.multimedia, ...{ slides: copy}};
    })
  }

  deleteSlide(slide: Slide) {
    let pos = this.multimedia.slides.map(item => item.id).indexOf(slide.id);
    let copy = [...this.multimedia.slides];
    copy.splice(pos,1);
    this.multimedia = {...this.multimedia, ...{ slides: copy}};
    this.slideService.delete(slide);
  }

}

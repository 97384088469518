<section class="content">
  <div class="container">
      <div class="row">
          <div class="col-lg-12">
            <form [formGroup]="form" (ngSubmit)="save()">
            <div class="card card-primary">
              <div class="card-body">
                <div class="form-group">
                  <label for="inputName">Nazwa grupy</label>
                  <input
                    formControlName="name"
                    type="text"
                    class="form-control"
                    placeholder="Nazwa grupy"
                  />
                </div>
              </div>

              <div class="card-footer">
                <button class="btn btn-success float-right" type="submit">
                  Zapisz
                  <i class="fas fa-angle-right fa-fw"></i>
                </button>
              </div>
            </div>
            </form>
          </div>
      </div>

<ng-template #rt let-r="result" let-t="term">
  <div class="d-flex flex-md-row">
  <div class="user-panel mr-2">
    <img [src]="r | userAvatar" class="img-circle">
  </div>
  <ngb-highlight class="align-self-center" [result]="formatter(r)" [term]="t"></ngb-highlight>
  </div>
</ng-template>


<div class="card">

  <div class="card-body" checkRolePermition="training.admin.scorm">
  <label for="typeahead-prevent-manual-entry">Dodaj użytkownika do grupy</label>
  <input id="typeahead-prevent-manual-entry"  type="text" class="form-control" placeholder="Zacznij wpisywać"
        [(ngModel)]="model"
        [ngbTypeahead]="search"
        [resultTemplate]="rt"
        [inputFormatter]="formatterEmpty"
        [editable]='false'
        (selectItem)="onUserSelect($event)"/>
  </div>
  <!-- <pre>{{ members | async | json }}</pre> -->
  <table class="table">
    <thead>
      <tr>
        <th style="width:1px">#</th>
        <th colspan=3>
          <div class="d-flex flex-md-row">
            <div>Użytkownik</div>
          </div>
        </th>
        <th style="width:1px" ></th>
      </tr>
    </thead>
    <tbody *ngIf="trainingGroup">
      <tr *ngFor="let item of members | async; let idx = index; trackBy: groupMemberIdentity" >
        <td  >{{idx+1 + (page-1)*pageSize}}</td>
        <td class="user-panel" style="width:70px">
          <img
                [src]="item | userAvatar: 34"
                class="img-circle elevation-2"
                alt="User Image"
                [appUserDisactivatable]="item"
            />
        </td>
        <td>
            <strong [appUserDisactivatable]="item">{{item.firstname}} {{item.lastname}}</strong>
        </td>
        <td>
          <span [appUserDisactivatable]="item">{{item.email}}</span>
        </td>
        <!-- <td>
          <span>{{item.createdAt | amUtc | amLocal | amDateFormat:'DD.MM.YYYY HH:mm'}}</span>
        </td> -->
        <td>
          <!-- <button class="btn btn-danger btn-sm p-1 elevation-2" (click)="deleteMemeber(item)">
            <i class="fas fa-trash fa-fw fa-lg"></i>
          </button> -->
          <a
                class="btn btn-danger btn-sm elevation-2"
                nz-popconfirm
                nzPopconfirmTitle="Czy na pewno usunąć?"
                nzOkText="Tak"
                nzCancelText="Nie"
                nzPopconfirmPlacement="left"
                ngbTooltip="Usuń"
                (nzOnConfirm)="deleteMemeber(item)" >
              <i class="fas fa-trash fa-fw fa-lg"></i>
            </a>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="card-footer" *ngIf="trainingGroup">
    <!-- <pre>{{ count }}</pre> -->
    <ngb-pagination [(page)]="page" [maxSize]="22" [pageSize]="pageSize" [collectionSize]="count" (pageChange)="onPageChange()">
      <ng-template ngbPaginationFirst><i class="fas fa-angle-double-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationLast><i class="fas fa-angle-double-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationPrevious><i class="fas fa-angle-left fa-fw"></i></ng-template>
      <ng-template ngbPaginationNext><i class="fas fa-angle-right fa-fw"></i></ng-template>
      <ng-template ngbPaginationEllipsis>...</ng-template>
      <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>
  </div>

</div>

</div>
</section>
